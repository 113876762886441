import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthLoginService {
  baseUrl:string;
  constructor(private http:HttpClient) { 
    this.baseUrl = environment.API_ENDPOINT; //+'/vms-profile-manager'
  }
  
  post(url,data){
    let finalUrl = this.baseUrl+url;
    return this.http.post(finalUrl,data);
  }

  get(url){
    return this.http.get(this.baseUrl+url);
  }

  put(url,data?){
    return this.http.put(this.baseUrl+url,data);
  }
  
}
