<vms-header *ngIf="!vmsTableConfig?.hideHeader" [mainmenuName]="vmsTableConfig?.title"
  [submenuName]="vmsTableConfig?.subTitle" [isCreateButtonName]="vmsTableConfig?.isCreateButtonName"
  [tableConfig]="vmsTableConfig" [currentPage]="currentPage" [maxPages]="maxPages"
  (changePages)="onPaginationClick($event)" (onCreate)="onCreateClick($event)" (onSetting)="onSettingClick($event)"
  (onSearch)="onSearchClick($event)" (onFilter)="onClickHeaderFilter($event)" (onListFilter)="listFilter($event)"
  [ngClass]="{'theme': vmsTableConfig?.isTheme}" (onTabClick)="onTabClicked($event)">
</vms-header>
<div class="content-area ui-content-wrapper bg-light" *ngIf="columnList" [ngClass]="{'theme': vmsTableConfig?.isTheme}">
  <div class="content-area-inner">
    <div class="fixed-header" [ngClass]="{'active': fixedHeader}" *ngIf="!vmsTableConfig?.isTheme">
      <table class="table table-listing">
        <thead>
          <tr>
            <th [hidden]="!vmsTableConfig?.isTheme" width="5%" class="pl-24">
              <div class="checkbox-wrapper">
                <label class="checkbox-container">
                  <input type="checkbox" name="selectall">
                  <span class="checkmark"></span>
                </label>
              </div>
            </th>
            <th [hidden]="!vmsTableConfig?.
            isExpand" width="5%" class="pl-24">
              <button class="btn x-small btn-light rounded-sm expaneded-btn">
                <table-icon size="12px" name="add" type="material" cuClass="icon"></table-icon>
              </button>
            </th>
            <th *ngFor="let header of vmsTableConfig?.columnList; let i =index"
              [ngClass]="{'pl-24':!vmsTableConfig?.isExpand && i == 0}"
              [width]="columnList[header?.name+'_width'] + '%'" [hidden]="!columnList[header?.name]">
              <span class="mr-4"> {{header?.title? header?.title : header?.name}}</span>
              <table-icon [hidden]="!header?.isIcon" size="24px" [color]="sortedColumn === header?.name ? 'blue' : ''"
                [name]="((sortedColumn === header?.name) && isSortAsc) ? 'expand_less' : 'expand_more'" type="material"
                [cuClass]="header?.iconClass ? header?.iconClass : 'icon'" (onClick)="onSortClick(header?.name)">
              </table-icon>
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="listing-wrap">
      <div class="table-outer">
        <table class="table table-listing">
          <thead>
            <tr>
              <th [hidden]="!vmsTableConfig?.isTheme" width="5%" class="pl-24">
                <div class="checkbox-wrapper">
                  <label class="checkbox-container">
                    <input type="checkbox" name="selectall">
                    <div class="checkmark" (click)="selectAllClick($event)"></div>
                  </label>
                </div>
              </th>
              <th [hidden]="!vmsTableConfig?.isExpand" width="5%" class="pl-24">
                <button class="btn x-small btn-light rounded-sm expaneded-btn">
                  <table-icon size="12px" name="add" type="material" cuClass="icon"></table-icon>
                </button>
              </th>
              <th *ngFor="let header of vmsTableConfig?.columnList; let i =index"
                [ngClass]="{'pl-24':!vmsTableConfig?.isExpand && i == 0}"
                [width]="columnList[header?.name+'_width'] + '%'" [hidden]="!columnList[header?.name]">
                <span class="mr-4">{{header?.title ? header?.title : header?.name}}</span>
                <table-icon [hidden]="!header?.isIcon" size="24px" [color]="sortedColumn === header?.name ? 'blue' : ''"
                  [name]="((sortedColumn === header?.name) && isSortAsc) ? 'expand_less' : 'expand_more'"
                  type="material" [cuClass]="header?.iconClass ? header?.iconClass : 'icon'"
                  (onClick)="onSortClick(header?.name)">
                </table-icon>
              </th>
            </tr>
          </thead>
          <tbody *ngIf="!loading">
            <tr *ngFor="let vmsData of vmsDataSource, let i=index" (mouseleave)="hideOptionDropdown()">
              <td
                [attr.colspan]="vmsTableConfig?.isTheme || vmsTableConfig?.isExpand ? vmsTableConfig?.columnList?.length + 1 : vmsTableConfig?.columnList?.length">
                <table>
                  <tr [ngClass]="{'ifExpand' : (expandRow === i) && open,'disable':isDisabled && indexValues === i}">
                    <td [hidden]="!vmsTableConfig?.isTheme" width="5%" class="pl-24">
                      <div class="checkbox-wrapper">
                        <label class="checkbox-container">
                          <input type="checkbox" name="select-item" [(ngModel)]="vmsData.isChecked">
                          <div class="checkmark" (click)="onCheckClick(vmsData, i)"></div>
                        </label>
                      </div>
                    </td>
                    <td class="pl-24" width="5%" *ngIf="vmsTableConfig?.isExpand"
                      (click)="onclickexpand(i, vmsTableConfig?.isIdDifferent ? vmsData[vmsTableConfig?.differentId] : vmsData?.id)">
                      <button class="btn x-small btn-light rounded-sm expaneded-btn">
                        <table-icon size="12px" [name]="((expandRow === i) && open) ? 'remove' : 'add'" type="material"
                          cuClass="d-flex"></table-icon>
                      </button>
                    </td>
                    <vms-row *ngFor="let column of vmsTableConfig?.columnList;let i = index"
                      [width]="columnList[column?.name+'_width'] + '%'" [isVmsTableExpand]="vmsTableConfig?.isExpand"
                      [index]="i"
                      [vmsRowDataSource]="getColumnData( column?.isRange ? column?.rangeNameList :column?.name, vmsData)"
                      [isImage]="column?.isImage" [isNumberBadge]="column?.isNumberBadge"
                      [isContact]="column?.isContact" (viewClicked)="onViewClickd($event, vmsData)"
                      (cloneClicked)="onCloneClickd(vmsData)" (deleteClicked)="deleteClick(i,vmsData)"
                      (viewProfileClicked)="clickToViewProfile(i,vmsData)" (editClicked)="editClicked($event, vmsData)"
                      (disableClicked)="onDisableClick(i, vmsData)" style="display: contents;"
                      [hidden]="!columnList[column?.name]" [isVieworEdit]="column?.isVieworEdit"
                      [isDetails]="column?.isDetails" [isWithdrawal]="column?.isWithdrawal"
                      [isViewProfile]="column?.isViewProfile" [isInterview]="column?.isInterview"
                      [isViewEnabled]="column?.isViewEnabled" [isVieworClone]="column?.isVieworClone"
                      [isDisableorDelete]="column?.isDisableorDelete" [isProfileMatch]="column?.isProfileMatch"
                      [isCandidateStatus]="column?.isCandidateStatus" [isDelete]="column?.isDelete"
                      [isNoOption]="column?.isNoOption" [isAction]="column?.isAction"
                      [isStatusColor]="column?.isStatusColor" [isIconList]="column?.isIconList"
                      [isShowCheckBox]="column?.isShowCheckBox" [isMultiUser]="column?.isMultiUser"
                      [isOptOut]="column?.isOptOut" [options]="column?.options"  (optOutClicked)="onOptOutClicked($event, vmsData)" 
                      [isSubmitCandidate]="column?.isSubmitCandidate" (submitCandidateClicked)="onSubmittedCandidateClicked($event, vmsData)" 
                      (click)="columnClick(column, vmsData)"
                      (numberBadgeClicked)="onNumberBadgeClicked(column, vmsData)"
                      (onNameClicked)="onRowNameClick(column, vmsData)"
                      (withdrawProfileClicked)="clickToWithdrawProfile(i,vmsData)"
                      (viewDetailClicked)="clickToDetails(i,vmsData)"
                      (rescheduleInterviewClicked)="clickToRescheduleInterview(i,vmsData)"
                      (cancelInterviewClicked)="clickToCancelInterview(i,vmsData)"
                      (viewSubmittedCandidate)="clickToViewSubmittedCandidate(i,vmsData)"
                      (onOptionClicked)="optionClicked($event, vmsData)"
                      [isCheckBoxReadonly]="column?.isCheckBoxReadonly">


                    </vms-row>
                  </tr>
                  <tr class="expanded-row" *ngIf="(expandRow === i) && open">
                    <td
                      [attr.colspan]="vmsTableConfig?.isExpand ? vmsTableConfig.columnList.length + 1 : vmsTableConfig.columnList.length"
                      style="padding-left:70px;">
                      <ng-content></ng-content>
                    </td>
                  </tr>

                </table>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="loading">
            <tr>
              <td class="data-loader">
                <ngx-skeleton-loader count="10" animation="progress" appearance="lines"
                  [theme]="{'width': '100%', height: '50px','margin-bottom': '-2px', 'border-radius':'0px'}">
                </ngx-skeleton-loader>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <vms-paginator *ngIf="!loading && !vmsTableConfig?.hideBottomPagination" [currentPage]="currentPage"
        [maxPages]="maxPages" (changePage)="onPaginationClick($event)">
      </vms-paginator>

      <div *ngIf="!vmsTableConfig?.hideResultCount" class="total-result">
        <div class="total-result-box">
          {{vmsDataSource?.length}} Results
        </div>
      </div>
    </div>
  </div>
</div>