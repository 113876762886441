<div class="checkbox-panel">
  <div class="toggle-panel"  *ngFor="let toggle of moduleData; let i = index">
    <div class="toggle-panel-inner" [ngClass]="(toggle?.is_enabled && !readOnly) ? 'if-active' : 'if-inactive'">
      <span class="d-flex align-items-center">
        <icon size="19px" [name]="toggle?.icon" type="material" cuClass="mr-4" class="d-flex"></icon>
        <span> {{toggle?.name}}</span>
      </span>
      <span class="toggle-switch" [ngClass]="toggle?.is_enabled ? 'toggle-on' : 'toggle-off'" (click)="onClickToggle(i)"></span>
    </div>
  </div>
</div>