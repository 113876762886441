
<div class="create-wrapper p-24">
    <div class="page-container ">
        <div class="row no-gutter">
            <div class="col-2"></div>
            <div class="col-8 bg-white p-16">
                <h3 class="mb-16 font-weight-medium">Contact Us</h3>
                <form [formGroup]="contactForm">
                    <div class="form-group">
                        <label class="">Your Detail <span class="require">*</span></label>
                        <input formControlName="details" type="text" name="full_name" readonly disabled
                            placeholder="Simplify MSP Alert (simplifymspalert@gmail.com)" class="form-control text-gray">
                    </div>
                    <div class="form-group">
                        <label>Support Email <span class="require">*</span></label>
                        <input formControlName="email" type="email" disabled name="email_address text-gray" readonly placeholder="Support@simplifyvms.com"
                            class="form-control text-gray">
                    </div>

                    <div class="form-group">
                        <label class="d-flex justifiy-content-center align-items-center">Subject <span class="require">*</span></label>
                        <input formControlName="subject" type="text" name="subject"
                        [ngClass]="{'error':contactForm.controls['subject'].invalid && submited}"
                        class="form-control text-gray">
                    </div>

                    <div class="form-group">
                        <label class="d-flex justifiy-content-center align-items-center">Message <span class="require">*</span></label>
                        <quill-editor [modules]="modules" formControlName="message" [styles]="{height: '200px'}" [ngClass]="{'error':contactForm.controls['message'].invalid && submited}"></quill-editor>
                        <!-- <textarea formControlName="message" name="message" id="message" [ngClass]="{'error':contactForm.controls['message'].invalid && submited}" class="form-control text-gray" rows="5"></textarea> -->
                    </div>
                    <div class="d-flex justifiy-content-center align-items-center">
                        <button type="submit" class="btn btn-primary mr-4 " [disabled]="contactForm?.invalid" (click)="contact()">Send</button>
                        <button type="reset" class="btn btn-default border-0" routerLink="/dashboard">Cancel</button>
                    </div>
                </form>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
</div>