import { Injectable } from '@angular/core';
import { HttpService } from '../core/services/http.service';
import { map } from 'rxjs/operators';
import { StorageService } from '../core/services/storage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private currentProgram;
  private baseUrl;
  private reportUrl;
  constructor(private http: HttpService,private storageService: StorageService) {
    this.currentProgram = this.storageService.get('CurrentProgram');
    this.baseUrl = environment.API_ENDPOINT;
    this.reportUrl = environment.REPORT_ENDPOINT;
  }
  get(url) {
    return this.http.get(url, this.reportUrl);
  }
  post(url, payload?) {
    return this.http.post(url, payload, this.reportUrl);
  }
  postBlob(url, payload?) {
    return this.http.downloadPostBlob(url, payload, this.reportUrl);
  }
}

