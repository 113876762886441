import { Injectable } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventStreamService {
  private subject$ = new Subject();

  on(event: Events, action: any): Subscription {
    return this.subject$
      .pipe(
        filter((e: EmitEvent) => e.name === event),
        map((e: EmitEvent) => e.value)
      )
      .subscribe(action);
  }

  emit(event: EmitEvent) {
    this.subject$.next(event);
  }
}

export class EmitEvent {
  constructor(public name: any, public value?: any) { }
}


export enum Events {
  LoggedIn,
  LoggedOut,
  AccountSetup,
  IsNoOrg,
  DO_SHOW_CLIENT_FORM,
  ProgramIndex,
  DO_SHOW_USER_FORM,
  CONTINUE_CLICKED,
  CREATE_NEW_USER,
  ON_SHOW_INSIGHT,
  ORG_CREATE,
  ORG_CREATED,
  FOUNDATION_DATA_TYPE_CREATE,
  USER_CREATE,
  ORG_EDIT,
  ORG_VIEW,
  OPTION_DROPDOWN,
  FOUNDATION_DATA_CREATE,
  UPDATE_FOUNDATION_DATA_TYPE_LIST,
  UPDATE_FOUNDATION_DATA_LIST,
  FOUNDATIONAL_DATA_EDIT,
  FOUNDATIONAL_DATA_TYPE_EDIT,
  ROLE_CREATE,
  FOUNDATIONAL_DATA_VIEW,
  VIEW_USER_ROLE,
  CREATE_QUALIFICATION,
  QUALIFICATION_DATA_EDIT,
  QUALIFICATION_DATA_VIEW,
  UPDATE_QUALIFICATION_TYPE_LIST,
  VIEW_HIERARCHY,
  EDIT_HIERARCHY,
  CREATE_QUALIFICATION_ITEM,
  QUALIFICATION_ITEM_VIEW,
  EDIT_QUALIFICATION_ITEM,
  CREATE_HIERARCHY,
  SELECTED_CUSTOM_FIELD,
  CREATE_VENDOR_GROUP,
  EDIT_VENDOR_GROUP,
  VENDOR_GROUP_VIEW,
  UPDATE_CUSTOM_FIELD_PROPERTIES,
  CREATE_VENDOR,
  VENDOR_VIEW,
  EDIT_VENDOR,
  VALIDATE_ORGANIZATION,
  CUSTOM_FIELDS,
  CREATE_CANDIDATE_CERTIFICATE,
  CREATE_CANDIDATE_VACCINE,
  CREATE_CANDIDATE_SKILL,
  CREATE_CANDIDATE_CREDENTIAL,
  CREATE_CANDIDATE_EDUCATION,
  CREATE_CANDIDATE_SPECIALITY,
  VIEW_CANDIDATE_ASSIGNMENT_AND_JOBS,
  CREATE_VENDOR_SCHEDULE,
  VENDOR_VIEW_SCHEDULE,
  EDIT_VENDOR_SCHEDULE,
  SYSTEM_QUALIFICATION_TYPE_VIEW,
  SHOW_GRID_LAYOUT,
  VIEW_CANDIDATE_PROFILE,
  WITHDRAW_CANDIDATE,
  PROGRAM_SIDEBAR,
  FOUNDATIONAL_DATA_TYPE_VIEW,
  SEARCH_IN_SUB_CANDIDATE,
  PENDING_APPROVAL_REQUEST,
  VIEW_INTERVIEW_DETAILS,
  VIEW_SUBMITTED_CANDIDATE_PROFILE,
  REJECT_CANDIDATE,
  UPDATED_CANDIDATE_STATUS,
  OFFER_DETAIL_PANEL,
  CANCEL_INTERVIEW,
  ENTER_TIMESHEET,
  TIMESHEET_REJECT,
  VIEW_TYPE,
  TASK_DETAILS,
  ADD_VENDOR,
  SEARCH_VENDOR,
  SHOW_COMPLIANCE,
  UPDATE_CURRENT_TIMESHEET_DETAILS,
  UPDATE_BILLING_AND_WORKING_DAYS_DETAILS,
  VIEW_HISTORY_OF_ASSIGNMENT,
  HIDE_HISTORY,
  UPDATE_TASK_ID,
  ASSIGNMENT_VIEW_PROFILE,
  VIEW_ASSIGNMENT_TIMESHEET,
  EDIT_PROFILE,
  ON_PROFILE_UPDATE,
  ADD_DELEGATE,
  EDIT_DELEGATE,
  SHOW_IMPACTED_TIMESHEETS,
  UPLOAD_NEW_COMPLIANCE,
  TIMESHEET_MODIFY,
  TEMPORARY_ACCESS,
  CREATE_RESTRICTION_RULE,
  EDIT_RESTRICTION_RULE
}
