import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import {AuthguardService} from '../core/services/auth_guard.service';
import { LoginComponent } from './login/login.component';
import { AccountSetupIntroComponent } from './account-setup/account-setup-intro/account-setup-intro.component';
import { SetPasswordComponent } from './account-setup/set-password/set-password.component';
import { AccountSetupComponent } from './account-setup/account-setup.component';
import { SecretQuestionsComponent } from './account-setup/secret-questions/secret-questions.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BasicDetailsComponent } from './account-setup/basic-details/basic-details.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { CustomizeAvatarComponent } from './account-setup/customize-avatar/customize-avatar.component';
import { ColorThemeComponent } from './account-setup/color-theme/color-theme.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InviteUserComponent } from './invite-user/invite-user.component';

@NgModule({
  declarations: [LoginComponent, PasswordStrengthComponent, AccountSetupIntroComponent, SetPasswordComponent, AccountSetupComponent, SecretQuestionsComponent, BasicDetailsComponent, CustomizeAvatarComponent, ColorThemeComponent, InviteUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    ImageCropperModule,
    SharedModule,
    NgSelectModule
  ],
  providers:[AuthguardService],
  exports: [PasswordStrengthComponent]
})
export class AuthModule { }
