import { Component, OnInit, NgZone, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName, FormControl } from '@angular/forms';
import { SearchAddressComponent } from '../../../shared/components/search-address/search-address.component';
import { NgSelectComponent } from '@ng-select/ng-select';
@Component({
  selector: 'address-and-contact-details',
  templateUrl: './address-and-contact-details.component.html',
  styleUrls: ['./address-and-contact-details.component.scss']
})
export class AddressAndContactDetailsComponent implements OnInit {

  constructor(private fb: FormBuilder, private zone: NgZone) { }
  title = 'Primary Address';
  subtitle = 'Primary Contact';
  secondaryAddress = 'Add Secondary Address';
  secondaryContact = 'Add Secondary Contact';
  secondaryaddressOpen = false;
  secondaryContactOpen = false;
  public addressForm: FormGroup;
  public countryShortName: string;
  secondaryCountryShortName: string;

  @Input() editAddress;
  @Input() isViewClicked;
  @Input() isCreate;
  public address = [];
  public establishmentAddress = [];
  public phone = [];
  public formattedAddress = [];
  public street_2 = [];
  public city = [];
  public state = [];
  public zipcode = [];
  public selecteItem = [];
  public mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  @ViewChild(SearchAddressComponent) googleAddressComponent: SearchAddressComponent;
  @ViewChild('address1') address1;
  @ViewChild('address2') address2;
  //public formattedAddress: string;

  ngOnInit(): void {
    this.addressForm = this.fb.group({
      country: [null, null],
      secondarycountry: [null, ''],
      address: ['', ''],
      secondary_address: ['', ''],
      contact_person: ['', ''],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      secondary_contact_person: ['', ''],
      secondary_first_name: [''],
      secondary_last_name: ['',],
      designation: ['', ''],
      secondary_designation: ['', ''],
      phone: ['', ''],
      secondary_phone: ['', ''],
      email: ['', [Validators.required]],
      secondary_email: ['', ''],
      phoneFomat: ['', ''],
      secondary_phoneFomat: ['', '']
    });
    this.addressForm.get('phoneFomat').setValue("<img src='../../../assets/images/flag/English (Canada).png' width='20px' /> +1");
    this.addressForm.get('secondary_phoneFomat').setValue("<img src='../../../assets/images/flag/English (Canada).png' width='20px' /> +1");
    let btntitle = this.isViewClicked ? "View" : (!this.isCreate && !this.isViewClicked) ? "Edit" : "Add";
    this.title = 'Primary Address';
    this.subtitle = 'Primary Contact';
  }

  ngOnChanges() {
    this.loadData();
    //this.secondaryaddressOpen = this.isViewClicked ? true : false;
    //this.secondaryContactOpen = this.isViewClicked ? true : false;
    let btntitle = this.isViewClicked ? "View" : (!this.isCreate && !this.isViewClicked) ? "Edit" : "Add";
    this.isViewClicked = this.isViewClicked;
    if (this.isCreate && this.editAddress?.length > 0) this.addressForm.reset();
  }

  loadData() {
    if (this.editAddress?.length > 0) {
      let primaryAddress = this.loadAddress(this.editAddress[0]?.primary);
      let secondaryAddress = this.loadAddress(this.editAddress[1]?.secondary);
      if (primaryAddress) {
        this.setCountry('primary')
      } else {
        this.setCountry('secondary')
      }
      //totalAdress = totalAdress !== undefined ? totalAdress : '';
      this.addressForm.patchValue({
        country: this.editAddress[0]?.primary.country !== '' ? this.editAddress[0]?.primary.country : '',
        secondarycountry: this.editAddress[1]?.secondary.country !== '' ? this.editAddress[1]?.secondary.country : '',
        address: primaryAddress,
        secondary_address: secondaryAddress,
        first_name: this.editAddress[2]?.primarycontacts?.first_name !== '' ? this.editAddress[2]?.primarycontacts.first_name : '',
        last_name: this.editAddress[2]?.primarycontacts?.last_name !== '' ? this.editAddress[2]?.primarycontacts.last_name : '',
        secondary_first_name: this.editAddress[3]?.secondarycontacts?.first_name !== '' ? this.editAddress[3]?.secondarycontacts.first_name : '',
        secondary_last_name: this.editAddress[3]?.secondarycontacts?.secondary_last_name !== '' ? this.editAddress[3]?.secondarycontacts.secondary_last_name : '',
        designation: this.editAddress[2]?.primarycontacts.designation !== '' ? this.editAddress[2]?.primarycontacts.designation : '',
        secondary_designation: this.editAddress[3]?.secondarycontacts.designation !== '' ? this.editAddress[3]?.secondarycontacts.designation : '',
        phone: this.editAddress[2]?.primarycontacts.phone !== '' ? this.editAddress[2].primarycontacts.phone : '',
        secondary_phone: this.editAddress[3]?.secondarycontacts.phone !== '' ? this.editAddress[3].secondarycontacts.phone : '',
        email: this.editAddress[2]?.primarycontacts.email !== '' ? this.editAddress[2]?.primarycontacts.email : '',
        secondary_email: this.editAddress[3]?.secondarycontacts.email !== '' ? this.editAddress[3]?.secondarycontacts.email : '',
      });
      this.address[0] = this.checkAddress(this.editAddress[0].primary)?.address_line1 || '';
      this.address[1] = this.checkAddress(this.editAddress[1].secondary)?.address_line1 || '';
      this.street_2[0] = this.checkAddress(this.editAddress[0].primary)?.address_line2;
      this.street_2[1] = this.checkAddress(this.editAddress[1].secondary)?.address_line2;
      this.zipcode[0] = this.checkAddress(this.editAddress[0]?.primary)?.zipcode || null;
      this.zipcode[1] = this.checkAddress(this.editAddress[1]?.secondary)?.zipcode || null;
      this.phone[0] = this.editAddress[0]?.primary?.phone || null;
      this.phone[1] = this.editAddress[1]?.secondary?.phone || null;
      this.city[0] = this.checkAddress(this.editAddress[0]?.primary)?.city || null;
      this.city[1] = this.checkAddress(this.editAddress[1]?.secondary)?.city || null;
      this.state[0] = this.checkAddress(this.editAddress[0]?.primary)?.state || null;
      this.state[1] = this.checkAddress(this.editAddress[1]?.secondary)?.state || null;
      if (primaryAddress) {
        this.setCountry('primary')
      } else {
        this.setCountry('secondary')
      }
    } else {
      this.addressForm = this.fb.group({
        country: [null, Validators.required],
        secondarycountry: [null, ''],
        address: ['', Validators.required],
        secondary_address: ['', ''],
        contact_person: ['', ''],
        secondary_contact_person: ['', ''],
        designation: ['', ''],
        secondary_designation: ['', ''],
        phone: ['', ''],
        secondary_phone: ['', ''],
        email: ['', ''],
        secondary_email: ['', ''],
        phoneFomat: ['', ''],
        secondary_phoneFomat: ['', '']
      });
    }
    if (this.addressForm.get('secondarycountry').value) {
      this.secondaryaddressOpen = true;
    }
    else {
      this.secondaryaddressOpen = false;
    }
    if (this.addressForm.get('secondary_contact_person').value || this.addressForm.get('secondary_phone').value || this.addressForm.get('secondary_email').value || this.addressForm.get('secondary_designation').value) {
      this.secondaryContactOpen = true;
    }
    else {
      this.secondaryContactOpen = false;
    }
  }

  loadAddress(data) {
    let street1 = (data?.address_line1?.trim() != '' && data?.address_line1 != undefined && data?.address_line1 != null) ? (data?.address_line1 + ' ,') : "";
    let street2 = (data?.address_line2?.trim() != '' && data?.address_line2 != undefined && data?.address_line2 != null) ? (data?.address_line2 + ' ,') : "";
    const city = (data?.city?.trim() !== '' && data?.city != undefined && data?.city != null) ? (data?.city + ' ,') : "";
    let country = (data?.country?.trim() !== '' && data?.country != undefined && data?.country != null) ? (data?.country + ' ,') : "";
    const zip = (data?.zipcode?.trim() !== '' && data?.zipcode != undefined && data?.zipcode != null) ? data?.zipcode : "";
    const state = (data?.state?.trim() !== '' && data?.state != undefined && data?.state != null) ? (data?.state + ' ,') : "";
    if (country && zip == '') {
      country = country.slice(0, -1);
    }
    return street1 + street2 + city + state + zip;

  }
  checkAddress(address) {
    return address ? address : '';
  }

  setCountry(type) {
    let val = (type === 'primary') ? this.addressForm.get('country').value : this.addressForm.get('secondarycountry').value;
    if (val) {
      if (type === 'primary') {
        let primaycontry;
        switch (val) {
          case 'Canada':
            this.countryShortName = "CA";
            this.addressForm.get('phoneFomat').setValue("<img src='../../../assets/images/flag/English (Canada).png' width='20px' /> +1");
            break;
          case 'USA':
            this.countryShortName = "US";
            this.addressForm.get('phoneFomat').setValue("<img src='../../../assets/images/flag/us.png' width='20px' /> +1");
            break;
          case 'India':
            this.countryShortName = "IN";
            this.addressForm.get('phoneFomat').setValue("<img src='../../../assets/images/flag/हिन्दी.png' width='20px' /> +91");
            break;
        }

        this.googleAddressComponent?.getPlaceAutocomplete(this.countryShortName);
      } else {
        switch (val) {
          case 'Canada':
            this.secondaryCountryShortName = "CA";
            this.addressForm.get('secondary_phoneFomat').setValue("<img src='../../../assets/images/flag/English (Canada).png' width='20px' /> +1");
            break;
          case 'USA':
            this.secondaryCountryShortName = "US";
            this.addressForm.get('secondary_phoneFomat').setValue("<img src='../../../assets/images/flag/us.png' width='20px' /> +1");
            break;
          case 'India':
            this.countryShortName = "IN";
            this.addressForm.get('secondary_phoneFomat').setValue("<img src='../../../assets/images/flag/हिन्दी.png' width='20px' /> +91");
            break;

        }
        if (this.address2) {
          this.address2._value = '';
          this.resetAddress(1);
        }
        this.googleAddressComponent?.getPlaceAutocomplete(this.secondaryCountryShortName);
      }
    }

  }

  setaddres(value) {
    if (value == 'primary') {
      if (this.address1) {
        this.address1._value = '';
        this.resetAddress(0);
      }
    }
    else {
      if (this.address2) {
        this.address2._value = '';
        this.resetAddress(1);
      }
    }
  }

  getAddress(place: object) {
    //this.phone = this.getPhone(place);
    this.formattedAddress[0] = place['formatted_address'];
    this.zone.run(() => this.formattedAddress[0] = place['formatted_address']);
    this.address[0] = this.getStreetNumber(place);
    this.street_2[0] = this.getStreet(place);
    this.city[0] = this.getCity(place);
    this.zipcode[0] = this.getPostCode(place);
    let phone = this.getPhone(place);
    this.state[0] = this.getState(place);
    if (phone) {
      this.addressForm.controls.phone.setValue(phone);
    }
    console.log(this.address, this.formattedAddress);

  }

  resetAddress(index) {
    this.address[index] = '';
    this.street_2[index] = '';
    this.zipcode[index] = '';
    this.phone[index] = '';
    this.state[index] = '';
    this.city[index] = '';
  }

  getSecondaryAddress(place: object) {
    this.address[1] = place['formatted_address'];
    //this.phone = this.getPhone(place);
    this.formattedAddress[1] = place['formatted_address'];
    this.zone.run(() => this.formattedAddress[1] = place['formatted_address']);
    this.address[1] = this.getStreetNumber(place);
    this.street_2[1] = this.getStreet(place);
    this.city[1] = this.getCity(place);
    this.zipcode[1] = this.getPostCode(place);
    let phone = this.getPhone(place);
    this.state[1] = this.getState(place);
    if (phone) {
      this.addressForm.controls.secondary_phone.setValue(phone);
    }
  }

  // getEstablishmentAddress(place: object) {
  //   this.establishmentAddress = place['formatted_address'];
  //   this.phone = this.getPhone(place);
  //   this.formattedEstablishmentAddress = place['formatted_address'];
  //   this.zone.run(() => {
  //     this.formattedEstablishmentAddress = place['formatted_address'];
  //     this.phone = place['formatted_phone_number'];
  //   });
  // }

  getAddrComponent(place, componentTemplate) {
    let result;
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' };
    const streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getStreet(place) {
    const COMPONENT_TEMPLATE = { route: 'long_name' };
    const street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getCity(place) {
    const COMPONENT_TEMPLATE = { locality: 'long_name' };
    const city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

  getState(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' };
    const state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getDistrict(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' };
    const state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getCountryShort(place) {
    const COMPONENT_TEMPLATE = { country: 'short_name' };
    const countryShort = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return countryShort;
  }

  getCountry(place) {
    const COMPONENT_TEMPLATE = { country: 'long_name' };
    const country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }

  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' };
    const postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  getPhone(place) {
    const COMPONENT_TEMPLATE = { formatted_phone_number: 'formatted_phone_number' };
    const phone = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return phone;
  }

  showPrimarySecondaryAddress() {
    let btntitle = this.isViewClicked ? "View" : (!this.isCreate && !this.isViewClicked) ? "Edit" : "Add";
    let type = 'secondary';
    this.secondaryaddressOpen = !this.secondaryaddressOpen;
    this.setCountry(type);
    if (this.secondaryAddress === 'Add Secondary Address') {
      this.setCountry(type);
      this.secondaryAddress = 'Remove Secondary Address';
    } else if (this.secondaryAddress === 'Remove Secondary Address') {
      this.secondaryAddress = 'Add Secondary Address';
      this.addressForm.controls.secondary_address.patchValue('');
      this.addressForm.controls.secondarycountry.patchValue(null);
    }
  }


  showPrimarySecondaryContact() {
    let btntitle = this.isViewClicked ? "View" : (!this.isCreate && !this.isViewClicked) ? "Edit" : "Add";
    this.secondaryContactOpen = !this.secondaryContactOpen;
    if (this.secondaryContact === 'Add Secondary Contact') {
      this.secondaryContact = 'Remove Secondary Contact';
    } else if (this.secondaryContact === 'Remove Secondary Contact') {
      this.secondaryContact = 'Add Secondary Contact';
      this.addressForm.controls.secondary_contact_person.patchValue('');
      this.addressForm.controls.secondary_designation.patchValue('');
      this.addressForm.controls.secondary_email.patchValue('');
      this.addressForm.controls.secondary_phone.patchValue('');
    }

  }
}
