import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CandidateComponent } from './candidate.component'
import { CreateCandidateComponent } from './create-candidate/create-candidate.component';
import { ListCandidateComponent } from './list-candidate/list-candidate.component'

const routes: Routes = [
  {
    path: '',
    component: CandidateComponent,
    children: [
      { path: 'list/add', component: CreateCandidateComponent },
      { path: 'submit', component: CreateCandidateComponent },
      { path: 'list', component: ListCandidateComponent },
      { path: 'edit/:id', component: CreateCandidateComponent},
      { path: 'view/:id', component: CreateCandidateComponent}
    ],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CandidateRoutingModule { }
