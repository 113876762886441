import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AuthorizationService } from 'src/app/core/services/authorize.service';
import {
  EventStreamService,
  Events,
} from 'src/app/core/services/event-stream.service';

@Component({
  selector: 'vms-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss']
})
export class VMSRowComponent implements OnInit {
  expandRow: number;
  @Input() isIcon = false;
  @Input() isImage = false;
  @Input() isNumberBadge = false;
  @Input() isVmsTableExpand: boolean = false;
  @Input() isContact = false;
  @Input() index: any;
  @Input() vmsRowDataSource: any;
  @Input() isAction = false;
  @Input() isNoOption = false;
  @Input() isDetails = false;
  @Input() isVieworEdit = false;
  @Input() isWithdrawal = false;
  @Input() isViewProfile = false;
  @Input() isVieworClone = false;
  @Input() isDisableorDelete = false;
  @Input() isDelete = false;
  @Input() isStatusColor = false;
  @Input() isIconList = false;
  @Input() isShowCheckBox = false;
  @Input() isMultiUser = false;
  @Input() isOpenView = false;
  @Input() toolTipVisibility = false;
  @Input() isArray: boolean;
  @Input() hideBadege: boolean;
  @Input() showTitleCase: boolean;
  // @Input() showUpdate = false;
  @Input() width: string;
  @Input() isProfileMatch: boolean;
  @Input() isCandidateStatus: boolean;
  @Input() isInterview: boolean;
  @Input() isViewEnabled: boolean;
  @Input() isOptOut: boolean;
  @Input() isSubmitCandidate: boolean;
  @Input() options: any[] = [];
  @Input() isCheckBoxReadonly: boolean;
  @Input() permission: string;
  @Input() vmsTableConfig: any;
  @Input() isNavigation: boolean;

  @Output() viewClicked = new EventEmitter();
  @Output() cloneClicked = new EventEmitter();
  @Output() disableClicked = new EventEmitter();
  @Output() editClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Output() optOutClicked = new EventEmitter();
  @Output() updateClicked = new EventEmitter();
  @Output() submitCandidateClicked = new EventEmitter();
  @Output() numberBadgeClicked = new EventEmitter();
  @Output() onNameClicked = new EventEmitter();
  @Output() viewProfileClicked = new EventEmitter();
  @Output() withdrawProfileClicked = new EventEmitter();
  @Output() viewDetailClicked = new EventEmitter();
  @Output() rescheduleInterviewClicked = new EventEmitter();
  @Output() cancelInterviewClicked = new EventEmitter();
  @Output() onOptionClicked = new EventEmitter();
  @Output() viewSubmittedCandidate = new EventEmitter();
  @Output() onOpenPanel = new EventEmitter();

  isObjectElementBig = false;
  showdropdown = false;
  showdropdownFirstCol = false;
  svgValue = `10, 100`

  has_permission = false

  constructor(
    private eventStream: EventStreamService,
    private _authorizationService: AuthorizationService
  ) { }

  ngOnInit(): void {
    if (this.isOpenView) {
      this.has_permission = true
    }
    if (this.permission) {
      this.has_permission = this._authorizationService.authorize(this.permission)
    }
    if (this.isShowCheckBox) {
      if (this.vmsRowDataSource) {
        this.vmsRowDataSource = true
      } else {
        this.vmsRowDataSource = false
      }
    }
    if (this.isObject() === 'object' && this.vmsRowDataSource) {
      const converArr = [this.vmsRowDataSource];
      this.isObjectElementBig = converArr.some(ele => (ele && ele.length > 2));
    }
    this.eventStream.on(Events.OPTION_DROPDOWN, (data) => {
      if (data) {
        this.showdropdown = false;
      }
    });
  }

  isObject() {
    if (this.vmsRowDataSource === 'pending') {
      // this.vmsRowDataSource = true;
    }
    return typeof this.vmsRowDataSource;
  }

  onclickexpand(id: any) { }

  clickToView() {
    this.viewClicked.emit(true);
  }

  clickToClone() {
    this.cloneClicked.emit(true);
  }

  clickToEdit() {
    this.editClicked.emit(true);
  }

  detailViewClick() {
    this.viewDetailClicked.emit(true);
  }

  clickTodisable() {
    this.disableClicked.emit(true);
  }

  clickTodelete() {
    this.deleteClicked.emit(true);
  }

  clickToSubmitCandidate() {
    this.submitCandidateClicked.emit(true);
  }

  clickToOptOut() {
    this.optOutClicked.emit(true);
  }

  clickToUpdate() {
    this.updateClicked.emit(true);
  }
  showOptionDropdown() {
    this.showdropdown = true;
  }

  showDropdownFirstCol() {
    this.showdropdownFirstCol = true;
  }

  hideDropdownFirstCol() {
    this.showdropdownFirstCol = false;
  }

  onNumberBadgeClicked() {
    this.numberBadgeClicked.emit();
  }

  onClickedName() {
    this.onNameClicked.emit();
  }

  clickToViewProfile() {
    this.viewProfileClicked.emit(true);
  }

  clickWithdrawCandidate() {
    this.withdrawProfileClicked.emit(true);
  }
  onRescheduleClick() {
    this.rescheduleInterviewClicked.emit(true);
  }
  onCancelClick() {
    this.cancelInterviewClicked.emit(true);
  }
  clickToViewCandidate() {
    this.viewSubmittedCandidate.emit(true);
  }
  optionClicked(option) {
    this.onOptionClicked.emit(option);
  }
  openPanel() {
    if (this.isOpenView) {
      this.onOpenPanel.emit(true);
    }
  }
}
