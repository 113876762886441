import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators, FormBuilder, FormGroup, FormControlName, FormArray } from '@angular/forms';
import { StorageKeys, StorageService } from 'src/app/core/services/storage.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { ConfirmationDialogService } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.service';
import { DashboardService } from '../../dashboard.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent implements OnInit {
  public contactForm: FormGroup;
  submited: any;
  user: any;
  programId: any;
  public modules: any;
  constructor(private fb: FormBuilder,
    private dashboardService: DashboardService,
    public alert: AlertService,
    public _storageService: StorageService,
    private _confirmService: ConfirmationDialogService,
    private location: Location
  ) {
    this.modules = {
      'toolbar': [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['blockquote'],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ]
    }
  }

  ngOnInit(): void {
    this.user = this._storageService.get('user');
    let programDetails = this._storageService.get('CurrentProgram');
    if (programDetails) {
      this.programId = programDetails['id'];
    }
    this.getSupportEmail();
    // this.emailValidator
    this.contactForm = this.fb.group({
      details: [null, [Validators.required]],
      email: [null, [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
    this.setData();

  }

  setData() {
    this.contactForm.patchValue({
      details: this.user?.first_name + ' (' + this.user?.email + ')',
      // email: 'info@simplifyvms.com',
    })
  }
  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
  }

  getSupportEmail() {
    let _url = `/configurator/programs/${this.programId}/config?entity_code=support`;
    this.dashboardService.get(`${_url}`).subscribe(res => {
      let response = JSON.parse(JSON.stringify(res));
      this.contactForm.patchValue({
        email: response?.config?.email || "info@simplifyvms.com",
      })
    }, err => {
      this.contactForm.patchValue({
        email: "info@simplifyvms.com",
      })
      // console.log(err);
    });
  }
  contact() {
    this.submited = true;
    if (this.contactForm.invalid) {
      this.alert.error('Please fill the required details');
      return;
    }
    const currentProgram = this._storageService.get(StorageKeys.CURRENT_PROGRAM);
    let Payload = {
      program_id:  this.programId,
      category: "Generic",
      event: "Custom Email Trigger",
      data: {
        from: {
          name: this.user?.full_name,
          email: this.user?.email
        },
        to: {
          email: this.user?.email
        },
        template_subject: `${this.contactForm?.value?.subject || ''} - ${currentProgram?.name || ''}`,
        template_body: this.contactForm.value.message
      }
    }

    this.dashboardService.post(`/customer-service/api/customer-service/api/send-customer-support-email`, Payload).subscribe(
      data => {
        if (data) {
          // this.alert.success('Email sent successfully');  
          this.goBack();
        }
      },
      (err) => {
        // this.alert.error(errorHandler(err));
      });
  }

  goBack() {
    this._confirmService.confirm('', `Your query has been sent to our support team. We will get back to you as soon as possible. Thank you.`,
      'Go To Dashboard', 'Okay')
      .then((confirmed) => {
        if (confirmed) {
          this.location.back();
        } else {
          this.contactForm.controls['subject'].reset();
          this.contactForm.controls['message'].reset();
          this.submited = false;
        }
      })
      .catch(() => {

      });
  }

}
