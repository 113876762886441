<div class="content-area ui-content-wrapper">
  <div class="main-wrapper text-center">
    <div class="d-flex align-items-center justify-content-center">
      <h4 i18n class="d-flex align-items-center">
        <icon size="24px" name="insights" theme="outlined" type="material" class="d-flex blue mr-16" cuClass="d-flex">
        </icon>
        Market Rate
      </h4>
    </div>
    <p class="pt-0 p-16">What salaries are candidates seeing for positions like this?</p>
    <img src="./assets/images/market-rate-chat.svg" alt="market-rate" class="img-fluid">
    <div class="chat mb-8">
      <ul>
        <li>Min:{{rateCard?.range_min}}</li>
        <li class="p-38">Mean : ${{rateCard?.range_max - rateCard?.range_min}}</li>
        <li>Max:{{rateCard?.range_max}}</li>
      </ul>
    </div>

    <div class="no-gutters row mt-8 mb-8 text-left">
      <div class="col">
        <div class="card card-info">
          <h4>{{selectedTemplate?.title?.title}} (Level {{selectedTemplate?.level}})</h4>
          <p class="d-flex align-items-center align-items-center">
            <i class="d-flex material-icons mr-8" style="font-size:18px;"> domain </i> <span class="d-flex"> United
              States of
              America</span>

            <div class="pt-8">Your Rate <span class=" pl-16 lead">${{rateCard?.salary/(2000 * 40)}}</span></div>
        </div>
      </div>
    </div>


    <div class="no-gutters row mt-8 text-left">
      <div class="col mr-8">
        <div class="card card-light">
          <h4>{{rateCard?.supply}}</h4>
          <span class="small">Supply</span>

        </div>
      </div>

      <div class="col">
        <div class="card card-light">
          <h4>{{rateCard?.demand}}</h4>
          <span class="small">Demand</span>
        </div>
      </div>
    </div>

    <div class="no-gutters row mt-8 text-left">
      <div class="col mr-8">
        <div class="card card-light">
          <h4>{{rateCard?.posting_duration}}</h4>
          <span class="small">Posting Duration</span>

        </div>
      </div>

      <div class="col">
        <div class="card card-light">
          <h4>${{rateCard?.salary/(2000 * 40)}}</h4>
          <span class="small">Market Hourly Billing </span>
        </div>
      </div>
    </div>

    <div class="no-gutters row mt-8 text-left">
      <div class="col mr-8">
        <div class="card card-light">
          <h4>${{rateCard?.salary}}</h4>
          <span class="small">Yearly Estimated Salary</span>

        </div>
      </div>

      <div class="col">

      </div>
    </div>
