<div class="page-container dashboard-wrap">
  <div class="">
    <div *ngIf="!user?.is_candidate">
      <!-- pending actions -->
      <div class="dashboard-right">
        <div class="panel panel-light  no-border mb-24 overflow-hidden">
          <div class="panel-head border-line border-bottom action-heading pt-8 pb-8">
            <div class="d-flex justify-content-between">
              <span class="d-flex justify-content-start">
                <icon size="24px" name="auto_graph" type="material" cuClass="d-flex" class="d-flex pt-2 mr-12 text-red">
                </icon>
                <span class="text-red font-weight-medium">Pending Actions</span>
              </span>
              <span class="d-flex justify-content-end">
                <icon size="19px" name="notifications_active" type="material" cuClass="d-flex"
                  class="d-flex mt-0 p-8 badge_Success"></icon>
              </span>
            </div>
          </div>

          <div class="panel-body">
            <div class="panel-content pb-0 overflow-hidden">
              <div class="panel-item has-hover pt-4 pb-4" *ngFor="let p of [1,2,3,4]" [hidden]="!dataLoader">
                <div class="py-12">
                  <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
                    [theme]="{'width': '70%', height: '10px', 'border-radius':'0px', 'margin-bottom':'0', 'background-color': '#FCF8FF', 'border-top': '0'}">
                  </ngx-skeleton-loader>
                </div>
              </div>

              <div class="panel-item has-hover pt-4 pb-4" *ngFor="let p of pending">
                <div class="d-flex justify-content-between py-12 cursor-pointer" (click)="navigateAction(p?.link)">
                  <p class="dark-color">{{p?.label}}</p>
                  <h4 class="mb-0 text-black" [ngClass]="{'highlight' : p?.count}">{{p?.count || 0}}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- pending actions -->

        <!-- head count -->
        <div class="panel panel-light no-border mb-16" *ngIf="!donutChartData && dataLoader">
          <div class="panel-head border-bottom">
            <div class="d-flex align-items-center justify-content-start">
              <icon size="24px" name="language" type="material" cuClass="d-flex" class="d-flex pt-2 mr-8"
                color="#5261FF">
              </icon>
              <span class="text-blue-color font-weight-medium">Headcount by Location</span>
            </div>
          </div>
          <div class="panel-body">
            <div class="panel-content">
              <div class="text-center mt-32">
                <ngx-skeleton-loader count="1" animation="progress" appearance="circle"
                  [theme]="{'width': '210px', height: '210px', 'background-color': '#FCF8FF', 'border-top': '0'}">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
                  [theme]="{'width': '80%', height: '20px', 'margin-top': '40px', 'margin-bottom': '40px', 'background-color': '#FCF8FF', 'border-top': '0'}">
                </ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-light no-border mb-16" *ngIf="isHeadCountChart">
          <div class="panel-head border-bottom">
            <div class="d-flex align-items-center justify-content-start">
              <icon size="24px" name="language" type="material" cuClass="d-flex" class="d-flex pt-2 mr-8"
                color="#5261FF">
              </icon>
              <span class="text-blue-color font-weight-medium">Headcount by Location</span>
            </div>
          </div>
          <div class="panel-body">
            <div class="panel-content">
              <div class="text-center mt-32">
                <h4 class="m-32 p-32" *ngIf="!donutChartData || donutChartData.length === 0">No headcount data present</h4>
                <svms-doughnut-chart *ngIf="donutChartData" [data]="donutChartData" value='headcount'></svms-doughnut-chart>
              </div>
            </div>
          </div>
        </div>
        <!-- head count -->
      </div>
      <div class="dashboard-left">
        <div class="row">

          <div class="col-3 db-widgets" *ngFor="let w of [1,2,3,4]" [hidden]="!dataLoader">
            <div
              class="panel mb-24 panel-light no-border p-12 d-flex flex-column justify-content-between cursor-pointer h-100">
              <div class="d-flex">
                <h2 class="font-weight-medium mt-8">
                  <span>
                    <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
                      [theme]="{'width': '50%', height: '10px', 'border-radius':'0px', 'margin-bottom':'0', 'background-color': '#FCF8FF', 'border-top': '0'}">
                    </ngx-skeleton-loader>
                  </span>
                </h2>
                <ngx-skeleton-loader count="1" animation="progress" appearance="circle"
                  [theme]="{'width': '42px', height: '42px',  'margin-bottom':'0', 'background-color': '#FCF8FF', 'border-top': '0'}">
                </ngx-skeleton-loader>
              </div>
              <p class="dashbaord-widget-title">
                <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
                  [theme]="{'width': '70%', height: '10px', 'border-radius':'0px', 'margin-bottom':'0', 'background-color': '#FCF8FF', 'border-top': '0'}">
                </ngx-skeleton-loader>
              </p>
            </div>
          </div>

          <div class="col-3 db-widgets" *ngFor="let w of widgets" [hidden]="w?.category != 'quick_link'">
            <!-- Assignment -->
            <div [hidden]="w?.name != 'create_new_assignment'" routerLink="/assignment/create"
              class="mb-24 cursor-pointer panel panel-blue no-border p-12 text-center align-items-center justify-content-center h-100 create-for-client">
              <div class="position-relative flex-column justify-content-center">
                <div class="align-items-center d-flex justify-content-center">
                  <div class="ripple-circle cursor-pointer">
                    <span class="circle1"></span>
                    <span class="circle2"></span>
                  </div>
                  <div class="position-absolute">
                    <icon size="16px" name="add" type="material" color="white"></icon>
                  </div>
                </div>
                <p tooltip="New Assignment" placement="bottom" delay="100"
                  class="font-weight-regular pt-8 light outline_none create-text" routerLink="/assignment/create">Create
                  New
                  Assignment</p>
              </div>
            </div>
            <!-- end -->
            <div *ngIf="w?.name != 'create_new_assignment'"
              class="panel mb-24 panel-light no-border p-12 d-flex flex-column justify-content-between cursor-pointer h-100"
              (click)="changeRoute(w)">
              <div class="d-flex"
                [ngClass]="{'justify-content-center': w?.name === 'contact_support_widget','justify-content-between':w?.name != 'contact_support_widget'}">
                <span *ngIf="!countLoader" [hidden]="w?.name === 'contact_support_widget'">
                  <ngx-skeleton-loader count="1" animation="progress" appearance="circle"
                    [theme]="{'width': '42px', height: '42px',  'margin-bottom':'0', 'background-color': '#FCF8FF', 'border-top': '0'}">
                  </ngx-skeleton-loader>
                </span>
                <h2 class="font-weight-medium mt-8">
                  <span *ngIf="countLoader" [hidden]="w?.name === 'contact_support_widget'">
                    {{w?.count || 0}}
                  </span>
                </h2>
                <span class="initials">
                  <span class="icon-bg" [style.background-color]="w?.color"></span>
                  <icon size="26px" color="{{w?.color}}" name="{{w?.icon || 'support_agent'}}" type="material"
                    cuClass="d-flex" class="flex">
                  </icon>
                </span>
              </div>
              <p class="dashbaord-widget-title" tooltip="{{w?.label}}" placement="bottom" delay="100"
                [ngClass]="{'text-center': w?.name === 'contact_support_widget'}">{{w?.label}}</p>
            </div>
          </div>
        </div>
        <!-- calender -->
        <div class="panel panel-light no-border">
          <ngx-skeleton-loader *ngIf="events && events.length == 0 && dataLoader" count="1" animation="progress" appearance="lines"
            [theme]="{'width': '100%', height: '500px','margin-bottom': '10px', 'border-radius':'0px', 'background-color': '#FCF8FF', 'border-top': '0'}">
          </ngx-skeleton-loader>

          <div class="panel-body p-24 pt-16 pb-0 calender-ui" *ngIf="events && events.length == 0 && dataLoader"
            style="min-height: 1040px">

            <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
              [theme]="{'width': '100%', height: '10px','margin-bottom': '20px', 'border-radius':'0px', 'background-color': '#FCF8FF', 'border-top': '0'}">
            </ngx-skeleton-loader>
          </div>
          <div class="panel-body d-flex align-items-center p-24 pt-16 pb-0 calender-ui"
            *ngIf="events && events.length>0">
            <ngx-fullcalendar defaultView="month" [events]="events" (onEventClick)="loadData($event)"
              [options]="options" class="ml-auto count_color">
            </ngx-fullcalendar>
          </div>

        </div>
        <!-- calender -->
      </div>

    </div>
    <div *ngIf="user?.is_candidate">
      <div class="dashboard-right">
        <div class="row">
          <div class="col">
            <div class="panel panel-light mb-24 p-16 cursor-pointer side-panel-button border-0"
              routerLink="/timesheet/list">
              <div class="text-center d-flex align-items-center justify-content-center child-container">
                <div class="text-center">
                  <div class="min-height d-flex">
                    <span class="circle_04">
                      <icon size="32px" name="view_stream" theme="outlined" type="material" color="#515589"></icon>
                    </span>
                  </div>
                  <p class="show_single">View All Timesheet(s)</p>
                </div>
              </div>
            </div>

          </div>
          <div class="col">
            <div class="panel panel-blue  no-border mb-24 p-16 cursor-pointer side-panel-button"
              (click)="onCreateClick()">
              <div class="text-center d-flex align-items-center justify-content-center child-container">
                <div class="position-relative d-flex flex-column justify-content-center">
                  <div class="d-flex align-items-center justify-content-center min-height">
                    <div class="ripple-circle cursor-pointer">
                      <span class="circle1"></span>
                      <span class="circle2"></span>
                    </div>
                    <div class="position-absolute">
                      <icon color="white" size="32px" name="more_time" type="material"></icon>
                    </div>
                  </div>
                  <p class="light"> Add Timesheet</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-light no-border  mb-16">
          <div class="panel-head border-bottom">
            <div class="d-flex justify-content-start align-items-center">
              <icon size="24px" name="access_alarm" type="material" cuClass="d-flex" class=" pt-2 mr-8 text-black">
              </icon>
              <span class="font-weight-medium">Timesheet(s) to Submit </span>
            </div>

          </div>
          <div class="panel-body">
            <div class="panel-content">
              <p class="my-24  text-center" *ngIf="draft_timesheet && draft_timesheet?.length === 0 && !dataLoader">
                Great job - it looks like you're all caught up.</p>
              <div class="panel-item" *ngFor="let time of [1,2]" [hidden]="!dataLoader">
                <div>
                  <div class="my-8">
                    <p class="font-weight-medium">
                      <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
                        [theme]="{'width': '70%', height: '10px', 'border-radius':'0px', 'margin-bottom':'0', 'background-color': '#FCF8FF', 'border-top': '0'}">
                      </ngx-skeleton-loader>
                    </p>

                  </div>
                </div>
              </div>
              <div class="panel-item" *ngFor="let time of draft_timesheet">
                <div class="d-flex justify-content-between align-items-center cursor-pointer"
                  (click)="showTimesheet(time)">
                  <div class="d-flex flex-column my-8">
                    <p class="font-weight-medium">
                      {{time?.start_date | date: 'MMM yyyy'}}
                    </p>
                    <div>
                      <p class="small text-truncate w-180"
                        tooltip="{{time?.assignment_title}} ({{shortAssignmentId(assignmentDetails?.assignment?.code)}})"
                        placement="bottom" delay="100">
                        <span class=""> {{time?.assignment_title}}
                          ({{shortAssignmentId(assignmentDetails?.assignment?.code)}}) </span>
                      </p>
                      <!-- <span class=""><span class="text-gray">Hours: </span>
                        <span>{{ time?.total_hours || '00:00'}} </span></span> -->
                    </div>
                  </div>
                  <div class="status-ui draft">
                    {{time?.status || 'missing'}}
                    <!-- Draft -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="panel panel-light no-border mb-16">
          <div class="panel-head border-bottom">
            <div class="d-flex justify-content-start align-items-center">
              <icon size="24px" name="access_alarm" type="material" cuClass="d-flex" class=" pt-2 mr-8 text-red">
              </icon>
              <span class="text-red font-weight-medium">Rejected Timesheet(s)</span>
            </div>

          </div>
          <div class="panel-body">
            <div class="panel-content">
              <p class="my-24 text-center"
                *ngIf="rejected_timesheet && rejected_timesheet?.length === 0 && !dataLoader">Great job - it looks like
                you're all caught up.
              </p>
              <div class="panel-item" *ngFor="let time of [1,2]" [hidden]="!dataLoader">
                <div>
                  <div class="my-8">
                    <p class="font-weight-medium">
                      <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
                        [theme]="{'width': '70%', height: '10px', 'border-radius':'0px', 'margin-bottom':'0', 'background-color': '#FCF8FF', 'border-top': '0'}">
                      </ngx-skeleton-loader>
                    </p>

                  </div>
                </div>
              </div>
              <div class="panel-item" *ngFor="let time of rejected_timesheet">

                <div class="d-flex justify-content-between align-items-center cursor-pointer"
                  (click)="showTimesheet(time)">
                  <div class="d-flex flex-column my-8">
                    <p>
                      {{time.start_date | date: 'MMM yyyy'}}
                    </p>
                    <div>
                      <p class="small text-truncate w-180"
                        tooltip="{{time?.assignment_title}} ({{shortAssignmentId(assignmentDetails?.assignment?.code)}})"
                        placement="bottom" delay="100">
                        <span class=""> {{time?.assignment_title}}
                          ({{shortAssignmentId(assignmentDetails?.assignment?.code)}})</span>
                      </p>
                      <!-- <span class=""><span class="text-gray">Hours: </span>
                        <span>{{ time?.total_hours || '00:00'}} </span></span> -->
                    </div>
                  </div>
                  <div class="status-ui rejected">
                    Rejected
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-left">
        <div class="panel panel-light no-border mb-24">
          <div class="panel-body">
            <div class="panel-content p-12">
              <div class="d-flex align-items-start justify-content-between mt-12">
                <div class="d-flex align-items-start">
                  <div class="mr-32 position-relative info profile-img">

                    <img class="img_rounded img-border" *ngIf="user?.avatar" src="{{user?.avatar}}" alt="">
                    <div class="avatar-initial rounded-circle text-capitalize" *ngIf="!user?.avatar">{{user?.initials}}</div>
                    <div class="initials initials-white edit-icon cursor-pointer d-none">
                      <icon size="16px" name="edit" color="#4588e5" class="d-flex" cuClass="d-flex">
                      </icon>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <p class="lead text-dark font-weight-medium user-name text-capitalize">
                      {{user?.full_name}}
                      <span class="status-ui active ml-12"> {{assignmentDetails?.assignment?.status}} </span>
                    </p>
                    <span class="text-gray d-flex align-items-center mt-4">
                      <icon class="d-flex text-gray mr-8" name="place" size="14px" color="#546E7A" cuCalss="d-flex"
                        type="material" theme="outlined">
                      </icon>
                      <span *ngIf="dataLoader">
                        <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
                          [theme]="{'width': '200px', height: '12px', 'margin-bottom':'0', 'background-color': '#FCF8FF', 'border-top': '0'}">
                        </ngx-skeleton-loader>
                      </span>
                      <span *ngIf="!dataLoader">
                        {{assignmentDetails?.assignment?.work_location?.name ||
                        assignmentDetails?.assignment?.work_location || '-'}}
                      </span>
                      <!-- New Jersey, USA -->
                    </span>
                    <div class="mt-8"
                      *ngIf="assignmentDetails?.assignment?.start_date && assignmentDetails?.assignment?.end_date">
                      Assignment Duration: {{assignmentDetails?.assignment?.start_date | date: 'mediumDate'}} -
                      {{assignmentDetails?.assignment?.end_date | date: 'mediumDate'}}
                    </div>

                  </div>
                </div>
                <div>
                  <button type="submit" class="btn btn-outline-primary rounded-pill small edit-btn d-none"
                    #actionButton>
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="panel-footer no-border mt-24">
            <div class="information">
              <div class="float-left info">
                <div class="initials initials-light mr-16">
                  <icon class="d-flex" size="18px" name="mail" type="material" theme="outlined" cuClass="d-flex">
                  </icon>
                </div>
                <div class="float-left info-text">
                  <p class="mb-0 small">Official Email</p>
                  <span *ngIf="dataLoader">
                    <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
                      [theme]="{'width': '100%', height: '10px', 'border-radius':'0px', 'margin-bottom':'0', 'background-color': '#ece8ef', 'border-top': '0'}">
                    </ngx-skeleton-loader>
                  </span>
                  <span *ngIf="!dataLoader" tooltip="{{assignmentDetails?.worker?.official_email || '-'}}"
                    placement="bottom" delay="100">{{assignmentDetails?.worker?.official_email || '-'}}</span>
                  <!-- <span>tejal@simplifyvms.com</span> -->
                </div>
              </div>
              <div class="float-left  info">
                <div class="initials initials-green  mr-16">
                  <icon class="d-flex" size="18px" name="label" type="material" theme="outlined" cuClass="d-flex ">
                  </icon>
                </div>
                <div class="float-left info-text">
                  <p class="mb-0 small">Worker ID</p>
                  <span *ngIf="dataLoader">
                    <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
                      [theme]="{'width': '100%', height: '10px', 'border-radius':'0px', 'margin-bottom':'0', 'background-color': '#ece8ef', 'border-top': '0'}">
                    </ngx-skeleton-loader>
                  </span>
                  <span *ngIf="!dataLoader" tooltip="{{assignmentDetails?.worker?.official_worker_id || '-' }}"
                    placement="bottom" delay="100">{{assignmentDetails?.worker?.official_worker_id || '-'}}</span>
                </div>
              </div>
              <div class="float-left  info">
                <div class="initials initials-purple  mr-16">
                  <icon class="d-flex" size="18px" name="assignment" type="material" theme="outlined" cuClass="d-flex">
                  </icon>
                </div>
                <div class="float-left info-text">
                  <p class="mb-0 small">Assignment</p>
                  <span *ngIf="dataLoader">
                    <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
                      [theme]="{'width': '100%', height: '10px', 'border-radius':'0px', 'margin-bottom':'0', 'background-color': '#ece8ef', 'border-top': '0'}">
                    </ngx-skeleton-loader>
                  </span>
                  <span *ngIf="!dataLoader"
                    tooltip="{{assignmentDetails?.assignment?.assignment_title?.name || '-'}} {{shortAssignmentId(assignmentDetails?.assignment?.code)}}"
                    placement="bottom" delay="100"
                    class="text-blue">{{assignmentDetails?.assignment?.assignment_title?.name || '-'}} <span
                      *ngIf="assignmentDetails?.assignment?.code">({{shortAssignmentId(assignmentDetails?.assignment?.code)}})</span></span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="panel panel-light  no-border">
          <div class="panel-body p-24 pt-16 pb-0 calender-ui" *ngIf="events && events.length == 0 && dataLoader"
            style="min-height: 1040px">
            <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
              [theme]="{'width': '70%', height: '10px','margin-bottom': '10px', 'border-radius':'0px', 'background-color': '#FCF8FF', 'border-top': '0'}">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="lines"
              [theme]="{'width': '100%', height: '10px','margin-bottom': '20px', 'border-radius':'0px', 'background-color': '#FCF8FF', 'border-top': '0'}">
            </ngx-skeleton-loader>
          </div>
          <div class="panel-body d-flex align-items-center p-24  pt-16 pb-0 calender-ui"
            *ngIf="events && events.length>0">
            <ngx-fullcalendar defaultView="month" [events]="events" [options]="options" class="ml-auto count_color">
            </ngx-fullcalendar>
            <!-- <ngx-fullcalendar defaultView="month" [events]="events" [options]="options" class="ml-auto count_color">
            </ngx-fullcalendar> -->
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>