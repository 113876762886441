<!--File Upload Sction -->
<form [formGroup]="orgCreate">
  <div class="Upload_box_wrapper ">
    <svms-upload-avatar [image]="orgCreate?.controls?.logo?.value" [ngClass]="{'disable':isViewClicked}" (hideError)="getCropImage($event)"></svms-upload-avatar>
    <div class="upload-panel-body">
      <h5 *ngIf="!logoComponent?.logoUrl || !isImagePresent">MSP Logo</h5>
      <div *ngIf="(!logoComponent?.logoUrl || !isImagePresent) && !isViewClicked" class="error-text">Only .PNG, .JPG formats with maximum
        file size of 3 MB</div>
    </div>

    <div class="toggle-panel" [ngClass]="{'disable':isViewClicked}">
      <div class="toggle-panel-inner" [ngClass]="toggle?.value ? 'if-active' : 'if-inactive'">
        <span class="status-text">{{toggle?.title}}</span>
        <span class="toggle-switch" [ngClass]="toggle?.value ? 'toggle-on' : 'toggle-off'"
          (click)="onClickToggle()"></span>
      </div>
    </div>
  </div>
  <!--File Upload Sction -->
  <div class="form-group">
    <label>MSP Name<span class="require">*</span></label>
    <input type="text" class="form-control" placeholder="MSP Organization Name" formControlName="org_name" required [readonly]= "isViewClicked"
      [ngClass]="{'error':
  orgCreate.controls['org_name'].invalid && (orgCreate.controls['org_name'].dirty || orgCreate.controls['org_name'].touched)}" minlength="2">
    <p class="validation-msg"
      *ngIf="orgCreate.controls.org_name.touched && orgCreate.controls['org_name'].invalid && orgCreate.controls['org_name'].errors?.required">
      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> MSP Name is
      Required
    </p>
    <p class="validation-msg" *ngIf="orgCreate.controls.org_name.errors?.minlength">
      MSP Name must be at least 2 characters long.
    </p>
    <p class="validation-msg"  *ngIf="!isNameValid">{{duplicateOrg}}</p>
  </div>

  <div class="form-group">
    <label>Organization Type <span class="require">*</span></label>
    <input type="text" class="form-control" placeholder="MSP" formControlName="org_type" readonly>
  </div>

  <div class="form-group">
    <label>Industry <span class="require">*</span></label>
    <ng-select bindLabel="name" [clearable]="false" placeholder="Industry" [multiple]=" true" ngDefaultControl
      formControlName="industry" [readonly]="isViewClicked">
      <ng-option [value]="data.id" *ngFor="let data of industries">{{data.name}}</ng-option>
    </ng-select>
    <p class="validation-msg" *ngIf="orgCreate.controls.industry.touched && orgCreate.controls['industry'].errors">
      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Industry is
      Required
    </p>
  </div>
</form>