import { Component, ElementRef,Output,EventEmitter, OnInit, ViewChild, Input } from '@angular/core';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { LoggerService } from 'src/app/core/services/logger.service';
import { EventStreamService } from '../../../core/services/event-stream.service';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'svms-upload-avatar',
  templateUrl: './svms-upload-avatar.component.html',
  styleUrls: ['./svms-upload-avatar.component.scss']
})
export class SvmsUploadAvatarComponent implements OnInit {
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public canvasRotation = 0;
  public rotation = 0;
  public scale = 1;
  public showCropper = false;
  public containWithinAspectRatio = false;
  public transform: ImageTransform = {};
  public imageLoad: boolean;
  public fileName: any;
  public logoUrl: any;
  public logoValidations: any;
  public logoSize: any;
  @Output() hideError = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;
  _image: any;
  @Input() set image(data) {
    if(data){
      this._image = data;
      this.showCropper = true;
      this.croppedImage = this._image;
      this.logoUrl = this.croppedImage;
    }
  }
  get image() {
    return this._image;
  }
  constructor(
    public eventStream: EventStreamService,
    private _alertService: AlertService) {
    }

  ngOnInit(): void {
   }
  private isValidImageType(type: string): boolean {
    return /image\/(png|jpg|jpeg)/.test(type);
  }

  fileChangeEvent(event: any): void {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageType = file.type;
      if ((file.size/1028/1028) > 3) {
        this.imageLoad = false; 
        this._alertService.error('Logo size should not exceed more than 3 MB', {}); 
      } else {
        if (this.isValidImageType(imageType)) {
          this.imageChangedEvent = event;
          this.fileName = event.target.files[0]?.name;
          this.imageLoad = true; 
        } else {
          this.loadImageFailed();
          event.target.value = '';
        }
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64; 
    const validations = base64ToFile(event.base64);
    if (validations.type === 'image/png' || validations.type === 'image/jpg') {

    }    

    this.logoUrl = this.croppedImage;
    this.hideError.emit(this.logoUrl);
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded: ' + this.showCropper);
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    this._alertService.error('Invalid Format uploaded, Only .PNG, .JPG formats supported', {});
    this.imageLoad = false;
    this.imageChangedEvent = '';
  }

  crop() { 
    this.imageLoad = false;
    this.fileInput.nativeElement.value = '';
    this.hideError.emit(this.croppedImage);
  }

  cancelUpload() {
    this.croppedImage = null;
    this.hideError.emit(this.croppedImage);
    this.imageChangedEvent = '';
    this.imageLoad = false;
    this.fileInput.nativeElement.value = '';

  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

}
