
    <div class="grid-stack">
        <div class="grid-stack-item" gs-w="6">
            <div class="grid-stack-item-content">
                <ngx-fullcalendar defaultView="month" [events]="events" [options]="options"></ngx-fullcalendar>
            </div>
            
        </div>
    </div>

<!-- <div class="grid-stack">
    <div class="grid-stack-item">
      <div class="grid-stack-item-content">Item 1</div>
    </div>
    <div class="grid-stack-item" gs-w="2">
      <div class="grid-stack-item-content">Item 2 wider</div>
    </div>
  </div> -->