import { Component, OnInit, Input } from '@angular/core';
import { VMSConfig } from 'src/app/library/table/table/table.model';
import {
  EventStreamService,
  Events,
  EmitEvent,
} from 'src/app/core/services/event-stream.service';

@Component({
  selector: 'app-job-assignments',
  templateUrl: './job-assignments.component.html',
  styleUrls: ['./job-assignments.component.scss']
})
export class JobAssignmentsComponent implements OnInit {
  public currentAssignments;
  @Input() currentColumnData;
  public vmsData: any;
  public tableConfig: VMSConfig;
  public isExpand = false;
  public totalPages = 1;
  public totalRecords = 3;
  public itemsPerPage: any;
  public tableLoaded = false;
  public editData: any;
  public viewData: any;
  public users: any;
  public viewclick: any;
  dataLoading = true;
  currentAssignmet = true;
  submittedJob = false;
  sidebarTitle = "Job Assignments";
  constructor(private _eventStream: EventStreamService) { }

  ngOnInit(): void {
    this._eventStream.on(Events.VIEW_CANDIDATE_ASSIGNMENT_AND_JOBS, (data) => {
      if (data) {
        // console.log("Emitted request true", data);
        // console.log("::::::::::::::",data);
        this.currentAssignments = 'visible';
      } else {
        this.currentAssignments = 'hidden';
        // console.log("Emitted request false", this.currentColumnData);
      }
    });
    this.tableConfig = {
      title: 'Candidate List',
      columnList: [
        { name: 'job_title', title: 'Job Title', width: 30, isIcon: false, isImage: true, isContact: false, isNumberBadge: false },
        { name: 'job_id', title: 'Job ID', width: 25, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
        { name: 'location', title: 'Location', width: 25, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
        { name: 'rate', title: 'Rate', width: 20, isIcon: false, isImage: false, isContact: false, isNumberBadge: false }

      ],
      isDownload: false,
      isExpand: false,
      isFilter: false,
      isSearch: false,
      isSetting: false,
      isTopPagination: false,
      isCreate: false,
      density: 'COMFORTABLE'
    };

    this.listOfjobs();

    this.tableLoaded = true;
    this.dataLoading = false;
  }
  sidebarClose() {
    this._eventStream.emit((new EmitEvent(Events.VIEW_CANDIDATE_ASSIGNMENT_AND_JOBS, false)));
    this.currentAssignments = "hidden";
  }

  listOfjobs() {
    if (this.currentAssignmet) {
      this.vmsData = {
        "candidate": [
          {
            "job_title": "Registered Nurse",
            "job_id": "JB215",
            "location": "Austin,Texas",
            "rate": "$49.00"
          },
          {
            "job_title": "Registered Nurse",
            "job_id": "JB215",
            "location": "Austin,Texas",
            "rate": "$45.00"
          }
        ]
      }

      this.totalPages = 1;
      this.totalRecords = 2;
      this.itemsPerPage = 10;
    }
    else if (this.submittedJob) {
      this.vmsData = {
        "candidate": [
          {
            "job_title": "Registered Nurse",
            "job_id": "JB500",
            "location": "Austin,Texas",
            "rate": "$109.00"
          },
          {
            "job_title": "Registered Nurse",
            "job_id": "JB601",
            "location": "Austin,Texas",
            "rate": "$60.00"
          },
          {
            "job_title": "Registered Nurse",
            "job_id": "JB621",
            "location": "Austin,Texas",
            "rate": "$160.00"
          }
        ]
      }

      this.totalPages = 1;
      this.totalRecords = 3;
      this.itemsPerPage = 10;
    }
  }

  submittedJobsList() {
    this.submittedJob = true;
    this.currentAssignmet = false;
    this.sidebarTitle = "Submitted Jobs";
    this.listOfjobs()
  }

  currentAssignmentList() {
    this.currentAssignmet = true;
    this.submittedJob = false;
    this.sidebarTitle = "Job Assignments"
    this.listOfjobs()
  }
}
