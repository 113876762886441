<div class="insights px-24 py-16">
  <div class="insights-header d-flex align-items-center justify-content-between">
    <span class="subtitle-2 font-weight-medium">Insights</span>
    <icon size="19px" name="close" theme="outlined" type="material" class="d-flex mr-4 cursor-pointer" cuClass="d-flex"
      (click)="onInsightClick()">
    </icon>
  </div>
  <div class="insights-content mt-24">
    <div class="bg-light p-24 shadow">
      <div class="d-flex align-items-center">
        <img src="../../../../assets/images/diagram.svg" class="img-fluid" width="30">
        <span class="subtitle-2 ml-12 font-weight-medium text-dark" (click)="createHierarchy()">Create Hierarchy</span>
      </div>
      <div class="text-secondary my-24">
        Make Sure you setup hierarchy For IntelCW program
      </div>
      <a class="d-flex align-items-center justify-content-between" style="cursor: pointer" (click)="createHierarchy()">
        <span>Create New Hierarchy</span>
        <icon size="19px" name="keyboard_arrow_right" theme="outlined" type="material" class="d-flex mr-4"
          cuClass="d-flex"></icon>
      </a>
    </div>
    <div class="bg-light mt-16 p-24 shadow">
      <div class="d-flex align-items-center">
        <img src="../../../../assets/images/Group_2.svg" class="img-fluid" width="30">
        <span class="subtitle-2 ml-12 font-weight-medium text-dark">Setup Users & Roles</span>
      </div>
      <div class="text-secondary my-24">
        Make Sure you setup hierarchy For IntelCW program
      </div>
      <a class="d-flex align-items-center justify-content-between " style="cursor: pointer;">
        <span (click)="createUser()">Add Users to Program</span>
        <icon size="19px" name="keyboard_arrow_right" theme="outlined" type="material" class="d-flex mr-4"
          cuClass="d-flex"></icon>
      </a>
    </div>
    <div class="bg-light mt-16 p-24 shadow">
      <div class="d-flex align-items-center">
        <img src="../../../../assets/images/time-management.svg" class="img-fluid" width="30">
        <span class="subtitle-2 ml-12 font-weight-medium text-dark">Work Flows</span>
      </div>

      <div class="text-secondary my-24">
        Make Sure you setup hierarchy For IntelCW program
      </div>
      <a class="d-flex align-items-center justify-content-between" (click)="createUser()" style="cursor: pointer">
        <span>Add Users to Program</span>
        <icon size="19px" name="keyboard_arrow_right" theme="outlined" type="material" class="d-flex mr-4"
          cuClass="d-flex"></icon>
      </a>
    </div>
  </div>
</div>
