import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FilterPipe } from './components/filter/filter';
import { HeaderComponent } from './components/header/header.component';
import { IconComponent } from './components/icon/icon.component';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { ModulesComponent } from './components/modules/modules.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ProgramManageComponent } from './components/program-manage/program-manage.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { SearchAddressComponent } from './components/search-address/search-address.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SvmsDatepickerComponent } from './components/svms-datepicker/svms-datepicker.component';
import { SvmsSidebarBodyComponent } from './components/svms-sidebar/svms-sidebar-body/svms-sidebar-body.component';
import { SvmsSidebarExtendedComponent } from './components/svms-sidebar/svms-sidebar-extended/svms-sidebar-extended.component';
import { SvmsSidebarFooterComponent } from './components/svms-sidebar/svms-sidebar-footer/svms-sidebar-footer.component';
import { SvmsSidebarComponent } from './components/svms-sidebar/svms-sidebar.component';
import { AddressContactDetailsComponent } from './components/svms-tab-components/address-contact-details/address-contact-details.component';
import { BasicInfoComponent } from './components/svms-tab-components/basic-info/basic-info.component';
import { HierarchyComponent } from './components/svms-tab-components/hierarchy/hierarchy.component';
import { PermissionsComponent } from './components/svms-tab-components/permissions/permissions.component';
import { SvmsUploadAvatarModule } from './components/svms-upload-avatar/svms-upload-avatar.module';
import { TabsModule } from './components/tabs/tabs.module';
import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';
import { DebounceDirective } from './directives/debounce.directive';
import { I18nDirective } from './directives/i18n.directive';
import { PasswordToggleDirective } from './directives/password-toggle.directive';
import { ResponsiveMenuDirective } from './directives/responsive-menu.directive';
import { ResumeUploadComponent } from './components/svms-tab-components/resume-upload/resume-upload.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { AuthorizeDirective } from './directives/validate-permission.directive';
import { FullNamePipe } from './fullname.pipe';
import { InputDateComponent } from './input-date.component';
import { ShortNamePipe } from './pipe/short-name.pipe';
import { AddressService } from './service/utility/address.service';
import { HighlightDirective } from './directives/highlight.directive';
import { ChunkPipe } from './pipe/chunk.pipe';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { CustomcurrencyPipe } from './pipe/customcurrency.pipe';
import { RemovePrefixSuffixPipe } from './pipe/remove-prefix-suffix.pipe';
import { LocalDateFormatPipe } from './pipe/local-date-format.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { AwsS3FileUploadService } from './service/utility/aws.s3.upload.service';
import { ButtonLoaderComponent } from './components/button-loader/button-loader.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

const declarables = [
  HeaderComponent,
  SidebarComponent,
  NotificationComponent,
  FullNamePipe,
  InputDateComponent,
  LangSwitcherComponent,
  ThemeSwitcherComponent,
  IconComponent,
  PasswordToggleDirective,
  DragAndDropDirective,
  AuthorizeDirective,
  DebounceDirective,
  SvmsDatepickerComponent,
  ShortNamePipe,
  ChunkPipe,
  I18nDirective,
  SvmsSidebarComponent,
  SvmsSidebarBodyComponent,
  SvmsSidebarFooterComponent,
  SvmsSidebarExtendedComponent,
  SearchAddressComponent,
  TooltipDirective,
  ResponsiveMenuDirective,
  BasicInfoComponent,
  HierarchyComponent,
  ModulesComponent,
  ProgramManageComponent,
  PermissionsComponent,
  AddressContactDetailsComponent,
  ResumeUploadComponent,
  HighlightDirective,
  CustomcurrencyPipe,
  RemovePrefixSuffixPipe,
  ButtonLoaderComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SvmsUploadAvatarModule,
    TabsModule,
    ReactiveFormsModule,
    NgxQRCodeModule,
    RouterModule
  ],
  exports: [...declarables, LocalDateFormatPipe, HighlightDirective, TranslateModule, TabsModule, SvmsUploadAvatarModule, ProgramsComponent, OnlyNumberDirective],
  providers: [LocalDateFormatPipe, TranslateService, AddressService, AwsS3FileUploadService],
  declarations: [...declarables, FilterPipe, BasicInfoComponent, HierarchyComponent,
    PermissionsComponent, AddressContactDetailsComponent, ProgramsComponent, LocalDateFormatPipe, OnlyNumberDirective, QrScannerComponent]
})
export class SharedModule { }
