import {
  Component,
  LOCALE_ID,
  Inject,
  OnInit,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  EventStreamService,
  Events,
  EmitEvent,
} from '../core/services/event-stream.service';
import { StorageService } from '../core/services/storage.service';
import { UserService } from '../core/services/user.service';
import { VendorService } from '../program-setup/vendor/vendor.service';
import { AlertService } from '../shared/components/alert/alert.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  title = 'Simplify VMS 2.0';
  showBackdrop = false;
  valueEmittedFromHeaderComponent: '';
  valueEmittedFromChildComponent = '';
  toggleProgramPanel = false;
  programs: any = [];
  logged_In = false;
  currentProgrumId = '';
  user: any;

  constructor(
    private changeDetection: ChangeDetectorRef,
    public translate: TranslateService,
    private eventStream: EventStreamService,
    private _alerts: AlertService,
    private _render: Renderer2,
    private userService: UserService,
    public storageService: StorageService,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    if (localeId) {
      translate.setDefaultLang(
        localeId.split('-')[0]
          ? localeId.split('-')[0]
          : 'English (United States)'
      );
    }
  }

  ngOnInit() {
    if (this.storageService.get('CurrentProgram')) {
      this.currentProgrumId = this.storageService.get('CurrentProgram')['id']
    }

    this.eventStream.on(Events.LoggedIn, (data) => {
      this.logged_In = data;
    });

    this.eventStream.on(Events.DO_SHOW_CLIENT_FORM, (data) => {
      this.showBackdrop = data;
      if (data) {
        this._render.addClass(document.body, 'overflow-hidden');
      } else {
        this._render.removeClass(document.body, 'overflow-hidden');
      }
      this.changeDetection.detectChanges();
    });

    this.eventStream.on(Events.DO_SHOW_USER_FORM, (data) => {
      this.showBackdrop = data;
      if (data) {
        this._render.addClass(document.body, 'overflow-hidden');
      } else {
        this._render.removeClass(document.body, 'overflow-hidden');
      }
      this.changeDetection.detectChanges();
    });

    this.eventStream.on(Events.DO_SHOW_USER_FORM, (data) => {
      this.showBackdrop = data;
      if (data) {
        this._render.addClass(document.body, 'overflow-hidden');
      } else {
        this._render.removeClass(document.body, 'overflow-hidden');
      }
      this.changeDetection.detectChanges();
    });

    this.eventStream.on(Events.PROGRAM_SIDEBAR, (data) => {
      this.toggleProgramPanel = data;
    });

    this.user = this.storageService.get('user');
    this.storageService.set('ORG_ID', this.user?.organization_id, true)
    this.getMyPrograms();
  }

  togglePrograms(e) {
    this.toggleProgramPanel = !this.toggleProgramPanel;
  }

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
  }

  notificationEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromHeaderComponent = valueEmitted;
  }

  getMyPrograms() {
    let currentProgram = this.storageService.get('CurrentProgram');
    if (currentProgram === null || currentProgram === undefined) {
      this.userService.getAllPrograms().subscribe(data => {
        this.programs = data.programs;
        if (Boolean(this.programs.length)) {
          if (!this.currentProgrumId && this.programs.some(p => p.id !== this.currentProgrumId)) {
            this.storageService.set('CurrentProgram', this.programs[0], true);
            this.currentProgrumId = this.programs[0]?.id;
            this.storageService.set('PROGRAM_ID', this.currentProgrumId, true);
          } else {
            let currentProgram = this.programs.filter(p => p.id === this.currentProgrumId)[0]
            this.storageService.set('CurrentProgram', currentProgram, true);
            this.storageService.set('PROGRAM_ID', currentProgram?.id, true);
          }

          this.getMembershipDetailsAndRole();
          this.eventStream.emit(new EmitEvent(Events.PROGRAM_SIDEBAR, false));
        }
      }, err => {
        this._alerts.error('Unable to fetch your programs');
      });
    } else {
      this.storageService.set('PROGRAM_ID', currentProgram?.id, true);
      this.getMembershipDetailsAndRole();
      this.eventStream.emit(new EmitEvent(Events.PROGRAM_SIDEBAR, false));
    }
  }


  getMembershipDetailsAndRole() {
    this.userService.getMembershipDetails(this.currentProgrumId, this.user.id).subscribe(res => {
      this.storageService.set('account', res?.member, true);
      let permissionList = res?.member?.role?.permissions;
      let permissions = [];
      permissionList?.forEach(element => {
        permissions.push(element?.slug);
      });
      this.storageService.set('user_permission', permissions, true);
    }, err => {
      console.log('Not found the membership details, restricted access');
      console.log(err);
    });
  }
}
