<svms-sidebar [title]="formTitle" size="small" [dataVisibility]="isCreateCandidate" (onClose)="sidebarClose()">
  <svms-sidebar-body>
    <form [formGroup]="createCandidateForm">
      <div class="p-24">
        <div class="form-group">
          <label I18n>First Name &nbsp;<span class="require">*</span></label>
          <input type="text" formControlName="first_name" maxlength="100" class="form-control" placeholder="Enter First Name"
            [ngClass]="{ 'error': submitted && f.first_name.errors }">
          <div *ngIf="submitted && f.first_name.errors" class="validation-msg">
            <div *ngIf="f.first_name.errors.required">First Name is required</div>
          </div>
        </div>
        <div class="form-group">
          <label I18n>Last Name &nbsp;<span class="require">*</span></label>
          <input type="text" formControlName="last_name" maxlength="100" class="form-control" placeholder="Enter Last Name"
            [ngClass]="{ 'error': submitted && f.last_name.errors }">
          <div *ngIf="submitted && f.last_name.errors" class="validation-msg">
            <div *ngIf="f.last_name.errors.required">Last Name is required</div>
          </div>
        </div>
        <div class="form-group">
          <label I18n>Phone &nbsp;<span class="require">*</span></label>
          <input type="tel" onkeypress="return (event.charCode>=48 && event.charCode<=57)"
           formControlName="phone_number" class="form-control" minlength="10" maxlength="15"
            placeholder="Enter Phone" [ngClass]="{ 'error': submitted && f.phone_number.errors }">
            <div *ngIf="submitted && f.phone_number.errors" class="validation-msg">
              <div *ngIf="f.phone_number.errors.required">Phone Numbers is required</div>
              <div *ngIf="createCandidateForm?.controls?.phone_number?.errors?.minlength">Phone Numbers should have minimum 10 digit</div>
              <div *ngIf="createCandidateForm?.controls?.phone_number?.errors.maxLength">Phone Numbers should have maximum 15 digit</div>
            </div>
        </div>

        <div class="form-group">
          <label I18n>Email &nbsp;<span class="require">*</span></label>
          <input type="text" class="form-control" formControlName="email" maxlength="254"
            placeholder="Enter Email" [ngClass]="{ 'error': submitted && f.email.errors }">
          <div *ngIf="submitted && f.email.errors" class="validation-msg">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.pattern">Please enter valid Email</div>
          </div>
        </div>

        <div class="form-group">
          <label I18n>Select Vendor&nbsp;<span class="require">*</span></label>
          <ng-select [ngClass]="{ 'error': submitted && f.vendorName.errors }" (change)="updateVendorId($event)"
            formControlName="vendorName" bindLabel="name" placeholder="Select Vendor">
            <ng-option [value]="v" *ngFor="let v of vendorList.vendors">{{v?.name}}</ng-option>
          </ng-select>
          <div *ngIf="submitted && f.vendorName.errors" class="validation-msg">
            <div *ngIf="f.vendorName.errors.required">Vendor is required</div>
          </div>
        </div>
      </div>
    </form>
  </svms-sidebar-body>
  <svms-sidebar-footer>
    <button type="submit" (click)="sidebarClose()" class="btn btn-light float-left">
      Cancel
    </button>
    <button type="submit" class="btn btn-primary float-right" (click)="saveCandidate('save')">
      {{btnText}}
    </button>
  </svms-sidebar-footer>
</svms-sidebar>
