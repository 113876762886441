import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';
import {
  debounceTime, distinctUntilChanged, switchMap, tap
} from 'rxjs/operators';
import {
  EmitEvent, Events, EventStreamService
} from 'src/app/core/services/event-stream.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { errorHandler } from 'src/app/shared/util/error-handler';
import { environment } from 'src/environments/environment';
import { ClonerService } from '../../core/services/cloner.service';
import { AlertService } from '../../shared/components/alert/alert.service';
import { LoaderService } from '../../shared/components/loader/loader.service';
import { ProgramManageComponent } from '../../shared/components/program-manage/program-manage.component';
import { ProgramConfig } from '../../shared/enums';
import { ProgramService } from '../program.service';

export type ORG_TYPE = 'TYPE_MSP';
@Component({
  selector: 'app-program-create',
  templateUrl: './program-create.component.html',
  styleUrls: ['./program-create.component.scss'],
})
export class ProgramCreateComponent implements OnInit, OnDestroy {

  public showCreate = false;
  public showUserCreate = false;
  public addUser = false;
  public inviteUser = false;
  public inviteUserName: string;
  private now = Date.now();
  private days = 24 * 60 * 60 * 1000;
  public usernameOldValue: string;
  public clientList = [];
  public mspList = [];
  public input$ = new Subject<string>();
  public mspInput$ = new Subject<string>();
  public programsid: string;
  public clientInfo: any;
  public userRoles: any;
  public Users: any;
  public program_uniqId: string;
  public clientId: string;
  public createProgramForm: FormGroup;
  public duplicateProgram: any;
  public validationMsg = undefined;
  public formValidMsg: any;
  public showSuccessModal = false;
  public programName: any;
  public newProgramId: any;
  public dbValidationMsg: any;
  public dbErrorMsg: any;
  public programIdValdation: any;
  public invalidClientId: any;
  public existMail: any;
  public clientName: any;
  public moduleGroup: any;
  public programObj: any;
  // public userRoleRef;
  @ViewChild('searchUser') searchUser: NgSelectComponent;
  @ViewChild('dateFiled') svmsDatePicker;
  @ViewChild(ProgramManageComponent) programMembers: ProgramManageComponent;

  public editProgramId = true;

  constructor(
    private formBuilder: FormBuilder,
    private eventStream: EventStreamService,
    private changeDetection: ChangeDetectorRef,
    private router: Router,
    private _programService: ProgramService,
    private _alertService: AlertService,
    private _loader: LoaderService,
    private _cloneService: ClonerService,
    private localStorage: StorageService
  ) { }

  options: any = {
    language: 'English',
    timepicker: true,
    format12h: true,
    range: true,
    // enabledDateRanges: [
    //   { start: new Date(this.now - 3 * this.days), end: new Date(this.now + 2 * this.days) },
    //   { start: new Date(this.now + 4 * this.days), end: new Date(this.now + 7 * this.days) }
    // ]
  };

  ngOnInit(): void {
    this.clientData();
    this.onSearch();
    this.onSearchMSP();
    this.mspData();
    this.getModuleList();
    // This means whenever client sidebar is closed, we are refreshing list of clients.
    this.eventStream.on(Events.ORG_CREATE, (hidden) => {
      if (hidden) {
        // this.clientData();
      }
    });
    this.eventStream.on(Events.DO_SHOW_CLIENT_FORM, (data) => {
      this.showCreate = data;
      this.changeDetection.detectChanges();
    });

    this.eventStream.on(Events.DO_SHOW_USER_FORM, (data) => {
      this.showUserCreate = data;
      this.changeDetection.detectChanges();
    });

    this.createProgramForm = this.formBuilder.group({
      client: [null, Validators.required],
      programType: [null, Validators.required],
      msp: [null, ''],
      name: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_ -]+$'),
        ]),
      ],
      programID: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9]+$'),
        ]),
      ],
      date: [null],
      UserRole: [null, ''],
      description: ['.', ''],
      members: this.formBuilder.array([]),
      managerName: [null, ''],
      username: [null, ''],
    });

    this.createProgramForm.get('name')?.valueChanges.pipe(debounceTime(400),
      distinctUntilChanged(),
      tap((text) => {
        this.checkProgramName(text);
      })
    )
      .subscribe();
    this.createProgramForm.get('programID')?.valueChanges.pipe(debounceTime(400),
      distinctUntilChanged(),
      tap((text) => {
        this.checkProgramId(text);
      })
    )
      .subscribe();
  }

  ngOnDestroy() {
    this.eventStream.emit(new EmitEvent(Events.DO_SHOW_CLIENT_FORM, false));
  }

  createOrg() {
    this.eventStream.emit(new EmitEvent(Events.ORG_CREATE, true));
  }

  checkProgramName(term) {
    let duplicate = false;
    this._programService.get(`/configurator/programs?name=${term}`).subscribe(
      data => {
        data.programs.map(data1 => {
          if (data1?.name?.toUpperCase() === term?.toUpperCase()) {
            duplicate = true;
          }
        });
        if (duplicate) {
          this.duplicateProgram = 'Program name already in use';
        }
        else {
          this.duplicateProgram = '';
        }
      }
    );
  }

  checkProgramId(term) {
    let duplicate = false;
    this._programService.get(`/configurator/programs?unique_id=${term}`).subscribe(
      data => {
        data.programs.map(data1 => {
          if (data1.unique_id.toUpperCase() === term.toUpperCase()) {
            duplicate = true;
          }
        });
        if (duplicate) {
          this.programIdValdation = 'Program id already in use';
        }
        else {
          this.programIdValdation = '';
        }
      }
    );
  }

  changeMSP() {
    const val = this.createProgramForm.get('programType').value;
    if (val === 'SELF-SERVICED') {
      this.createProgramForm.controls.msp.setValue('');
    }
  }

  gotToconfiguration() {
    this.router.navigate(['/program-setup'], { queryParams: { programId: this.program_uniqId, clientId: this.clientId, program_req_id: this.newProgramId, clientName: this.clientName } });
    this.localStorage.set(JSON.stringify(ProgramConfig[5]), this.programObj, true)
  }

  clientData() {
    this._programService
      .get('/configurator/organizations?category=CLIENT')
      .subscribe((data) => {
        this.clientList = data?.organizations;
      });
  }

  mspData() {
    this._programService
      .get('/configurator/organizations?category=MSP')
      .subscribe((data) => {
        this.mspList = data?.organizations;
      });
  }

  addUserToProgram(event) {
    this.eventStream.emit(new EmitEvent(Events.CREATE_NEW_USER, { show: true, orgId: environment.SIMPLIFY_ORG_ID }));
  }

  onFormSubmit() {
    let payLoad;
    const programMembers = this.programMembers?.programManagersObj;
    let conditions = false;
    const datefield = this.createProgramForm.get('date').value;
    let date1;
    if (datefield) {
      const date = new Date(this.createProgramForm.get('date').value);
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      date1 = new Date(year, month, day + 1);
      date1 = date1.getTime() / 1000;
    }
    // this.moduleGroup.map( function(module) {return  { id: module.id , is_enabled: module.is_enabled} ; } )
    payLoad = {
      name: this.createProgramForm.get('name').value,
      unique_id: this.createProgramForm.get('programID').value,
      description: this.createProgramForm.get('description').value || 'testing',
      type: this.createProgramForm.get('programType').value,
      client: this.clientInfo?.id,
      msp: this.createProgramForm.get('msp').value || null,
      vendors: [],
      start_date: date1?.toString() || '',
      members: this.prepareObj(programMembers),
      module_groups: this.getModuleGroup()
    };

    if (!payLoad.client || payLoad.client == null) {
      this._alertService.error('Please Select a Client', {});
      conditions = true;
    }
    if (!payLoad.type || payLoad.type == null) {
      this._alertService.error('Please Select a Program Type', {});
      conditions = true;
    }
    if (!payLoad.name || payLoad.name == null) {
      this._alertService.error('Please Enter Your Program Name', {});
      conditions = true;
    }
    if (payLoad.msp == null && payLoad.type === 'MSP-MANAGED') {
      this._alertService.error('Please Select a MSP', {});
      conditions = true;
    }
    if (!payLoad.unique_id || payLoad.unique_id == null) {
      this._alertService.error('Please Enter Your Program Name', {});
      conditions = true;
    }
    if (programMembers?.length === 0) {
      this._alertService.error('Please Select a user and Position', {});
      conditions = true;
    }
    if (this.programIdValdation && this.programIdValdation !== '' && this.programIdValdation !== undefined) {
      this._alertService.error('Program id Already in use', {});
      conditions = true;
    }
    if (this.duplicateProgram && this.duplicateProgram !== '') {
      this._alertService.error('Program Name Already in use', {});
      conditions = true;
    }

    this.validationMsg = conditions;
    if (!conditions) {
      this.program_uniqId = payLoad.unique_id;
      this.clientId = payLoad.client;
      this._loader.show();
      this.programName = payLoad.name;
      this._programService.post('/configurator/programs', payLoad).subscribe(
        (data) => {
          this._loader.hide();
          if (data.program?.id) {
            //this.inviteUsers();
            this.clientName = this.clientInfo.name;
            this.newProgramId = data.program.id;
            this.localStorage.set('newProgramId', JSON.stringify(this.newProgramId), true);
            this.showSuccessModal = true;
            this.createProgramForm.reset();
            this.programMembers.programMemidObj = [];
            this.programMembers.programManagersObj = [];
            this.clientInfo = '';
            this.svmsDatePicker._value = "";
            this.dbValidationMsg = '';
            this.dbErrorMsg = '';
            this.programMembers.usernameOldValue = '';
            this.programMembers.roleOldValue = '';
            this.programMembers.programMemForm.reset();
            this.programMembers.userEmail = [];
            this.programMembers.selectedUsers = [];
            this.programObj = payLoad;
            this.resetModuleList();
          }
          if (data[0]?.type?.message) {
            this.dbValidationMsg = data[0]?.type?.message;
            this._alertService.error(this.dbValidationMsg, {});
          }
          if (data[0]?.name?.message) {
            this.dbValidationMsg = data[0]?.name?.message;
            this._alertService.error(this.dbValidationMsg, {});
          }
        },
        (err) => {
          this._alertService.error(errorHandler(err), {});
          this._loader.hide();
        }
      );
    }
  }
  getModuleGroup() {
    const module = this.moduleGroup.map(fg => {
      return { id: fg.id, is_enabled: fg.is_enabled };
    });
    return module;
  }

  closeSuccessModal() {
    this.showSuccessModal = false;
    this.resetModuleList();
  }

  onClickAddUser() {
    this.addUser = true;
    if (this.searchUser !== undefined) {
      this.searchUser.open();
      setTimeout(() => {
        this.searchUser.focus();
      }, 0);
    }
  }

  onAddUserChange(event) {
    this.validateEmail(event);
    this.inviteUserName = event.term;
    if (this.inviteUserName.length === 0) {
      this.inviteUser = false;
    }
  }

  validateEmail(eve) {
    const pattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    let emailpttern;
    emailpttern = pattern.test(eve.term);
    if (emailpttern && this.clientInfo?.id) {
      this.inviteUser = true;
    } else {
      this.inviteUser = false;
    }
  }

  inviteUsers() {
    const emailArr = this.programMembers.userEmail;
    emailArr?.map(val => {
      const payLoad = { email: val };
      this._programService
        .post(
          `/profile-manager/organizations/${environment.SIMPLIFY_ORG_ID}/members/invite`,
          payLoad
        )
        .subscribe(
          (data) => {
            if (data?.member?.id) {
              this.existMail = '';
            }
            if (data?.error?.message) {
              this.existMail = data.error.message;
            }
          },
          (err) => {
            if (err?.error?.error?.message) {
              this._alertService.error(err.error.error.message, {});
            }
            this._alertService.error(errorHandler(err), {});
          }
        );
    });
  }

  onBackToLIsting(event) {
    this.router.navigateByUrl('/programs/list');
    event.preventDefault();
  }

  prepareObj(obj) {
    const copyObj = this._cloneService.deepClone(obj);
    const programObj = [];
    let copyElemet;
    copyObj?.map((element) => {
      copyElemet = element;
      delete copyElemet.id;
      programObj.push(copyElemet);
    });
    return programObj;
  }

  generateProgramId() {
    const payLoad = { organization_id: this.clientInfo?.id };
    this._programService
      .post('/configurator/programs/unique-id', payLoad)
      .subscribe((data) => {
        this.programIdValdation = data?.error?.message;
        this.createProgramForm.controls.programID.setValue(data.unique_id);
      });
  }

  editProgramsId() {
    this.editProgramId = false;
  }

  onSearch() {
    this.input$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term) => this.clientService(term))
      )
      .subscribe((data) => {
        this.clientList = data.organizations;
      });
  }

  onSearchMSP() {
    this.mspInput$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term) => this.MSPService(term))
      )
      .subscribe((data) => {
        this.mspList = data.organizations;
      });
  }

  private clientService(term) {
    if (term == null || !term) { this.clientData(); }
    return this._programService.get(`/configurator/organizations?category=CLIENT&name=${term}`);
  }

  private MSPService(term) {
    if (term == null || !term) { this.mspData(); }
    return this._programService.get(`/configurator/organizations?category=MSP&name=${term}`);
  }

  getUsers() {
    this._programService
      .get(
        `/profile-manager/organizations/${environment.SIMPLIFY_ORG_ID}/members`
      )
      .subscribe((data) => {
        this.Users = data?.membersList.filter(
          (element) => element.first_name && element.first_name !== undefined
        );
      });
  }

  changeClient() {
    const client = this.createProgramForm.get('client').value;
    if (client === '' || client == null) {
      this.clientInfo = '';
    } else {
      this.clientInfo = this.clientList.find(client_ => client_.name === client);
      this.generateProgramId();
    }
  }
  onClickModule(e) {
  }
  resetModuleList() {
    this.moduleGroup.map((module) => {
      module.is_enabled = false;
      return module;
    });
  }
  getModuleList() {
    this._programService.get('/configurator/resources/module-groups').subscribe(data => {
      if (data && data.module_groups) {
        data?.module_groups?.forEach((mGroup: any) => {
          mGroup.is_enabled = false;
          mGroup.modules = mGroup.modules.map(module => {
            return {
              name: module.name,
              code: module.code,
              module_id: module.id,
              is_enabled: false,
              is_read_allowed: true,
              is_create_allowed: false,
              is_edit_allowed: false,
              is_delete_allowed: false
            };
          });
        });
        this.moduleGroup = data.module_groups;
      }
    }, error => {
      console.log(error);
    });

    // TODO enable/disable module in edit mode from "role.module_groups"
  }

}
