import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountSetupComponent } from './auth/account-setup/account-setup.component';
import { AuthguardService } from './core/services/auth_guard.service';
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthguardService],
    loadChildren:() => import('./main/main.module').then(m => m.MainModule),
  },
  {
    path: 'program-setup',
    canActivate: [AuthguardService],
    loadChildren:() => import('./program-setup/program-setup.module').then(m => m.ProgramSetupModule),
  },
  {
    path: 'candidates',
    canActivate: [AuthguardService],
    loadChildren:() => import('./candidate/candidate.module').then(m => m.CandidateModule),
  },

  {
    path: 'auth',
    loadChildren:() => import('./auth/auth.module').then(m => m.AuthModule),
  },
  { path: '**', redirectTo: 'auth/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthguardService]
})
export class AppRoutingModule { }
