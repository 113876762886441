<div id="svmsSidebar" class="svms-sidebar position-fixed bg-light" [class]="size"
  [attr.data-visibility]="dataVisibility" [attr.data-extended]="dataExtended" #svmsSidebarPanel>
  <div class="svms-header overflow-hidden p-12">
    <h4 class="float-left pl-8 mb-0 font-weight-normal">
      <icon size="24px" *ngIf="icon" [name]="icon" type="material" cuClass="icon float-left pr-16"></icon> {{title}}
    </h4>
    <a class="float-right mr-4" href="javascript:void(0)" (click)="closeSidebar()">
      <icon size="24px" name="close" type="material" cuClass="icon d-flex"></icon>
    </a>
  </div>
  <ng-content></ng-content>
</div>
<div id="svmsSidebarBackdrop" class="svms-backdrop position-fixed" [attr.data-visibility]="dataVisibility" (click)="closeSvmsSidebar()"></div>
