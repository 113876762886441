<div class="svms-toolbox-title">
  <p class="small text-secondary">
    HEADINGS
  </p>
  <span (click)="openHeadings()" class="cursor-pointer">
    <img *ngIf="isShowHeadings" src="../../../../../assets/images/custom-fields-icons/arrow_drop_down.svg" />
    <img *ngIf="!isShowHeadings" src="../../../../../assets/images/custom-fields-icons/arrow_drop_up.svg" />
  </span>
</div>
<div *ngIf="isShowHeadings" class="svms-toolbox">
  <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.heading">
    <img src="../../../../../assets/images/custom-fields-icons/asset-7.svg" alt=""
      [attr.field-type]="fieldTypes.heading" />
    <p class="small">Heading</p>
  </div>
  <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.subheading">
    <img src="../../../../../assets/images/custom-fields-icons/asset-8.svg" alt=""
      [attr.field-type]="fieldTypes.subheading" />
    <p class="small">Sub Heading</p>
  </div>
</div>