<div class="account-form-wrapper basic-details">
  <div class="top-left-corner"></div>
  <div class="top-right-corner"></div>
  <div class="bottom-left-corner"></div>
  <div class="bottom-right-corner"></div>
  <div class="form-wrapper">
    <div class="logo text-center pt-8">@</div>
    <h2 class="text-center">Basic Details</h2>
    <p class="text-center mx-auto custom-width">Let's get to know you a little bit. This won't take long.</p>
    <div class="progress-bar">
      <ul class="pb-12">
        <li class="done"></li>
        <li class="active"></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div class="validation-alert mt-12 p-8 alert-error overflow-hidden mb-16 d-flex align-items-center"
      *ngIf="isFirstNameError || isLastNameError || isTitleError">
      <icon name="error_outline" size="24px" type="material" class="pt-0 float-left mr-8"></icon>
      <p class="text12">
        {{errorMassage}}
      </p>
    </div>
    <div class="row pt-12">
      <div class="col">
        <div class="form-group pb-8">
          <label>First Name <span class="require">*</span></label>
          <input type="text" name="firstname" class="form-control medium" maxlength="23"
            [ngClass]="{'error': isFirstNameError}" [(ngModel)]="userInfo.first_name" placeholder="First name"
            (keydown)="onKeydown($event)" (keyup)="resetError('first_name')">
        </div>
      </div>
      <div class="col">
        <div class="form-group pb-8">
          <label>Middle Name</label>
          <input type="text" name="middlename" class="form-control medium" maxlength="23"
            [(ngModel)]="userInfo.middle_name" placeholder="Middle name" (keydown)="onKeydown($event)">
        </div>
      </div>
      <div class="col">
        <div class="form-group pb-8">
          <label>Last Name <span class="require">*</span></label>
          <input type="text" name="lastname" class="form-control medium" maxlength="23"
            [ngClass]="{'error': isLastNameError}" [(ngModel)]="userInfo.last_name" placeholder="Last Name"
            (keydown)="onKeydown($event)" (keyup)="resetError('last_name')">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group pb-8">
          <label>Prefix</label>
          <ng-select [clearable]="true" class="medium" placeholder="Choose" [(ngModel)]="userInfo.name_prefix"
            [searchable]="false">
            <ng-option [value]="'mr'">Mr</ng-option>
            <ng-option [value]="'mrs'">Mrs</ng-option>
            <ng-option [value]="'miss'">Miss</ng-option>
            <ng-option [value]="'dr'">Dr</ng-option>
            <ng-option [value]="'prof'">Prof</ng-option>
            <ng-option [value]="'mx'">MX.</ng-option>
            <ng-option [value]="'ind'">IND.</ng-option>
            <ng-option [value]="'misc'">Misc</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="col">
        <label>Suffix</label>
        <ng-select [clearable]="true" class="medium" placeholder="Choose" [(ngModel)]="userInfo.name_suffix"
          [searchable]="false">
          <ng-option [value]="'jr'">Jr</ng-option>
          <ng-option [value]="'sr'">Sr</ng-option>
          <ng-option [value]="'III'">III</ng-option>
          <ng-option [value]="'IV'">IV</ng-option>
          <ng-option [value]="'V'">V</ng-option>
        </ng-select>
      </div>
      <div class="col">

      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group mb-8">
          <label>User Name</label>
          <div class="position-relative rounded-0 shadow-sm mb-4 d-flex align-items-center">
            <input (keyup)="validateUserName($event)" placeholder="User Name" type="text" class="form-control medium" [(ngModel)]="userInfo.username"
            readonly [ngClass]="{ 'error': !isValidUserName && userInfo?.username}">
            <span *ngIf="(!isValidUserName || userNameAlreadyExist) && !validatingUserName && userInfo?.username" class="material-icons error-text align">
              error
            </span>
            <span *ngIf="(isValidUserName && !userNameAlreadyExist) && !validatingUserName  && userInfo?.username" class="material-icons align green-check">
              check_box
            </span>
            <span *ngIf="validatingUserName  && userInfo?.username"><i class="fa fa-circle-o-notch fa-spin loader align"
                aria-hidden="true"></i>
            </span>
          </div>

          <div class="validation-alert mt-12 p-8 alert-error overflow-hidden mb-16 d-flex align-items-center"
            *ngIf="userNameAlreadyExist && !validatingUserName">
            <icon name="error_outline" size="24px" type="material" class="pt-0 float-left mr-8"></icon>
            <p class="text12">
              User Name is already taken.
            </p>
          </div>
          <div class="validation-alert mt-12 p-8 alert-error overflow-hidden mb-16 d-flex align-items-center"
            *ngIf="!validatingUserName && (userInfo?.username && userInfo?.username?.length != 0 && userInfo?.username?.length < 5)">
            <icon name="error_outline" size="24px" type="material" class="pt-0 float-left mr-8"></icon>
            <p class="text12">
              User name should be more than 5 characters long.
            </p>
          </div>

          <div class="validation-alert mt-12 p-8 alert-error overflow-hidden mb-16 d-flex align-items-center"
            *ngIf="!validatingUserName && (userInfo?.username && userInfo?.username?.length != 0 && userInfo?.username?.length > 10)">
            <icon name="error_outline" size="24px" type="material" class="pt-0 float-left mr-8"></icon>
            <p class="text12">
              User name should be less than 10 characters long.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group mb-8">
          <label for="title">Title</label>
          <input type="text" name="title" class="form-control" (keyup)="resetError('title')" [ngClass]="{'error': isTitleError}"
            [(ngModel)]="userInfo.title" placeholder="Title">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button class="account-btn btn btn-secondary float-right" (click)="secretQuestions()">Save &amp;
          Continue
          <icon class="icon--on-right d-flex" cuClass="intro-btn" name="keyboard_backspace" size="24px" type="material">
          </icon>
        </button>
      </div>
    </div>
  </div>
</div>