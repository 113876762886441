import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateService } from 'src/app/candidate/service/candidate.service';
import { EventStreamService, Events, EmitEvent } from 'src/app/core/services/event-stream.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { JobDetailsService } from '../../job-details.service';

@Component({
  selector: 'app-interview-details',
  templateUrl: './interview-details.component.html',
  styleUrls: ['./interview-details.component.scss'],
})
export class InterviewDetailsComponent implements OnInit {
  viewInterviewDetails = "hidden";
  candidateId: '';
  jobId: '';
  currentProgram: any;
  candidateInfo: any;
  intDetails: any;
  locationLabel = 'Interview Location';
  constructor(
    private eventStream: EventStreamService,
    private route : ActivatedRoute,
    private storageService: StorageService,
    private jobService : JobDetailsService,
    private candidateService : CandidateService,
    private alert: AlertService,
  ) { }

  ngOnInit(): void {
    this.candidateId = this.route.snapshot.params['candidateId'];
    this.currentProgram = this.storageService.get('CurrentProgram'); 
    this.jobId = this.route.snapshot.params['jobid'];
    if(this.jobId === undefined ||this.jobId === null || this.jobId === ''){
      this.jobId = this.route.snapshot.params['id'];
    }
    this.eventStream.on(Events.VIEW_INTERVIEW_DETAILS, (data) => {
      if (data.value) {
        let interviewID = data.value.id;
        if(data.value.candidateId){
          this.candidateId = data.value.candidateId;
        }
        this.viewInterviewDetails = 'visible';
        this.interviewDetails(this.currentProgram.id,this.jobId,interviewID);
        this.getCandidateDetails(this.candidateId);
      } else {
        this.viewInterviewDetails = 'hidden';
      }
    });
  }

  sidebarClose(){
    this.viewInterviewDetails = 'hidden';
  }

  interviewDetails(programId,jobId,interviewId){
    this.jobService.getInterviewDetails(programId,jobId,interviewId).subscribe(data=>{
      if(data){
        this.intDetails = data.interview;
        if(this.intDetails?.interview_type === 'F2F'){
          this.locationLabel = 'Interview Location';
        }else if(this.intDetails?.interview_type === 'PHONE'){
          this.locationLabel = 'Contact Details';
        }else if(this.intDetails?.interview_type === 'VIRTUAL'){
          this.locationLabel = 'Interview Joining Link';
        }
        else{this.locationLabel = 'Interview Location';}
      }
    })
  }

  getCandidateDetails(candidateId){
    this.candidateService.getCandidateDetail(candidateId).subscribe(data=>{
      this.candidateInfo = data.candidate;
    })
  }
  downloadResume(){
    if(this.candidateInfo.raw_resume){
      const linkSource = this.candidateInfo.raw_resume;
      const downloadLink = document.createElement("a");
      const fileName = this.candidateInfo.resume;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    else{
      this.alert.error(`No resume found for this candidate.`);
    }
  }
}
