import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-actions',
  templateUrl: './dashboard-actions.component.html',
  styleUrls: ['./dashboard-actions.component.scss']
})
export class DashboardActionsComponent implements OnInit {
  pending =[
  {name:'Job Pending Review',count:'46'},
  {name:'Job Pending Review',count:'176'},
  {name:'Resumes To Review',count:'56'},
  {name:'Offers Pending PMO Release',count:'2'},
  {name:'Offers Pending Workorders Creation',count:'31'},
  {name:'Rejected Offers',count:'201'},
  {name:'Rejected Workorders By Suppliers',count:'11'},
  {name:'Validate Onboarding Completion',count:'12'},
  {name:'Background Verification Review',count:'79'},
  {name:'Approve New Budget',count:'23'},
  {name:'Contract Extensions Pending Approval',count:'10'},
  {name:'Contract Amendments Pending Approval',count:2},
  {name:'Budget Pending Approval',count:'2'},
  {name:'Pending Misc Expense',count:'16'}
]
  constructor() { }

  ngOnInit(): void {
  }

}
