import { Component, OnInit, HostListener, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { JobDetailsService } from './job-details.service';
import { errorHandler } from 'src/app/shared/util/error-handler';
import { EmitEvent, Events, EventStreamService } from 'src/app/core/services/event-stream.service';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  state$: Observable<object>;
  toggleContent = false;
  actionVisibility = false;
  jobDetails = true;
  availableCandidate = false;
  submittedCandidate = false;
  interviews = false;
  offers = false;
  assignment = false;
  approval = false;
  history = false;
  fixedHeader = false;
  lastScrollTop = 0;
  public param = '';
  jobData: any;
  currentJobId = '';
  isOptOutText = 'opt-out';
  isOptOut = true;
  vendorId;
  public months = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
  jobStatus;
  @ViewChild('actionButton', { read: ElementRef, static: false }) actionButton: ElementRef;
  @ViewChild('actionDropdown', { read: ElementRef, static: false }) actionDropdown: ElementRef;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private jobService: JobDetailsService,
    private alertService: AlertService,
    private render: Renderer2,
    private _storageService: StorageService
  ) {
    this.render.listen('window', 'click', (e: Event) => {
      if ((this.actionButton && this.actionButton.nativeElement.contains(e.target) && !this.actionVisibility) ||
        (this.actionDropdown && this.actionDropdown.nativeElement.contains(e.target))) {
        this.actionVisibility = true;
      } else {
        this.actionVisibility = false;
      }
    });

  }

  ngOnInit(): void {

    let jobid = this.route.snapshot.params['id'];
    this.param = jobid;
    this.currentJobId = jobid;
    this.getJobDetails();
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    const st = window.pageYOffset;
    if (st > this.lastScrollTop) {
      if (st > 40) {
        this.fixedHeader = true;
      }
    } else if (40 < st && st < this.lastScrollTop) {
      this.fixedHeader = true;
    } else if (st <= 40) {
      this.fixedHeader = false;
    }
    this.lastScrollTop = st;
  }

  toggleSection() {
    this.toggleContent = !this.toggleContent;
  }

  optOut() {
    // this.actionVisibility = false;
    const payload = {
      opt_option: this.isOptOutText
    }
    this.vendorId = '85a1e450-e5ba-4558-9baa-8f36d5d1966f';
    // need to update vendor Id here.
    this.jobService.optInOutJob(payload, this.currentJobId, this.vendorId).subscribe(data => {
      if (data) {
        if (this.isOptOutText == 'opt-out') {
          this.isOptOutText = 'opt-in';
          this.isOptOut = false;
        } else {
          this.isOptOutText = 'opt-out';
          this.isOptOut = true;
        }
        this.alertService.success(`${this.isOptOutText} successfully.`);
      }
    }, (err) => {
      this.alertService.error(errorHandler(err));
    });
  }

  submitCandidate() {
    this.actionVisibility = false;
    this.router.navigate(['/candidates/submit'], { queryParams: { candidateSubmission: true, jobId: this.currentJobId, type: 'submit', candidateId: '22eeb6be-58c2-478b-845f-3dd0fd5bc1cc' } });
  }

  addSubmission() {
    this.actionVisibility = false;
    this.router.navigate(['/candidates/submit'], { queryParams: { candidateSubmission: true, jobId: this.currentJobId, type: 'addThenSubmit' } });
  }
  getJobDetails() {
    var data = this._storageService.get('viewd_job');
    // if(data && data['uid'] == this.currentJobId){
    //     if (data) {
    //       this.jobData = data;
    //     }
    // } else {
       this.jobService.getJobs(`${this.currentJobId}`).subscribe(data => {
        if (data.data) {
          this.jobData = data.data[0];
          this._storageService.set('viewd_job', this.jobData , true);
         if(this.jobData?.status == 'pending_approval'){
            this.jobStatus = 'Pending Approval';
          }else{
            this.jobStatus = this.jobData?.status
          }
        }  
      }, (err) => {
          this.alertService.error(errorHandler(err));
      });
    // }
    if (this.jobData) {
      if (this.jobData?.status == 'pending_approval') {
        this.jobStatus = 'Pending Approval';
      } else {
        this.jobStatus = this.jobData?.status
      }
    }
  }
  
  getFormattedDate(date ) {
    if(date){
    date = date?.split(' ');
    let onlyDate = date[0] || "";
    let allDate = onlyDate?.split('-');
    let formattedDate= '';    
    if(allDate?.length === 3) {
      let month= this.months[allDate[1] - 1];
      formattedDate= `${month} ${allDate[2]}, ${allDate[0]}`;
    } else {

    }
    return formattedDate;    
  }
}
getFormattedTime(date) {
  if(date) {
  date = date.split(' ');
  let formattedTime='';
  if(date?.length > 1){
    var onlyTime = date[1];
    var time = onlyTime?.split(':');
    if(time?.length > 1){
      var allTime = time[0]?.concat(':', ...time[1]);
      formattedTime= this.getTime(allTime);
    }     
  }
  return formattedTime;
}
}


getTime(time) {
  if(time) {
  time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  if (time?.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? ' AM' : ' PM';
    time[0] = +time[0] % 12 || 12;
  } return time.join('');
}
}
}
