import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvmsUploadAvatarComponent} from './svms-upload-avatar.component';
import { IconComponent } from './icon/icon.component';
import {ImageCropperModule} from 'ngx-image-cropper';



@NgModule({
  declarations: [SvmsUploadAvatarComponent, IconComponent],
  imports: [
    CommonModule,
    ImageCropperModule
  ],
  exports: [
    SvmsUploadAvatarComponent
  ]

  
})
export class SvmsUploadAvatarModule { }
