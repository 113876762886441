import { Injectable } from '@angular/core';
import { SidebarModule } from './sidebar.module';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  sideBarData: SidebarModule[] = [
    {
      name: 'dashboard',
      title: 'Dashboard',
      icon: 'dashboard',
      class: 'sidebar-icon',
      path: '/dashboard',
      permission: 'menu_dashboard',
      isSideMenu: false,
      isSearch: false
    },
    {
      name: 'sow',
      title: 'SOW',
      icon: 'dns',
      class: 'sidebar-icon',
      permission: 'menu_sow',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'Statement of Work',
        icon: 'close',
        class: 'sidebar-close-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          subMenuItem: [
            {
              title: 'Create SOW',
              icon: 'add',
              class: 'sidebar-icon-submenu',
              linkClass: "create",
              permission: 'menu_sow',
              isNotification: false,
              path: '/dashboard'
            },
            {
              title: 'View all SOW',
              icon: 'view_stream',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              path: '/dashboard'
            },
            {
              title: 'Proposal',
              icon: 'book',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              path: '/dashboard'
            },
            {
              title: 'Project',
              icon: 'all_inbox',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              path: '/dashboard'
            }
          ]
        }
      ]
    },
    {
      name: 'jobs',
      title: 'Job',
      icon: 'work',
      class: 'sidebar-icon',
      permission: 'menu_jobs',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'Jobs',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          subMenuItem: [
            {
              title: 'Create Job',
              icon: 'add',
              class: 'sidebar-icon-submenu',
              linkClass: "create",
              permission: 'create_job',
              isNotification: false,
              path: 'jobs/create'
            },
            {
              title: 'View All Job',
              icon: 'view_stream',
              class: 'sidebar-icon-submenu',
              permission: 'view_job',
              isNotification: false,
              path: '/jobs/list'
            },
            {
              title: 'Candidate',
              icon: 'people',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              path: '/candidates/list'
            },
            {
              title: 'Interview',
              icon: 'group_work',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              path: '/dashboard'
            },
            {
              title: 'Offer',
              icon: 'drafts',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              path: '/dashboard'
            }
          ]
        }
      ]
    },
    {
      name: 'Assignments',
      title: 'Assignment',
      icon: 'assignment',
      class: 'sidebar-icon',
      permission: 'menu_assignments',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'Assignments',
        icon: 'close',
        class: 'sidebar-close-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          subMenuItem: [
            {
              title: 'Create Assignment',
              icon: 'add',
              class: 'sidebar-icon-submenu',
              linkClass: "create",
              permission: 'create_assignment',
              isNotification: false,
              path: '/assignment/create'
            },
            {
              title: 'View Assignments',
              icon: 'assignment',
              class: 'sidebar-icon-submenu',
              permission: 'view_assignment',
              isNotification: false,
              path: '/assignment/all-list'
            },
            // {
            //   title: 'Qualification',
            //   icon: 'verified_user',
            //   class: 'sidebar-icon-submenu',
            //   isNotification: false,
            //   path: '/qualifications/qualification-type'
            // }
          ]
        }
      ]
    },
    {
      name: 'timesheet',
      title: 'Time & Expense',
      icon: 'schedule',
      class: 'sidebar-icon',
      permission: 'menu_timesheet_and_expense',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'Time & Expense',
        icon: 'close',
        class: 'sidebar-close-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          subMenuItem: [
            {
              title: 'View Timesheets',
              icon: 'pending_actions',
              class: 'sidebar-icon-submenu',
              permission: 'view_timesheet',
              isNotification: false,
              path: '/timesheet/list/all'
            }
          ]
        }
      ]
    },
    {
      name: 'Invoice',
      title: 'Invoice',
      icon: 'receipt_long',
      class: 'sidebar-icon',
      permission: 'menu_invoices',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'Invoice',
        icon: 'close',
        class: 'sidebar-close-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          subMenuItem: [
            {
              title: 'Individual Invoice',
              icon: 'receipt',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              path: '/dashboard'
            },
            {
              title: 'Consolidate Invoice',
              icon: 'request_quote',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              path: '/dashboard'
            },
            {
              title: 'Reconciliation',
              icon: 'receipt',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              path: '/dashboard'
            }
          ]
        }
      ]
    },
    {
      name: 'report',
      title: 'Report',
      icon: 'analytics',
      class: 'sidebar-icon',
      // path: '/reports',
      permission: 'menu_reports',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'Reports',
        icon: 'close',
        class: 'sidebar-close-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          subMenuItem: [
            {
              title: 'View Reports',
              icon: 'analytics',
              class: 'sidebar-icon-submenu',
              permission: 'menu_reports',
              isNotification: false,
              path: '/reports'
            }
          ]
        }
      ]
    },
    {
      name: 'programs',
      title: 'Programs',
      icon: 'layers',
      class: 'sidebar-icon',
      permission: 'menu_programs',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'Programs',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          subMenuItem: [
            {
              title: 'Create Program',
              icon: 'add',
              class: 'sidebar-icon-submenu',
              permission: 'create_program',
              linkClass: "create",
              path: '/programs/create',
              isNotification: false
            },
            {
              title: 'View All Program',
              icon: 'layers',
              class: 'sidebar-icon-submenu',
              path: '/programs/list',
              permission: 'view_program',
              isNotification: false
            },
            {
              title: 'View All Program',
              icon: 'layers',
              class: 'sidebar-icon-submenu',
              path: '/vendor-managment/program-list',
              permission: 'view_org_program',
              isNotification: false
            }
          ]
        },
        {
          title: 'Organization(s)',
          subMenuItem: [
            {
              title: 'View of Client(s)',
              icon: 'supervised_user_circle',
              class: 'sidebar-icon-submenu',
              path: 'org/list/client',
              isNotification: false
            },
            {
              title: 'View of MSP(s)',
              icon: 'corporate_fare',
              class: 'sidebar-icon-submenu',
              path: 'org/list/msp',
              isNotification: false
            },
            {
              title: 'View of Vendor(s)',
              icon: 'storefront',
              class: 'sidebar-icon-submenu',
              path: 'org/list/vendor',
              isNotification: false
            }
          ]
        }
      ]
    },
    {
      name: 'Settings',
      title: 'Settings',
      icon: 'settings',
      class: 'sidebar-icon',
      permission: 'menu_settings',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'Settings',
        icon: 'close',
        class: 'sidebar-close-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          subMenuItem: [
            {
              title: 'Lock/Unlock',
              icon: 'lock_open',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              permission: 'view_lock_unlock',
              path: '/vendor-managment/compliance-document-restriction'
            },
            {
              title: 'Company Details',
              icon: 'business',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              permission: 'view_vendor_setup',
              path: '/vendor-managment/setup'
            },
            {
              title: 'Compliance Documents',
              icon: 'assignment',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              permission: 'view_compliance',
              path: '/vendor-managment/compliance-list/{PROGRAM_ID}/{ORG_ID}',
              params: [
                'PROGRAM_ID',
                'ORG_ID'
              ]
            }
          ]
        },
        {
          title: 'Manage Vendor Users',
          subMenuItem: [
            {
              title: 'Add User',
              icon: 'person_add',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              permission: 'view_vendor_setup',
              path: '/vendor-managment/users/list/add'
            },
            {
              title: 'List of User(s)',
              icon: 'layers',
              class: 'sidebar-icon-submenu',
              isNotification: false,
              permission: 'view_vendor_setup',
              path: '/vendor-managment/users/list'
            }            
          ]
        }
      ]
    }
  ]

  constructor() { }

  getSideMenu() {
    return this.sideBarData;
  }

}
