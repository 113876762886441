<td *ngIf="isArray" [width]="width">
    <span class="d-flex align-items-center flex-wrap">
        <span class="badge badge-light mr-8 text-nowrap mb-4" *ngFor="let m of vmsRowDataSource">{{m}}</span>
    </span>
</td>

<td *ngIf="isShowCheckBox && !isArray" [width]="width" class="check-required">
    <div class="checkbox-box">
        <div class="checkbox-wrapper">
            <label class="checkbox-container">
                <input [disabled]="isCheckBoxReadonly" [checked]="vmsRowDataSource" type="checkbox" name="EDIT">
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
</td>

<td *ngIf="isImage && !isMultiUser && !isArray" [width]="width" [ngClass]="{'pl-24':!isVmsTableExpand && index === 0}">
    <p *ngIf="!isContact && vmsRowDataSource">
        <span *ngIf="isIcon" class="initials initials__purple"
            [ngClass]="{'initials_blue':!isVmsTableExpand && index === 1}">{{vmsRowDataSource |
            shortName:'last'}}</span>
        <a href="javascript:void(0)" *ngIf="toolTipVisibility" (click)="clickToView()" class="text-ellipsis"
            tooltip="{{vmsRowDataSource}}" placement="right" delay="500">{{vmsRowDataSource}}</a>
        <a href="javascript:void(0)" *ngIf="!toolTipVisibility" [ngClass]="isNavigation ? 'on-navigate' : ''"
            (click)="clickToView()" class="text-ellipsis">{{vmsRowDataSource}}</a>
    </p>
    <p *ngIf="isContact && vmsRowDataSource" class="contact-avatar-list">
        <span class="initials initials__green">{{vmsRowDataSource}}</span>
        <span class="initials initials__gray">{{vmsRowDataSource}}</span>
    </p>
    <span class="actions" *ngIf="!isNoOption">
        <!-- <button class="btn small btn-secondary" (click)="clickToView()">
      <table-icon size="14px" name="visibility" type="material" class="d-flex" cuClass="icon--on-left d-flex">
      </table-icon>
      View
    </button> -->

        <button class="btn small btn-outine rounded-sm ml-4" (click)="showDropdownFirstCol()">
            <table-icon size="14px" name="more_horiz" type="material" cuClass="d-flex" class="flex">
            </table-icon>
        </button>
    </span>
    <nav class="option-dropdown position-absolute" (mouseleave)="hideDropdownFirstCol()"
        [ngClass]="{'active' : showdropdownFirstCol}">
        <ul>
            <!-- detail button ui -->
            <li *ngIf="isDetails">
                <a href="javascript:void(0)" (click)="detailViewClick()">
                    <table-icon size="19px" name="list_alt" type="material" class="d-flex"
                        cuClass="icon--on-left d-flex">
                    </table-icon>
                    Details
                </a>
            </li>
            <li *ngIf="isVieworClone">
                <a href="javascript:void(0)" (click)="clickToClone()">
                    <table-icon size="19px" name="content_copy" type="material" class="d-flex"
                        cuClass="icon--on-left d-flex">
                    </table-icon>
                    Clone
                </a>
            </li>
            <li *ngIf="isVieworEdit">
                <a href="javascript:void(0)" (click)="clickToEdit()">
                    <table-icon size="19px" name="create" type="material" class="d-flex" cuClass="icon--on-left d-flex">
                    </table-icon>
                    Edit
                </a>
            </li>
            <li *ngIf="isDisableorDelete">
                <a href="javascript:void(0)" (click)="clickTodisable()">
                    <table-icon size="19px" name="label_off" type="material" class="d-flex"
                        cuClass="icon--on-left d-flex">
                    </table-icon>
                    <span *ngIf="vmsRowDataSource">Disable</span>
                    <span *ngIf="!vmsRowDataSource">Enable</span>
                </a>
            </li>
            <li *ngIf="isDelete">
                <a href="javascript:void(0)" (click)="clickTodelete()">
                    <table-icon size="19px" name="delete" type="material" class="d-flex" cuClass="icon--on-left d-flex">
                    </table-icon>
                    Delete
                </a>
            </li>
            <li *ngIf="isOptOut">
                <a href="javascript:void(0)" (click)="clickToOptOut()">
                    <table-icon size="19px" name="launch" type="material" class="d-flex" cuClass="icon--on-left d-flex">
                    </table-icon>
                    Opt Out
                </a>
            </li>
            <!-- <li *ngIf="showUpdate">
        <a href="javascript:void(0)" (click)="clickToUpdate()">
          <table-icon size="19px" name="edit" type="material" class="d-flex" cuClass="icon--on-left d-flex">
          </table-icon>
          Update
        </a>
      </li> -->
            <li *ngIf="isSubmitCandidate">
                <a href="javascript:void(0)" (click)="clickToSubmitCandidate()">
                    <table-icon size="19px" name="publish" type="material" class="d-flex"
                        cuClass="icon--on-left d-flex">
                    </table-icon>
                    Add & Submit Candidate
                </a>
            </li>
            <li *ngFor="let option of (options || [])" (click)="optionClicked(option)">
                <a href="javascript:void(0)">
                    <table-icon size="19px" *ngIf="option?.icon" [name]="option?.icon" type="material" class="d-flex"
                        cuClass="icon--on-left d-flex">
                    </table-icon>
                    {{option?.name}}
                </a>
            </li>
        </ul>
    </nav>
</td>

<td *ngIf="isImage && isMultiUser && !isArray" [width]="width">
    <ng-container *ngIf="vmsRowDataSource?.length == 1">
        <span class="initials initials__purple">{{vmsRowDataSource[0].first_name | shortName:'first'}}</span>
        {{vmsRowDataSource[0].first_name}}
    </ng-container>
    <ng-container *ngIf="vmsRowDataSource?.length == 2">
        <p *ngIf="vmsRowDataSource" class="contact-avatar-list">
            <span class="initials initials__green">{{vmsRowDataSource[0].first_name | shortName:'first'}}</span>
            <span class="initials initials__gray">{{vmsRowDataSource[1].first_name | shortName:'first'}}</span>
        </p>
    </ng-container>
    <ng-container *ngIf="vmsRowDataSource?.length > 2">
        <ul class="manager-avatar-list">
            <ng-container *ngFor="let item of vmsRowDataSource; let x = index">
                <li *ngIf="x < 2">
                    {{item.first_name | shortName:'first'}}
                </li>
            </ng-container>
            <li class="more-link">
                {{vmsRowDataSource?.length - 2}}
                <div class="more-item-dropdown position-absolute bg-light">
                    <ul>
                        <ng-container *ngFor="let item of vmsRowDataSource; let x = index">
                            <li *ngIf="x > 1">
                                {{item.first_name | shortName:'first'}}
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </li>
        </ul>
    </ng-container>
</td>
<!-- [ngClass]="{'initial-bg':isNumberBadge}"? -->
<td *ngIf="!isImage && (isObject() !== 'number') && (isObject() !== 'boolean') && !isStatusColor && !isIconList && !isProfileMatch && !isCandidateStatus && !isInterview && !isArray"
    [width]="width">
    <span *ngIf="(vmsRowDataSource?.toString() | lowercase) !== 'approved' && (vmsRowDataSource?.toString() | lowercase) !== 'open' && (vmsRowDataSource?.toString() | lowercase) !== 'inactive' && (vmsRowDataSource?.toString() | lowercase) !== 'closed' && 
        (vmsRowDataSource?.toString() | lowercase) !== 'active' && 
        (
        (vmsRowDataSource?.toString() | lowercase) !== 'pending' || 
        ((vmsRowDataSource?.toString() | lowercase) == 'pending' && hideBadege )
        )
        &&  (vmsRowDataSource?.toString() | lowercase) !== 'pending approval' && (vmsRowDataSource?.toString() | lowercase) !== 'rejected' && (vmsRowDataSource?.toString() | lowercase) !== 'scheduled' && (vmsRowDataSource?.toString() | lowercase) !== 'completed' && (vmsRowDataSource?.toString() | lowercase) !== 'cancelled' && (vmsRowDataSource?.toString() | lowercase) !== 'draft' &&  (vmsRowDataSource?.toString() | lowercase) !== 'withdrawn' && (vmsRowDataSource?.toString() | lowercase) !== 'sourcing' && (vmsRowDataSource?.toString() | lowercase) !== 'deferred' && (vmsRowDataSource?.toString() | lowercase) !== 'in progress' && (vmsRowDataSource?.toString() | lowercase) !== 'overdue'"
        (click)="openPanel()">
        <span *ngIf="isIcon && vmsRowDataSource" class="initials initials__purple"
            [ngClass]="{'initials_blue':!isVmsTableExpand && index === 1}">
            {{vmsRowDataSource | shortName:'last'}}</span>
            <span *ngIf="!showTitleCase"  [ngClass]="{'text-hover': (has_permission && vmsRowDataSource)}">{{vmsRowDataSource}}</span>
            <span *ngIf="showTitleCase"  [ngClass]="{'text-hover': (has_permission && vmsRowDataSource)}">{{vmsRowDataSource | titlecase}}</span>

    </span>
    <span
        *ngIf="(vmsRowDataSource?.toString() | lowercase) == 'approved' || (vmsRowDataSource?.toString() | lowercase) === 'open' || (vmsRowDataSource?.toString() | lowercase) === 'inactive' || (vmsRowDataSource?.toString() | lowercase) == 'closed' || (vmsRowDataSource?.toString() | lowercase) === 'active' || 
        ((vmsRowDataSource?.toString() | lowercase) == 'pending' && !hideBadege) || 
        (vmsRowDataSource?.toString() | lowercase) == 'pending approval' || (vmsRowDataSource?.toString() | lowercase) == 'rejected' || (vmsRowDataSource?.toString() | lowercase) == 'scheduled' || (vmsRowDataSource?.toString() | lowercase) == 'completed' || (vmsRowDataSource?.toString() | lowercase) == 'cancelled' || (vmsRowDataSource?.toString() | lowercase) == 'draft' || (vmsRowDataSource?.toString() | lowercase) == 'sourcing' || (vmsRowDataSource?.toString() | lowercase) == 'withdrawn' || (vmsRowDataSource?.toString() | lowercase) == 'deferred' || (vmsRowDataSource?.toString() | lowercase) == 'in progress' || (vmsRowDataSource?.toString() | lowercase) == 'overdue'"
        class="status-ui"
        [ngClass]="{'approved': ((vmsRowDataSource?.toString() | lowercase) === 'approved' || (vmsRowDataSource?.toString() | lowercase) === 'open' || (vmsRowDataSource?.toString() | lowercase) == 'active' || (vmsRowDataSource?.toString() | lowercase) == 'scheduled' || (vmsRowDataSource?.toString() | lowercase) == 'completed'),'withdrawn': (vmsRowDataSource?.toString() | lowercase) == 'withdrawn'  || (vmsRowDataSource?.toString() | lowercase) == 'cancelled', 'pending-approval': (vmsRowDataSource?.toString() | lowercase) == 'pending' || (vmsRowDataSource?.toString() | lowercase) === 'inactive'  || (vmsRowDataSource?.toString() | lowercase) == 'pending approval' || (vmsRowDataSource?.toString() | lowercase) == 'in progress' || (vmsRowDataSource?.toString() | lowercase) == 'overdue','rejected': (vmsRowDataSource?.toString() | lowercase) == 'rejected', 'draft':(vmsRowDataSource?.toString() | lowercase) == 'draft', 'sourcing':(vmsRowDataSource?.toString() | lowercase) == 'sourcing', 'closed': (vmsRowDataSource?.toString() | lowercase) == 'closed', 'deferred': (vmsRowDataSource?.toString() | lowercase) == 'deferred'}">
        {{vmsRowDataSource}}
    </span>
    <table-icon *ngIf="vmsRowDataSource === null" size="24px" name="remove" type="material" cuClass="icon"></table-icon>
</td>

<td *ngIf="(isObject() === 'number') && vmsRowDataSource > 999999999" [width]="width">
    {{vmsRowDataSource | date:'d MMM y'}}
    <table-icon *ngIf="vmsRowDataSource === undefined" size="24px" name="remove" type="material" cuClass="icon">
    </table-icon>
</td>

<td *ngIf="(isObject() === 'number') && vmsRowDataSource < 999999999" [width]="width">
    <span (click)="onNumberBadgeClicked()" [ngClass]="{'initial-bg':isNumberBadge}">{{vmsRowDataSource}}</span>
    <table-icon *ngIf="vmsRowDataSource === undefined" size="24px" name="remove" type="material" cuClass="icon">
    </table-icon>
</td>

<td *ngIf="isStatusColor" [width]="width">
    <p *ngIf="vmsRowDataSource == 'REQUIRED'" class="hierarchy-required">
        <span class="red">Required</span>
    </p>
    <p *ngIf="vmsRowDataSource == 'OPTIONAL'" class="hierarchy-required">
        <span class="violet">Optional</span>
    </p>
    <p *ngIf="vmsRowDataSource == 'OFF'" class="hierarchy-required">
        <span class="grey">Off</span>
    </p>
</td>

<td *ngIf="isCandidateStatus" [width]="width" class="status-column">
    <span class="submitted position-relative" *ngIf="vmsRowDataSource == 'Submitted'">{{vmsRowDataSource}}</span>
    <span class="sourcing position-relative" *ngIf="vmsRowDataSource == 'Sourcing'">{{vmsRowDataSource}}</span>
    <span class="rejected position-relative" *ngIf="vmsRowDataSource == 'Rejeceted'">{{vmsRowDataSource}}</span>
</td>

<td *ngIf="isIconList" [width]="width">
    <ng-container *ngFor="let item of vmsRowDataSource | keyvalue">
        <p class="module-icon-display" *ngIf="(item.key !== 'hierarchy_included')">
            <span>
                <span *ngIf="(item.value !== 'OFF')">
                    <table-icon *ngIf="(item.key == 'module_sow')" size="20px" name="article" type="material"
                        cuClass="module-icon  mr-24">
                    </table-icon>
                    <table-icon *ngIf="(item.key == 'module_jobs')" size="20px" name="work_outline" type="material"
                        cuClass="module-icon  mr-24">
                    </table-icon>
                </span>
            </span>
            <span *ngIf="(item.value == true)">
                <table-icon *ngIf="(item.key == 'audio')" size="20px" name="phone_in_talk" type="material"
                    cuClass="module-icon  mr-24">
                </table-icon>
                <table-icon *ngIf="(item.key == 'video')" size="20px" name="videocam" type="material"
                    cuClass="module-icon  mr-24">
                </table-icon>
                <table-icon *ngIf="(item.key == 'face_to_face')" size="20px" name="people_alt" type="material"
                    cuClass="module-icon  mr-24">
                </table-icon>
            </span>
        </p>
    </ng-container>
</td>

<td *ngIf="isObject() === 'boolean' && !isShowCheckBox && !isIcon" [width]="width">
    <span class="status-ui approved" *ngIf="vmsRowDataSource">
        Active
    </span>
    <span class="status-ui draft" *ngIf="!vmsRowDataSource">
        Inactive
    </span>


</td>

<td *ngIf="isObject() === 'boolean' && !isShowCheckBox && isIcon" [width]="width">
    <table-icon name="locked" color="#FF0D0D" size="15px" *ngIf="!vmsRowDataSource"></table-icon>
    <table-icon name="lock_open" color="#4C7FF0" size="15px"  *ngIf="vmsRowDataSource"></table-icon>
</td>

<td *ngIf="isProfileMatch" [width]="width" [ngClass]="{'large-width-dropdown' : isWithdrawal}">
    <div class="match-svg">
        <svg width="36" height="36" viewBox="0 0 36 36" class="circular-chart orange">
            <path class="circle-bg" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
            <path class="circle" [style.strokeDasharray]="svgValue" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
        </svg>
    </div>
    <p class="match-value">{{vmsRowDataSource?.matchValue}}%</p>
    <span class="actions">
        <!-- <button *ngIf="vmsRowDataSource.status !== 'SUBMITTED'"
      class="btn small d-inline-block  btn-secondary font-weight-normal" (click)="clickToView()">
      Submit Candidate
    </button> -->

        <button *ngIf="isViewEnabled" class="btn small btn-secondary d-inline-block font-weight-normal"
            (click)="clickToViewCandidate()">
            View
        </button>

        <button class="btn small btn-light d-inline-block rounded-sm ml-4 no-border" (click)="showOptionDropdown()">
            <table-icon size="20px" name="more_horiz" type="material" cuClass="d-flex" class="flex">
            </table-icon>
        </button>
    </span>
    <nav class="option-dropdown position-absolute active" [ngClass]="{'active' : showdropdown}">
        <ul>
            <li *ngIf="isViewProfile">
                <a href="javascript:void(0)" (click)="clickToViewProfile()">
                    <table-icon size="19px" name="how_to_reg" type="material" class="d-flex"
                        cuClass="icon--on-left d-flex">
                    </table-icon>
                    View Profile
                </a>
            </li>
            <li *ngIf="isVieworEdit">
                <a href="javascript:void(0)" (click)="clickToEdit()">
                    <table-icon size="19px" name="create" type="material" class="d-flex" cuClass="icon--on-left d-flex">
                    </table-icon>
                    Edit
                </a>
            </li>
            <li *ngIf="isWithdrawal" class="red-color">
                <a href="javascript:void(0)" (click)="clickWithdrawCandidate()">
                    <table-icon size="19px" name="person_remove" type="material" class="d-flex"
                        cuClass="icon--on-left d-flex">
                    </table-icon>
                    {{vmsRowDataSource.status == 'SUBMITTED' ? 'Withdraw' : 'Submit'}} Candidate
                </a>
            </li>
        </ul>
    </nav>
</td>

<td *ngIf="isInterview" [width]="width">
    {{vmsRowDataSource}}
    <span class="actions" *ngIf="!isNoOption">
        <button class="btn small btn-secondary" (click)="clickToView()">
            <table-icon size="14px" name="visibility" type="material" class="d-flex" cuClass="icon--on-left d-flex">
            </table-icon>
            View
        </button>

        <button class="dropdown-trigger btn small btn-light rounded-sm ml-4" (click)="showOptionDropdown()">
            <table-icon size="24px" name="more_horiz" type="material" cuClass="d-flex" class="flex">
            </table-icon>
        </button>
    </span>
    <nav class="option-dropdown custom-width position-absolute" [ngClass]="{'active' : showdropdown}">
        <ul>
            <li>
                <a href="javascript:void(0)" (click)="onRescheduleClick()">
                    <table-icon size="19px" name="today" type="material" class="d-flex" cuClass="icon--on-left d-flex">
                    </table-icon>
                    Reschedule Interview
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" (click)="clickToEdit()">
                    <table-icon size="19px" name="create" type="material" class="d-flex" cuClass="icon--on-left d-flex">
                    </table-icon>
                    Edit Interview
                </a>
            </li>
            <li class="red-color">
                <a href="javascript:void(0)" (click)="onCancelClick()">
                    <table-icon size="19px" name="backspace" type="material" class="d-flex"
                        cuClass="icon--on-left d-flex">
                    </table-icon>
                    Cancel Interview
                </a>
            </li>
        </ul>
    </nav>
</td>