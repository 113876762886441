<svms-sidebar [title]="'Detail Estimate'" size="big" [dataVisibility]="isCreateEstimate"
  (onClose)="sidebarClose(false)">
  <svms-sidebar-body>
    <form [formGroup]="jobEstimateForm">
      <div class="p-24 job-estimate">
        <div class="no-gutters row">
          <div class="col-8">
            <label class="spc">Estimated Budget / Position</label>
            <label class="spc">Number of Positions</label>
            <label class="spc">Estimated Hours / Day</label>
            <label class="spc">Work Days / Week</label>
            <label class="spc">Work Duration</label>
            <label class="spc">Total Working Hours / Position</label>
            <label class="spc">Amount / Position</label>
            <label class="spc">Additional Amount / Position</label>
            <label class="spc">Sub Total / Position</label>
            <!-- <label class="spc"> Adjustment 1 - Local Tax / Position</label>
            <label class="spc"> Sub Total with Adjustments / Position</label> -->
            <label class="spc"><b>Total Estimated Budget</b></label>
          </div>
          <div class="col-4 text-right">
            <div class="spc mb-12">
              {{jobEstimateForm.get('single_gross_budget').value |currency:'USD':'symbol':'4.2-2' }}
            </div>
            <div class="w-100 d-flex justify-content-end">
              <input type="number" formControlName="num_resources" class="form-control mb-16 text-right small-input"
                (change)="getWorkingHoursEstimate()" onkeydown="return ( event.ctrlKey || event.altKey 
                || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                || (95<event.keyCode && event.keyCode<106)
                || (event.keyCode==8) || (event.keyCode==9) 
                || (event.keyCode>34 && event.keyCode<40) 
                || (event.keyCode==46) )">
             
            </div>
            <!-- <p class="validation-msg" *ngIf="jobEstimateForm?.get('num_resources').errors?.pattern">
              Positions must be greater than zero.
            </p> -->
            <div class="w-100 d-flex justify-content-end"> 
              <input type="number" formControlName="hours_per_day" class="form-control mb-16 text-right small-input"
                (change)="getWorkingHoursEstimate()" onkeydown="return ( event.ctrlKey || event.altKey 
                || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                || (95<event.keyCode && event.keyCode<106)
                || (event.keyCode==8) || (event.keyCode==9) 
                || (event.keyCode>34 && event.keyCode<40) 
                || (event.keyCode==46) )">
           
            </div>
            <!-- <p class="validation-msg" *ngIf="jobEstimateForm?.get('hours_per_day').errors?.pattern">
              Estimated Hours /Day must be greater than zero.
             </p> -->
            <div class="w-100 d-flex justify-content-end"> 
              <input type="number" formControlName="week_working_days" class="form-control mb-16 text-right small-input"
                (change)="getWorkingHoursEstimate()" onkeydown="return ( event.ctrlKey || event.altKey 
                || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                || (95<event.keyCode && event.keyCode<106)
                || (event.keyCode==8) || (event.keyCode==9) 
                || (event.keyCode>34 && event.keyCode<40) 
                || (event.keyCode==46) )">
            </div>
            <div class="spc mb-12">{{jobEstimateForm.get('formatted_working_days').value}}</div>
            <div class="spc mb-12">{{jobEstimateForm.get('working_hours').value}}</div>
            <div class="spc mb-12">
              {{jobEstimateForm.get('single_initial_budget').value  |currency:'USD':'symbol':'4.2-2'}}</div>
            <!-- <input type="number" formControlName="additional_amount" class="form-control mb-16 text-right"
              (change)="getResourceBudget()">
            <div class="spc mb-12">
              {{jobEstimateForm.get('single_gross_budget').value |currency:'USD':'symbol':'4.2-2' }}</div> -->
            <div class="mb-12">
              <span class="float-left w-50">
                <select class="form-control  highlight-form select1 mb-16 text-right" formControlName="adjustment_type"
                  (change)="onAdjustmentTypeChange($event)">
                  <option  [ngValue]="null" disabled>Select Method</option>
                  <option   value="percentage">Percentage</option>
                  <option  value="fixed">Fixed</option>
                </select>
              </span>
              <input class="form-control w-50 float-right  highlight-form select12 mb-16 text-right"
                placeholder="{{place_data}}" type="number" formControlName="adjustment_value"
                (change)="getResourceBudget()" >
            </div>
            <div class="spc mb-12">{{jobEstimateForm.get('single_net_budget').value |currency:'USD':'symbol':'4.2-2' }}
            </div>
            <div class="spc mb-12"><b>
                {{jobEstimateForm.get('net_budget')?.value | currency:'USD':'symbol':'4.2-2' }}</b></div>
          </div>
        </div>
      </div>
    </form>
  </svms-sidebar-body>
  <svms-sidebar-footer>
    <div class="row no-gutters">
      <div class="col-12 d-flex align-items-center justify-content-between">
        <button type="submit" class="btn btn-outline-secondary mr-8" (click)="sidebarClose(false)">
          Cancel
        </button>
        <button type="submit" class="btn btn-primary border-0" (click)="sidebarClose(true)">
          <icon size="24px" name="check_circle" type="material" class="d-flex mr-8" cuClass="icon-cu">
          </icon> Update
        </button>
      </div>
    </div>
  </svms-sidebar-footer>
</svms-sidebar>