import { Injectable, Output } from '@angular/core';
import { HttpService } from '../../../../src/app/core/services/http.service';
import { AlertService } from '../../../../src/app/shared/components/alert/alert.service';
import { errorHandler } from '../../shared/util/error-handler';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { StorageService } from 'src/app/core/services/storage.service';
import { of } from 'rxjs';

@Injectable()
export class JobDetailsService {
  private baseUrl;
  private currentProgram;
  constructor(private _http: HttpService,
    private _alert: AlertService,
    private storageService: StorageService) {
    this.baseUrl = environment.API_ENDPOINT;
    this.currentProgram = this.storageService.get('CurrentProgram');
  }

  get(url) {
    return this._http.get(url, this.baseUrl);
  }

  post(url, payload) {
    return this._http.post(url, payload, this.baseUrl);
  }

  put(url, payload) {
    return this._http.put(url, payload, this.baseUrl);
  }
  updateStatus(id, payload) {
    // const _url = `/job-manager/PRG-ABJ-003/job/${id}`;
    const _url = `/approval/approval-id/${id}`;
    return this.put(_url, payload).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }
  patch(url, payload) {
      return this._http.patch(url,payload);
  }

  getJobs(id) {
    // const _url = `/job-manager/PRG-ABJ-003/job/${id}`;
    const _url = `/job-manager/${this.currentProgram.id}/job/${id}`;
    return this.get(_url).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }

  getJobsBy(user_id, id) { 
     const _url = `/approval/approver/${user_id}?status=pending&entity_id=${id}`;
    return this.get(_url).pipe(map(res => {
      let data = res; 
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }
  getByEntityID(entity_id) {
    const _url = `/approval/status/entity/job/${entity_id}?get_obj=False`;
    return this.get(_url).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }
  saveSubmitCandidate(payload, jobId) {
    const _url = `/configurator/programs/${this.currentProgram.id}/jobs/${jobId}/candidates/submit`;
    return this._http.post(_url, payload).pipe(map(
      data => {
        return data;
      }
    ));
  }
  withdrawCandidate(payload, jobId) {
    const _url = `/configurator/programs/${this.currentProgram.id}/jobs/${jobId}/candidates/withdraw`;
    return this.put(_url, payload).pipe(map(
      data => {
        return data;
      }
    ));
  }
  fetchCurrentSubmitCandidate(candidateId, jobId) {
    const _url = `/configurator/programs/${this.currentProgram.id}/jobs/${jobId}/candidates/${candidateId}`;
    return this.get(_url).pipe(map(
      data => {
        return data;
      }
    ));
  }
  updateSubmitCandidate(payload, jobId, candidateId, submissionId) {
    const _url = `/configurator/programs/${this.currentProgram.id}/jobs/${jobId}/candidates/${candidateId}/submission/${submissionId}`;
    return this._http.put(_url, payload).pipe(map(
      data => {
        return data;
      }
    ));
  }
   getOfferDetails(jobId,candidateId,offerId){
    const _url = `/configurator/programs/${this.currentProgram.id}/jobs/${jobId}/candidates/${candidateId}/offers/${offerId}`;
    return this._http.get(_url).pipe(map(
      data => {
        return data;
      }
    ));
  }
  releaseOffer(payload, jobId, candidateId) {
    const _url = `/configurator/programs/${this.currentProgram.id}/jobs/${jobId}/candidates/${candidateId}/offers`;
    return this._http.post(_url, payload).pipe(map(
      data => {
        return data;
      }
    ));
   }
   getInterviewList(jobId,candidateId?){
    const _url = `/configurator/programs/${this.currentProgram.id}/jobs/${jobId}/interviews${candidateId ? ('?candidate_id=' + candidateId) : ''}`;
    return this._http.get(_url).pipe(map(
      data => {
        return data;
      }
    ));
   }
   optInOutJob(payload,jobId, vendorId){
    const _url = `/job-manager/${this.currentProgram.id}/job_distribution/${vendorId}/${jobId}`;
    return this._http.put(_url, payload).pipe(map(
      data => {
        return data;
      }
    ));
   }
   counterOffer(payload,jobId,candidateId,offerId){
    const _url = `/configurator/programs/${this.currentProgram.id}/jobs/${jobId}/candidates/${candidateId}/offers/${offerId}`;
     return this._http.put(_url, payload).pipe(map(
      data => {
        return data;
      }
    ));
   }

   getProgramConfig(type){ // once the config table updated with data, need to change the program id.
    const _url = `/configurator/programs/${this.currentProgram.id}/config?entity_code=${type}`;
    return this._http.get(_url).pipe(map(
      data => {
        return data;
      }
    ));
   }

   rejectCandidate(payload, jobId){
     //PUT /job-manager/programs/<PROGRAM_ID>/jobs/<JOB_ID>/candidates/reject
     const _url = `/configurator/programs/${this.currentProgram.id}/jobs/${jobId}/candidates/reject`;
     return this.put(_url, payload).pipe(map(
      data => {
        return data;
      }
    ));
   }

   getInterviewDetails(programId,jobId,interviewId){
    const _url = `/configurator/programs/${programId}/jobs/${jobId}/interviews/${interviewId}`;
    return this._http.get(_url).pipe(map(
      data => {
        return data;
      }
    ));
   }
  scheduleInterview(jobId,candidateId,payload){
    const _url = `/configurator/programs/${this.currentProgram.id}/jobs/${jobId}/candidates/${candidateId}/interviews`;
    return this._http.post(_url, payload).pipe(map(
      data => {
        return data;
      }
    ));
  }
  getTimezones(){
    const _url = `/configurator/resources/time_zones`;
    return this._http.get(_url).pipe(map(
      data => {
        return data;
      }
    ));
  }
}
