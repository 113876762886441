import { CurrencyPipe, formatNumber } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customcurrency"
})
export class CustomcurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(
    value: any,
    currencyCode?: string,
    display?: "code" | "symbol" | "symbol-narrow" | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    if ((currencyCode === "INR") && (value)) {
      return this.indian_money_format(value);
    } else {
      return super.transform(
        value,
        currencyCode || "INR",
        display || "symbol",
        digitsInfo || "0.2-2",
        locale
      );
    }
  }

  indian_money_format(amt) {
    const point = (amt.toString()).split(".");
    let afterPoint = formatNumber(Number(`.${point[1]}`), 'en-un', '0.2-2').split('.')[1]
    if (!afterPoint) {
      afterPoint = '00'
    }
    amt = point[0]
    var lastThree = amt.substring(amt.length - 3);
    var otherNumbers = amt.substring(0, amt.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var result = `₹ ${otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",")}${lastThree}.${afterPoint}`;
    return result;
  }
}
