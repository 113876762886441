import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { SharedModule } from '../shared/shared.module';
import { VmsTableModule } from '../library/smartTable/vms-table.module';
import { ReportsDetailsComponent } from './reports-details/reports-details.component';
import { AsidePanelComponent } from './components/aside-panel/aside-panel.component';
import { ReportService } from './report.service';
import { ReportsGraphComponent } from './components/reports-graph/reports-graph.component';
import { FilterFieldsSidepanelComponent } from './components/filter-fields-sidepanel/filter-fields-sidepanel.component';
import { NgSelectModule } from '@ng-select/ng-select';

//Charts
import {SvmsPieChartModule} from 'src/app/library/svms-pie-chart/svms-pie-chart.module';
import {SvmsLineChartModule} from 'src/app/library/svms-line-chart/svms-line-chart.module';
import {SvmsColumnChartModule} from 'src/app/library/svms-column-chart/svms-column-chart.module';
import {SvmsBubbleChartModule} from 'src/app/library/svms-bubble-chart/svms-bubble-chart.module';
import {SvmsBarChartModule} from 'src/app/library/svms-bar-chart/svms-bar-chart.module';
import {DonutChartModule} from 'src/app/library/donut-chart/donut-chart.module';
import { ClickOutsideModule } from 'ng-click-outside';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ReportsComponent, ReportsDetailsComponent, AsidePanelComponent, ReportsGraphComponent, FilterFieldsSidepanelComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedModule,
    VmsTableModule,
    NgSelectModule,
    ClickOutsideModule,
    SvmsPieChartModule,
    SvmsLineChartModule,
    SvmsColumnChartModule,
    SvmsBubbleChartModule,
    SvmsBarChartModule,
    DonutChartModule,
    NgSelectModule,

    FormsModule,
    ReactiveFormsModule,

  ],
  providers: [ReportService]
})
export class ReportsModule { }
