import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName, FormArray } from '@angular/forms';
import {
  EventStreamService,
  Events,
  EmitEvent,
} from 'src/app/core/services/event-stream.service';
import { CandidateService } from '../../service/candidate.service';
import { errorHandler } from '../../../shared/util/error-handler';
import { AlertService } from 'src/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-add-skill',
  templateUrl: './add-skill.component.html',
  styleUrls: ['./add-skill.component.scss']
})
export class AddSkillComponent implements OnInit {
  @Output() skillData: EventEmitter<any> = new EventEmitter<any>();
  closePanel: EventEmitter<boolean> = new EventEmitter();
  @Input() skillTypeId;
  skillList = [];
  addSkills = "hidden";
  panelHeading = 'Add New Skill';
  public addSkilsForm: FormGroup;
  editPanel = false;
  clickedIndex ;
  constructor(
    private fb: FormBuilder,
    private eventStream: EventStreamService,
    private alertService: AlertService,
    private candidateService : CandidateService
    ) { }

  ngOnInit(): void {
    this.eventStream.on(Events.CREATE_CANDIDATE_SKILL, (data) => {
      if (data.value) {
        this.addSkills = 'visible';
        if(data.obj != null){
          this.updatePanel(data.obj);
          this.panelHeading = 'Edit Skill';
          this.editPanel = true;
          this.clickedIndex = data.obj.clickedIndex;
        }
      } else {
        this.addSkills = 'hidden';
      }
    });
    this.getSkills();
    this.addSkilsForm = this.fb.group({
      skillType: [null, Validators.required],
      experienceLevel: [null, Validators.required],
      description: [null, ''],
      notes: [null, ''],
      files:[null,'']
    });
  }
  sidebarClose(){
    this.addSkills = 'hidden';
    this.addSkilsForm.reset();
    this.panelHeading = 'Add New Skill';
    this.editPanel = false;
    this.closePanel.emit(true);
  }
  getSkills(){
    const skillId = this.skillTypeId;
    this.candidateService._getQualiFicationType(skillId) .subscribe((res) => {
      this.skillList = res.qualifications;
    }, (err) => {
      this.alertService.error(errorHandler(err));
    })
  }
  uploadFiles(event){
    this.addSkilsForm.patchValue({
      files:event
    })
  }
  updatePanel(data){
    this.addSkilsForm.patchValue({
      skillType: data.qualification_id,
      experienceLevel: data.experience_level,
      description: data.description,
      notes: data.notes,
      files:data.files
    })
  }
  saveSkill(action){
    if(this.addSkilsForm.valid){
      let data = this.addSkilsForm.value;
      const skillType = this.skillList.filter((item)=>{
        if(item.id == data.skillType){
          return item.name;
        }
      }).map((obj)=>{
          return obj.name;
      })
      const payload={
        qualification_id: data.skillType,
        experience_level: data.experienceLevel,
        description: data.description,
        notes:data.notes,
        files:data.files,
        skill_name: skillType
      }
      this.addSkilsForm.reset();
      this.skillData.emit({payload: payload, isEdit:this.editPanel, clickedIndex: this.clickedIndex});
      if(action == 'save'){
        this.sidebarClose();
      }else{
        this.editPanel = false;
        this.panelHeading = 'Add New Skill';
        this.closePanel.emit(true);
      }
    }else{
      this.alertService.error('Please fill the required fields properly.');
    }
  }
}
