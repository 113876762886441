<ng-template [ngIf]="showTimesheets">
    <h3 class="font-weight-medium pl-8 pb-8">Update Impacted Timesheet(s)</h3>
<div class="p-8">
<table class="impacted-sheet">
    <thead>
    <tr>
        <th class="pl-16" width="7%">
        <input type="checkbox"  checked="true" disabled="true">
        </th>
        <th class="font-weight-medium" width="15%">
        Timesheet ID
        </th>
        <th class="font-weight-medium" width="30%">
        Work Period           
            </th>
        <th class="font-weight-medium">
        Status
        </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let timesheet of timesheets">
        <td class="pl-16">
        <input type="checkbox" (change)="updateControl(timesheet)" [checked]="timesheet.selected" [disabled]="!timesheet.is_enable">
        </td>
        <td>
            <span class="text-primary"> {{timesheet?.display_timesheet_code}}</span>
        </td>
        <td>
        <span>
            {{timesheet?.display_value}}
        </span>
        </td>
        <td>
            <span class="status-ui"
            [ngClass]="{'draft':(timesheet?.status ==='draft' || timesheet?.status=='missing'), 'pending-approval':(timesheet?.status ==='pending'), 'rejected': (timesheet?.status ==='rejected'),'approved':(timesheet?.status==='approved'), 'withdrawn':(timesheet?.status ==='withdrawn'),'deferred': (timesheet?.status==='deferred')}">
            {{timesheet?.status}}</span>
        </td>
    </tr>
   </tbody>
</table>
</div>
</ng-template>