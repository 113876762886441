import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { ReportService } from '../../report.service';
import { errorHandler } from '../../../shared/util/error-handler';
import { StorageService } from 'src/app/core/services/storage.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { ReportKeys } from './../../enums/report-enum'; 
@Component({
  selector: 'app-filter-fields-sidepanel',
  templateUrl: './filter-fields-sidepanel.component.html',
  styleUrls: ['./filter-fields-sidepanel.component.scss']
})
export class FilterFieldsSidepanelComponent implements OnInit {
  public report_Filter_Data: any;
  public report_filters: any = {};
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input()  reportKey:any;
  @Input() set reportFilterData(data) {
    if (data) {
      this.report_Filter_Data = data;
      this.report_Filter_Data?.forEach(rf => {
        rf.isFilter = false;
        rf.isVisible = false;
      });
    }
  };
  programId: any;
  get reportFilterData() {
    return this.report_Filter_Data;
  }
  constructor(
    public _alert: AlertService,
    public reportService: ReportService,
    public _storageService: StorageService,
    private _loader: LoaderService,
  ) { }
  ngOnInit(): void {
    let programDetails = this._storageService.get('CurrentProgram');
    if (programDetails) {
      this.programId = programDetails['id'];
    }
  }
  selectedFilter(data, name) {
    const payload: any = new Array();
    data?.forEach(d => {
      payload.push(d?.id);
    });
    if (payload && payload?.length > 0) {
      this.report_filters[name] = payload;
    } else {
      delete this.report_filters[name];
    }

    if((this.reportKey?.toLowerCase() == ReportKeys.MergedComplianceReport) && name == 'vendor' && this.report_filters[name]?.length > 10){
      this._alert.error("You cannot select more than 10 vendors!");
      this.report_filters[name]?.splice(10, 1);
      const newData = this.report_filters[name];
      this.report_filters[name] = [];
      this.report_filters[name] = newData;
    }

  }
  removeFilter(data, i) {
    this.report_filters[data]?.splice(i, 1);
    const newData = this.report_filters[data];
    this.report_filters[data] = newData;
  }
  applyFilter() {
    if((this.reportKey?.toLowerCase() == ReportKeys.MergedComplianceReport) && (this.report_filters?.vendor?.length == 0 || Object.keys(this.report_filters)?.length === 0)){
      this._alert.error("Please select atleast one vendor!");
      return;
    }else{
      this.onSubmit.emit({ filter: this.report_filters });
    }
  }
  hideSidebar() {
    this.onClose.emit({ sidebar: false });
  }
  getFilterData(f){
    if(!f.filter_data){

      this._loader.show()
      let payLoad = {
        "report_name": this.reportKey,
        "filter_name": [f.filter_id]
      };
      
      let _url = `/report/programs/${this.programId}/get-report-filters`;
      this.reportService.post(_url, payLoad).subscribe(
      data => { 
        if(data){
          f['filter_data']=data?.data?.report_filters[f.filter_id]['filter_data'];
        }
        f.isFilter = !f.isFilter;
        this._loader.hide()
      }, err => {
        this._alert.error(errorHandler(err));
      });

    }else{
      f.isFilter = !f.isFilter;
    }   
  }
}
