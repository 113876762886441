import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { FormRendererComponent } from './form-renderer.component';
import { NavTabsHandlerComponent } from './components/nav-tabs-handler/nav-tabs-handler.component';
import { SharedModule } from '../../shared/shared.module';
import { FieldGroupHandlerComponent } from './components/field-group-handler/field-group-handler.component';
import { FieldHandlerComponent } from './components/field-handler/field-handler.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormRendererService } from './form-renderer.service';
import { SvmsHierarchyModule } from 'src/app/library/hierarchy/hierarchy.module';
import { VmsTableModule } from '../smartTable/vms-table.module';
import { ImpactedTimesheetTableComponent } from './impacted-timesheet/impacted-timesheet-table/impacted-timesheet-table.component';


@NgModule({
  declarations: [FormRendererComponent, NavTabsHandlerComponent, FieldGroupHandlerComponent, FieldHandlerComponent, ImpactedTimesheetTableComponent],
  imports: [
    CommonModule,
    VmsTableModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SvmsHierarchyModule
  ],
  exports: [FormRendererComponent],
  providers: [FormRendererService]
})
export class FormRendererModule { }
