import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormBuilderComponent } from './form-builder.component';
import { ToolboxComponent } from './toolbox/toolbox.component';
import { PropertyEditorComponent } from './property-editor/property-editor.component';
import { FormEditorComponent } from './form-editor/form-editor.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputFieldsComponent } from './toolbox/input-fields/input-fields.component';
import { HeadingsComponent } from './toolbox/headings/headings.component';
import { PicklistComponent } from './property-editor/picklist/picklist.component';
import { CustomDataComponent } from './property-editor/custom-data/custom-data.component';
import { NoFieldsComponent } from './no-fields/no-fields.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';

@NgModule({
  declarations: [FormBuilderComponent, ToolboxComponent, PropertyEditorComponent, FormEditorComponent, InputFieldsComponent, HeadingsComponent, PicklistComponent, CustomDataComponent, NoFieldsComponent, CustomFieldsComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgSelectModule
  ],
  exports: [FormBuilderComponent, ToolboxComponent, PropertyEditorComponent, FormEditorComponent, PicklistComponent, CustomDataComponent, CustomFieldsComponent]
})
export class FormBuilderModule { }
