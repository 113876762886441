import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'svms-line-chart',
  templateUrl: './svms-line-chart.component.html',
  styleUrls: ['./svms-line-chart.component.scss']
})
export class SvmsLineChartComponent implements OnInit {
  @Input() data: any;
  @Input() height: number = 280;
  @Input() width: number = 900;
  @Input() margin: { top: 40, right: 20, bottom: 60, left: 60 };
  @Input() xLabel = 'label';
  @Input() yLabel = 'status';
  @Input() onHoverIncreaseEffect = true;

  // legend dimensions
  legendRectSize: number = 6; // defines the size of the colored squares in legend
  legendSpacing: number = 6; // defines spacing between squares

  svg: any;
  lineTooltip: any;
  color: any;

  @Input() set chart(data) {
    if (data.length > 0) {
      d3.select("#lineId").html("");
      this.data = data;

      let keys = Object.keys(this.data[0]);

      this.yLabel = keys[0], this.xLabel = keys[1];

      this.initializeLineComponents();
      

    } else {
      d3.select("svg").remove();
      d3.select("#lineId").html("No Data Available").style("font-size", "1.5em").style("font-weight", 600).style("color", "rgb(185 183 183)");
      return;
    }
  };

  get chart() {
    return this.data;
  }

  constructor() {
  }

  ngOnInit() {

  }

  //Captialize Word
  private titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  private initializeLineComponents() {
    this.data.forEach(d => {
      d[this.yLabel] = +d[this.yLabel];
    });

    this.color = d3.scaleOrdinal(d3.schemePaired);
    this.margin = { top: 40, right: 20, bottom: 60, left: 60 };

    d3.select("svg").remove();

    this.svg = d3.select("#lineId")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    this.lineTooltip = d3.select("#lineHoverId").append("div").attr("class", "barToolTip");

    this.drawPath();
  }

  private drawPath() {

    const yField = this.yLabel,
      xField = this.xLabel;
      
    //Container Height & Width
    const contentWidth = this.width - this.margin.left - this.margin.right;
    const contentHeight = this.height - this.margin.top - this.margin.bottom;

    //------X & Y Axes--------------
    const x = d3
      .scaleBand()
      .rangeRound([0, contentWidth])
      .padding(0.1)
      .domain(this.data.map(d => d[this.xLabel]));

    const y = d3
      .scaleLinear()
      .rangeRound([contentHeight, 0])
      .domain([0, Math.max.apply(Math, this.data.map(d => {
        return d[this.yLabel];
      }))]);

    this.svg.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(d3.axisBottom(x))
      // //roatate labels
      // .selectAll("text")
      // .attr("transform", "translate(-10,0)rotate(-45)")
      // .style("text-anchor", "end")
      //X Axis Label
      //X Axis Label
      .selectAll(".tick text")
      .attr("text-transform", "capitalize")
      .text(d => {
        let label=d.split("-")[0];
        return this.titleCase(label.replace(/_/g, ' '));
      });

    // text label for the x axis
    // this.svg.append("text")
    //   .attr("transform",
    //     "translate(" + (this.width / 2) + " ," +
    //     (this.height - 50) + ")")
    //   .style("text-anchor", "middle").style("text-transform", "capitalize")
    //   .text(this.xLabel);

    this.svg.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(y).ticks(5))
      //Y Axis Label
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text(this.yLabel);

    // text label for the y axis
    // this.svg.append("text")
    //   .attr("transform", "rotate(-90)")
    //   .attr("y", 0 - this.margin.left)
    //   .attr("x", 0 - ((this.height / 2) - (this.margin.bottom)))
    //   .attr("dy", "1em")
    //   .style("text-anchor", "middle").style("text-transform", "capitalize")
    //   .text(this.yLabel);
    //--------line Code-----------
    //Line generator function
    var line = d3.line()
      .x(d => { return (x(d[this.xLabel]) + (x.bandwidth() / 2)); })
      .y(d => { return y(d[this.yLabel]); })
      .curve(d3.curveCardinal);

    //Add path
    this.svg.append("path")
      .datum(this.data)
      .attr("class", "line")
      .attr("d", line).style("fill", "none").style("stroke", "steelblue").style("stroke-width", 2);

    //Line Transition   
    this.svg.selectAll(".line").datum(this.data).attr("d", line).call(this.lineTransition);

    //Line Tooltip
    this.svg.selectAll("dot")
      .data(this.data)
      .enter().append("circle").attr("class", "circle")
      .attr("r", 3)
      .attr("cx", d => { return x(d.xLabel); })
      .attr("cy", d => { return y(d.yLabel); })
      .on("mouseover", (d, i) => {
        let name = (this.titleCase(i[this.xLabel].replace(/_/g, ' ')));
        this.lineTooltip
          .style("left", Math.abs(d.pageX - 20) + "px")
          .style("top", Math.abs(d.pageY - 150) + "px")
          .style("opacity", 1)
          .html(name + ": " + (i[this.yLabel]));
      })
      .on("mouseout", d => {
        this.lineTooltip.transition()
          .duration(500)
          .style("opacity", 0);
      });

  }
  //----------------Line Transition-----------
  private lineTransition(path) {
    path.transition().duration(3000).attrTween("stroke-dasharray", this.tweenDash);
  }

  private tweenDash() {

    let path = this.svg.selectAll(".line");
    var l = path.get(0).getTotalLength();
    console.log(l)
    // let l = path.getTotalLength(),
    let i = d3.interpolateString("0," + l, l + "," + l);

    return function (t) {
      return i(t);
    };

  }
}



