import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { JobDetailsService } from '../../job-details.service';
import { errorHandler } from '../../../../shared/util/error-handler';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {
  candidateInfo = false;
  candidateInfoEdit = {
    visibility: false,
    title: 'Edit'
  };
  jobInfo = false;
  jobInfoEdit = {
    visibility: false,
    title: 'Edit'
  };
  rateInfo = false;
  offerData;
  currentJobid;
  rateInfoEdit = {
    visibility: false,
    title: 'Edit'
  };
  offerPayRate = '';
  offerBillRate = '';
  notes = '';
  timesheetManager = '';
  jobManager = '';
  availableStartDate = '';
  noteInfo = false;
  candidateId;
  offerId;
  constructor(
    private jobService : JobDetailsService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.currentJobid = this.route.snapshot.params['id'];
    this.candidateId = '02fdf6a9-2caf-41ae-a375-d307c9edbd67';
    if(this.currentJobid){
      // this.getOfferDetails();
    }
  }
  toggleCandidateInfo() {
    this.candidateInfo = !this.candidateInfo;
  }
  toggleJobInfo() {
    this.jobInfo = !this.jobInfo;
  }
  toggleRateInfo() {
    this.rateInfo = !this.rateInfo;
  }
  toggleNotes() {
    this.noteInfo = !this.noteInfo;
  }
  editInformation() {
    this.candidateInfoEdit.visibility = !this.candidateInfoEdit.visibility;
    this.candidateInfoEdit.visibility ? (this.candidateInfoEdit.title = "Save") : (this.candidateInfoEdit.title = "Edit");
    this.candidateInfo = false;
  }
  jobInformation() {
    this.jobInfoEdit.visibility = !this.jobInfoEdit.visibility;
    this.jobInfoEdit.visibility ? (this.jobInfoEdit.title = "Save") : (this.jobInfoEdit.title = "Edit");
    this.jobInfo = false;
  }
  rateInformation() {
    this.rateInfoEdit.visibility = !this.rateInfoEdit.visibility;
    this.rateInfoEdit.visibility ? (this.rateInfoEdit.title = "Save") : (this.rateInfoEdit.title = "Edit");
    this.rateInfo = false;
  }
  getOfferDetails(){
    if (this.currentJobid) {
      this.jobService.getOfferDetails(this.currentJobid, this.candidateId, this.offerId).subscribe(data => {
        if (data.data) {
          this.offerData = data.data[0];
        }
      }, (err) => {
        this.alertService.error(errorHandler(err));
      });
    }  
  }
  releaseOffer(){
    const payload = {
      available_start_date: this.availableStartDate,
      bill_rate: this.offerBillRate,
      pay_rate: this.offerPayRate,
      notes: this.notes
    }
    this.jobService.releaseOffer(payload, this.currentJobid, this.candidateId).subscribe(data => {
      if (data) {
        this.alertService.success('Offer released successfully');
      }
    }, (err) => {
      this.alertService.error(errorHandler(err));
    });
  }
}
