<form [formGroup]="addressForm">
  <div class="float-left subtitle-2 mb-16 font-weight-bold">Primary Address</div>
  <div class="btn small btn-light float-right" *ngIf="showSecondaryAddress" (click)="showPrimarySecondaryAddress()"
    [ngClass]="{'disable':isViewClicked}">{{secondaryAddress}}</div>
  <div class="clearfix"></div>
  <div class="primary-address">
    <div class="form-group">
      <label> Country </label>
      <ng-select bindLabel="name" [clearable]="false" placeholder="Select Country" [readonly]="isViewClicked"
        ngDefaultControl formControlName="country" (change)="setCountry('primary')">
        <ng-option [value]="'USA'">USA</ng-option>
        <ng-option [value]="'Canada'">Canada</ng-option>
        <ng-option [value]="'India'">India</ng-option>
      </ng-select>
      <!-- <p class="validation-msg"
        *ngIf="(addressForm.controls.country.touched && addressForm.controls['country'].errors)">
        <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Country is
        Required
      </p> -->
    </div>
    <div class="form-group" *ngIf="addressForm.get('country').value" [ngClass]="{'disable':isViewClicked}">
      <label>Address</label>
      <search-address (setAddress)="getAddress($event)" [adressType]="'geocode'" [country]="countryShortName" id="date"
        #address1 formControlName="address" [readonlyMode]="isViewClicked"></search-address>
    </div>
  </div>
  <div class="Secondary-address" [hidden]="!secondaryaddressOpen">
    <div class="float-left subtitle-2 mb-16 font-weight-bold">Secondary Address </div>
    <div class="clearfix"></div>
    <div class="form-group">
      <label> Country </label>
      <ng-select bindLabel="name" [clearable]="false" placeholder="Select Country" ngDefaultControl
        formControlName="secondarycountry" (change)="setCountry('secondary')" [readonly]="isViewClicked">
        <ng-option [value]="'USA'">USA</ng-option>
        <ng-option [value]="'Canada'">Canada</ng-option>
      </ng-select>
    </div>
    <div class="form-group" *ngIf="addressForm.get('secondarycountry').value" [ngClass]="{'disable':isViewClicked}">
      <label>Address</label>
      <search-address (setAddress)="getSecondaryAddress($event)" [adressType]="'geocode'" #address2
        [country]="secondaryCountryShortName" id="date1" formControlName="secondary_address"
        [readonlyMode]="isViewClicked"></search-address>
    </div>
  </div>

  <div class="float-left subtitle-2 mb-16 font-weight-bold" *ngIf="!hideContact">{{subtitle}}</div>
  <div class="btn small btn-light float-right" *ngIf="showSecondaryContact" (click)="showPrimarySecondaryContact()"
    [ngClass]="{'disable':isViewClicked}">{{secondaryContact}}</div>
  <div class="clearfix"></div>
  <div class="primary-contact" *ngIf="!hideContact">
    <div class="form-group-row">
      <div class="form-group">
        <label>Contact User</label>
        <input type="text" class="form-control" placeholder="Contact Person Name" formControlName="contact_person"
          [ngClass]="{'disable':isViewClicked}" [readonly]="isViewClicked">
      </div>
      <div class="form-group">
        <label>Designation</label>
        <input type="text" class="form-control" placeholder="Enter Designation" formControlName="designation"
          [ngClass]="{'disable':isViewClicked}" [readonly]="isViewClicked">
      </div>
    </div>
    <div class="form-group-row">
      <div class="form-group">
        <label>Phone</label>
        <div class="d-flex align-items-center country-searchable">
          <ng-select [searchable]="false" [clearable]="false" ngDefaultControl formControlName="phoneFomat"
            [ngClass]="{'disable':isViewClicked}">
            <ng-option>
              <img src="../../../assets/images/flag/English (Canada).png" width="20px" class="mr-4" />
              <span>+1</span>
            </ng-option>
            <ng-option>
              <img src="../../../assets/images/flag/us.png" width="20px" class="mr-4" />
              <span>+1</span>
            </ng-option>
          </ng-select>

          <input type="number" class="form-control" placeholder="Contact Person Phone Number" formControlName="phone"
            [pattern]="mobNumberPattern" [ngClass]="{'error':
        addressForm.controls['phone'].invalid && (addressForm.controls['phone'].dirty || addressForm.controls['phone'].touched),'disable':isViewClicked
          }" [readonly]="isViewClicked">
        </div>
        <p *ngIf="
        addressForm.get('phone').touched &&
                (addressForm.get('phone').errors?.pattern || addressForm.get('phone').errors?.required || addressForm.get('phone').invalid)
              " class="validation-msg">
          <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
          Phone number is not valid.
        </p>
      </div>
      <div class="form-group  position-relative">
        <label>Email</label>
        <input type="email" class="form-control pr-32" placeholder="Contact Person Email Address"
          formControlName="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$" [ngClass]="{'error':
      addressForm.controls['email'].invalid && (addressForm.controls['email'].dirty || addressForm.controls['email'].touched),'disable':isViewClicked
        }" [readonly]="isViewClicked">
        <span class="clear-invalid position-absolute" *ngIf="addressForm.get('email').invalid"
          (click)="clearEmail('primary')">x</span>
        <span class="valid-email position-absolute"
          *ngIf="addressForm.get('email').value && addressForm.get('email').valid">
          <icon name="check_circle" size="14px" type="material" cuClass="validation-icon"></icon>
        </span>

        <p *ngIf="
        addressForm.get('email').touched &&
                (addressForm.get('email').errors?.pattern || addressForm.get('email').errors?.required || addressForm.get('email').invalid)
              " class="validation-msg">
          <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
          Please enter a valid email address.
        </p>
      </div>
    </div>
  </div>
  <div class="secondary-contact" [hidden]="!secondaryContactOpen">
    <div class="float-left subtitle-2 mb-16 font-weight-bold">Secondary Contact </div>
    <div class="clearfix"></div>
    <div class="form-group-row">
      <div class="form-group">
        <label>Contact User</label>
        <input type="text" class="form-control" placeholder="Contact Person Name"
          formControlName="secondary_contact_person" [ngClass]="{'disable':isViewClicked}" [readonly]="isViewClicked">
      </div>
      <div class="form-group">
        <label>Designation</label>
        <input type="text" class="form-control" placeholder="Enter Designation" formControlName="secondary_designation"
          [ngClass]="{'disable':isViewClicked}" [readonly]="isViewClicked">
      </div>
    </div>
    <div class="form-group-row">
      <div class="form-group">
        <label>Phone</label>
        <div class="d-flex align-items-center country-searchable">
          <ng-select [searchable]="false" [clearable]="false" ngDefaultControl formControlName="secondary_phoneFomat"
            [ngClass]="{'disable':isViewClicked}">
            <ng-option>
              <img src="../../../assets/images/flag/English (Canada).png" width="20px" class="mr-4" />
              <span>+1</span>
            </ng-option>
            <ng-option>
              <img src="../../../assets/images/flag/us.png" width="20px" class="mr-4" />
              <span>+1</span>
            </ng-option>
          </ng-select>

          <input type="number" class="form-control" placeholder="Contact Person Phone Number"
            formControlName="secondary_phone" [pattern]="mobNumberPattern" [ngClass]="{'error':
        addressForm.controls['secondary_phone'].invalid && (addressForm.controls['secondary_phone'].dirty || addressForm.controls['secondary_phone'].touched),'disable':isViewClicked
          }" [readonly]="isViewClicked">
        </div>
        <p *ngIf="
        addressForm.get('secondary_phone').touched &&
                (addressForm.get('secondary_phone').errors?.pattern || addressForm.get('secondary_phone').errors?.required || addressForm.get('secondary_phone').invalid)
              " class="validation-msg">
          <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
          Phone number is not valid.
        </p>
      </div>
      <div class="form-group  position-relative">
        <label>Email</label>
        <input type="email" class="form-control pr-32" placeholder="Contact Person Email Address"
          formControlName="secondary_email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$"
          [ngClass]="{'error':
      addressForm.controls['secondary_email'].invalid && (addressForm.controls['secondary_email'].dirty || addressForm.controls['secondary_email'].touched),'disable':isViewClicked}"
          [readonly]="isViewClicked">
        <span class="clear-invalid position-absolute" *ngIf="addressForm.get('secondary_email').invalid"
          (click)="clearEmail('secondary')">x</span>
        <span class="valid-email position-absolute"
          *ngIf="addressForm.get('secondary_email').value && addressForm.get('secondary_email').valid">
          <icon name="check_circle" size="14px" type="material" cuClass="validation-icon"></icon>
        </span>
        <p *ngIf="addressForm.get('secondary_email').touched && (addressForm.get('secondary_email').errors?.pattern || addressForm.get('secondary_email').errors?.required || addressForm.get('secondary_email').invalid)"
          class="validation-msg">
          <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
          Please enter a valid email address.
        </p>
      </div>
    </div>
  </div>
</form>