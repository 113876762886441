import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, Type, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName, FormArray } from '@angular/forms';
import { SearchAddressComponent } from 'src/app/shared/components/search-address/search-address.component';
import {
  EventStreamService,
  Events,
  EmitEvent,
} from 'src/app/core/services/event-stream.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { CandidateService } from '../service/candidate.service';
import { errorHandler } from '../../shared/util/error-handler';
import { count, flatMap } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { JobDetailsService } from 'src/app/jobs/job-details/job-details.service';

@Component({
  selector: 'app-create-candidate',
  templateUrl: './create-candidate.component.html',
  styleUrls: ['./create-candidate.component.scss']
})
export class CreateCandidateComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  basicInfo = true;
  qualification = false;
  submitCandidate = false;
  saveCandidateId = '';
  saveCandidateModal = false;
  public loader = false;
  emailvalidation = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  formHeading = 'Add Candidate';
  public personalInfoForm: FormGroup;
  public contactInfoForm: FormGroup;
  public visaInfoForm: FormGroup;
  public submissionForm: FormGroup;
  public toggleDisable = {
    value: false
  };
  public togglePreference = {
    value: false
  };
  // public mobNumberPattern = "^[1-9]{1}[0-9]{9}$";
  public mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  public preferredRatePattern = "^[0-9]{1,4}(\.[0-9][0-9])?$";
  public nameValidation = "^[a-zA-Z _\\\/.’#@&*'-]+$";
  public toggleResumeAutoFill = {
    value: false
  }
  public modalStatus = {
    value: false
  }
  public toggle = {
    sun: {
      title: 'active',
      value: true
    },
    mon: {
      title: 'active',
      value: true
    },
    tue: {
      title: 'active',
      value: true
    },
    wed: {
      title: 'active',
      value: true
    },
    thu: {
      title: 'active',
      value: true
    },
    fri: {
      title: 'active',
      value: true
    },
    sat: {
      title: 'active',
      value: true
    }
  };

  public toggleEligible = {
    value: false
  };
  private parsedResumeData: any;
  candidatePreference: FormGroup;
  preferredAvailability: FormGroup;
  preferredTime: FormGroup;
  sunTime = [1];
  monTime = [1];
  tueTime = [1];
  wedTime = [1];
  thuTime = [1];
  friTime = [1];
  satTime = [1];
  public countryShortName: string;
  resumeFileSize: any;
  resumeFile = {
    name: null,
    time: 0,
    ext: null
  };
  fileNotSupportAlert = false;
  socialProfileArr = [{
    type: null,
    link: null
  }];
  progressBar = false;
  certificationsArr = [];
  credentialsArr = [];
  vaccinationsArr = [];
  skillsArr = [];
  educationArr = [];
  specialityArr = [];
  qualificationArr = [];
  public street_1 = [];
  public street_2 = [];
  public city = [];
  public state = [];
  public zipcode = [];
  editCandidateId = '';
  currencies = [];
  viewMode = false;
  qualificationTypeArr = [];
  qualificationTypeName;
  mbKbFileSize = '';
  isValidFileSize = true;
  afterSaveText = 'Created';
  isSubmitCandidateRoute = false;
  certificationTypeId = '';
  vaccineTypeId = '';
  credentialTypeId = '';
  specialityTypeId = '';
  educationTypeId = '';
  skillTypeId = '';
  currentJobId = '';
  jobData: any;
  directSubmitCandidate = false;
  isUpdateSubmitCandidate = false;
  candidateData;
  submittedCandidateId;
  rateConfig;
  markupConfig = 0;
  showBillRateValidation = false;
  clientDoubleTimeRateFactor;
  clientOverTimeRateFactor;
  uploadedDocs = [];
  constructor(private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private eventStream: EventStreamService,
    private router: Router,
    private alert: AlertService,
    private candidateService: CandidateService,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private jobService: JobDetailsService,
  ) { }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  @HostListener("drop", ["$event"]) ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.uploadResume(evt);
  }
  @ViewChild(SearchAddressComponent) googleAddressComponent: SearchAddressComponent;
  @ViewChild('candidateAddress') candidateAddress;
  ngOnInit(): void {
    this.getCurrencies();
    this.getProgramConfiguration('vendor_markup');
    this.getProgramConfiguration('rate_factor');
    // if route coming from job details page
    if(this.route.snapshot.queryParams['candidateSubmission']){
        this.currentJobId = this.route.snapshot.queryParams['jobId'];
        this.getJobDetails(this.currentJobId );
        this.isSubmitCandidateRoute = true;
        if (this.route.snapshot.queryParams['candidateId']) {
          let type = this.route.snapshot.queryParams['type'];
          this.saveCandidateId = this.route.snapshot.queryParams['candidateId'];
          this.isUpdateSubmitCandidate = true;
          this.fetchCurrentSubmitCandidate(this.saveCandidateId,this.currentJobId);
          if(type == 'updateThensubmit'){
            this.formHeading = 'Edit & Submit Candidate';
          }else if(type == 'submit'){
            this.formHeading = 'Submit Candidate';
            this.basicInfo = false;
            this.qualification = false;
            this.submitCandidate = true;
            this.directSubmitCandidate = true;
          }
          this.getCandidateDetails(this.saveCandidateId);
        }else{
          let type = this.route.snapshot.queryParams['type'];
          if(type == 'addThenSubmit'){
            this.formHeading = 'Add & Submit Candidate';
          }else if(type == 'submit'){
            this.isUpdateSubmitCandidate = true;
            this.formHeading = 'Submit Candidate';
            this.basicInfo = false;
            this.qualification = false;
            this.submitCandidate = true;
            this.directSubmitCandidate = true;
          }
        }
    }else{
      this.isSubmitCandidateRoute = false;
      if (this.route.snapshot.params.id) {
        this.editCandidateId = this.route.snapshot.params.id;
        if (this.route.snapshot.routeConfig.path == 'edit/:id') {
          this.getCandidateDetails(this.editCandidateId);
          this.formHeading = 'Edit Candidate';
        } else if (this.route.snapshot.routeConfig.path == 'view/:id') {
          this.getCandidateDetails(this.editCandidateId);
          this.viewMode = true;
          this.formHeading = 'View Candidate';
        }
      }
    }

    this.getQualifications();
  
    this.personalInfoForm = this.fb.group({
      firstName: [null, [Validators.required,Validators.pattern(this.nameValidation)]],
      middleName: [null, [Validators.pattern(this.nameValidation)]],
      lastName: [null, [Validators.required,Validators.pattern(this.nameValidation)]],
      suffix: [null, ''],
      prefix: [null, ''],
      title: [null, '']
    });
    this.contactInfoForm = this.fb.group({
      email: [null, Validators.required],
      country: [null,''],
      address: [null,''],
      phone: [null, ''],
      phoneFormat: ['+1', '']
    });
    this.visaInfoForm = this.fb.group({
      type: [],
      date: []
    })
    this.submissionForm = this.fb.group({
      availableDate:[null,Validators.required],
      dob:[null,''],
      stateId:[null,''],
      sourcingType:[null,''],
      type:[null,''],
      virtual:[null,''],
      comments:[null,''],
      rateType:[null,''],
      noExceedBillRate:[null,''],
      billRate:[null,Validators.required],
      payRate:[null,Validators.required],
      candidateOverTimeRate:[null,''],
      clientOverTimeRate:[null,''],
      eligibleToWork:[null,''],
      cover:[null,Validators.required],
      portfolio:[null, Validators.required]
    });

    this.preferredTime = this.fb.group({
      from: [null],
      to: [null]
    })

    this.preferredAvailability = this.fb.group({
      'sun': this.fb.array([]),
      'mon': this.fb.array([]),
      'tue': this.fb.array([]),
      'wed': this.fb.array([]),
      'thu': this.fb.array([]),
      'fri': this.fb.array([]),
      'sat': this.fb.array([]),
    })

    this.candidatePreference = this.fb.group({
      'preferred_availability': this.preferredAvailability,
      'locations': [],
      'period': null,
      'currency': ['USD', ''],
      'rate': [null, Validators.pattern(this.preferredRatePattern)]
    });
    this.onAddTime('sun')
    this.onAddTime('mon')
    this.onAddTime('tue')
    this.onAddTime('wed')
    this.onAddTime('thu')
    this.onAddTime('fri')
    this.onAddTime('sat')

    this.submissionForm.get('payRate').valueChanges.subscribe((data) => {
      if(data){
        let calOverTimeRate = this.clientOverTimeRateFactor*this.submissionForm.get('payRate').value;
        let calDoubleTimeRate = this.clientDoubleTimeRateFactor*this.submissionForm.get('payRate').value;
        // let calPayRate = this.submissionForm.get('billRate').value + (1+this.markupConfig['markup']);
        // let calBillRate = this.submissionForm.get('billRate').value  + (this.submissionForm.get('billRate').value*this.markupConfig['markup']) ;
        this.submissionForm.patchValue({
          candidateOverTimeRate : calOverTimeRate.toFixed(2),
          clientOverTimeRate : calDoubleTimeRate.toFixed(2),
        });
        if(this.jobData?.rate_model != 'bill_rate'){
          let calBillRate = this.submissionForm.get('payRate').value  + (this.submissionForm.get('payRate').value*this.markupConfig['markup']) ;
          this.submissionForm.patchValue({
            billRate : calBillRate.toFixed(2),
          });
        }
        if(this.submissionForm.get('billRate').value > this.jobData?.max_bill_rate){
          this.showBillRateValidation = true;
        }else{
          this.showBillRateValidation = false;
        }
      }
    });
    this.submissionForm.get('billRate').valueChanges.subscribe((data) => {
      if( this.jobData?.rate_model == 'bill_rate'){
        if(!this.jobData?.vendor_rate_exceed){
          if(this.submissionForm.get('billRate').value > this.jobData?.max_bill_rate){
            this.showBillRateValidation = true;
          }else{
            this.showBillRateValidation = false;
          }
        }
      }
    });
  }
  onClickToggle(day: string) {
    let toggleValue = null
    switch (day) {
      case 'sun':
        this.toggle.sun.value = !this.toggle.sun.value
        toggleValue = this.toggle.sun.value
        break;
      case 'mon':
        this.toggle.mon.value = !this.toggle.mon.value
        toggleValue = this.toggle.mon.value
        break;
      case 'tue':
        this.toggle.tue.value = !this.toggle.tue.value
        toggleValue = this.toggle.tue.value
        break;
      case 'wed':
        this.toggle.wed.value = !this.toggle.wed.value
        toggleValue = this.toggle.wed.value
        break;
      case 'thu':
        this.toggle.thu.value = !this.toggle.thu.value
        toggleValue = this.toggle.thu.value
        break;
      case 'fri':
        this.toggle.fri.value = !this.toggle.fri.value
        toggleValue = this.toggle.fri.value
        break;
      case 'sat':
        this.toggle.sat.value = !this.toggle.sat.value
        toggleValue = this.toggle.sat.value
        break;
    }
    if (toggleValue) {
      this.candidatePreference.get('preferred_availability').get(day).enable()
    } else {
      this.candidatePreference.get('preferred_availability').get(day).disable()
    }
    this.changeDetectorRef.detectChanges()
  }

  onAddTime(day: string, first = true, disabled = false) {
    if (disabled) {
      return;
    }
    if (!first) {
      switch (day) {
        case 'sun':
          this.sunTime.push(1)
          break;
        case 'mon':
          this.monTime.push(1);
          break;
        case 'tue':
          this.tueTime.push(1);
          break;
        case 'wed':
          this.wedTime.push(1);
          break;
        case 'thu':
          this.thuTime.push(1);
          break;
        case 'fri':
          this.friTime.push(1);
          break;
        case 'sat':
          this.satTime.push(1);
          break;
      }
    }

    (<FormArray>this.preferredAvailability.get(day)).push(
      this.fb.group({
        time: this.fb.group({
          from: [null],
          to: [null]
        })
      })
    )


  }

  onRemoveTime(day: string, index: number, disabled = false) {
    if (disabled) {
      return;
    }
    (<FormArray>this.candidatePreference.get('preferred_availability').get(day)).removeAt(index);
    switch (day) {
      case 'sun':
        this.sunTime.splice(index, 1);
        break;
      case 'mon':
        this.monTime.splice(index, 1);
        break;
      case 'tue':
        this.tueTime.splice(index, 1);
        break;
      case 'wed':
        this.wedTime.splice(index, 1);
        break;
      case 'thu':
        this.thuTime.splice(index, 1);
        break;
      case 'fri':
        this.friTime.splice(index, 1);
        break;
      case 'sat':
        this.satTime.splice(index, 1);
        break;
    }

  }

  onPrefereSubmit() {
    console.log(this.candidatePreference.value)
  }
  setCountry() {
    let val = this.contactInfoForm.get('country').value;
    switch (val) {
      case 'Canada':
        this.countryShortName = "CA";
        this.contactInfoForm.get('phoneFormat').setValue("+12");
        break;
      case 'USA':
        this.countryShortName = "US";
        this.contactInfoForm.get('phoneFormat').setValue("+1");
        break;
      case 'India':
        this.countryShortName = "IN";
        this.contactInfoForm.get('phoneFormat').setValue("+91");
        break;
    }
    this.candidateAddress._value = '';
    this.googleAddressComponent?.getPlaceAutocomplete(this.countryShortName);
  }

  onClickToggleDisable() {
    if (this.toggleDisable.value) {
      this.toggleDisable.value = false;
    } else {
      this.toggleDisable.value = true;
    }
  }

  onClickTogglePreference() {
    if (this.togglePreference.value) {
      this.togglePreference.value = false;
    } else {
      this.togglePreference.value = true;
    }
  }

  uploadResume(event: any, flag?): void {

    if (event) {
      this.progressBar = true;
      this.fileNotSupportAlert = false;
      let fileEvent = event.target?.files ? event.target.files : event.dataTransfer.files;
      let fileExtension = fileEvent[0].name.split('.').pop();
      let fileData = fileEvent[0];
      this.resumeFile['name'] = fileData.name;
      this.resumeFile['time'] = Date.now();
      this.resumeFile['ext'] = fileData.name.split('.').pop();
      if (fileExtension == 'pdf' || fileExtension == 'doc' || fileExtension == 'docx') {
        const fsize = fileEvent[0].size;
        // check file in KB
        if( fsize < 1000000){
          this.isValidFileSize = true;
          this.mbKbFileSize = 'KB';
          this.resumeFileSize = Math.floor(fsize/1000);
          //file to base64
          this.candidateService.encodeToBase64(fileData)
            .then((data) => {
              const fileD = String(data);
              this.resumeFile['raw_without_base64'] = String(data).split(',')[1];
              this.resumeFile['raw'] = data;
              this.onClickToggleAutofill();
            }).catch((err) => {
              console.log("FileError", err)
            });
        }else{
           // check file in MB
          this.mbKbFileSize = 'MB';
          this.resumeFileSize = (fsize / (1024*1024)).toFixed(2);
          if(this.resumeFileSize <= 10){
            this.isValidFileSize = true;
            //file to base64
            this.candidateService.encodeToBase64(fileData)
            .then((data) => {
              const fileD = String(data);
              this.resumeFile['raw_without_base64'] = String(data).split(',')[1];
              this.resumeFile['raw'] = data;
              this.onClickToggleAutofill();
            }).catch((err) => {
              console.log("FileError", err)
            });
          } else{
            this.isValidFileSize = false;
          }
        }
      } else {
        this.fileNotSupportAlert = true;
      }
    }
    this.progressBar = false;
  }
  deleteResume() {
    this.resumeFileSize = '';
    this.isValidFileSize = true;
    this.fileInput.nativeElement.value = '';
    this.resumeFile = {
      name: null,
      time: 0,
      ext: null
    };
  }
  addSocialLink() {
    this.socialProfileArr.push({ type: null, link: null });
  }
  deleteSocialLink(index) {
    console.log(this.socialProfileArr);
    this.socialProfileArr.splice(index, 1);
  }
  continue(type) {
    window.scroll(0, 0);
    if(type == 'goToQualification'){
      if (this.personalInfoForm.valid && this.contactInfoForm.valid && this.candidatePreference.valid) {
        this.basicInfo = false;
        this.qualification = true;
      }else {
        this.alert.error('Please fill the required details');
      }
    }else if(type == 'goToSubmitCandidate'){
      if (this.personalInfoForm.valid && this.contactInfoForm.valid && this.candidatePreference.valid) {
        this.basicInfo = false;
        this.qualification = false;
        this.submitCandidate = true;
      }else {
        this.alert.error('Please fill the required details');
      }
      // this.basicInfo = false;
      // this.qualification = false;
      // this.submitCandidate = true;
    }
  }

  back(type) {
    window.scroll(0, 0);
    if(type == 'goToBasic'){
      this.basicInfo = true;
      this.qualification = false;
      this.submitCandidate = false;
    }else if(type == 'goToQualification'){
      this.basicInfo = false;
      this.submitCandidate = false;
      this.qualification = true;
    }
  }
  addNewCertificate(event) {
    if (event) {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_CERTIFICATE, { obj: null, value: true }));
    }
  }
  addNewSkill(event) {
    if (event) {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_SKILL, { obj: null, value: true }));
    }
  }
  addNewVaccinations(event) {
    if (event) {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_VACCINE, { obj: null, value: true }));
    }
  }
  getCertificateData(data) {
    if (data.isEdit) {
      this.certificationsArr[data.clickedIndex] = data.payload;
    } else {
      this.certificationsArr.push(data.payload);
    }
  }
  getVaccineData(data) {
    if (data.isEdit) {
      this.vaccinationsArr[data.clickedIndex] = data.payload;
    } else {
      this.vaccinationsArr.push(data.payload);
    }
  }
  getSkillData(data) {
    if (data.isEdit) {
      this.skillsArr[data.clickedIndex] = data.payload;
    } else {
      this.skillsArr.push(data.payload);
    }
  }
  addNewEducations(event) {
    if (event) {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_EDUCATION, { obj: null, value: true }));
    }
  }
  addSpecialities(event) {
    if (event) {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_SPECIALITY, { obj: null, value: true }));
    }
  }
  addNewCredential(event) {
    if (event) {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_CREDENTIAL, { obj: null, value: true }));
    }
  }
  getCredentialData(data) {
    if (data.isEdit) {
      this.credentialsArr[data.clickedIndex] = data.payload;
    } else {
      this.credentialsArr.push(data.payload);
    }
  }
  getEducationData(data) {
    if (data.isEdit) {
      this.educationArr[data.clickedIndex] = data.payload;
    } else {
      this.educationArr.push(data.payload);
    }
  }
  getSpecialityData(data) {
    if (data.isEdit) {
      this.educationArr[data.clickedIndex] = data.payload;
    } else {
      this.specialityArr.push(data.payload);
    }
  }
  resetCandidateForm() {
    this.personalInfoForm.reset();
    this.contactInfoForm.reset();
    this.candidatePreference.reset();
    this.socialProfileArr.push({ type: null, link: null });
  }
  saveCandidate(type) {
    this._loader.show();
    const personal = this.personalInfoForm.value;
    const contact = this.contactInfoForm.value;
    const preference = this.candidatePreference.value;
    const payload = {
      name_prefix: personal.prefix,
      first_name: personal.firstName,
      middle_name: personal.middleName,
      last_name: personal.lastName,
      name_suffix: personal.suffix,
      email: contact.email,
      phone_isdcode: contact.phoneFormat,
      phone_number: contact.phone ? contact.phone.toString() : null,
      title: personal.title ? personal.title : null,
      resume: {
        filename: this.resumeFile['name'],
        raw: this.resumeFile['raw'] ? this.resumeFile['raw'] : null
      },
      social_profiles: this.socialProfileArr,
      is_enabled: true,
      addresses: [
        {
          type: "PRIMARY",
          street_1: this.street_1 ? this.street_1[0] : null,
          city: this.city ? this.city[0] : null,
          state: this.state ? this.state[0] : null,
          zipcode: this.zipcode ? this.zipcode[0] : null,
          country: contact.country
        }
      ],
      visa_info: {},
      preferences: {
        rate: {
          currency: preference.currency,
          amount: preference.rate,
          period: preference.period
        },
        locations: preference.locations ? preference.locations : []
      },
      qualifications: [...this.certificationsArr, ...this.educationArr, ...this.skillsArr, ...this.credentialsArr, ...this.vaccinationsArr]
    }
    if (this.editCandidateId) {
      this.candidateService.updateCandidate(this.editCandidateId, payload).subscribe(data => {
        if (data) {
          this.afterSaveText = 'Updated';
          this.saveCandidateModal = true;
          this.resetCandidateForm();
          this._loader.hide();
          if(type == 'submit' && this.isSubmitCandidateRoute){
            this.saveSubmitCandidate();
          }
        }
      }, (error) => {
        this.alert.error(errorHandler(error));
        this._loader.hide();
      });
    } else {
      this.candidateService.saveCandidate(payload).subscribe(data => {
        if (data) {
          this.saveCandidateId = data.id;
          if(type == 'save'){
            this.saveCandidateModal = true;
            this.afterSaveText = 'Created';
          }
          this.editCandidateId = data.id;
          this.resetCandidateForm();
          this._loader.hide();
          if(type == 'submit' && this.isSubmitCandidateRoute){
            this.saveSubmitCandidate();
          }
        }
      }, (error) => {
        this.alert.error(errorHandler(error));
        this._loader.hide();
      });
    }
  }
  saveSubmitCandidate(){
    this._loader.show();
    let submissionData = this.submissionForm.value;
    if(this.submissionForm.valid && !this.showBillRateValidation){
      const payload1 = {
        candidates:[{
          candidate_id:this.saveCandidateId,
          vendor_id: "ecd6a8b2-b651-44d9-b19c-00690755470e",
          available_date: submissionData.availableDate,
          date_of_birth: submissionData.dob,
          state_id: submissionData.stateId,
          candidate_sourcing_type:submissionData.sourcingType,
          candidate_type: submissionData.sourcingType,
          is_eligible_for_us_work: submissionData.eligibleToWork,
          comments: submissionData.comments,
          rate_type: submissionData.rateType,
          bill_rate: submissionData.billRate,
          candidate_pay_rate: submissionData.payRate,
          candidate_over_time_pay_rate: submissionData.candidateOverTimeRate,
          candidate_double_time_pay_rate: submissionData.clientOverTimeRate,
          meta_data: submissionData.files,
          uploads: this.uploadedDocs
        }]
      }
      if(this.submittedCandidateId){
        const payload2 = {
          candidate_id:this.saveCandidateId,
          vendor_id: "ecd6a8b2-b651-44d9-b19c-00690755470e",
          available_date: submissionData.availableDate,
          date_of_birth: submissionData.dob,
          state_id: submissionData.stateId,
          candidate_sourcing_type:submissionData.sourcingType,
          candidate_type: submissionData.sourcingType,
          is_eligible_for_us_work: submissionData.eligibleToWork,
          comments: submissionData.comments,
          rate_type: submissionData.rateType,
          bill_rate: submissionData.billRate,
          candidate_pay_rate: submissionData.payRate,
          candidate_over_time_pay_rate: submissionData.candidateOverTimeRate,
          candidate_double_time_pay_rate: submissionData.clientOverTimeRate,
          meta_data: submissionData.files,
          uploads: this.uploadedDocs
        }
        this.jobService.updateSubmitCandidate(payload2,this.currentJobId,this.saveCandidateId,this.submittedCandidateId).subscribe(data =>{
          if(data){
            this._loader.hide();
            this.afterSaveText = 'Submitted';
            this.saveCandidateModal = true;
          }
        }, (error) => {
            this.alert.error(errorHandler(error));
            this._loader.hide();
        });
      }else{
        this.jobService.saveSubmitCandidate(payload1,this.currentJobId).subscribe(data =>{
          if(data){
            this._loader.hide();
            this.afterSaveText = 'Submitted';
            this.saveCandidateModal = true;
          }
        }, (error) => {
            this.alert.error(errorHandler(error));
            this._loader.hide();
        });
      }
    }else{
      this.alert.error('Please fill the required details properly.');
      this._loader.hide();
    }
  }
  onBackToLIsting(event) {
    if(this.isSubmitCandidateRoute) {
      this.router.navigateByUrl(`jobs/details/job-details/${this.currentJobId}`);
    }
    else {
      this.router.navigateByUrl('candidates/list');
    }
    event.preventDefault();
  }

  viewCandidate(event) {
    this.saveCandidateModal = false;
    this.router.navigateByUrl(`candidates/view/${this.editCandidateId}`);
    event.preventDefault();
  }

  createNewCandidate(event) {
    this.saveCandidateModal = false;
    this.basicInfo = true;
    this.qualification = false;
    this.editCandidateId = '';
    this.router.navigateByUrl('candidates/list/add');
    event.preventDefault();
    window.scroll(0, 0);
  }
  getAddress(place: object) {
    this.street_1[0] = this.getStreet(place);
    this.city[0] = this.getCity(place);
    this.zipcode[0] = this.getPostCode(place);
    this.state[0] = this.getState(place);
  }

  getCity(place) {
    const COMPONENT_TEMPLATE = { locality: 'long_name' };
    const city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }
  getStreet(place) {
    const COMPONENT_TEMPLATE = { route: 'long_name' };
    const street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }
  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' };
    const postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }
  getState(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' };
    const state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }
  getAddrComponent(place, componentTemplate) {
    let result;
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }
  loadAddress(data) {
    let street1 = (data?.address_line1?.trim() != '' && data?.address_line1 != undefined && data?.address_line1 != null) ? (data?.address_line1 + ' ,') : "";
    const city = (data?.city?.trim() !== '' && data?.city != undefined && data?.city != null) ? (data?.city + ' ,') : "";
    let country = (data?.country?.trim() !== '' && data?.country != undefined && data?.country != null) ? (data?.country + ' ,') : "";
    const zip = (data?.zipcode?.trim() !== '' && data?.zipcode != undefined && data?.zipcode != null) ? data?.zipcode : "";
    const state = (data?.state?.trim() !== '' && data?.state != undefined && data?.state != null) ? (data?.state + ' ,') : "";
    if (country && zip == '') {
      country = country.slice(0, -1);
    }
    return street1 + city + state + country + zip;
  }
  getCurrencies() {
    this.candidateService.getCurrency().subscribe(data => {
      if (data) {
        this.currencies = data.currencies;
      }
    }, (error) => {
      this.alert.error(errorHandler(error));
    });
  }
  getCandidateDetails(id) {
    this._loader.show();
    this.candidateService.getCandidateDetail(id).subscribe(
      data => {
        if (data) {
          let candidate = data.candidate;
          this.candidateData = data.candidate;
          this.personalInfoForm.patchValue({
            firstName: candidate.first_name,
            middleName: candidate.middle_name,
            lastName: candidate.last_name,
            suffix: candidate.name_suffix,
            prefix: candidate.name_prefix,
            title: candidate?.title
          });
          this.contactInfoForm.patchValue({
            email: candidate?.email,
            country: candidate?.addresses[0]?.country,
            address: this.loadAddress(candidate?.addresses[0]),
            phone: candidate?.phone_number,
            phoneFormat: candidate?.phone_isdcode
          });
          if (candidate.preferences.locations) {
            this.togglePreference.value = true
          }
          this.candidatePreference.patchValue({
            'locations': candidate.preferences.locations,
            'period': candidate.preferences.rate.period,
            'currency': candidate.preferences.rate.currency,
            'rate': candidate.preferences.rate.amount
          });
          this.socialProfileArr = candidate.social_profiles;
          this.resumeFile['name'] = candidate.resume;
          this.resumeFile['raw'] = candidate?.raw_resume;
          this.resumeFile['raw_without_base64'] = String(this.resumeFile['raw']).split(',')[1];
          this.resumeFileSize = candidate.resume_filesize ? candidate.resume_filesize : '';
          this.resumeFile['ext'] = candidate.resume ? candidate.resume.split('.').pop() : '';
          if (candidate.qualifications.length) {
            candidate.qualifications.filter((can) => {
              if (can.qualification_type) {
                this.getTypeName(can);
              }
            })
          }
          this._loader.hide();
        }
      }, (error) => {
        this.alert.error(errorHandler(error));
        this._loader.hide();
      });
  }

  getTypeName(can) {
    this.candidateService._getQualiFicationType(can.qualification_type.id).subscribe((res) => {
      const resQualification = res.qualifications;
      for (let val of resQualification) {
        if (val.id == can.qualification_id) {
          this.qualificationTypeName = val.name;
          if (can.qualification_type.code == 'CERTIFICATION') {
            can['certification_name'] = this.qualificationTypeName;
            delete can.qualification_type;
            this.certificationsArr.push(can);
          } else if (can.qualification_type.code == 'VACCINATION') {
            can['vaccine_name'] = this.qualificationTypeName;
            delete can.qualification_type;
            this.vaccinationsArr.push(can);
          } else if (can.qualification_type.code == 'CREDENTIAL') {
            can['credential_name'] = this.qualificationTypeName;
            delete can.qualification_type;
            this.credentialsArr.push(can);
          } else if (can.qualification_type.code == 'SPECIALITY') {
            can['speciality_name'] = this.qualificationTypeName;
            delete can.qualification_type;
            this.specialityArr.push(can);
          } else if (can.qualification_type.code == 'EDUCATION') {
            can['education_name'] = this.qualificationTypeName;
            delete can.qualification_type;
            this.educationArr.push(can);
          } else if (can.qualification_type.code == 'SKILL') {
            can['skill_name'] = this.qualificationTypeName;
            delete can.qualification_type;
            this.skillsArr.push(can);
          }
        }
      }
    });

  }
  editPanel(type, data, index) {
    data['clickedIndex'] = index;
    if (type == 'CERTIFICATION') {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_CERTIFICATE, { obj: data, value: true }));
    } else if (type == 'VACCINATION') {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_VACCINE, { obj: data, value: true }));
    } else if (type == 'CREDENTIAL') {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_CREDENTIAL, { obj: data, value: true }));
    } else if (type == 'SPECIALITY') {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_SPECIALITY, { obj: data, value: true }));
    } else if (type == 'EDUCATION') {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_EDUCATION, { obj: data, value: true }));
    } else if (type == 'SKILL') {
      this.eventStream.emit(new EmitEvent(Events.CREATE_CANDIDATE_SKILL, { obj: data, value: true }));
    }
  }

  onClickToggleAutofill() {
    // this.toggleResumeAutoFill.value = !this.toggleResumeAutoFill.value;
    if(this.toggleResumeAutoFill.value === true){
      this.toggleResumeAutoFill.value = false;
    } else{
      this.modalStatus.value = !this.modalStatus.value;
    }
  }

  disableAutoFill() {
    this.toggleResumeAutoFill.value = false;
    this.modalStatus.value = false;
  }

  enabledAutoFill() {
    this.loader = true;
    this.candidateService.getParsedResumeData(this.resumeFile)
      .subscribe(data => {
        if (!data.candidate.value) {
          const err = 'Unable to parse the resume';
          this.alert.warn(err);
          this.toggleResumeAutoFill.value = false;
          this.modalStatus.value = false;
          this.loader = false;
        } else {
          // console.log("Parsed Resume Data...",JSON.stringify(data));

          this.parsedResumeData = data.candidate.value;
          /**
           * Feeling the value in form after Parsing.
           */

           const personal_details = this.parsedResumeData?.Personal_Details;
           const Experience = this.parsedResumeData?.Experience;
           let split_name = String(personal_details.candidate_name).split(' ');
           let middle_name = split_name.length >= 2 ? split_name[1] : '';
           let last_name;
           if(split_name.length >= 2){
             last_name = split_name.slice(1, split_name.length).join(' ');
           } else {
             last_name = split_name[1];
           }

           let latest_title = Experience[0]?.title;
           let phone = personal_details.contact_no.split(/[ -]+/);
           let phone_num = phone.slice(1, phone.length).join();
          this.personalInfoForm.patchValue({
            firstName: split_name[0],
            middleName: middle_name,
            lastName: last_name,
            suffix: '',
            prefix: '',
            title: latest_title
          });
          this.contactInfoForm.patchValue({
            email: personal_details.email,
            country: personal_details.country,
            address: '',
            phone: phone_num,
            phoneFormat: phone[0]
          });
          
          this.toggleResumeAutoFill.value = true;
          this.modalStatus.value = false;
          this.loader = false;
        }
      }, err => {
        this.alert.error(errorHandler(err));
        this.toggleResumeAutoFill.value = false;
        this.modalStatus.value = false;
        this.loader = false;
      });

    // console.log("Parsed Resume data", this.parsedResumeData);
  }

  fillParsedResumeData(){

  }

  checkSocialApp(val) {
    let socialLink = val.link
    if(socialLink.indexOf("linkedin") >= 0) {
      val.type = "linkedin"
    }
    else if(socialLink.indexOf("facebook") >= 0) {
      val.type = "facebook"
    }
    else if(socialLink.indexOf("git") >= 0) {
      val.type = "git"
    }
    else if(socialLink.indexOf("skype") >= 0) {
      val.type = "skype"
    }
    else if(socialLink.indexOf("instagram") >= 0) {
      val.type = "instagram"
    }
  }

  selectSocialApp(app) {
    let socialType = app.type;
    if(socialType.indexOf("linkedin") >= 0) {
      app.link = "https://linkedin.com/";
    }
    else if(socialType.indexOf("facebook") >= 0) {
      app.link = "https://facebook.com/"
    }
    else if(socialType.indexOf("git") >= 0) {
      app.link = "https://github.com/"
    }
    else if(socialType.indexOf("skype") >= 0) {
      app.link = "https://skype.com/"
    }
    else if(socialType.indexOf("instagram") >= 0) {
      app.link = "https://instagram.com/"
    }
  }
  getQualifications(){
    this.candidateService.getQualifications().subscribe((data) => {
      if(data){
        let qualificationArr = data.qualification_types;
        qualificationArr.filter((type) => {
          if (type.code == 'CERTIFICATION') {
            this.certificationTypeId = type.id;
          } else if (type.code == 'VACCINATION') {
            this.vaccineTypeId = type.id;
          } else if (type.code == 'CREDENTIAL') {
            this.credentialTypeId = type.id;
          } else if (type.code == 'SPECIALITY') {
            this.specialityTypeId = type.id;
          } else if (type.code == 'EDUCATION') {
            this.educationTypeId = type.id;
          } else if (type.code == 'SKILL') {
            this.skillTypeId = type.id;
          }
        });
      }
    }, (err) => {
      this.alert.error(errorHandler(err));
    })
  }

  onClickEligible() {
    if (this.toggleEligible.value) {
      this.toggleEligible.value = false;
    } else {
      this.toggleEligible.value = true;
    }
    this.submissionForm.patchValue({
      eligibleToWork:this.toggleEligible.value
    })
  }
    getJobDetails(jobid){
    if(jobid){
      this.jobService.getJobs(`${jobid}`).subscribe(data => {
        if (data.data) {
          this.jobData = data.data[0];
          this.submissionForm.patchValue({
            noExceedBillRate:this.jobData?.max_bill_rate,
          })
        }  
      }, (err) => {
          this.alert.error(errorHandler(err));
      });
    }
  }
  fetchCurrentSubmitCandidate(candidateId, jobId){
    if(candidateId){
      this.jobService.fetchCurrentSubmitCandidate(candidateId, jobId).subscribe(data => {
        if (data) {
          let submitData = data.candidate;
          this.submittedCandidateId = submitData.submission_id;
          this.submissionForm.patchValue({
            availableDate:submitData.available_date,
            dob:submitData.date_of_birth,
            stateId:submitData.state_id,
            sourcingType:submitData.candidate_sourcing_type,
            type:submitData.candidate_type,
            comments:submitData.comments,
            rateType:submitData.rate_type,
            billRate:submitData.bill_rate,
            payRate:submitData.candidate_pay_rate,
            candidateOverTimeRate:submitData.candidate_over_time_pay_rate,
            clientOverTimeRate:submitData.candidate_double_time_pay_rate,
            eligibleToWork:submitData.is_eligible_for_us_work,
          })
        }  
      }, (err) => {
          this.alert.error(errorHandler(err));
      });
    }
  }
  goToSubmittedCandidate(evt){
    if(evt){
      this.router.navigateByUrl(`/jobs/details/job-details/${this.currentJobId}/submitted-candidate`);
    }
  }
  getProgramConfiguration(type){
    this.jobService.getProgramConfig(type).subscribe(data => {
      if (data) {
        if(type == 'vendor_markup'){
          this.markupConfig = JSON.parse(data.config);
        }else if(type == 'rate_factor'){
          this.rateConfig = JSON.parse(data.config);
          this.clientOverTimeRateFactor = this.rateConfig.rate_factors[0].rate_factors[0].rate_factor;
          this.clientDoubleTimeRateFactor = this.rateConfig.rate_factors[1].rate_factors[0].rate_factor;
        }
      } 
    }, (err) => {
        this.alert.error(errorHandler(err));
    });
  }
  uploadDocs(event, type){
    let fileEvent = event.target?.files;
    let fileData = fileEvent[0];
    this.candidateService.encodeToBase64(fileData)
    .then((data) => {
      let raw = data;
      if(type == 'cover'){
        this.uploadedDocs[0] = {
          type: "COVER_LETTER",
          file_name: fileData.name,
          raw: raw
        }
        this.submissionForm.patchValue({
          cover:fileData.name
        })
      }else if(type == 'portfolio'){
        this.uploadedDocs[1] = {
          type: "SAMPLE_PORTFOLIO",
          file_name: fileData.name,
          raw: raw
        }
        this.submissionForm.patchValue({
          portfolio:fileData.name
        })
      }
    }).catch((err) => {
      console.log("FileError", err)
    });
  }
}
