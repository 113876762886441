import { Component, Output, OnInit, EventEmitter,ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName, FormArray } from '@angular/forms';
import {
  EventStreamService,
  Events,
  EmitEvent,
} from 'src/app/core/services/event-stream.service';
import { CandidateService } from '../../service/candidate.service';
import { errorHandler } from '../../../shared/util/error-handler';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { SvmsDatepickerComponent } from 'src/app/shared/components/svms-datepicker/svms-datepicker.component';

@Component({
  selector: 'app-add-vaccinations',
  templateUrl: './add-vaccinations.component.html',
  styleUrls: ['./add-vaccinations.component.scss']
})
export class AddVaccinationsComponent implements OnInit {
  @Output() vaccinationData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('initialDate') initialDate: SvmsDatepickerComponent;
  @ViewChild('expiryDate') expiryDate: SvmsDatepickerComponent;
  closePanel: EventEmitter<boolean> = new EventEmitter();
  @Input() vaccineTypeId;
  addVaccinations = "hidden";
  vaccinationList = [];
  panelHeading = 'Add New Vaccination';
  public addVaccinationsForm: FormGroup;
  clickedIndex ;
  editPanel = false;
  constructor(
    private fb: FormBuilder,
    private eventStream: EventStreamService,
    private alertService: AlertService,
    private candidateService : CandidateService
    ) { }

  ngOnInit(): void {
    this.eventStream.on(Events.CREATE_CANDIDATE_VACCINE, (data) => {
      if (data.value) {
        this.addVaccinations = 'visible';
        if(data.obj != null){
          this.updatePanel(data.obj);
          this.panelHeading = 'Edit Vaccination';
          this.editPanel = true;
          this.clickedIndex = data.obj.clickedIndex;
        }
      } else {
        this.addVaccinations = 'hidden';
      }
    });
    this.getVaccinations();
    this.addVaccinationsForm = this.fb.group({
      vaccinationType: [null, Validators.required],
      vaccinationProvider: [null, Validators.required],
      vaccinationDate: [null,  Validators.required],
      vaccinationExpiryDate: [null,  Validators.required],
      vaccinationNumbr: [null,  Validators.required],
      notes: [null, ''],
      files:[null,'']
    });
  }
  sidebarClose(){
    this.addVaccinations = 'hidden';
    this.addVaccinationsForm.reset();
    this.panelHeading = 'Add New Vaccination';
    this.editPanel = false;
    this.closePanel.emit(true);
  }
  getVaccinations(){
    const vaccineId = this.vaccineTypeId;
    this.candidateService._getQualiFicationType(vaccineId) .subscribe((res) => {
      this.vaccinationList = res.qualifications;
    }, (err) => {
      this.alertService.error(errorHandler(err));
    })
  }
  uploadFiles(event){
    this.addVaccinationsForm.patchValue({
      files:event
    })
  }
  updatePanel(data){
    this.addVaccinationsForm.patchValue({
      vaccinationType: data.qualification_id,
      vaccinationProvider: data.provider,
      vaccinationDate: data.vaccination_date,
      vaccinationExpiryDate: data.expiration_date,
      vaccinationNumbr: data.credential_number,
      notes: data.notes,
      files:data.files
    })
  }
  saveVaccination(action){
    if(this.addVaccinationsForm.valid){
      let data = this.addVaccinationsForm.value;
      const vaccType = this.vaccinationList.filter((item)=>{
        if(item.id == data.vaccinationType){
          return item.name;
        }
      }).map((obj)=>{
          return obj.name;
      })
      const payload = {
        qualification_id: data.vaccinationType,
        provider: data.vaccinationProvider,
        vaccination_date: data.vaccinationDate,
        expiration_date: data.vaccinationExpiryDate,
        credential_number: data.vaccinationNumbr,
        notes: data.notes,
        files: data.files,
        vaccine_name : vaccType
      }
      this.vaccinationData.emit({payload: payload, isEdit:this.editPanel, clickedIndex: this.clickedIndex});
      this.addVaccinationsForm.reset();
      this.initialDate.value = null;
      this.expiryDate.value = null;
      if(action == 'save'){
        this.sidebarClose();
      }else{
        this.editPanel = false;
        this.panelHeading = 'Add New Vaccination';
        this.closePanel.emit(true);
      }
    }else{
      this.alertService.error('Please fill the required fields properly.');
    }
   
  }
}
