<svms-sidebar [title]="panelHeading" [dataVisibility]="addEducation" (onClose)="sidebarClose()">
  <svms-sidebar-body>
    <form [formGroup]="addEducationForm">
      <div class="p-24">
        <div class="form-group">
          <label>Select Education <span class="require">*</span></label>
          <ng-select [clearable]="false" placeholder="Select" [searchable]="false" formControlName="educationType">
            <ng-option [value]="type.id" *ngFor="let type of educationList">{{type.name}}</ng-option>
          </ng-select>
          <p class="validation-msg"
                *ngIf="(addEducationForm.controls.educationType.touched && addEducationForm.controls['educationType'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Education Type 
                is Required
          </p>
        </div>
        <div class="form-group">
          <label>Institution <span class="require">*</span></label>
          <input type="text" class="form-control" placeholder="Enter Details" formControlName="institution">
          <p class="validation-msg"
          *ngIf="(addEducationForm.controls.institution.touched && addEducationForm.controls['institution'].errors)">
          <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Institution 
          is Required
          </p>
        </div>
        <div class="form-group">
          <label>Degree <span class="require">*</span></label>
          <input type="text" class="form-control" placeholder="Enter Details" formControlName="degree">
          <p class="validation-msg"
          *ngIf="(addEducationForm.controls.degree.touched && addEducationForm.controls['degree'].errors)">
          <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Degree 
          is Required
          </p>
        </div>
        <div class="form-group-row">
          <div class="form-group">
            <label>Major <span class="require">*</span></label>
            <input type="text" class="form-control" placeholder="Enter Details" formControlName="major">
            <p class="validation-msg"
            *ngIf="(addEducationForm.controls.major.touched && addEducationForm.controls['major'].errors)">
              <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Major 
              is Required
            </p>
          </div>
          <div class="form-group">
            <label>Minor</label>
            <input type="text" class="form-control" placeholder="Enter Details" formControlName="minor">
          </div>
        </div>
        <div class="form-group-row">
          <div class="form-group position-relative date-field">
            <label>From <span class="require">*</span></label>
            <svms-datepicker [inputdateFormat]="'mm/dd/yy'" [placeholder]="'Select Date'" ngDefaultControl formControlName="from"
              id="date1" I18n #startDate>
            </svms-datepicker>
            <p class="validation-msg"
            *ngIf="(addEducationForm.controls.from.touched && addEducationForm.controls['from'].errors)">
              <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Start Date 
              is Required
            </p>
          </div>
          <div class="form-group position-relative date-field">
            <label>To <span class="require">*</span></label>
            <svms-datepicker [inputdateFormat]="'mm/dd/yy'" [placeholder]="'Select Date'" ngDefaultControl formControlName="to"
              id="date1" I18n #endDate>
            </svms-datepicker>
            <p class="validation-msg"
            *ngIf="(addEducationForm.controls.to.touched && addEducationForm.controls['to'].errors)">
              <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> End Date 
              is Required
            </p>
          </div>
          <div class="form-group position-relative date-field">
            <label>GPA <span class="require">*</span></label>
            <input type="text" class="form-control" placeholder="Enter score" formControlName="gpa">
            <p class="validation-msg"
            *ngIf="(addEducationForm.controls.gpa.touched && addEducationForm.controls['gpa'].errors)">
              <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Score 
              is Required
            </p>
          </div>
        </div>
        <div class="form-group">
          <label>Notes</label>
          <textarea class="form-control notes" rows="4" formControlName="notes" placeholder="Enter Notes"></textarea>
        </div>
        <app-upload-documents uploadLabel="Upload Educational Documents" (uploadDataFiles)="uploadFiles($event)"  [closePanel] = "closePanel"></app-upload-documents>
      </div>
    </form>
  </svms-sidebar-body>
  <svms-sidebar-footer>
    <button type="submit" class="btn btn-outline float-right" (click)="saveEducation('save')">
      <icon size="20px" name="check_circle" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
      Add
    </button>
    <button type="submit" class="btn btn-primary float-right mr-16" (click)="saveEducation('saveThenAdd')">
      Add New
    </button>
  </svms-sidebar-footer>
</svms-sidebar>
