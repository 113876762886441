<h4 class="pl-16">Your Programs</h4>
<a href="javascript:void(0)" class="close-notify" (click)="closeSidebar()">
  <icon name="clear" size="20px" type="material"></icon>
</a>
<div class="clearfix"></div>
<ul class="notify-listing p-16 mt-30">
  <li *ngFor="let p of programs" (click)="selectProgram(p)" [ngClass]="{'active' : selectedProgram === p?.id}">
    <icon cuClass="notify-list-icon" name="layers" size="18px" type="material"></icon>
    <div class="notification-message">
      <p class="title highlight">{{p?.name}} ({{p?.client?.name}}) </p>
      <p class="small mt-4">{{p?.service_type}}</p>
    </div>
    <div class="time">{{p?.start_date}}</div>
  </li>

</ul>
