import { Component, Input, OnInit } from '@angular/core';
import { FullCalendarOptions, EventObject } from 'ngx-fullcalendar';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../../dashboard.service';
import { forkJoin, Subject } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { errorHandler } from '../../../shared/util/error-handler';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { EmitEvent, Events, EventStreamService } from 'src/app/core/services/event-stream.service';
@Component({
  selector: 'app-dashboard-counter',
  templateUrl: './dashboard-counter.component.html',
  styleUrls: ['./dashboard-counter.component.scss']
})
export class DashboardCounterComponent implements OnInit {
  options: FullCalendarOptions;
  events: EventObject[];
  programId: any;
  is_candidate: any;
  user: any;
  _assignment_uuid: any;
  _workerID: any;
  worker_id: any;
  assignmentDetails: any;
  rejected_timesheet: any = new Array();
  draft_timesheet: any = new Array();
  dataLoader = true;
  pending = [];
  donutChartData: any;
  isCountLoaded = false;
  mainWidgetData: any;
  countLoader = false;
  isHeadCountChart = false;
  viewModes = ['month', 'agendaWeek', 'agendaDay', 'today'];
  navButtons = ['title', 'prev', 'next'];

  @Input("widgetData") widgetData = [];
  @Input("assignmentID") set assignmentID(data) {
    this._assignment_uuid = data;
    if (this._assignment_uuid) {
      // this.loader.show();
      // this.dataLoader = true;
      this.events = new Array();
      this.getAssignmentDetails(this._assignment_uuid);
      if (this.mainWidgetData) {
        this.widgetData = JSON.parse(this.mainWidgetData);
      }
      this.isCountLoaded = false;
    }
  };

  get assignmentID() {
    return this._assignment_uuid;
  }
  @Input("workerID") set workerID(data) {
    this._workerID = data;
    if (this._workerID) {
      this.worker_id = this._workerID[0]?.worker_id;
      // if (this.user?.is_candidate) {
      //   this.getHolidayDetails(this.worker_id);
      // }
    }
  };

  get workerID() {
    return this._workerID;
  }

  widgets = [];
  constructor(public router: Router,
    public _storageService: StorageService,
    private dashboardService: DashboardService,
    public _alert: AlertService,
    public loader: LoaderService,
    public storageService: StorageService,
    private eventStream: EventStreamService,
  ) { }

  ngOnInit(): void {
    let programDetails = this._storageService.get('CurrentProgram');
    if (programDetails) {
      this.programId = programDetails['id'];
    }
    let defaultDate = new Date();
    this.options = {
      defaultDate: defaultDate,
      editable: false,
      slotEventOverlap: false,
      eventLimit: 2,
      header: {
        left: this.navButtons.join(','),
        // center: 'title',
        right: this.viewModes.join(',')
      },
    };
    this.events = [
      // { id: 'a', title: 'My Birthday', allDay: true },
    ];

    this.user = this._storageService.get('user');
  }

  ngOnChanges() {
    this.widgets = new Array();
    this.mainWidgetData = JSON.stringify(this.widgetData)
    for (let k in this.widgetData) {
      this.widgets.push(this.widgetData[k]);
      this.dataLoader = false;
    }
    if (this.widgets && this.widgets?.length > 0 && !this.isCountLoaded && this._assignment_uuid) {
      this.rejected_timesheet = new Array();
      this.draft_timesheet = new Array();
      this.getCount();
    } else if (this.widgets && this.widgets?.length > 0 && !this.isCountLoaded && !this.user?.is_candidate) {
      this.rejected_timesheet = new Array();
      this.draft_timesheet = new Array();
      this.getCount();
    }
  }

  loadData(data) {
    if (data?.event?.id) {
      this.router.navigate(['/assignment/details/' + data?.event?.id + '/final'], { queryParams: {tab: 'assignment'}});
    }
  }

  getAssignmentDetails(id) {
    let _url = `/assignment/programs/${this.programId}/assignment/${id}`;
    this.dashboardService.get(_url).subscribe(
      data => {
        this.dataLoader = false;
        this.loader.hide();
        this.assignmentDetails = data?.data?.assignments;
      });
  }

  getHolidayDetails(id) {
    if (id && this.assignmentID) {
      const _url = `/report/programs/${this.programId}/leaves-calendar/${id}?assignment_id=${this.assignmentID}`;
      this.dashboardService.getreport(_url).subscribe(
        data => {
          data?.data?.forEach(d => {
            let h_name;
            if (d?.assignment_code) {
              h_name = d?.holiday_name + ' (' + this.shortAssignmentId(d?.assignment_code) + ')';
            } else { h_name = d?.holiday_name }
            this.events.push({ id: d?.holiday_id, title: h_name, start: d?.holiday_date, allDay: true })
          });
          if (this.events && this.events?.length === 0) {
            this.checkCalenderData();
          }
          this.dataLoader = false;
        }, err => {
          this.checkCalenderData();
          this._alert.error(errorHandler(err));
        });
    }
  }



  checkCalenderData() {
    let isCalendar = this.widgets?.some(w => w?.name === 'today_interviews_widget');
    if (!isCalendar && this.events?.length === 0) {
      this.events = [{ id: 'a', title: 'My Birthday', allDay: true }]
    }
  }

  getCount() {
    let countAPI: any;
    this.isCountLoaded = true;
    this.widgets?.forEach(w => {
      if (w?.name === 'pending_actions_widget') {
        this.getPending(w);
      } else if (w?.name === 'today_interviews_widget') {
        this.getInterviewData(w);
      } else if (w?.name === 'leaves_calendar') {
        this.getHolidayDetails(this.worker_id);
      } else if (w.api) {
        w.api = w?.api?.replace("<PROGRAM_ID>", this.programId);
        w.api = w?.api?.replace("<ASSIGNMENT_ID>", this._assignment_uuid);
        w.api = w?.api?.replace("<WORKER_ID>", this.worker_id);
        w.api = w?.api?.replace("<ORG_ID>", this.user?.organization_id);

        w.icon = w["icon_class"];
        w.color = w["icon_color"];
        if (w?.name === 'headcountchart_location') {
        countAPI = this.dashboardService.getreport(`${w?.api}`);}
        else {
        countAPI = this.dashboardService.get(`${w?.api}`);
        }
        forkJoin(countAPI).subscribe(res => {

          let response = JSON.parse(JSON.stringify(res));


          if (w?.name === 'table_rejected_timesheet') {
            if (response[0]?.data?.timesheet) {
              this.rejected_timesheet = response[0]?.data?.timesheet;
            }
          }
          if (w?.name === 'table_timesheet_to_submit') {
            if (response[0]?.data?.timesheet) {
              this.draft_timesheet = response[0]?.data?.timesheet;
            }
          }
          if (w?.name === 'headcountchart_location') {
            this.isHeadCountChart = true;
            this.donutChartData = response[0]?.data;
          }
          if (response[0]?.data && response[0]?.data?.length > 0 && !response[0]?.data?.count) {
            this.countLoader = true;
            w.count = response[0]?.data[0]?.count || 0;
          } else {
            this.countLoader = true;
            w.count = response[0]?.data?.count || 0;
          }
          if (response[0]?.total_records) {
            w.count = response[0]?.total_records || 0;
          }
          return response[0]?.data?.count;
        }, err => {
          this.countLoader = true;
          this.isCountLoaded = true;
          this._alert.error(errorHandler(err));
        });
      }
    });
  }

  getPending(data) {
    this.pending = new Array();
    let pendingCountAPI: any;
    this.pending = Object.entries(data?.apis[0]).map((e) => (e[1]));
    this.pending?.forEach(p => {
      p.api = p?.api?.replace("<PROGRAM_ID>", this.programId);
      p.api = p?.api?.replace("<ASSIGNMENT_ID>", this._assignment_uuid);
      p.api = p?.api?.replace("<WORKER_ID>", this.worker_id);
      p.api = p?.api?.replace("<ORG_ID>", this.user?.organization_id);
    });

    this.pending.forEach(p => {
      pendingCountAPI = this.dashboardService.get(`${p?.api}`);
      forkJoin(pendingCountAPI).subscribe(res => {
        let response = JSON.parse(JSON.stringify(res));
        if (response[0]?.onboarding_task_stats) {
          p.count = response[0]?.onboarding_task_stats?.pending;
        }
        if (response[0]?.onboarding_background_checklist_stats) {
          p.count = response[0]?.onboarding_background_checklist_stats?.pending;
        }
        if (response[0]?.data) {
          p.count = response[0]?.data[0]?.count;
        }
        if (response[0]?.data?.count) {
          p.count = response[0]?.data?.count;
        } if (response[0]?.onboarding_task_stats?.total_records) {
          p.count = response[0]?.onboarding_task_stats?.total_records;
        }
      }, err => {
        // this._alert.error(errorHandler(err));
      });
    });
  }

  getInterviewData(data) {
    data.api = data?.api?.replace("<PROGRAM_ID>", this.programId);
    data.api = data?.api?.replace("<ASSIGNMENT_ID>", this._assignment_uuid);
    this.dashboardService.getreport(`${data?.api}`).subscribe(res => {
      let response = JSON.parse(JSON.stringify(res));
      response?.data?.forEach(d => {
        if (d?.title.includes('Starting')) {
          d.textColor = '#408426';
        } else {
          d.textColor = '#ffffff';
        }
        this.events.push({ id: d?.contractID, title: d?.title, start: d?.start, end: d?.end, backgroundColor: d?.color, textColor: d?.textColor });
      });
      if (this.events && this.events?.length === 0) {
        this.events = [{ id: 'a', title: 'My Birthday', allDay: true }]
      }
      // this.dataLoader = false;
    }, err => {
      // console.log(err);
      this.checkCalenderData();
      this._alert.error(errorHandler(err));
    });
  }

  changeRoute(path) {
    path.link = path?.link?.replace("<PROGRAM_ID>", this.programId);
    path.link = path?.link?.replace("<ORG_ID>", this.user?.organization_id);
    path.link = path?.link?.replace("<ASSIGNMENT_ID>", this._assignment_uuid);
    if (path?.link == '/contact') {
      this.router.navigate(["/dashboard/contact-support"])
    } else if (path?.link) {
      this.router.navigateByUrl(path?.link)
    }
  }

  createNewAssignment() {
    this.router.navigate(['/assignment/create']);
  }

  showContact() {
    this.router.navigate(['/dashboard/contact-support']);
  }

  navigateAction(link) {
    if (link) {
      this.router.navigate([link]);
    }
  }

  onCreateClick() {
    // { queryParams: { type: 'create' } }
    this.router.navigate(['/timesheet/list/create']);
  }
  shortAssignmentId(assignmentId = '') {
    if (assignmentId?.includes("AM-")) {
      assignmentId = assignmentId?.substr(assignmentId?.lastIndexOf("AM-"))
    }
    return assignmentId;
  }
  removeUnwanted(str) {
    return str?.replace(/MR |Mrs |Miss |Dr |Prof |MX. |IND |Misc /ig, '');
  }

  showTimesheet(data) {
    const date = data?.start_date?.split("-");
    if (date?.length === 3) {
      const start_date = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]), 0, 0, 0, 0);
      let title: string = data?.assignment_title || '';
      if (data?.assignment_code) {
        title += ' (' + this.shortAssignmentId(data?.assignment_code) + ')';
      }
      if (data?.user?.name) {
        this.removeUnwanted(data?.user?.name);
      }
      let timesheet = {
        ...data,
        "assignment_id": data?.assignment_uuid || data?.assignment_id,
        "timesheet_manager_name": data?.timesheet_manager?.name ? this.removeUnwanted(data?.timesheet_manager?.name) : data?.timesheet_manager?.name,
        "worker_name": data?.user?.name ? this.removeUnwanted(data?.user?.name) : 'Robbert William',
        "status": data?.status ? data.status.charAt(0).toUpperCase() + data.status.substr(1).toLowerCase() : '',
        "id": data?.id,
        "work_period": start_date.toLocaleString('default', { month: 'short' }) + ' ' + start_date.getFullYear(),
        "assignment": title,
        "days": data?.total_days,
        "timesheet_id": data?.timesheet_uuid,
        "total_billable_amount": data?.total_amount ? data?.total_amount : '-'
      }
      if (timesheet?.status?.toLowerCase() === 'missing') {
        timesheet.status = null;
      }
      this.storageService.set('timeSheetData', timesheet, true);
    }
    this.router.navigate(['/timesheet/entry']);
  }

}
