
import { HttpService } from '../core/services/http.service';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { errorHandler } from 'src/app/shared/util/error-handler';
import { StorageService } from '../core/services/storage.service';
@Injectable({
  providedIn: 'root'
})
export class JobService {
  mockApi: string;
  constructor(
    public storageService: StorageService,
    private http: HttpClient,
     private _alert: AlertService) {
  }

  get(endpoint) {
    if (endpoint) {
      return this.http.get(environment.API_ENDPOINT + endpoint);
    } else {
      return of(null);
    }
  }
  post(endpoint, data) {
    if (endpoint) {
      return this.http.post(environment.API_ENDPOINT + endpoint, data);
    } else {
      return of(null);
    }
  }
  put(endpoint, data) {
    if (endpoint) {
      return this.http.put(environment.API_ENDPOINT + endpoint, data);
    } else {
      return of(null);
    }
  }
  delete(endpoint, data) {
    if (endpoint) {
      return this.http.delete(environment.API_ENDPOINT + endpoint, data);
    } else {
      return of(null);
    }
  }

  getQualifications() {
    const _url = `/configurator/programs/*/qualification-types`;
    return this.get(_url).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }
  getQualificationsByProgram(id) {
    let programDetails = JSON.parse(this.storageService.get('NewProgramData'));
    let currentProgram = programDetails['program_req_id'];const _url = `/configurator/programs/${currentProgram}/qualification-types?limit=25&page=1${id ? ('&hierarchy_ids=' + id ): ''}`;
    return this.get(_url).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }

}
