import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  baseUrl: string;
 
  constructor(private http: HttpClient) {
    if (environment.demoMode){
      this.baseUrl = environment.MOCK_ENDPOINT;
    }
    else{
      this.baseUrl = environment.API_ENDPOINT;
    }
  }
 
  get(endPoint): Observable<any> {
    return this.http.get(this.baseUrl + endPoint)
  }
}
