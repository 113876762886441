export const dateRangeToTimeStampConverter = (dateRangeValue) => {

    const timeStampdate = [];
    const date2 = dateRangeValue.split('-');
    for (let i = 0; i <= 1; i++) {
        const date = new Date(!isNaN(date2[i]) ? Number(date2[i]) : date2[i] );
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        let date1: any = new Date(year, month, day);
        date1 = date1.getTime();
        timeStampdate.push(date1);
    }
    return timeStampdate;
};
