<div class="content-area ui-content-wrapper">
  <div class="main-wrapper text-center">
    <icon size="30px" name="assistant" type="material" class="blue" cuClass="cu-icon"></icon>
    <h4>@simplify Assist</h4>
    <p class="p-8">@simplify can help you to see hat salaries are candidates seeing for positions like this?</p>
    <a class="btn small btn-light light-bg mt-4 blue">
      <icon size="24px" name="play_circle_filled" type="material" class="icon--on-left blue" cuClass="cu-icon"></icon>
      How to
      Create Job ?
      Program
    </a>

    <div class="middle">
      <img src="../../../../assets/images/repeat-grid.png" alt="" width="123" height="129">
    </div>
  </div>
</div>
