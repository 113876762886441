import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { DetailsComponent } from './details/details.component';
import { AvailableCandidatesComponent } from './components/available-candidates/available-candidates.component';
import { SubmittedCandidatesComponent } from './components/submitted-candidates/submitted-candidates.component';
import { InterviewsComponent } from './components/interviews/interviews.component';
import { OffersComponent } from './components/offers/offers.component';
import { AssignmentComponent } from './components/assignment/assignment.component';
import { ApprovelComponent } from './components/approvel/approvel.component';
import { HistoryComponent } from './components/history/history.component';
import { JobDetailComponent } from './components/job-details/job-details.component';
import { JobDetailsComponent } from './job-details.component';
import { JobDetailsRoutingModule } from './job-details-routing.module';
import { VmsTableModule } from '../../library/table/vms-table.module';
import { GridViewComponent } from './components/grid-view/grid-view.component';
import { ViewProfileComponent } from './components/view-profile/view-profile.component';
import { WithdrawCandidateComponent } from './components/withdraw-candidate/withdraw-candidate.component';
import { GridFilterComponent } from './components/grid-view/grid-filter/grid-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { JobDetailSidebarComponent } from './components/job-detail-sidebar/job-detail-sidebar.component';
import { JobDetailsService } from './job-details.service';
import { PendingApprovalRequestComponent } from './components/pending-approval-request/pending-approval-request.component';
import { InterviewDetailsComponent } from './components/interview-details/interview-details.component';
import { ViewSubmittedCandidateComponent } from './components/view-submitted-candidate/view-submitted-candidate.component';

@NgModule({
  declarations: [JobDetailsComponent, JobDetailComponent, DetailsComponent, AvailableCandidatesComponent, SubmittedCandidatesComponent, InterviewsComponent, OffersComponent, AssignmentComponent, ApprovelComponent, HistoryComponent, GridViewComponent, ViewProfileComponent, WithdrawCandidateComponent, GridFilterComponent, JobDetailSidebarComponent, PendingApprovalRequestComponent, InterviewDetailsComponent, ViewSubmittedCandidateComponent],

  imports: [
    CommonModule,
    JobDetailsRoutingModule,
    SharedModule,
    NgSelectModule,
    VmsTableModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule
  ],
  exports: [
    JobDetailSidebarComponent,WithdrawCandidateComponent,InterviewDetailsComponent
  ],
  providers: [
    JobDetailsService
  ]
})
export class JobDetailsModule { }
