<a class="qr-modal-cta" href="javascript:void(0)" (click)="openQRScaner()">
    <icon name="qr_code_scanner" size="20px" type="material"></icon> {{'QR Code'|translate}}
  </a>
  
  <div class="qr-scanner-modal"  [ngClass]="{'active' : qrScannerModal}">
      <a href="javscript:void(0)" class="close-trigger" (click)="closeQRScanner()">
          <icon name="close" size="24px" type="material"></icon>
      </a>
    <div class="d-flex align-items-stretch justify-content-between">
      <div class="qr-left d-flex align-items-center justify-content-center">
        <figure class="mobile-screen">
          <img src="assets/images/mobile-screen.png" width="860" height="1069" alt="">
        </figure>
        <p class="text-center font-weight-medium d-none">{{'Download mobile app from'|translate}}</p>
        <div class="store-logo text-center mt-12 d-none">
          <figure class="d-inline-block mr-12">
            <img src="assets/images/app-store.png" width="522" height="171" alt="">
          </figure>
          <figure class="d-inline-block">
            <img src="assets/images/google-play.png" width="512" height="168" alt="">
          </figure>
        </div>
      </div>
      <div class="qr-right">
        <h4 class="qr-right-title font-weight-medium">{{'To login to your @simplify Account in Mobile app you need to Scan this QR code first'|translate}}</h4>
        <div class="code-body">
          <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [width]="300" value="{{newData}}" cssClass="bshadow">
          </ngx-qrcode>
        </div>
        <h4 class="how-works font-weight-medium">{{'How it works ?'|translate}}</h4>
        <ol>
          <li>{{'Download @simplify Mobile App'|translate}}</li>
          <li>{{'Open Mobile App & Scan QR Code'|translate}}</li>
          <li>{{'Enter Your Password And Continue'|translate}}</li>
        </ol>
      </div>
    </div>
  </div>
  <div class="qr-scaner-overlay"  [ngClass]="{'active' : qrScannerModal}"></div>
  