import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Output()
  notifyClose: EventEmitter<string> = new EventEmitter<string>();
  notifyCloseText = "active";

  closeNotification() {
    this.notifyClose.emit(this.notifyCloseText = "");
  }

  constructor() { }

  ngOnInit(): void {
  }

}
