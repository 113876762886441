<div class="create-program p-24">
  <a href="" class="back-button" (click)="onBackToLIsting($event)">
    <icon size="24px" name="keyboard_backspace" type="material" cuClass="d-flex"></icon>
  </a>
  <div class="create-program-inner">
    <div class="page-title my-24">
      Create New Program
    </div>
    <div class="create">
      <form [formGroup]="createProgramForm">
        <div class="panel panel-light mb-24">
          <div class="panel-head">
            <h5 I18n>
              <icon size="19px" name="info" theme="outlined" type="material" class="d-flex mr-4" cuClass="d-flex">
              </icon>
              Program Details
            </h5>
          </div>
          <div class="panel-content">
            <label I18n>Client<span class="require">*</span></label>
            <div class="d-flex">
              <div class="pl-0 create-client">
                <div class="form-group">
                  <!--  -->
                  <ng-select bindLabel="name" placeholder="Choose Client" ngDefaultControl formControlName="client"
                    [typeahead]="input$" (change)="changeClient()" required>
                    <ng-option value="{{arr.name}}" *ngFor="let arr of clientList">
                      <span class="initials initials__img">
                        <img *ngIf="arr.logo" src="{{arr.logo}}" alt="" class="img-fluid" />
                        <!-- <img *ngIf="!arr.logo" src="../../../assets/images/no-logo.svg" alt="" class="img-fluid" /> -->
                        <span *ngIf="!arr.logo" class="material-icons option-icon">
                          business
                          </span>
                      </span> <span>{{arr.name}}</span>
                    </ng-option>
                  </ng-select>
                  <p class="validation-msg"
                    *ngIf="createProgramForm.controls.client.touched && createProgramForm.controls['client'].errors">
                    <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Client is Required
                  </p>
                </div>
              </div>
              <div class="ml-8">
                <button type="button" class="btn btn-primary" (click)="createOrg()">
                  <icon name="add" type="material" class="d-flex" cuClass="d-flex"></icon>
                </button>
              </div>
            </div>
            <div class="org-summary" *ngIf="clientInfo">
              <div class="logo">
                <img *ngIf="clientInfo?.logo" src="{{clientInfo?.logo}}" class="img-fluid" width="100" />
                <!-- <img *ngIf="!clientInfo?.logo" src="../../../assets/images/no-logo.svg" class="img-fluid" width="100" /> -->
                <span *ngIf="!clientInfo?.logo" class="material-icons select-option-icon">
                  business
                  </span>
              </div>
              <div class="industry">
                <span *ngIf="clientInfo?.industries?.length>0">{{clientInfo?.industries[0]?.name}}</span>
                <span>
                  Industry
                </span>
              </div>
              <div class="client">
                <span>{{clientInfo?.type}}</span>
                <span>
                  ORG. TYPE
                </span>
              </div>
              <div class="icons" *ngIf="false">
                <a href="">
                  <icon size="18px" name="language" type="material"></icon>
                </a>
                <a href="">
                  <icon size="18px" name="mail" type="material"></icon>
                </a>
              </div>
            </div>
            <div class="form-group">
              <label I18n>Program Type<span class="require">*</span></label>
              <ng-select bindLabel="name" placeholder="Choose Program Type" ngDefaultControl
                formControlName="programType" I18n required (change)="changeMSP()">
                <ng-option [value]="'SELF-SERVICED'">SELF-SERVICED</ng-option>
                <ng-option [value]="'MSP-MANAGED'">MSP-MANAGED</ng-option>
              </ng-select>
              <p class="validation-msg"
                *ngIf="createProgramForm.controls.programType.touched && createProgramForm.controls['programType'].errors">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Program Type is
                Required
              </p>
            </div>
            <div class="form-group"
              *ngIf="createProgramForm.get('programType').value && createProgramForm.get('programType').value == 'MSP-MANAGED'">
              <label I18n>MSP<span class="require">*</span></label>
              <ng-select bindLabel="name" placeholder="Choose Msp" formControlName="msp" [typeahead]="mspInput$" I18n>
                <ng-option [value]="arr.uuid" *ngFor="let arr of mspList">{{arr.name}}</ng-option>
              </ng-select>
              <p class="validation-msg"
                *ngIf="createProgramForm.controls.msp.touched && createProgramForm.controls['msp'].errors">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> MSP is Required
              </p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Program Name<span class="require">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter Your Program Name" formControlName="name"
                    required [ngClass]="{'error':
                    createProgramForm.controls['name'].invalid && (createProgramForm.controls['name'].dirty || createProgramForm.controls['name'].touched)
                      }" minlength="5">
                  <p class="validation-msg"
                    *ngIf="createProgramForm.controls.name.touched && createProgramForm.controls['name'].invalid && createProgramForm.controls['name'].errors?.required">
                    <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Program Name is
                    Required
                  </p>
                  <p class="validation-msg" *ngIf="createProgramForm.controls.name.errors?.minlength">
                    Name must be at least 5 characters long.
                  </p>
                  <p class="validation-msg" *ngIf="duplicateProgram">{{duplicateProgram}}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Program ID </label>
                  <div class="program-id">
                    <input type="text" class="form-control" placeholder="Program ID" formControlName="programID"
                      [readonly]="editProgramId" required />
                    <span class="cursor-pointer" *ngIf="createProgramForm.get('programID').value"
                      (click)="editProgramsId()">
                      <icon size="15px" name="edit" type="material" cuClass="edit-icon">
                      </icon>
                    </span>
                  </div>
                  <p class="validation-msg" *ngIf="programIdValdation">{{programIdValdation}}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Effective Date</label>
                  <!-- <svms-datepicker [inputdateFormat]="" ngDefaultControl formControlName="date" id="date1" I18n #dateFiled>
                  </svms-datepicker> -->

                  <svms-datepicker [inputdateFormat]="" ngDefaultControl
                    formControlName="date" id="date1" I18n #dateFiled>
                  </svms-datepicker>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-light mb-24">
          <div class="panel-head">
            <h5>
              <icon size="19px" name="people_alt" theme="outlined" type="material" class="d-flex mr-4" cuClass="d-flex">
              </icon>
              Program Members
            </h5>
          </div>
          <div class="panel-content">
            <app-program-manage [formType]="'CreateProgram'" (showCreateUser)="addUserToProgram($event)" [edit]="'true'"></app-program-manage> 
          </div>
        </div>
        <div class="panel panel-light mb-24">
          <div class="panel-head">
            <h5>
              <icon size="19px" name="category" theme="outlined" type="material" class="d-flex mr-4" cuClass="d-flex">
              </icon>
              Modules
            </h5>
          </div>
          <div class="panel-content module-panel">
            <app-modules [moduleData]="moduleGroup"  (moduleDataChange)="onClickModule($event)"></app-modules>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center mt-24 pb-32">
          <button type="submit" class="btn btn-primary" (click)="onFormSubmit()" [disabled]="!createProgramForm.status">
            Continue
            <icon size="20px" name="chevron_right" type="material" class="icon--on-right" cuClass="icon-cu"></icon>
          </button>
          <!-- Success modal html goes here-->
          <div class="success-modal position-fixed bg-light rounded-sm pt-64" [ngClass]="{'active' : showSuccessModal}">
            <a href="javascript:void(0)" class="close-success-modal" (click)="closeSuccessModal()">
              <icon size="18px" name="close" type="material" cuClass="success-close"></icon>
            </a>
            <div class="success-modal-body text-center pb-64">
              <icon size="69px" name="check_circle" type="material" class="success-icon" cuClass="success-close"></icon>
              <h3 class="mt-8">
                Your program has been successfully created
              </h3>
            </div>
            <div class="modal-footer p-24">
              <figure class="user-avatar float-left rounded-sm text-center overflow-hidden mr-12">
                <span *ngIf="programName">{{programName | shortName :'last'}} </span>
              </figure>
              <div class="user-info float-left">
                <p>{{programName}}</p>
                <div class="user-status rounded-sm mt-4 inactive pl-8 pr-8 d-inline-block">
                  <span class="rounded-circle d-inline-block mr-8"></span> Inactive
                </div>
              </div>
              <button class="btn float-right btn-secondary" (click)="gotToconfiguration()">Configure the Program <icon
                  size="20px" name="chevron_right" type="material" class="icon--on-right" cuClass="icon-cu"></icon>
              </button>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="modal-backdrop position-fixed" [ngClass]="{'active' : showSuccessModal}"></div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-organization-create [title]="'Create New Client'" [icon]="'domain'" [type]="'TYPE_CLIENT'">
</app-organization-create>
<app-create-new-user [showUserCreate]="showUserCreate"></app-create-new-user>
