import { Component, Input, OnInit } from '@angular/core';
import { EventStreamService, Events, EmitEvent  } from 'src/app/core/services/event-stream.service';

@Component({
  selector: 'app-impacted-timesheet-table',
  templateUrl: './impacted-timesheet-table.component.html',
  styleUrls: ['./impacted-timesheet-table.component.scss']
})
export class ImpactedTimesheetTableComponent implements OnInit {

  showTimesheets:boolean = false;
  timesheets:any= undefined;
  @Input() renderForm;
  @Input() tabName;
  public months = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ]; 

  constructor(private eventStream: EventStreamService) { }

  ngOnInit(): void {
    this.eventStream.on(Events.SHOW_IMPACTED_TIMESHEETS, (data) => {
      if (data) {
        data.impactedTimesheets?.forEach(duration=>{​​​​
          const date= duration?.start_date?.split("-");
          duration.selected= !duration?.is_enable;
          duration.display_timesheet_code= this.shortTimesheetId(duration?.code);
          if(date?.length === 3){
            const start_date= new Date(parseInt(date[0]), parseInt(date[1]) -1, parseInt(date[2]),0,0,0,0); //new Date(duration?.start_date+ " 00:00:00"); fixed for safari
            duration.display_value= this.months[start_date.getMonth()]+ ' '+ start_date.getFullYear();
          }          
        }​​​​);
        this.timesheets= data.impactedTimesheets;
        if(data?.showTable){
          this.showTimesheets= true;
        }
        if(data?.showTable === false){
          this.showTimesheets= false;
        }
      } 
    });

  }
  
  updateControl(timesheet){
    timesheet.selected= !timesheet.selected;
    this.renderForm[this.tabName]?.get('impacted_timesheet_data')?.setValue(this.timesheets);
  }

  shortTimesheetId(timesheetId = '') {
    if (timesheetId?.includes("TS-")) {
      timesheetId = timesheetId?.substr(timesheetId?.lastIndexOf("TS-"))
    }
    return timesheetId;
  }

}
