<svms-sidebar size="small" [title]="title" [dataVisibility]="withdrawSidebar" (onClose)="sidebarClose()">
  <svms-sidebar-body>
    <div class="pl-24 pr-24 pt-32 pb-32"  [formGroup]="withdrawForm">
      <div class="form-group">
        <label>{{label}} <span class="require">*</span></label>
        <ng-select [clearable]="false" placeholder="Select Reason" [searchable]="false" formControlName="reason">
          <ng-option [value]="'Reason 01'">Reason 01</ng-option>
          <ng-option [value]="'Reason 02'">Reason 02</ng-option>
        </ng-select>
      </div>
      <div class="form-group">
        <label>Notes</label>
        <textarea class="form-control" placeholder="Enter Additional Notes" rows="7" cols="12" maxlength="3000" formControlName="notes"></textarea>
      </div>
    </div>
  </svms-sidebar-body>
  <svms-sidebar-footer>
      <button type="submit" [disabled]="!withdrawForm.valid" class="btn btn-primary float-right" (click)="withdrawCandidate()">
        <icon size="20px" name="check_circle" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
        {{btnLabel}}
      </button>
  </svms-sidebar-footer>
</svms-sidebar>
