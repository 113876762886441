import { Component, EventEmitter, Input, OnInit,Output,ViewChild } from '@angular/core';
import { EventStreamService, Events, EmitEvent } from '../../../core/services/event-stream.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName } from '@angular/forms';
import { ProgramService } from '../../../programs/program.service';
import {SvmsUploadAvatarComponent} from '../../../shared/components/svms-upload-avatar/svms-upload-avatar.component';

@Component({
  selector: 'app-vendor-basic-info',
  templateUrl: './vendor-basic-info.component.html',
  styleUrls: ['./vendor-basic-info.component.scss']
})
export class VendorBasicInfoComponent implements OnInit {

  public vendorCreate: FormGroup;
  public status;
  @Input()editdata;
  @Input()isViewClicked;
  public toggle = {
    title: 'active',
    value: true
  };
  public industries = [];
  isImagePresent: boolean = false;

  @ViewChild(SvmsUploadAvatarComponent) logoComponent:SvmsUploadAvatarComponent;
  public isNameValid = true;
  duplicateOrg = "Vendor name already in use.";
  constructor(public eventStream: EventStreamService, private fb: FormBuilder, private _programService: ProgramService) { }

  ngOnInit(): void {

    this.vendorCreate = this.fb.group({
      logo: ['', ''],
      org_name: ['', Validators.required],
      org_type: ['Vendor'],
      industry: [null, Validators.required],
      status: ['','']
    });
    this.getIndustries();
    this.vendorCreate.get('status').setValue(true);
 }

  getCropImage(e) { 
     if (e) {
      this.isImagePresent = true; 
     } else {
      this.isImagePresent = false; 
     } 
  }
  ngOnChanges(){
    this.loadData();
  }

loadData(){
  let industry= [];
  if(this.editdata && this.editdata?.length>0){
  this.editdata[0]?.industry?.map(data => {
    industry.push(data?.id);
  })
    this.vendorCreate?.patchValue({
      logo: this.editdata[0]?.logo !== ''? this.editdata[0]?.logo:'',
      client_inactivereason: this.editdata[0]?.status !== ''? this.editdata[0]?.status:'',
      org_name: this.editdata[0]?.name !== ''? this.editdata[0]?.name:'',
      org_type: this.editdata[0]?.type !== ''? this.editdata[0]?.type:'Vendor',
      industry: industry ? industry:'',
      status: this.editdata[0]?.status != ''? this.editdata[0]?.status:false
    });
    let toggle = this.editdata[0]?.status;
    if(toggle){
      this.toggle.value = true;
      this.toggle.title = 'active';
    }
    else {
      this.toggle.value = false;
      this.toggle.title = 'inactive';
    }
  }
  else {
    this.vendorCreate?.reset();
    this.vendorCreate?.get('org_type').setValue('Vendor');
    this.vendorCreate?.get('status').setValue(true);
    this.toggle.value = true;
    this.toggle.title = 'active';
  }
}

  onClickToggle() {
    if (this.toggle.value) {
      this.toggle.value = false;
      this.toggle.title = 'inactive';
      this.vendorCreate.get('status').setValue(false);
    } else {
      this.toggle.value = true;
      this.vendorCreate.get('status').setValue(true);
      this.toggle.title = 'active';
    }
    this.status = this.toggle.value;
  }

  getIndustries() {
    this._programService.get('/configurator/resources/industries').subscribe(
      data => {
        this.industries = data.industries;
      }
    )
  }

}

