<li>
  <div class="radio-wrapper">
    <label class="radio-container" *ngFor="let t of themes" [class]="t">
      <input type="radio"  [(ngModel)]="colorTheme" (click)="handleClick($event, t)"  [value]="t" name="theme">
      <span class="checkmark" [style.background]="getNavColor(t)" [style.border-color]="getNavColor(t)">
        <span class="second-color" [style.background]="getPrimaryColor(t)"></span>
      </span>
    </label>
  </div>
</li>
<!-- <li>
  <a class="pt-0 pb-0 pl-16 pr-16 d-block" href="javascript:void(0)" (click)="switchColorMode()">
    <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="nights_stay" size="20px" type="material"></icon>
    Enable Dark Mode
    <icon class="toggle-off float-right mr-0" cuClass="sidebar-icon-submenu" name="toggle_off" size="40px" type="material"></icon>
  </a>
</li> -->

