<div class="panel panel-light p-0">
  <div class="panel-head">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="d-flex align-items-center ">
        Distribute to Vendors
      </h4>
      <div>
        <div class="toggle-disable">
          <div class="toggle-panel-inner">
            <span class="toggle-switch" [ngClass]="toggleDisable?.value ? 'toggle-on' : 'toggle-off'"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-content">
    <div class="radio-wrapper">
      <label class="radio-container ">
        <input type="radio" checked>
        <span class="checkmark"></span>
      </label>
      <p class="text-dark ">Automatic Distribution</p>
   
    </div>
    <div class="ml-24 mt-12">
      <p class="text-dark mb-8 font-weight-medium">
        Distribution Method
      </p>
      <div class="radio-wrapper">
        <label class="radio-container ">
          <input type="radio" checked>
          <span class="checkmark"></span>
        </label>
        <p>Automatically Distribute upon Submit</p>
      </div>
      <div class="radio-wrapper">
        <label class="radio-container ">
          <input type="radio" checked>
          <span class="checkmark"></span>
        </label>
        <p>Automatically Distribute upon Final Approval</p>
      </div>
    </div>
    <div class="panel panel-light mt-16">
      <div class="panel-content">
<p class="text-dark mt-16 font-weight-medium"> Industry matches</p>
<div class="d-flex flex-wrap align-items-center">
  <div class="badge badge-blue mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-blue mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-blue mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>

</div>
<div class="form-group mt-16 mb-0">
  <label>Add more Industries</label>
  <input type="text" class="form-control" placeholder="Search Industries" />
</div>
<div class="d-flex flex-wrap align-items-center">
  <div class="badge badge-light mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-light mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-light mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>

</div>


<p class="text-dark mt-16 font-weight-medium"> Region match</p>
<div class="d-flex flex-wrap align-items-center">
  <div class="badge badge-blue mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-blue mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-blue mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>

</div>
<div class="form-group mt-16 mb-0">
  <label>Add more Regions</label>
  <input type="text" class="form-control" placeholder="Search Regions" />
</div>
<div class="d-flex flex-wrap align-items-center">
  <div class="badge badge-light mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-light mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-light mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>

</div>

<p class="text-dark mt-16 font-weight-medium mb-12"> Matched Vendors</p>
<div class="d-flex flex-wrap align-items-center border rounded-sm px-16 pb-12">
  <div class="badge badge-blue mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-blue mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-blue mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>

</div>
<div class="form-group mt-16 mb-0">
  <label>Additional Vendors</label>
  <div class="position-relative search-job" >
    <ng-select placeholder="Search Vendors">
        <ng-option>skills</ng-option>
    </ng-select>
    <span class="cursor-pointer search">
        <icon size="20px" name="search" type="material" class="d-flex" cuClass="d-flex">
        </icon>
    </span>
</div>
</div>
<div class="d-flex flex-wrap align-items-center">
  <div class="badge badge-light mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-light mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>
  <div class="badge badge-light mt-12">
    Vendor A
    <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
    </icon>
  </div>

</div>

      </div>
    </div>
    <div class="radio-wrapper my-16">
      <label class="radio-container ">
        <input type="radio" checked>
        <span class="checkmark"></span>
      </label>
      <p class="text-dark">Tiered Distribution Schedule</p>
    </div>

    <div class="panel panel-light mt-16">
      <div class="panel-content mt-24">
        <div class="form-group">
          <label>Distribution Schedule</label>
          <ng-select placeholder="Select Distribution Schedule 1" (change)="distributionScheduleSelected($event)">
            <ng-option *ngFor="let item of distributionSchedules" [value]="item">{{item?.name}}</ng-option>
          </ng-select>
        </div>
        <ng-container *ngFor="let vendorSchedule of vendorsList; let ind = index">
          <p class="text-dark mt-16 font-weight-medium" *ngIf="vendorSchedule?.schedule_unit==='IMMEDIATE'">
            Immediately Release To
            <!-- {{vendorSchedule?.schedule_unit}} -->
          </p>
          <p class="text-dark mt-16 font-weight-medium" *ngIf="vendorSchedule?.schedule_unit !='IMMEDIATE'">
            After {{vendorSchedule?.schedule_value}} {{vendorSchedule?.schedule_unit | titlecase }}, release to:
          </p>
          <div class="d-flex flex-wrap align-items-center">
            <div class="badge badge-blue mt-12" *ngFor="let item of vendorSchedule?.vendors">
              {{item?.vendor?.name}}
              <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
              </icon>
            </div>
          </div>
          <div class="form-group mt-16 mb-0">
            <label>Add more vendors</label>
            <input type="text" class="form-control" placeholder="Search more vendors" [debounce]="300"
              (debounceChange)="addMoreVendor($event,ind)" />
            <!-- <ng-select placeholder="Select Distribution Schedule 1"  (change)="moreVendorSelected($event)" #moreVendor [typeahead]="moreVendorTypehead">
            <ng-option *ngFor="let item of moreSearchVendorList" [value]="item">{{item?.vendor?.name}}</ng-option>`
          </ng-select> -->
          </div>
          <div class="d-flex flex-wrap align-items-center">
            <div class="badge badge-light mt-12" *ngFor="let item of vendorSchedule?.vendorSuggestion"
              (click)="addToVendors(item, ind)" [hidden]="hideFromSuggestion(item.id, ind)">
              {{item?.vendor?.name}}
              <icon size="14px" name="cancel" type="material" class="d-flex ml-8" cuClass="d-flex">
              </icon>
            </div>
          </div>
        </ng-container>
        
        <div class="radio-wrapper my-16">
          <label class="radio-container ">
            <input type="radio" checked>
            <span class="checkmark"></span>
          </label>
          <p class="text-dark">Require Manual Distribution after Job Submit</p>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="panel panel-light mt-24">
  <div class="panel-head">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="d-flex align-items-center ">
        Direct sourcing
      </h4>
      <div>
        <div class="toggle-disable">
          <div class="toggle-panel-inner">
            <span class="toggle-switch" [ngClass]="toggleDisable?.value ? 'toggle-on' : 'toggle-off'"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-content">
    <div class="no-gutters row mb-16">
      <div class="col">
        <div class="panel panel-light p-16 mr-8 h-80">
          <div class="d-flex align-items-center justify-content-between">
            <img src="../../../../assets/images/indeed.png" class="img-fluid" width="99" height="25">
            <span class="cursor-pointer">
              <icon size="24px" name="check_circle" type="material" class="d-flex text-success" cuClass="d-flex">
              </icon>
            </span>
          </div>

        </div>
      </div>
      <div class="col">
        <div class="panel panel-light p-16 h-80">
          <div class="d-flex align-items-center justify-content-between">
            <img src="../../../../assets/images/zip_recruiter.png" class="img-fluid" width="99" height="25">
            <span class="cursor-pointer">
              <icon size="24px" name="check_circle" type="material" class="d-flex text-success" cuClass="d-flex">
              </icon>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="no-gutters row mb-16">
      <div class="col">
        <div class="panel panel-light p-16 mr-8 h-80">
          <div class="d-flex align-items-center justify-content-between">
            <img src="../../../../assets/images/linkedin.png" class="img-fluid" width="99" height="25">
            <span class="cursor-pointer">
              <icon size="24px" name="check_circle" type="material" class="d-flex text-grey" cuClass="d-flex">
              </icon>
            </span>
          </div>

        </div>
      </div>
      <div class="col">
        <div class="panel panel-light p-16 h-80">
          <div class="d-flex align-items-center justify-content-between">
            <img src="../../../../assets/images/indeed.png" class="img-fluid" width="99" height="25">
            <span class="cursor-pointer">
              <icon size="24px" name="check_circle" type="material" class="d-flex text-success" cuClass="d-flex">
              </icon>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row no-gutters mt-24 mb-16">
  <div class="col-12 d-flex align-items-center justify-content-between">
    <button type="button" class="btn btn-outline">
      <icon size="24px" name="keyboard_backspace" theme="outlined" type="material" class="d-flex" cuClass="d-flex">
      </icon>
    </button>
    <button type="button" class="btn btn-primary" (click)="saveDistribution()">
      <icon size="20px" name="check_circle" type="material" class="icon--on-Left mr-8" cuClass="icon-cu" >
      </icon> Submit
    </button>
  </div>
</div>
