import { StorageService } from 'src/app/core/services/storage.service';
import { JobService } from 'src/app/jobs/job.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
@Component({
  selector: 'app-job-estimate',
  templateUrl: './job-estimate.component.html',
  styleUrls: ['./job-estimate.component.scss']
})
export class JobEstimateComponent implements OnInit {
  jobEstimateForm: FormGroup;
  submitted = false;
  programId: string = undefined;
  data: any = undefined;
  place_data = '%';
  @Input() isCreateEstimate = 'hidden';

  @Input() set estimatorFields(value: any) {
    this.data = value;
    if (this.jobEstimateForm)
      this.jobEstimateForm.patchValue(value);
  }
  @Output() onCreateClose = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private alertService: AlertService,
    public jobService: JobService, private localStorage: StorageService) { }

  get f() { return this.jobEstimateForm.controls; }

  ngOnInit(): void {

    const currentProgram = this.localStorage.get('CurrentProgram');
    this.programId = currentProgram?.id;

    this.jobEstimateForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
      num_resources: [1, [Validators.required ,Validators.pattern(/^[0-9]\d*$/)]],
      hours_per_day: [8, [Validators.required ,Validators.pattern(/^[0-9]\d*$/)]],
      week_working_days: [5, [Validators.required ,Validators.pattern(/^[1-9]\d*$/)]],
      formatted_working_days: [null, [Validators.required]],
      working_hours: [null, [Validators.required]],
      adjustment_type: ['percentage'],
      rate_type: ['per_hour'],//per_hour / per_day
      single_initial_budget: [null, [Validators.required]],
      single_net_budget: [null, [Validators.required]],
      additional_amount: [0, [Validators.required]],
      single_gross_budget: [null, [Validators.required]],
      adjustment_value: ['', [Validators.required]],
      net_budget: [null, [Validators.required]],
      min_bill_rate: ['', [Validators.required]],
      max_bill_rate: ['', [Validators.required]],
    });
    if (this.jobEstimateForm)
      this.jobEstimateForm.patchValue(this.data);
  }

  sidebarClose(allowUpdate) {
    let values = undefined;
    if (allowUpdate) {
      values = this.jobEstimateForm.value;
    }
    this.isCreateEstimate = 'hidden';
    this.onCreateClose.emit(values);
  }

  async getWorkingHoursEstimate() {
    const isValidData = await this.validateInput();
    if (isValidData) {
      const week_working_days = this.jobEstimateForm.get('week_working_days').value;
      const hours_per_day = this.jobEstimateForm.get('hours_per_day').value;
      let start_date: any = this.getDate(this.jobEstimateForm.get('start_date').value);
      let end_date: any = this.getDate(this.jobEstimateForm.get('end_date').value);
      if (start_date && end_date) {
        start_date = this.getFormattedDate(new Date(start_date));
        end_date = this.getFormattedDate(new Date(end_date));
        if (start_date && end_date) {
          this.jobService.get(`/rate-estimate/programs/${this.programId}/working-hours-estimate?week_working_days=${week_working_days}&hours_per_day=${hours_per_day}&start_date=${start_date}&end_date=${end_date}`).subscribe(data => {
            if (data && data.data) {
              this.jobEstimateForm.patchValue(data.data);
              this.getResourceBudget();
            }
          });
        }
      }

    }
  }
  getDate(date) {
    if(date) {
    const splittedDate = date.split('-');
       const yy = splittedDate[0];
       const month = splittedDate[1];
        const dd = splittedDate[2];
          return  new Date(yy, month-1, dd); 
    }
  }

  validateInput() {
    let request = this.jobEstimateForm.value;
    if(request && request.adjustment_type === 'fixed' && typeof(request.adjustment_value) === 'number' ) {
      let value = request.adjustment_value.toFixed(2);
       this.jobEstimateForm.patchValue({adjustment_value: value});
     } else {
      
     }
    if (request.week_working_days < 0 || request.week_working_days > 7) {
      this.showValidationError('Please provide valid working days');
      return false;
    } else if (request.hours_per_day < 0 || request.hours_per_day > 24) {
      this.showValidationError('Please provide valid hours');
      return false;
    } else if (request.adjustment_value < 0) {
      this.showValidationError('Please provide valid adjustment value');
      // return false;
    } else if (request.additional_amount < 0) {
      this.showValidationError('Please provide valid additional amount');
      return false;
    } else if (request.num_resources < 0) {
      this.showValidationError('Please provide valid positions');
      return false;
    } else {
      return true;
    }
  
  }

  showValidationError(message) {
    this.alertService.error(message);
  }

  async getResourceBudget() {
    const isValidData = await this.validateInput();
    if (isValidData) {
      let request = JSON.parse(JSON.stringify(this.jobEstimateForm.value));
      delete request['foundational'];
      request.total_hours = request.working_hours;
      request.rate = this.jobEstimateForm.get('max_bill_rate').value;
      let start_date: any = this.getDate(this.jobEstimateForm.get('start_date').value);
      let end_date: any =  this.getDate(this.jobEstimateForm.get('end_date').value);
      if (!request.rate || !start_date || !end_date) {
        return;
      }
      request.start_date = this.getFormattedDate(new Date(start_date));
      request.end_date = this.getFormattedDate(new Date(end_date));
      request.adjustment_type = 'percentage';
      request.rate_type = 'per_month';
      if(!request.adjustment_value) {
        delete request.adjustment_value
      }
      const requestParam = this.jsonToQueryString(request);
      this.jobService.get(`/rate-estimate/programs/${this.programId}/resource-budget${requestParam}`).subscribe(data => {
        if (data && data.data) {
          this.jobEstimateForm.patchValue(data.data);
        }
      });
    }
  }

  getFormattedDate = function (date) {
    if (date) {
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = date.getFullYear();
      return yyyy + '-' + mm + '-' + dd;
    }
  }

  jsonToQueryString(json) {
    return '?' +
      Object.keys(json).map(function (key) {
        return encodeURIComponent(key) + '=' +
          encodeURIComponent(json[key]);
      }).join('&');
  }
  
  onAdjustmentTypeChange(e) {
    if (e.target.value == 'percentage') {
      this.place_data = '%';
    } else if (e.target.value == 'fixed') {
      this.place_data = '00.00';
    }
    // this.jobEstimateForm.controls['adjustment_value'].setValue(0);
    this.getResourceBudget();
  }
}
