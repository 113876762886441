import { Component, OnInit } from '@angular/core';
import { EventStreamService, Events, EmitEvent } from 'src/app/core/services/event-stream.service';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent implements OnInit {
  viewProfile = "hidden";
  certificationShowHide = true;
  constructor(private eventStream: EventStreamService) { }

  ngOnInit(): void {
    this.eventStream.on(Events.VIEW_CANDIDATE_PROFILE, (data) => {
      if (data.value) {
        this.viewProfile = 'visible';
      
      } else {
        this.viewProfile = 'hidden';
      }
    });
  }

  toggleCertification() {
    this.certificationShowHide = !this.certificationShowHide; 
  }

  closeProfileView() {
    this.viewProfile = 'hidden';
    console.log(this.viewProfile)
  }
}
