<svms-sidebar [title]="panelHeading" [dataVisibility]="addVaccinations" (onClose)="sidebarClose()">
  <svms-sidebar-body>
    <form [formGroup]="addVaccinationsForm">
      <div class="p-24">
        <div class="form-group">
          <label>Select Vaccination <span class="require">*</span></label>
          <ng-select [clearable]="false" placeholder="Select" [searchable]="false" formControlName="vaccinationType">
            <ng-option [value]="type.id" *ngFor="let type of vaccinationList">{{type.name}}</ng-option>
          </ng-select>
          <p class="validation-msg"
                *ngIf="(addVaccinationsForm.controls.vaccinationType.touched && addVaccinationsForm.controls['vaccinationType'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Vaccination
                is Required
          </p>
        </div>
        <div class="form-group">
          <label>Vaccination Provider <span class="require">*</span></label>
          <input type="text" class="form-control" placeholder="Enter Details"  formControlName="vaccinationProvider">
          <p class="validation-msg"
                *ngIf="(addVaccinationsForm.controls.vaccinationProvider.touched && addVaccinationsForm.controls['vaccinationProvider'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Vaccination Provider 
                is Required
          </p>
        </div>
        <div class="form-group-row">
          <div class="form-group position-relative date-field">
            <label>Vaccination Date<span class="require">*</span></label>
            <svms-datepicker [inputdateFormat]="'mm/dd/yy'" [placeholder]="'Select Date'" ngDefaultControl
              formControlName="vaccinationDate" id="date1" I18n #initialDate>
            </svms-datepicker>
            <!-- <p class="validation-msg"
                *ngIf="(addVaccinationsForm.controls.vaccinationDate.touched && addVaccinationsForm.controls['vaccinationDate'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Vaccination Date
                is Required
            </p> -->
          </div>
          <div class="form-group position-relative date-field">
            <label>Vaccination Expiration Date<span class="require">*</span></label>
            <svms-datepicker [inputdateFormat]="'mm/dd/yy'" [placeholder]="'Select Date'" ngDefaultControl
              formControlName="vaccinationExpiryDate" id="date1" I18n #expiryDate>
            </svms-datepicker>
            <!-- <p class="validation-msg"
                *ngIf="(addVaccinationsForm.controls.vaccinationExpiryDate.touched && addVaccinationsForm.controls['vaccinationExpiryDate'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Vaccination Expiry Date
                is Required
            </p> -->
          </div>
          <div class="form-group position-relative date-field"></div>
        </div>
        <div class="form-group">
          <label>Vaccination Number<span class="require">*</span></label>
          <input type="text" placeholder="Enter Details" class="form-control" formControlName="vaccinationNumbr">
          <p class="validation-msg"
                *ngIf="(addVaccinationsForm.controls.vaccinationNumbr.touched && addVaccinationsForm.controls['vaccinationNumbr'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Vaccination Number
                is Required
          </p>
        </div>
        <div class="form-group">
          <label>Notes</label>
          <textarea class="form-control notes" rows="4" placeholder="Enter Notes" formControlName="notes"></textarea>
        </div>
        <app-upload-documents uploadLabel="Upload Vaccination Documents"  (uploadDataFiles)="uploadFiles($event)" [closePanel] = "closePanel"></app-upload-documents>
      </div>
    </form>

  </svms-sidebar-body>
  <svms-sidebar-footer>
    <button type="submit" class="btn btn-outline float-right" (click)="saveVaccination('save')">
      <icon size="20px" name="check_circle" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
      Add
    </button>
    <button type="submit" class="btn btn-primary float-right mr-16" (click)="saveVaccination('saveThenAdd')">
      Add New
    </button>
  </svms-sidebar-footer>
</svms-sidebar>
