<div>
  <div class="d-flex align-items-center p-24 justify-content-between bg-light vms-header-panel">
    <div class="d-flex">
      <ul class="breadcrumb-list">
        <li class="breadcrumb-listItem">
          <a href="javascript:void(0)" class="title font-weight-medium"> {{mainmenuName}} </a>
          <table-icon *ngIf="submenuName" class="sub-menu-arrow d-inline-block" size="20px" name="navigate_next"
            type="material" cuClass="d-flex"></table-icon>
          <a href="javascript:void(0)" *ngIf="submenuName" class="page-title font-weight-medium"> {{submenuName}} </a>
        </li>
        <li class="breadcrumb-listItem ml-8" [hidden]="!tableConfig?.isCreate" *authorize="tableConfig?.permission">
          <button type="button" class="btn small btn-primary ml-8 rounded-circle add-btn" (click)="onCreateClick()"
            tooltip="{{isCreateButtonName? isCreateButtonName : 'Create '+tooltipName}}" placement="right" delay="500">
            <table-icon size="20px" name="add" type="material" cuClass="d-flex" class="d-flex">
            </table-icon>
            <!-- {{isCreateButtonName ? isCreateButtonName:createBtnName}} -->
          </button>
        </li>
        <li class="breadcrumb-listItem" [hidden]="!tableConfig?.isDownload">
          <button class="btn small btn-outine ml-12 pl-8 pr-8">
            <table-icon size="20px" name="save_alt" type="material" cuClass="d-flex" class="d-flex"></table-icon>
          </button>
        </li>
      </ul>
    </div>
    <div class="d-flex">
      <ul class="listing-filter">
        <li class="breadcrumb-listItem sortby" [hidden]="!tableConfig?.isMonthYearFilter">
         <ng-select placeholder="Select Month" [(ngModel)]="selectedMonth"  (change)="onMonthChange($event)">
          <ng-option *ngFor="let month of monthList" [value]="month.value">{{month.name}}</ng-option>
         </ng-select>
        </li>
        <li class="breadcrumb-listItem sortby" [hidden]="!tableConfig?.isMonthYearFilter">
          <ng-select placeholder="Select year" [(ngModel)]="selectedYear" (change)="onYearChange($event)">
           <ng-option *ngFor="let year of yearList" [value]="year">{{year}}</ng-option>
          </ng-select>
         </li>
        <li class="breadcrumb-listItem">
          <a href="javascript:void(0)" class="btn small btn-outine green color-light" [hidden]="!tableConfig?.isSelectSubmitButton"
            (click)="onSelectButtonClick($event)" placement="right" delay="500">
            <icon size="18px" name="check_box" type="material" class="icon--on-left" cuClass="icon-cu"></icon> {{isSelectSubmitButtonName}}
        </a>
        </li>
        <li class="breadcrumb-listItem position-relative" [hidden]="!tableConfig?.isDownloadButton"
          (click)="openDropdown()" (clickOutside)="onClickedOutside($event)">
          <a href="javascript:voi(0)" class="btn small btn-primary color-light" placement="right" delay="500">
            Download
            <icon class="d-flex cursor-pointer icon--on-right" size="20px" name="arrow_drop_down" type="material"
              cuClass="d-flex">
            </icon>
          </a>
          <ul class="dropdown-menu-download" *ngIf="openDrop">
            <li class="dropdown-item cursor-pointer" (click)="onDownloadClick('excel')">Excel
            </li>
            <li class="dropdown-item cursor-pointer" (click)="onDownloadClick('csv')">CSV
            </li>
            <li class="dropdown-item cursor-pointer" (click)="onDownloadClick('pdf')">PDF
            </li>
          </ul>
        </li>
        <li class="breadcrumb-listItem position-relative" [hidden]="!tableConfig?.isDownloadButton">
          <a [href]="tableConfig?.routeLinkText" class="btn btn-primary color-light small">
            <icon class="d-flex cursor-pointer icon--on-left" size="20px" name="pending_actions" type="material"
              cuClass="d-flex"></icon> {{tableConfig?.routeLinkOption}}
          </a>
        </li>
        <li class="position-relative" [ngClass]="{'search-when-expand': isSearchOpen}"
          [hidden]="!tableConfig?.isSearch">
          <a href="javascript:void(0)" class="d-block btn small d-flex jiustify-align-center" #search>
            <table-icon size="17px" name="search" type="material" cuClass="d-flex"></table-icon>
          </a>
          <div class="filter-search" [hidden]="!isSearchOpen" #searchInput>
            <input type="text" placeholder="Enter Search Term" [(ngModel)]="searchValue"
              class="form-control search-input " (keyup.enter)="onSearchClick($event)" (keyup)="onSearchClick($event)"
              [ngClass]="{'no-radius': tableConfig?.isTheme}">
            <table-icon size="17px" name="search" type="material" cuClass="d-flex" (click)="onSearchClick()">
            </table-icon>
          </div>
        </li>
        <li class="position-relative" [hidden]="!tableConfig?.isSetting">
          <a href="javascript:void(0)" class="btn small btn-outine" #setting>
            <table-icon size="17px" name="settings" type="material" cuClass="d-flex"></table-icon>
          </a>
          <div class="header-dropdown bg-light border rounded-sm position-absolute" [hidden]="!showSettings"
            #settingMenu>
            <h5 class="px-16 py-12 font-weight-bold border-bottom">Customize Listing</h5>
            <div class="">
              <h6 class="px-16 pb-4 pt-8 font-weight-bold">
                DISPLAY DENSITY
              </h6>
              <ul class="density-tab px-16 pb-12 d-flex flex-row">
                <li class="text-center py-8 mr-0" [ngClass]="{'active' : density === 'COMFORTABLE' }"
                  (click)="onDensityClick('COMFORTABLE')">Comfortable</li>
                <li class="text-center py-8 mr-0" [ngClass]="{'active' : density === 'COMPACT' }"
                  (click)="onDensityClick('COMPACT')">
                  Compact
                </li>
              </ul>
              <h6 class="px-16 pb-4 pt-12 font-weight-bold">
                CUSTOMIZE COLUMNS
              </h6>
              <div class="d-flex flex-column px-16 mb-8 reordring-column" [dndList] [dndPlaceholder]="placeholder"
                [dndModel]="tableConfig?.columnList">
                <div class="dndPlaceholder col-md-12" #placeholder></div>
                <div class="toggle-inner d-flex align-items-center justify-content-between reordering-item"
                  *ngFor="let col of tableConfig?.columnList, let i = index" [attr.data-index]="i" [dndType]="'col'"
                  [dndDraggable]="{draggable:false, effectAllowed:'move'}" [dndObject]="col"
                  (dndMoved)="onDragEnd(col, tableConfig?.columnList)" [class.dnone]="(i===0)">
                  <div class="toggle d-flex align-items-center"
                    [ngClass]="filterList[col?.name] ? 'if-active' : 'if-inactive'">
                    <table-icon size="40px" [name]="filterList[col?.name] ? 'toggle_on' : 'toggle_off'" type="material"
                      cuClass="d-flex cursor-pointer" class="d-flex" (click)="onClickColumn(col?.name)">
                    </table-icon>
                    <span class="ml-4 toggle-name">{{col?.title ? col?.title : col?.name}}</span>
                  </div>
                  <span class="drag cursor-pointer">
                    <table-icon size="20px" name="drag_indicator" type="material" cuClass="d-flex" class="d-flex">
                    </table-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="position-relative sortby" *ngIf="!tableConfig?.isSort">
          <!-- <a href="javascript:void(0)" class="small btn btn-outine p-0"> -->
          <!-- <span class="mr-8">Sort by :</span> -->
          <ng-select [clearable]="true" placeholder="Sort By" (change)="onClickSort($event)">
            <ng-option [value]="columns?.key" *ngFor="let columns of sortOptions">{{columns?.title}}</ng-option>
          </ng-select>
          <!-- <span class="text-dark ml-4 mr-4"></span> -->
          <!-- <table-icon size="17px" name="keyboard_arrow_down" type="material" class="text-dark" cuClass="d-flex">
            </table-icon> -->
          <!-- </a> -->
        </li>
        <li class="position-relative" [hidden]="!tableConfig?.isFilter">
          <!--ui for no active filter-->
          <a href="javascript:void(0)" *ngIf="totalFilterApplied === 0" class="small btn btn-outine" #filter>
            <table-icon class="mr-12" size="20px" name="filter_list" type="material" cuClass="d-flex"></table-icon>
            <span class="filter-active" *ngIf="tableConfig?.isTheme"></span>
            Filter
          </a>
          <!--ui for active filter-->
          <div *ngIf="totalFilterApplied > 0" class="active-filter btn small btn-secondary">
            <a href="javascript:void(0)" class="filter-trigger" #filter>
              <table-icon size="20px" name="filter_list" type="material" cuClass="d-flex"></table-icon>
            </a>
            <span class="active-count font-weight-normal pl-4 pr-4" #filterCount>{{totalFilterApplied}} Active
              filters</span>
            <a href="javascript:void(0)" class="clear-filter" (click)="onAdFilterClear()">
              <table-icon size="14px" name="close" type="material" cuClass="d-flex"></table-icon>
            </a>
          </div>
          <program-filter *ngIf="tableConfig?.advanceFilter" [class.active]="showFilter"
            [filter]="tableConfig?.advanceFilter" [isFilterCleard]="isFilterCleard" #filterMenu
            (advanceSearchFilter)="onFilterDone($event)">
          </program-filter>
        </li>
        <li class="position-relative d-none">
          <a href="javascript:void(0)" class="small btn btn-outine">
            <table-icon size="24px" name="view_module" type="material" cuClass="d-flex"></table-icon>
          </a>
        </li>
        <li class="position-relative d-none">
          <a href="javascript:void(0)" class="small btn btn-outine active-grid">
            <table-icon size="24px" name="view_stream" type="material" cuClass="d-flex"></table-icon>
          </a>
        </li>
        <li class="pagination mr-0" [hidden]="!tableConfig?.isTopPagination">
          <!-- Top Pagination -->
          <ul class="d-flex">
            <li class="position-relative">
              <a href="#" class="small btn btn-outine pl-4 pr-4" (click)="onClick($event, '-1')">
                <table-icon size="24px" name="arrow_left" type="material" cuClass="d-flex"></table-icon>
              </a>
            </li>
            <li class="position-relative mr-0">
              <a href="#" class="small btn btn-outine pl-4 pr-4" (click)="onClick($event, '+1')">
                <table-icon size="24px" name="arrow_right" type="material" cuClass="d-flex">
                </table-icon>
              </a>
            </li>
          </ul>
        </li>
        <li class="position-relative mr-0 view-type no-radius-right" [hidden]="!tableConfig?.isTheme">
          <!--ui for list view-->
          <a href="javascript:void(0)" class="small btn btn-light bg-light active">
            <table-icon size="22px" name="reorder" type="material" cuClass="d-flex"></table-icon>
          </a>
        </li>
        <li class="position-relative mr-0 view-type no-radius" [hidden]="!tableConfig?.isTheme">
          <!--ui for kanban view-->
          <a href="javascript:void(0)" class="small btn btn-light bg-light">
            <table-icon theme="outlined" size="22px" name="view_stream" type="material" cuClass="d-flex"></table-icon>
          </a>
        </li>
        <li class="position-relative mr-0 view-type no-radius-left" [hidden]="!tableConfig?.isTheme">
          <!--ui for grid view-->
          <a href="javascript:void(0)" (click)="showGridViewLayout($event)" class="small btn btn-light bg-light">
            <table-icon size="22px" name="apps" type="material" cuClass="d-flex"></table-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>


  <ul class="theme-filter" *ngIf="tableConfig?.isTheme">
    <!-- <li>
      Active Filters <table-icon size="14px" name="cancel" type="material" cuClass="d-flex"></table-icon>
    </li>
    <li>
      Preferred Pay Rate : <span>$30 - $140</span> <table-icon size="14px" name="cancel" type="material" cuClass="d-flex"></table-icon>
    </li>
    <li>
      Estimated Hours : <span>30h - 90h</span> <table-icon size="14px" name="cancel" type="material" cuClass="d-flex"></table-icon>
    </li>
    <li>
      Match Score : <span>50% +</span> <table-icon size="14px" name="cancel" type="material" cuClass="d-flex"></table-icon>
    </li>
    <li>
      Estimated Payrate : <span>$3000+</span> <table-icon size="14px" name="cancel" type="material" cuClass="d-flex"></table-icon>
    </li>
    <li>
      Available Date : <span>12/04/2020</span> <table-icon size="14px" name="cancel" type="material" cuClass="d-flex"></table-icon>
    </li> -->
  </ul>
  <!-- <div *ngIf="tableConfig?.showTabs" class="tab-wraper d-flex align-items-center justify-content-between bg-light">
    <ul class="assignment-tab">
      <li class="position-relative px-24 mr-4" [ngClass]="{'active-tab': selectedTab == tab}"
        *ngFor="let tab of tableConfig?.tabsList" (click)="tabClick(tab)">
        {{tab}}
      </li>
    </ul>
  </div> -->
</div>
<div>
</div>


<div class="d-flex align-items-center px-24 justify-content-between bg-light mt-16" *ngIf="tableConfig?.showTabs">
  <ul class="tabs border-bottom">
    <li class="position-relative d-flex align-items-end active-tab pb-12"
      *ngFor="let tab of tableConfig?.tabsList;let i=index" [ngClass]="{'active-tab': getActiveTab(tab) }"
      (click)="tabClick(tab)">
      <span class="mb-0 font-weight-medium">{{tab}}</span>
      <span class="rounded-sm ml-12 count pl-4 pr-4" *ngFor="let c of count;let j = index" [hidden]="j != i">
        {{c}}
      </span>
    </li>
    <!-- <li class="position-relative d-flex align-items-end pb-12">
      <span class="mb-0">Open Jobs</span>
      <span class="rounded-sm ml-8 count">16</span>
    </li>
    <li class="position-relative d-flex align-items-end pb-12">
      <span class="mb-0">Filled Jobs</span>
      <span class="rounded-sm ml-8 count">16</span>
    </li>
    <li class="position-relative d-flex align-items-end pb-12">
      <span class="mb-0">Closed Jobs</span>
      <span class="rounded-sm ml-8 count">16</span>
    </li>
      <li class="position-relative d-flex align-items-end pb-12">
        <span class="mb-0">Pending Approval</span>
        <span class="rounded-sm ml-8 count">16</span>
      </li> -->
  </ul>
</div>
