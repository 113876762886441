import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { AuthguardService } from '../core/services/auth_guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'qualifications',
        canActivate: [AuthguardService],
        loadChildren: () => import('../qualification/qualification.module').then(m => m.QualificationModule),
      },
      {
        path: 'programs',
        canActivate: [AuthguardService],
        loadChildren: () => import('../programs/programs.module').then(m => m.ProgramsModule),
      },
      {
        path: 'assignment',
        canActivate: [AuthguardService],
        loadChildren: () => import('../assignment/assignment.module').then(m => m.AssignmentModule),
      },

      {
        path: 'org',
        canActivate: [AuthguardService],
        loadChildren: () => import('../organizations/organizations.module').then(m => m.OrganizationsModule),
      },
      {
        path: 'jobs',
        canActivate: [AuthguardService],
        loadChildren: () => import('../jobs/jobs.module').then(m => m.JobsModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      /* {
        path: 'generic-timesheet',
        canActivate: [AuthguardService],
        loadChildren: () => import('../timesheet/timesheet.module').then(m => m.TimesheetModule),
      }, */
      {
        path: 'timesheet',
        canActivate: [AuthguardService],
        loadChildren: () => import('../wipro-timesheet/timesheet.module').then(m => m.TimesheetModule),
      },
      {
        path: 'vendor-managment',
        canActivate: [AuthguardService],
        loadChildren: () => import('../vendor-managment/vendor-managment.module').then(m => m.VendorManagmentModule),
      },
      {
        path: 'reports',
        canActivate: [AuthguardService],
        loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'auth/user-account',
        canActivate: [AuthguardService],
        loadChildren: () => import('../auth/user-account/user-account.module').then(m => m.UserAccountModule),
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
