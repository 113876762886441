import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'svms-bubble-chart',
  templateUrl: './svms-bubble-chart.component.html',
  styleUrls: ['./svms-bubble-chart.component.scss']
})
export class SvmsBubbleChartComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() height: number = 280;
  @Input() width: number = 700;
  @Input() margin: number = 50;
 @Input() value: string = 'Count';
  @Input() label: string = 'Name';
  @Input() onHoverIncreaseEffect = true;

  // legend dimensions
  legendRectSize: number = 6; // defines the size of the colored squares in legend
  legendSpacing: number = 6; // defines spacing between squares

  radius: number = 0;
  svg: any;
  color: any;
  // pie: any;
  // arc: any;
  // arcOver: any;
  // outerArc: any;
  div: any;
  // sumOfData: number = 0;

  bubbleId: string = ''
  // bubbleHoverId: string = ''
  bubbleIdList: string[] = []

  constructor() {
  }

  ngOnInit() {
    this.bubbleId = '_' + Math.random().toString(36).substr(2, 9);
    this.radius = (Math.min(this.width, this.height) / 2);
    this.color = d3.scaleOrdinal(d3.schemePaired);

    // this.bubbleHoverId = '_' + Math.random().toString(36).substr(2, 9);
    // this.sumOfData = (this.data.reduce((d: any, c: any) => { return (d + parseFloat(c[this.value])) }, 0)).toFixed(1)
  }
  ngAfterViewInit() {

    this.data = {
      "name": "flare",
      "children": [
        {
          "name": "query",
          "children": [
            { "name": "AggregateExpression", "size": 1616 },
            { "name": "And", "size": 1027 },
            { "name": "Arithmetic", "size": 3891 },
            { "name": "Average", "size": 891 },
            { "name": "BinaryExpression", "size": 2893 },
            { "name": "Comparison", "size": 5103 },
            { "name": "CompositeExpression", "size": 3677 },
            { "name": "Count", "size": 781 },
            { "name": "DateUtil", "size": 4141 },
            { "name": "Distinct", "size": 933 },
            { "name": "Expression", "size": 5130 },
            { "name": "ExpressionIterator", "size": 3617 },
            { "name": "Fn", "size": 3240 },
            { "name": "If", "size": 2732 },
            { "name": "IsA", "size": 2039 },
            { "name": "Literal", "size": 1214 },
            { "name": "Match", "size": 3748 },
            { "name": "Maximum", "size": 843 }
          ]
        }

      ]
    };
    this.svg = d3.select(`#${this.bubbleId}`)
      .append("svg")
      .attr("width", this.radius * 2)
      .attr("height", this.radius * 2)
      .attr("class", "bubble")
      .append("g")
      .attr('transform', 'translate(' + ('120') + ' ,' + ('10') + ')');

    //this.div = d3.select("body").attr("class", "tooltip-donut").style("opacity", 0);

    this.drawBubbles();
  }

  private autoIdGenrator() {
    let id = `_${Math.random().toString(36).substr(2, 9)}`;
    if (this.bubbleIdList.indexOf(id) === -1) {
      this.bubbleIdList.push(id);
      return id;
    } else {
      return this.bubbleIdList;
    }
  }

  private drawBubbles() {

    let diameter = 200;
    this.color = d3.scaleOrdinal(d3.schemePaired);

    let bubble = d3.pack()
      .size([diameter, diameter])
      .padding(1.5);

    let root = d3.hierarchy(classes(this.data))
      .sum(d => { return d["value"]; })
      .sort((a, b) => { return b["value"] - a["value"]; });

    bubble(root);
    let node = this.svg.selectAll(".node")
      .data(root.children)
      .enter().append("g")
      .attr("class", "node")
      .attr("transform", d => { return "translate(" + d.x + "," + d.y + ")"; });

    node.append("title")
      .text(d => { return d.data.className + ": " + (d.value); });

    node.append("circle")
      .attr("r", d => { return d.r; })
      .style("fill", d => {
        console.log(d)
        return this.color(d.data.className);
      }).style("fill-opacity",0.7);

    node.append("text")
      .attr("dy", ".3em")
      .style("text-anchor", "middle")
      .text(d => { return d.data.className.substring(0, d.r / 3); });


    // Returns a flattened hierarchy containing all leaf nodes under the root.
    function classes(root) {
      let classes = [];

      function recurse(name, node) {
        if (node.children) node.children.forEach(function (child) { recurse(node.name, child); });
        else classes.push({ packageName: name, className: node.name, value: node.size });
      }

      recurse(null, root);
      return { children: classes };
    }

    d3.select(self.frameElement).style("height", diameter + "px");

  }
}