import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName, FormArray } from '@angular/forms';
import { EventStreamService, Events, EmitEvent } from 'src/app/core/services/event-stream.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { CandidateService } from '../../service/candidate.service';
import { errorHandler } from '../../../shared/util/error-handler';
import { SvmsDatepickerComponent } from 'src/app/shared/components/svms-datepicker/svms-datepicker.component';

@Component({
  selector: 'app-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.scss']
})
export class AddCertificateComponent implements OnInit {
  @Output() certificateData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('initialDate') initialDate: SvmsDatepickerComponent;
  @ViewChild('recertiDate') recertiDate: SvmsDatepickerComponent;
  @ViewChild('expiryDate') expiryDate: SvmsDatepickerComponent;
  closePanel: EventEmitter<boolean> = new EventEmitter();
  @Input() certificationTypeId;
  addCertificate = "hidden";
  certificationTypeList = [];
  public addCertificateForm: FormGroup;
  panelHeading = 'Add New Certification';
  editPanel = false;
  clickedIndex;
  constructor(
    private fb: FormBuilder,
    private eventStream: EventStreamService,
    private alertService : AlertService,
    private candidateService : CandidateService
    ) { }

  ngOnInit(): void {
    this.addCertificateForm = this.fb.group({
      type: [null, Validators.required],
      authority: [null, Validators.required],
      initialDate: [null, Validators.required],
      reCertificationDate: [null, ''],
      expiryDate: [null, ''],
      id:[null,''],
      notes:[null,''],
      files:[null,'']
    });
    this.eventStream.on(Events.CREATE_CANDIDATE_CERTIFICATE, (data) => {
      if (data.value) {
        this.addCertificate = 'visible';
        if(data.obj != null){
          this.updatePanel(data.obj);
          this.panelHeading = 'Edit Certification';
          this.editPanel = true;
          this.clickedIndex = data.obj.clickedIndex;
        }
      } else {
        this.addCertificate = 'hidden';
      }
    });
    this.getCertificates();
  }
  getCertificates(){
    const certificationId = this.certificationTypeId;
    this.candidateService._getQualiFicationType(certificationId) .subscribe((res) => {
      this.certificationTypeList = res.qualifications;
    }, (err) => {
      this.alertService.error(errorHandler(err));
    })
  }
  sidebarClose(){
    this.addCertificate = 'hidden';
    this.addCertificateForm.reset();
    this.panelHeading = 'Add New Certification';
    this.editPanel = false;
    this.initialDate.value = null;
    this.expiryDate.value = null;
    this.recertiDate.value = null;
    this.closePanel.emit(true);
  }
  uploadFiles(event){
    this.addCertificateForm.patchValue({
      files:event
    })
  }
  updatePanel(data){
    this.addCertificateForm.patchValue({
      type: data.qualification_id,
      authority: data.authority,
      initialDate: data.initial_date,
      reCertificationDate: data.re_issue_date,
      expiryDate: data.expiration_date,
      id:data.certification_id,
      notes:data.notes,
      files:data.files
    })
  }
  saveCertification(action){
    let data = this.addCertificateForm.value;
    const certiType = this.certificationTypeList.filter((item)=>{
      if(item.id == data.type){
        return item.name;
      }
    }).map((obj)=>{
        return obj.name;
    })
    if( this.addCertificateForm.valid){
      const payload = {
        qualification_id: data.type,
        authority: data.authority,
        initial_date: data.initialDate ,
        re_issue_date: data.reCertificationDate,
        expiration_date: data.expiryDate,
        certification_id: data.id,
        notes: data.notes,
        files:data.files,
        certification_name: certiType
      }
      this.certificateData.emit({payload: payload, isEdit:this.editPanel, clickedIndex: this.clickedIndex});
      if(action == 'save'){
        this.sidebarClose();
      }else{
        this.editPanel = false;
        this.panelHeading = 'Add New Certification';
        this.initialDate.value = null;
        this.expiryDate.value = null;
        this.recertiDate.value = null;
        this.closePanel.emit(true);
      }
      this.addCertificateForm.reset();
      console.log(this.addCertificateForm.value)

    }else{
      this.alertService.error('Please fill the required fields properly.');
    }
    
  }

}
