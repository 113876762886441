<div class="form-group">
    <div class="svms-picklist-header">
      <label class="mb-0">Values<span class="require">*</span></label>
      <a href="" class="small-text text-green">Load from Picklist</a>
    </div>
    <div class="svms-picklist">
      <ul>
        <li>
          <span><input type="checkbox"><span class="ml-12">Enter your Values here</span></span>
          <img src="../../../../../assets/images/custom-fields-icons/Path 16711.svg" width="15" />
        </li>
        <li>
          <span><input type="checkbox"><span class="ml-12">Enter your Values here</span></span>
        </li>
        <li>
          <span><input type="checkbox"><span class="ml-12">Enter your Values here</span></span>
        </li>
      </ul>
      <div class="svms-picklist-btn">
        <a class="">Save to Pick List</a>
        <a class="">+ ADD MORE</a>
      </div>
    </div>

    <div class="form-group mt-24">
      <label>Picklist Name<span class="require">*</span></label>
      <div class="svms-picklist-name">
        <input type="text" class="form-control" placeholder="Picklist Name" value="Workflow Picklist">
        <div class="checkbox-panel">
          <div class="checkbox-box">
            <div class="checkbox-wrapper">
              <label class="checkbox-container">
                <input type="checkbox" value="OFF" checked>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>