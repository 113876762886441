<svms-sidebar [dataVisibility]="viewProfile" class="view-profile-sidebar">
  <svms-sidebar-body>
    <header class="profile-header py-32 px-24">
      <figure class="float-left mr-24">
        MS
      </figure>
      <div class="profile-information float-left mt-8">
        <h2 class="d-inline-block mr-16">Muhammad Shanu</h2>
        <a href="javascript:void(0)" class="d-inline-block mr-16">
          <img src="../../../assets/images/facebook-white.svg" alt="" />
        </a>
        <a href="javascript:void(0)" class="d-inline-block mr-16">
          <img src="../../../assets/images/linkedin-white.svg" alt="" />
        </a>
        <p class="mb-16">UX/UI Designer</p>
        <p class="contact-info float-left mb-16 mr-32">
          <icon class="mr-8" size="12px" name="mail_outline" type="material" cuClass="icon-cu"></icon> <a
            href="mailto:mshanu@simplifyvms.com">mshanu@simplifyvms.com</a>
        </p>
        <p class="contact-info float-left mb-16">
          <icon class="mr-8" size="12px" name="call" type="material" cuClass="icon-cu"></icon> +91 88913 01940
        </p>
        <div class="clearfix"></div>
        <p class="contact-info">
          <icon size="12px" class="mr-8" name="home" type="material" cuClass="icon-cu"></icon> Monkey monks , Shaarmis
          tower, Sasthamangalam, India
        </p>
      </div>
      <div class="header-right float-right text-right">
        <a href="javascript:void(0)" (click)="closeProfileView()">
          <icon size="24px" name="close" type="material" cuClass="icon-cu"></icon>
        </a>
        <button class="btn btn-outine">Download Resume</button>
      </div>
      <div class="clearfix"></div>
    </header>
    <section class="profile-body pt-32 pb-32 pl-24 pr-24">
      <h4 class="profile-title position-relative mb-24">
        <icon class="icon-bg mr-12 rounded-sm" theme="outlined" size="16px" name="description" type="material"
          cuClass="icon-cu"></icon>
        Certifications
        <span class="toggle position-absolute rounded-sm" (click)="toggleCertification()">
          <icon size="16px" [name]="certificationShowHide ? 'expand_more' : 'expand_less'" type="material"
            cuClass="icon-cu"></icon>
        </span>
      </h4>
      <div class="section-info overflow-hidden" [ngClass]="certificationShowHide ? 'active' : 'hidden'">
        <ul class="item-list">
          <li class="clearfix position-relative mb-16">
            <figure class="bullet float-left text-center d-flex mt-12">
              <img src="/assets/images/badge.svg" alt="">
            </figure>
            <div class="item-description float-right pt-12">
              <h4>UX Designer</h4>
              <p>Membership Certificate</p>
              <p>The Interaction Design Foundation Issued Sep 2020 . No Expiration Date
              </p>
              <p class="mt-8">Credential ID <a href="javascript:void()">23677</a></p>
              <div>
                <figure class="item-img mt-16 float-left mr-16">
                  <img src="/assets/images/certificate.svg" alt="">
                  <figcaption>Certificate Samp..</figcaption>
                </figure>
              </div>
            </div>
          </li>
          <li class="clearfix position-relative mb-16">
            <figure class="bullet float-left text-center d-flex mt-12">
              <img src="/assets/images/badge.svg" alt="">
            </figure>
            <div class="item-description float-right pt-12">
              <h4>UX Designer</h4>
              <p>UX Management: Strategy and Tactics</p>
              <p>The Interaction Design Foundation Issued Sep 2020 . Expired on Sep 2021
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </svms-sidebar-body>
</svms-sidebar>
