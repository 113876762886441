import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../../core/services/theme.service';
import { Theme } from '../../enums';
import { StorageService } from '../../../core/services/storage.service';
import { UserDataObj } from '../../enums';
@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent implements OnInit {

  theme: string = Theme.Light;
  colorTheme: string = "blue";
  userTheme = UserDataObj;
  themes = [];
  constructor(private themeService: ThemeService, private _localStorage: StorageService) { }

  ngOnInit(): void {
    let savedtheme = this._localStorage.get(this.userTheme[2]);
    if (savedtheme != "" && savedtheme != undefined) this.colorTheme = savedtheme;

    this.themes = this.themeService.getThemes().splice(2, 20);
    let thiss = this;
    setTimeout(() => {
      thiss.themeService.changeTheme(thiss.colorTheme);
    }, 5);
  }

  ngOnChanges(): void {
    this.themeService.changeTheme(this.theme);
  }

  changeTheme(t) {
    this.themeService.changeTheme(t);
  }

  handleClick(e, t) {
    if (e.target.checked) {
      this.themeService.changeTheme(t);
    }
  }

  getPrimaryColor(t) {
      return this.themeService.theme[t]['--color-assets-primary'];
  }

  getNavColor(t) {
    return this.themeService.theme[t]['--navigation-bg'];
}

  switchColorMode() {
    if (this.theme = "dark") {
      this.themeService.changeTheme("dark");
      console.warn("1st option" + this.theme);
    }
    else {
      this.themeService.changeTheme("dark");
      console.warn("2nd option" + this.theme);
    }

    console.warn(this.theme);
  }
}
