import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {
  @Input() data: any;
  @Input() height: number = 400;
  @Input() width: number = 800;
  @Input() margin: number = 100;
  @Input() xLabel = 'label';
  @Input() yLabel = 'status';
  @Input() onHoverIncreaseEffect = true;

  // legend dimensions
  legendRectSize: number = 6; // defines the size of the colored squares in legend
  legendSpacing: number = 6; // defines spacing between squares

  radius: number = 0;
  svg: any;
  color: any;
  pie: any;
  arc: any;
  labelArc: any;
  div: any;
  sumOfData: number = 0;

  @Input() set chart(data) {
    if (data.length > 0) {
      d3.select("#donutId").html("");
      this.data = data;

      let keys = Object.keys(this.data[0]);

      this.yLabel = keys[0], this.xLabel = keys[1];

      this.initializeDonutComponents();
    } else {
      d3.select("svg").remove();
      d3.select("#donutId").html("No Data Available").style("font-size", "1.5em").style("font-weight", 600).style("color", "rgb(185 183 183)");
      return;
    }
  };

  get chart() {
    return this.data;
  }

  constructor() { }

  ngOnInit(): void {
  }

  private initializeDonutComponents() {

    this.data.forEach(d => {
      d[this.yLabel] = +d[this.yLabel];
    });

    this.sumOfData = (this.data.reduce((d: any, c: any) => { return (d + parseFloat(c[this.yLabel])) }, 0)).toFixed(1)

    this.radius = (Math.min(this.width, this.height) / 2) - this.margin;
    this.color = d3.scaleOrdinal(d3.schemePaired);

    d3.select("svg").remove();

    this.svg = d3.select("#donutId")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`);

    this.pie = d3.pie().sort(null).value((d: any) => { return Math.abs(d[this.yLabel]) })
    this.arc = d3.arc().outerRadius(this.radius - 10).innerRadius(50)
    this.labelArc = d3.arc().outerRadius(this.radius - 40).innerRadius(this.radius - 40);
    this.div = d3.select("#donutHoverId").attr("class", "tooltip-donut").style("opacity", 0);

    this.drawDonutSlices();
  }

  private drawDonutSlices() {

    const yField = this.yLabel,
      xField = this.xLabel;

    let tempSvg = this.svg.selectAll(".arc")
      .data(this.pie(this.data))
      .enter().append("g")
      .attr("class", "arc");

    tempSvg.append("path")
      .attr("d", this.arc)
      .style('fill', (d: any, i: number) => this.color(i))
      .attr("fill-opacity", 0.7);

    //Path Transition
    tempSvg.transition().delay((d: any, i: any) => { return i * 100; }).duration(500)
      .attrTween('d', a => {
        var i = d3.interpolate(a.startAngle + 0.1, a.endAngle);
        return t => {
          a.endAngle = i(t);
          return this.arc(a);
        }
      }).attr('fill', (d: any, i: number) => this.color(i)).attr("fill-opacity", 0.7);

    tempSvg.append("text")
      .attr("transform", d => { return "translate(" + this.labelArc.centroid(d) + ")"; })
      .attr("dy", ".25em")
      .style("fill", "#000")
      .text(d => {
        if (d.data[yField] >= 5)
          return d.data[yField];
        else
          return '';
      });


    //-------Legend-------
    let lg = this.svg.selectAll('.legend') // selecting elements with class 'legend'
      .data(this.pie(this.data)) // refers to an array of labels from our dataset
      .enter() // creates placeholder
      .append('g') // replace placeholders with g elements
      .attr('class', 'legend') // each g is given a legend class
      .attr('transform', (d: any, i: any) => {
        let height = this.legendRectSize + this.legendSpacing; // height of element is the height of the colored square plus the spacing      
        let offset = height * this.color.domain().length / 2; // vertical offset of the entire legend = height of a single element & half the total number of elements  
        let horz = 20 * this.legendRectSize; // the legend is shifted to the left to make room for the text
        let vert = i * height - offset; // the top of the element is hifted up or down from the center using the offset defiend earlier and the index of the current element 'i'               
        return 'translate(' + horz + ',' + vert + ')'; //return translation 
      });

    lg.append('circle') // append rectangle squares to legend                                   
      .attr('cx', this.legendRectSize) // width of rect size is defined above                        
      .attr('cy', this.legendRectSize) // height of rect size is defined above   
      .attr('r', this.legendRectSize / 2)
      .style('fill', (d: any, i: number) => this.color(i)) // each fill is passed a color
      .style('fill-opacity', 1);

    // adding text to legend
    lg.append('text')
      .attr('x', this.legendRectSize + this.legendSpacing)
      .attr('y', this.legendRectSize - this.legendSpacing + 8)
      .text((d) => {
        let name = d.data[this.xLabel].split("-")[0];
        name = this.titleCase(name.replace(/_/g, ' '));
        return name;
      }) // return label
      .style("font-size", "10px").style("font-family", "Arial");
  }

  //Captialize Word
  private titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }
}
