import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VMSConfig } from 'src/app/library/table/table/table.model';
import { CandidateService } from '../service/candidate.service';
import {
  EventStreamService,
  Events,
  EmitEvent,
} from 'src/app/core/services/event-stream.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { errorHandler } from 'src/app/shared/util/error-handler';

@Component({
  selector: 'app-list-candidate',
  templateUrl: './list-candidate.component.html',
  styleUrls: ['./list-candidate.component.scss']
})
export class ListCandidateComponent implements OnInit {
  public vmsData: any;
  public tableConfig: VMSConfig;
  public isExpand = false;
  public totalPages;
  public totalRecords;
  public itemsPerPage: any;
  public tableLoaded = false;
  public editData: any;
  public viewData: any;
  public users: any;
  public viewclick: any;
  dataLoading = true;
  currentAssignment = "hidden";
  public columnData;
  public searchTerm;
  private isAdvanceFilter: boolean = false;
  constructor(
    private _candidateService: CandidateService,
    private _eventStream: EventStreamService,
    private router: Router,
    private _loader: LoaderService,
    private _alert: AlertService,
  ) { }



  ngOnInit(): void {

    this._loader.show();
    //pre loading the candidate data.
    this.candidatesList();

    this.tableConfig = {
      title: 'Candidate List',
      columnList: [
        { name: 'candidate_name', title: 'Candidate Name', width: 20, isIcon: false, isImage: true, isContact: false, isNumberBadge: false },
        { name: 'candidate_id', title: 'Candidate ID', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
        { name: 'location', title: 'Location', width: 11, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
        { name: 'title', title: 'Title', width: 11, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
        { name: 'modified_on', title: 'Updated Date', width: 12, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
        { name: 'current_assignment', title: 'Current Assignment', width: 9, isIcon: false, isImage: false, isContact: false, isNumberBadge: true, enableClick: true },
        { name: 'submitted_jobs', title: 'Submitted Jobs', width: 9, isIcon: false, isImage: false, isContact: false, isNumberBadge: true, enableClick: true },
        {
          name: 'is_enabled', title: 'Status', width: 13, isIcon: false, isImage: false,
          isContact: false, isNoOption: false, isVieworEdit: true, isDisableorDelete: true, isNumberBadge: false
        }

      ],
      isDownload: false,
      isExpand: false,
      isFilter: true,
      isSearch: true,
      isSetting: true,
      isTopPagination: true,
      isCreate: true,
      density: 'COMFORTABLE',
      advanceFilter: [
        { name: 'designation', title: 'Title', filterType: 'TEXT' },
        {
          name: 'is_enabled', title: 'Status', filterType: 'SELECT', multiSelectData: [
            { name: 'Active', value: true },
            { name: 'Inactive', value: false }
          ]
        },
        { name: 'location', title: 'Location', filterType: 'TEXT' },
        { name: 'start_date', title: 'Effective Date', filterType: 'DATERANGE' }
      ]


    };

  }

  onEditClick(eve) {
    this.router.navigate([`candidates/edit/${eve.candidate_real_id}`]);
  }

  onClickView(eve) {
    this.router.navigate([`candidates/view/${eve.candidate_real_id}`]);
  }

  onExpandClick(event) { }
  onPaginationClick(event) {
    this._loader.show();
    // this.dataLoading = true;
    if (!this.isAdvanceFilter) {
      this.candidatesList(event)
    } else {
      this.onListFilter
    }
  }
  onViewClick(eve) {
    this.router.navigate([`candidates/view/${eve.candidate_id}`]);
  }

  onCreateClick(e) {
    this.router.navigate([`candidates/list/add`]);
  }

  onSearch(event?) {
    console.log("search", event);
    this.dataLoading = true;
    this.searchTerm = event;
    this.candidatesList();
  }

  candidatesList(pageNo = 1) {
    // if (pageNo === 1 && !this.searchTerm) {
    //   this._loader.show();
    // }
    let search = this.searchTerm ? ('&k=' + this.searchTerm) : ''
    this._candidateService.fetchCandidates(10, pageNo, search).subscribe(data => {
      const noOfPages = Math.ceil(data.total_records / data.items_per_page);
      var cand_arr = [];
      data?.candidates.forEach(element => {

        let candidate_name1 = (element?.middle_name) ? `${element?.first_name} ${element?.middle_name} ${element?.last_name}` : `${element?.first_name} ${element?.last_name}`;
        let location = `${element?.addresses[0]?.city ? element?.addresses[0]?.city + ',' : ""} ${element?.addresses[0]?.country ? element?.addresses[0]?.country : ""}`
        let candidate_list_data = {
          "candidate_name": candidate_name1,
          "candidate_id": element.ref_id,
          "candidate_real_id": element.id,
          "location": location,
          "title": element?.title,
          "modified_on": element?.modified_on,
          "current_assignment": 6,
          "submitted_jobs": 6,
          "is_enabled": element.is_enabled
        }
        cand_arr.push(candidate_list_data);
      });

      this.vmsData = { 'candidate': cand_arr };
      // this.vmsData = data?.candidates;
      this.dataLoading = false;
      this.totalPages = noOfPages;
      this.totalRecords = data?.total_records;
      this.itemsPerPage = data?.items_per_page;
      this.tableLoaded = true;
      this._loader.hide();
    });

  }

  columnClicked(event) {
    if (event?.name == 'current_assignment' || event?.name == 'submitted_jobs') {
      this.columnData = event;
      this._eventStream.emit((new EmitEvent(Events.VIEW_CANDIDATE_ASSIGNMENT_AND_JOBS, this.columnData)));
    }
  }

  changeStatus(event) {

    this._candidateService.chnageCandidateStatus(event)
      .subscribe(res => {
        this.dataLoading = true;
        this._alert.success(`Candidate updated successfully `);
        this.candidatesList();
      }, err => {
        this._alert.error(errorHandler(err));
      });
  }

  onListFilter(event) {

    this._loader.show();
    if (event == undefined) {
      this.candidatesList();
    }
    this._candidateService.advanceFilter(event)
      .subscribe(data => {
        const noOfPages = Math.ceil(data.total_records / data.items_per_page);
        var cand_arr = [];
        data?.candidates.forEach(element => {

          let candidate_name1 = (element.middle_name) ? `${element.first_name} ${element.middle_name} ${element.last_name}` : `${element.first_name} ${element.last_name}`;
          let location = `${element?.addresses[0].city}, ${element?.addresses[0].country}`

          let candidate_list_data = {
            "candidate_name": candidate_name1,
            "candidate_id": element.ref_id,
            "candidate_real_id": element.id,
            "location": location,
            "title": element?.title,
            "modified_on": element?.modified_on,
            "current_assignment": 6,
            "submitted_jobs": 6,
            "is_enabled": element.is_enabled
          }
          cand_arr.push(candidate_list_data);
        });

        this.vmsData = { 'candidate': cand_arr };
        // this.vmsData = data?.candidates;
        this.dataLoading = false;
        this.totalPages = noOfPages;
        this.totalRecords = data?.total_records;
        this.itemsPerPage = data?.items_per_page;
        this.tableLoaded = true;
        this._loader.hide();
      }), err => {
        this._alert.error(errorHandler(err));
      }
  }
}
