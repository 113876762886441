import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventStreamService, Events, EmitEvent } from '../core/services/event-stream.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramConfig } from '../shared/enums';
import { StorageService } from '../core/services/storage.service';
import {AlertService} from '../shared/components/alert/alert.service';
@Component({
  selector: 'app-program-setup',
  templateUrl: './program-setup.component.html',
  styleUrls: ['./program-setup.component.scss']
})
export class ProgramSetupComponent implements OnInit, OnDestroy {
  isInsightVisible = true;
  public clientId: string;
  public programId: string;
  constructor(private eventStream: EventStreamService,
    private route: ActivatedRoute,
    private router: Router,
    private _alert: AlertService,
    private localStorage: StorageService) {
    this.router.events.subscribe((event) => {
      this.eventStream.emit(new EmitEvent(Events.ON_SHOW_INSIGHT, false));
    })
  }

  ngOnInit(): void {
    if (this.router.url === '/program-setup') {
      this.eventStream.emit(new EmitEvent(Events.ON_SHOW_INSIGHT, true));
      this.isInsightVisible = true;
    } else {
      this.eventStream.emit(new EmitEvent(Events.ON_SHOW_INSIGHT, false));
      this.isInsightVisible = false;
    }
    this.eventStream.on(Events.ON_SHOW_INSIGHT, (visibility) => {
      this.isInsightVisible = visibility;
    })
    // //Subscribing Route params from URL  -- ticket no. 561 --
    this.route.queryParams
      .subscribe(params => {

        if(params?.program_req_id){
          let newProgramJson = {
            program_uniqId: params?.programId,
            clientId: params?.clientId,
            program_req_id: params?.program_req_id,
            clientName : params?.clientName
          }
          this.localStorage.set(ProgramConfig[0], JSON.stringify(newProgramJson), true);
          if (params?.navigate_to_detail) {
            this.router.navigate(['/program-setup/program-detail'])
          }
        }
       
      })
      let programData = this.localStorage.get(ProgramConfig[0]);
      if(!programData || programData == undefined){
        this._alert.error("You have not configured the Program",{});
        this.router.navigate(['/dashboard']);
      }
  }
 
  
  ngOnDestroy() {
    this.eventStream.emit(new EmitEvent(Events.ON_SHOW_INSIGHT, false));
  }

  valueEmittedFromChildComponent = '';

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
  }

  valueEmittedFromHeaderComponent: '';

  notificationEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromHeaderComponent = valueEmitted;

  }

}
