<svms-sidebar [title]="title" icon="" [dataVisibility]="isCreateQualificationItem" (onClose)="sidebarClose($event)">
    <svms-sidebar-body>
      <form [formGroup]="addNewQualificationForm">
        <vms-tab-group [selectedIndex]="tabIndex" (onSelectedIndexChange)="onIndexChange($event)">
          <vms-tab label="Basic Info">
            <!-- Create new tab content start here -->
            <div class="create-new-tab">
              <div class="form-group-row mb-16">
                <div class="form-group">
                  <label>Title<span class="require">*</span> </label>
                  <input type="text" class="form-control" placeholder="CPR Cert 2"
                    formControlName="name" [readOnly]="isViewMode">
                  <p class="validation-msg"
                    *ngIf="addNewQualificationForm.controls['name'].touched && addNewQualificationForm.controls['name'].invalid && addNewQualificationForm.controls['name'].errors?.required">
                    <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
                    Qualification Name is Required
                  </p>
                  <p class="validation-msg" *ngIf="addNewQualificationForm.controls.name.errors?.minlength">
                    Name must be at least 5 characters long.
                  </p>
                  <p class="validation-msg" *ngIf="addNewQualificationForm.controls.name.errors?.maxlength">
                    Name cannot be more than 100 characters long.
                  </p>
                </div>
                <div class="toggle-panel mt-24" [ngClass]="{'disable':isViewMode}">
                  <div class="toggle-panel-inner" [ngClass]="toggle?.value ? 'if-active' : 'if-inactive'">
                    <span class="status-text">{{toggle?.title}}</span>
                    <span class="toggle-switch" [ngClass]="toggle?.value ? 'toggle-on' : 'toggle-off'"
                      (click)="onClickToggle()"></span>
                  </div>
                </div>
              </div>
              <div class="form-group-row mb-16">
                <div class="form-group">
                  <label>Code<span class="require">*</span>  </label>
                  <input type="text" class="form-control" [readOnly]="isViewMode" placeholder="Enter code here"
                     formControlName="code">
                  <p class="validation-msg"
                    *ngIf="addNewQualificationForm.controls['code'].touched && addNewQualificationForm.controls['code'].invalid && addNewQualificationForm.controls['code'].errors?.required">
                    <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
                    Code is Required
                  </p>
                  <p class="validation-msg" *ngIf="addNewQualificationForm.controls.code.errors?.minlength">
                    Code must be at least 5 characters long.
                  </p>
                  <p class="validation-msg" *ngIf="addNewQualificationForm.controls.code.errors?.maxlength">
                    Code cannot be more than 100 characters long.
                  </p>
                </div>
              </div>
              <div class="form-group mb-32">
                <label>Description </label>
                <textarea type="textarea" [readOnly]="isViewMode" rows="7" cols="12" maxlength="1000" class="form-control"
                  formControlName="description"
                  placeholder="Brief Description of what a credential is or does and how it impacts the instance"></textarea>
              </div>
            </div>
            <!-- Create new tab content end here -->
          </vms-tab>  
        </vms-tab-group>
      </form>
    </svms-sidebar-body>
    <svms-sidebar-footer></svms-sidebar-footer>
  </svms-sidebar>
  