<div class="confirmation-dialog position-fixed bg-light rounded-sm pt-32 overflow-hidden active">
  <div class="success-modal-body text-center pb-32">

    <h4 class="mt-8 pl-16 pr-16">
      {{message}}
    </h4>
  </div>
  <div class="modal-footer text-center d-flex justify-content-center">
    <button class="btn btn-light large" (click)="decline()">{{btnCancelText}}</button>
    <button class="btn  btn-primary large" (click)="accept()">{{btnOkText}}</button>
    <div class="clearfix"></div>
  </div>
</div>
<div class="dialog-backdrop position-fixed active"></div>