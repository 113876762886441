<div class="table-bg" [ngClass]="{'compact' : compactView}">
    <div class="table-bg-inner">
        <vms-header *ngIf="!vmsTableConfig?.hideHeader" [count]="count" [mainmenuName]="vmsTableConfig?.title" [submenuName]="vmsTableConfig?.subTitle" [isCreateButtonName]="vmsTableConfig?.isCreateButtonName" [isSelectSubmitButtonName]="vmsTableConfig?.isSelectSubmitButtonName" [routeLinkOption]="vmsTableConfig?.routeLinkOption" [routeLinkText]="vmsTableConfig?.routeLinkText" [tableConfig]="vmsTableConfig" [currentPage]="currentPage"
        [maxPages]="maxPages" (changePages)="onPaginationClick($event)" (onCreate)="onCreateClick($event)" (onHeaderButtonClick)="onSelectButtonClick($event)" (onDownloadButtonClick)="onDownloadClick($event)" (onSetting)="onSettingClick($event)" (onSearch)="onSearchClick($event)" (onMonthChangeEvent)="monthChange($event)" (onYearChangeEvent)="yearChange($event)" (onFilter)="onClickHeaderFilter($event)" (onListFilter)="listFilter($event)"
            [ngClass]="{'theme': vmsTableConfig?.isTheme}" (onTabClick)="onTabClicked($event)" (onSortFilter)="onSortClick($event)" [currentTab]="currentTab">
        </vms-header>

        <div class="content-area ui-content-wrapper bg-light" *ngIf="columnList" [ngClass]="{'theme': vmsTableConfig?.isTheme}">
            <div class="content-area-inner pl-24 pt-12">
                <div class="listing-wrap">
                    <div class="table-outer" [ngClass]="{'has-shadow' : vmsTableConfig?.tableWidth !== '100%'}">
                        <div class="table-overflow">
                            <perfect-scrollbar style="min-height: calc(100vh - 329px);" [config]="{ suppressScrollX: false, suppressScrollY: true}">
                                <table class="table table-listing cloned" *ngIf="vmsTableConfig?.tableWidth !== '100%'" [ngStyle]="{'width': vmsTableConfig?.tableWidth}">
                                    <thead>
                                        <tr>
                                            <th class="checkbox-all" width="5%" *ngIf="vmsTableConfig?.isCheckboxOption">
                                                <div class="checkbox-wrapper">
                                                    <label class="checkbox-container">
                            <input type="checkbox" name="selectall" [(ngModel)]="selectAllValue">
                            <div class="checkmark" (click)="selectAllClick($event)"></div>
                          </label>
                                                </div>
                                            </th>
                                            <th *ngFor="let header of vmsTableConfig?.columnList; let i =index" [ngClass]="{'pl-24':!vmsTableConfig?.isExpand && i == 0}" [width]="columnList[header?.name+'_width'] + '%'" [hidden]="!columnList[header?.name]" [ngClass]="{'not-scrollable' : i == 0}">
                                                <span class="mr-4">{{header?.title ? header?.title : header?.name}}</span>
                                                <!-- <table-icon [hidden]="!header?.isIcon" size="24px"
                        [color]="sortedColumn === header?.name ? 'blue' : ''"
                        [name]="((sortedColumn === header?.name) && isSortAsc) ? 'expand_less' : 'expand_more'"
                        type="material" [cuClass]="header?.iconClass ? header?.iconClass : 'icon'"
                        (onClick)="onSortClick(header?.name)">
                      </table-icon> -->
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngIf="!loading">
                    <tr *ngFor="let vmsData of vmsDataSource, let i=index" (mouseleave)="hideOptionDropdown()"
                      [ngClass]="{'active' : hoverState == i, 'dropdown-visible-top': ((vmsDataSource?.length > 5) && (i >= (vmsDataSource?.length -3)))}"
                      (mouseenter)="hoverClass(i)">
                      <td
                      [attr.colspan]="vmsTableConfig?.isTheme || vmsTableConfig?.isExpand || vmsTableConfig?.isCheckboxOption ? vmsTableConfig?.columnList?.length + 1 : vmsTableConfig?.columnList?.length">
                        <table>
                          <tr>
                            <td class="checkbox-td" width="5%" *ngIf="vmsTableConfig?.isCheckboxOption">
                                <div class="checkbox-wrapper">
                                  <label class="checkbox-container">
                                    <input type="checkbox" name="select-item" [(ngModel)]="vmsData.isChecked">
                                    <div class="checkmark" (click)="onCheckClick(vmsData, i)"></div>
                                  </label>
                                </div>
                            </td>
                            
                            <vms-row *ngFor="let column of vmsTableConfig?.columnList;let i = index"
                              [width]="columnList[column?.name+'_width'] + '%'"
                              [isVmsTableExpand]="vmsTableConfig?.isExpand" [index]="i"
                              [vmsRowDataSource]="getColumnData( column?.isRange ? column?.rangeNameList :column?.name, column, vmsData)"
                              [isImage]="column?.isImage" [isContact]="column?.isContact"
                              (viewClicked)="onViewClickd($event, vmsData)" (cloneClicked)="onCloneClickd(vmsData)"
                              (deleteClicked)="deleteClick(i,vmsData)" [isArray]="column?.isArray"
                              (viewProfileClicked)="clickToViewProfile(i,vmsData)"
                              (editClicked)="editClicked($event, vmsData)" (disableClicked)="onDisableClick(i, vmsData)"
                              style="display: contents;" [hidden]="!columnList[column?.name]"
                              [isVieworEdit]="column?.isVieworEdit" [isDetails]="column?.isDetails"
                              [isWithdrawal]="column?.isWithdrawal" [isViewProfile]="column?.isViewProfile"
                              [isInterview]="column?.isInterview" [isViewEnabled]="column?.isViewEnabled"
                              [isVieworClone]="column?.isVieworClone" [isDisableorDelete]="column?.isDisableorDelete"
                              [isProfileMatch]="column?.isProfileMatch" [isCandidateStatus]="column?.isCandidateStatus"
                              [isDelete]="column?.isDelete" [isNoOption]="column?.isNoOption"
                              [isAction]="column?.isAction" [isStatusColor]="column?.isStatusColor"
                              [isIconList]="column?.isIconList" [isShowCheckBox]="column?.isShowCheckBox"
                              [isCheckBoxReadonly]="column?.isCheckBoxReadonly" [isMultiUser]="column?.isMultiUser"
                              [isOptOut]="column?.isOptOut" [options]="column?.options"
                              [permission]="column?.permission" (updateClicked)="onUpdateClicked($event, vmsData)"
                              [isIcon]="column?.isIcon" (optOutClicked)="onOptOutClicked($event, vmsData)"
                              [isSubmitCandidate]="column?.isSubmitCandidate"
                              (submitCandidateClicked)="onSubmittedCandidateClicked($event, vmsData)"
                              (click)="columnClick(column, vmsData)"
                              (numberBadgeClicked)="onNumberBadgeClicked(column, vmsData)"
                              (onNameClicked)="onRowNameClick(column, vmsData)"
                              (withdrawProfileClicked)="clickToWithdrawProfile(i,vmsData)"
                              (viewDetailClicked)="clickToDetails(i,vmsData)"
                              (rescheduleInterviewClicked)="clickToRescheduleInterview(i,vmsData)"
                              (cancelInterviewClicked)="clickToCancelInterview(i,vmsData)"
                              (viewSubmittedCandidate)="clickToViewSubmittedCandidate(i,vmsData)"
                              (onOptionClicked)="optionClicked($event, vmsData)"
                              (onOpenPanel)="clickToViewPanel(i,vmsData)" [isOpenView]="column?.isOpenView"
                              [showTitleCase]="column?.showTitleCase" [hideBadege]="column?.hideBadege"
                              [toolTipVisibility]="column?.toolTipVisibility"
                              [ngClass]="{'not-scrollable has-border' : i == 0}">
                            </vms-row>
                          </tr>
                          <tr class="expanded-row" *ngIf="(expandRow === i) && open">
                            <td
                              [attr.colspan]="vmsTableConfig?.isExpand ? vmsTableConfig.columnList.length + 1 : vmsTableConfig.columnList.length"
                              style="padding-left:70px;">
                              <ng-content></ng-content>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="loading">
                    <tr>
                      <td class="data-loader">
                        <ngx-skeleton-loader count="10" animation="progress" appearance="lines"
                          [theme]="{'width': '100%', height: '50px','margin-bottom': '-2px', 'border-radius':'0px'}">
                        </ngx-skeleton-loader>
                      </td>
                    </tr>
                  </tbody>
                                </table>
                                <table class="table regular-table table-listing" [ngStyle]="{'width': vmsTableConfig?.tableWidth}">
                                    <thead>
                                        <tr>
                                            <th class="checkbox-all" width="5%" *ngIf="vmsTableConfig?.isCheckboxOption">
                                                <div class="checkbox-wrapper">
                                                    <label class="checkbox-container">
                            <input type="checkbox" name="selectall" [(ngModel)]="selectAllValue">
                            <div class="checkmark" (click)="selectAllClick($event)"></div>
                          </label>
                                                </div>
                                            </th>
                                            <th *ngFor="let header of vmsTableConfig?.columnList; let i =index" [ngClass]="{'pl-24':!vmsTableConfig?.isExpand && i == 0}" [width]="columnList[header?.name+'_width'] + '%'" [hidden]="!columnList[header?.name]" [ngClass]="{'not-scrollable' : i == 0}">
                                                <span class="mr-4">{{header?.title ? header?.title : header?.name}}</span>
                                                <!-- <table-icon [hidden]="!header?.isIcon" size="24px"
                        [color]="sortedColumn === header?.name ? 'blue' : ''"
                        [name]="((sortedColumn === header?.name) && isSortAsc) ? 'expand_less' : 'expand_more'"
                        type="material" [cuClass]="header?.iconClass ? header?.iconClass : 'icon'"
                        (onClick)="onSortClick(header?.name)">
                      </table-icon> -->
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngIf="!loading && vmsDataSource?.length > 0">
                    <tr *ngFor="let vmsData of vmsDataSource, let i=index" (mouseleave)="hideOptionDropdown()"
                      [ngClass]="{'active' : hoverState == i, 'dropdown-visible-top': ((vmsDataSource?.length > 5) && (i >= (vmsDataSource?.length -3)))}"
                      (mouseenter)="hoverClass(i)">
                      <td 
                      [attr.colspan]="vmsTableConfig?.isTheme || vmsTableConfig?.isExpand || vmsTableConfig?.isCheckboxOption ? vmsTableConfig?.columnList?.length + 1 : vmsTableConfig?.columnList?.length">
                        <table>
                          <tr>
                            <td class="checkbox-td" width="5%" *ngIf="vmsTableConfig?.isCheckboxOption">
                                <div class="checkbox-wrapper">
                                  <label class="checkbox-container">
                                    <input type="checkbox" name="select-item" [(ngModel)]="vmsData.isChecked">
                                    <div class="checkmark" (click)="onCheckClick(vmsData, i)"></div>
                                  </label>
                                </div>
                            </td>
                            <vms-row *ngFor="let column of vmsTableConfig?.columnList;let i = index"
                              [width]="columnList[column?.name+'_width'] + '%'"
                              [isVmsTableExpand]="vmsTableConfig?.isExpand" [index]="i"
                              [vmsRowDataSource]="getColumnData( column?.isRange ? column?.rangeNameList :column?.name, column,vmsData)"
                              [isImage]="column?.isImage" [isContact]="column?.isContact"
                              (viewClicked)="onViewClickd($event, vmsData)" (cloneClicked)="onCloneClickd(vmsData)"
                              (deleteClicked)="deleteClick(i,vmsData)"
                              (viewProfileClicked)="clickToViewProfile(i,vmsData)" [isArray]="column?.isArray"
                              (editClicked)="editClicked($event, vmsData)" (disableClicked)="onDisableClick(i, vmsData)"
                              style="display: contents;" [hidden]="!columnList[column?.name]"
                              [isVieworEdit]="column?.isVieworEdit" [isDetails]="column?.isDetails"
                              [isWithdrawal]="column?.isWithdrawal" [isViewProfile]="column?.isViewProfile"
                              [isInterview]="column?.isInterview" [isViewEnabled]="column?.isViewEnabled"
                              [isVieworClone]="column?.isVieworClone" [isDisableorDelete]="column?.isDisableorDelete"
                              [isProfileMatch]="column?.isProfileMatch" [isCandidateStatus]="column?.isCandidateStatus"
                              [isDelete]="column?.isDelete" [isNoOption]="column?.isNoOption"
                              [isAction]="column?.isAction" [isStatusColor]="column?.isStatusColor"
                              [isIconList]="column?.isIconList" [isShowCheckBox]="column?.isShowCheckBox"
                              [isCheckBoxReadonly]="column?.isCheckBoxReadonly" [permission]="column?.permission"
                              [isIcon]="column?.isIcon" [isMultiUser]="column?.isMultiUser"
                              [isOptOut]="column?.isOptOut" [options]="column?.options"
                              (optOutClicked)="onOptOutClicked($event, vmsData)"
                              [isSubmitCandidate]="column?.isSubmitCandidate"
                              (submitCandidateClicked)="onSubmittedCandidateClicked($event, vmsData)"
                              (click)="columnClick(column, vmsData)"
                              (numberBadgeClicked)="onNumberBadgeClicked(column, vmsData)"
                              (onNameClicked)="onRowNameClick(column, vmsData)"
                              (withdrawProfileClicked)="clickToWithdrawProfile(i,vmsData)"
                              (viewDetailClicked)="clickToDetails(i,vmsData)"
                              (rescheduleInterviewClicked)="clickToRescheduleInterview(i,vmsData)"
                              (cancelInterviewClicked)="clickToCancelInterview(i,vmsData)"
                              (viewSubmittedCandidate)="clickToViewSubmittedCandidate(i,vmsData)"
                              (onOptionClicked)="optionClicked($event, vmsData)"
                              [showTitleCase]="column?.showTitleCase" [hideBadege]="column?.hideBadege"
                              (onOpenPanel)="clickToViewPanel(i,vmsData)" [isOpenView]="column?.isOpenView"
                              [toolTipVisibility]="column?.toolTipVisibility" [ngClass]="{'not-scrollable' : i == 0}">
                            </vms-row>
                          </tr>
                          <tr class="expanded-row" *ngIf="(expandRow === i) && open">
                            <td
                              [attr.colspan]="vmsTableConfig?.isExpand ? vmsTableConfig.columnList.length + 1 : vmsTableConfig.columnList.length"
                              style="padding-left:70px;">
                              <ng-content></ng-content>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="loading">
                    <tr>
                      <td class="data-loader">
                        <ngx-skeleton-loader count="10" animation="progress" appearance="lines"
                          [theme]="{'width': '100%', height: '50px','margin-bottom': '-2px', 'border-radius':'0px'}">
                        </ngx-skeleton-loader>
                      </td>
                    </tr>
                  </tbody>
                                </table>
                                <div class="dotloader" *ngIf="loading"></div>
                                <div *ngIf="!loading && vmsDataSource?.length === 0">
                                    <div class="no-matches-found">No matches found. </div>
                                </div>
                            </perfect-scrollbar>

                        </div>
                    </div>

                    <div *ngIf="!vmsTableConfig?.hideResultCount" class="total-result d-none">
                        <div class="total-result-box overflow-hidden">
                            {{vmsDataSource?.length}} Results
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vms-paginator class="float-right mt-24 mb-24" *ngIf="!loading && !vmsTableConfig?.hideBottomPagination && vmsDataSource?.length > 0" [currentPage]="currentPage" [maxPages]="maxPages" (changePage)="onPaginationClick($event)">
        </vms-paginator>
        <div class="clearfix"></div>
    </div>
</div>