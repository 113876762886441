import { Injectable } from '@angular/core';
import { ProgramSetupSidebarModule } from './program-setup-sidebar.module';

@Injectable({
  providedIn: 'root'
})
export class ProgramSetupSidebarService {
  sideBarData: ProgramSetupSidebarModule[] = [
    {
      name: 'dashboard',
      title: 'Dashboard',
      icon: 'speed',
      class: 'sidebar-icon',
      path: '/dashboard',
      permission: 'menu_dashboard',
      isSideMenu: false,
      isSearch: false
    },
    {
      name: 'Program Details',
      title: 'Program Details',
      icon: 'info',
      class: 'sidebar-icon',
      path: '/program-setup/program-detail',
      permission: 'menu_program_details',
      isSideMenu: false,
      isSearch: false
    },
    {
      name: 'Activities',
      title: 'Activities',
      icon: 'insights',
      class: 'sidebar-icon',
      path: '/activities',
      permission: 'menu_activities',
      isSideMenu: false,
      isSearch: false
    },
    {
      name: 'Hierarchy',
      title: 'Hierarchy',
      icon: 'account_tree',
      class: 'sidebar-icon',
      permission: 'menu_hierarchy',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'Hierarchy',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          title: 'Foundational Data',
          subMenuItem: [
            {
              title: 'Add Foundational Data Type',
              icon: 'add_box',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/hierarchy/list/add',
              isNotification: false
            },
            {
              title: 'List of Foundational Data Type(s)',
              icon: 'layers',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/hierarchy/list',
              isNotification: false
            }
          ]
        },
        {
          title: 'Hierarchy',
          subMenuItem: [
            {
              title: 'Hierarchy Configuration',
              icon: 'layers',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/hierarchy/hierarchy-configuraton',
              isNotification: false
            }
          ]
        }
      ]
    },
    {
      name: 'Users',
      title: 'Manage Users',
      icon: 'people_alt',
      class: 'sidebar-icon',
      permission: 'menu_users',
      isSideMenu: true,
      isSearch: false,

      closeSubMenu: {
        title: 'USERS',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          title: 'Manage Users',
          subMenuItem: [
            {
              title: 'Add User',
              icon: 'add_box',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/users/list/add',
              isNotification: false
            },
            {
              title: 'List of User(s)',
              icon: 'layers',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/users/list',
              isNotification: false
            }
          ]
        },
        {
          title: 'User Roles',
          subMenuItem: [
            {
              title: 'Add User Role',
              icon: 'add_box',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/users/roles/add',
              isNotification: false
            },
            {
              title: 'List of User Role(s)',
              icon: 'layers',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/users/roles',
              isNotification: false
            }
          ]
        }
      ]
    },
    {
      name: 'Manage Pages',
      title: 'Manage Pages',
      icon: 'assignment',
      class: 'sidebar-icon',
      permission: 'menu_manage_pages',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'MANAGE PAGES',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      }
    },
    {
      name: 'Qualifications',
      title: 'Qualifications',
      icon: 'school',
      class: 'sidebar-icon',
      permission: 'menu_qualifications',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'QUALIFICATIONS',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          title: 'Qualification Type',
          subMenuItem: [
            {
              title: 'List of Qualification Type(s)',
              icon: 'layers',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/qualifications/list',
              isNotification: false
            }
          ]
        },
      ]
    },
    {
      name: 'Custom Fields',
      title: 'Custom Fields',
      icon: 'view_stream',
      class: 'sidebar-icon',
      permission: 'menu_custom_fields',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'CUSTOM FIELDS',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          title: 'Custom Field Management',
          subMenuItem: [
            {
              title: 'Configuration & Settings',
              // icon: 'layers',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/custom-fields/list',
              isNotification: false
            }
          ]
        },
      ]
    },
    {
      name: 'Notifications',
      title: 'Notifications',
      icon: 'notifications',
      class: 'sidebar-icon',
      permission: 'menu_notifications',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'NOTIFICATIONS',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      }
    },
    {
      name: 'Vendor',
      title: 'Manage Vendor',
      icon: 'school',
      class: 'sidebar-icon',
      permission: 'menu_vendor',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'MANAGE VENDOR',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          title: 'Vendor(s)',
          subMenuItem: [
            {
              title: 'Add New Vendor',
              icon: 'add_box',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/vendor/create-vendor',
              isNotification: false
            },
            {
              title: 'List of Vendor(s)',
              icon: 'layers',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/vendor/vendor-list',
              isNotification: false
            },
            {
              title: 'Vendor Group List',
              icon: 'api',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/vendor/vendor-group-list',
              isNotification: false
            },
            {
              title: 'Vendor Distribution Schedule',
              icon: 'more_time',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/vendor/vendor-distribution-list',
              isNotification: false
            }
          ]
        },
      ]
    },
    {
      name: 'management',
      title: 'Job Management',
      icon: 'school',
      class: 'sidebar-icon',
      permission: 'menu_job_management',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'JOB MANAGEMENT',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      },
      sideBarSubMenu: [
        {
          title: 'Job Management',
          subMenuItem: [
            {
              title: 'Create Job Template',
              icon: 'add_box',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/job-template/create',
              isNotification: false
            },
            {
              title: 'View Job Template',
              icon: 'layers',
              class: 'sidebar-icon-submenu',
              path: '/program-setup/job-template/list',
              isNotification: false
            }
          ]
        },
      ]
    },
    // {
    //   name: 'Time & Expense',
    //   title: 'Time & Expense',
    //   icon: 'pending_actions',
    //   class: 'sidebar-icon',
    //   permission: 'ADMIN',
    //   isSideMenu: true,
    //   isSearch: false,
    //   closeSubMenu: {
    //     title: 'TIME & EXPENSE',
    //     icon: 'close',
    //     class: 'sidebar-icon',
    //     isNotification: false
    //   }
    // },
    {
      name: 'Work Flows',
      title: 'Work Flows',
      icon: 'device_hub',
      class: 'sidebar-icon',
      permission: 'menu_work_flows',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'WORK FLOWS',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      }
    },
 
    {
      name: 'Credentialing',
      title: 'Credentialing',
      icon: 'security',
      class: 'sidebar-icon',
      permission: 'menu_credentialing',
      isSideMenu: true,
      isSearch: false,
      closeSubMenu: {
        title: 'CREDENTIALING',
        icon: 'close',
        class: 'sidebar-icon',
        isNotification: false
      }
    },
  ]

  constructor() { }
  getSideMenu() {
    return this.sideBarData;
  }

}
