<svms-sidebar [title]="panelHeading" [dataVisibility]="addCredential" (onClose)="sidebarClose()">
  <svms-sidebar-body>
    <form [formGroup]="addCredentialForm">
      <div class="p-24">
        <div class="form-group">
          <label>Select Credential <span class="require">*</span></label>
          <ng-select [clearable]="false" placeholder="Select" [searchable]="false" formControlName="credentialType">
            <ng-option [value]="type.id" *ngFor="let type of credentialList">{{type.name}}</ng-option>
          </ng-select>
          <p class="validation-msg"
                *ngIf="(addCredentialForm.controls.credentialType.touched && addCredentialForm.controls['credentialType'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Credential Type
                is Required
          </p>
        </div>
        <div class="form-group">
          <label>Issuing Authority <span class="require">*</span></label>
          <input type="text" placeholder="Enter Details" class="form-control" formControlName="issuingAuthority">
          <p class="validation-msg"
                *ngIf="(addCredentialForm.controls.issuingAuthority.touched && addCredentialForm.controls['issuingAuthority'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Issuing Authority
                is Required
          </p>
        </div>
        <div class="form-group-row">
          <div class="form-group position-relative date-field">
            <label>Initial Issuing Date <span class="require">*</span></label>
            <svms-datepicker [inputdateFormat]="'mm/dd/yy'" [placeholder]="'Select Date'" ngDefaultControl
              formControlName="initialIssuingDate" id="date1" I18n #initialDate>
            </svms-datepicker>
          </div>
          <div class="form-group position-relative date-field">
            <label>Re-Issuing Date</label>
            <svms-datepicker [inputdateFormat]="'mm/dd/yy'" [placeholder]="'Select Date'" ngDefaultControl
              formControlName="reIssuingDate" I18n #reIssueDate>
            </svms-datepicker>
          </div>
          <div class="form-group position-relative date-field">
            <label>Credential Expiration Date</label>
            <svms-datepicker [inputdateFormat]="'mm/dd/yy'" [placeholder]="'Select Date'" ngDefaultControl
              formControlName="credentialExpirationDate" I18n #expiryDate>
            </svms-datepicker>
          </div>
        </div>
        <div class="form-group">
          <label>Credential ID</label>
          <input type="text" placeholder="Enter Details" class="form-control" formControlName="credentialId">
        </div>
        <div class="form-group">
          <label>Notes</label>
          <textarea class="form-control notes" rows="4" formControlName="notes" placeholder="Enter Notes"></textarea>
        </div>
        <app-upload-documents uploadLabel="Upload Credential Documents" (uploadDataFiles)="uploadFiles($event)"  [closePanel] = "closePanel"></app-upload-documents>
      </div>
    </form>
  </svms-sidebar-body>
  <svms-sidebar-footer>
    <button type="submit" class="btn btn-outline float-right" (click)="saveCredential('save')">
      <icon size="20px" name="check_circle" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
      Add
    </button>
    <button type="submit" class="btn btn-primary float-right mr-16" (click)="saveCredential('saveThenAdd')">
      Add New
    </button>
  </svms-sidebar-footer>
</svms-sidebar>
