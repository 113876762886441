import { Component, OnInit,ViewChild,Input,OnChanges, Output, EventEmitter } from '@angular/core';
import { EventStreamService, Events, EmitEvent } from '../../../core/services/event-stream.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName } from '@angular/forms';
import { ProgramService } from '../../../programs/program.service';
import {SvmsUploadAvatarComponent} from '../../../shared/components/svms-upload-avatar/svms-upload-avatar.component';

@Component({
  selector: 'app-client-basic-info',
  templateUrl: './client-basic-info.component.html',
  styleUrls: ['./client-basic-info.component.scss']
})
export class ClientBasicInfoComponent implements OnInit,OnChanges {
  public clientCreate: FormGroup;
  public status: boolean;
  isImagePresent: boolean = false;

  // @Input()editdata: { industry: { id: any; }; }[];
  @Input()editdata: any[];
  @Input()isViewClicked: any;
  public toggle = {
    title: 'active',
    value: true
  };
  public industries = [];
  @ViewChild(SvmsUploadAvatarComponent) logoComponent :SvmsUploadAvatarComponent;
  public isNameValid = true;
  duplicateOrg = "Client name already in use.";
  constructor(public eventStream: EventStreamService, private fb: FormBuilder, private _programService: ProgramService) { }


  ngOnInit(): void {
      this.clientCreate = this.fb.group({
      logo: ['', ''],
      client_inactivereason: [null, ''],
      org_name: [null, Validators.required],
      org_type: ['Client'],
      industry: [null, Validators.required],
      status: ['','']
    });
    this.getIndustries();
    this.clientCreate.get('status').setValue(true);
  }
  getCropImage(e) { 
     if (e) {
      this.isImagePresent = true; 
     } else {
      this.isImagePresent = false; 
     } 
  }
  ngOnChanges(){
    this.loadData();
  }

loadData(){
    let industry= [];
    if(this.editdata && this.editdata?.length>0){
    this.editdata[0]?.industry?.map(data => {
      industry.push(data?.id);
    })
  
    this.clientCreate?.patchValue({
      logo: this.editdata[0]?.logo !== ''? this.editdata[0]?.logo:'',
      client_inactivereason: this.editdata[0]?.inactive_reason !== ''? this.editdata[0]?.inactive_reason:'',
      org_name: this.editdata[0]?.name !== ''? this.editdata[0]?.name:'Client',
      org_type: this.editdata[0]?.type !== ''? this.editdata[0]?.type:'',
      industry: industry ? industry:'',
      status: this.editdata[0]?.status != ''? this.editdata[0]?.status:false
    });
    let toggle = this.editdata[0]?.status;
    if(toggle){
      this.toggle.value = true;
      this.toggle.title = 'active';
    }
    else {
      this.toggle.value = false;
      this.toggle.title = 'inactive';
    }
    }
    else {
      this.clientCreate?.reset();
      this.clientCreate?.get('org_type')?.setValue('Client');
      this.clientCreate?.get('status').setValue(true);
      this.toggle.value = true;
      this.toggle.title = 'active';
    }
}

onChangestatus(){
 let status = this.clientCreate.get('status').value;
 this.clientCreate.get('client_inactivereason').setValue(null);
}

  getIndustries() {
    this._programService.get('/configurator/resources/industries').subscribe(
      data => {
        this.industries = data.industries;
      }
    )
  }

  onClickToggle() {
    if (this.toggle.value) {
      this.toggle.value = false;
      this.toggle.title = 'inactive';
      this.clientCreate.get('status').setValue(false);
    } else {
      this.toggle.value = true;
      this.toggle.title = 'active';
      this.clientCreate.get('status').setValue(true);
    }
    this.status = this.toggle.value;
    this.clientCreate.controls.client_inactivereason.setValue(null);
  }
}
