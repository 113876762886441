import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-job-static',
  templateUrl: './create-job-static.component.html',
  styleUrls: ['./create-job-static.component.scss']
})
export class CreateJobStaticComponent implements OnInit {
  public modules: any = {};
  constructor() {
    this.modules = {
      toolbar: [
        ['bold', 'italic', 'link', { list: 'ordered' }, { list: 'bullet' }]
      ]
    };
  }

  basicInfo = true;
  financialDetail = false;
  jobDetails = false;
  btnText = 'continue';
  expense = "Yes";
  items = [
    { value: "Yes" },
    { value: "No" }
  ];
  preicdntified = false;
  showSummaries = false;
  maxRate;
  skills = [
    {value: "HTML"},
    {value: "Java"}
  ];
  selectedSkills;

  ngOnInit(): void {
  }
  clickToContinue() {
    if (this.basicInfo == true) {
      this.basicInfo = false;
      this.financialDetail = true;
    }
    else if (this.financialDetail == true) {
      this.basicInfo = false;
      this.financialDetail = false;
      this.jobDetails = true;
      this.btnText = 'Save Job';
      this.showSummaries = false;
    }
  }

  onClickToggle() {
    this.preicdntified = !this.preicdntified;
  }

  showSummary() {
    console.log(this.maxRate);
    if(this.maxRate >= 1) {
      this.showSummaries = true;
    }
    else {
      this.showSummaries = false;
    }
  }

  deleteSkill(skill) {
    this.selectedSkills.splice(skill, 1);
  }

  goToBasic() {
    this.basicInfo = true;
    this.financialDetail = false;
    this.jobDetails = false;
    this.showSummaries = false;
    this.btnText = 'Continue';
  }

  goToFinancialDetail() {
    this.basicInfo = false;
    this.financialDetail = true;
    this.jobDetails = false;
    this.showSummaries = false;
    this.btnText = 'Continue';
  }

  goToJobDetails() {
    this.basicInfo = false;
    this.financialDetail = false;
    this.jobDetails = true;
    this.btnText = 'Save Job';
    this.showSummaries = false;
  }
}
 