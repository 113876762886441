<div class="header-fixed" [style.background-color]="bannerBackgroundColor">
  <div class="header-left">
    <div class="logo d-flex align-items-center mr-16">
      <img [src]="logoUrl">
  </div>
  </div>
  <div class="header-right position-relative">
   <!--  <div class="global-search-wrap float-left">
        <div class="global-search" [ngClass]="{'search-active': searchToggle }">
          <icon class="search-icon" cuClass="global-search-icon" name="search" size="22px" type="material"></icon>
          <input type="search" placeholder="Search" (click)="openSearch()" I18n>
          <a href="javascript:void(0)" class="close-search" (click)="closeSearch()" *ngIf="searchToggle">
            <icon name="clear" size="20px" type="material"></icon>
          </a>
          <div class="search-listing" *ngIf="searchToggle">
            <div class="no-result">
              <span>
                <icon name="highlight" size="24px" type="material"></icon>
              </span>
              <p class="mt-12" I18n>No Recent Searches Found</p>
            </div>
          </div>
        </div>
      </div>
      <div class="name d-none">
        <a href="javascript:void(0)" (click)="showAllPrograms()">
            <span class="p-title">{{currentProgram?.name}}</span>
        </a>
      </div>
      <div class="grid">
        <a href="javascript:void(0)" (click)="showAllPrograms()">
          <icon class="pt-4" cuClass="all-apps-icon" name="apps" size="24px" type="material"></icon>
        </a>
      </div>
      <div class="notification">
        <a href="javascript:void(0)" (click)="notificationToggle()">
          <icon cuClass="header-notification-icon" name="notifications_none" size="24px" type="material"></icon>
          <span class="count">2</span>
        </a>
      </div> -->
    <div class="help float-right mt-12 mr-24">
      <a class="cursor-pointer" (click)="downloadPdf()">
        <icon name="help" size="20px" type="material"></icon> Help
      </a>
    </div>
    <div class="language float-right">
      <app-lang-switcher></app-lang-switcher>
    </div>
      <div  *authorize="'view_login_qrcode'" class="qr-menu float-right mr-24">
        <qr-scanner></qr-scanner>
      </div>
    <!-- <div class="recent-comments">
      <a href="javascript:void(0)">
        <icon cuClass="header-chat-link" name="textsms" size="24px" type="material"></icon>
        <span class="count">4</span>
      </a>
    </div> -->
    <div class="clearfix"></div>
  </div>
  <div class="clearfix"></div>
</div>
