import { Component, OnInit, Input, NgZone, Output, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { EventStreamService, Events, EmitEvent } from 'src/app/core/services/event-stream.service';
import { ColumnConfig, VMSConfig } from './table.model';
import { ProgramConfig } from 'src/app/shared/enums';
import { StorageService } from 'src/app/core/services/storage.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'vms-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [DatePipe]
})
export class VMSTableComponent implements OnInit, OnChanges {

  @Input() vmsClass: string;
  @Input() vmsTableConfig: VMSConfig;
  @Input() vmsDataSource: any[];
  @Input() loading = false;
  @Input() open = false;
  @Input() totalItem = 1;
  @Input() itemsPerPage = 10;
  @Input() count: any[];
  @Input() currentTab;
  @Input()  currentPage = 1;
  @Output() onDetailClick = new EventEmitter();
  @Output() onViewClick = new EventEmitter();
  @Output() cloneClicked = new EventEmitter();
  @Output() onEditClick = new EventEmitter();
  @Output() onDeleteClick = new EventEmitter();
  @Output() onExpandClick = new EventEmitter();
  @Output() changePage = new EventEmitter<number>();
  @Output() onCreate = new EventEmitter<boolean>();
  @Output() onSort = new EventEmitter();
  @Output() search = new EventEmitter();
  @Output() onMonthChange = new EventEmitter();
  @Output() onYearChange = new EventEmitter();
  @Output() onDisableClicked = new EventEmitter();
  @Output() columnClicked = new EventEmitter();
  @Output() onListFilter = new EventEmitter();
  @Output() onClickFilter = new EventEmitter<boolean>();
  @Output() deactivateClicked = new EventEmitter();
  @Output() numberBadgeClicked = new EventEmitter();
  @Output() onNameClick = new EventEmitter();
  @Output() onTabClick = new EventEmitter<any>();
  @Output() onViewProfileClick = new EventEmitter();
  @Output() onWithdrawProfileClick = new EventEmitter();
  @Output() onSelectAllClick = new EventEmitter();
  @Output() onSelectClick = new EventEmitter();
  @Output() onRescheduleInterviewClick = new EventEmitter();
  @Output() onCancelInterviewClick = new EventEmitter();
  @Output() onViewSubmittedCandidateClick = new EventEmitter();

  @Output() onSubmittedCandidateClick = new EventEmitter();
  @Output() onOptOutClick = new EventEmitter();
  @Output() updateClicked = new EventEmitter();
  @Output() onOptionClicked = new EventEmitter();
  @Output() onOpenPanel = new EventEmitter();
  @Output() onHeaderButtonClick = new EventEmitter();
  @Output() onDownloadButtonClick = new EventEmitter();
  sortedColumn = '';
  isSortAsc = true;
  columnList = {};
  columnWidth = [];

  expandRow: number;
  fixedHeader = false;
  lastScrollTop = 0;
  direction = '';
  countColumnValue = 0;

  initialPage = 1;
  maxPages = 1;
  hideOptiondropdown = false;
  isDisabled = false;
  indexValues: any;
  selectedRowArr = [];
  allSelectedRows = false;
  hoverState;
  compactView = false;
  public months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  selectAllValue = false;
  constructor(private changeDetectorRef: ChangeDetectorRef,
    private eventStream: EventStreamService,
    private router: Router,
    private _datepipe: DatePipe,
    private localStorage: StorageService) {
    window.onscroll = () => {
      const st = window.pageYOffset;
      if (st > this.lastScrollTop) {

        if (st > 110) {
          this.fixedHeader = true;
        }
      } else if (110 < st && st < this.lastScrollTop) {
        this.fixedHeader = true;
      } else if (st <= 110) {
        this.fixedHeader = false;
      }

      this.lastScrollTop = st;

      this.changeDetectorRef.detectChanges()
    };
  }

  ngOnChanges() {
    this.pagination();
  }
  pagination() {
    if (this.itemsPerPage < 1) {
      this.itemsPerPage = 10
    }
    this.maxPages = Math.ceil(this.totalItem / this.itemsPerPage)
  }
  ngOnInit() {
    if (this.vmsTableConfig && this.vmsTableConfig.columnList) {
      this.vmsTableConfig.columnList.forEach((col, index) => {
        col.index = index;
        this.columnList[col.name] = true;
        this.columnList[col.name + '_width'] = col.width;
        if (col.width !== undefined) {
          this.columnWidth.push({ name: col.name, value: col.width })
        } else {
          if (this.vmsTableConfig.isExpand) {
            this.columnWidth.push({ name: col.name, value: (95 / this.vmsTableConfig.columnList.length).toFixed(2) })
          } else {
            this.columnWidth.push({ name: col.name, value: (100 / this.vmsTableConfig.columnList.length).toFixed(2) })
          }
        }
      });
    }

    this.changeDetectorRef.detectChanges();

    this.eventStream.on(Events.VIEW_TYPE, (data) => {
      if (data == "COMPACT") {
        this.compactView = true;
      }
      else if (data == "COMFORTABLE") {
        this.compactView = false;
      }
    });
  }

  onclickexpand(index: number, id: string) {
    if (this.expandRow === index) {
      this.expandRow = undefined;
      this.onExpandClick.emit(undefined);
    }
    else {
      this.expandRow = index;
      this.onExpandClick.emit(id);
    }
  }


  getColumnValue(data: any) {
    if (this.countColumnValue <= this.vmsTableConfig.columnList.length) {
      this.countColumnValue += 1;
      return { data: data[this.vmsTableConfig.columnList[0].name], type: typeof data[this.vmsTableConfig.columnList[0].name] }
    } else {
      this.countColumnValue = 0;
      this.getColumnValue(data);
    }
  }

  getColumnData(name: any, column: any, data: any) {

    if (typeof name === 'object') {
      if ((data[name[0]] !== 'None') && (data[name[1]] !== 'None')) {
        return this.getFormattedDate(data[name[0]]) + ' - ' + this.getFormattedDate(data[name[1]])
        // return this._datepipe.transform(date1, 'dd-MM-yyyy') + ' - ' + this._datepipe.transform(date2, 'dd-MM-yyyy')
      } else {
        return null
      }
    }

    const nameList = name?.split('.');
    if(column?.isArray){
      let returnData = [];
      let tempData;
      if (!data[nameList[0]]) {
        return null;
      }
      for (let nl of nameList) {
        if (tempData) {
          if(!tempData[nl]) {
            break;
          }
          tempData = tempData[nl];

        } else {
          tempData = data[nl];
        }
      }
      if(tempData){
        tempData?.forEach(td => {
           if(td[nameList[nameList?.length-1]]){
            returnData.push(td[nameList[nameList?.length-1]]);
           }
         });
      }
      // console.log(returnData)
      return returnData;
    } else {
      if (nameList?.length > 1) {
        let returnData;
        if (!data[nameList[0]]) { 
          return null;
        }
        nameList?.forEach(n => {
          if (returnData) {
            returnData = returnData[n];
          } else {
            returnData = data[n];
          }
        });
        return returnData;
      }
    }
    return data[name];
  }
  getFormattedDate(date) {
     date = date?.split(' ');
    let onlyDate = date[0] || "";
    let allDate = onlyDate?.split('-');
    let formattedDate: any= '';    
    if(allDate?.length === 3){
      let month= [allDate[1]];
      formattedDate= `${allDate[2]}-${month}-${allDate[0]}`;
    } else {
      if(date && date.length > 0) {
       formattedDate = this._datepipe.transform(date[0], 'dd-MM-yyyy');
      }
    }
    return formattedDate;    
  }




  onViewClickd(event, vmsData) {
    this.onViewClick.emit(vmsData);
    if (vmsData.unique_id) {
      this.router.navigate(['/program-setup'], { queryParams: { programId: vmsData.unique_id, clientId: vmsData.client.id, program_req_id: vmsData.id, clientName: vmsData.client.name } });
      this.localStorage.set(JSON.stringify(ProgramConfig[5]), vmsData, true);
    }
  }

  optionClicked(option, vmsData) {
    this.onOptionClicked.emit({ option, data: vmsData });
  }

  clickToDetails(event, vmsData) {
    this.onDetailClick.emit(vmsData);
  }

  onCloneClickd(vmsData) {
    this.cloneClicked.emit(vmsData);
  }


  editClicked(event, vmsData) {
    this.onEditClick.emit(vmsData);
  }
  onTabClicked(vmsData) {
    this.currentPage = 1; 
    this.onTabClick.emit(vmsData);
  }
  listFilter(event) {
    this.currentPage = 1;
    this.onListFilter.emit(event);
  }

  onDisableClick(index, vmsData) {
    // this.indexValues = index;
    // this.isDisabled = true;
    this.onDisableClicked.emit(vmsData);
  }

  deleteClick(index, vmsData) {
    this.onDeleteClick.emit(vmsData);
    this.vmsDataSource.splice(index, 1);
  }

  clickToViewProfile(index, vmsData) {
    this.onViewProfileClick.emit(vmsData);
  }

  clickToWithdrawProfile(index, vmsData) {
    this.onWithdrawProfileClick.emit(vmsData);
  }

  onPlusButtonClick(id: string) {

  }
  onPaginationClick(event) {
    this.currentPage = event;
    this.changePage.emit(event);
  }
  onCreateClick(event) {
    this.onCreate.emit(event);
  }

  onSettingClick(event) {
    event.forEach((colName) => {
      this.columnList[colName.name] = colName.value;
    });
    this.widthCalculator(event);
    this.changeDetectorRef.detectChanges();
  }

  onNumberBadgeClicked(column: ColumnConfig, vmsData: any) {
    this.numberBadgeClicked.emit({
      name: column.name,
      vmsData
    });
  }

  widthCalculator(event) {
    let totalAdjustValue = 0;
    let noOfShow = 1;
    event.forEach((colName) => {
      if (!this.columnList[colName.name]) {
        totalAdjustValue += this.columnWidth.find(a => a.name === colName.name).value
      } else {
        noOfShow += 1;
      }
    });

    const adjustWidth = totalAdjustValue / noOfShow;

    this.columnWidth.forEach(colName => {
      if (this.columnList[colName.name]) {
        this.columnList[colName.name + '_width'] = colName.value + adjustWidth;
      }
    });
  }

  onSearchClick(event) {
    this.search.emit(event);
  }
  
  monthChange(event) {
    this.onMonthChange.emit(event);
  }

  yearChange(event) {
    this.onYearChange.emit(event);
  }

  onClickHeaderFilter(event) {
    this.onClickFilter.emit(event)
  }

  columnClick(column: ColumnConfig, vmsData: any) {
    if (column.enableClick) {
      this.columnClicked.emit({
        name: column.name,
        vmsData
      });
    }
  }

  onSortClick(name) {
    if (this.sortedColumn === '') {
      this.isSortAsc = true;
    } else if (this.sortedColumn !== name) {
      this.isSortAsc = true;
    } else {
      this.isSortAsc = !this.isSortAsc;
    }
    if ((this.sortedColumn === name) && this.isSortAsc) {
      this.sortedColumn = '';
    } else {
      this.sortedColumn = name;
    }
    if ((this.sortedColumn === name) && this.isSortAsc) {
      this.onSort.emit({ name, order: 'ASC' });
    } else if ((this.sortedColumn === name) && !this.isSortAsc) {
      this.onSort.emit({ name, order: 'DESC' });
    } else if (this.sortedColumn === '') {
      this.onSort.emit(undefined);
    }
    this.currentPage = 1
  }

  hideOptionDropdown() {
    this.eventStream.emit(new EmitEvent(Events.OPTION_DROPDOWN, true));
    this.hoverState = null;
  }

  onRowNameClick(column: ColumnConfig, vmsData: any) {
    this.onNameClick.emit({ column, vmsData })
  }

  selectAllClick(vmsData: any) {
    if (this.allSelectedRows) {
      this.allSelectedRows = false;
      this.vmsDataSource.forEach(val => {
        val['isChecked'] = false
      })
      this.onSelectAllClick.emit({ selected: [] })
    } else {
      this.allSelectedRows = true;
      this.vmsDataSource.forEach(val => {
        val['isChecked'] = true
      })
      this.onSelectAllClick.emit({ selected: this.vmsDataSource })
    }
  }
  onCheckClick(vmsData: any, index) {
    this.vmsDataSource[index]['isChecked'] = vmsData['isChecked'] ? true : false;
    this.onSelectClick.emit({ selected: vmsData })
    if(vmsData['isChecked']){
      this.resetTimesheetCheckboxes();
    }
    let checkTicked;
    for (let i = 0; i < this.vmsDataSource.length; i++) {
      if(this.vmsDataSource[i].isChecked && this.vmsDataSource[i] != vmsData || (!this.vmsDataSource[i].isChecked && this.vmsDataSource[i] == vmsData)){
        checkTicked = true;
      }else{
        checkTicked = false;
        return;
      }
    }
    if(checkTicked){
      this.enableSelectAll();
    }
  }
  clickToRescheduleInterview(index, vmsData) {
    this.onRescheduleInterviewClick.emit(vmsData);
  }
  clickToCancelInterview(index, vmsData) {
    this.onCancelInterviewClick.emit(vmsData);
  }

  clickToViewSubmittedCandidate(event, vmsData) {
    this.onViewSubmittedCandidateClick.emit(vmsData);
  }

  onOptOutClicked(event, vmsData) {
    this.onOptOutClick.emit(vmsData);
  }

  onUpdateClicked(event, vmsData) {
    this.updateClicked.emit(vmsData);
  }

  onSubmittedCandidateClicked(event, vmsData) {
    this.onSubmittedCandidateClick.emit(vmsData);
  }

  hoverClass(i) {
    this.hoverState = i;
  }

  clickToViewPanel(event,vmsData){
    this.onOpenPanel.emit(vmsData);
  }
  onSelectButtonClick(eve){
    this.onHeaderButtonClick.emit(eve);
  }
  onDownloadClick(eve){
    this.onDownloadButtonClick.emit(eve);
  }
  resetTimesheetCheckboxes(){
    this.allSelectedRows = false;
    this.selectAllValue = false;
  }
  enableSelectAll(){
    this.allSelectedRows = true;
    this.selectAllValue = true;
  }
}
