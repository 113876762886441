<div class="widget-listing-wrap">
    <div class="widgetlist">
        <ul>
            <li>
                <a><i class="material-icons">supervisor_account</i> <label>Headcount</label></a></li>
            <li>
                <a> <i class="material-icons">bubble_chart</i> <label>Spend By Supplier</label></a></li>
            <li>
                <a><i class="material-icons">bubble_chart</i> <label>Spend By Category</label></a></li>
            <li>
                <a><i class="material-icons">waves</i><label>Budget Spend</label></a></li>
            <li>
                <a><i class="material-icons">waves</i><label>Daily Spend</label></a></li>
            <li>
                <a><i class="material-icons">attach_money</i><label>RT, OT &amp; Expense</label></a></li>
            <li>
                <a><i class="material-icons">account_circle</i><label>Spend By Candidate</label></a></li>
            <li>
                <a><i class="material-icons">card_travel</i><label>Job Report</label></a></li>
            <li>
                <a><i class="material-icons">dashboard</i><label>Spend By Year</label></a></li>
            <li>
                <a><i class="material-icons">show_chart</i><label>Spend Over Time</label></a></li>
            <li>
                <a><i class="material-icons">list_alt</i><label>Spend Report</label></a></li>
            <li>
                <a><i class="material-icons">list_alt</i><label>Tenure Report</label></a></li>
            <li>
                <a><i class="material-icons">account_circle</i><label>Candidate By OT</label></a></li>
        </ul>
    </div>
</div>