<div class="account-intro-wrap text-center">
  <header class="intro-header">
    <div class="left">
    </div>
    <div class="middle">
      <img src="../../../../assets/images/intro-pattern.png" alt="" width="326" height="65">
    </div>
    <div class="right">
    </div>
  </header>
  <div class="intro-content" *ngIf="isValid == 'valid'">
    <figure>
      <img src="../../../../assets/images/intro-emoji.png" alt="" width="63" height="63">
    </figure>
    <h1>Welcome to <span>@</span>Simplify</h1>
    <h4>Our Mission is to make you more productive. This will only take a minute to setup your account.</h4>
    <button class="account-btn btn btn-secondary mx-auto" (click)="setPassword()">Let's do it! <icon
        class="icon--on-right d-flex" cuClass="intro-btn" name="keyboard_backspace" size="19px" type="material"></icon>
    </button>
  </div>
  <div class="invalid-token" *ngIf="isValid == 'invalid'">
    <div *ngIf="!newInvitation">
      <img src="./assets/images/link-expired.svg" alt="Invalid token" width="303" class="img-fluid">
      <h2 class="mt-64 font-weight-semibold">This invitation is already expired.</h2>
      <p class="font-weight-medium">
        Please click this button to request
        <span>SimplifyVMS admin to get a new invitation email</span>
      </p>
      <button class="account-btn btn btn-secondary mx-auto" (click)="requestNewInvitation()">Resend Activation Email <icon class="icon--on-right d-flex" cuClass="intro-btn" name="keyboard_backspace" size="24px"
          type="material"></icon></button>
    </div>
    <div *ngIf="newInvitation">
      <icon size="69px" name="check_circle" type="material" class="success-icon" cuClass="success"></icon>
      <h3 class="font-weight-semibold">
        Your request has been sent successfully with {{newInvitationMessage}}
      </h3>
      <p>
        Please check your inbox for new invitation
      </p>
    </div>
  </div>
  <footer class="intro-footer">
    <div class="left"></div>
    <div class="middle" *ngIf="isValid == 'valid'">
      <img src="../../../../assets/images/inro-character.png" alt="" width="498" height="202">
    </div>
    <div class="right"></div>
  </footer>
</div>
