import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import {
  debounceTime, distinctUntilChanged,
  switchMap
} from 'rxjs/operators';
import { dateRangeToTimeStampConverter } from '../../../shared/util/date.util';
import { ProgramService } from '../../../programs/program.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AdvanceFiltter } from '../table/table.model';
import { StorageService } from 'src/app/core/services/storage.service';
@Component({
  selector: 'program-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent implements OnInit, OnChanges {

  // rangeDate: Date;
  advanceFilterForm: FormGroup;

  @Input() filter = [];
  @Input() set isFilterCleard(data) {
    if (data) {
      this.advanceFilterForm.reset()
    }
  }

  @ViewChild('refdate1') datePicker;
  @Output() advanceSearchFilter = new EventEmitter();
  public createdDate: any = {
    language: 'English',
    timepicker: true,
    format12h: true,
    range: true
  };
  public effectiveDate: any = {
    language: 'English',
    timepicker: true,
    format12h: true,
    range: true
  };

  constructor(private _programService: ProgramService,
    private storageService: StorageService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.advanceFilterForm = this.fb.group({})
    if (this.filter) {
      this.filter.forEach((filter: AdvanceFiltter) => {
        if (filter.filterType === 'TEXT') {
          this.advanceFilterForm.addControl(filter.name, this.fb.control(null))
        } else if (filter.filterType === 'MULTISELECT') {
          this.advanceFilterForm.addControl(filter.name, this.fb.control([]));
          if (filter.eventEmiiter && filter.changeHandler) {
            filter.eventEmiiter.pipe(debounceTime(1000)).subscribe((newTerm) => {
              filter.changeHandler(newTerm);
            });
          }
        } else if (filter.filterType === 'SELECT') {
          this.advanceFilterForm.addControl(filter.name, this.fb.control(null))
        } else if (filter.filterType === 'DATERANGE') {
          this.advanceFilterForm.addControl(filter.name, this.fb.control(null))
        }
      })
    }
    this.addAccessKey();
  }

  addAccessKey(){
    let user_type = this.storageService.get('user_type');
    this.filter.forEach((element,index) => {
      if((element.title.toLowerCase() == 'compliance period' || 
          element.title.toLowerCase() == 'month' ||
          element.name.toLowerCase() == 'year') && 
          (user_type.toLowerCase() == 'vendor' || 
          user_type.toLowerCase() == 'super_org')){
        this.filter[index].access = false;
      }else{
        this.filter[index].access = true;
      }      
    });
  }

  ngOnChanges(data) {
    if (data.filter && data.filter.previousValue) {
      if (data.filter.previousValue[0].title !== data.filter.currentValue[0].title) {
        this.ClearFilter()
        this.advanceSearchFilter.emit()
      }
    }
  }

  ClearFilter() {
    if (this.advanceFilterForm) {
      this.advanceFilterForm.reset()
      this.advanceSearchFilter.emit()
    }
  }

  onSearch() {
    let noOfFilter = 0;
    let filterData = []
    this.filter.forEach((filter: AdvanceFiltter) => {
      if (this.advanceFilterForm.get(filter.name).value !== null) {
        if (Array.isArray(this.advanceFilterForm.get(filter.name).value) && this.advanceFilterForm.get(filter.name).value.length > 0) {
          noOfFilter += 1;
        } else if (!Array.isArray(this.advanceFilterForm.get(filter.name).value)) {
          noOfFilter += 1;
        }
        if (filter.filterType === 'DATERANGE') {
          const dateRange = (this.advanceFilterForm.get(filter.name).value).split('-')
          filterData[filter.name] = [(new Date(dateRange[0])).getTime(), (new Date(dateRange[1])).getTime()]
        } else {
          filterData[filter.name] = this.advanceFilterForm.get(filter.name).value
        }
      }

    })

    this.advanceSearchFilter.emit({ filterData, noOfFilter })
  }


}
