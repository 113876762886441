  <aside>
    <div class="aside-panel p-24">
      <h4 class="head d-flex align-items-center mb-24">
        <span class="initials initials-blue mr-16">
          <icon class="d-flex green cursor-pointer" theme="outlined" size="16px" name="savings" type="material"
            cuClass="d-flex">
          </icon>
        </span>
        Pins
      </h4>
      <ul class="pl-0 ml-0">
        <li>
          Supplier Scorecard Report
        </li>
        <li>
          Tenure Report
        </li>
      </ul>
      <h4 class="head d-flex align-items-center mb-24">
        <span class="initials initials-blue mr-16">
          <icon class="d-flex green cursor-pointer" theme="outlined" size="16px" name="savings" type="material"
            cuClass="d-flex">
          </icon>
        </span>

        Saved Reports
      </h4>
      <ul>
        <li>
          Supplier Scorecard Report
        </li>
        <li>
          Cycle Time Report
        </li>
        <li>
          Tenure Report
        </li>
      </ul>
    </div>
  </aside>