<div class="job-reports d-flex ">
  <div class="job-report-content px-24">
    <div class="search-reports p-16 d-flex align-items-center my-24">
      <h3 class="mb-0 mr-12">Reports</h3>
      <div class="search position-relative w-100">
        <input type="text" class="form-control" [(ngModel)]="search_term" (ngModelChange)="searchReport(search_term)"
          placeholder="Search Reports By Name" />
        <span class="position-absolute search-icon">
          <icon *ngIf="!search_term" class="d-flex green cursor-pointer" size="16px" name="search" type="material"
            cuClass="d-flex">
          </icon>

          <icon *ngIf="search_term" (click)="searchReport('')" class="d-flex green cursor-pointer" size="16px"
            name="close" type="material" cuClass="d-flex">
          </icon>

        </span>
      </div>
    </div>

    <div class="mt-24 text-center" *ngIf="reportData && reportData?.length === 0">
      <h3 class="mb-0 mr-12">No Reports found</h3>
    </div>
    <!-- <div class="mt-24">
      <h4 class="mb-16">Recently Used Reports</h4>
      <div class="d-flex align-items-center flex-nowrap">
        <span class="badge badge-outline-light rounded-sm mb-12 mr-12">
          <span>Jobs Report</span>
          <span class="ml-8">
            <icon class="d-flex cursor-pointer" size="16px" name="close" type="material" cuClass="d-flex">
            </icon>
          </span>
        </span>
        <span class="badge badge-outline-light rounded-sm mb-12">
          <span>Jobs Report</span>
          <span class="ml-8">
            <icon class="d-flex cursor-pointer" size="16px" name="close" type="material" cuClass="d-flex">
            </icon>
          </span>
        </span>
      </div>
    </div> -->
    <div class="mt-24">
      <div class="row">
        <div class="col-md-4" *ngFor="let r of reportData">
          <h4 class="head d-flex align-items-center mb-24">
            <span class="initials initials-blue mr-16">

              <icon class="d-flex green cursor-pointer" theme="outlined" size="16px" name="{{r?.icon}}" type="material"
                cuClass="d-flex">
              </icon>
            </span> {{r?.label}}
          </h4>
          <ul class="pl-0 ml-0" *ngFor="let sub of r?.sub_items">
            <li class="d-flex align-items-center justify-content-between" (click)="showReport(sub?.key)">
              <span class="d-flex align-items-center">
                <icon class="d-flex green cursor-pointer mr-4" size="16px" name="keyboard_arrow_right" type="material"
                  cuClass="d-flex">
                </icon> {{sub?.label}}
              </span>
              <!-- <span class="pin">
                <icon class="d-flex green cursor-pointer" size="16px" name="push_pin" type="material" cuClass="d-flex">
                </icon>
              </span> -->
            </li>
          </ul>

          <!-- <ul class="pl-0 ml-0">
            <li class="d-flex align-items-center justify-content-between" routerLink="/reports/details">
              <span class="d-flex align-items-center">
                <icon class="d-flex green cursor-pointer mr-4" size="16px" name="keyboard_arrow_right" type="material"
                  cuClass="d-flex">
                </icon> Supplier Scorecard Report
              </span>
              <span class="pin">
                <icon class="d-flex green cursor-pointer" size="16px" name="push_pin" type="material" cuClass="d-flex">
                </icon>
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <span class="d-flex align-items-center">
                <icon class="d-flex green cursor-pointer mr-4" size="16px" name="keyboard_arrow_right" type="material"
                  cuClass="d-flex">
                </icon> Supplier Scorecard Report
              </span>
              <span class="pin">
                <icon class="d-flex green cursor-pointer" size="16px" name="push_pin" type="material" cuClass="d-flex">
                </icon>
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between" rouetLink="">
              <span class="d-flex align-items-center">
                <icon class="d-flex green cursor-pointer mr-4" size="16px" name="keyboard_arrow_right" type="material"
                  cuClass="d-flex">
                </icon> Supplier Scorecard Report
              </span>
              <span class="pin">
                <icon class="d-flex green cursor-pointer" size="16px" name="push_pin" type="material" cuClass="d-flex">
                </icon>
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <span class="d-flex align-items-center">
                <icon class="d-flex green cursor-pointer mr-4" size="16px" name="keyboard_arrow_right" type="material"
                  cuClass="d-flex">
                </icon> Supplier Scorecard Report
              </span>
              <span class="pin">
                <icon class="d-flex green cursor-pointer" size="16px" name="push_pin" type="material" cuClass="d-flex">
                </icon>
              </span>
            </li>
          </ul> -->
        </div>


      </div>
    </div>
    <!-- <div class="mt-24">
      <h4 class="head d-flex align-items-center mb-24">
        <span class="initials initials-blue mr-16">
          <icon class="d-flex green cursor-pointer" theme="outlined" size="16px" name="savings" type="material"
            cuClass="d-flex">
          </icon>
        </span>
        Pins
      </h4>
    </div> -->
  </div>


  <!-- <app-aside-panel></app-aside-panel> -->
</div>