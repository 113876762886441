import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { UserDataObj } from '../../shared/enums';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../../shared/components/loader/loader.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private _storageService: StorageService, private _loader: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._storageService.get(UserDataObj[0]);
    const authHeader = `Bearer ${token}`;
    let authReq = undefined;
    if (req.url.includes(`s3.amazonaws.com`)) {
      authReq = req.clone({
      });
    } else {
      authReq = req.clone({
        headers: req.headers.set('Authorization', authHeader)
      });
    }
    return next.handle(authReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this._loader.hide();
            this.router.navigate(['/auth/login']);
          }
        }
      }));
  }
}
