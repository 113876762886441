<!-- <app-job-detail-sidebar *ngIf="jobData" [jobData]="jobData"></app-job-detail-sidebar> -->
<div class="detail-page-wrapper">
  <section class="description-panel p-32 position-relative">
    <a class="back-btn" [routerLink]="['/jobs/list']">
      <icon class="d-inline-block" size="24px" name="chevron_left" type="material" cuClass="d-flex"></icon> Back to Jobs
    </a>
    <div class="card bg-light mt-24">
      <header class="detail-page-header position-relative">
        <div>
          <h3 *ngIf="jobData?.title?.title || jobData?.template_name">
            {{jobData?.template_name || jobData.title.title}} <span
              class="status-ui ml-16 pending-approval">{{jobStatus}}</span>
          </h3>
          <p class="detail-meta" *ngIf="!fixedHeader">
            <icon class="meta-label" name="label" size="18px"></icon> {{jobData?.id}}
            <icon class="ml-32 updated" name="update" size="18px"></icon> Last Updated By :
            <b> {{jobData?.created_by?.first_name}} {{jobData?.created_by?.last_name}} </b>
           on {{getFormattedDate(jobData?.modified_on)}}  {{getFormattedTime(jobData?.modified_on)}}
          </p>
        </div>
        <div class="actions position-absolute">
          <button class="btn btn-primary" #actionButton>
            Actions <icon class="icon--on-right" size="20px"
              [name]="actionVisibility? 'keyboard_arrow_up' : 'keyboard_arrow_down'" type="material" cuClass="icon-cu">
            </icon>
          </button>
          <ul class="action-list position-absolute bg-light rounded-sm pt-12 pb-12"
            [ngClass]="{'active' : actionVisibility}" #actionDropdown>
            <li>
              <a href="javascript:void(0)" class="pt-8 pb-8 pl-24 pr-8" (click)="optOut()">
                <icon class="mr-16" size="20px" name="launch" type="material" cuClass="icon-cu"></icon>
                {{isOptOutText == 'opt-in' ? 'Opt-In' : 'Opt-Out'}}
              </a>
            </li>
            <!-- <li>
                <a href="javascript:void(0)" class="pt-8 pb-8 pl-24 pr-8" (click)="submitCandidate()">
                  <icon class="mr-16" size="20px" name="how_to_reg" type="material" cuClass="icon-cu"></icon> Submit
                  Candidate
                </a>
              </li>-->
            <li>
              <a class="pt-8 pb-8 pl-24 pr-8" (click)="addSubmission()"
                *ngIf="isOptOut && (jobStatus == 'Pending Approval - Sourcing' || jobStatus == 'Sourcing')">
                <icon class="mr-16" size="20px" name="person_add" type="material" cuClass="icon-cu"></icon> Add &amp;
                Submit Candidate
              </a>
            </li>
          </ul>
        </div>
      </header>
      <nav class="detail-nav pl-24 pr-24">
        <ul class="primary">
          <li>
            <a [routerLink]="['/jobs/details/job-details', param]" [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{ exact: true }">
              <icon class="mr-8" size="20px" name="info" theme="outlined" cuClass="icon-cu"></icon> Job Details
            </a>
          </li>
          <!-- <li>
            <a href="javascrpt:void(0)">
              <icon class="mr-8" size="20px" name="people_outline" theme="outlined" cuClass="icon-cu"></icon> Available Candidates
            </a>
          </li> -->
          <li>
            <a [routerLink]="['/jobs/details/job-details',param,'submitted-candidate']"
              [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
              <icon class="mr-8" size="20px" name="how_to_reg" theme="outlined" cuClass="icon-cu"></icon> Submitted Candidates
            </a>
          </li>
          <li>
            <a [routerLink]="['/jobs/details/job-details',param,'interviews']" [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{ exact: true }">
              <icon class="mr-8" size="20px" name="group_work" theme="outlined" cuClass="icon-cu"></icon> Interviews
            </a>
          </li>
          <li hidden>
            <a>
              <icon class="mr-8" size="20px" name="drafts" theme="outlined" cuClass="icon-cu"></icon> Offers
            </a>
          </li>
          <!-- <li><a [routerLink]="['/jobs/details/job-details',param,'offers']" [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{ exact: true }">Offers</a></li> -->
          <li hidden>
            <a>
              <icon class="mr-8" size="20px" name="assignment" theme="outlined" cuClass="icon-cu"></icon> Assignment
            </a>
          </li>
          <li>
            <a [routerLink]="['/jobs/details/job-details',param,'approval']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
              <icon class="mr-8" size="20px" name="published_with_changes" theme="outlined" cuClass="icon-cu"></icon> Approval History
            </a>
          </li>
          <li hidden>
            <a>
              <icon class="mr-8" size="20px" name="history" theme="outlined" cuClass="icon-cu"></icon> History
            </a>
          </li>
        </ul>
        <div class="clearfix"></div>
      </nav>
      <ng-content></ng-content>
    </div>
    
    <!--<router-outlet></router-outlet> -->
    <!-- <job-details *ngIf="jobDetails"></job-details>
        <app-available-candidates *ngIf="availableCandidate"></app-available-candidates>
        <app-submitted-candidates *ngIf="submittedCandidate"></app-submitted-candidates>
        <app-interviews *ngIf="interviews"></app-interviews>
        <app-offers *ngIf="offers"></app-offers>
        <app-assignment *ngIf="assignment"></app-assignment>
        <app-approvel *ngIf="approval"></app-approvel>
        <app-history *ngIf="history"></app-history> -->
  </section>
</div>