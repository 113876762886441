import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EmitEvent, Events, EventStreamService } from 'src/app/core/services/event-stream.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { ProgramConfig, UserDataObj } from 'src/app/shared/enums';
import { DataTransferService } from '../../../core/services/data-transfer.service';
import { ProgramService } from '../../../programs/program.service';
import { UserService } from './../../../core/services/user.service';
import { ProgramSetupSidebarModule } from './program-setup-sidebar.module';
import { ProgramSetupSidebarService } from './program-setup-sidebar.service';


@Component({
  selector: 'app-program-setup-sidebar',
  templateUrl: './program-setup-sidebar.component.html',
  styleUrls: ['./program-setup-sidebar.component.scss']
})
export class ProgramSetupSidebarComponent implements OnInit {

  @Output()
  buttonClicked: EventEmitter<string> = new EventEmitter<string>();
  sidebarToggle = "";
  @Input()
  addZindexClass;

  isToggled: string = '';
  isActive: string = '';
  sideBarData: ProgramSetupSidebarModule[];
  sideBar: ProgramSetupSidebarModule;
  public userAvatar: string;
  public userName: string;
  public userData = UserDataObj;
  clientData: any;
  programData: any;
  client_name: any;
  client_logo: any;
  client_status: any;
  category: any;
  industry: any;
  email: any;
  moduleGroup = [];
  public programId: string;
  public user: any;
  selectedModuleName: any;
  eventSubscription: any;
  constructor(public themeService: ThemeService,
    private sidebarService: ProgramSetupSidebarService,
    private router: Router,
    public userService: UserService,
    private _storageService: StorageService,
    private programService: ProgramService,
    public theme: ThemeService,
    public dataTransferService: DataTransferService,
    private eventStream: EventStreamService,
  ) { }

  ngOnInit(): void {
    this.user = this._storageService.get('user');
    let programId = this._storageService.get(ProgramConfig[0]);
    if (programId && programId != null) {
      programId = JSON.parse(programId);
      this.programId = programId?.program_req_id;
    }
    let programObj = this._storageService.get(ProgramConfig[5]);
    if(programObj && programObj != null){
      this.client_name = programObj?.name;
    }

    let sidebar = this._storageService.get(this.userData[5]);
    if (sidebar != null)
      setTimeout(() => {
        this.buttonClicked.emit(this.sidebarToggle = sidebar);
      }, 0);
    this.sideBarData = this.sidebarService.getSideMenu();
    this.userService.getAllModuleList(this.programId).subscribe(data => {
      if (data && data.module_groups) {
        this.moduleGroup = data.module_groups;
        this.sideBarData.map(m => {
          if (m.name === 'Custom Fields') {
            m.sideBarSubMenu[0].subMenuItem = [];
            this.moduleGroup.forEach(e => {
              let obj = {
                title: e.name,
                class: 'sidebar-icon-submenu',
                path: '/program-setup/custom-fields/list',
                isNotification: false
              }
              m.sideBarSubMenu[0].subMenuItem.push(obj);
            });
          }
        })
      }
    }, error => {
      console.log(error);
    })
    this.programData = JSON.parse(this._storageService.get(ProgramConfig[0]));
    this.fetchClientDetails();
    this.programService.shareProgramData.subscribe(data => {
      if(data){
        this.fetchClientDetails();
      }
    })

  }


  fetchClientDetails() {
    //let categoryName = 'client';
    this.programService.get(`/configurator/programs/${this.programData.program_req_id}`).subscribe((data) => {
      this.clientData = data.program;
      this.client_name = this.clientData?.name != null ? this.clientData?.name : '';
      this.client_logo = this.clientData?.logo != null ? this.clientData?.logo : this.client_name;
      this.client_status = this.clientData?.is_enabled != null ? this.clientData?.is_enabled : '';
      //this.category = this.clientData?.category != null ? this.clientData?.category : '';
      // this.industry = this.clientData?.industries?.name != null ? this.clientData?.industry?.name : '';
      // this.email = this.clientData?.created_by?.email != null ? this.clientData?.created_by?.email : '';
      // if (this.clientData) {
      //   let newClientJson = {
      //     client_name: this.clientData?.name,
      //     client_logo: this.clientData?.logo,
      //     client_status: this.clientData?.is_enabled,
      //     category: this.category,
      //     industry: this.industry,
      //     email: this.email
      //   }
      //  // this._storageService.set(ClientData[0], JSON.stringify(newClientJson), true);
      // }
    }, error => {
      if (error?.error?.message) {
        console.log(error);
        //this._alert.error(err?.error?.message);
      }
    });
  }


  get logoUrl() {
    switch (this.themeService.getCurrentTheme()) {
      case 'light':
        return './assets/images/logo-light.svg';
      case 'dark':
        return './assets/images/logo-dark.svg';
      default: return './assets/images/logo-light.svg';
    }
  }
  openSideMenu(name: string, open = false) {
    if (open) {
      if (this.isToggled === name) {
        this.isToggled = ''
        this.sideBar = undefined
        return
      }
      this.sideBar = this.sideBarData.find(s => s.name === name)
      this.isToggled = name
    } else {
      this.isToggled = ''
      this.sideBar = undefined
    }
  }

  closeSidebarMenu(name, title) {
    this.selectedModuleName = title;
    this.eventStream.emit(new EmitEvent(Events.CUSTOM_FIELDS, this.selectedModuleName));
     this.isToggled = ''
    this.sideBar = undefined
    this.isActive = name
  }

  redirectByUrl(url) {
    this.isToggled = ''
    this.sideBar = undefined
    this.router.navigateByUrl(url)
  }

  logout() {
    this.theme.changeTheme('');
    this._storageService.clear();
    this.router.navigate(['/auth/login']);
  }

}
