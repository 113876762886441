import { Component, OnInit } from '@angular/core';
import { CandidateService } from 'src/app/candidate/service/candidate.service';
import { EventStreamService, Events, EmitEvent } from 'src/app/core/services/event-stream.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { errorHandler } from 'src/app/shared/util/error-handler';

@Component({
  selector: 'app-view-submitted-candidate',
  templateUrl: './view-submitted-candidate.component.html',
  styleUrls: ['./view-submitted-candidate.component.scss']
})
export class ViewSubmittedCandidateComponent implements OnInit {
  viewCandidateProfile = "hidden";
  qualificationTypeName: any;
  public certificationsArr = [];
  public vaccinationsArr = [];
  public credentialsArr = [];
  public specialityArr = [];
  public educationArr = [];
  public skillsArr = [];
  basicInfo;
  constructor(
    private eventStream: EventStreamService, 
    private candidateService: CandidateService,
    private _alert: AlertService,
  ) { }

  ngOnInit(): void {
    this.eventStream.on(Events.VIEW_SUBMITTED_CANDIDATE_PROFILE, (data) => {
      if (data.value) {
        this.viewCandidateProfile = 'visible';
        this.getCandidateDetailsById(data.value.id);
      } else {
        this.viewCandidateProfile = 'hidden';
      }
    });
  }
  sidebarClose() {
   this.certificationsArr = [];
   this.vaccinationsArr = [];
   this.credentialsArr = [];
   this.specialityArr = [];
   this.educationArr = [];
   this.skillsArr = [];
   this.viewCandidateProfile = 'hidden';
  }
  getCandidateDetailsById(id){
    this.candidateService.getCandidateDetail(id).subscribe(data=>{
      this.basicInfo =data.candidate;
      if (data.candidate.qualifications.length) {
        data.candidate.qualifications.filter((qItem) => {
          if (qItem.qualification_type) {
            this.getTypeName(qItem);
          }
        })
      }
    },
    error=>{
      this._alert.error(errorHandler(error));
    })
  }
  getTypeName(qItem) {
    this.candidateService._getQualiFicationType(qItem.qualification_type.id).subscribe((res) => {
      const resQualification = res.qualifications;
      for (let val of resQualification) {
        if (val.id == qItem.qualification_id) {
          this.qualificationTypeName = val.name;
          //code is commeted for future use, If needed we can uncomment this.
          // if (qItem.qualification_type.code == 'CERTIFICATION') {
          //   qItem['certification_name'] = this.qualificationTypeName;
          //   delete qItem.qualification_type;
          //   this.certificationsArr.push(qItem);
          // } else if (qItem.qualification_type.code == 'VACCINATION') {
          //   qItem['vaccine_name'] = this.qualificationTypeName;
          //   delete qItem.qualification_type;
          //   this.vaccinationsArr.push(qItem);
          // } else if (qItem.qualification_type.code == 'CREDENTIAL') {
          //   qItem['credential_name'] = this.qualificationTypeName;
          //   delete qItem.qualification_type;
          //   this.credentialsArr.push(qItem);
          // } else if (qItem.qualification_type.code == 'SPECIALITY') {
          //   qItem['speciality_name'] = this.qualificationTypeName;
          //   delete qItem.qualification_type;
          //   this.specialityArr.push(qItem);
          // } else if (qItem.qualification_type.code == 'EDUCATION') {
          //   qItem['education_name'] = this.qualificationTypeName;
          //   delete qItem.qualification_type;
          //   this.educationArr.push(qItem);
          // } 
          //else if (qItem.qualification_type.code == 'SKILL') {
            if (qItem.qualification_type.code == 'SKILL') {
            qItem['skill_name'] = this.qualificationTypeName;
            delete qItem.qualification_type;
            this.skillsArr.push(qItem);
          }
        }
      }
    });
  }
  downloadResume() {
    if(this.basicInfo.raw_resume){
      const linkSource = this.basicInfo.raw_resume;
      const downloadLink = document.createElement("a");
      const fileName = this.basicInfo.resume;
      downloadLink.href = linkSource;
      console.log(downloadLink.href);
      downloadLink.download = fileName;
      downloadLink.click();
    }
    else{
      this._alert.error(`No resume found for this candidate.`);
    }
  }
}
