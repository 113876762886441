import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/core/services/storage.service';
import { VMSConfig } from 'src/app/library/smartTable/table/table.model';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { ReportService } from '../report.service';
import { errorHandler } from '../../shared/util/error-handler';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import value from '*.json';
import { ReportKeys } from 'src/app/reports/enums/report-enum';
@Component({
  selector: 'app-reports-details',
  templateUrl: './reports-details.component.html',
  styleUrls: ['./reports-details.component.scss']
})
export class ReportsDetailsComponent implements OnInit {
  openDrop = false;
  public dataVisibility = 'hidden';
  public tableConfig: VMSConfig;
  public report_filters: any = [];
  public selectedReportFilters: any = {};
  public activeChartValue = 'barChart';
  programId: any;
  reportKey: any;
  reportData: any = [];
  chartData: any = [];
  reportConfigData: any = [];
  totalRecords: number = 0;
  public itemPerPage: number = 25;
  searchTerm: '';
  dimention: any = {};
  dataLoading = true;
  public tableLoaded = false;
  dimension_id: any;
  diamentionData: any = [];
  download = false;
  // code for future use
  // monthNames = ["jan", "feb", "mar", "apr", "may", "jun",
  // "jul", "aug", "sep", "oct", "nov", "dec"];
  // selectedMonth;
  // selectedYear;

  constructor(public reportService: ReportService,
    public _storageService: StorageService,
    public _alert: AlertService,
    private route: ActivatedRoute,
    public loader: LoaderService,
    public router: Router,) { }

  ngOnInit(): void {
    //this.loader.show();
    let programDetails = this._storageService.get('CurrentProgram');
    if (programDetails) {
      this.programId = programDetails['id'];
    }
    this.route.paramMap.subscribe(param => {
      this.reportKey = param.get('report');
    });

    this.getConfigData();

    this.tableConfig = {
      title: '           ',
      columnList: [],
      tabsList: ['All', 'Approved', 'Pending', 'Rejected'],
      showTabs: false,
      isExpand: true,
      isFilter: false,
      isSearch: true,
      isSetting: true,
      isTopPagination: true,
      isCreate: false,
      isSort: true,
      isMonthYearFilter: false,
      density: 'COMFORTABLE',
      isIdDifferent: true,
      differentId: 'assignment_uuid',
    };
  }

  getConfigData() {
    let _url = `/report/programs/${this.programId}/report-config/${this.reportKey}`;
    this.reportService.get(_url).subscribe(
      data => {
        let column_width;
        if (data?.data?.chart_dimensions) {
          var result = Object.keys(data?.data?.chart_dimensions).map((key) => [data?.data?.chart_dimensions[key]]);
        }
        result?.forEach(element => {
          this.diamentionData?.push(element[0]);
        });
        this.dimention.Id = this.diamentionData[0]?.dimension_id;
        //Don't remove this commented line, we need to enable it later, not in scope for wipro
        // if(data?.data?.has_chart){
        //   this.getChartData();
        // }

        this.tableConfig.title = data?.data?.report_title || 'Reports';
        let columnData = data?.data?.list_default_columns;
        if (columnData && columnData?.length > 7) {
          let width = columnData && columnData?.length * 200 + 'px';
          this.tableConfig.tableWidth = width;
        } else {
          this.tableConfig.tableWidth = "100%";
        }
        column_width = (100 / columnData?.length);
        columnData?.forEach(r => {
          const title = this.snakeCaseToTitle(r, data?.data?.list_column_mapping);


          if (r === 'status') {
            this.tableConfig?.columnList.push({ name: r, title: title, width: column_width, isIcon: false, isImage: false, isContact: false, isNoOption: false, isNumberBadge: false });
          } else {
            this.tableConfig?.columnList.push({ name: r, title: title, width: column_width, isIcon: false, isImage: false, isContact: false, isNumberBadge: false, isNoOption: true });
          }
        });
        if (this.tableConfig?.columnList && this.tableConfig?.columnList?.length === 0) {
          this.tableConfig.columnList = [
            { name: 'status', title: 'Status', width: 10, isIcon: true, isImage: false, isContact: false, isNumberBadge: false },
            { name: 'title', title: 'Title', width: 10, isIcon: true, isImage: false, isContact: false, isNumberBadge: false },
            { name: 'buisnees_need', title: 'Business Need', width: 15, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
            { name: 'remote_location', title: 'Remote Location', width: 15, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
            { name: 'cost_center', title: 'Cost Center', width: 15, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          ]
        }
        this.tableLoaded = true;
        this.reportConfigData = data?.data;
        this.getReportData(1);
        //this.getFilterData();
        this.route.queryParams.subscribe(
          params => {
            if (params['download']) {
              if (params['download'] == 'pdf') {
                this.downloadReport('pdf');
              } else if (params['download'] == 'csv') {
                this.downloadReport('csv');
              } else {
                this.downloadReport('excel');
              }
            }
          })
      }, err => {
        this._alert.error(errorHandler(err));
      });
  }

  snakeCaseToTitle(snakeCaseString, data) {
    for (const [key, value] of Object.entries(data)) {
      if (snakeCaseString === `${key}`) {
        snakeCaseString = `${value}`;
        return snakeCaseString;
      }
    }

    if (!snakeCaseString) {
      return '';
    }
    snakeCaseString = snakeCaseString?.toLowerCase().split('_');
    for (let i = 0; i < snakeCaseString?.length; i++) {
      snakeCaseString[i] = snakeCaseString[i][0].toUpperCase() + snakeCaseString[i].slice(1);
    }
    return snakeCaseString.join(' ');
  }

  getReportData(pageNo = 1) {
    this.loader.show();

    // for future use
    // var monthYear = '';
    // if(this.selectedMonth){
    //   monthYear += `${this.selectedMonth}`;
    // }else{
    //   monthYear += `${this.monthNames[(new Date).getMonth()]}`;
    // }


    // if(this.selectedYear){
    //   monthYear += `_${this.selectedYear}`;
    // }else{
    //   monthYear += `_${(new Date()).getFullYear()}`;
    // }

    let payLoad = {
      "report_id": this.reportKey,
      "page": pageNo,
      "per_page": 25,
      "default_filters": null,
      "report_filters": this.selectedReportFilters,
      "table_columns": this.reportConfigData?.list_default_columns,
      "order_by": "start_date",
      "order_type": "asc",
      "search_text": this.searchTerm,
      // "time":monthYear
    };
    let _url = `/report/programs/${this.programId}/list-by-params`;

    this.reportService.post(_url, payLoad).subscribe(
      data => {
        data?.data?.table_data?.forEach(d => {
          const reportKeys = Object.keys(d);
          const dateKeys = reportKeys?.filter(key => key?.includes('_date'));
          dateKeys?.forEach(dk => {
            d[dk] = this.dateFormate(d[dk]);
          });
        });
        this.reportData = data?.data;

        this.getFilterData();
        this.checkStatus(this.reportData?.table_data);
        this.totalRecords = data?.data?.paging?.total_records ? data?.data?.paging?.total_records : 0;
        this.dataLoading = false;
        this.tableLoaded = true;
        this.loader.hide();
      }, err => {
        this.reportData.table_data = [];
        this.totalRecords = 0;
        this._alert.error(errorHandler(err));
        this.dataLoading = false;
        this.loader.hide();
      });
  }
  checkStatus(data) {
    data?.forEach(s => {
      const statusKeys = Object.keys(s);
      const Keys = statusKeys?.filter(key => key?.includes('_status'));
      Keys?.forEach(k => {
        if (s[k] === 'IN_PROGRESS') {
          s[k] = 'in progress'
        }
      });
    });
  }
  dateFormate(d) {
    const date = new Date(d);
    return ('0' + date?.getDate()).slice(-2) + '/' + ('0' + (date?.getMonth() + 1)).slice(-2) + '/' + date?.getFullYear();
    // return  date?.getDate() + "/" + (date?.getMonth() + 1) + "/" + date?.getFullYear() + " ";
  }

  getDimentionId(id) {
    this.dimension_id = id;
    this.getChartData();
  }
  getChartData(pageNo = 1) {
    //this.loader.show();
    let payLoad = {
      "report_id": this.reportKey,
      "dimension": this.dimension_id || this.dimention.Id,
    };
    let _url = `/report/programs/${this.programId}/chart-by-params`;
    this.reportService.post(_url, payLoad).subscribe(
      data => {
        this.chartData = data?.data;
        this.totalRecords = data?.data?.paging?.total_records ? data?.data?.paging?.total_records : 0;
        this.dataLoading = false;
        // this.tableLoaded = true;
        // this.loader.hide();
      }, err => {
        this.totalRecords = 0;
        this._alert.error(errorHandler(err));
        this.dataLoading = false;
        //this.loader.hide();
      });
  }


  getFilterData() {
    if(this.report_filters?.length == 0){
      this.reportData?.selected_columns?.forEach(dt=> {
        this.report_filters?.push({'filter_id':dt?.column_name,'filter_title':dt?.column_title});
      });
    }
    // this.reportConfigData?.list_default_columns?.forEach(data => {
    //   let payLoad = {
    //     "report_name": this.reportKey,
    //     "filter_name": [data]
    //   };
    //   let _url = `/report/programs/${this.programId}/get-report-filters`;
    //   this.reportService.post(_url, payLoad).subscribe(
    //   data => {
    //     let report_filters = Object.entries(data?.data?.report_filters).map((e) => (e[1]));
    //     report_filters?.forEach((r:any) => {
    //       this.tableConfig?.columnList?.forEach(t => {
    //         if (t?.name === r?.filter_id) {
    //           r.filter_title = t.title
    //         }
    //       });
    //     });
    //     if(report_filters && report_filters.length){
    //     this.report_filters?.push(report_filters[0]);
    //     }
    //   }, err => {
    //     this._alert.error(errorHandler(err));
    //   });
    // });
  }


  onPaginationClick(e) {
    this.getReportData(e)
  }
  onSearch(term) {
    if (term != this.searchTerm) {
      this.searchTerm = term;
      this.getReportData();
    }
  }

  // monthChangeClick(event){
  //   this.selectedMonth = event
  //   this.getReportData();
  // }

  // yearChangeClick(event){
  //   this.selectedYear = event
  //   this.getReportData();
  // }

  onClickedOutside(e) {
    this.openDrop = false;
  }

  downloadReport(type) {
    if((this.reportKey?.toLowerCase() == ReportKeys.MergedComplianceReport) && (Object.keys(this.selectedReportFilters)?.length === 0 || this.selectedReportFilters?.vendor?.length == 0 )){
      this._alert.error("Select atleast one vendor!");
      return;
    }
    this.download = true;
    //this.loader.show('Downloading, Please wait...');
    let payLoad = {
      "report_data_type": "filtered",
      "report_format": type,
      "report_name": this.reportKey,
      "report_filters": this.selectedReportFilters,
      "report_columns": this.reportConfigData?.list_default_columns,
      "chart_image": "",
      "chart_by": "Category"
    };
    let _url = `/report/programs/${this.programId}/downloadreport`;
    this.reportService.postBlob(_url, payLoad).subscribe(
      (data: Blob) => {
        let isDownloadRoute = this.route.snapshot.queryParams['download'];
        if (type === 'excel') {
          let blob = new Blob([data], { type: "application/vnd.ms-excel" });
          let random = Math.floor(Math.random() * Math.floor(10));
          let filename = this.reportKey + random + '.xlsx';
          var link = document.createElement('a');
          document.body.appendChild(link);
          const blobData = window.URL.createObjectURL(blob);
          link.href = blobData
          link.download = filename;
          link.style.display = 'none';
          link.click();
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobData);
            if (isDownloadRoute) {
              window.close();
            }
          }, 100);
        } else if (type === 'csv') {
          let blob = new Blob([data], { type: "application/vnd.csv" });
          let random = Math.floor(Math.random() * Math.floor(10));
          let filename = this.reportKey + random + '.csv';
          var link = document.createElement('a');
          document.body.appendChild(link);
          const blobData = window.URL.createObjectURL(blob);
          link.href = blobData
          link.style.display = 'none';
          link.download = filename;
          link.click();
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobData);
            if (isDownloadRoute) {
              window.close();
            }
          }, 100);
        }
        else if (type === 'pdf') {
          let blob = new Blob([data], { type: "application/vnd.pdf" });
          let random = Math.floor(Math.random() * Math.floor(10));
          let filename = this.reportKey + random + '.pdf';
          var link = document.createElement('a');
          document.body.appendChild(link);
          const blobData = window.URL.createObjectURL(blob);
          link.href = blobData;
          link.style.display = 'none';
          link.download = filename;
          link.click();
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobData);
            if (isDownloadRoute) {
              window.close();
            }
          }, 100);
        }
        //this.loader.hide();
        this.dataLoading = false;
        // this.tableLoaded = true;
      }, err => {
        this._alert.error(errorHandler(err));
      });
  }
  applyReportFilter(data) {
    this.selectedReportFilters = data?.filter
    this.getReportData();
    this.closehistoryView();
  }

  closehistoryView() {
    this.dataVisibility = 'hidden';
  }
  onClickView() { }
  onSortClick() { }
  onCreateClick() { }
  onExpandClick() { }

  activeChart(val) {
    this.activeChartValue = val;
  }
  openDropdown() {
    this.openDrop = !this.openDrop;
  }
}
