<div class="account-form-wrapper">
  <div class="top-left-corner"></div>
  <div class="top-right-corner"></div>
  <div class="bottom-left-corner"></div>
  <div class="bottom-right-corner"></div>
  <div class="form-wrapper">
    <div class="logo text-center pt-8">@</div>
    <h2 class="text-center">Set Account Password</h2>
    <p class="text-center">Create a strong password for your simplify account using the form below</p>
    <div class="progress-bar">
      <ul class="pb-12">
        <li class="active"></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <div class="form-group mb-0">
      <label>Enter your password <span class="require">*</span></label>
      <input type="password" class="form-control medium no-radius" [ngClass]="{'error': isPasswordError}"
        [(ngModel)]="password" placeholder="Enter your password"
        appPasswordToggle>
    </div>
    <div class="output-checker mb-24">
      <vms-password-strength [passwordToCheck]="password">
      </vms-password-strength>
    </div>
    <div class="validation-alert p-8 alert-error overflow-hidden d-flex mb-16"
      *ngIf="isPasswordError || isRePasswordError">
      <icon name="error_outline" size="24px" type="material" class="d-flex mr-16 justify-content-align-center"></icon>
      <p class="small" *ngIf="errorMessage">
        {{errorMessage}}
      </p>
    </div>

    <div class="form-group mb-8">
      <label>Confirm your password <span class="require">*</span></label>
      <input type="password" class="form-control medium" [ngClass]="{'error': isRePasswordError}"
        [(ngModel)]="rePassword" (ngModelChange)="checkPassword(rePassword)" placeholder="Confirm your password" appPsswordToggle>
    </div>
    <div class="row">
      <div class="col">
        <button [disabled]="invalidPassword" class="account-btn btn btn-secondary float-right" (click)="basicDetail()">Save &amp; Continue
          <icon class="icon--on-right d-flex" cuClass="intro-btn" name="keyboard_backspace" size="24px" type="material">
          </icon>
        </button>
      </div>
    </div>
  </div>
</div>
