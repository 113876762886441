import { Component, LOCALE_ID, Inject, OnInit, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventStreamService, Events } from '../app/core/services/event-stream.service';
import { mapTo } from 'rxjs/operators';
import { Observable, fromEvent, merge, of } from 'rxjs'
import { AlertService } from '../app/shared/components/alert/alert.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Simplify VMS 2.0';
  showBackdrop = false;

  valueEmittedFromChildComponent = '';

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
  }

  valueEmittedFromHeaderComponent: '';

  notificationEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromHeaderComponent = valueEmitted;
  }

  logged_In = false;
  onLine: Observable<any>;
  constructor(
    private changeDetection: ChangeDetectorRef,
    public translate: TranslateService,
    private eventStream: EventStreamService,
    private _alerts: AlertService,
    private _render: Renderer2,
    @Inject(LOCALE_ID) protected localeId: string) {
    if (localeId) {
      translate.setDefaultLang(localeId.split('-')[0] ? localeId.split('-')[0] : 'English (United States)');
    }
    this.onLine = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo('yes')),
      fromEvent(window, 'offline').pipe(mapTo('no'))
    )
    this.getNetworkStatus();
  }
  ngOnInit() {
    this.eventStream.on(Events.LoggedIn, (data) => {
      this.logged_In = data;
      // this.changeDetection.detectChanges()
    })
    this.eventStream.on(Events.DO_SHOW_CLIENT_FORM, (data) => {
      this.showBackdrop = data;
      if (data) {
        this._render.addClass(document.body, 'overflow-hidden')
      } else {
        this._render.removeClass(document.body, 'overflow-hidden')
      }
      this.changeDetection.detectChanges()
    })
    this.eventStream.on(Events.DO_SHOW_USER_FORM, (data) => {
      this.showBackdrop = data;
      if (data) {
        this._render.addClass(document.body, 'overflow-hidden')
      } else {
        this._render.removeClass(document.body, 'overflow-hidden')
      }
      this.changeDetection.detectChanges()
    })
    this.eventStream.on(Events.DO_SHOW_USER_FORM, (data) => {
      this.showBackdrop = data;
      if (data) {
        this._render.addClass(document.body, 'overflow-hidden')
      } else {
        this._render.removeClass(document.body, 'overflow-hidden')
      }
      this.changeDetection.detectChanges()
    })
    this.injectMatomo();

  }
  
  injectMatomo(){
    var _paq = window['_paq'] = window['_paq'] || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = "//insights.simplifyvms.com/";
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', environment.MATOMO_SITE_ID]);
      console.log("environment.MATOMO_SITE_ID  ",environment.MATOMO_SITE_ID);
      var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript'; g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
    })();
  }

  getNetworkStatus() {
    let cnt = 0;
    this.onLine.subscribe(status => {
      if (cnt > 0 && status == 'yes') {
        this._alerts.success('Back to online', { color: 'black', bgColor: 'lightgreen' });
      }
      if(status == 'no') {
        this._alerts.warn('You are in offline', { color: 'black', bgColor: '#DE4E5E' });
      }
      cnt++;
    })
  }
}
