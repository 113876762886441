import { Component, OnInit, Input, Output } from '@angular/core';
import { JobDetailsService } from '../../job-details.service';
import { errorHandler } from '../../../../shared/util/error-handler';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { ActivatedRoute } from '@angular/router';
import { EmitEvent, Events, EventStreamService } from 'src/app/core/services/event-stream.service';
import { ApprovalStatus } from './../../../../shared/enums';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailComponent implements OnInit {
  @Input() maxContentLength: number = 1000;
  public toggleDescription = false;
  public jobDetails: any;
  public param = '';
  public myName = "rahul"
  private jobId = ''; 
  public user: any;
  public isShowStatus: boolean = false;
  public ApprovalStatus: any = ApprovalStatus;
  public jobStatus;
  approval_id: any;

  constructor(
    private jobService: JobDetailsService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private _eventStrem: EventStreamService,
    private _storageService: StorageService) {
    // console.log(this.route.snapshot);

  }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    let jobid = this.route.snapshot.params['id'];
    this.param = jobid;
    this.getJobDetails(jobid);
  }

  reloadDetailsPage(e) {
    this.isShowStatus = false;
    let jobid = this.route.snapshot.params['id'];
    this.param = jobid;
    this.getJobDetails(jobid);
  }

  contentViewToggle() {
    this.toggleDescription = !this.toggleDescription;
  }

  getJobDetails(jobid) {
    // const data = this._storageService.get('viewd_job');
    // if (data) {
    //   this.jobDetails = data;
    //   this.jobStatus = data.status;
    //   // console.log("this.jobDetails", this.jobDetails);
    //   this.jobDetails.approverlist.forEach(a => {
    //     if (a.approver.id == this.user.id && (this.jobStatus == 'pending_approval' || this.jobStatus == 'Pending Approval')) {
    //       this.isShowStatus = true;
    //     }
    //   });
    //   this.getJobByStatus();
    // }
    if (jobid) {
      this.jobService.getJobs(`${jobid}`).subscribe(data => {
        if (data?.data) {
          this.jobDetails = data?.data[0];
          this.jobStatus = data?.data[0]?.status;
          this.jobDetails?.approverlist?.forEach(a => {
            if (a.approver.id == this.user.id && a.status == 'pending' && (this.jobStatus == 'pending_approval' || this.jobStatus == 'Pending Approval')) {
              this.isShowStatus = true;
            }
          }); 
        }
      }, (err) => {
        this.alertService.error(errorHandler(err));
      });
    }
  }

  openApprovalModal() { 
    this._eventStrem.emit(new EmitEvent(Events.PENDING_APPROVAL_REQUEST, { value: true, data: this.jobDetails }));
  }
    
  getFormattedDate(date) {
    if(date){
    date = date?.split(' ');
    let onlyDate = date[0] || "";
    let allDate = onlyDate?.split('-');
    let formattedDate= '';    
    if(allDate?.length === 3){
      let month= [allDate[1]];
      formattedDate= `${allDate[2]}/${month}/${allDate[0]}`;
    } else {

    }
    return formattedDate;    
  }
}

}
