export enum Theme {
  Light = 'light',
  Dark = 'dark'
}
export enum ApprovalStatus {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected'
}

export enum OrgTypes {
  TYPE_VENDOR = "TYPE_VENDOR",
  TYPE_CLIENT = "TYPE_CLIENT",
  TYPE_MSP = "TYPE_MSP"
}

export enum BASIC_FIELD_TYPES {
  textfield = "textfield",
  phone = "phone",
  time = "time",
  date = "date",
  dropdown = "dropdown",
  checkbox = "checkbox",
  radio = "radio",
  toggle = "toggle",
  textarea = "textarea",
  number = "number",
  email = "email",
  file = "file",
  currency = "currency",
  hyperlink = "hyperlink",
  heading = "heading",
  subheading = "subheading"

}

export enum SidebarPosition {
  Left,
  Right
}

export enum UserDataObj {
  Token,
  UserData,
  Usertheme,
  UserLanguage,
  UserWrongCredentials,
  UserSideBar,
  UserTooltip
}

export enum Usersetup {
  formstages
}

export enum ProgramConfig {
  NewProgramData,
  ProgramId,
  ClientId,
  program_req_Id,
  clientName,
  ProgramObj
}

export enum ClientData {
  ClientData
}

export enum HierarchyConfig {
  HIERARCHY_CONFIG
}
export enum BreadcrumEvents {
  PUSH,
  POP
}
