<form [formGroup]="vendorCreate">
  <div class="Upload_box_wrapper ">
    <svms-upload-avatar [image]="vendorCreate?.controls?.logo?.value" [ngClass]="{disable:isViewClicked}" (hideError)="getCropImage($event)"></svms-upload-avatar>
    <div class="upload-panel-body">
      <h5 *ngIf="!logoComponent?.logoUrl || !isImagePresent">Vendor Logo</h5>
      <div *ngIf="(!logoComponent?.logoUrl || !isImagePresent) && !isViewClicked" class="error-text">Only .PNG, .JPG formats with maximum file size of 3 MB</div>
    </div>

    <div class="toggle-panel" [ngClass]="{disable:isViewClicked}">
      <div class="toggle-panel-inner" [ngClass]="toggle?.value ? 'if-active' : 'if-inactive'">
        <span class="status-text">{{toggle?.title}}</span>
        <span class="toggle-switch" [ngClass]="toggle?.value ? 'toggle-on' : 'toggle-off'"
          (click)="onClickToggle()"></span>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label>Organization Name<span class="require">*</span></label>
    <input type="text" class="form-control" placeholder="Vendor name" formControlName="org_name" required [readonly]="isViewClicked" [ngClass]="{'error':
    vendorCreate.controls['org_name'].invalid && (vendorCreate.controls['org_name'].dirty || vendorCreate.controls['org_name'].touched),'disable':isViewClicked
      }" minlength="2">
    <p class="validation-msg"
      *ngIf="vendorCreate.controls.org_name.touched && vendorCreate.controls['org_name'].invalid && vendorCreate.controls['org_name'].errors?.required">
      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Vendor Name is
      Required
    </p>
    <p class="validation-msg" *ngIf="vendorCreate.controls.org_name.errors?.minlength">
      Vendor Name must be at least 2 characters long.
    </p>
    <p class="validation-msg"  *ngIf="!isNameValid">{{duplicateOrg}}</p>
  </div>

  <div class="form-group">
    <label>Organization Type <span class="require">*</span></label>
    <input type="text" class="form-control" placeholder="Vendor" formControlName="org_type" readonly>
  </div>

  <div class="form-group">
    <label>Industry <span class="require">*</span></label>
    <ng-select bindLabel="name" [class.hide-arrow]="isViewClicked" [clearable]="false" [multiple]=" true" placeholder="Industry" ngDefaultControl
      formControlName="industry" [readonly]="isViewClicked">
      <ng-option [value]="data.id" *ngFor="let data of industries">{{data.name}}</ng-option>
    </ng-select>
    <p class="validation-msg"
      *ngIf="vendorCreate.controls.industry.touched && vendorCreate.controls['industry'].errors">
      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Industry is
      Required
    </p>
  </div>
</form>
