<div class="svms-toolbox-header">
  <div class="svms-toolbox-title">
    <p class="small text-secondary">
      CUSTOM FIELDS
    </p>
    <span (click)="openInputFields()" class="cursor-pointer">
      <img *ngIf="isShowInputFields" src="../../../../../assets/images/custom-fields-icons/arrow_drop_down.svg" />
      <img *ngIf="!isShowInputFields" src="../../../../../assets/images/custom-fields-icons/arrow_drop_up.svg" />
    </span>
  </div>
  
  <div *ngIf="!isShowInputFields" class="svms-toolbox">
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.textfield">
      <img src="../../../../../assets/images/custom-fields-icons/1.svg" [attr.field-type]="fieldTypes.textfield" alt="" />
      <p class="small">Text Box</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.phone">
      <img src="../../../../../assets/images/custom-fields-icons/2.svg" [attr.field-type]="fieldTypes.phone" alt="" />
      <p class="small">Phone</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.time">
      <img src="../../../../../assets/images/custom-fields-icons/3.svg" alt="" [attr.field-type]="fieldTypes.time" />
      <p class="small">Date & TIme</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.date">
      <img src="../../../../../assets/images/custom-fields-icons/4.svg" alt="" [attr.field-type]="fieldTypes.date" />
      <p class="small">Date</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.dropdown">
      <img src="../../../../../assets/images/custom-fields-icons/5.svg" alt="" [attr.field-type]="fieldTypes.dropdown" />
      <p class="small">Dropdwon</p>
    </div>
  
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.checkbox">
      <img src="../../../../../assets/images/custom-fields-icons/6.svg" alt="" [attr.field-type]="fieldTypes.checkbox" />
      <p class="small">Checkbox</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.radio">
      <img src="../../../../../assets/images/custom-fields-icons/7.svg" alt="" [attr.field-type]="fieldTypes.radio" />
      <p class="small">Radio</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.toggle">
      <img src="../../../../../assets/images/custom-fields-icons/8.svg" alt="" [attr.field-type]="fieldTypes.toggle" />
      <p class="small">Toggle Button</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.textarea">
      <img src="../../../../../assets/images/custom-fields-icons/asset-1.svg" alt=""
        [attr.field-type]="fieldTypes.textarea" />
      <p class="small">Text Area</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.number">
      <img src="../../../../../assets/images/custom-fields-icons/asset-2.svg" alt=""
        [attr.field-type]="fieldTypes.number" />
      <p class="small">Numbers</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.email">
      <img src="../../../../../assets/images/custom-fields-icons/asset-3.svg" alt=""
        [attr.field-type]="fieldTypes.email" />
      <p class="small">Email</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.file">
      <img src="../../../../../assets/images/custom-fields-icons/asset-4.svg" alt=""
        [attr.field-type]="fieldTypes.file" />
      <p class="small">File Upload</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.currency">
      <img src="../../../../../assets/images/custom-fields-icons/asset-5.svg" alt=""
        [attr.field-type]="fieldTypes.currency" />
      <p class="small">Currency</p>
    </div>
    <div class="svms-input-fields" draggable="true" [attr.field-type]="fieldTypes.hyperlink">
      <img src="../../../../../assets/images/custom-fields-icons/asset-6.svg" alt=""
        [attr.field-type]="fieldTypes.hyperlink" />
      <p class="small">Hyperlink</p>
    </div>
  </div>

</div>
<div class="svms-toolbox-content">
  <app-input-fields></app-input-fields>
  <app-headings></app-headings>
</div>
