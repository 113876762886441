<!-- Login panel language component start here -->
<!-- <div class="language-switcher" *ngIf="standalone"> 
  <ng-select [multiple]="false" [(ngModel)]="language" [clearable]="false" (change)="switchLang()" bindValue="language">
    <ng-option *ngFor="let language of translate.getLangs()" [value]="language">{{ language }}</ng-option>
  </ng-select>
</div> -->

<!-- header panel language component start here -->
<a href="javascript:void(0)" class="lang-modal-cta"  (click)="openModalLanguage()" #langTrigger>
  <icon name="public" size="20px" type="material"></icon>
  {{selectedLang}}
</a>
<div class="language-switcher-modal"  [ngClass]="{'active': langShowHide }" #langSwitcherPanel>
  <h4>
    <icon name="public" size="20px" type="material"></icon> Language
  </h4>
  <a href="javascript:void(0)" class="close-lang-modal" (click)="closeLang()">
    <icon name="clear" size="20px" type="material"></icon>
  </a>
  <div class="language-content p-16">
    <input type="text" placeholder="Search" name="search" *ngIf="langSearch" [(ngModel)]="searchText">
    <input type="text" placeholder="Search" name="search" class="form-control" [(ngModel)]="searchText">
    <ul *ngIf="(translate.getLangs() | filter: searchText).length > 0; else noResults">
      <li *ngFor="let lang of translate.getLangs() | filter: searchText">
        <label class="radio-container" *ngIf="lang != 'en'">
          <input type="radio" [(ngModel)]="language" [value]="lang" name="langgroup" (click)="changeLang($event, lang)">
          <span class="checkmark"></span>
          {{lang}}
          <img src="../../../../assets/images/flag/{{lang}}.png" alt="">
        </label>
      </li>
    </ul>
    <ng-template #noResults>
      <p>Language not available for "{{searchText}}".</p>
    </ng-template>
  </div>
  <div class="lang-footer">
    <a href="javascript:void(0)">
      <icon name="contact_support" size="24px" type="material"></icon>
      Help
    </a>
  </div>
</div>
