<div class="widget-listing-wrap">
    <div class="widgetlist">
        <ul>
            <li><a><i class="material-icons">link</i><label class="pt-8">Add Job</label></a> </li>
            <li><a><i class="material-icons">link</i><label >Spend Analytics</label></a></li>
            <li><a><i class="material-icons">link</i><label>Contact Support</label></a></li>
            <li><a><i class="material-icons">work</i><label>Active Jobs</label></a></li>
            <li><a><i class="material-icons">description</i><label>Resumes to Review</label></a></li>
            <li><a><i class="material-icons">monetization_on</i><label>Headcount</label></a>
            </li>
            <li><a><i class="material-icons">assignment_ind</i><label>Pending
                        Timesheets</label></a></li>
            <li><a><i class="material-icons">assignment</i><label>Current
                        Openings</label></a></li>
            <li><a><i class="material-icons">money_off</i><label>Pending Expenses</label></a>
            </li>
            <li><a><i class="material-icons">monetization_on</i><label>Contract
                        Ending</label></a></li>
        </ul>
    </div>
</div>