<svms-sidebar [title]="label" [clickOutside]="clickOutside" [icon]="orgIcon" [dataVisibility]="createOrganizationType" (onClose)="sidebarClose()">
  <svms-sidebar-body>

    <!--for msp-->
    <vms-tab-group [selectedIndex]="tabIndex" (onSelectedIndexChange)="onIndexChange($event)"
      *ngIf="orgType == 'TYPE_MSP'">
      <vms-tab label="MSP Basic info">

        <app-msp-basic-info [editdata]="clientBasicEditInfo" [isViewClicked]="isViewClicked">
        </app-msp-basic-info>
      </vms-tab>

      <vms-tab label="Address & Contact">
        <address-and-contact-details [editAddress]="clientBasicAddEditInfo" [isViewClicked]="isViewClicked"
          [isCreate]="isCreate"></address-and-contact-details>
      </vms-tab>
    </vms-tab-group>
    <!--for msp-->

    <!--for client-->
    <vms-tab-group [selectedIndex]="tabIndex" (onSelectedIndexChange)="onIndexChange($event)"
      *ngIf="orgType == 'TYPE_CLIENT'">
      <vms-tab label="Client Basic info">
        <app-client-basic-info [editdata]="clientBasicEditInfo" [isViewClicked]="isViewClicked">
        </app-client-basic-info>
      </vms-tab>

      <vms-tab label="Address & Contact">
        <address-and-contact-details [editAddress]="clientBasicAddEditInfo" [isViewClicked]="isViewClicked"
          [isCreate]="isCreate"></address-and-contact-details>
      </vms-tab>
    </vms-tab-group>
    <!--for client-->

    <!--for vendor-->
    <vms-tab-group [selectedIndex]="tabIndex" (onSelectedIndexChange)="onIndexChange($event)"
      *ngIf="orgType == 'TYPE_VENDOR'">
      <vms-tab label="Vendor Basic info">
        <app-vendor-basic-info [editdata]="clientBasicEditInfo" [isViewClicked]="isViewClicked">
        </app-vendor-basic-info>

      </vms-tab>

      <vms-tab label="Address & Contact">
        <address-and-contact-details [editAddress]="clientBasicAddEditInfo" [isViewClicked]="isViewClicked"
          [isCreate]="isCreate"></address-and-contact-details>
      </vms-tab>
    </vms-tab-group>
    <!--for vendor-->

  </svms-sidebar-body>

  <svms-sidebar-footer>

    <button type="submit" class="btn btn-primary float-right" (click)="onContinue()"
      [disabled]="validForm == 'INVALID' || !nameIsValid" *ngIf="tabIndex == 0 && !isViewClicked">
      Continue <icon size="20px" name="chevron_right" type="material" class="icon--on-right" cuClass="icon-cu"></icon>
    </button>
    <button type="submit" class="btn btn-primary float-right" (click)="onContinue()"
      *ngIf="tabIndex == 1 && !isViewClicked"
      [disabled]="validForm == 'INVALID' || !nameIsValid || validAddressForm== 'INVALID'">
      Save <icon size="20px" name="check_circle" type="material" class="icon--on-right" cuClass="icon-cu"></icon>
    </button>

  </svms-sidebar-footer>

</svms-sidebar>