import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'svms-bar-chart',
  templateUrl: './svms-bar-chart.component.html',
  styleUrls: ['./svms-bar-chart.component.scss']
})
export class SvmsBarChartComponent implements OnInit {
  @Input() data: any;
  @Input() height: number = 280;
  @Input() width: number = 900;
  @Input() xLabel = 'label';
  @Input() yLabel = 'status';
  @Input() onHoverIncreaseEffect = true;

  @Input() set chart(data) {
    if (data.length > 0) {
      d3.select("#barId").html("");

      this.data = data;
      
      let keys = Object.keys(this.data[0]);

      this.yLabel = keys[0], this.xLabel = keys[1];

      this.initializeBarComponents();

    } else {
      d3.select("svg").remove();
      d3.select("#barId").html("No Data Available").style("font-size", "1.5em").style("font-weight", 600).style("color", "rgb(185 183 183)");
      return;
    }
  };

  get chart() {
    return this.data;
  }

  // legend dimensions
  legendRectSize: number = 6; // defines the size of the colored squares in legend
  legendSpacing: number = 6; // defines spacing between squares

  svg: any;
  tooltip: any;
  color: any;
  div: any;
  margin: any;


  constructor() {
  }

  ngOnInit() {

  }

  private initializeBarComponents() {
    this.data.forEach(d => {
      d[this.yLabel] = +d[this.yLabel];
    });

    this.color = d3.scaleOrdinal(d3.schemePaired);
    this.margin = { top: 40, right: 20, bottom: 60, left: 60 };

    d3.select("svg").remove();

    this.svg = d3.select("#barId")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    this.tooltip = d3.select("#barHoverId").append("div").attr("class", "barToolTip");

    this.drawBars();
  }

  //Captialize Word
  private titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  private drawBars() {

    const yField = this.yLabel,
      xField = this.xLabel;

    //Container Height & Width
    const contentWidth = this.width - this.margin.left - this.margin.right;
    const contentHeight = this.height - this.margin.top - this.margin.bottom;

    //X & Y Axes
    const x = d3
      .scaleBand()
      .rangeRound([0, contentWidth])
      .padding(0.5)
      .domain(this.data.map(d => d[xField]));

    const y = d3
      .scaleLinear()
      .rangeRound([contentHeight, 0])
      .domain([0, Math.max.apply(Math, this.data.map(d => {
        return d[yField];
      }))]);

    this.svg.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(d3.axisBottom(x))
      //X Axis Label
      .selectAll(".tick text")
      .attr("text-transform", "capitalize")
      .text(d => {
        let label = d.split("-")[0];
        return this.titleCase(label.replace(/_/g, ' '));
      });

    // text label for the x axis
    // this.svg.append("text")
    //   .attr("transform",
    //     "translate(" + (this.width / 2) + " ," +
    //     (this.height - 50) + ")")
    //   .style("text-anchor", "middle").style("text-transform", "capitalize")
    //   .text(this.xLabel);

    this.svg.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(y).ticks(5))
      //Y Axis Label
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text(yField);

    // text label for the y axis
    // this.svg.append("text")
    //   .attr("transform", "rotate(-90)")
    //   .attr("y", 0 - this.margin.left)
    //   .attr("x", 0 - ((this.height / 2) - (this.margin.bottom)))
    //   .attr("dy", "1em")
    //   .style("text-anchor", "middle").style("text-transform", "capitalize")
    //   .text(this.yLabel);

    //-----Bar Code------
    //Add Bar
    this.svg.selectAll('.bar')
      .data(this.data)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d[xField]))
      .attr('width', x.bandwidth())
      .attr("height", d => { return y(d[yField]); }) // always equal to 0
      .attr("y", d => { return y(0); })
      //Fill Color
      .attr('fill', (d: any, i: number) => this.color(i)).attr("fill-opacity", 0.7)
      //Tooltip
      .on("mousemove", (d, i) => {
        let name = (this.titleCase(i[this.xLabel].replace(/_/g, ' ')));
        this.tooltip
          .style("left", Math.abs(d.pageX - 20) + "px")
          .style("top", Math.abs(d.pageY - 150) + "px")
          .style("opacity", 1)
          .html(name + ": " + (i[yField]));
      })
      .on("mouseout", d => {
        this.tooltip.style("opacity", 0);
      });

    //Bar Transition
    this.svg.selectAll("rect")
      .transition()
      .duration(800)
      .attr("y", d => { return y(d[yField]); })
      .attr("height", d => { return contentHeight - y(d[yField]); })
      .delay((d, i) => { return (i * 100) })
  }

}

