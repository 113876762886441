<div class="header py-24 d-flex aign-items-center justify-content-between">
    <a href="" class="d-flex align-items-center text-primary">
      <icon size="24px" name="keyboard_arrow_left" type="material" cuClass="d-flex mr-3"></icon>
      <span>Back to Reports</span>
    </a>
    <div class="d-flex align-items-center">
      <button type="button" class="btn btn-outline-primary small">
        <icon size="18px" name="save_alt" type="material" cuClass="d-flex"></icon>
      </button>
      <button type="button" class="btn btn-outline-primary small ml-12">
        <icon size="18px" name="filter_alt" type="material" cuClass="d-flex mr-8"></icon>
        <span>Filter & Fields</span>
      </button>
      <button type="button" class="btn btn-primary small ml-12">
        <icon size="18px" name="watch_later" type="material" cuClass="d-flex mr-8"></icon>
        <span>Schedule Report</span>
      </button>
    </div>
  </div>
  <div class="panel panel-light mb-24 p-24">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center ">
        <h4 class="font-weight-medium mr-16 mb-0">Job Reports By</h4>
        <div class="position-relative">
          <div class="d-flex align-items-center status px-8 py-4 rounded-sm cursor-pointer">
            <span>Status</span>
            <icon size="18px" name="keyboard_arrow_down" type="material" cuClass="d-flex ml-24"></icon>
          </div>
          <div class="status-drop">
            <span class="pt-12 border-bottom">dashboard</span>
            <span class="pt-12 border-bottom">dashboard</span>
            <span class="pt-12 border-bottom">dashboard</span>
            <span class="pt-12 border-bottom">dashboard</span>
          </div>
        </div>
      </div>

      <div class="d-flex aign-items-center chart-filter">
        <a href="" class="active">
          <icon size="16px" name="bar_chart" type="material" cuClass="d-flex"></icon>
        </a>
        <a href="" class="flip">
          <icon size="16px" name="bar_chart" type="material" cuClass="d-flex"></icon>
        </a>
        <a href="">
          <icon size="16px" name="pie_chart" type="material" cuClass="d-flex"></icon>
        </a>
      
        <a href="">
          <icon size="16px" name="donut_large" type="material" cuClass="d-flex"></icon>
        </a>
        <a href="">
          <icon size="16px" name="show_chart" type="material" cuClass="d-flex"></icon>
        </a>
        <a href="">
          <icon size="16px" name="bubble_chart" type="material" cuClass="d-flex"></icon>
        </a>
      </div>
    </div>

  </div>