import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDateFormat'
})
export class LocalDateFormatPipe extends DatePipe implements PipeTransform {

  localeLang = navigator.language;
  localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  localDateFormat = [
    { location: 'asia', format: 'dd/MM/yyyy' },
    { location: 'america', format: 'MM/dd/yyyy' }
  ]


  transform(value: any, format?: string, timezone?: string, locale?: string): string {
    if (isNaN(new Date(value) as any)) return undefined;
    const formattedDate = super.transform(value, format || this.getLocalFormatByLocation(this.localTimezone), timezone, locale);
    return formattedDate
  }

  getLocalFormatByLocation(location) {
    const currentLocation = location.split('/')[0];
    return this.localDateFormat.find(d => d.location === currentLocation.toLowerCase())?.format || 'MM/dd/yyyy'
  }

}
