import { Qualification } from './../../qualification/qualification.model';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ConfirmationDialogService } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.service';
import { DataTransferService } from '../../core/services/data-transfer.service';
import { StorageService } from '../../core/services/storage.service';
import { AlertService } from '../../shared/components/alert/alert.service';
import { LoaderService } from '../../shared/components/loader/loader.service';
import { errorHandler } from '../../shared/util/error-handler';
import { JobService } from '../job.service';
import { UserService } from './../../core/services/user.service';


@Component({
  selector: 'app-create-job',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.scss']
})
export class CreateJobComponent implements OnInit {
  public createJobForm: FormGroup;
  public programId: string;
  public clientId: string;
  private pageNo = 0;
  private limit = 5;
  public dataLoading = false;
  public totalPages = 12;
  public totalRecords = 10;
  public itemsPerPage: 5;
  public tableLoaded = false;
  public popularJobs: any = [];
  public recentJobs: any = [];
  public tempPopularArray: any = [];
  public tempRecentArray: any = [];
  public templateData = new Array();
  public selectedTemplate: any = {};
  public searchTerm: any;
  public popularJobsTotalCount: any;
  public recentJobsTotalCount: any;
  public rateCard: any;
  show: boolean = true;
  tabIndex = 0;
  foundationTypeList: any[] = [];
  seletHierarchy = 'hidden';
  isCreateEstimate = 'hidden';
  hierarchyData = [];
  defaultHierarchy: string[] = [];
  userAssociateHierarchy: string[] = [];
  usersearchTemplate = new Subject<string>();
  basicJobInfo: any = {};
  createJobPayLoad: any = {};
  selectedTemplateId: any = {};
  selectedHierarchyLevel: any = {};
  jobDetails: any;
  isShow: Boolean = false;
  public currentProgram;
  dropdownShowHide = false;
  // new changes
  createJobObject: any = {};
  basicInfo = true;
  financialDetail = false;
  // jobDetails = false;
  btnText = 'Continue';
  expense = "Yes";
  items = [
    { value: "Yes" },
    { value: "No" }
  ];
  public modules: any = {};
  preicdntified = false;
  showSummaries = false;
  maxRate;
  skills = [
    { value: "HTML" },
    { value: "Java" }
  ];
  managerList: any[] = [];
  public credentialsData = [];
  public qualification_types = [];
  selectCredentials: any = [];
  isCreateCandidate = 'hidden';
  firstFoundationalType: any;
  _foundationTypelist: any[] = [];
  public candidates = [];
  public addCandidate = false;
  approvalList: any = [];
  selectedSkills;
  showOption = false;
  showQualification = false;
  suggestionPaneVisible = false;
  loadingTemplateDetail = false;
  skeletonLoading: any;
  tooltipvisible = 'hidden';
  tooltipTitle: any = '';
  private now = Date.now();
  private days = 24 * 60 * 60 * 1000;
  options: any = {
    language: 'English',
    timepicker: true,
    format12h: true,
    range: false,
    enabledDateRanges: [
      { start: this.now },
    ]
  };
  options2: any = {
    language: 'English',
    timepicker: true,
    format12h: true,
    range: false,
    enabledDateRanges: [
      { start: this.now },
      
    ]
  };
  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    private localStorage: StorageService,
    private _loader: LoaderService,
    private _alert: AlertService,
    public dataTransferService: DataTransferService,
    public jobService: JobService,
    public router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _confirmService: ConfirmationDialogService,
    private location: Location) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
        this.selectedTemplate.template_name = '';
      }
    });
    this.usersearchTemplate.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe((value: any) => {
        if (value?.target?.value) {
          this.searchTerm = value?.target?.value;
          this.getJobTemplate();
        }
      });
  }

  ngOnInit(): void {
    // if (tooltip && tooltip == "visible") {
    this.tooltipvisible = "visible";
    // }
    const yesterday = new Date(this.now)
    yesterday.setDate(yesterday.getDate() - 1);
    this.options = {
      language: 'English',
      timepicker: true,
      format12h: true,
      range: false,
      enabledDateRanges: [
        // { start:  yesterday},
      ]
    };

    this.skeletonLoading = true;
    this.createJobForm = this.fb.group({
      start_date: ['',],
      end_date: ['',],
      location: [null],
      currency: [null, [Validators.required]],
      num_resources: [1, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      hours_per_day: [8, [Validators.required]],
      week_working_days: [5, [Validators.required]],
      formatted_working_days: [null, [Validators.required]],
      working_hours: [null, [Validators.required]],
      adjustment_type: ['percentage' ,''],
      rate_type: ['per_hour', ''],//per_hour / per_day
      single_initial_budget: [null, [Validators.required]],
      single_net_budget: [null, [Validators.required]],
      additional_amount: [0, [Validators.required]],
      single_gross_budget: [null, [Validators.required]],
      adjustment_value: ['', [Validators.required]],
      net_budget: [null, [Validators.required]],
      min_bill_rate: [null,[Validators.required ] ],
      max_bill_rate: [null, [Validators.required ] ],
      rate_dtls: [''],
      pre_identified_candidate: this.preicdntified,
      vendor_rate_exceed: [false, []],
      locations: [null, [Validators.required]],
      job_manager: [null, [Validators.required]],
      hierarchy: ['', [Validators.required]],
      description: [''],
      allow_expense: ['Yes', ''],
      note_for_approver: [''],
      selectedQualification: [null, []],
      qualifications: this.fb.array([]),
      no_of_openings: [1 ,''],
      foundational_data: this.fb.array([]),
      unit_of_measure: [null, [Validators.required]]
    });

    this.modules = {
      toolbar: [
        ['bold', 'italic', 'link', { list: 'ordered' }, { list: 'bullet' }]
      ]
    };
    this.currentProgram = this.localStorage.get('CurrentProgram');
    this.programId = this.currentProgram?.id;

    // this.getJobTemplate();

    this.getRecentJobList(this.pageNo = 1);
    this.getPopularJobList(this.pageNo = 1);
    this.hierarchyList();
    this.getFoundantionalType(this.pageNo = 1);


    this.getWorkLocations();
    this.init();

    this.createJobForm?.controls['start_date']?.valueChanges?.subscribe(data => {
      if(data){
    let start_date = this.getDate(data);
      start_date.setDate(start_date.getDate());

      this.options2 = {
        language: 'English',
        timepicker: true,
        format12h: true,
        range: false,
        enabledDateRanges: [
          { start: start_date }
        ]
      }
    }
      if (data) {
        this.getWorkingHoursEstimate();
        //  if (end_date && start_date.getTime() > new Date(end_date).getTime()) {
        //   this._alert.error("Start Date should be less then end date");
        // }
        // else {
          
        // }

      }
    });
    this.createJobForm?.controls['end_date']?.valueChanges?.subscribe(data => {
      if (data) {
        let start_date: any = this.createJobForm.get('start_date').value;
        let end_date = new Date(data);   
        if (start_date && new Date(start_date).getTime() > end_date.getTime()) {
          this._alert.error("End Date should be greater then start date");
        } else {
          this.getWorkingHoursEstimate();
        }
      }
    });
  }
  get f() { return this.createJobForm.controls; }
  async init() {
    this.getCurrencies();
    // this.getQualificationTypes();
    this.managerList = await this.getManagerList(null, false).toPromise();
    if(this.managerList) {
      let currentUser = this.localStorage.get('user');
      let created_by = {
              id: currentUser?.id,
              first_name: currentUser?.first_name,
              last_name: currentUser?.last_name,
              email: currentUser?.email
            }
      this.managerList.splice(0, 0, created_by);
    }
  }
getDate(date) {
  if(date) {
  const splittedDate = date.split('-');
     const yy = splittedDate[0];
     const month = splittedDate[1];
      const dd = splittedDate[2];
        return  new Date(yy, month-1, dd); 
  }
}
  cancelCreateJob() {
    this._confirmService.confirm('', `Are you sure to cancel the Create New Job ?`,
      'Yes', 'No')
      .then((confirmed) => {
        if (confirmed) {
          this.location.back();
        }
      })
      .catch(() => {

      });
  }
  snakeCase = string => {
    string = string.toLowerCase();
    return string.replace(/\W+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');
  }


  onIndexChange(event) {
    this.tabIndex = event;
  }
  goToNext() {
    this.onIndexChange(this.tabIndex + 1)
  }
  showMoreRecent() {
    this.pageNo = this.pageNo + 1
    this.getRecentJobList(this.pageNo);
  }
  hideMoreRecent() {
    this.tempRecentArray = [];
    this.getRecentJobList(this.pageNo = 1);
  }

  getRecentJobList(pageNo = 1) {
    this.dataLoading = true;
    if (pageNo === 1) {
      this._loader.show();
    }
    this.userService.getAllRecentJobs(this.programId, this.limit, this.pageNo).subscribe(data => {
      this.recentJobs = data.data;
      this.tempRecentArray?.push(...this.recentJobs);
      this.recentJobsTotalCount = data.total_count;
      this._loader.hide();
      this.dataLoading = false;
      this.skeletonLoading = false;
    }, error => {
      this._alert.error(errorHandler(error), {});
      this._loader.hide();
    },
      () => {
        this.dataLoading = false;
        this.skeletonLoading = false;
        this._loader.hide();
      });
  }
  clearTemplate() {
    this.selectedTemplate = {};
    this.selectedTemplateId = {};
  }

  async getFoundantionalType(pageNo = 1) {
    this.dataLoading = true;
    if (pageNo === 1) {
      this._loader.show();
    }
    this.userService.getFoundantionalType(this.programId).subscribe(data => {
      const { foundational_data_types } = data;
      this.foundationTypeList = foundational_data_types.filter(fd => {
        return fd?.configuration && fd?.configuration?.module_jobs !== 'OFF';
      }).map(fd => {
        fd.code = this.snakeCase(fd.name);
        return fd;
      });

      const foundationalDataHttp = this.foundationTypeList.map(dataType => {
        return this.userService.get(`/configurator/programs/${this.programId}/foundational-data-types/${dataType?.id}/foundational-data`);
      });

      let foundationalValue: any[] = [];
      forkJoin(foundationalDataHttp).subscribe(res => {
        foundationalValue = res.map(foundData => foundData.foundational_data);
        foundationalValue.forEach((val, index) => {
          this.foundationTypeList[index].values = val;
        });
        this.foundationTypeList = [...this.foundationTypeList];
        this._foundationTypelist = this.chunkArray(this.foundationTypeList, 3);
        this.updateFoudationalForm();
      }, err => {
        this._alert.error(errorHandler(err));
      });

      this._loader.hide();
    }, error => {
      this._alert.error(errorHandler(error), {});
      this._loader.hide();
    },
      () => {
        this.dataLoading = false;
        this._loader.hide();
      });
  }
  get foundationlGroupForm() {
    return this.createJobForm.get('foundational_data') as FormGroup;
  }
  chunkArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      const chunk = array.slice(i, i + size);
      if (chunk.length < size) {
        chunk.push({ hidden: true });
      }
      result.push(chunk);
    }
    return result;
  }
  makeFoundationDataType(event, code) {

  }
  showMore() {
    this.pageNo = this.pageNo + 1
    this.getPopularJobList(this.pageNo);
  }
  hideMore() {
    this.tempPopularArray = [];
    this.getPopularJobList(this.pageNo = 1);
  }
  getPopularJobList(pageNo = 1) {
    this.dataLoading = true;
    if (pageNo === 1) {
      this._loader.show();
    }
    this.userService.getAllPopularJobs(this.programId, 11, this.pageNo).subscribe(data => {
      this.popularJobs = data.data;
      if(data.data?.length > 5) {
          data?.data.forEach((element,i ) => {
            if(i>=4) {
              
              this.tempPopularArray?.push(element);
            }
           
          });
      } else {

        this.tempPopularArray?.push(...this.popularJobs); 
      }
      this.popularJobsTotalCount = data.total_count;
      this._loader.hide();
      this.dataLoading = false;
      this.skeletonLoading = false;
    }, error => {
      this._alert.error(errorHandler(error), {});
      this._loader.hide();
    },
      () => {
        this.dataLoading = false;
        this.skeletonLoading = false;
        this._loader.hide();
      });
  }
  searchTemplate(event) {

  }


  get isDisabled() {
    return (this.basicInfo && !this.basicInfoValid) ||
      (this.financialDetail && !this.financialDetailValid) ||
      (this.jobDetails && !this.jobDetailValid) 
  }

  get basicInfoValid() {
    return this.createJobForm.get('hierarchy').valid &&
      this.createJobForm.get('locations').valid &&
      this.createJobForm.get('job_manager').valid 
  }

  get financialDetailValid() {
    return this.createJobForm.get('start_date').valid &&
      this.createJobForm.get('end_date').valid &&
      this.createJobForm.get('min_bill_rate').valid &&
      this.createJobForm.get('max_bill_rate').valid &&
      this.createJobForm.get('num_resources').valid &&
      this.createJobForm.get('currency').valid && 
      this.createJobForm.get('unit_of_measure').valid
  }

  get jobDetailValid() {
    const hasInvalidControls = this.foundationalDataArray.controls.some(control => {
      return control.get('values').invalid
    })
    return !hasInvalidControls;
  }

  // async selectedJobtemplate(event) {
  //   const jobTemplate = this.templateData.filter(template => template.uid === event);
  //   this.selectedTemplateId.name = this.selectedTemplate.title?.title;
  //   this.selectedTemplateId.id = jobTemplate[0]?.uid;
  //   let tempDetail = await this.jobService.get(`/job-manager/${this.programId}/job_template/${event}`).toPromise();
  //   tempDetail = tempDetail.data[0];

  //   if (jobTemplate && jobTemplate.length > 0) {
  //     this.selectedTemplate = jobTemplate[0];
  //     this.selectedTemplate.qualifications = tempDetail.qualifications;
  //     this.setQualifications();
  //     this.selectedTemplateId.name = this.selectedTemplate.title?.title;
  //     this.selectedTemplateId.id = jobTemplate[0]?.uid;
  //     this.isShow = false;
  //     this.selectedTemplate = { ...this.selectedTemplate };


  //     this.createJobForm.patchValue({ description: this.selectedTemplate.description });
  //   }
  // }

  async selectedJobtemplate(event) {
    if (!event?.template_name) {
      event.template_name = event?.title?.title;
    }
    this.clearJobForm()
    this.selectedTemplate = event;
    this.suggestionPaneVisible = false;
    this.templateData = [];
    this.loadTemplateDetails();
    this.createJobForm.patchValue({ description: this.selectedTemplate.description });
    // if( this.createJobObject.locations &&  this.createJobObject.locations.length >0) {
    //   // this.createJobForm.patchValue({locations: this.createJobObject.locations[0]})
    // }
    if (this.managerList.length > 0) {
      this.createJobForm.patchValue({
        job_manager: this.managerList[0]
      });
    }
 
  }

  clearJobForm() {
    this.createJobForm.reset();
    this.createJobForm.patchValue({ num_resources: 1 });
    this.createJobForm.patchValue({ hours_per_day: 8 });
    this.createJobForm.patchValue({ additional_amount: 0 });
    this.createJobForm.patchValue({ allow_expense: 'Yes' });
    this.createJobForm.patchValue({week_working_days: 5});
    this.preicdntified = false;

    const formArray = this.createJobForm.get('foundational_data') as FormArray;
    while (formArray && formArray?.length !== 0) {
      formArray?.removeAt(0);
    }
    // this.createJobForm.patchValue({foundational_data: new FormArray([])})
    // this.createJobForm.patchValue({qualifications: new FormArray([])})
    this.candidates = new Array();
    this.updateFoudationalForm();
  
  }


  async loadTemplateDetails() {
    this.loadingTemplateDetail = true;
    this.jobService.get(`/job-manager/${this.programId}/job_template/${this.selectedTemplate.uid}`)
      .subscribe(tempDetail => {
        tempDetail = tempDetail.data[0];
        this.selectedTemplate.qualifications = tempDetail?.qualifications;
        this.setQualifications();
        this.changeDefaultHierarchy();
        this.selectedTemplate = { ...this.selectedTemplate };

        this.loadingTemplateDetail = false;
      }, err => {
        this.loadingTemplateDetail = false;
      })
  }

  setQualifications() {
    if (this.qualification_types?.length > 0 && this.selectedTemplate.qualifications?.length > 0) {
      this.qualification_types.forEach(qualification => {
        this.selectedTemplate.qualifications.forEach(templateQualification => {
          if (qualification?.name?.toLowerCase() === templateQualification?.qualification_type?.toLowerCase()) {
            qualification.values = templateQualification.values;
            qualification.selected = true;
          }
        });
      });
      this.updateQualification();
    }
  }

  changePage(event) {
    if (event && event.type === 'changeTemplate') {
      this.tabIndex = 0;
    } else if (event && event.type === 'saveBasic') {
      this.tabIndex = event.value;
    } else if (event && event.type === 'saveQualification') {
      this.tabIndex = event.value;
    } else if (event.type === 'changeHierarchy') {
      this.sidebarOpen();
    } else if (event.type === 'saveJobDetails') {
      this.tabIndex = event.value;
    } else if (event.type === 'jobDetails') {
      this.tabIndex = event.value;
    } else if (event.type === 'saveJob') {
      this.saveJob();
    } else if (event.type === 'jobInfo') {
      this.tabIndex = event.value;
    } else if (event.type === 'jobDistribution') {
      this.tabIndex = event.value;
    }
  }


  getJobTemplate() {
    this.dataLoading = true;
    this.templateData = [];
    const url = `/job-manager/${this.programId}/job_template?limit=10${this.searchTerm ? ('&template_name=' + this.searchTerm) : ''}`;
    this.jobService.get(url).subscribe(data => {
      this.dataLoading = false;
      if (data && data.data && data.data.length > 0) {
        this.templateData = (data.data);
        this.totalRecords = data.total_records;
        this.itemsPerPage = data.items_per_page;
        this.tableLoaded = true;
      } else {
        this.templateData = [];
      }
    }, error => {
      this._alert.error(errorHandler(error), {});
      this._loader.hide();
      this.dataLoading = false;
      this.templateData = [];
    });
  }
  sidebarClose() {
    this.seletHierarchy = 'hidden';
  }

  sidebarOpen() {
    this.seletHierarchy = 'visible';
  }
  changeDefaultHierarchy() {
    this.selectedHierarchyLevel = this.basicJobInfo.selectedHierarchy;
    if(this.basicJobInfo.selectedHierarchy) {
      this.createJobForm.patchValue({hierarchy: this.basicJobInfo.selectedHierarchy.name});
      // if(this.selectedHierarchyLevel && this.selectedHierarchyLevel?.managers && this.selectedHierarchyLevel?.managers?.length > 0)
      //   {
      //     this.createJobForm.patchValue({job_manager: this.selectedHierarchyLevel?.managers[0]});
      //   }
      this._changeDetectorRef.detectChanges();
      this.sidebarClose(); 
    } else {
      this.sidebarClose();
    }

  }
  selectHierarchy(event) {
    this.changeHierarchy(event, this.hierarchyData);
  }

  // get hierarchy available in this program
  hierarchyList() {
    // let logUser;
    const logUser = this.localStorage.get('user');
    //  let url =  '/configurator/programs/' + this.programId + '/hierarchy'
    let url = '/configurator/programs/' + this.programId + '/hierarchy?user_id=' + logUser?.id
    this.jobService.get(url).subscribe(data => {
      // this.jobService.get(`/configurator/programs/${this.programId}/hierarchy`).subscribe(data => {
      if (data && data?.result[0]?.hierarchies && data?.result[0]?.hierarchies.length> 0) {
        this.hierarchyData = data?.result[0]?.hierarchies;
        this.defaultHierarchy = [data.result[0].hierarchies[0].id];
        this.basicJobInfo.selectedHierarchy = data.result[0].hierarchies[0];
        this.userAssociateHierarchy = [data.result[0].hierarchies[0].id];
        this.selectedHierarchyLevel = data.result[0].hierarchies[0];
        this.createJobObject.selectHierarchyData = new Array();
        this.createJobObject.hierarchyData = data?.result[0]?.hierarchies;
        // this.createJobForm.patchValue({hierarchy: data.result[0].hierarchies[0].name});
  
        this.getQualificationTypes();

      }
    });
    this._changeDetectorRef.detectChanges();
  }
  openhierarchy() {
    this.createJobObject.showHierarchy= true;
  }

  changeHierarchy(id, hierarchyData) {
    let isPresent = false;
    const selectedHierarchy = hierarchyData?.filter(data => {
      if (data.id === id[0]) {
        isPresent = true;
        this.basicJobInfo.selectedHierarchy = data;
        return data;
      } else {
        if (data.hierarchies && data.hierarchies.length > 0) {
          data.hierarchies.forEach(element => {
            if (element.id === id[0]) {
              this.basicJobInfo.selectedHierarchy = element;
              return element;
              // }
            } else {
              // this.createJobObject.hierarchyData.push({ name: element?.name, id: element?.id })
              this.changeHierarchy(id, data.hierarchies);

            }
          });
        }
      }
    });

  }
  makeJobObject(payLoad) {
    if (!this.createJobPayLoad) {
      this.createJobPayLoad = {};
    }
    if (!this.createJobPayLoad[payLoad.type]) {
      this.createJobPayLoad[payLoad.type] = {};
    }
    if (payLoad.type === 'jobInfo') {
      if (payLoad.data.min_bill_rate) {
        this.createJobPayLoad.basicInfo.min_bill_rate = payLoad.data.min_bill_rate;
      }
      if (payLoad.data.max_bill_rate) {
        this.createJobPayLoad.basicInfo.max_bill_rate = payLoad.data.max_bill_rate;
      }
    }


    this.createJobPayLoad[payLoad.type] = payLoad.data;

  }
  getRate(rateData) {
    this.rateCard = rateData;
    this._changeDetectorRef.detectChanges();
  }

  saveJob() {
    let approval_list = [];
    // const Qualifications = [];
    this.createJobForm?.value?.qualifications?.forEach(qualification => {
      qualification?.values?.forEach(value => {
        value.is_active = value.is_active || false;
        if (qualification.name === 'Skill' || qualification.name === 'Speciality') {
          value.level = value.level || 1;
        } else {
          value.level = '';
        }
      });
    });
    if (this.approvalList && this.approvalList.length > 0) {
      approval_list = this.approvalList.map(approval => {
        return {
          id: approval.id,
          status: approval?.value ? 'enabled' : 'disabled'
        }
      })
    }
    const jobDetails = this.createJobForm.value;
    let PayLoad: any = {
      title: this.selectedTemplate?.title?.uid,
      category: this.selectedTemplate?.category?.uid,
      program_id: this.programId,
      job_manager: jobDetails?.job_manager?.id,
      // config_id: 1,
      msp_manager: this.selectedTemplate.msp_manager,
      description: jobDetails?.description,
      start_date: this.getDate(jobDetails?.start_date),
      end_date: this.getDate(jobDetails?.end_date),
      min_bill_rate: jobDetails.min_bill_rate,
      max_bill_rate: jobDetails.max_bill_rate,
      hierarchy:  this.basicJobInfo.selectedHierarchy.id,
      // tags: this.selectedTemplate?.tags || ['1'] ,
      response_by_date: this.createJobPayLoad?.jobInfo?.response_by_date ?
        this.getFormattedDate(new Date(this.createJobPayLoad?.jobInfo?.response_by_date)) : this.getFormattedDate(new Date()),
      no_of_openings: jobDetails.num_resources,
      currency: jobDetails?.currency,
      level: this?.selectedTemplate?.level,
      submit_type: 'Submit',
      status: 'pending_approval',
      // vendor_rate_exceed: this.createJobPayLoad.basicInfo?.vendor_rate_exceed,
      foundational: this.selectedTemplate?.foundational?.id,
      candidates: [],
      // location_id: '1',
      location_id: jobDetails?.locations,
      is_template: false,
      allow_expense: jobDetails?.allow_expense == 'Yes' ? true : false,
      note_for_approver: jobDetails?.note_for_approver,
      unit_of_measure:  jobDetails?.unit_of_measure,
      template_name: this.selectedTemplate?.template_name,
      template:this.selectedTemplate?.uid,
      budget_estimate: jobDetails?.net_budget || '0.00'
    };

    if (this.candidates && this.candidates.length > 0) {
      let c = this.candidates.map(data => {
        return {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number ? data.phone_number : null,
          vendor_id: data?.vendorName?.id,
        }
      })
      if (c && c.length > 0) {
        PayLoad.candidates = c;
      }
    }
    if(this.selectedTemplate?.tags && this.selectedTemplate?.tags?.length > 0 && this.selectedTemplate?.tags[0]?.id) {
      PayLoad.tags= [this.selectedTemplate?.tags[0]?.id] || []
    } else {
      PayLoad.tags = [] ;
    }
 

    if (approval_list && approval_list.length > 0) {
      PayLoad.approverlist = approval_list;
    }

    const formValue = this.createJobForm.value;
    const { foundational_data } = formValue;
    PayLoad.foundational_data = foundational_data.map(data => {
      let { foundational_data_type_id, values } = data;
      values = values?.id ? [{ id: values.id }] : [];
    
      return {
        foundational_data_type_id,
        values
      }
    });
    const { qualifications } = formValue;
    PayLoad.qualifications = qualifications.map(data => {
      let { qualification_type_id, values , qualification_type} = data;
      values = values?.map(v=> {
        return {
          id: v?.id,
          level:v?.level || '',
          is_active: v?.is_active
        }
      });
      return {
        qualification_type,
        qualification_type_id,
        values
      }
    }) || [];
    const url = `/job-manager/${this.programId}/job`;
    this.jobService.post(url, PayLoad).subscribe(data => {
      this.jobDetails = data.job;
      this.viewJob();
      if (data && data.data && data.data.length > 0) {
        this.tableLoaded = true;
      }
    }, error => {
      this._alert.error(errorHandler(error), {});
      this._loader.hide();
      this.dataLoading = false;
    });
  }

  viewJob() {
    this.router.navigate([`jobs/details/job-details/${this.jobDetails?.id}`]);
    this.tabIndex = 0;
    this.createJobPayLoad = {};
    this.selectedTemplate = {};
    this.jobDetails = {};
    this.selectedTemplateId = {};
    this.createJobObject = {};
    this.createJobForm.reset();
    this.basicInfo = true;
    this.candidates = [];
    this.maxRate = '';
  }
  createJob() {
    this.tabIndex = 0;
    this.createJobPayLoad = {};
    this.selectedTemplate = {};
    this.jobDetails = {};
    this.selectedTemplateId = {};
    this.createJobObject = {};
    this.createJobForm.reset();
    this.basicInfo = true;
    this.candidates = [];
    this.goToBasic();
    this.createJobForm.patchValue({
      'allow_expense': 'Yes'
    });
    this.hierarchyList();
    this.getWorkLocations();
    this.init();
    this.maxRate = '';
  }

  getFormattedDate = function (date) {
    if (date) {
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = date.getFullYear();
      return yyyy + '-' + mm + '-' + dd;
    }
  }
  
  getDateFormat = function (date) {
    if (date) {
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = date.getFullYear();
      return dd + '-' + mm + '-' + yyyy;
      // return yyyy + '-' + mm + '-' + dd;
    }
  }

  showjobDtls() {
    this.show = !this.show;
  }
checkCandidate(){
  if (this.preicdntified) {
    if (this.candidates && this.candidates?.length <= 0) { 
      return  true;
    }
  } else{
    return  false;
  }
}
  clickToContinue() {
    if (this.isDisabled) {
      return;
    } 

    if (this.btnText === 'Submit') {
      this.saveJob();
    } else if (this.basicInfo == true) {
      
      this.basicInfo = false;
      this.financialDetail = true;
    }
    else if (this.financialDetail == true) {
      const jobDetails = this.createJobForm.value;
      let currentDate: any = this.getFormattedDate(new Date());
      // if (jobDetails.start_date && Date.parse(jobDetails.start_date) < Date.parse(currentDate)) {
      //   this._alert.error(`Please select start date  greater than or  current date`);
      //   return;
      // }
      let startDate:any = this.getDate(jobDetails.start_date);
      let endDate:any = this.getDate(jobDetails.end_date);
      if (startDate && endDate && Date.parse(startDate) <= Date.parse(endDate)) {
      } else {
        this._alert.error(`Please select end date greater than start data`);
        return;
      }
      let max_bill_rate = parseInt(jobDetails.max_bill_rate);
      let min_bill_rate = parseInt(jobDetails.min_bill_rate);
      if (min_bill_rate > -1 && max_bill_rate > -1 && (min_bill_rate) < (max_bill_rate)) {
        this.basicInfo = false;
        this.financialDetail = false;
        this.jobDetails = true;
        this.btnText = 'Submit';
        this.showSummaries = false;
      } else {
        this._alert.error(`Please select max bill rate greater than min bill rate`);
        return;
      }
    }
  }

  onClickToggle() {
    // this.preicdntified = !this.preicdntified;
    if (this.preicdntified) {
      this.preicdntified = false;
      this.addCandidate = false;
    } else {
      this.preicdntified = true;
      this.addCandidate = true;

    }
  }
  openCreateCandidate() {
    this.createJobObject.editedCandidate = {};
    this.isCreateCandidate = 'visible';
  }
  openCreateEstimate() {
    this.isCreateEstimate = 'visible';
  }

  onCreateClose() {
    this.isCreateCandidate = 'hidden';
    this.isCreateEstimate = 'hidden';
  }

  closePopup(event) {
    this.isCreateCandidate = 'hidden';
    this.isCreateEstimate = 'hidden';
    if (event) {
      this.createJobForm.patchValue(event);
    }
  }

  candidate(e) {
    if (this.createJobObject.editedCandidate && this.createJobObject.editedCandidate.hasOwnProperty('index') && this.createJobObject?.editedCandidate?.index !== -1) {
      this.candidates[this.createJobObject.editedCandidate.index] = e;
      this.createJobObject.editedCandidate = {};
    } else {
      this.candidates.push(e);
    }

  }
  removeCandidate(i) {
    this.candidates.splice(i, 1);
  }
  editCandidate(index) {
    const editedData = this.candidates[index];
    editedData.index = index;
    this.createJobObject.editedCandidate = editedData;
    this.isCreateCandidate = 'visible';
  }
  displayCandidateOption(index) {
    // this.showOption =! this.showOption;
    this.candidates[index].showOption = !this.candidates[index].showOption
  }
  showSummary() {
    if (this.maxRate >= 1) {
      this.showSummaries = true;
    }
    else {
      this.showSummaries = false;
    }
  }

  deleteSkill(skill) {
    this.selectedSkills.splice(skill, 1);
  }

  goToBasic() {
    this.basicInfo = true;
    this.financialDetail = false;
    this.jobDetails = false;
    this.showSummaries = false;
    this.btnText = 'Continue';
  }

  goToFinancialDetail() {
    if (this.basicInfoValid) {
      this.basicInfo = false;
      this.financialDetail = true;
      this.jobDetails = false;
      this.showSummaries = false;
      this.btnText = 'Continue';
    }
  }

  goToJobDetails() {
    if (this.financialDetailValid) {
      this.basicInfo = false;
      this.financialDetail = false;
      this.jobDetails = true;
      this.btnText = 'Submit';
      this.showSummaries = false;
    }
  }
  getWorkLocations() {
    const url = `/configurator/programs/${this.programId}/work-locations`;
    this.jobService.get(url).subscribe(data => {
      if (data && data.work_locations && data.work_locations.length > 0) {
        if (data.work_locations && data.work_locations?.length > 0) {
          data.work_locations.push(data.work_locations[0])
          const uniqueLocations : any  = [...new Map(data.work_locations.map(item => [ item.name, item])).values()]
          const locations = uniqueLocations.filter(l =>l.name);
          this.createJobObject.locations = locations;
        }
      }
    }, error => {
      this._alert.error(errorHandler(error), {});
    });
  }
  getManagerList(term, reset = false) {
    if (reset) {
      this.pageNo = 1;
    }

    return this.userService.get(`/configurator/programs/${this.programId}/members?org_category=CLIENT`)
      .pipe(
        map((res: any) => res.members.map(mem => {
          return {
            id: mem.id,
            first_name: mem.first_name,
            last_name: mem.last_name,
            email: mem.email
          }
        }))
      );
  }

  updateQualification() {
    // if (this.selectedTemplate && this.selectedTemplate.qualifications) {
    if (this.qualification_types) {
      const formArray = this.createJobForm.get('qualifications') as FormArray;
      while (formArray && formArray?.length !== 0) {
        formArray?.removeAt(0);
      }

      // this.selectedTemplate.qualifications.
      this.qualification_types.forEach(element => {
        if (element?.selected === true) {
          const control = this.createJobForm.get('qualifications') as FormArray;
          const group = this.fb.group({
            name: [element.name],
            qualification_type: [element.name],
            qualification_type_id: [element.id],
            values: [element.values],
            data: []
          });
          control.push(group);
          this.searchQualifications(group, { term: '' });
        }
      });
    }
  }

  removeQualification(index) {
    const control = this.createJobForm.get('qualifications') as FormArray;
    const qualification = control.controls[index]?.get('qualification_type')?.value;
    if (qualification) {
      this.qualification_types.forEach((q, index) => {
        if (q?.name?.toLowerCase() === qualification?.toLowerCase()) {
          // qualificationTypes.splice(index, 1);
          this.qualification_types[index].selected = false;
        }
      })
    }
    control.removeAt(index);

    // this.qualification_types[index].selected = false;
  }
  get foundationalDataArray() {
    return (this.createJobForm.get('foundational_data') as FormArray || new FormArray([]));
  }

  updateFoudationalForm() {
    this.foundationTypeList.forEach(element => {
      const isRequired = element?.configuration?.module_jobs === '"REQUIRED"';
      this.foundationalDataArray.push(
        this.fb.group({
          is_required: [isRequired],
          foundational_data_name: [element.name],
          foundational_data_type_id: [element.id],
          values: [null, isRequired ? [Validators.required] : []],
          options: [element.values]
        })
      )
    });

  }

  snakeCaseToTitle(snakeCaseString) {
    if (!snakeCaseString) {
      return '';
    }
  }

  clickOnRatings(c, r) {
    c.level = Number(r);
  }
  showTooltip(i) {
    if (i == 0) {
      this.tooltipTitle = 'Beginner';
    } else if (i == 1) {
      this.tooltipTitle = 'Intermediate';
    } else if (i == 2) {
      this.tooltipTitle = 'Expert';
    }
  }

  removeFromValue(i) {
    const formArray = this.createJobForm.get('qualifications') as FormArray;
    // formArray.removeAt(i);
    const formGroup = formArray.controls[i] as FormGroup;
    formGroup.patchValue(formGroup.get('values').value.splice(i, 1));
  }

  searchQualifications(item, value) {
    let qualificationTypeId = item.get('qualification_type_id').value;
    const _url = `/configurator/programs/${this.currentProgram?.id}/qualification-types/${qualificationTypeId}/qualifications?k=` + value?.term;
    this.jobService.get(_url).subscribe(
      data => {
        item.get('data').value = data?.qualifications;
      })
  }

  getCurrencies() {
    const url = '/configurator/resources/currencies';
    this.jobService.get(url).subscribe(data => {
      // this._loader.hide();
      if (data) {
        this.createJobObject.currencies = new Array();
        this.createJobObject.currencies = JSON.parse(JSON.stringify(data.currencies));
        this.createJobObject.currencySymbol = '$'
      }
    }, error => {
      this._alert.error(errorHandler(error), {});
    });
  }
  
  checkMaxMin() {

    const jobDetails = this.createJobForm.value;
    if (jobDetails.max_bill_rate > 1) {
      this.showSummaries = true;
    } else {
      this.showSummaries = false;
    }

    if (jobDetails.min_bill_rate && jobDetails.max_bill_rate  && (jobDetails.min_bill_rate) < (jobDetails.max_bill_rate)) {
      this.getApprovalList();
      this.getResourceBudget();
      this.getRateCard();
    } else {

        this._alert.error(`Please select max bill rate greater than min bill rate`);

      return;
    }
    if (jobDetails.max_bill_rate) {
      this.getResourceBudget();
    }
    if (jobDetails.max_bill_rate && typeof(jobDetails.max_bill_rate === 'number' ) ) {
       const maxBill = jobDetails?.max_bill_rate?.toFixed(2);
       this.createJobForm.patchValue({max_bill_rate: maxBill});
    }
    
    // this.ToFloat();
  }
  ToFloat() {
    const jobDetails = this.createJobForm.value;
  

    if (jobDetails.min_bill_rate && typeof(jobDetails.min_bill_rate === 'number' )) {
       let minBill = jobDetails?.min_bill_rate?.toFixed(2);
        this.createJobForm.patchValue({min_bill_rate: minBill})
    }
 
    
  }

  checkDateMax() {
    const jobDetails = this.createJobForm.value;
    let currentDate: any = this.getFormattedDate(new Date());
    if (jobDetails.start_date && Date.parse(jobDetails.start_date) < Date.parse(currentDate)) {
      this._alert.error(`Please select start date  greater than or  current date`);
      return;
    }
    if (jobDetails.start_date && jobDetails.end_date && Date.parse(jobDetails.start_date) < Date.parse(jobDetails.end_date)) {
      this.getWorkingHoursEstimate();
    } else {
      this._alert.error(`Please select end date greater than start data`);
      return;
    }

  }
  getApprovalList() {
    let logUser = this.localStorage.get('user');

    let payLoad = {
      module: "job",
      data: {
        program_id: this.programId,
        job_manager: this.createJobForm.value.job_manager,

        min_bill_rate: Number(this.createJobForm.get('min_bill_rate')?.value),
        max_bill_rate: Number(this.createJobForm.get('max_bill_rate')?.value),
        created_by: {
          id: logUser?.id,
          first_name: logUser?.first_name,
          last_name: logUser?.last_name,
          email: logUser?.email
        }
      }
    }
    this.jobService.post('/approval/approvers-list', payLoad).subscribe(data => {
      this.approvalList = data.approvers_list;
      if(this.approvalList && this.approvalList.length > 0) {
      this.approvalList.map(data => {
        data.value = true
      })
    }
    },
      error => {
        this.approvalList = new Array();
      });
  }

  showDropdown() {
    this.dropdownShowHide = true;
  }
  hideDropdown(index) {
    this.candidates[index].showOption = false;
    // this.candidates[index].showOption = !this.candidates[index].showOption
  }

  async getWorkingHoursEstimate() {
    const isValidData = await this.validateInput();
    if (isValidData) {
      const week_working_days = this.createJobForm.get('week_working_days').value;
      const hours_per_day = this.createJobForm.get('hours_per_day').value;
      let start_date: any =this.getDate( this.createJobForm.get('start_date').value);
      let end_date: any =  this.getDate(this.createJobForm.get('end_date').value);
      if (start_date && end_date) {
        start_date = this.getFormattedDate(new Date(start_date));
        end_date = this.getFormattedDate(new Date(end_date));
        if (start_date && end_date) {
          this.jobService.get(`/rate-estimate/programs/${this.programId}/working-hours-estimate?week_working_days=${week_working_days}&hours_per_day=${hours_per_day}&start_date=${start_date}&end_date=${end_date}`).subscribe(data => {
            if (data && data.data) {
              this.createJobForm.patchValue(data.data);
              this.getResourceBudget();
    
            }
          });
        }
      }

    }
  }

  validateInput() {
    let request = this.createJobForm.value;
    if (request.week_working_days < 0 || request.week_working_days > 7) {
      this.showValidationError('Please provide valid working days');
      return false;
    } else if (request.hours_per_day < 0 || request.hours_per_day > 24) {
      this.showValidationError('Please provide valid hours');
      return false;
    } else if (request.adjustment_value < 0) {
      this.showValidationError('Please provide valid adjustment value');
      return false;
    } else if (request.additional_amount < 0) {
      this.showValidationError('Please provide valid additional amount');
      return false;
    } else {
      return true;
    }
  }

  showValidationError(message) {
    this._alert.error(message);
  }

  async getResourceBudget() {

    const isValidData = await this.validateInput();
    if (isValidData) {
      let request =  JSON.parse(JSON.stringify(this.createJobForm.value));
      delete request['foundational'];
      delete request['rate_dtls'];
      delete request['location'];
      delete request['pre_identified_candidate'];
      delete request['vendor_rate_exceed'];
      delete request['locations'];
      delete request['job_manager'];
      delete request['hierarchy'];
      delete request['description'];
      delete request['allow_expense'];
      delete request['note_for_approver'];
      delete request['selectedQualification'];
      delete request['qualifications'];
      delete request['foundational_data'];
      request.total_hours = request.working_hours;
      request.rate = this.createJobForm.get('max_bill_rate').value;

      /* var start_date: any = new Date();
      request.start_date = this.getFormattedDate(start_date);
      var end_date: any = new Date();
      end_date.setDate( end_date.getDate() + 10);*/
      let start_date: any = this.getDate(this.createJobForm.get('start_date').value);
      let end_date: any = this.getDate(this.createJobForm.get('end_date').value);
      if (!request.rate || !start_date || !end_date) {
        return;
      }
      let req:any = {} ;
      if(request.max_bill_rate) {
        req.rate = request.max_bill_rate;
      }
      if(request.total_hours) {
        req.total_hours = request.total_hours;
      }
      if(request.hours_per_day) {
        req.hours_per_day = request.hours_per_day;
      }
      if(request.week_working_days) {
        req.week_working_days = request.week_working_days;
      }
      if(request.num_resources) {
        req.num_resources = request.num_resources;
      }
      req.adjustment_type = 'percentage';
      req.rate_type = 'per_month';
      if(request.adjustment_value) {
        req.adjustment_value = request.adjustment_value
      }
  
      req.start_date = this.getFormattedDate(new Date(start_date));
      req.end_date = this.getFormattedDate(new Date(end_date));
      request.rate_type ='per_hour'
      const requestParam = this.jsonToQueryString(req);
      this.jobService.get(`/rate-estimate/programs/${this.programId}/resource-budget${requestParam}`).subscribe(data => {
        if (data && data.data) {
          this.createJobForm.patchValue(data.data);
        }
      });
    }
  }

  jsonToQueryString(json) {
    return '?' +
      Object.keys(json).map(function (key) {
        return encodeURIComponent(key) + '=' +
          encodeURIComponent(json[key]);
      }).join('&');
  }

  getQualificationTypes(pageNo = 1) {
    let hierarchiesList = '';
    if (this.hierarchyData) {
      this.hierarchyData.forEach(hierarchy => {
        hierarchiesList += hierarchy?.id + ',';
      });
    }
    const url = `/configurator/programs/${this.programId}/qualification-types?limit=25&k=true&page=${pageNo}${hierarchiesList ? ('&hierarchy_ids=' + hierarchiesList) : ''}`;
    this.jobService.get(url).subscribe(
      data => {
        this._loader.hide();
        if (data) {
          this.qualification_types = data.qualification_types;
          this.setQualifications();

        } else {
          // this._loader.hide();
        }
      },
      (err) => {
        // this._loader.hide();
        this._alert.error(errorHandler(err));
      });
  }
  changeLocation(event) {
      this.createJobObject.selectedLocation = this.createJobObject.locations.filter(l=>l.id === event);
  }

  getRateCard() {
    let url = `/job-manager/${this.programId}/rate_cards${this.selectedTemplate?.title?.id ? ('?job_title__id=' + this.selectedTemplate?.title?.id) : ''}${this.selectedTemplate?.category?.id ? ('&job_category__id=' + this.selectedTemplate?.category?.id) : ''}`;
    if (this.createJobForm.get('currency').value) {
      url = `${url}&currency=${this.createJobForm.get('currency').value}`;
    }
    if (this.createJobObject.selectedLocation && this.createJobObject.selectedLocation?.length > 0) {
      url = `${url}&work_location=${this.createJobObject.selectedLocation[0].name}`;
    }
    if(this.createJobForm.get('currency').value) {
      let currency = this.createJobForm.get('currency').value;
      let currencySymbol = this.createJobObject?.currencies.filter(c=> c.name === currency);

      if(currencySymbol && currencySymbol.length> 0) {
        this.createJobObject.currencySymbol = currencySymbol[0].symbol || '$' 
      }
    }

    this.jobService.get(url).subscribe(data => {
      if (data) {
        const rateCardData = JSON.parse(JSON.stringify(data.results));
        let jobdtls = this.createJobForm.value;
        if (rateCardData && rateCardData.length > 0) {
          this.createJobObject.rateCart = rateCardData[0];
          this.rateCardDetails();
          this.createJobForm.patchValue({
            rate_dtls: rateCardData[0]
          });
          if (!jobdtls.min_bill_rate) {
            if (rateCardData[0]?.min_rate) {
              this.createJobForm.patchValue({
                min_bill_rate: rateCardData[0]?.min_rate
              });
            } else {
              this.createJobForm.patchValue({
                min_bill_rate: 0.00
              });
            }
          }
          if (!jobdtls.max_bill_rate) {
            if (rateCardData[0]?.max_rate) {
              this.createJobForm.patchValue({
                max_bill_rate: rateCardData[0]?.max_rate,
              });
              this.showSummaries = true;
            } else {
              this.createJobForm.patchValue({
                max_bill_rate: 0.00
              });
            }
          }
        } else {
          if (!jobdtls.max_bill_rate) {
          this.createJobForm.patchValue({
            max_bill_rate: 0.00,
          });
        }
        if (!jobdtls.min_bill_rate) {
          this.createJobForm.patchValue({
            min_bill_rate: 0.00,

          });
        }

          this.createJobObject.rateCart = {};
        }
      }
    }, error => {
      this._alert.error(errorHandler(error), {});
    });
  }

  rateCardDetails() {
    let url = `/job-manager/talentneuron${this.createJobObject.rateCart?.id ? ('?ratecard_id=' + this.createJobObject.rateCart?.id) : ''}`

    this.jobService.get(url).subscribe(data => {
      // this.rateCard = data.results[0];
      this.createJobObject.rateCartDetails = data.results[0];
    })
  }
  selectedQualification(event) {

  }
  getValuesForQualification(event, name, pageNo = 1) {
    let id = event;
    const url = `/configurator/programs/${this.programId}/qualification-types/${id}/qualifications?limit=25&page=${pageNo}`;
    this.jobService.get(url).subscribe(
      data => {
        this._loader.hide();
        if (data) {
          // this.credentialsData = data.qualifications;
          if (this.createJobObject[name]) {
            this.createJobObject[name] = [];
            this.createJobObject[name] = data[name];

          }

        }
      },
      (err) => {
        this._loader.hide();
        this._alert.error(errorHandler(err));
      });
  }

  openDropdown() {
    this.showQualification = !this.showQualification;
  }
  onClickedOutside() {
    this.showQualification = false;
  }

  closeDropdown(qualification, index) {
    if (!qualification.selected) {
      const control = this.createJobForm.get('qualifications') as FormArray;
      const group = this.fb.group({
        name: [qualification.name],
        qualification_type: [qualification.name],
        qualification_type_id: [qualification.id],
        values: [qualification.values],
        data: []
      });
      control.push(group);
      this.searchQualifications(group, { term: '' });
      // qualification.selected= true;
      this.qualification_types[index].selected = true;
    }
    this.showQualification = false;
  }

  removeQualificationValues(item, index) {
    item.get('values')?.value?.splice(index, 1);
    item.get('values').patchValue(item.get('values')?.value);
  }

}





