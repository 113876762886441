import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appResponsiveMenu]'
})
export class ResponsiveMenuDirective {
  navContainer;
  constructor(private el: ElementRef) {


  }

  ngOnInit() {
    this.navContainer = this.el.nativeElement;
    const primary = this.navContainer.querySelector('.primary');
    const primaryItems = this.navContainer.querySelectorAll('.primary > li:not(.more)');

    // insert "more" button and duplicate the list

    primary.insertAdjacentHTML('beforeend', `
      <li class="more">
      <a href="javascrpt:void(0)">More ...</a>
        <ul class="-secondary">
          ${primary.innerHTML}
        </ul>
      </li>
      `)
    const secondary = this.navContainer.querySelector('.-secondary')
    const secondaryItems = secondary.querySelectorAll('li')
    const allItems = this.navContainer.querySelectorAll('li')
    const moreLi = primary.querySelector('.more')
    const moreBtn = moreLi.querySelector('a')

    // adapt tabs

    const doAdapt = () => {
      // reveal all items for the calculation
      allItems.forEach((item) => {
        item.classList.remove('hidden')
      })

      // hide items that won't fit in the Primary
      let stopWidth = moreBtn.offsetWidth
      let hiddenItems = []
      const primaryWidth = primary.offsetWidth;
      primaryItems.forEach((item, i) => {
        if (primaryWidth >= stopWidth + item.offsetWidth) {
          stopWidth += item.offsetWidth
        } else {
          item.classList.add('hidden')
          hiddenItems.push(i)
        }
      })

      // toggle the visibility of More button and items in Secondary
      if (!hiddenItems.length) {
        moreLi.classList.add('hidden')
        moreBtn.setAttribute('aria-expanded', false)
      }
      else {
        secondaryItems.forEach((item, i) => {
          if (!hiddenItems.includes(i)) {
            item.classList.add('hidden')
          }
        })
      }
    }

    doAdapt() // adapt immediately on load
    window.addEventListener('resize', doAdapt) // adapt on window resize
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    if(event.target.offsetParent) {
      let elemID = event.target.offsetParent.id;

      if (elemID && elemID == "toggleSection") {
        setTimeout(() => {
          const allItems = this.navContainer.querySelectorAll('li');
          const primary = this.navContainer.querySelector('.primary');
          const primaryItems = this.navContainer.querySelectorAll('.primary > li:not(.more)');
          const moreLi = primary.querySelector('.more');
          const moreBtn = moreLi.querySelector('a');
          const secondary = this.navContainer.querySelector('.-secondary');
          const secondaryItems = secondary.querySelectorAll('li');
          allItems.forEach((item) => {
            item.classList.remove('hidden')
          })
  
          // hide items that won't fit in the Primary
          let stopWidth = moreBtn.offsetWidth
          let hiddenItems = []
          const primaryWidth = primary.offsetWidth;
          primaryItems.forEach((item, i) => {
            if (primaryWidth >= stopWidth + item.offsetWidth) {
              stopWidth += item.offsetWidth
            } else {
              item.classList.add('hidden')
              hiddenItems.push(i)
            }
          })
  
          // toggle the visibility of More button and items in Secondary
          if (!hiddenItems.length) {
            moreLi.classList.add('hidden')
            moreBtn.setAttribute('aria-expanded', false)
          }
          else {
            secondaryItems.forEach((item, i) => {
              if (!hiddenItems.includes(i)) {
                item.classList.add('hidden')
              }
            })
          }
        }, 600);
  
      }
      else { }
    }
  }
}
