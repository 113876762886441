import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simplify-assist',
  templateUrl: './simplify-assist.component.html',
  styleUrls: ['./simplify-assist.component.scss']
})
export class SimplifyAssistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
