import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'svms-sidebar-extended',
  templateUrl: './svms-sidebar-extended.component.html',
  styleUrls: ['./svms-sidebar-extended.component.scss']
})
export class SvmsSidebarExtendedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
