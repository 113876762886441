<div class="filter-panel position-fixed bg-light">
  <h4 class="pt-16 pl-24 pr-24 font-weight-normal">
    <table-icon size="19px" name="filter_alt" type="material" cuClass="d-flex" class="float-left mr-4"></table-icon>
    Filters
  </h4>
  <form [formGroup]="advanceFilterForm" (submit)="onSearch()">
    <div class="filter-content p-24 pt-8">

      <div class="form-group mb-8 pb-0 position-relative" *ngFor="let item of filter">
        <div class="mb-8">
          <label *ngIf="item?.access">{{item?.title}}</label>
          <!-- <span class="total-count position-absolute" *ngIf="advanceFilterForm.get(item.name) > 0">{{client.length}}</span> -->
          <input *ngIf="item.filterType === 'TEXT'" type="text" [name]="item.name" [formControlName]="item.name"
            class="form-control mb-8" autocomplete="off"
            placeholder="{{item?.placeholder ? item?.placeholder : item?.title}}">
        </div>
        <!-- For Multi Select DropDown -->
        <div class="mb-8">
          <ng-select *ngIf="item.filterType === 'MULTISELECT' && !item.eventEmiiter && item?.access" [clearable]="false"
             placeholder="{{item?.placeholder ? item?.placeholder : item?.title}}"
            [formControlName]="item?.name" [hideSelected]="true" ngDefaultControl>
            <ng-option [value]="data?.value" *ngFor="let data of item?.multiSelectData">{{data?.name}}</ng-option>
          </ng-select>
          <ng-select [typeahead]="item.eventEmiiter" *ngIf="item.filterType === 'MULTISELECT' && item.eventEmiiter && item.changeHandler"
            [clearable]="false"  placeholder="{{item?.placeholder ? item?.placeholder : item?.title}}"
            [formControlName]="item?.name" [hideSelected]="true" ngDefaultControl>
            <ng-option [value]="data?.value" *ngFor="let data of item?.multiSelectData">{{data?.name}}</ng-option>
          </ng-select>
        </div>

        <!-- For Select DropDown -->
        <div class="mb-8">
          <ng-select *ngIf="item.filterType === 'SELECT' && item?.access" [clearable]="false" [items]="item?.multiSelectData"
            bindLabel="name" bindValue="value" placeholder="{{item?.placeholder ? item?.placeholder : item?.title}}"
            [formControlName]="item?.name">
          </ng-select>
        </div>

        <!-- For DateRange Picker-->
        <div class="mb-8">
          <div *ngIf="item.filterType === 'DATERANGE'" class="form-group position-relative">
            <svms-datepicker [inputdateFormat]="" [formControlName]="item?.name" [options]="createdDate"
              [placeholder]="'choose Date Range'" id="startDate" I18n #refdate1>
            </svms-datepicker>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-footer pt-16 pb-16 pl-24 pr-24">
      <button class="btn btn-outine float-left" type="reset" (click)="ClearFilter()">
        <table-icon size="20px" name="highlight_off" type="material" cuClass="d-flex" class="icon--on-left d-flex">
        </table-icon> Clear
        all Filter
      </button>
      <button class="btn btn-primary float-right" type="submit">
        <table-icon size="20px" name="check_circle" type="material" cuClass="d-flex" class="icon--on-left d-flex">
        </table-icon> Apply
      </button>
    </div>
  </form>
</div>
