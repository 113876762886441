<div class="content-area ui-content-wrapper bg-light" [class.no-content]="isInsight">
  <div class="first-program">
    <img src="../../../assets/images/mask-group-1.svg" alt="landing-image" class="img-fluid">
    <h2 class="mb-8">Welcome to your new program</h2>
    <div class="page-title mb-24">{{programObj?.name}} ({{programObj?.unique_id}})</div>
    <a class="btn btn-secondary large mt-4 pr-16" href="">Lets Start the Configuration<icon size="20px"
        name="keyboard_backspace" type="material" class="icon--on-right icon-reflect" cuClass="cu-icon"></icon></a>
  </div>
</div>

<!--Alert info box -->
<div class="alert alert-info d-flex align-items-center justify-content-between" *ngIf="dataVisibility">
  <icon name="error" size="21px" type="material" class="d-flex mr-8"></icon>
  <p>Now you are in program mode, In <a>program mode</a> tab you can’t access any other sections of @simplify
    admin until you
    <a href="" (click)="exitProgramSetup()" class="cursor-pointer">exit program mode</a></p>
  <span class=cursor-pointer (click)="alertInfoClose()">
    <icon name="clear" size="21px" type="material" class="ml-8 d-flex" cuClass="cu-icon">
    </icon>
  </span>
</div>
<!--Alert info box -->
