<div class="password-strength-wrapper">
  <div class="password-strength mb-16">
    <div class="pt-16 password-strength {{passwordstrength}}">
      <ul class="position-relative">
        <li [style.background-color]="bar0"></li>
        <li [style.background-color]="bar1"></li>
        <li [style.background-color]="bar2"></li>
        <li [style.background-color]="bar3"></li>
      </ul>
      <div class="clearfix"></div>
    </div>
  </div>
  <div class="password-tips">
    <ul>
      <li [ngClass]="{'disabletext':!passwordstrength1,'active':passwordstrength1}"><span class="font-weight-bold"
          i18n>Is longer than 7
          characters</span> </li>
      <li [ngClass]="{'disabletext':!passwordstrength2,'active':passwordstrength2}"><span i18n>Must contain
          upper-case and lower-case letters</span></li>
      <li [ngClass]="{'disabletext':!passwordstrength3,'active':passwordstrength3}"><span i18n>Must contain numbers
          and symbols</span></li>
      <li [ngClass]="{'disabletext':!passwordstrength4,'active':passwordstrength4}"><span i18n> Must not contain the
          word
          password </span></li>
    </ul>
  </div>
</div>
