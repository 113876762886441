<div id="sidebar" class="sidebar float-left position-fixed">
  <a href="javascript:void(0)" (click)="toggleSidebar()" class="switch-menu"></a>
  <div class="sidebar-inner mb-64 position-fixed">
    <ul class="main-nav m-0 mt-12 p-0 overflow-auto">
      <li *ngFor="let sideBar of sideBarData" class="mt-4 mb-4 ml-0 mr-0">
        <!--For no submenu-->
        <a tooltip="{{sideBar?.title}}" [visibility]="tooltipvisible" placement="right" delay="500"
          *authorize="sideBar?.permission" [hidden]="sideBar?.isSideMenu" [routerLink]="sideBar?.path"
          class="pl-24 position-relative" [routerLinkActive]="['active']"
          (click)="toActiveSideBar(sideBar, sideBar?.name)" [ngClass]="{'active-perent-menu':
          activeSideBar === sideBar?.name}">
          <icon [cuClass]="sideBar.class" theme="outlined" [name]="sideBar.icon" size="20px" type="material"
            class="float-left mr-16"></icon>
          <label I18n class="m-0">{{sideBar?.title}}</label>
        </a>
        <!--For submenu-->
        <a tooltip="{{sideBar?.title}}" [visibility]="tooltipvisible" placement="right" delay="500"
          *authorize="sideBar?.permission" [hidden]="!sideBar?.isSideMenu" class="nav-item pl-24 position-relative"
          (click)="openSideMenu(sideBar?.name, true); toActiveSideBar(sideBar, sideBar?.name)" [ngClass]="{'active': isToggled
          === sideBar?.name,
          'active-perent-menu': activeSideBar === sideBar?.name}">
          <icon [cuClass]="sideBar.class" theme="outlined" [name]="sideBar.icon" size="20px" type="material"
            class="float-left mr-16"></icon>
          <label I18n class="m-0">{{sideBar?.title}}</label>
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="current-program" *ngIf="currentProgram?.name">
  <a href="javascript:void(0)" (click)="openSideMenu('current-program', true)" class="mini-mode"
    [ngClass]="{'multiple' : currentProgram?.name > 1}">
    {{currentProgram?.name | shortName:'first'}}
  </a>
  <div class="expanded-mode">
    <figure class="float-left mr-12">
      {{currentProgram?.name | shortName:'first'}}
    </figure>
    <div class="full-name float-left">
      <p>
        {{currentProgram?.name}}
      </p>
      <span>{{currentProgram?.unique_id}}</span>
    </div>
    <a href="javascript:void(0)" class="view-list float-right" (click)="openSideMenu('current-program', true)">
      <icon name="keyboard_arrow_right" size="20px" type="material"></icon>
    </a>
    <div class="clearfix"></div>
  </div>
</div>

<div class="user-settings">
  <a href="javascript:void(0)" (click)="openSideMenu('user', true)" [ngClass]="{'active': isToggled === 'user' }">
    <figure class="rounded-circle mb-0 text-center position-relative mb-0 text-center default-color"
      [style.background]="user?.theme_code">
      <!-- <img [src]="user?.avatar" alt="" class="rounded-circle" *ngIf="user?.avatar"> -->
      <span *ngIf="userAvatar" class="text-capitalize"> {{userAvatar |shortName: 'last'}}</span>
      <span *ngIf="!userAvatar" class="text-capitalize"> {{user?.initials}}</span>
      <span *ngIf="!userAvatar && !user?.initials" class="text-capitalize"> {{user?.first_name |shortName: 'last'}}</span>

      <span class="availability rounded-circle online d-none"></span>
    </figure>
    <div class="current-user-info float-right mt-4">
      <span class="name d-block overflow-hidden">{{user?.first_name + ' ' + user?.last_name}}</span>
      <p class="m-0 small overflow-hidden">{{account?.role?.organization_category }}</p>
    </div>
  </a>
</div>

<div class="sidenav position-fixed overflow-auto" [ngClass]="{'sidenav-active': isToggled === sideBar?.name}">
  <div class="nav-title overflow-hidden pt-8 pr-24 pb-8 pl-24">
    <h4 class="float-left m-0 font-weight-medium">{{sideBar?.closeSubMenu?.title}}</h4>
    <a href="javascript:void(0)" class="closebtn float-right overflow-hidden mt-12"
      (click)="openSideMenu(sideBar?.name)">
      <icon [cuClass]="sideBar?.closeSubMenu?.class" [name]="sideBar?.closeSubMenu?.icon" size="18px" type="material">
      </icon>
    </a>
  </div>
  <div class="nav-search" *ngIf="sideBar?.isSearch">
    <input type="text" name="search" placeholder="Search" class="p-8">
  </div>
  <ul class="submenu  mt-0 mr-24 mb-0 ml-24 p-0" *ngFor="let subMenu of sideBar?.sideBarSubMenu">
    <li *ngIf="subMenu?.title" class="seperationlabel mt-16 ml-16">{{subMenu?.title}}</li>

    <li *ngFor="let sortSubMenu of subMenu?.subMenuItem">
      <a *authorize="sortSubMenu?.permission" [routerLink]="sortSubMenu?.path" class="{{sortSubMenu?.linkClass}}"
        [ngClass]="{'active-perent-menu': activeSideBarSub === sortSubMenu?.title}"
        class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative"
        (click)="openSideMenu(sideBar?.name, true); toActiveSideBar(sortSubMenu, sideBar?.name, true)">
        <icon class="float-left mr-16" [cuClass]="sortSubMenu?.class" [name]="sortSubMenu?.icon" size="24px"
          type="material">
        </icon>
        {{sortSubMenu?.title}}
      </a>
    </li>

  </ul>
</div>

<div class="sidenav position-fixed overflow-auto" [ngClass]="{'sidenav-active': isToggled === 'user' }">
  <div class="user-title overflow-hidden mb-16 d-flex flex-column pt-24">
    <div class="d-flex px-24">
      <figure class="text-center rounded-circle mr-16 mb-0 position-relative text-center overflow-hidden default-color"
        [style.background]="user?.theme_code">
        <!-- <img [src]="user?.avatar" alt="" class="rounded-circle" *ngIf="user?.avatar"> -->
        <span *ngIf="userAvatar"> {{userAvatar |shortName: 'last'}}</span>
        <span *ngIf="!userAvatar"> {{user?.initials}}</span>
        <span *ngIf="!userAvatar && !user?.initials"> {{user?.first_name |shortName: 'last'}}</span>

        <span class="availability rounded-circle online d-none"></span>
      </figure>
      <div>
        <h4 class="mb-0 overflow-hidden font-weight-medium text-capitalize" tooltip="{{createFullName(user) | titlecase}}"
          placement="right" delay="500">
          {{createFullName(user) | titlecase}}
          <!-- {{user?.first_name + ' ' + user?.last_name}} -->
        </h4>
        <p class="user-email">
          <icon class="d-inline-block mr-4" name="email" theme="outlined" size="10px" type="material"></icon> <span
            class="d-inline-block overflow-hidden" tooltip="{{user?.email}}" placement="right"
            delay="500">{{user?.email}}</span>
        </p>
      </div>
    </div>
    <div class="d-flex justify-content-around other-user-info mt-8 px-24">
      <p class="small" *ngIf="account?.role?.name">
        <icon class="d-inline-block mr-4" name="verified_user" color="#000000" size="12px" type="material"></icon>
        {{account?.role?.name}}
      </p>
      <p class="small" *ngIf="account?.role?.organization_category">
        {{account?.role?.organization_category | titlecase}}'s Account
      </p>
    </div>
    <a href="javascript:void(0)" class="float-right p-0 closebtn" (click)="openSideMenu('user')">
      <icon cuClass="sidebar-icon" name="close" size="20px" type="material"></icon>
    </a>
  </div>



  <ul class="submenu mt-24 mr-8 mb-0 ml-8 p-0">
    <li>
      <a routerLink="auth/user-account/account-settings" (click)="closeSidebar()"
        class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="build_circle" size="20px" type="material">
        </icon>
        Account Settings
      </a>
    </li>
    <!-- <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="build_circle" size="20px" type="material">
        </icon>
        Account Settings
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="add_alert" size="20px" type="material">
        </icon>
        Notifications Settings
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="security" size="20px" type="material">
        </icon>
        Change Password
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="book" size="20px" type="material"></icon>
        Recent Activities
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="phonelink_setup" size="20px"
          type="material">
        </icon>
        Mobile App Settings
      </a>
    </li> -->

    <li class="seperationlabel mt-24 ml-16">Theme</li>
    <app-theme-switcher class="theme-switcher"></app-theme-switcher>
    <li>
      <a href="javascript:void(0)" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative"
        (click)="logout()">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="login" size="20px" type="material">
        </icon>
        Logout
      </a>
    </li>
    <!-- <li class="seperationlabel mt-16 ml-16">Support</li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="contact_support" size="20px"
          type="material">
        </icon>
        Help
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="keyboard" size="20px" type="material">
        </icon>
        Hot Keys
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="play_circle_filled" size="20px"
          type="material"></icon>
        Video Tutorials
      </a>
    </li> -->
  </ul>
</div>

<div class="sidenav position-fixed overflow-auto" [ngClass]="{'sidenav-active': isToggled === 'current-program' }">
  <div class="nav-title overflow-hidden pt-8 pr-24 pb-8 pl-24">
    <h4 class="float-left m-0 font-weight-medium">Your Programs</h4>
    <a href="javascript:void(0)" class="closebtn float-right overflow-hidden mt-12"
      (click)="openSideMenu('current-program')">
      <icon cuClass="sidebar-icon" name="close" size="18px" type="material">
      </icon>
    </a>
  </div>
  <div class="program-list">
    <app-programs></app-programs>
  </div>
</div>