<div *authorize="'menu_dashboard'">
  <div class="dashboard-bg" *ngIf="!hasNoProgram">
    <app-dashboard-header [assignment]="assignment" (assignmentUUID)="setAssignmentUUID($event)"
      (onClickSetting)="onClickSetting($event)"></app-dashboard-header>
    <!-- <router-outlet></router-outlet> -->
    <div class="page-container  p-24 pt-0">
      <div class="row no-gutter">
        <!-- <div class="col-5">
                <app-calendars [grid]="grid"></app-calendars>
            </div> -->
        <div class="col-12">
          <app-dashboard-counter [workerID]="workers" [assignmentID]="assignmentId" [widgetData]="widgetData">
          </app-dashboard-counter>
        </div>
        <!-- <div class="col-3">
                <app-dashboard-actions></app-dashboard-actions>
            </div> -->
      </div>
    </div>
  </div>
</div>

<div *ngIf="hasNoProgram" class="no-program-bg">
  <div class="has-no-program">
    <img src="../../../assets/images/no-program.svg" alt="landing-image" class="img-fluid" width="220">
    <h2>You are not associated with any programs yet</h2>
  </div>
</div>