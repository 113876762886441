<svms-sidebar [title]="panelHeading" [dataVisibility]="addCertificate" (onClose)="sidebarClose()">
    <svms-sidebar-body>
        <form [formGroup]="addCertificateForm">
            <div class="p-24">
                <div class="form-group">
                <label>Select Certification <span class="require">*</span></label>
                <ng-select [clearable]="false" placeholder="Select" [searchable]="false" formControlName="type">
                    <ng-option [value]="type.id"  *ngFor="let type of certificationTypeList">{{type.name}}</ng-option>
                </ng-select>
                <p class="validation-msg"
                *ngIf="(addCertificateForm.controls.type.touched && addCertificateForm.controls['type'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Certification Type
                is
                Required
                </p>
                </div>
                <div class="form-group">
                <label>Certification Authority <span class="require">*</span></label>
                <input type="text" class="form-control" placeholder="Enter Details" formControlName="authority">
                <p class="validation-msg"
                *ngIf="(addCertificateForm.controls.authority.touched && addCertificateForm.controls['authority'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Certification Authority
                is
                Required
                </p>
                </div>
                <div class="form-group-row">
                <div class="form-group position-relative date-field">
                    <label>Initial Certification Date <span class="require">*</span></label>
                    <svms-datepicker [inputdateFormat]="'mm/dd/yy'" [placeholder]="'Select Date'" ngDefaultControl formControlName="initialDate" I18n #initialDate>
                    </svms-datepicker>
                </div>
                <div class="form-group position-relative date-field">
                    <label>Re-certification Date</label>
                    <svms-datepicker [inputdateFormat]="'mm/dd/yy'" [placeholder]="'Select Date'"  ngDefaultControl formControlName="reCertificationDate" #recertiDate>
                    </svms-datepicker>
                    </div>
                    <div class="form-group position-relative date-field">
                    <label>Certification Expiration Date</label>
                    <svms-datepicker [inputdateFormat]="'mm/dd/yy'" [placeholder]="'Select Date'"  ngDefaultControl formControlName="expiryDate" I18n #expiryDate>
                    </svms-datepicker>
                    </div>
                </div>
                <div class="form-group">
                <label>Certification ID</label>
                <input type="text" class="form-control" placeholder="Enter Certification ID" formControlName="id">
                </div>
                <div class="form-group">
                <label>Notes</label>
                <textarea class="form-control notes" rows="4" formControlName="notes" placeholder="Enter Notes"></textarea>
                </div>
                <app-upload-documents uploadLabel="Upload Certification Documents" (uploadDataFiles)="uploadFiles($event)"  [closePanel] = "closePanel"></app-upload-documents>
            </div>
        </form>
    </svms-sidebar-body>
    <svms-sidebar-footer>
      <button type="submit" class="btn btn-outline float-right" (click)="saveCertification('save')">
          <icon size="20px" name="check_circle" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
          Add
        </button>
      <button type="submit" class="btn btn-primary float-right mr-16" (click)="saveCertification('saveThenAdd')">
        Add New
      </button>
    </svms-sidebar-footer>
</svms-sidebar>
  