<!--no msp type organization-->
<div class="content-area ui-content-wrapper no-content bg-light" [hidden]="orgType !== 'TYPE_MSP'">
  <div class="page-title px-24 pt-24">MSP</div>
  <div class="first-Organizationtype">
    <img src="./assets/images/no-programs.png" alt="organization-board" width="250" class="img-fluid">
    <h2>You don’t have any MSP yet.</h2>
    <h6 class="pb-16"> Your MPS’s will show up here Create a MSP to get started. </h6>
    <a class="btn btn-secondary mt-4 btn-icon-on-left" id="addOrg" (click)="createOrg()">
      <icon size="20px" name="add" type="material" class="icon--onLeft d-flex pr-8" cuClass="cu-icon"></icon> Add your
      first MSP
    </a>
  </div>
</div>

<!--no vendor type organization-->
<div class="content-area ui-content-wrapper no-content bg-light" [hidden]="orgType !== 'TYPE_VENDOR'">
  <div class="page-title px-24 pt-24">Vendors</div>
  <div class="first-Organizationtype">
    <img src="./assets/images/no-vendors.png" alt="organization-board" width="250" class="img-fluid">
    <h2>No Vendors Added Yet</h2>
    <h6 class="pb-16">Your Vendors will show up here Create a Vendor to get started.</h6>
    <a class="btn btn-secondary mt-4 btn-icon-on-left" id="addOrg" (click)="createOrg()">
      <icon size="20px" name="add" type="material" class="icon--onLeft d-flex pr-8" cuClass="cu-icon"></icon> Add your
      First Vendor
    </a>
  </div>
</div>

<!--no client type organization-->
<div class="content-area ui-content-wrapper no-content bg-light" [hidden]="orgType !== 'TYPE_CLIENT'">
  <div class="page-title px-24 pt-24">Clients</div>
  <div class="first-Organizationtype">
    <img src="./assets/images/no-clients.png" alt="organization-board" width="250" class="img-fluid">
    <h2>You don’t have any Clients yet.</h2>
    <h6 class="pb-16">Your clients will show up here Create a client to get started.</h6>
    <a class="btn btn-secondary mt-4 btn-icon-on-left" id="addOrg" (click)="createOrg()">
      <icon size="20px" name="add" type="material" class="icon--onLeft d-flex pr-8" cuClass="cu-icon"></icon> Add your
      first Client
    </a>
  </div>
</div>
<app-organization-create [title]="orgTitle" [icon]="orgIcon" [type]="orgType"></app-organization-create>
