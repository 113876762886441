import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dashboard-config',
  templateUrl: './dashboard-config.component.html',
  styleUrls: ['./dashboard-config.component.scss']
})
export class DashboardConfigComponent implements OnInit {
  @Output() closeSidebar = new EventEmitter
  show = true;
  public viewMode: any;
  public viewModeDate: any;
  constructor() { }

  ngOnInit(): void {
    this.viewMode = "tab1";
    this.viewModeDate = "tab1";
  }
  hideWidget() {
    this.closeSidebar.emit(false)
  }

}
