<form [formGroup]="userForm">
  <div class="Upload_box_wrapper">
    <svms-upload-avatar [image]="userForm?.controls?.avatar?.value" (hideError)="getCropImage($event)" [ngClass]="{'disable':isViewClicked1}"></svms-upload-avatar>
    <div   class="upload-panel-body">
      <h4 *ngIf="!logoComponent?.logoUrl || !isImagePresent" class="mb-4">Profile Avatar</h4> 
      <div *ngIf="(!isImagePresent || !logoComponent?.logoUrl) && !isViewClicked1"   class="error-text">Only .PNG, .JPG formats with maximum file size of 3 MB</div>
      <p class="error-text small">{{logoSize}}</p>
    </div>

    <div class="toggle-panel" [ngClass]="{'disable':isViewClicked1}"> 
      <div class="toggle-panel-inner" [ngClass]="toggle?.value ? 'if-active' : 'if-inactive'">
        <span class="status-text">{{toggle?.title}}</span>
        <span class="toggle-switch" [ngClass]="toggle?.value ? 'toggle-on' : 'toggle-off'"
          (click)="onClickToggle()"></span>
      </div>
    </div>
  </div>

  <div class="form-group-row">
    <div class="form-group">
      <label>First Name <span class="require">*</span></label>
      <input type="text" class="form-control" placeholder="Enter first name" formControlName="first_name" required [ngClass]="{'error':
      userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched)
        }" minlength="2" [readonly]="isViewClicked1">
    <p class="validation-msg"
      *ngIf="userForm.controls.first_name.touched && userForm.controls['first_name'].invalid && userForm.controls['first_name'].errors?.required">
      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> First Name is
      Required
    </p>
    <p class="validation-msg" *ngIf="userForm.controls.first_name.errors?.minlength">
      First Name must be at least 2 characters long.
    </p>
    </div>
    <div class="form-group">
      <label>Middle Name</label>
      <input type="text" class="form-control" placeholder="Enter middle name" formControlName="middle_name" [readonly]="isViewClicked1">
    </div>
    <div class="form-group">
      <label>Last Name <span class="require">*</span></label>
      <input type="text" class="form-control" placeholder="Enter last name" formControlName="last_name" required [ngClass]="{'error':
      userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched)
        }" minlength="2" [readonly]="isViewClicked1">
    <p class="validation-msg"
      *ngIf="userForm.controls.last_name.touched && userForm.controls['last_name'].invalid && userForm.controls['last_name'].errors?.required">
      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Last Name is
      Required
    </p>
    <p class="validation-msg" *ngIf="userForm.controls.last_name.errors?.minlength">
     Last Name must be at least 2 characters long.
    </p>
    </div>
  </div>
  <div class="form-group-row">
    <div class="form-group">
      <label>Prefix</label>
      <ng-select [clearable]="false" placeholder="Choose" ngDefaultControl formControlName="name_prefix" [readonly]="isViewClicked1">
        <ng-option [value]="'mr'">Mr</ng-option>
        <ng-option [value]="'mrs'">Mrs</ng-option>
        <ng-option [value]="'miss'">Miss</ng-option>
        <ng-option [value]="'dr'">Dr</ng-option>
        <ng-option [value]="'prof'">Prof</ng-option>
        <ng-option [value]="'mx'">MX.</ng-option>
        <ng-option [value]="'ind'">IND.</ng-option>
        <ng-option [value]="'misc'">Misc</ng-option>
      </ng-select>
    </div>
    <div class="form-group">
      <label>Suffix</label>
      <ng-select [clearable]="false" placeholder="Choose" ngDefaultControl formControlName="name_suffix" [readonly]="isViewClicked1">
        <ng-option [value]="'jr'">Jr</ng-option>
        <ng-option [value]="'sr'">Sr</ng-option>
        <ng-option [value]="'III'">III</ng-option>
        <ng-option [value]="'IV'">IV</ng-option>
        <ng-option [value]="'V'">V</ng-option>
      </ng-select>
    </div>
    <div class="form-group">
      <label>Supervisor</label>
      <ng-select [clearable]="false" placeholder="Choose" ngDefaultControl formControlName="supervisor_id" [readonly]="isViewClicked1">
        <ng-option [value]="data?.id" *ngFor="let data of supervisorList">{{data?.first_name}} {{data?.last_name}}</ng-option>
      </ng-select>
    </div>

    <div class="form-group" *ngIf="false">
      <label>Phone</label>
      <div class="d-flex align-items-center country-searchable">
        <ng-select [searchable]="false" [clearable]="false" ngDefaultControl formControlName="phone" [readonly]="isViewClicked1">
          <ng-option>
            <img src="../../../assets/images/flag/हिन्दी.png" width="20px" class="mr-4" />
            <span>+91</span>
          </ng-option>
          <ng-option>
            <img src="../../../assets/images/flag/au.png" width="20px" class="mr-4" />
            <span>+12</span>
          </ng-option>
          <ng-option>
            <img src="../../../assets/images/flag/us.png" width="20px" class="mr-4" />
            <span>+1</span>
          </ng-option>
        </ng-select>
        <input type="number" class="form-control" placeholder="Contact Person Phone Number">
      </div>
    </div>
  </div>

  <div class="form-group">
    <label>Title</label>
    <input type="text" class="form-control" formControlName="title" [readonly]="isViewClicked1">
  </div>

  <div class="form-group">
    <label>Email <span class="require">*</span></label>
    <input type="email" class="form-control" formControlName="email" pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$" [ngClass]="{'error':
    userForm.controls['email'].invalid && (userForm.controls['email'].dirty)
      }" [readonly]="isViewClicked1">
    <p *ngIf="
      userForm.get('email').dirty &&
              (userForm.get('email').errors?.pattern || userForm.get('email').errors?.required || userForm.get('email').invalid)
            " class="validation-msg">
      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
      Email is not valid.
    </p>
  </div>

  <div class="form-group">
    <label>User Role <span class="require">*</span></label>
    <ng-select [clearable]="false" placeholder="Choose" ngDefaultControl formControlName="role_id" [readonly]="isViewClicked1">
      <ng-option [value]="role?.id" *ngFor = "let role of Roles">{{role?.name}}</ng-option>
    </ng-select>
  </div>
</form>
