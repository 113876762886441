import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProgramService } from '../../programs/program.service';
import { StorageService } from '../../core/services/storage.service';
import { BreadcrumEvents, ClientData, ProgramConfig } from '../../shared/enums';
import { LoaderService } from '../../shared/components/loader/loader.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { UserService } from 'src/app/core/services/user.service';
import { ProgramManageComponent } from 'src/app/shared/components/program-manage/program-manage.component';
import { ClonerService } from '../../core/services/cloner.service';
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  tap,
  elementAt,
} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { errorHandler } from '../../shared/util/error-handler'
import {
  EventStreamService,
  Events,
  EmitEvent,
} from 'src/app/core/services/event-stream.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-program-setup-details',
  templateUrl: './program-setup-details.component.html',
  styleUrls: ['./program-setup-details.component.scss']
})
export class ProgramSetupDetailsComponent implements OnInit, AfterViewInit {
  //public programSetup: FormGroup;
  showDropdwon = false;
  showCustomPanel = true;
  public programId: any;
  vmsData: any;
  public editData: boolean;
  public manageUserComp: any;
  public editProgramForm: FormGroup;
  public mspInput$ = new Subject<string>();
  @ViewChild(ProgramManageComponent) manageUser: ProgramManageComponent;

  public toggle = {
    title: 'active',
    value: true
  };
  public status: boolean;
  public programData: any
  public showUserCreate = false;
  public existMail: any;
  clientName: any;
  clientData: any;
  is_enabled: any;
  programName: any;
  uniqueId: any;
  service_type: any;
  msp: any;
  start_date: any;
  industry: any;
  category: any;
  client_logo: any;
  email: any;
  public duplicateProgram: any;
  public mspList: any;
  moduleGroup: any = [] ;
  public programStatus:boolean;
  public dataLoad = true;
  public clientLoad: boolean;
  public programModuleGroup;
  constructor(private route: ActivatedRoute, private programService: ProgramService, private fb: FormBuilder, private localStorage: StorageService, private _userService: UserService, private _cloneService: ClonerService
    , private _loadService: LoaderService, private _alert: AlertService, private changeDetection: ChangeDetectorRef, private eventStream: EventStreamService) { }

    ngOnInit() {

    this.editProgramForm = this.fb.group({
      client: [null, Validators.required],
      programType: [null, Validators.required],
      msp: [null, ''],
      name: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9 -]+$'),
        ]),
      ],
      programID: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9-]+$'),
        ]),
      ],
      date: [null],
      UserRole: [null, ''],
      description: ['.', ''],
      members: this.fb.array([]),
      managerName: [null, ''],
      is_enabled: [null, ''],
    });

    this.mspData();
    this.onSearchMSP();
    //this.programId = this.route.snapshot.queryParams.programId ? this.route.snapshot.queryParams.programId : '';
    this.editData = false;
    let programdata = this.localStorage.get(ProgramConfig[0]);
    if(programdata){
    this.programData = JSON.parse(programdata);
    }
    let clientData = this.localStorage.get(ClientData[0]);
    if(clientData){
      this.clientData = JSON.parse(clientData);
    }
    
    this.programId = this.programData?.program_req_id;
    this.fetchProgramIddetails();
    this.getMmebers();
    this.getProgramModuleList();
    this.editProgramForm.get('name')?.valueChanges.pipe(debounceTime(400),
      distinctUntilChanged(),
      tap((text) => {
        this.checkProgramName(text);
      })
    ).subscribe();

    this.eventStream.on(Events.DO_SHOW_USER_FORM, (data) => {
      this.showUserCreate = data;
      this.changeDetection.detectChanges();
    });

  }

  ngAfterViewInit() {
  }

  addUserToProgram(eve) {
    this.eventStream.emit(new EmitEvent(Events.CREATE_NEW_USER, { show: true, orgId: environment.SIMPLIFY_ORG_ID }));

  }

  dateFormat(date) {
    if (!date) return;
    let shortMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    date = new Date(date);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    if(Number.isNaN(shortMonth[date.getMonth()] + " " + day + ", " + year)) {
      
      return null;
    }
    return shortMonth[date.getMonth()] + " " + day + ", " + year;
  }

   fetchProgramIddetails() {
    this._loadService.show();
    this.programService.get(`/configurator/programs?unique_id=${this.programData?.program_uniqId}`).subscribe((data) => {
      this.vmsData = data.programs;
      this.clientName = this.vmsData[0].client.name;
      this.fetchClientData(this.clientName);
      this.is_enabled = this.vmsData[0].is_enabled;
      this.programName = this.vmsData[0].name;
      this.uniqueId = this.vmsData[0].unique_id;
      this.service_type = this.vmsData[0].service_type;
      this.msp = this.vmsData[0].msp !== null ? this.vmsData[0].msp.name : this.vmsData[0].msp;
      this.start_date = this.dateFormat(this.vmsData[0].start_date);
      this.programStatus = this.vmsData[0]?.is_enabled;
      this._loadService.hide();
      this.dataLoad = true;
    }, err => {
      this._loadService.hide();
      this._alert.error(errorHandler(err));
    });
  }

   fetchClientData(client){
    let clientName = client;
    if(clientName){
    this.programService.get(`/configurator/organizations?category=CLIENT&name=${clientName}`).subscribe(data => {
      data = data?.organizations;
      let industries = '';
      data[0]?.industries.map(ele => {
        industries += ele.name+', ';
      })
      industries = industries.slice(0,-2);
    this.industry = industries;
      this.category = data[0]?.category;
      this.client_logo = data[0]?.logo != null  ? data[0]?.logo : data[0]?.name;
      this.email = data[0]?.email != null ? "mailto:" + data[0]?.email : '';
    })
  }
  }

  loadData() {
    this.editProgramForm.patchValue({
      name: this.vmsData[0]?.name,
      msp: this.vmsData[0]?.msp?.id,
      client: this.vmsData[0]?.client?.id,
      programType: this.vmsData[0]?.service_type,
      programID: this.vmsData[0]?.unique_id,
      date: this.dateFormat(this.vmsData[0]?.start_date),
      is_enabled: this.vmsData[0]?.is_enabled
    })
  }

  checkProgramName(term) {
    let duplicate = false;
    this.programService.get(`/configurator/programs?name=${term}`).subscribe(
      data => {
        data.programs.map(data1 => {
          if (data1?.name?.toUpperCase() == term.toUpperCase() && term.toUpperCase() != this.vmsData[0]?.name?.toUpperCase()) {
            duplicate = true;
          }

          else {
            duplicate = false;
          }
        })
        if (duplicate) {
          this.duplicateProgram = "Program name already in use";
        }
        else {
          this.duplicateProgram = "";
        }
      }
    )
  }

  mspData() {
    this.programService
      .get('/configurator/organizations?category=MSP')
      .subscribe((data) => {
        this.mspList = data?.organizations;
      });
  }

  selectstatus(flag){
    this.editProgramForm.get('is_enabled').setValue(flag);
    if(flag) {
      this.programStatus = flag;
    }
    else {
      this.programStatus = flag;
    }
  }

  onSearchMSP() {
    this.mspInput$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term) => this.MSPService(term))
      )
      .subscribe((data) => {
        this.mspList = data?.organizations;
      });
  }

  private MSPService(term) {
    if (term == null || !term) this.mspData();
    return this.programService.get(`/configurator/organizations?category=MSP&name=${term}`);
  }

  onClickDropdwon() {
    this.showDropdwon = !this.showDropdwon;
  }

  onClickToggle() {
    if (this.toggle.value) {
      this.toggle.value = false;
      this.toggle.title = 'inactive';
    } else {
      this.toggle.value = true;
      this.toggle.title = 'active';
    }
    this.status = this.toggle.value;
  }

  onEditBtnClick() {
    this.editData = true;
    this.dataLoad = false;
    this.loadData();
    this.showCustomPanel = false;
  }

  onCancelBtnClick() {
    this.editData = false;
    this.showCustomPanel = true;
    this.dataLoad = true;
  }

  onSaveBtnClick() {
    let conditions;
    let editForm = this.editProgramForm.value;
    let date1;
    let date = new Date(this.editProgramForm.get('date').value);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    date1 = new Date(year, month, day + 1);
    date1 = date1.getTime() / 1000;
    date1 = isNaN(date1?.toString()) ? undefined :date1?.toString();
   let module_groups= this.moduleGroup.map(fg => {
      return { id: fg.id, is_enabled: fg.is_enabled };
   })
    let payLoad = {
      name: editForm?.name,
      description: ".",
      service_type: editForm?.programType,
      msp: editForm?.msp || null,
      vendors: [],
      start_date: date1 || undefined,
      is_enabled: editForm?.is_enabled,
      members: this.prepareObj(this.manageUser.programManagersObj)||[],
      module_groups: module_groups || []
    }
    if (payLoad?.name == '' || payLoad?.name == undefined) {
      this._alert.error("program name is required");
      conditions = true;
    }
    if (payLoad?.service_type == '' || payLoad?.service_type == undefined) {
      this._alert.error("program type is required");
      conditions = true;
    }
    if (payLoad?.service_type == 'MSP-MANAGED' && (payLoad?.msp == undefined || payLoad?.msp == '')) {
      this._alert.error("MSP is required");
      conditions = true;
    }
    if (payLoad?.members?.length == 0) {
      this._alert.error("program members are required");
      conditions = true;
    }
    if(this.duplicateProgram != '' && this.duplicateProgram != undefined){
      this._alert.error("program Name already in use");
      conditions = true;
    }
    if (!conditions) {
      this._loadService.show();
      this.programService.put(`/configurator/programs/${this.vmsData[0]?.id}`, payLoad).subscribe(data => {
        if (data?.program?.id) {
          this._loadService.hide();
          this._alert.success('Program Updated Successfully..', {
            color: 'white',
            bgColor: 'lightgreen',
          });
          if((this.vmsData[0]?.name != payLoad.name) || (this.vmsData[0]?.is_enabled != payLoad.is_enabled)){
            this.programService.shareProgramData.emit(true);
          }
          this.fetchProgramIddetails();
          this.getMmebers();
          //this.inviteUsers();
          this.manageUser.programMemForm.reset();
          this.manageUser.programManagersObj = [];
          this.manageUser.programMemidObj = [];
          this.editData = false;
          this.showCustomPanel = true;
          this.manageUser.userEmail = [];
          this.manageUser.selectedUsers = [];
        }

      }, err => {
        this._loadService.hide();
        this._alert.error(errorHandler(err), {})
      }
      )
    }
  } 

  changeService_Type(){
    this.editProgramForm.controls.msp.setValue(null);
  }

  getMmebers() {
    this._userService.get(`/configurator/programs/${this.programId}/members?org_ids=${environment.SIMPLIFY_ORG_ID}`).subscribe
      (
        data => {
          let _id = Date.now();
          data.members.forEach(ele => {
            _id = _id + 1;
            this.manageUser.programManagersObj.push({
              role_id: ele?.role.id,
              user_id: ele?.id,
              id: _id,
              is_enabled: ele?.['is_enabled']
            })
            this.manageUser.programMemidObj.push({
              role_id: ele?.role.name,
              user_id: ele?.first_name,
              id: _id,
              is_enabled: ele?.['is_enabled']
            })
          this.manageUser.selectedUsers.push(ele?.role.id);
          })
        }
      )
  }

  prepareObj(obj) {
    let copyObj = this._cloneService.deepClone(obj);
    let programObj = [];
    let copyElemet;
    copyObj?.map((element) => {
      copyElemet = element;
      delete copyElemet.id;
      programObj.push(copyElemet);
    });
    return programObj;
  }

  inviteUsers() {
    const emailArr = this.manageUser?.userEmail;
    emailArr?.map(val => {
      const payLoad = { email: val };
      this.programService
        .post(
          `/profile-manager/organizations/${environment.SIMPLIFY_ORG_ID}/members/invite`,
          payLoad
        )
        .subscribe(
          (data) => {
            if (data?.member?.id) {
              this.existMail = '';
            }
            if (data?.error?.message) {
              this.existMail = data.error.message;
            }
          },
          (err) => {
            if (err?.error?.error?.message) {
              this._alert.error(err.error.error.message, {});
            }
            this._alert.error(errorHandler(err),{});
          }
        );
    });
  }

  getProgramModuleList() {
    this._userService.getAllModuleList(this.programId).subscribe(data => {
      data?.module_groups?.forEach((mGroup: any) => {
        mGroup.is_enabled = true;
        mGroup.modules = mGroup.modules.map(module => {
          return {
            name: module.name,
            code: module.code,
            module_id: module.id,
            is_enabled: false,
            is_read_allowed: true,
            is_create_allowed: false,
            is_edit_allowed: false,
            is_delete_allowed: false
          };
        });
      });
      this.programModuleGroup = data.module_groups;
      this.getAllModuleList();
    }, error => {
      this._alert.error(errorHandler(error), {});
    },
      () => {
      });


  }
     getAllModuleList() {
    this.programService.get('/configurator/resources/module-groups').subscribe(data => {
      if (data && data.module_groups ) {
      data?.module_groups?.forEach((mGroup: any) => {
        mGroup.is_enabled = false;
        mGroup.modules = mGroup.modules.map(module => {
          return {
            name: module.name,
            code: module.code,
            module_id: module.id,
            is_enabled: false,
            is_read_allowed: true,
            is_create_allowed: false,
            is_edit_allowed: false,
            is_delete_allowed: false
          };
        });
      });
      this.moduleGroup = data.module_groups;
      if (this.programModuleGroup && this.programModuleGroup.length > 0) {
        this.programModuleGroup.forEach(programModule => {
          this.moduleGroup.forEach(module => {
              if (programModule.id === module.id) {
                module.is_enabled = true;
              }
          });
        });
      }
    }
    }, error => {
      console.log(error);
    });
  }
  onClickModule(e) {
  }
}
