import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import * as d3 from 'd3';
import { pointer } from 'd3';

@Component({
  selector: 'svms-doughnut-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './svms-doughnut-chart.component.html',
  styleUrls: ['./svms-doughnut-chart.component.scss'],
  styles: [
    `
      .tooltipLegend{
        width: 8px;
        height: 8px;
        position: relative;
        display: inline-block;
        margin-right: 5px;
        top: -1px;
      }
    `
  ]
})
export class SvmsDoughnutChartComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() height: number = 280;
  @Input() width: number = 350;
  @Input() margin: number = 50;
  @Input() thikness: number = 40;
  @Input() value: string = 'value';
  @Input() label: string = 'name';
  @Input() onHoverIncreaseEffect = true;

  legendRectSize: number = 6;
  legendSpacing: number = 6;

  radius: number = 0;
  svg: any;
  color: any;
  pie: any;
  arc: any;
  arcOver: any;
  outerArc: any;
  div: any;
  sumOfData: number = 0;
  show: any;

  doughnutId: string = ''
  doughnutHoverId: string = ''
  doughnutIdList: string[] = []

  constructor() {
  }

  @HostListener('document:click', ['$event'])

  clickout() {

    if (this.show == true) {
      this.show = false;
      if (document.getElementById("legendMoreContainer") != null)
        document.getElementById("legendMoreContainer").style.display = "none";
    } else {
      this.show = true;
    }

  }

  ngOnInit() {
    this.doughnutId = '_' + Math.random().toString(36).substr(2, 9);
    this.doughnutHoverId = '_' + Math.random().toString(36).substr(2, 9);
    this.sumOfData = (this.data.reduce((d: any, c: any) => { return (d + parseFloat(c[this.value])) }, 0)).toFixed(1)
  }
  ngAfterViewInit() {

    this.radius = (Math.min(this.width, this.height) / 2) - this.margin
    this.color = d3.scaleOrdinal(d3.schemePaired)


    this.svg = d3.select(`#${this.doughnutId}`)
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", `translate(${this.width / 2}, ${this.height / 2})`);

    this.pie = d3.pie().sort(null).value((d: any) => { return Math.abs(d[this.value]) })
    this.arc = d3.arc().outerRadius(this.radius).innerRadius(this.radius - this.thikness)
    this.arcOver = d3.arc().outerRadius(this.radius + 10).innerRadius(this.radius - this.thikness)
    this.outerArc = d3.arc().innerRadius(this.radius * 0.9).outerRadius(this.radius * 0.9)
    this.div = d3.select(`#${this.doughnutHoverId}`).attr("class", "tooltip-donut").style("opacity", 0);
    this.drawSlices();
  }

  private autoIdGenrator() {
    let id = `_${Math.random().toString(36).substr(2, 9)}`;
    if (this.doughnutIdList.indexOf(id) === -1) {
      this.doughnutIdList.push(id);
      return id
    } else {
      return this.doughnutIdList
    }
  }

  private drawSlices() {

    let tempSvg = this.svg.selectAll('allSlices')
      .remove().exit()
      .data(this.pie(this.data))
      .enter().append('g').append('path')
      .attr('d', this.arc)
      .attr('fill', "#fff")
      .attr('id', (d: any, i: number) => `${this.autoIdGenrator()}`);

    tempSvg.transition().delay((d: any, i: any) => { return i * 100; }).duration(500)
      .attrTween('d', a => {
        var i = d3.interpolate(a.startAngle + 0.1, a.endAngle);
        return t => {
          a.endAngle = i(t);
          return this.arc(a);
        }
      }).attr('fill', (d: any, i: number) => this.color(i)).attr("fill-opacity", 0.7);

    tempSvg.on('mouseover', (d: any, i: any) => {
      const others = this.svg.selectAll('path').filter(a => {
        return a !== i;
      })
      others.style('opacity', 1)


      let divData = document.getElementById(`${this.doughnutId}`).getBoundingClientRect();
      this.div.transition()
        .duration(50)
        .style("opacity", 1);
      let num = (Math.round((parseFloat(this.data[i.index][this.value]) / this.sumOfData) * 100)).toString() + '%';

      let bgStyle = "background:" + this.color(i.index);

      let tooltipLegend = "<div class='tooltipLegend' style='" + bgStyle + "'></div>";
      let dataLabel = (i?.data[this.label]);

      this.div.html(tooltipLegend + dataLabel)
        .style("left", Math.abs(d.pageX - 70) + "px")
        .style("top", Math.abs(d.pageY - 150) + "px");

      if (this.onHoverIncreaseEffect) {
        d3.select(`#${d.target.id}`).transition()
          .duration(1000)
          .attr('d', this.arcOver);
      }
      
      this.svg.append("text").attr("class", "centerText")
        .attr("font-size", "2.5em").style("font-weight", "bold").style("font-family", "Arial")
        .attr("text-anchor", "middle")
        .attr('y', 15)
        .text(Math.round(parseFloat(this.data[i.index][this.value])));
    })
      .on('mouseout', (d: any, i: any) => {
        const others = this.svg.selectAll('path').filter(a => {
          return a !== i;
        })
        others.style('opacity', 1);

        this.div.transition()
          .duration(50)
          .style("opacity", 0);
        if (this.onHoverIncreaseEffect) {
          d3.select(`#${d.target.id}`).transition()
            .duration(1000)
            .attr('d', this.arc);
        }

        d3.selectAll(".centerText").remove();
      });

    let legendContainer = document.getElementById("legendContainer");
    legendContainer.innerHTML = "";

    let legendUl = document.createElement("ul");

    let legendData = this.data.map(d => {
      return d[this.label];
    });

    let summarizedLegendData = [];
    if (legendData.length > 8) {
      for (let i = 0; i < 8; i++) {
        summarizedLegendData.push(legendData[i]);
      }
      summarizedLegendData.push("<i class='material-icons' style='color: rgb(82, 97, 255); font-size: 26px;'>more_horiz</i>")
    } else {
      summarizedLegendData = legendData;
    }


    summarizedLegendData.forEach((d, i) => {

      let liElem = document.createElement("li"),
        circleElem = document.createElement("div"),
        textElem = document.createElement("span");

      circleElem.className = "legendSymbol";
      circleElem.style.backgroundColor = this.color(i);

      textElem.innerHTML = d;
      textElem.className = "legendText";

      if (d != "<i class='material-icons' style='color: rgb(82, 97, 255); font-size: 26px;'>more_horiz</i>") {
        liElem.appendChild(circleElem);
      } else {
        let colorSet = this.color;
        textElem.onclick = function () {

          let that = this;
          let legendWrapper = document.createElement("div");
          legendWrapper.id = "legendMoreContainer",
            legendWrapper.className = "legendMoreContainer";

          liElem.appendChild(legendWrapper);

          let legendMoreContainer = document.getElementById("legendMoreContainer");
          let legendUlMoreContainer = document.createElement("ul");

          legendMoreContainer.innerHTML = "";         

          legendData.forEach((d, i) => {

            let liMoreElem = document.createElement("li"),
              circleMoreElem = document.createElement("div"),
              textMoreElem = document.createElement("span");

            circleMoreElem.className = "legendSymbol";
            circleMoreElem.style.backgroundColor = colorSet(i);

            textMoreElem.innerHTML = d;
            textMoreElem.className = "legendText";

            liMoreElem.appendChild(circleMoreElem);
            liMoreElem.appendChild(textMoreElem);

            legendUlMoreContainer.appendChild(liMoreElem);

          });

          legendMoreContainer.appendChild(legendUlMoreContainer);
          document.getElementById("legendMoreContainer").style.display = "block";
        };
      }

      liElem.appendChild(textElem);
      legendUl.appendChild(liElem);

    });

    legendContainer.appendChild(legendUl);
  }

}
