
<h4 class="pl-16">Notification</h4> 
<a href="javascript:void(0)" class="close-notify" (click)="closeNotification()">
    <icon name="clear" size="20px" type="material"></icon>
</a>
<div class="clearfix"></div>
<ul class="notify-listing p-16 mt-30">
    <li>
        <icon cuClass="notify-list-icon" name="assignment_turned_in" size="18px" type="material"></icon>
        <div class="notification-message">
            <span class="title highlight">New Submission</span>
            for your job <span class="highlight">java Developer</span>
        </div>
        <div class="time">9:00 AM</div>
    </li>

    <li>
        <icon cuClass="notify-list-icon" name="assignment_turned_in" size="18px" type="material"></icon>
        <div class="notification-message">
            <span class="title highlight">New Submission</span>
            for your job <span class="highlight">java Developer</span> 
        </div>
        <div class="time">9:00 AM</div>
    </li>
</ul>

