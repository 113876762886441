import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateCandidateComponent } from './create-candidate/create-candidate.component';
import { CandidateComponent } from './candidate.component';
import { ListCandidateComponent } from './list-candidate/list-candidate.component';
import { VmsTableModule } from '../library/table/vms-table.module';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CandidateRoutingModule } from './candidate-routing.module';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { AddSkillComponent } from './components/add-skill/add-skill.component';
import { AddCertificateComponent } from './components/add-certificate/add-certificate.component';
import { AddVaccinationsComponent } from './components/add-vaccinations/add-vaccinations.component';
import { AddEducationComponent } from './components/add-education/add-education.component';
import { AddSpecialitiesComponent } from './components/add-specialities/add-specialities.component';
import { AddCredentialComponent } from './components/add-credential/add-credential.component';
import { JobAssignmentsComponent } from './job-assignments/job-assignments.component'
import { JobDetailsModule } from '../jobs/job-details/job-details.module';


@NgModule({
  declarations: [CreateCandidateComponent, CandidateComponent, ListCandidateComponent, UploadDocumentsComponent, AddCertificateComponent, AddSkillComponent, AddVaccinationsComponent, AddEducationComponent, AddSpecialitiesComponent, AddCredentialComponent, JobAssignmentsComponent],
  imports: [
    CommonModule, VmsTableModule, SharedModule, NgSelectModule, FormsModule,
    ReactiveFormsModule, CandidateRoutingModule, JobDetailsModule
  ],
  exports:[
    CreateCandidateComponent, CandidateComponent, ListCandidateComponent
  ]
})
export class CandidateModule { }
