<svms-sidebar title="Add New User" icon="person_add" [dataVisibility]="addnewUser" (onClose)="sidebarClose()">
  <svms-sidebar-body>
    <vms-tab-group [selectedIndex]="tabIndex" (onSelectedIndexChange)="onIndexChange($event)">
      <vms-tab label="Basic Info">
        <app-basic-info [simplifyUser]="true"></app-basic-info>
      </vms-tab>
      <vms-tab label="Address & Contact">
      <form [formGroup]="createUser">
        <!-- <h4 class="float-left mb-24">Primary Address</h4> -->
      <app-address-contact-details  [showSecondaryAddress]="true"   [showSecondaryContact]="true"></app-address-contact-details>

  
        <h4 class="mb-24">Additional Phone Numbers</h4>
        <div class="form-group-row">
          <div class="form-group">
            <label>Additional Phone Number </label>
            <div class="d-flex align-items-center country-searchable">
              <ng-select [searchable]="false" [clearable]="false" ngDefaultControl formControlName= "additional_phone">
                <ng-option>
                  <img src="../../../assets/images/flag/English (Canada).png" width="20px" class="mr-4" />
                  <span>+1</span>
                </ng-option>
                <ng-option>
                  <img src="../../../assets/images/flag/us.png" width="20px" class="mr-4" />
                  <span>+1</span>
                </ng-option>
              </ng-select>
              <input type="text" class="form-control" placeholder="Additional Phone Number" formControlName="additionalphone" [pattern]="mobNumberPattern" [ngClass]="{'error':
              createUser.controls['additionalphone'].invalid && (createUser.controls['additionalphone'].dirty || createUser.controls['additionalphone'].touched)
                }">
            </div>
            <p *ngIf="
            createUser.get('additionalphone').touched &&
                    (createUser.get('additionalphone').errors?.pattern || createUser.get('additionalphone').errors?.required || createUser.get('additionalphone').invalid)
                  " class="validation-msg">
            <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
            Phone number not valid.
          </p>
          </div>
        </div>
      </form>
      </vms-tab>
    </vms-tab-group>
  

  </svms-sidebar-body>
  <svms-sidebar-footer>
    <button class="btn btn-outline float-left" *ngIf='userBasic'>
      <icon size="20px" name="mail" type="material" class="icon--on-left d-flex" cuClass="d-flex"></icon>
      Invite User
    </button>
    <button class="btn btn-primary float-right" (click)="nexttab()" *ngIf='userBasic' [disabled]= "BasicInfoForm == 'INVALID'">
      Continue
      <icon size="20px" name="arrow_forward" type="material" class="icon--on-right d-flex" cuClass="d-flex"></icon>
    </button>
    <button class="btn btn-primary float-right" *ngIf="userAddressTab" (click)="saveUsers()" [disabled]= "BasicInfoForm == 'INVALID' || createUser.status == 'INVALID'">
      <icon size="20px" name="check_circle" type="material" class="icon--on-left d-flex" cuClass="d-flex"></icon>
      Create User
    </button>
  </svms-sidebar-footer>
</svms-sidebar>
