import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InterviewsComponent } from './components/interviews/interviews.component';
import { JobDetailComponent } from './components/job-details/job-details.component';
import { OffersComponent } from './components/offers/offers.component';
import { SubmittedCandidatesComponent } from './components/submitted-candidates/submitted-candidates.component';
import { JobDetailsComponent } from './job-details.component';
import { ApprovelComponent } from './components/approvel/approvel.component';



const routes: Routes = [
  {
    path: 'job-details', component: JobDetailComponent,
  },
  {
    path: 'job-details/:id', component: JobDetailComponent,
  },
  {
    path: 'job-details/:id/submitted-candidate', component: SubmittedCandidatesComponent,
  },
  {
    path: 'job-details/:id/offers', component: OffersComponent,
  }
  ,
  {
    path: 'job-details/:id/interviews', component: InterviewsComponent,
  },
  {
    path: 'job-details/:id/approval', component: ApprovelComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobDetailsRoutingModule { }
