import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/core/services/storage.service';
import { ThemeService } from '../../../core/services/theme.service';
import {
  EventStreamService,
  Events,
  EmitEvent,
} from '../../../core/services/event-stream.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [TranslateService]
})
export class HeaderComponent implements OnInit {
  @Output()
  notificationClick: EventEmitter<string> = new EventEmitter<string>();
  programClick: EventEmitter<string> = new EventEmitter<string>();
  currentProgram: any;
  notifyToggle = "";
  user: any = { name: '' };
  bannerBackgroundColor:string= '#FFFFFF';
  notificationToggle() {
    this.notificationClick.emit(this.notifyToggle = "active");
  }

  showAllPrograms() {
    this.eventStrems.emit(new EmitEvent(Events.PROGRAM_SIDEBAR, true));
  }

  searchToggle = false;

  openSearch() {
    this.searchToggle = true;
  }

  closeSearch() {
    this.searchToggle = false;
  }

  constructor(
    public translate: TranslateService,
    public eventStrems: EventStreamService,
    public storageService: StorageService,
    public themeService: ThemeService
  ) { }

  ngOnInit(): void {
    this.bannerBackgroundColor= environment.BANNER_BACKGROUND_COLOR;
    this.eventStrems.on(Events.LoggedIn, (data) => {
      this.user.name = data;
    });

    this.eventStrems.on(Events.PROGRAM_SIDEBAR, (data) => {
      this.currentProgram = this.storageService.get('CurrentProgram');
    });

    this.currentProgram = this.storageService.get('CurrentProgram');
  }
  get logoUrl() {
    if (this.currentProgram?.client?.logo) {
      return this.currentProgram?.client?.logo;
    }

    switch (this.themeService.getCurrentTheme()) {
      case 'light':
        return './assets/images/logo-light.svg';
      case 'dark':
        return './assets/images/logo-dark.svg';
      default:
        return './assets/images/logo-light.svg';
    }
  }

  downloadPdf() {
    var link = document.createElement('a');
    const account = this.storageService.get('account');
    let name = '';
    if (account?.role?.organization_category === 'VENDOR') {
      name = 'vendor_';
    } else if (account?.role?.organization_category === 'CANDIDATE') {
      name = 'worker_';
    } else if (account?.role?.organization_category === 'CLIENT' && account?.role?.name === 'Hiring Manager') {
      name = 'hiring_manager_';
    } else if (account?.role?.organization_category === 'CLIENT' && account?.role?.name !== 'Hiring Manager') {
      name = 'client_';
    }
    link.href = `https://s3.simplifyvms.com/wipro/${name}help_section.pdf`;
    link.target = "_blank";
    link.download = 'help_section.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  }

}
