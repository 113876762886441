<svms-sidebar [title]="panelHeading" [dataVisibility]="addSkills" (onClose)="sidebarClose()">
  <svms-sidebar-body>
    <form [formGroup]="addSkilsForm">
        <div class="p-24">
            <div class="form-group">
              <label>Select Skill <span class="require">*</span></label>
              <ng-select [clearable]="false" placeholder="Select" [searchable]="false" formControlName="skillType">
                <ng-option [value]="type.id" *ngFor="let type of skillList">{{type.name}}</ng-option>
              </ng-select>
              <p class="validation-msg"
                *ngIf="(addSkilsForm.controls.skillType.touched && addSkilsForm.controls['skillType'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Skill
                is Required
              </p>
            </div>
            <div class="form-group">
              <label>Experience Level <span class="require">*</span></label>
              <ng-select [clearable]="false" placeholder="Select" [searchable]="false" formControlName="experienceLevel">
                <ng-option [value]="'Not Applicable'">Not Applicable</ng-option>
                <ng-option [value]="'Fundamental Awareness (basic knowledge)'">Fundamental Awareness (basic knowledge)</ng-option>
                <ng-option [value]="'Intermediate (practical application)'">Intermediate (practical application)</ng-option>
                <ng-option [value]="' Advanced (applied theory)'"> Advanced (applied theory)</ng-option>
                <ng-option [value]="'Expert (recognized authority)'">Expert (recognized authority)</ng-option>
              </ng-select>
              <p class="validation-msg"
                *ngIf="(addSkilsForm.controls.experienceLevel.touched && addSkilsForm.controls['experienceLevel'].errors)">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Experience Level
                is Required
              </p>
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea class="form-control disable" rows="4" placeholder="Enter Description" formControlName="description"></textarea>
            </div>
            <div class="form-group">
              <label>Notes</label>
              <textarea class="form-control notes" rows="4" placeholder="Enter Notes" formControlName="notes"></textarea>
            </div>
            <app-upload-documents uploadLabel="Upload Supporting Skill Documents" (uploadDataFiles)="uploadFiles($event)"  [closePanel] = "closePanel"></app-upload-documents>
          </div>
    </form>
    
  </svms-sidebar-body>
  <svms-sidebar-footer>
    <button type="submit" class="btn btn-outline float-right" (click)="saveSkill('save')">
      <icon size="20px" name="check_circle" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
      Add
    </button>
    <button type="submit" class="btn btn-primary float-right mr-16"  (click)="saveSkill('saveThenAdd')">
      Add New
    </button>

  </svms-sidebar-footer>
</svms-sidebar>
