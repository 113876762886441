
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ProgramSetupRoutingModule } from './program-setup-routing.module';
import { HierarchyModule } from './hierarchy/hierarchy.module';
import { QualificationsModule } from './qualifications/qualifications.module';
import { ProgramSetupComponent } from './program-setup.component';
import { ProgramSetupHeaderComponent } from './component/program-setup-header/program-setup-header.component';
import { ProgramSetupSidebarComponent } from './component//program-setup-sidebar/program-setup-sidebar.component';
import { InsightComponent } from './component/insight/insight.component';
import { ProgramSetupHomeComponent } from './program-setup-home/program-setup-home.component';
import { NgxStickySidebarModule } from '@smip/ngx-sticky-sidebar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { ProgramSetupDetailsComponent } from './program-setup-details/program-setup-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProgramSetupDetailsComponent } from './program-setup-details/program-setup-details.component';
// import { ManageUsersModule } from './manage-users/manage-users.module';
import {ProgramsModule} from '../programs/programs.module';



@NgModule({
  declarations: [ProgramSetupComponent, ProgramSetupHeaderComponent, ProgramSetupSidebarComponent, InsightComponent, ProgramSetupHomeComponent, ProgramSetupDetailsComponent],
  imports: [
    CommonModule,
    ProgramSetupRoutingModule,
    SharedModule,
    NgxStickySidebarModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
    ProgramsModule,
    ReactiveFormsModule
  ],
  exports: [TranslateModule],
  providers: [TranslateService],

})
export class ProgramSetupModule { }
