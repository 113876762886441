import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'vms-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class VMSPaginatorComponent implements OnInit, OnChanges {
  @Output() changePage = new EventEmitter<number>(true);
  @Input() initialPage = 1;
  @Input() currentPage = 1;
  @Input() maxPages = 1;

  pager: any = [];

  constructor() { }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    this.pager = new Array(this.maxPages).fill(1, 0, this.maxPages).map((x, i) => i + 1)
  }

  onClick(event, page: string) {
    if (page === '-1') {
      if (this.currentPage === 1) {
        return
      }
      this.currentPage = this.currentPage - 1;
    } else if (page === '+1') {
      if (this.currentPage === this.maxPages) {
        return
      }
      this.currentPage = this.currentPage + 1;
    } else {
      this.currentPage = parseInt(page);
    }
    this.changePage.emit(this.currentPage)
    event.preventDefault()
  }

  paginationTabShowHide(page: number) {
    if ((this.currentPage === 1) && ((page === 2) || (page === 3))) {
      return false
    } else if ((this.currentPage === this.maxPages) && (page === (this.maxPages - 2))) {
      return false
    } else if ((this.currentPage - page) > 1) {
      return true
    } else if ((page - this.currentPage) > 1) {
      return true
    }
    return false
  }
}

