import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AlertService } from '../../shared/components/alert/alert.service';
import { StorageService } from './storage.service';
import { UserDataObj } from '../../shared/enums';
import { EventStreamService, EmitEvent, Events } from './event-stream.service';
@Injectable({
  providedIn: 'root',
})
export class AuthguardService implements CanActivate {
  _userData = UserDataObj;
  constructor(
    private router: Router,
    private _alert: AlertService,
    private _storage: StorageService,
    private _eventSteam: EventStreamService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this._storage.get(this._userData[0])) {
      // this._alert.warn(
      //   'You are not allowed to view this page. You are redirected to login Page',
      //   { color: 'red', bgColor: 'lightgreen', position: 'top-right' }
      // );

      this.router.navigate(['auth/login'], { queryParams: { navigate: encodeURIComponent(state.url) } });

      return false;
    } else {
      this._eventSteam.emit(new EmitEvent(Events.LoggedIn, true));
      return true;
    }
  }
}
