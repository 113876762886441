import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'svms-column-chart',
  templateUrl: './svms-column-chart.component.html',
  styleUrls: ['./svms-column-chart.component.scss']
})
export class SvmsColumnChartComponent implements OnInit {
  @Input() data: any;
  @Input() height: number = 280;
  @Input() width: number = 900;
  @Input() margin: any;
  @Input() xLabel = 'status';
  @Input() yLabel = 'label';
  @Input() onHoverIncreaseEffect = true;

  @Input() set chart(data) {
    if (data.length > 0) {
      d3.select("#columnId").html("");
      this.data = data;
      let keys = Object.keys(this.data[0]);

      this.yLabel = keys[1], this.xLabel = keys[0];

      // for (var i = 0; i < keys.length; i++) {
      //   if (keys[i] != "label") {
      //     this.yLabel = keys[i];
      //     break;
      //   }
      // }

      this.initializeColComponents();
    } else {
      d3.select("svg").remove();
      d3.select("#columnId").html("No Data Available").style("font-size", "1.5em").style("font-weight", 600).style("color", "rgb(185 183 183)");
      return;
    }
  };

  get chart() {
    return this.data;
  }

  // legend dimensions
  legendRectSize: number = 6; // defines the size of the colored squares in legend
  legendSpacing: number = 6; // defines spacing between squares

  svg: any;
  tooltip: any;
  color: any;

  constructor() {
  }

  ngOnInit() {

  }

  private initializeColComponents() {

    this.data.forEach(d => {
      d[this.xLabel] = +d[this.xLabel];
    });

    this.margin = { top: 40, right: 20, bottom: 60, left: 90 };
    this.color = d3.scaleOrdinal(d3.schemePaired);

    d3.select("svg").remove();

    this.svg = d3.select("#columnId")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    this.tooltip = d3.select("#columnHoverId").append("div").attr("class", "columnToolTip").style("opacity", 0);

    this.drawColumns();
  }


  //Captialize Word
  private titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  private drawColumns() {

    //Container Height & Width
    const contentWidth = this.width - this.margin.left - this.margin.right;
    const contentHeight = this.height - this.margin.top - this.margin.bottom;

    const yField = this.yLabel,
      xField = this.xLabel;
    

    // Add X axis
    const x = d3.scaleLinear()
      .domain([0, Math.max.apply(Math, this.data.map(d => {
        return d[xField];
      }))])
      .range([0, contentWidth]);

    this.svg.append("g")
      .attr("transform", "translate(0," + contentHeight + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "middle");

    // text label for the x axis
    // this.svg.append("text")
    //   .attr("transform",
    //     "translate(" + (this.width / 2) + " ," +
    //     (this.height - 50) + ")")
    //   .style("text-anchor", "middle").style("text-transform", "capitalize")
    //   .text(this.xLabel);

    // Y axis
    var y = d3.scaleBand()
      .range([0, contentHeight])
      .domain(this.data.map(function (d, i) {
        return d[yField];
      }))
      .padding(.1);
    this.svg.append("g")
      .call(d3.axisLeft(y))
      .selectAll(".tick text")
      .attr("text-transform", "capitalize")
      .text(d => {
        let label = d.split("-")[0];
        return this.titleCase(label.replace(/_/g, ' '));
      });

    // text label for the y axis
    // this.svg.append("text")
    //   .attr("transform", "rotate(-90)")
    //   .attr("y", 0 - this.margin.left)
    //   .attr("x", 0 - ((this.height / 2) - (this.margin.bottom)))
    //   .attr("dy", "1em")
    //   .style("text-anchor", "middle")
    //   .style("text-transform", "capitalize")
    //   .text(this.yLabel);

    //Columns
    this.svg.selectAll(".bar")
      .data(this.data)
      .enter()
      .append("rect")
      .attr("x", x(0))
      .attr("y", function (d) { return y(d[yField]);
      })
      .attr("width", 0)
      .attr("height", y.bandwidth())
      .attr('fill', (d: any, i: number) => this.color(i)).attr("fill-opacity", 0.7)
      //Mouseover
      .on("mousemove", (d, i) => {
        let name = (this.titleCase(i[yField].replace(/_/g, ' ')));
        this.tooltip
          .style("left", Math.abs(d.pageX - 20) + "px")
          .style("top", Math.abs(d.pageY - 150) + "px")
          .style("opacity", 1)
          .html(name + ": " + (i[xField]));
      })
      //Mouseout
      .on("mouseout", d => {
        this.tooltip.style("opacity", 0);
      });

    //Column Transition
    this.svg.selectAll("rect")
      .transition()
      .duration(800)
      .attr("width", d => { return x(d[xField]); })
      .delay((d, i) => { return (i * 100) })
  }

}



