import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {
  show: boolean = false;
  widget: boolean = false;
  assignment_uuid: any;
  _assignment: any;
  user: any;
  @Input() set assignment(data) {
    this._assignment = data;
    this.assignment?.forEach(a => {
      if (a?.code) {
        a.bindLabel = a?.assignment_title?.title + ' (' + this.shortAssignmentId(a?.code) + ')';
      } else {
        a.bindLabel = a?.assignment_title?.title;
      }

    });
    if (this.assignment) {
      this.assignment_uuid = this.assignment[0]?.assignment_uuid;
      this.changeAssignment(this.assignment_uuid);
    }
  };

  get assignment() {
    return this._assignment;
  }

  @Output() onClickSetting = new EventEmitter();
  @Output() assignmentUUID = new EventEmitter();

  constructor(public _storageService: StorageService) { }

  ngOnInit(): void {
    this.user = this._storageService.get('user');
  }
  shortAssignmentId(assignmentId = '') {
    if (assignmentId?.includes("AM-")) {
      assignmentId = assignmentId?.substr(assignmentId?.lastIndexOf("AM-"))
    }
    return assignmentId;
  }

  changeAssignment(id) {
    this.assignmentUUID.emit(id)
  }
  showMenus() {
    this.onClickSetting.emit(true)
    this.show = true
  }

  hideMenus() {
    this.onClickSetting.emit(false)
    this.show = false;
  }

  showWidget() {
    this.widget = true
  }

  close(event) {
    this.widget = event;
  }
  saveWidget() {
    this.onClickSetting.emit(false)
    this.show = false;
  }
}
