import {
  ChangeDetectorRef, Component, Input,
  OnChanges, OnInit, ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  debounceTime, distinctUntilChanged
} from 'rxjs/operators';
import { EmitEvent, Events, EventStreamService } from 'src/app/core/services/event-stream.service';
import { OrgTypes } from 'src/app/shared/enums';
import { ProgramService } from '../../programs/program.service';
import { AlertService } from '../../shared/components/alert/alert.service';
import { LoaderService } from '../../shared/components/loader/loader.service';
import { errorHandler } from '../../shared/util/error-handler';
import { AddressAndContactDetailsComponent } from '../components/address-and-contact-details/address-and-contact-details.component';
import { ClientBasicInfoComponent } from '../components/client-basic-info/client-basic-info.component';
import { MspBasicInfoComponent } from '../components/msp-basic-info/msp-basic-info.component';
import { VendorBasicInfoComponent } from '../components/vendor-basic-info/vendor-basic-info.component';

@Component({
  selector: 'app-organization-create',
  templateUrl: './organization-create.component.html',
  styleUrls: ['./organization-create.component.scss'],
})
export class OrganizationCreateComponent implements OnInit, OnChanges {
  createOrganizationType = 'hidden';
  editOrganizationType = 'hidden';
  tabIndex = 0;
  label: string;
  orgType: string;
  orgIcon: string;
  editDataRow: any;
  clientBasicEditInfo: any;
  clientBasicAddEditInfo: any;
  isViewClicked = false;
  public createTitle: string;
  public editTitle: string;
  public viewTitle: string;
  clickOutside: boolean;


  @Input() public set title(title: string) {
    this.label = title;
    this.createTitle = this.label;
    this.isCreate = true;
    this.changeDetection.detectChanges();
  }
  @Input() public set type(type: string) {
    this.orgType = type;
    this.changeDetection.detectChanges();
  }
  @Input() public set icon(icon: string) {
    this.orgIcon = icon;
    this.changeDetection.detectChanges();
  }
  public addresValidation;
  public addres1Validation;
  public cityValidation;
  public zipcodeValidation;
  public address2Validation;
  public stateValidation;
  public chekvalidations = undefined;
  public isCreate = false;
  public validAddressForm = 'VALID';
  public validForm = 'INVALID';
  public orgFormData: any;
  public orgId: any;
  public existOrgId: string;
  public logoComponent: any;
  public editAddressForm: any;
  public nameIsValid: boolean = true;
  @ViewChild(AddressAndContactDetailsComponent)
  addresscomponent: AddressAndContactDetailsComponent;
  @ViewChild(MspBasicInfoComponent) mspcomponent: MspBasicInfoComponent;
  @ViewChild(VendorBasicInfoComponent)
  vendorcomponent: VendorBasicInfoComponent;
  @ViewChild(ClientBasicInfoComponent)
  clientcomponent: ClientBasicInfoComponent;
  // @ViewChild(SvmsUploadAvatarComponent) logocomponent: SvmsUploadAvatarComponent;

  constructor(
    private eventStream: EventStreamService,
    private router: Router,
    private changeDetection: ChangeDetectorRef,
    private _programService: ProgramService,
    private _loadService: LoaderService,
    private _alert: AlertService,
    private _activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.eventStream.on(Events.ORG_CREATE, (data) => {
      if (data) {
        this.clientBasicEditInfo = [];
        this.editDataRow = [];
        this.existOrgId = '';
        this.isViewClicked = false;
        this.isCreate = true;
        if (this.label.search('Create') <= 0) {
          this.label = this.createTitle;
        }
        this.createOrganizationType = 'visible';
      } else {
        this.createOrganizationType = 'hidden';
      }
      this.clickOutside = false;
    });

    this.eventStream.on(Events.ORG_VIEW, (data) => {
      if (data.event) {
        this.isViewClicked = true;
        this.isCreate = false;
        this.editDataRow = data?.data;
        this.existOrgId = data?.data?.id;
        this.orgType = this.editDataRow.category === 'CLIENT' ? 'TYPE_CLIENT' : this.editDataRow.category === 'MSP' ? 'TYPE_MSP' : this.editDataRow.category === 'VENDOR' ? 'TYPE_VENDOR' : '';
        let labelName = this.editDataRow.category === 'CLIENT' ? this.editDataRow?.name + ' detail view' : this.editDataRow.category === 'MSP' ? this.editDataRow?.name + ' detail view' : this.editDataRow.category === 'VENDOR' ? this.editDataRow?.name + ' detail view' : '';
        this.label = labelName;
        this.clientBasicEditInfo = [];
        this.clientBasicEditInfo.push({
          label: 'edit',
          logo: this.editDataRow?.logo,
          status: this.editDataRow?.status,
          name: this.editDataRow?.name,
          type: this.editDataRow?.category,
          industry: this.editDataRow?.industries,
          inactive_reason: this.editDataRow?.inactive_reason || undefined
        });
        this.editTitle = labelName;
        this.clientBasicAddEditInfo = [];

        const editAddressInfo = data.data.addresses.length > 0 ? data.data.addresses : '';
        const primaryAdress = editAddressInfo[0] !== undefined ? editAddressInfo[0] : '';
        const secondaryAdress = editAddressInfo[1] !== undefined ? editAddressInfo[1] : '';

        const editContactInfo = data.data.contacts?.length > 0 ? data.data?.contacts : '';
        const primaryContact = editContactInfo[0] !== undefined ? editContactInfo[0] : '';
        const secondaryContact = editContactInfo[1] !== undefined ? editContactInfo[1] : '';
        this.clientBasicAddEditInfo.push({
          primary: {
            label: 'edit',
            address_line1: primaryAdress.address_line1,
            address_line2: primaryAdress.address_line2,
            address_type: primaryAdress.address_type,
            city: primaryAdress.city,
            country: primaryAdress.country,
            state: primaryAdress.state,
            zipcode: primaryAdress.zipcode
          }
        },
          {
            secondary: {
              label: 'edit',
              address_line1: secondaryAdress.address_line1,
              address_line2: secondaryAdress.address_line2,
              address_type: secondaryAdress.address_type,
              city: secondaryAdress.city,
              country: secondaryAdress.country,
              state: secondaryAdress.state,
              zipcode: secondaryAdress.zipcode
            }
          },
          {
            primarycontacts: {
              email: primaryContact?.email,
              first_name: primaryContact?.first_name,
              last_name: primaryContact?.last_name,
              phone: primaryContact?.contact_phone,
              designation: primaryContact?.title
            }
          },
          {
            secondarycontacts: {
              email: secondaryContact?.email,
              first_name: secondaryContact?.first_name,
              last_name: secondaryContact?.last_name,
              phone: secondaryContact?.contact_phone,
              designation: secondaryContact?.title
            }
          });
        this.createOrganizationType = 'visible';
      } else {
        this.createOrganizationType = 'hidden';
      }
      this.clickOutside = true;
    });

    this.eventStream.on(Events.ORG_EDIT, (data) => {
      if (data.event) {
        this.isViewClicked = false;
        this.isCreate = false;
        this.editDataRow = data?.data;
        this.existOrgId = data?.data?.id
        this.orgType = this.editDataRow.category === 'CLIENT' ? 'TYPE_CLIENT' : this.editDataRow.category === 'MSP' ? 'TYPE_MSP' : this.editDataRow.category === 'VENDOR' ? 'TYPE_VENDOR' : '';
        let labelName = this.editDataRow.category === 'CLIENT' ? ' Edit ' + this.editDataRow?.name : this.editDataRow.category === 'MSP' ? ' Edit ' + this.editDataRow?.name : this.editDataRow.category === 'VENDOR' ? ' Edit ' + this.editDataRow?.name : '';
        this.label = labelName;
        this.editTitle = labelName;
        this.clientBasicEditInfo = [];
        this.clientBasicEditInfo.push({ "label": "edit", "logo": this.editDataRow.logo, "status": this.editDataRow.status, "name": this.editDataRow.name, "type": this.editDataRow.category, "industry": this.editDataRow?.industries, "inactive_reason": this.editDataRow?.inactive_reason });

        this.clientBasicAddEditInfo = [];

        const editAddressInfo = data.data.addresses.length > 0 ? data.data.addresses : '';
        const primaryAdress = editAddressInfo[0] !== undefined ? editAddressInfo[0] : '';
        const secondaryAdress = editAddressInfo[1] !== undefined ? editAddressInfo[1] : '';
        const editContactInfo = data.data.contacts?.length > 0 ? data.data?.contacts : '';
        const primaryContact = editContactInfo[0] !== undefined ? editContactInfo[0] : '';
        const secondaryContact = editContactInfo[1] !== undefined ? editContactInfo[1] : '';

        this.clientBasicAddEditInfo.push({
          primary: {
            label: 'edit',
            address_line1: primaryAdress.address_line1,
            address_line2: primaryAdress.address_line2,
            address_type: primaryAdress.address_type,
            city: primaryAdress.city,
            country: primaryAdress.country,
            state: primaryAdress.state,
            zipcode: primaryAdress.zipcode
          }
        },
          {
            secondary: {
              label: 'edit',
              address_line1: secondaryAdress.address_line1,
              address_line2: secondaryAdress.address_line2,
              address_type: secondaryAdress.address_type,
              city: secondaryAdress.city,
              country: secondaryAdress.country,
              state: secondaryAdress.state,
              zipcode: secondaryAdress.zipcode
            }
          },
          {
            primarycontacts: {
              email: primaryContact?.email,
              first_name: primaryContact?.contact_name,
              phone: primaryContact?.contact_phone,
              designation: primaryContact?.title
            }
          },
          {
            secondarycontacts: {
              email: secondaryContact?.email,
              first_name: secondaryContact?.contact_name,
              phone: secondaryContact?.contact_phone,
              designation: secondaryContact?.title
            }
          });
        this.createOrganizationType = 'visible';
      } else {
        this.createOrganizationType = 'hidden';
      }

      this.clickOutside = false;
    });
  }

  ngOnChanges() {
    this.changeDetection.detectChanges();
    if (this.orgType === OrgTypes.TYPE_VENDOR) {
      let vendorValue;
      this.vendorcomponent?.vendorCreate?.valueChanges?.pipe(debounceTime(400),
        distinctUntilChanged()
      ).subscribe((data) => {
        console.log('vendor')
        if (vendorValue != data?.org_name) {
          if (this.editDataRow?.name != data?.org_name) {

            this.checkOrgName(data?.org_name, 'Vendor');
          }
          vendorValue = data?.org_name;
        }
        this.validForm = this.vendorcomponent.vendorCreate.status;
        this.orgFormData = this.vendorcomponent.vendorCreate.value;
        this.logoComponent = this.vendorcomponent.logoComponent;
      });
    }
    if (this.orgType === OrgTypes.TYPE_MSP) {
      let mspValue;
      this.mspcomponent?.orgCreate?.valueChanges?.pipe(debounceTime(400),
        distinctUntilChanged()
      ).subscribe((data) => {
        console.log('msp')
        if (mspValue != data?.org_name) {
          if (this.editDataRow?.name != data?.org_name) {
            console.log(this.editDataRow?.name, '!=', data?.org_name)
            this.checkOrgName(data?.org_name, 'MSP');
          }
          mspValue = data?.org_name;
        }
        this.validForm = this.mspcomponent.orgCreate.status;
        this.orgFormData = this.mspcomponent?.orgCreate.value;
        this.logoComponent = this.mspcomponent.logoComponent;
      })
    }
    if (this.orgType === OrgTypes.TYPE_CLIENT) {
      let clientValue;
      this.clientcomponent?.clientCreate?.valueChanges.pipe(debounceTime(400),
        distinctUntilChanged()
      ).subscribe((data) => {
        console.log('client')
        if (clientValue != data?.org_name) {
          if (this.editDataRow?.name != data?.org_name) {
            this.checkOrgName(data?.org_name, 'Client');
          }
          clientValue = data?.org_name;
        }
        this.validForm = this.clientcomponent.clientCreate.status;
        this.orgFormData = this.clientcomponent.clientCreate.value;
        this.logoComponent = this.clientcomponent.logoComponent;
        if (!data?.status && (!data?.client_inactivereason || data?.client_inactivereason == null)) {
          this.validForm = "INVALID";
        }
      })
    }

    this.addresscomponent?.addressForm?.valueChanges?.subscribe((data) => {
      this.validAddressForm = this.addresscomponent.addressForm.status;
    });
  }

  checkOrgName(term, type) {
    let org_Type;
    this._activatedRoute.params.subscribe(param => {
      org_Type = param['term']
    })
    if (term) {
      this._programService.get(`/configurator/organizations?category=${org_Type}&&name_exact=${term}`).subscribe(
        data => {
          if (data.organizations.length > 0) {
            this.nameIsValid = false;
          }
          else {
            this.nameIsValid = true;
          }
          this.setValidtoOrg(type);
        }
      )
    }
    else {
      this.nameIsValid = true;
      this.setValidtoOrg(type);
    }
  }

  setValidtoOrg(type) {
    switch (type) {
      case 'Vendor':
        this.vendorcomponent.isNameValid = this.nameIsValid;
        break;
      case 'Client':
        this.clientcomponent.isNameValid = this.nameIsValid;
        break;
      case 'MSP':
        this.mspcomponent.isNameValid = this.nameIsValid;
    }
  }

  onContinue() {
    let chekvalidations = false;
    if (this.tabIndex === 0) {
      this.tabIndex = 1 + this.tabIndex;
    } else {
      const addressForm = this.addresscomponent.addressForm.value;
      let contactssecondary: any = {};
      if (addressForm?.secondary_contact_person) {
        contactssecondary.contact_name = addressForm?.secondary_contact_person;
      }
      if (addressForm?.secondary_designation) {
        contactssecondary.title = addressForm?.secondary_designation;
      }
      if (addressForm?.secondary_email) {
        contactssecondary.email = addressForm?.secondary_email;
      }
      if (addressForm?.secondary_phone) {
        contactssecondary.phone = addressForm?.secondary_phone;
      }

      const payLoad = {
        name: this.orgFormData.org_name,
        category: this.orgFormData.org_type,
        industries: this.orgFormData.industry,
        is_enabled: this.orgFormData?.status,
        inactive_reason: this.orgFormData?.client_inactivereason,
        logo: this.logoComponent?.logoUrl || null,
        addresses: [
          {
            type: 'PRIMARY',
            street_1: this.addresscomponent.street_2[0] || '',
            street_2: this.addresscomponent.address[0] || '',
            city: this.addresscomponent.city[0] || '',
            state: this.addresscomponent.state[0],
            zipcode: this.addresscomponent.zipcode[0],
            country: addressForm.country,
          },
          {
            type: 'SECONDARY',
            street_1: this.addresscomponent.address[1] || '',
            street_2: this.addresscomponent.street_2[1] || '',
            city: this.addresscomponent.city[1] || '',
            state: this.addresscomponent.state[1] || '',
            zipcode: this.addresscomponent.zipcode[1] || '',
            country: addressForm.secondarycountry,
          },
        ],
        contacts: [
          {
            type: 'PRIMARY',
            first_name: addressForm.first_name || '',
            last_name: addressForm.last_name || '',
            title: addressForm.designation || '',
            email: addressForm.email || '',
            phone: addressForm.phone || '',
          },
          {
            type: 'SECONDARY',
            // contact_name: addressForm?.secondary_contact_person || '',
            first_name: addressForm.secondary_first_name || '',
            last_name: addressForm.secondary_last_name || '',
            title: addressForm?.secondary_designation,
            email: addressForm?.secondary_email || '',
            phone: addressForm?.secondary_phone || '',
          },
        ],
      };

      payLoad.contacts = payLoad.contacts.filter(cont => cont.first_name && cont.last_name && cont.email);
      if (this.logoComponent?.logoSize !== '' && this.logoComponent?.logoSize !== undefined) {
        chekvalidations = true;
      }
      if (chekvalidations) {
        this.chekvalidations = true;
      } else { this.chekvalidations = false; }
      if (!this.chekvalidations || this.chekvalidations === 'undefined') {
        this._loadService.show();
        if (this.existOrgId) {
          this._programService.put(`/configurator/organizations/${this.existOrgId}`, payLoad).subscribe(data => {
            this.UpdateFormData(data, 'Updated');
            this.tabIndex = 0;
            this.eventStream.emit(new EmitEvent(Events.ORG_EDIT, false));
            this.eventStream.emit(new EmitEvent(Events.ORG_CREATE, false));
          }, err => {
            this._loadService.hide();
            this._alert.error(errorHandler(err));
          });
        }
        else {
          this._programService
            .post('/configurator/organizations', payLoad)
            .subscribe((data) => {
              if (data?.organization?.id) {
                this.orgId = data?.organization?.id;
                this.UpdateFormData(data, 'Created');
                this.tabIndex = 0;
                //this.eventStream.emit(new EmitEvent(Events.ORG_CREATE, false));
                this.sidebarClose();
              }
            }, err => {
              this._loadService.hide();
              this._alert.error(errorHandler(err));
            });
        }
      }
    }
  }

  UpdateFormData(data, type) {
    let orgType;
    if (this.orgType === 'TYPE_CLIENT') {
      orgType = 'Client';
      this.clientcomponent.clientCreate.reset();
    }
    if (this.orgType === 'TYPE_VENDOR') {
      orgType = 'Vendor';
      this.vendorcomponent.vendorCreate.reset();
    }
    if (this.orgType === 'TYPE_MSP') {
      orgType = 'MSP';
      this.mspcomponent.orgCreate.reset();
    }
    this.addresscomponent.addressForm.reset();
    this.addresscomponent.street_2[0] = '';
    this.addresscomponent.address[0] = '';
    this.addresscomponent.city[0] = '';
    this.addresscomponent.state[0] = '';
    this.addresscomponent.zipcode[0] = '';
    this.addresscomponent.street_2[1] = '';
    this.addresscomponent.address[1] = '';
    this.addresscomponent.city[1] = '';
    this.addresscomponent.state[1] = '';
    this.addresscomponent.zipcode[1] = '';

    this.logoComponent.croppedImage = '';
    let msg;
    if (type === 'Create') {
      msg = ` New ${orgType} ${type} Successfully`;
    }
    else {
      msg = `${orgType} ${type} Successfully`;
    }

    this._alert.success(msg, {
      color: 'white',
      bgColor: 'lightgreen',
      type: { INTERVAL_TIME: 3000 }
    });
    this._loadService.hide();
    this.sidebarClose()
  }

  onIndexChange(event) {
    this.tabIndex = event;
  }

  createOrg() {
    this.eventStream.emit(new EmitEvent(Events.IsNoOrg, false));
    this.router.navigate(['/settings/list']);
  }

  cancelOrgCreate() {
    this.eventStream.emit(new EmitEvent(Events.DO_SHOW_CLIENT_FORM, false));
  }

  sidebarClose() {
    this.eventStream.emit(new EmitEvent(Events.ORG_CREATE, false));
    if (this.orgType === 'TYPE_CLIENT') {
      this.clientcomponent.logoComponent.croppedImage = '';
      this.clientcomponent.logoComponent.logoUrl = '';
    } else if (this.orgType === 'TYPE_MSP') {
      this.mspcomponent.logoComponent.croppedImage = '';
      this.mspcomponent.logoComponent.logoUrl = '';
    } else if (this.orgType === 'TYPE_VENDOR') {
      this.vendorcomponent.logoComponent.croppedImage = '';
      this.vendorcomponent.logoComponent.logoUrl = '';
    }
    this.tabIndex = 0;
    this.clientBasicEditInfo = [];
  }
}
