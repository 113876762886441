import { Injectable,EventEmitter } from '@angular/core';
import { HttpService } from '../core/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  mockApi: string;
  public shareProgramData = new EventEmitter;
  constructor(private _http: HttpService) {
  }

  get(url) {
    return this._http.get(url);
  }

  post(url, payload) {
    return this._http.post(url, payload);
  }

  put(url, payload) {
    return this._http.put(url, payload);
  }
  delete(url){
    return this._http.delete(url, {});
  }
  
  getMock(url){
    return this._http.getMock(url);
  }
  serachClient(url) {
    let finalApi = url;
    console.log(finalApi)
    return this._http.get(finalApi);
  }

}
