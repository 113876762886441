<svms-sidebar [title]="panelHeading" [dataVisibility]="addSpecialities" (onClose)="sidebarClose()">
    <svms-sidebar-body>
      <form [formGroup]="addSpecialitiesForm">
        <div class="p-24">
          <div class="form-group">
            <label>Select Speciality <span class="require">*</span></label>
            <ng-select [clearable]="false" placeholder="Select" [searchable]="false" formControlName="specialityType">
              <ng-option [value]="type.id" *ngFor="let type of specialityList">{{type.name}}</ng-option>
            </ng-select>
            <p class="validation-msg"
            *ngIf="(addSpecialitiesForm.controls.specialityType.touched && addSpecialitiesForm.controls['specialityType'].errors)">
              <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Speciality 
              is Required
            </p>
          </div>
          <div class="form-group">
            <label>Experience Level <span class="require">*</span></label>
            <ng-select [clearable]="false" placeholder="Select" [searchable]="false" formControlName="experienceLevel">
                <ng-option [value]="'Not Applicable'">Not Applicable</ng-option>
                <ng-option [value]="'Fundamental Awareness (basic knowledge)'">Fundamental Awareness (basic knowledge)</ng-option>
                <ng-option [value]="'Novice (limited experience)'">Novice (limited experience)</ng-option>
                <ng-option [value]="'Intermediate (practical application)'">Intermediate (practical application)</ng-option>
                <ng-option [value]="'Advanced (applied theory)'">Advanced (applied theory)</ng-option>
                <ng-option [value]="'Expert (recognized authority)'">Expert (recognized authority)</ng-option>
              </ng-select>
              <p class="validation-msg"
            *ngIf="(addSpecialitiesForm.controls.experienceLevel.touched && addSpecialitiesForm.controls['experienceLevel'].errors)">
              <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Experience Level 
              is Required
            </p>
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea class="form-control notes" rows="4" formControlName="description" placeholder="Enter Description"></textarea>
          </div>
          <div class="form-group">
            <label>Notes</label>
            <textarea class="form-control notes" rows="4" formControlName="notes" placeholder="Enter Notes"></textarea>
          </div>
          <app-upload-documents uploadLabel="Upload Supporting Specialty Documents" (uploadDataFiles)="uploadFiles($event)" [closePanel] = "closePanel"></app-upload-documents>
        </div>
      </form>
    </svms-sidebar-body>
    <svms-sidebar-footer>
      <button type="submit" class="btn btn-outline float-right" (click)="saveSkill('save')">
        <icon size="20px" name="check_circle" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
        Add
      </button>
      <button type="submit" class="btn btn-primary float-right mr-16" (click)="saveSkill('saveThenAdd')">
        Add New
      </button>
    </svms-sidebar-footer>
  </svms-sidebar>