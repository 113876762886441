import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';
import { AccountService } from '../account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SetPasswordComponent } from '../set-password/set-password.component';
import { AlertService } from '../../../shared/components/alert/alert.service';
import {
  EventStreamService,
  EmitEvent,
  Events,
} from 'src/app/core/services/event-stream.service';
import { UserService } from 'src/app/core/services/user.service';
@Component({
  selector: 'app-color-theme',
  templateUrl: './color-theme.component.html',
  styleUrls: ['./color-theme.component.scss']
})
export class ColorThemeComponent implements OnInit {
  colorList = [
    { "name": "blue", "navbg": "#17227B", "color": "#5E71FE" },
    { "name": "blue2", "navbg": "#002766", "color": "#1890FF" },
    { "name": "purple", "navbg": "#44337A", "color": "#805AD5" },
    { "name": "purple2", "navbg": "#120338", "color": "#9254DE" },
    { "name": "grey", "navbg": "#1A202C", "color": "#2C7A7B" },
    { "name": "red", "navbg": "#c11f36", "color": "#c11f36" },
    { "name": "teal", "navbg": "#234E52", "color": "#285E61" },
    { "name": "brown", "navbg": "#3E2723", "color": "#6D4C41" },
    { "name": "white-blue", "navbg": "#ffffff", "color": "#5E71FE" },
    { "name": "white-red", "navbg": "#ffffff", "color": "#c11f36" }
  ]

  @Input() defaultColor = "blue";
  @Output() onBack = new EventEmitter(false)
  @Output() onNext = new EventEmitter();
  @Input() disableSubmit = false; 
  defaultNavBg = "#17227B";
  defaultThemeColor = "#5E71FE";
  orgId: any;
  private currentProgram: any;
  private user: any;
  userToken: any;
  org_id: any;
  @ViewChild(SetPasswordComponent) passwordComponent: SetPasswordComponent;

  setColor(e, color) {
    if (e.target.checked) {
      this.defaultColor = color.name;
      this.defaultNavBg = color.navbg;
      this.defaultThemeColor = color.color;
    }
  }

  constructor(private storageService: StorageService,
    public eventStream: EventStreamService,
    private router: Router,
    private accountService: AccountService,
    public userService: UserService,
    private route: ActivatedRoute, private alertService: AlertService) { }


  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.orgId = params['orgId'];
        this.userToken = params['token']
      }
    )
  }

  onBackClick() {
    this.onBack.emit(true);
    this.router.navigate(['auth/avatar'], { queryParams: { orgId: this.orgId, token: this.userToken } });
  }

  // onSkip() {
  //   let payload = { themeColor: this.defaultColor }
  //   let members = this.storageService.get('members');
  //   let userData = this.storageService.get('userEmail');
  //   let password = (this.storageService.get('password') || this.accountService.password);
  //   let authPayload = { username: userData, password: password };
  //   if (payload.themeColor !== undefined) members.theme_code = payload.themeColor;

  //   this.accountService.uploadMembers(this.orgId, this.userToken, members).subscribe((res) => {
  //     this.doLogin(authPayload, userData);
  //   }, (err) => {
  //     console.log(err);

  //   })
  // }



  onNextClick() {
    let payload = { themeColor: this.defaultColor }
    // let members = this.storageService.get('members');
    // let userData = this.storageService.get('userEmail');
    // let password = (this.storageService.get('password') || this.accountService.password);
    // let authPayload = { username: userData, password: password };
    // if (payload.themeColor !== undefined) { members.theme_code = payload.themeColor; }

    // this.accountService.uploadMembers(this.orgId, this.userToken, members).subscribe((res) => {
    //   this.doLogin(authPayload, userData);

    // }, (err) => {
    //   console.log(err);
    // })
    this.onNext.emit({ themeColor: this.defaultColor });

  }

  doLogin(authPayload, userData) {
    this.accountService.login(authPayload).subscribe((res) => {
      let userObj = { email: userData, user_id: res?.user_id };
      this.eventStream.emit(new EmitEvent(Events.LoggedIn, true));
      this.storageService.set("Token", res['token'], true);
      if (userObj.email != ' ' && userObj.email != '' && userObj.email) this.storageService.set("userData", userObj, true);

      this.accountService.getUser(userObj.user_id).subscribe((data) => {
        if (data && data.user) {
          this.storageService.set('user', data?.user, true);
          this.user = data?.user;
          this.org_id = data?.user?.organization_id;
          this.getuserType(this.org_id)
          this.getMyPrograms();

        } else {
          this.alertService.warn('Invalid user information');
        }
      });
      userObj.email = this.removedPlus(userObj.email);
    }, (err) => {
      console.log(err);
      this.alertService.error(err.error)
    });
  }

  getuserType(org_id) {
    this.userService.get(`/configurator/organizations/${org_id}`).subscribe(data => {
      this.storageService.set('user_type', data.category, true);
    }, error => {
      console.log(error);
    });
  }

  getMyPrograms() {
    this.userService.getAllPrograms().subscribe(data => {
      this.storageService.set('CurrentProgram', data?.programs[0], true);
      this.currentProgram = data?.programs[0];
      this.alertService.success(
        'Congratulations You have successfully set up your account.'
      );
      this.getMembershipDetailsAndRole();
    }, err => {
      alert('Error');
      console.log(err);
    })
  }

  removedPlus(email) {
    return email?.replace('+', '%2B');
  }


  getMembershipDetailsAndRole() {
    this.userService.getMembershipDetails(this.currentProgram?.id, this.user.id).subscribe(res => {
      this.storageService.set('account', res?.member, true);
      let permissionList = res?.member?.role?.permissions;
      let permissions = [];
      permissionList?.forEach(element => {
        permissions.push(element?.slug);
      });
      this.storageService.set('user_permission', permissions, true);
      this.router.navigateByUrl('/dashboard');
    }, err => {
      console.log('Not found the membership details, restricted access');
      console.log(err);
      this.router.navigateByUrl('/dashboard');
    });
  }

}
