import { Component, Output, OnInit,EventEmitter, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName, FormArray } from '@angular/forms';
import { EventStreamService, Events, EmitEvent } from 'src/app/core/services/event-stream.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { CandidateService } from '../../service/candidate.service';
import { errorHandler } from '../../../shared/util/error-handler';
import { SvmsDatepickerComponent } from 'src/app/shared/components/svms-datepicker/svms-datepicker.component';

@Component({
  selector: 'app-add-credential',
  templateUrl: './add-credential.component.html',
  styleUrls: ['./add-credential.component.scss']
})
export class AddCredentialComponent implements OnInit {
  @Output() credentialData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('initialDate') initialDate: SvmsDatepickerComponent;
  @ViewChild('reIssueDate') reIssueDate: SvmsDatepickerComponent;
  @ViewChild('expiryDate') expiryDate: SvmsDatepickerComponent;
  closePanel: EventEmitter<boolean> = new EventEmitter();
  @Input() credentialTypeId;
  credentialList = [];
  addCredential = "hidden";
  public addCredentialForm: FormGroup;
  panelHeading = 'Add New Credential';
  editPanel = false;
  clickedIndex ;
  constructor(
    private fb: FormBuilder,
    private eventStream: EventStreamService,
    private alertService: AlertService,
    private candidateService : CandidateService

  ) {

  }

  ngOnInit(): void {
    this.eventStream.on(Events.CREATE_CANDIDATE_CREDENTIAL, (data) => {
      if (data.value) {
        this.addCredential = 'visible';
        if(data.obj != null){
          this.updatePanel(data.obj);
          this.panelHeading = 'Edit Credential';
          this.editPanel = true;
          this.clickedIndex = data.obj.clickedIndex;
        }
      } else {
        this.addCredential = 'hidden';
      }
    });
    this.getCredentials();
    this.addCredentialForm = this.fb.group({
      credentialType: [null, Validators.required],
      issuingAuthority: [null, Validators.required],
      initialIssuingDate: [null, Validators.required],
      reIssuingDate: [null, ''],
      credentialExpirationDate: [null, ''],
      credentialId: [null, ''],
      notes: [null, ''],
      files:[null,'']
    });
  }
  sidebarClose() {
    this.addCredential = 'hidden';
    this.addCredentialForm.reset();
    this.panelHeading = 'Add New Credential';
    this.editPanel = false;
    this.closePanel.emit(true);
  }
  uploadFiles(event){
    this.addCredentialForm.patchValue({
      files:event
    })
  }
  getCredentials(){
    const credentialId = this.credentialTypeId;
    this.candidateService._getQualiFicationType(credentialId) .subscribe((res) => {
      this.credentialList = res.qualifications;
    }, (err) => {
      this.alertService.error(errorHandler(err));
    })
  }
  updatePanel(data){
    this.addCredentialForm.patchValue({
      credentialType: data.qualification_id,
      issuingAuthority: data.issuing_authority,
      initialIssuingDate: data.initial_issue_date,
      reIssuingDate: data.re_issue_date,
      credentialExpirationDate:data.expiration_date,
      credentialId: data.credential_id,
      notes: data.notes,
      files: data.files
    })
  }
  saveCredential(action){
    let data = this.addCredentialForm.value;
    const credentialType = this.credentialList.filter((item)=>{
      if(item.id == data.credentialType){
        return item.name;
      }
    }).map((obj)=>{
        return obj.name;
    })
    if( this.addCredentialForm.valid){
      const payload = {
        qualification_id: data.credentialType,
        issuing_authority: data.issuingAuthority,
        initial_issue_date: data.initialIssuingDate,
        re_issue_date: data.reIssuingDate,
        expiration_date: data.credentialExpirationDate,
        credential_id: data.credentialId,
        notes: data.notes,
        files:data.files,
        credential_name: credentialType
      }
      this.credentialData.emit({payload: payload, isEdit:this.editPanel, clickedIndex: this.clickedIndex});
      if(action == 'save'){
        this.sidebarClose();
      }else{
        this.editPanel = false;
        this.panelHeading = 'Add New Credential';
        this.closePanel.emit(true);
      }
      this.addCredentialForm.reset();
      this.initialDate.value = null;
      this.expiryDate.value = null;
      this.reIssueDate.value = null;
    }else{
      this.alertService.error('Please fill the required fields properly.');
    }
    
  }
}
