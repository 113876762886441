<div class="form-group mb-0">
    <label>{{uploadLabel}}</label>
    <div class="upload-zone text-center p-32">
        <div class="drop-zone mb-16">
            <icon size="24px" name="publish" type="material" cuClass="d-flex"></icon>
        </div>
        <h4 class="pt-4 mb-4">Drag &amp; Drop file or <a (click)="uploadFileInput.click()">Browse</a></h4>
        <input hidden type="file" accept=".pdf, .doc, .docx, .png , .jpg" #uploadFileInput (change)="uploadFile($event)">
        <!-- <p class="file-format mb-32">Only .png, .jpg, .pdf, .doc files with maximum size of 10 MB</p> -->
        <p class="file-format mb-32">.jpg and .png files are allowed with maximum size of 3MB whereas .pdf and .doc files are allowed with maximum size of 10MB</p>
    </div>
    <ul class="uploaded-list" *ngFor="let file of uploadedFileArr, let i = index">
      <li>
        <figure *ngIf="file.ext == 'pdf'">
          <img src="/assets/images/pdf.svg" alt="">
        </figure>
        <figure *ngIf="file.ext == 'docx' || file.ext == 'doc' ">
          <img src="/assets/images/word.svg" alt="">
        </figure>
        <figure *ngIf="file.ext == 'jpg' || file.ext == 'jpeg' ">
          <img src="/assets/images/img-jpg.svg" alt="">
        </figure>
        <figure *ngIf="file.ext == 'png'">
          <img src="/assets/images/img-png.svg" alt="">
        </figure>
        <div class="item-description">
          {{file.name}} <icon class="d-inline-block ml-8" size="18px" name="check_circle" type="material"
            cuClass="d-flex"></icon>
          <p class="upload-size">
            {{file.size}} {{mbKbFileSize}}<span>|</span>{{file.time | date:'d MMM'}}
          </p>
        </div>
        <a (click)="deleteFile(i)" class="delete-file">
          <icon class="d-flex" size="24px" name="delete_forever" type="material" cuClass="d-flex">
          </icon>
        </a>
      </li>
    </ul>
    <ul class="uploaded-list error" *ngIf="!isValidFileSize">
      <li>
        <div class="item-description">
           <icon class="d-inline-block mr-8" size="18px" name="error" type="material"
            cuClass="d-flex"></icon>
          <p class="d-inline-block upload-size" *ngIf="!isImageFile">
            Error : File size must be less than 10MB
          </p>
          <p class="d-inline-block upload-size" *ngIf="isImageFile">
            Error : File size must be less than 3MB
          </p>
        </div>
      </li>
    </ul>
    <ul class="uploaded-list error" *ngIf="fileNotSupportAlert">
      <li>
        <div class="item-description">
          <icon class="d-inline-block mr-8" size="18px" name="error" type="material"
            cuClass="d-flex"></icon>
          <p class="d-inline-block upload-size">
            Error : File type is not supported. Please upload the documents either in .png, .jpg, .pdf, .doc format
          </p>
        </div>
      </li>
    </ul>
</div>