import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { JobService } from '../job.service';
import { errorHandler } from '../../shared/util/error-handler';
import { StorageService } from 'src/app/core/services/storage.service';
import { VendorService } from '../../program-setup/vendor/vendor.service';



@Component({
  selector: 'app-create-candidate',
  templateUrl: './create-candidate.component.html',
  styleUrls: ['./create-candidate.component.scss']
})
export class CreateCandidateComponent implements OnInit {
  createCandidateForm: FormGroup;
  submitted = false;
  public dataLoading = false;
  public totalPages = 12;
  public totalRecords = 10;
  public itemsPerPage: 25;
  public tableLoaded = false;
  vendorList: any = {};
  public programId: any;
  public currentProgram: any;
  public formTitle:any ;
  public btnText = 'Add';
  @Input() isCreateCandidate = 'hidden';
  @Input() set isEdit(data) {
    if (data && data.email) {
      this.editCandidate(data);
      this.btnText = 'Update';
     this.formTitle = 'Edit Candidate';
    } else{
      this.btnText = 'Add';
      this.formTitle = 'Add Candidate';
    }
  }
  @Output() onCreateClose = new EventEmitter();
  @Output() createdCandidate = new EventEmitter()

  constructor(private formBuilder: FormBuilder,
    public jobService: JobService,
    private _loader: LoaderService,
    private _alert: AlertService,
    private storageService: StorageService,
    public vendorService: VendorService,


  ) { }
  get f() { return this.createCandidateForm.controls; }

  ngOnInit(): void {

    this.createCandidateForm = this.formBuilder.group({
      vendorName: [null, Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z A-Z]{3,4}$")]],
      phone_number: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
      vendor_id: ['']
    });
    this.currentProgram = this.storageService.get('CurrentProgram');
    this.programId = this.currentProgram?.id;
    this.getVendorListItems();
    this.btnText = 'Add';
    this.formTitle = 'Add Candidate';
  }
  editCandidate(data) { 
    this.createCandidateForm.patchValue(data);
  }

  saveCandidate(type) {
    this.submitted = true;
    if (this.createCandidateForm.invalid) {
      // this._alert.error(`Mandatory fields are not entered`);
      return;
    } else {

      this.createdCandidate.emit(this.createCandidateForm.value);
      if (type == 'saveAndNew') {
      } else if (type == 'save') {
        this.sidebarClose();
        this.btnText = 'Add';
        this.formTitle = 'Add Candidate';
      }
      this.submitted = false;
      this.createCandidateForm.reset();
    }
  }
  sidebarClose() {
    this.onCreateClose.emit(this.isCreateCandidate = 'hidden');
    this.createCandidateForm.reset();
    this.submitted = false;

  }
  updateVendorId(event) {
    const v = this.vendorList.vendors.filter(vendor => vendor.name === event);
    if (v && v.length > 0) {
      this.createCandidateForm.patchValue({
        vendor_id: v[0]?.program_vendor
      });
    }



  }
  getVendorListItems(pageNo = 1) {
    this._loader.show();
    const url = `/configurator/programs/${this.programId}/vendors?limit=25&page=${pageNo}`;
    this.vendorService.get(url).subscribe(
      data => {
        if (data) {
          this.vendorList = data;


          if (this.vendorList?.program_vendors && this.vendorList?.program_vendors.length > 0) {
            this.vendorList?.program_vendors.forEach(programList => {
              if (programList && programList.vendor) {
                if (!this.vendorList.vendors) {
                  this.vendorList.vendors = new Array();
                }
                if (programList.is_enabled) {
                  programList.vendor.is_enabled = true;
                } else {
                  programList.vendor.is_enabled = false;
                }
                programList.vendor.program_vendor = programList.id;
                this.vendorList.vendors.push(programList.vendor);
              }
            });
          }
          const totalPages = Math.ceil(data.total_records / data.items_per_page);
          this.totalPages = totalPages;
          this.totalRecords = data.total_records;
          this.tableLoaded = true;
          this._loader.hide();
        }
      },
      (err) => {
        this._loader.hide();
        this._alert.error(errorHandler(err));
      });
  }

}
