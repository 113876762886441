<div class="page-container px-24 py-16 min-height" [ngClass]="{'py-24': user?.is_candidate}">
  <div class="row no-gutters">
    <div class="col-3">
      <h3 class="dashboard-heading mb-0">Dashboard</h3>
    </div>
    <div class="col-9">
      <div class="d-flex justify-content-end align-items-center float-right settings-icon-height">
        <!-- <div *ngIf="!show" class="d-flex justify-content-end align-items-center" [ngClass]="{'pr-24': user?.is_candidate}">
          <i class="material-icons cursor-pointer" (click)="showMenus()">settings </i>
        </div> -->
        <label class="mr-16 mb-0" *ngIf="user?.is_candidate"><h4 class="mb-0">Assignment</h4></label>
        <div class="form-group max-length mb-0 pb-0" *ngIf="user?.is_candidate">
         
          <ng-select [clearable]="false" placeholder="Select assignment" [readonly]="!assignment?.length" [(ngModel)]="assignment_uuid" (change)="changeAssignment(assignment_uuid)">
            <ng-option *ngFor="let a of assignment" [value]="a?.assignment_uuid">
              {{a?.bindLabel || 'Worker'}}
            </ng-option>
          </ng-select>
        </div>
        </div>
      <div class=" d-flex justify-content-end align-items-center float-right" *ngIf="show">
        <!-- add new widget -->
        <button class="btn btn-light mr-12" (click)="showWidget()"> <i class="material-icons pr-4">add_circle</i>Add
          New Widget
        </button>
        <!-- Save widgets -->
        <button (click)="saveWidget()" class="btn btn-primary mr-12"> <i
            class="material-icons pr-4">check_circle</i>Save
        </button>
        <!-- empty dashboard -->
        <button class=" btn  btn-light mr-12"> <i class="material-icons pr-4">delete_forever</i>Empty
          Dashboard
        </button>

        <!-- Reset dashboard -->
        <button class="btn btn-light mr-12 " id="reset-dashboard" style="display: none;">
          <i class="material-icons pr-4">refresh</i>Reset</button>

        <!-- cancel widgets -->
        <button class="btn  btn-light" [ngClass]="{'mr-24': user?.is_candidate}" (click)="hideMenus()"> <i
            class="material-icons pr-4">highlight_off</i>Close
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="widget">
  <app-dashboard-config (closeSidebar)="close($event)"></app-dashboard-config>
</div>
