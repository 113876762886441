<section class="detail-wrapper">
  <div class="fixed-width p-32">
    <div class="card bg-light mb-24">
      <header class="page-header position-relative">
        <h3>Jobs / <span class="title">Senior Java Developer</span> <span
            class="tag ml-16 position-relative">Sourcing</span></h3>
        <p>
          <icon class="job-id" name="label" size="18px"></icon> JT354 <icon class="ml-32 updated" name="update"
            size="18px">
          </icon> Last Updated On : Oct 30, 2020
        </p>
        <div class="actions position-absolute">
          <button type="submit" class="btn btn-primary" #actionButton>
            Actions
            <icon size="20px" name="keyboard_arrow_down" type="material" class="icon--on-right" cuClass="icon-cu">
            </icon>
          </button>
          <ul class="action-list position-absolute bg-light" [ngClass]="{'active' : toggleAction}" #actionDropdown>
            <li>
              <a href="javascript:void(0)">
                <icon name="launch" size="20" class="mr-16"></icon> Opt-Out
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <icon name="person_add" size="20" class="mr-16"></icon> Add Candidate
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <icon name="how_to_reg" size="20" class="mr-16"></icon> Add &amp; Submit Candidate
              </a>
            </li>
          </ul>
        </div>
      </header>
      <nav class="detail-nav pl-16 pr-16">
        <ul>
          <li>
            <a href="javascript:void(0)" [ngClass]="{'active' : jobDetals}" (click)="openJobDetail()">Job Details</a>
          </li>
          <li>
            <a href="javascript:void(0)" [ngClass]="{'active' : availableCandidate}"
              (click)="openAvailableCandidate()">Available Candidates</a>
          </li>
          <li>
            <a href="javascript:void(0)" [ngClass]="{'active' : SubmittedCandidate}"
              (click)="openSubmittedCandidate()">Submitted Candidates</a>
          </li>
          <li>
            <a href="javascript:void(0)" [ngClass]="{'active' : interviews}" (click)="openInterviews()">Interviews</a>
          </li>
          <li>
            <a href="javascript:void(0)">Offers</a>
          </li>
          <li>
            <a href="javascript:void(0)">Assignments</a>
          </li>
          <li>
            <a href="javascript:void(0)">Approval</a>
          </li>
          <li>
            <a href="javascript:void(0)">History</a>
          </li>
        </ul>
      </nav>
      <div class="tab-content p-32" *ngIf="jobDetals">
        <h3 class="section-title position-relative mb-24">
          <icon class="icon-bg mr-12" theme="outlined" size="16px" name="description" type="material" cuClass="icon-cu">
          </icon>
          Job Description
        </h3>
        <div class="description mb-24" [ngClass]="{'short-view' : shortDescription}">
          <p>We are looki a Java developer responsible for building Java applications. This includes anything
            between
            complex groups of back-end services and their client-end (desktop and mobile) counterparts. Your primary
            responsibility will be to design and develop these applications, and to coordinate with the rest of the team
            working on different layers of the infrastructure. Thus, a commitment to collaborative problem solving,
            sophisticated design, and product quality is essential.</p>
          <h4>Responsibilities</h4>
          <ul>
            <li>Translate application storyboards and use cases into functional applications</li>
            <li>Design, build, and maintain efficient, reusable, and reliable Java code</li>
            <li>Ensure the best possible performance, quality, and responsiveness of the applications</li>
            <li>Identify bottlenecks and bugs, and devise solutions to these problems</li>
            <li>Help maintain code quality, organization, and automatization</li>
            <li>Translate application storyboards and use cases into functional applications</li>
            <li>Design, build, and maintain efficient, reusable, and reliable Java code</li>
            <li>Ensure the best possible performance, quality, and responsiveness of the applications</li>
            <li>Identify bottlenecks and bugs, and devise solutions to these problems</li>
            <li>Help maintain code quality, organization, and automatization</li>
            <li>Translate application storyboards and use cases into functional applications</li>
            <li>Design, build, and maintain efficient, reusable, and reliable Java code</li>
            <li>Ensure the best possible performance, quality, and responsiveness of the applications</li>
            <li>Identify bottlenecks and bugs, and devise solutions to these problems</li>
            <li>Help maintain code quality, organization, and automatization</li>
          </ul>
        </div>
        <button class="more-link" (click)="clicktoExpand()">{{btnText}}</button>
      </div>
      <div class="tab-content p-32" *ngIf="availableCandidate">
        <h3>Available Candidate</h3>

      </div>
      <div class="tab-content p-32" *ngIf="SubmittedCandidate">
        <h3>Submitted candidates</h3>
        <section class="grid-view">
          <div class="grid-item rounded-sm position-relative">
            <div class="p-16">
              <div class="grid-actions position-absolute">
                <button class="btn btn-secondary small d-inline-block mr-4">View</button>
                <button class="btn btn-light small d-inline-block more-btn">
                  <icon size="20px" class="mr-4" name="more_horiz" type="material" cuClass="icon-cu"></icon>
                </button>
                <nav class="action-dropdown position-absolute">
                  <ul>
                    <li class="withdraw">
                      <a href="javascript:void(0)">
                        <icon size="19px" name="person_remove" type="material" cuClass="d-flex"></icon>
                        Withdraw Candidate
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="float-left checkbox-wrapper mr-12 mt-12">
                <label class="checkbox-container">
                  <input type="checkbox" name="selectall">
                  <span class="checkmark"></span>
                </label>
              </div>
              <figure class="float-left user-avatar mr-12 text-center rounded-sm overflow-hidden">
                JB
              </figure>
              <p class="title float-left font-weight-semibold">James Butt<br><span
                  class="d-inline-block rounded-sm position-relative font-weight-normal status">Submitted</span></p>
              <div class="updated-on float-right">
                <icon size="14px" class="mr-4" name="schedule" type="material" cuClass="icon-cu"></icon>
                1 Days ago
              </div>
              <div class="clearfix"></div>
              <div class="rates d-flex mt-32 mb-16">
                <p class="font-weight-medium">
                  $10
                  <span class="d-block font-weight-normal">Pref. Pay Rate</span>
                </p>
                <p class="font-weight-medium">
                  $20
                  <span class="d-block font-weight-normal">Est.Hours</span>
                </p>
                <p class="font-weight-medium">
                  $30
                  <span class="d-block font-weight-normal">Est.Pay</span>
                </p>
              </div>
            </div>
            <footer class="grid-footer p-16">
              <div class="float-left availability">
                <icon size="14px" class="mr-4" name="event" type="material" cuClass="icon-cu"></icon> 20/12/2020
              </div>
              <div class="match-value float-right font-weight-medium ml-8">
                40%
              </div>
              <div class="progress-wrap float-right">
                <span class="progress-value" style="width: 40%"></span>
              </div>
              <div class="clearfix"></div>
            </footer>
          </div>
          <div class="grid-item rounded-sm position-relative">
            <div class="p-16">
              <div class="grid-actions position-absolute">
                <button class="btn btn-secondary small d-inline-block mr-4">View</button>
                <button class="btn btn-light small d-inline-block more-btn">
                  <icon size="20px" class="mr-4" name="more_horiz" type="material" cuClass="icon-cu"></icon>
                </button>
                <nav class="action-dropdown position-absolute">
                  <ul>
                    <li class="withdraw">
                      <a href="javascript:void(0)">
                        <icon size="19px" name="person_remove" type="material" cuClass="d-flex"></icon>
                        Withdraw Candidate
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="float-left checkbox-wrapper mr-12 mt-12">
                <label class="checkbox-container">
                  <input type="checkbox" name="selectall">
                  <span class="checkmark"></span>
                </label>
              </div>
              <figure class="float-left user-avatar mr-12 text-center rounded-sm overflow-hidden">
                RD
              </figure>
              <p class="title float-left font-weight-semibold">Roy D. TESTL <br><span
                  class="d-inline-block rounded-sm position-relative font-weight-normal status">Submitted</span></p>
              <div class="updated-on float-right">
                <icon size="14px" class="mr-4" name="schedule" type="material" cuClass="icon-cu"></icon>
                1 Days ago
              </div>
              <div class="clearfix"></div>
              <div class="rates d-flex mt-32 mb-16">
                <p class="font-weight-medium">
                  $10
                  <span class="d-block font-weight-normal">Pref. Pay Rate</span>
                </p>
                <p class="font-weight-medium">
                  $20
                  <span class="d-block font-weight-normal">Est.Hours</span>
                </p>
                <p class="font-weight-medium">
                  $30
                  <span class="d-block font-weight-normal">Est.Pay</span>
                </p>
              </div>
            </div>
            <footer class="grid-footer p-16">
              <div class="float-left availability">
                <icon size="14px" class="mr-4" name="event" type="material" cuClass="icon-cu"></icon> 20/12/2020
              </div>
              <div class="match-value float-right font-weight-medium ml-8">
                20%
              </div>
              <div class="progress-wrap float-right">
                <span class="progress-value" style="width: 20%"></span>
              </div>
              <div class="clearfix"></div>
            </footer>
          </div>
          <div class="grid-item rounded-sm position-relative">
            <div class="p-16">
              <div class="grid-actions position-absolute">
                <button class="btn btn-secondary small d-inline-block mr-4">View</button>
                <button class="btn btn-light small d-inline-block more-btn">
                  <icon size="20px" class="mr-4" name="more_horiz" type="material" cuClass="icon-cu"></icon>
                </button>
                <nav class="action-dropdown position-absolute">
                  <ul>
                    <li class="withdraw">
                      <a href="javascript:void(0)">
                        <icon size="19px" name="person_remove" type="material" cuClass="d-flex"></icon>
                        Withdraw Candidate
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="float-left checkbox-wrapper mr-12 mt-12">
                <label class="checkbox-container">
                  <input type="checkbox" name="selectall">
                  <span class="checkmark"></span>
                </label>
              </div>
              <figure class="float-left user-avatar mr-12 text-center rounded-sm overflow-hidden">
                JB
              </figure>
              <p class="title float-left font-weight-semibold">James Butt<br><span
                  class="d-inline-block rounded-sm position-relative font-weight-normal status">Submitted</span></p>
              <div class="updated-on float-right">
                <icon size="14px" class="mr-4" name="schedule" type="material" cuClass="icon-cu"></icon>
                1 Days ago
              </div>
              <div class="clearfix"></div>
              <div class="rates d-flex mt-32 mb-16">
                <p class="font-weight-medium">
                  $10
                  <span class="d-block font-weight-normal">Pref. Pay Rate</span>
                </p>
                <p class="font-weight-medium">
                  $20
                  <span class="d-block font-weight-normal">Est.Hours</span>
                </p>
                <p class="font-weight-medium">
                  $30
                  <span class="d-block font-weight-normal">Est.Pay</span>
                </p>
              </div>
            </div>
            <footer class="grid-footer p-16">
              <div class="float-left availability">
                <icon size="14px" class="mr-4" name="event" type="material" cuClass="icon-cu"></icon> 20/12/2020
              </div>
              <div class="match-value float-right font-weight-medium ml-8">
                40%
              </div>
              <div class="progress-wrap float-right">
                <span class="progress-value" style="width: 40%"></span>
              </div>
              <div class="clearfix"></div>
            </footer>
          </div>
          <div class="grid-item rounded-sm position-relative">
            <div class="p-16">
              <div class="grid-actions position-absolute">
                <button class="btn btn-secondary small d-inline-block mr-4">View</button>
                <button class="btn btn-light small d-inline-block more-btn">
                  <icon size="20px" class="mr-4" name="more_horiz" type="material" cuClass="icon-cu"></icon>
                </button>
                <nav class="action-dropdown position-absolute">
                  <ul>
                    <li class="withdraw">
                      <a href="javascript:void(0)">
                        <icon size="19px" name="person_remove" type="material" cuClass="d-flex"></icon>
                        Withdraw Candidate
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="float-left checkbox-wrapper mr-12 mt-12">
                <label class="checkbox-container">
                  <input type="checkbox" name="selectall">
                  <span class="checkmark"></span>
                </label>
              </div>
              <figure class="float-left user-avatar mr-12 text-center rounded-sm overflow-hidden">
                RD
              </figure>
              <p class="title float-left font-weight-semibold">Roy D. TESTL <br><span
                  class="d-inline-block rounded-sm position-relative font-weight-normal status">Submitted</span></p>
              <div class="updated-on float-right">
                <icon size="14px" class="mr-4" name="schedule" type="material" cuClass="icon-cu"></icon>
                1 Days ago
              </div>
              <div class="clearfix"></div>
              <div class="rates d-flex mt-32 mb-16">
                <p class="font-weight-medium">
                  $10
                  <span class="d-block font-weight-normal">Pref. Pay Rate</span>
                </p>
                <p class="font-weight-medium">
                  $20
                  <span class="d-block font-weight-normal">Est.Hours</span>
                </p>
                <p class="font-weight-medium">
                  $30
                  <span class="d-block font-weight-normal">Est.Pay</span>
                </p>
              </div>
            </div>
            <footer class="grid-footer p-16">
              <div class="float-left availability">
                <icon size="14px" class="mr-4" name="event" type="material" cuClass="icon-cu"></icon> 20/12/2020
              </div>
              <div class="match-value float-right font-weight-medium ml-8">
                20%
              </div>
              <div class="progress-wrap float-right">
                <span class="progress-value" style="width: 20%"></span>
              </div>
              <div class="clearfix"></div>
            </footer>
          </div>
          <div class="grid-item rounded-sm position-relative">
            <div class="p-16">
              <div class="grid-actions position-absolute">
                <button class="btn btn-secondary small d-inline-block mr-4">View</button>
                <button class="btn btn-light small d-inline-block more-btn">
                  <icon size="20px" class="mr-4" name="more_horiz" type="material" cuClass="icon-cu"></icon>
                </button>
                <nav class="action-dropdown position-absolute">
                  <ul>
                    <li class="withdraw">
                      <a href="javascript:void(0)">
                        <icon size="19px" name="person_remove" type="material" cuClass="d-flex"></icon>
                        Withdraw Candidate
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="float-left checkbox-wrapper mr-12 mt-12">
                <label class="checkbox-container">
                  <input type="checkbox" name="selectall">
                  <span class="checkmark"></span>
                </label>
              </div>
              <figure class="float-left user-avatar mr-12 text-center rounded-sm overflow-hidden">
                JD
              </figure>
              <p class="title float-left font-weight-semibold">Josephine Darakjy<br><span
                  class="d-inline-block rounded-sm position-relative font-weight-normal status">Submitted</span></p>
              <div class="updated-on float-right">
                <icon size="14px" class="mr-4" name="schedule" type="material" cuClass="icon-cu"></icon>
                1 Days ago
              </div>
              <div class="clearfix"></div>
              <div class="rates d-flex mt-32 mb-16">
                <p class="font-weight-medium">
                  $10
                  <span class="d-block font-weight-normal">Pref. Pay Rate</span>
                </p>
                <p class="font-weight-medium">
                  $20
                  <span class="d-block font-weight-normal">Est.Hours</span>
                </p>
                <p class="font-weight-medium">
                  $30
                  <span class="d-block font-weight-normal">Est.Pay</span>
                </p>
              </div>
            </div>
            <footer class="grid-footer p-16">
              <div class="float-left availability">
                <icon size="14px" class="mr-4" name="event" type="material" cuClass="icon-cu"></icon> 20/12/2020
              </div>
              <div class="match-value float-right font-weight-medium ml-8">
                40%
              </div>
              <div class="progress-wrap float-right">
                <span class="progress-value" style="width: 40%"></span>
              </div>
              <div class="clearfix"></div>
            </footer>
          </div>
          <div class="grid-item rounded-sm position-relative">
            <div class="p-16">
              <div class="grid-actions position-absolute">
                <button class="btn btn-secondary small d-inline-block mr-4">View</button>
                <button class="btn btn-light small d-inline-block more-btn">
                  <icon size="20px" class="mr-4" name="more_horiz" type="material" cuClass="icon-cu"></icon>
                </button>
                <nav class="action-dropdown position-absolute">
                  <ul>
                    <li class="withdraw">
                      <a href="javascript:void(0)">
                        <icon size="19px" name="person_remove" type="material" cuClass="d-flex"></icon>
                        Withdraw Candidate
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="float-left checkbox-wrapper mr-12 mt-12">
                <label class="checkbox-container">
                  <input type="checkbox" name="selectall">
                  <span class="checkmark"></span>
                </label>
              </div>
              <figure class="float-left user-avatar mr-12 text-center rounded-sm overflow-hidden">
                AV
              </figure>
              <p class="title float-left font-weight-semibold">Art Venere<br><span
                  class="d-inline-block rounded-sm position-relative font-weight-normal status">Submitted</span></p>
              <div class="updated-on float-right">
                <icon size="14px" class="mr-4" name="schedule" type="material" cuClass="icon-cu"></icon>
                1 Days ago
              </div>
              <div class="clearfix"></div>
              <div class="rates d-flex mt-32 mb-16">
                <p class="font-weight-medium">
                  $10
                  <span class="d-block font-weight-normal">Pref. Pay Rate</span>
                </p>
                <p class="font-weight-medium">
                  $20
                  <span class="d-block font-weight-normal">Est.Hours</span>
                </p>
                <p class="font-weight-medium">
                  $30
                  <span class="d-block font-weight-normal">Est.Pay</span>
                </p>
              </div>
            </div>
            <footer class="grid-footer p-16">
              <div class="float-left availability">
                <icon size="14px" class="mr-4" name="event" type="material" cuClass="icon-cu"></icon> 20/12/2020
              </div>
              <div class="match-value float-right font-weight-medium ml-8">
                20%
              </div>
              <div class="progress-wrap float-right">
                <span class="progress-value" style="width: 20%"></span>
              </div>
              <div class="clearfix"></div>
            </footer>
          </div>
          <div class="clearfix"></div>
        </section>
      </div>
      <div class="tab-content p-32" *ngIf="interviews">
        Interviews candidate
      </div>
    </div>
    <div class="other-sections" *ngIf="jobDetals">
      <div class="wrapper d-flex">
        <div class="card first bg-light mr-24 mb-24 p-32">
          <h3 class="section-title position-relative  mb-24">
            <icon class="icon-bg color-yellow mr-12" theme="outlined" size="16px" name="info" type="material"
              cuClass="icon-cu"></icon>
            Basic Info
          </h3>
          <div class="d-flex">
            <div class="info-item">
              <div class="d-flex mb-24">
                <p>Category</p>
                <p>Technology</p>
              </div>
              <div class="d-flex  mb-24">
                <p>Job Manager</p>
                <p>
                  <figure class="d-inline-block mr-8"><img src="../../../assets/images/5.jpg" alt=""></figure> Suresh
                  Gupta
                </p>
              </div>
              <div class="d-flex  mb-24">
                <p>Start Date</p>
                <p>14/12/2020</p>
              </div>
              <div class="d-flex">
                <p>Openings</p>
                <p>5</p>
              </div>
            </div>
            <div class="info-item">
              <div class="d-flex  mb-24">
                <p>Job level</p>
                <p>Expereienced</p>
              </div>
              <div class="d-flex  mb-24">
                <p>Assignment Length</p>
                <p>135 Days</p>
              </div>
              <div class="d-flex  mb-24">
                <p>End Date</p>
                <p>14/12/2021</p>
              </div>
              <div class="d-flex">
                <p>Job Type</p>
                <p>Contract</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card bg-light mb-24 p-32">
          <h3 class="section-title position-relative  mb-24">
            <icon class="icon-bg color-gray mr-12" theme="outlined" size="16px" name="inbox" type="material"
              cuClass="icon-cu"></icon>
            Foundational Data
          </h3>
          <div class="info-item">
            <div class="d-flex mb-24">
              <p>Cost Center</p>
              <p>CS001</p>
            </div>
            <div class="d-flex  mb-24">
              <p>GL Code</p>
              <p>
                GLC001
              </p>
            </div>
            <div class="d-flex  mb-24">
              <p>Foundational Data 1</p>
              <p>Custom Value</p>
            </div>
            <div class="d-flex">
              <p>Company Code</p>
              <p>USTC001</p>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper d-flex">
        <div class="card first bg-light mr-24 p-32">
          <h3 class="section-title position-relative  mb-24">
            <icon class="icon-bg color-pink mr-12" theme="outlined" size="16px" name="stars" type="material"
              cuClass="icon-cu"></icon>
            Qualifications
          </h3>
          <div class="d-flex mb-24">
            <div class="q-label">
              <p>Credentials</p>
            </div>
            <div class="q-value">
              <ul>
                <li>Adhar Card</li>
                <li>Driving Licence</li>
              </ul>
            </div>
          </div>
          <div class="d-flex">
            <div class="q-label">
              <p>Skills</p>
            </div>
            <div class="q-value">
              <ul>
                <li>Core Java</li>
                <li>Java Build Tools</li>
                <li>GitHub</li>
                <li>Code Version Control</li>
                <li>Java Server</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card bg-light p-32">
          <h3 class="section-title position-relative  mb-24">
            <icon class="icon-bg color-light-red mr-12" theme="outlined" size="16px" name="payments" type="material"
              cuClass="icon-cu"></icon>
            Financial Info
          </h3>
          <div class="info-item">
            <div class="d-flex mb-24">
              <p>Min Bill Rate</p>
              <p>$100.00</p>
            </div>
            <div class="d-flex  mb-24">
              <p>Max Bill Rate</p>
              <p>
                $140.00
              </p>
            </div>
            <div class="d-flex  mb-24">
              <p>Minimum budget</p>
              <p>$ 46,360.00</p>
            </div>
            <div class="d-flex">
              <p>Maximum Budget</p>
              <p>$ 56,160.00</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
