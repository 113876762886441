import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { StorageService } from 'src/app/core/services/storage.service';
import { UpdateFormRenderModel } from '../../form-renderer.model';
import { FormRendererService } from '../../form-renderer.service';

@Component({
  selector: 'app-field-handler',
  templateUrl: './field-handler.component.html',
  styleUrls: ['./field-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldHandlerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() field;
  @Input() renderForm: FormGroup;
  @Input() is_continue = false;
  @Input() is_update = false
  @Input() updateConfig: UpdateFormRenderModel[];
  @Input() updateValue: any;
  @Input() errorMassage: any;
  @Input() candidateId: string;
  @Input() dateFieldDisable = [];
  @Input() hasActiveAssignment: boolean;

  @Output() onClickHierarchy = new EventEmitter();
  @Output() onDataChange = new EventEmitter();
  @Output() onClickCandidateView = new EventEmitter();
  @Output() onClickVendorView = new EventEmitter();
  @Output() onBtnClick = new EventEmitter();
  @Output() selectedHierarchy = new EventEmitter();


  searchSubscription: Subscription;
  hierarchyNameSubscription: Subscription;

  url: string = ''
  selectedHierarchyName: string = ''
  dropDownList = []
  isLoading = false;
  programId = ''
  is_selected = false
  dateValue = ''

  selectedDta: any = {}
  items = [
    { label: "Yes", value: true },
    { label: "No", value: false }
  ];

  currencySym = '₹'
  currencyData = [
    { key: 'usd', value: '$' },
    { key: 'inr', value: '₹' },
    { key: 'eur', value: '€' }
  ]

  country = { 'US': 'USA', 'CA': 'Canada', 'IN': 'India', 'USA': 'USA', 'CAD': 'Canada', 'IND': 'India' }
  selectedCandidate;
  assignmentManagerId: string = '';
  assignmentManagerName: string = '';
  constructor(private fb: FormBuilder,
    private _formRendererService: FormRendererService,
    private _storageService: StorageService,
    private datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.url = this.field?.datasource?.url
    let programDetails = this._storageService.get('CurrentProgram');
    if (programDetails) {
      this.programId = programDetails['id'];
    }
    if (!this.is_update) {
      let user_type = this._storageService.get('user_type')
      if (user_type === 'CLIENT') {
        let account = this._storageService.get('account')
        if (account) {
          this.assignmentManagerId = account?.id
          this.assignmentManagerName = account?.full_name
        }
      }
    }
  }

  ngOnChanges() {
    if ((this._storageService.get(this.field?.slug) !== undefined) && (this._storageService.get(this.field?.slug) !== null)) {
      this.dropDownList = this._storageService.get(this.field?.slug)
    }
    if (this.field?.type === 'HIERARCHY') {
      if (this.hierarchyNameSubscription) {
        this.hierarchyNameSubscription.unsubscribe()
      }
      this.hierarchyNameSubscription = this._formRendererService.getHierarchyName().subscribe(data => {
        this.selectedHierarchyName = data
        this.changeDetectorRef.detectChanges()
      })
      if (this.renderForm.get(this.field?.slug).value) {
        this.selectedHierarchy.emit(this.renderForm.get(this.field?.slug).value)
      }
    } else if (this.field?.type === 'CANDIDATE_DROPDOWN') {
      if (this.candidateId) {
        this.selectedCandidate = this.candidateId
        let tempCandidateList = this._storageService.get(this.field?.slug)
        this.dropDownList = tempCandidateList
      }
      this.onSelect(this.renderForm.get(this.field?.slug).value, false, true)
    } else if (this.field?.type === 'VENDOR_DROPDOWN') {
      this.onSelect(this.renderForm.get(this.field?.slug).value)
    } else if (this.field?.type === 'CURRENCY') {

      this.field?.meta_data?.rules.forEach(element => {
        if (element?.condition === 'currency') {
          this.currencyData.forEach(c => {
            if (c.key === this.renderForm.get(element?.name).value) {
              this._formRendererService.setCurrencySymbol(c.value)
              this._formRendererService.setCurrency((c.key).toUpperCase())
            }
          })
        }
      });
    }
  }

  ngAfterViewInit() {
    this.renderForm.get(this.field?.slug).valueChanges.subscribe(value => {
      if (this.field?.type === 'DATE') {
        this.dateValue = value
      } else if (this.field?.type === 'DROPDOWN') {
        this.currencyData.forEach(c => {
          if (c.key === value) {
            this._formRendererService.setCurrencySymbol(c.value)
            this._formRendererService.setCurrency((c.key).toUpperCase())
          }
        })
      } else if (this.field?.type === 'VENDOR_DROPDOWN') {
        if (this.renderForm.get(this.field?.slug).value) {
          this.dropDownList = this._storageService.get(this.field?.slug)
          this.onSelect(this.renderForm.get(this.field?.slug).value)
          this.is_selected = true
        }
      }
      this.onDataChange.emit({ value, slug: this.field?.slug })
    })
    this._formRendererService.getCurrencySymbol().subscribe(data => {
      this.currencySym = data;
      this.changeDetectorRef.detectChanges();
    })

    let fieldValue = this.renderForm.get(this.field?.slug).value
    // if ((fieldValue === null) || (fieldValue === undefined) || this.is_update) {
    this.getDropDownData({ term: '' })
    // }

    if ((this.field?.datasource?.type !== 'url') && (this.field?.datasource?.options?.length === 1)) {
      this.renderForm.controls[this.field?.slug].setValue(this.field?.datasource?.options[0][this.field?.bind_value], { emitEvent: false })
    }

    this.setDataToDropDown()
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  setDataToDropDown() {
    this.renderForm.valueChanges.subscribe(value => {
      if (this.field?.meta_data?.rules?.length > 0) {
        this.field?.meta_data?.rules?.forEach(rule => {
          if (rule?.condition === 'set_value') {
            if ((rule?.slug in value) && (value[rule?.slug] !== null)) {
              const ruleSlugData = this._storageService.get(rule?.slug)
              if (ruleSlugData) {
                let result = this.getDataByKey(ruleSlugData, rule?.slug_value, value[rule?.slug])
                let id = this.getDataByKeyArray(result, rule?.value_get_by)
                if ((this.field?.type === 'DROPDOWN') || (this.field?.type === 'PERSON_DROPDOWN') || (this.field?.type === 'CANDIDATE_DROPDOWN') || (this.field?.type === 'VENDOR_DROPDOWN') || (this.field?.type === 'HUMAN_DROPDOWN') || (this.field?.type === 'JOB_DROPDOWN')) {
                  let label = this.getDataByKeyArray(result, rule?.label_get_by)
                  if (!this.dropDownList.some(e => e[this.field?.bind_value] === id) && id && label) {
                    this.dropDownList.push({ [this.field?.bind_value]: id, [this.field?.bind_label]: label })
                    this._storageService.set(this.field?.slug, this.dropDownList)
                    this.changeDetectorRef.detectChanges();
                  }
                }
              }
            }
          }
        });
      }
    })
  }

  getDataByKey(updateData, key: string, value: string) {
    if (updateData.hasOwnProperty(key) && updateData[key] === value) {
      return updateData;
    } else {
      for (let i = 0; i < Object.keys(updateData).length; i++) {
        let data = updateData[Object.keys(updateData)[i]];
        if (typeof data === "object" && data !== null) {
          let result = this.getDataByKey(data, key, value);
          if (result) return result;
        }
      }
    }
  }

  getDataByKeyArray(data, keyArray) {
    if (data) {
      for (let key of keyArray) {
        data = data[key]
      }
      return data
    }
  }

  onClickhierarchy() {
    // console.log(this.is_update, !this.field?.is_update_allow)
    if (this.is_update && !this.field?.is_update_allow) {
      return;
    }
    this.onClickHierarchy.emit()
  }

  getDropDownData(event) {
    if ((this.url !== '') && (this.url !== undefined) && ((this.field?.type === 'DROPDOWN') || (this.field?.type === 'PERSON_DROPDOWN') || (this.field?.type === 'CANDIDATE_DROPDOWN') || (this.field?.type === 'VENDOR_DROPDOWN') || (this.field?.type === 'HUMAN_DROPDOWN') || (this.field?.type === 'JOB_DROPDOWN'))) {
      if (event?.term !== '' && event?.term.length < 2) {
        return
      }
      this.isLoading = true
      this.url = this.url.replace('${programId}', this.programId)
      if (this.searchSubscription) {
        this.searchSubscription.unsubscribe();
      }
      this.searchSubscription = this._formRendererService.get(this.url + event?.term).subscribe((data: any[]) => {
        if (data) {
          this.field?.datasource?.getBy.forEach(d => {
            data = data[d]
          });
          this.dropDownList = data
          if (this.is_update) {
            if ((this.field?.type === 'DROPDOWN') || (this.field?.type === 'PERSON_DROPDOWN') || (this.field?.type === 'CANDIDATE_DROPDOWN') || (this.field?.type === 'VENDOR_DROPDOWN') || (this.field?.type === 'HUMAN_DROPDOWN') || (this.field?.type === 'JOB_DROPDOWN')) {
              const fieldData = this.updateConfig.find(a => a.fieldName === this.field?.slug)
              if ((this.dropDownList.findIndex(a => a[this.field?.bind_value] === fieldData?.value) === -1)) {
                this.dropDownList.push({ [this.field?.bind_value]: fieldData.value, [this.field?.bind_label]: fieldData.label ? fieldData.label : fieldData.value })
              }
              this._storageService.set(this.field?.slug, this.dropDownList)
            }
          } else {
            if ((this.field?.slug === 'assignment_manager') || (this.field?.slug === 'timesheet_manager')) {
              if ((this.dropDownList.findIndex(a => a[this.field?.bind_value] === this.assignmentManagerId) === -1)) {
                this.dropDownList.push({ [this.field?.bind_value]: this.assignmentManagerId, [this.field?.bind_label]: this.assignmentManagerName })
              }
              if ((this.renderForm.get(this.field?.slug).value === null) || this.renderForm.get(this.field?.slug).value === undefined) {
                this.renderForm.controls[this.field?.slug].setValue(this.assignmentManagerId, { emitEvent: false })
              }
            }
            this._storageService.set(this.field?.slug, this.dropDownList)
            if (this.dropDownList?.length === 1) {
              this.renderForm.controls[this.field?.slug].setValue(this.dropDownList[0][this.field?.bind_value], { emitEvent: false })
            }
          }
          this.isLoading = false
          this.changeDetectorRef.detectChanges()
        }
      }, err => {
        this.isLoading = false
      })
    }
  }

  getLabel() {
    if (this.is_update) {
      if (this.updateConfig?.length > 0) {

        for (let j = 0; j < this.updateConfig?.length; j++) {
          const data = this.updateConfig[j]
          if (data?.fieldName === this.field?.slug) {
            let value = this.updateValue;
            if (data?.label && (typeof data?.label === 'string')) {
              const keyList = data?.label.split('.');
              for (let i = 0; i < keyList.length; i++) {
                if (value) {
                  value = value[keyList[i]]
                }
              }
              return value
            }
          }
        }
      }
      // console.log(this.finalUpdateData)
    }
  }

  onSelect(event, is_candidate = false, on_start = false) {
    const bindKeyList = this.field?.bind_value.split('.')
    if (this.dropDownList) {
      this.dropDownList.forEach(data => {
        let tempData = data;
        for (let key of bindKeyList) {
          if (tempData) {
            tempData = tempData[key]
          }
        }
        if (tempData === event) {
          this.selectedDta = data
          this.is_selected = true
        }
      })
      this.changeDetectorRef.detectChanges()
    }
    // console.log(this.selectedDta)
    if (is_candidate) {
      this.renderForm.get(this.field?.slug).setValue(this.selectedCandidate)
    }

    if (on_start) {
      this.selectedCandidate = this.renderForm.get(this.field?.slug).value
    }
  }

  onSelectVendor(event) {
    this.selectedDta = event
    this.is_selected = true
    // if (this.dropDownList) {
    //   this.dropDownList.forEach(data => {
    //     if (data[this.field?.bind_value] === event[this.field?.bind_value]) {
    //       this.selectedDta = data
    //       console.log(data)
    //       this.is_selected = true
    //     }
    //   })
    // }
  }

  onCandidateView() {
    this.onClickCandidateView.emit(this.selectedDta?.id)
  }

  onVendorClick() {
    this.onClickVendorView.emit(this.selectedDta?.id)
  }

  onBtnClickEvent(slug) {
    this.onBtnClick.emit(slug)
  }

  onCandidate() {
    this.selectedDta = undefined
    this.is_selected = false
  }

}
