<div class="page-container">
  <app-program-setup-sidebar></app-program-setup-sidebar>
  <div class="content">
    <app-program-setup-header (notificationClick)="notificationEventHandlerFunction($event)">
    </app-program-setup-header>
    <app-insight [hidden]="!isInsightVisible"></app-insight>
    <router-outlet></router-outlet>
  </div>
</div>

<div class="notification-panel {{valueEmittedFromHeaderComponent}}">
  <app-notification (notifyClose)="notificationEventHandlerFunction($event)"></app-notification>
</div>
