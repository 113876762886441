import { Component, OnInit } from '@angular/core';
import { RouterEvent, Router } from '@angular/router';

@Component({
  selector: 'app-no-program',
  templateUrl: './no-program.component.html',
  styleUrls: ['./no-program.component.scss']
})
export class NoProgramComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  onclickButton(){
    this.router.navigateByUrl('/programs/create');
  }

}
