import { Component, OnInit, Input } from '@angular/core';
import { FullCalendarOptions, EventObject } from 'ngx-fullcalendar';

@Component({
  selector: 'app-calendars',
  templateUrl: './calendars.component.html',
  styleUrls: ['./calendars.component.scss']
})
export class CalendarsComponent implements OnInit {
  options: FullCalendarOptions;
  events: EventObject[];
  @Input() grid;
  constructor() { }

  ngOnInit(): void {
    let defaultDate = new Date();
    this.options = {
      defaultDate: defaultDate,
      editable: true
    };
    console.log('grid is here cmameee', this.grid);
 
    this.events = [
      { id: 'a', title: 'My Birthday', allDay: true },
      { id: 'b', title: 'Interview', start: '2020-12-24T18:00:00', end: '2020-12-25T23:00:00' }
    ]

    const serializedData = [
      {x: 0, y: 0, w: 2, h: 2},
      {x: 2, y: 3, w: 3, content: 'item 444'},
      {x: 1, y: 3}
    ];
    this.grid.addWidget({w: 2, content: 'item 1'});
    
    // this.grid.load(serializedData);
  }

}
