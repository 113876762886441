import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { EmitEvent, Events, EventStreamService } from 'src/app/core/services/event-stream.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit {
  @Output()
  toggle: EventEmitter<string> = new EventEmitter<string>();
  notifyCloseText = "active";
  @Input('programs') programs: any = [];
  selectedProgram = '';

  constructor(
    private streamService: EventStreamService,
    private storageService: StorageService,
    private userService: UserService) { }

  ngOnInit(): void {
    const activeProgram = this.storageService.get('CurrentProgram');
    this.selectedProgram = activeProgram?.id;
    if (!this.programs || this.programs.length == 0) {
      this.getMyPrograms();
    }
  }

  closeSidebar() {
    this.streamService.emit(new EmitEvent(Events.PROGRAM_SIDEBAR, false));
  }

  getMyPrograms() {
    this.userService.getAllPrograms().subscribe(data => {
      this.programs = data.programs;
      let currentProgram = this.storageService.get('CurrentProgram');
      if (currentProgram === null || currentProgram === undefined) {
        if (this.programs && this.programs.length > 0) {
          this.storageService.set('CurrentProgram', this.programs[0], true);
        }
      }
    }, err => {
      console.log('error ', err);
    })
  }

  selectProgram(p) {
    this.selectedProgram = p.id;
    this.storageService.set('CurrentProgram', p, true);
    this.streamService.emit(new EmitEvent(Events.PROGRAM_SIDEBAR, false));
    window.location.reload();
  }
}
