<div class="content-area-topHead" id="hideForNoOrganization" *ngIf="!isNoOrg">
    <div class="topHead--left">
        <ul class="breadcrumb-list">
            <li class="breadcrumb-listItem">
                <a href="#"> {{mainmenuName}} </a>
            </li>
            <li class="breadcrumb-listItem" *ngIf="!isButtonhide">
                <button type="button" class="btn btn-red ml-3" (click)="onCreateClick()">
                  <icon name="add_circle" type="material" class="icon--onLeft" cuClass="icon-cu"></icon>
                  {{submenuName}}
              </button>
            </li>
        </ul>
    </div>
    <div class="topHead--right" *ngIf="!isRightpanel">
        <ul class="listing-filter">
            <li>
                <a href="" class="filter-item">
                    <icon size="17px" name="search" type="material" cuClass="d-flex"></icon>
                </a>
            </li>
            <li>
                <a href="" class="filter-item">
                    <icon size="17px" name="settings" type="material" cuClass="d-flex"></icon>
                </a>
            </li>
            <li>
                <a href="" class="filter-item">
                    <icon size="17px" name="filter_alt" type="material" cuClass="d-flex"></icon>
                </a>
            </li>
            <li>
                <!-- Top Pagination -->
                <div class="pagination">
                    <a href="" class="mr-1">
                        <icon size="20px" name="keyboard_arrow_left" type="material" cuClass="d-flex"></icon>
                    </a>
                    <span>1</span>
                    <span>&#x0002F;</span>
                    <span>10</span>
                    <a href="" class="ml-1">
                        <icon size="20px" size="20px" name="keyboard_arrow_right" type="material" cuClass="d-flex"></icon>
                    </a>
                </div>
            </li>
        </ul>
    </div>
