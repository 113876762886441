import { Component, OnInit, Input, HostListener, Output, EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { CandidateService } from '../../service/candidate.service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {
  @Input() uploadLabel;
  @Output() uploadDataFiles: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('uploadFileInput') uploadFileInput: ElementRef;
  @Input() private closePanel: EventEmitter<boolean>;
  fileSize: any;
  uploadedFileArr = [];
  formattedUploadArr = [];
  fileNotSupportAlert = false;  
  isValidFileSize = true;
  mbKbFileSize = '';
  isImageFile = true;
  constructor(private candidateService : CandidateService) { }
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  @HostListener("drop", ["$event"]) ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.uploadFile(evt);
  }
  ngOnInit(): void {
    if (this.closePanel) {
      this.closePanel.subscribe(data => {
        this.fileNotSupportAlert = false;  
        this.fileSize = '';
        this.isValidFileSize = true;
        this.uploadFileInput.nativeElement.value = '';
        this.uploadedFileArr = [];
      });
    }
  }
  uploadFile(event: any): void {
    if(event){
      this.fileNotSupportAlert = false;
      let fileEvent = event.target?.files ? event.target.files : event.dataTransfer.files;
      let fileExtension = fileEvent[0].name.split('.').pop();
      if(fileExtension == 'pdf' || fileExtension == 'doc' || fileExtension == 'docx' || fileExtension == 'png' || fileExtension == 'jpg' || fileExtension == 'jpeg'){
        const fsize = fileEvent[0].size; 
        let fileData = fileEvent[0];
        let upload = {};
        upload['name'] = fileData.name;
        upload['time'] = Date.now();
        upload['ext'] = fileData.name.split('.').pop();
        if(fileExtension == 'png' || fileExtension == 'jpg' || fileExtension == 'jpeg'){
          this.isImageFile = true;
        }else{
          this.isImageFile = false;
        }
        // file in KB
        if( fsize < 1000000){
          this.isValidFileSize = true;
          this.mbKbFileSize = 'KB';
          this.fileSize = Math.floor(fsize/1000);
          upload['size'] = this.fileSize;    
          //file to base64
          this.candidateService.encodeToBase64(fileData)
          .then((data) => {
            const fileD = String(data);
            upload['raw'] = data;
            this.uploadedFileArr.push(upload);
            this.formattedUploadArr.push({name:upload['name'],raw:upload['raw']});
            this.uploadDataFiles.emit(this.formattedUploadArr);
          }).catch((err) => {
            console.log("FileError", err)
          });
        }else {
          //file in MB
          this.mbKbFileSize = 'MB';
          this.fileSize = (fsize / (1024*1024)).toFixed(2);
          // if its a image file -> should be less than 3MB
          if(fileExtension == 'png' || fileExtension == 'jpg' || fileExtension == 'jpeg'){ 
            if(this.fileSize <= 3){
              this.isValidFileSize = true;
              //file to base64
              this.candidateService.encodeToBase64(fileData)
              .then((data) => {
                const fileD = String(data);
                upload['raw'] = data;
                this.uploadedFileArr.push(upload);
                this.formattedUploadArr.push({name:upload['name'],raw:upload['raw']});
                this.uploadDataFiles.emit(this.formattedUploadArr);
              }).catch((err) => {
                console.log("FileError", err)
              });
            } else{
              this.isValidFileSize = false;
            }
          }else{ 
             // if its a doc file -> should be less than 10MB
            if(this.fileSize <= 10){
              this.isValidFileSize = true;
              //file to base64
              this.candidateService.encodeToBase64(fileData)
              .then((data) => {
                const fileD = String(data);
                upload['raw'] = data;
                this.uploadedFileArr.push(upload);
                this.formattedUploadArr.push({name:upload['name'],raw:upload['raw']});
                this.uploadDataFiles.emit(this.formattedUploadArr);
              }).catch((err) => {
                console.log("FileError", err)
              });
            } else{
              this.isValidFileSize = false;
            }
          }
        }
      } else{
        this.fileNotSupportAlert = true;
      }
    }
  }
  deleteFile(index){
    this.fileSize = '';
    this.isValidFileSize = true;
    this.uploadFileInput.nativeElement.value = '';
    this.uploadedFileArr.splice(index,1);
    this.formattedUploadArr.splice(index,1);
  }
}
