<div class="page-container p-24">
  <h2 class="mb-16">Available Widgets <span class="float-right pr-24"> <i class="material-icons cursor-pointer"
        (click)=hideWidget()>cancel</i></span></h2>
  <div class="d-flex">
    <div class="width_73">
      <div>
        <ul class="d-flex tab-content-wrapper ">
          <li (click)="viewMode ='tab1'"><span class="px-16 py-8" [ngClass]="{'active':viewMode =='tab1'}">
              Quick Links
            </span>
          </li>
          <li (click)="viewMode ='tab2'"><span class="px-16 py-8" [ngClass]="{'active':viewMode =='tab2'}"><i
                class="fa fa-folder"></i>List Widgets</span>

          </li>
          <li (click)="viewMode ='tab3'"><span class="px-16 py-8" [ngClass]="{'active':viewMode =='tab3'}">Charts</span>
          </li>
          <li (click)="viewMode ='tab4'"> <span class="px-16 py-8"
              [ngClass]="{'active':viewMode =='tab4'}">Calenders</span>
          </li>
        </ul>

        <div [ngSwitch]="viewMode">
          <div *ngSwitchCase="'tab1'">
            <app-quick-links></app-quick-links>
          </div>
          <div *ngSwitchCase="'tab2'">
            <app-list-widget></app-list-widget>
          </div>
          <div *ngSwitchCase="'tab3'">
            <app-charts></app-charts>
          </div>
          <div *ngSwitchCase="'tab4'">
            <app-calendars></app-calendars>
          </div>
        </div>
      </div>
    </div>
    <div class=" p-0 width_26">
      <div class="widget_add_details">
        <div class="widget_info">
          <div class="widgetInfoHead"><i class="material-icons widgetinfoheadicon">link</i>
            <h3>Spend Analytics</h3>
            <p class="font-weight-medium">Spend Analytics Widget</p>
          </div>
          <div class="widget-options">
            <div class="form-row p-8"><span class="">Enter Spend Analytics Label</span>
              <input type="text" class=" form-control" placeholder="Spend Analytics">
            </div>
          </div>
          <div class="widgetinfoFooter p-16"><button type="submit" class="btn btn-primary">Add To
              Dashboard</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
