<div class="page-container">
    <div class="counter">
         <div class="d-flex align-items-center justify-content-start countetitle" ><i class="material-icons pt-2 mr-8">signal_cellular_alt</i> Pending Actions</div>
        <div class="p-12 border-bottom" *ngFor="let p of pending">
            <h4 class="mb-0 text-primary font-weight-bold">{{p.count}}</h4>
            <div class="d-flex align-items-center" >
                <div>{{p.name}}</div>
                <div class="ml-auto"><i class="material-icons text-primary">notification_important</i></div>
            </div>
        </div>
    </div>
</div>