<div class="page-title p-24" I18n>
  Program Details
</div>

<div class="row no-gutters">
  <div class="col-5 pl-24">
    
    <div class="panel panel-light mb-24" *ngIf="showCustomPanel && dataLoad">
      <div class="panel-head py-16 border-bottom d-flex align-items-center justify-content-between">
        <h5 I18n>
          {{programName}}
        </h5>
        <button class="btn btn-outline-secondary small" (click)="onEditBtnClick()" I18n>Edit</button>
      </div>
      <div class="panel-content">
        <div class="d-flex align-items-center mt-16">
          <label class="w-50">Status</label>
          <div class="status-dropdown">
            <div class="status d-flex align-justify-center rounded-sm cursor-pointer px-8"  [ngClass]="{'status-active':is_enabled,'status-inactive':!is_enabled}">
              <icon size="8px" name="stop_circle" type="material" cuClass="circle d-flex mr-4">
              </icon> <span I18n *ngIf="is_enabled">Active</span>
              <span I18n *ngIf="!is_enabled">Inactive</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-16">
          <label class="w-50" I18n>Program Name</label>
          <span class="w-50" I18n>{{programName}}</span>
        </div>
        <div class="d-flex align-items-center mt-16">
          <label class="w-50" I18n>Program ID</label>
          <span class="w-50">{{uniqueId}}</span>
        </div>
        <div class="d-flex align-items-center mt-16">
          <label class="w-50">Program Type</label>
          <span class="w-50">{{service_type}}</span>
        </div>
        <div class="d-flex align-items-center mt-16" *ngIf="msp">
          <label class="w-50" I18n>MSP</label>
          <span class="w-50" I18n>{{msp}}</span>
        </div>
        <div class="d-flex align-items-center mt-16">
          <label class="w-50" I18n>Effective Date</label>
          <span class="w-50" I18n>{{start_date}}</span>

        </div>
      </div>
    </div>
    
    <div class="panel panel-light mb-24 " *ngIf="!showCustomPanel">
      <div class="panel-head py-16 border-bottom d-flex align-items-center justify-content-between">
        <h5 I18n>
          {{programName}}
        </h5>
        <div class="d-flex align-items-center">
          <button class="btn btn-outline-secondary small mr-12" (click)="onCancelBtnClick()" I18n>Cancel</button>
          <button class="btn btn-primary small" (click)="onSaveBtnClick()" I18n>Save </button>
        </div>

      </div>
      <div class="panel-content">
        <form [formGroup]="editProgramForm">
        <div class="d-flex align-items-center mt-16">
          <label class="w-50">Status </label>
          <div class="status-dropdown position-relative" (click)="onClickDropdwon()">
            <div class="status d-flex align-justify-center rounded-sm cursor-pointer px-8" [ngClass]="{'status-active':programStatus,'status-inactive':!programStatus}">
              <icon size="8px" class="d-flex" name="stop_circle" type="material" cuClass="circle d-flex mr-4 ">
              </icon> 
              <span I18n *ngIf="programStatus">Active</span>
              <span I18n *ngIf="!programStatus">Inactive</span>
              <icon size="16px" class="d-flex" name="expand_more" type="material" cuClass="circle d-flex ml-12">
              </icon>
            </div>
            <div class="dropdown" *ngIf="showDropdwon">
              
              <ul class="pl-0 ml-0">
                <li class="pb-16 cursor-pointer" I18n (click)="selectstatus(true)">
                  Active
                </li>
                <li class="cursor-pointer" I18n (click)="selectstatus(false)">Inactive</li>
              </ul>
            </div>

          </div>
        </div>
        <div class="d-flex align-items-center mt-16">
          <label class="w-50" I18n>Program Name</label>
          <div class="w-50">
            <input type="text" class="form-control" value="" formControlName="name" required [ngClass]="{'error':
            editProgramForm.controls['name'].invalid && (editProgramForm.controls['name'].dirty || editProgramForm.controls['name'].touched)
              }" minlength="5">
          <p class="validation-msg"
            *ngIf="editProgramForm.controls.name.touched && editProgramForm.controls['name'].invalid && editProgramForm.controls['name'].errors?.required">
            <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Program Name is
            Required
          </p>
          <p class="validation-msg" *ngIf="editProgramForm.controls.name.errors?.minlength">
            Name must be at least 5 characters long.
          </p>
          <p class="validation-msg" *ngIf="duplicateProgram">{{duplicateProgram}}</p>

          </div>
        </div>
        <div class="d-flex align-items-center mt-16">
          <label class="w-50" I18n>Program ID</label>
          <div class="w-50">
            <input type="text" class="form-control" value="" formControlName= "programID"  readonly>
          </div>

        </div>
        <div class="d-flex align-items-center mt-16">
          <label class="w-50" I18n>Program Type </label>
          <div class="w-50">
            <ng-select bindLabel="name" placeholder="Choose Program Type" formControlName="programType" I18n required (change)="changeService_Type()">
              <ng-option [value]="'SELF-SERVICED'">SELF-SERVICED</ng-option>
              <ng-option [value]="'MSP-MANAGED'">MSP-MANAGED</ng-option>
            </ng-select>
            <p class="validation-msg"
                *ngIf="editProgramForm.controls.programType.touched && editProgramForm.controls['programType'].errors">
                <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Program Type is
                Required
              </p>
          </div>

        </div>
      
        <div class="d-flex align-items-center mt-16" *ngIf="editProgramForm.get('programType').value && editProgramForm.get('programType').value == 'MSP-MANAGED'">
          <label class="w-50" I18n>MSP</label>
          <div class="w-50">
            <ng-select bindLabel="name" placeholder="MSP" formControlName="msp" I18n required [typeahead]="mspInput$">
              <ng-option [value]="msp?.uuid" *ngFor="let msp of mspList">{{msp?.name}}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="d-flex align-items-center mt-16">
          <label class="w-50" I18n>Effective Date</label>
          <div class="w-50 edit-date">
            <svms-datepicker [inputdateFormat]="" id="date1" I18n formControlName="date" #dateFiled>
            </svms-datepicker>
          </div>
        </div>
      </form>
      </div>
    </div>
    <div class="panel panel-light p-16">
      <div class="d-flex align-items-center">
        <div class="w-50 pb-12 pr-12 pl-0 d-flex align-items-start justify-content-start" *ngIf="client_logo">
          <!-- <img [src]="../../../assets/images/Group_2.svg" width="50" alt="" /> -->
          <span class="p-8 border rounded-sm">{{ client_logo | shortName: 'first'}}</span>
        </div>
        <div class="d-flex flex-column w-50 ml-4">
          <h4 I18n>{{clientName}}</h4>
          <div class="icons" *ngIf="false">
            <a href="">
              <icon size="18px" name="language" type="material"></icon>
            </a>
            <a [href]="email" class="ml-8">
              <icon size="18px" name="mail" type="material"></icon>
            </a>
          </div>
        </div>
      </div>
      <div class="mt-24">
        <div class="d-flex align-items-center mt-8">
          <label class="w-50" I18n>Industry</label>
          <span class="w-50" I18n>{{industry}}</span>
        </div>
        <div class="d-flex align-items-center mt-8">
          <label class="w-50" I18n>Org. Type</label>
          <span class="w-50" I18n>{{category}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-7 px-24 ">
    <div class="panel panel-light mb-24 pt-0 p-16 tabs h-100 detail-tab">
      <vms-tab-group>
        <vms-tab label="Program Members" iconLeft="people_alt">
          <app-program-manage [edit]="editData" (showCreateUser)="addUserToProgram($event)"></app-program-manage>
        </vms-tab>

        <vms-tab label="Modules" iconLeft="category">
          <app-modules [moduleData]="moduleGroup" (moduleDataChange)="onClickModule($event)"></app-modules>
        </vms-tab>
      </vms-tab-group>
    </div>

  </div>
</div>
<app-create-new-user [showUserCreate]="showUserCreate"></app-create-new-user>