import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})

export class StorageService {

  private storage: any = {};
  constructor(private _cookie: CookieService) { }

  get(key) {
    let data = this.storage[key];
    if (!data) {
      let d = localStorage.getItem(key);
      if (d || d !==undefined) {
        if(d !='undefined'){
          data = JSON.parse(d);
        }
      }
    }
    return data;
  }

  set(key: string, data: any, storeInLocalStorage?: boolean) {
    this.storage[key] = data;
    if (storeInLocalStorage) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  remove(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
    }
    return delete this.storage[key];
  }

  setWithExpiry(key, value, ttl) {
    let now = new Date()
    let item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }

  setCookie(key: string, data: any, storeInLocalStorage?: boolean) {
    let cookieData;
    if (storeInLocalStorage) {
      this._cookie.set(key, data);
    }
  }

  getCookie(key) {
    let data = this._cookie.check(key);
    if (data) {
      return this._cookie.get(key);
    }
    else return data;
  }

  clear() {
    localStorage.clear();
  }

}
export enum StorageKeys { 
  CURRENT_USER = 'user',
  USER_TYPE = 'user_type',
  CURRENT_PROGRAM = 'CurrentProgram',
}
