import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { FormRendererModule } from '../library/form-renderer/form-renderer.module';

import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgramsModule } from '../programs/programs.module';
import { OrganizationsModule } from '../organizations/organizations.module';
import { FormBuilderModule } from 'src/app/library/form-builder/form-builder.module';
import { JobsModule } from '../jobs/jobs.module';
import { QualificationModule } from 'src/app/qualification/qualification.module';
import { CandidateModule } from '../candidate/candidate.module';
import { ReportsModule } from '../reports/reports.module';

// import { VendorManagmentModule } from '../vendor-managment/vendor-managment.module';

@NgModule({
  declarations: [MainComponent, DashboardComponent],
  imports: [CommonModule, FormRendererModule, MainRoutingModule, SharedModule, ProgramsModule, OrganizationsModule, FormBuilderModule, QualificationModule, CandidateModule, ReportsModule],
  exports: [JobsModule]
})
export class MainModule { }
