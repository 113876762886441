<div id="sidebar" class="sidebar float-left position-fixed">
  <div class="sidebar-inner pt-16 pb-64">
    <div class="logo mb-32 ml-16 d-flex align-items-center position-fixed">
      <div *ngIf="client_logo" class="program-name-initials d-flex align-justify-center rounded-sm mr-8 subtitle-1 ">
        {{client_logo | shortName: 'last'}}</div>
      <div class="d-flex flex-column">
        <span class="subtitle-2 font-weight-medium">{{client_name}}</span>
        <span *ngIf="client_status" class="status d-flex align-justify-center rounded-sm"
          [ngClass]="{'status-active':client_status}">
          <icon size="8px" name="stop_circle" type="material" cuClass="circle d-flex mr-4">
          </icon> Active
        </span>
        <span *ngIf="!client_status" class="status d-flex align-justify-center rounded-sm"
          [ngClass]="{'status-inactive':!client_status}">
          <icon size="8px" name="stop_circle" type="material" cuClass="circle d-flex mr-4">
          </icon> Inactive
        </span>
      </div>
    </div>
    <div class="prog-nav-outer">
      <ul class="main-nav p-0">
        <li *ngFor="let sideBar of sideBarData" class="mt-4 mb-4 ml-0 mr-0">
          <!--For no submenu-->
          <a *authorize="sideBar?.permission" [hidden]="sideBar?.isSideMenu" [routerLink]="sideBar?.path"
            class="pl-16 position-relative">
            <icon [cuClass]="sideBar.class" theme="outlined" [name]="sideBar.icon" size="20px" type="material"
              class="float-left mr-16"></icon>
            <label I18n class="m-0">{{sideBar?.title}}</label>
          </a>

        </li>
      </ul>
      <p class="program-config-title x-small d-flex align-items-center pl-16">
        Program Configuration
      </p>
      <!--For submenu-->
      <ul class="main-nav m-0 p-0">
        <li *ngFor="let sideBar of sideBarData" class="mt-4 mb-4 ml-0 mr-0">
          <!--For submenu-->
          <a *authorize="sideBar?.permission" [hidden]="!sideBar?.isSideMenu" class="nav-item pl-16 position-relative"
            (click)="openSideMenu(sideBar?.name, true)" [ngClass]="{'active': isActive === sideBar?.name}">
            <icon [cuClass]="sideBar.class" theme="outlined" [name]="sideBar.icon" size="20px" type="material"
              class="float-left mr-16"></icon>
            <label I18n class="m-0">{{sideBar?.title}}</label>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="user-settings" [ngStyle]="{'z-index':addZindexClass? '2' : '5' }">
  <a href="javascript:void(0)" (click)="openSideMenu('user', true)" [ngClass]="{'active': isToggled === 'user' }">
    <figure class="rounded-circle mb-0 text-center position-relative mb-0 text-center initials__green">
      <!-- <img src="../../../../assets/images/user-profile.png" alt="" class="rounded-circle"> -->
      <!-- {{userAvatar |shortName: 'last'}} -->
      {{user?.first_name |shortName: 'last'}}
      <span class="availability rounded-circle online"></span>
    </figure>
    <div class="current-user-info float-right">
      <span class="name d-block overflow-hidden">{{user?.first_name}}</span>
      <p class="m-0 overflow-hidden">{{user?.role?.name || 'Member'}}</p>
    </div>
  </a>
</div>

<div class="sidenav position-fixed overflow-auto" [ngClass]="{'sidenav-active': isToggled === sideBar?.name}">
  <div class="nav-title overflow-hidden pt-8 pr-16 pb-8 pl-24">
    <h4 class="float-left m-0">{{sideBar?.closeSubMenu?.title}}</h4>
    <a href="javascript:void(0)" class="closebtn float-right overflow-hidden mt-4"
      (click)="openSideMenu(sideBar?.name)">
      <icon [cuClass]="sideBar?.closeSubMenu?.class" [name]="sideBar?.closeSubMenu?.icon" size="20px" type="material">
      </icon>
    </a>
  </div>
  <div class="nav-search" *ngIf="sideBar?.isSearch">
    <input type="text" name="search" placeholder="Search" class="p-8">
  </div>
  <ul class="submenu  mt-16 mr-8 mb-0 ml-8 p-0" *ngFor="let subMenu of sideBar?.sideBarSubMenu">
    <li *ngIf="subMenu?.title" class="seperationlabel mt-16 ml-16">{{subMenu?.title}}</li>
    <li *ngFor="let sortSubMenu of subMenu?.subMenuItem">
      <a [routerLink]="sortSubMenu?.path" (click)="closeSidebarMenu(sideBar?.name, sortSubMenu?.title)"
        class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon *ngIf="sortSubMenu?.icon" class="float-left mr-16" [cuClass]="sortSubMenu?.class"
          [name]="sortSubMenu?.icon" size="20px" type="material">
        </icon>
        {{sortSubMenu?.title}}
      </a>
    </li>

  </ul>
</div>

<div class="sidenav position-fixed overflow-auto" [ngClass]="{'sidenav-active': isToggled === 'user' }">
  <div class="user-title overflow-hidden mb-16">
    <figure
      class="text-center rounded-circle mb-0 float-left position-relative text-center overflow-hidden initials__green">
      <!-- <img src="../../../../assets/images/user-profile.png" alt=""> -->
      <!-- {{userAvatar |shortName: 'last'}} -->
      {{user?.first_name |shortName: 'last'}}
    </figure>
    <h4 class="float-left ml-8 mb-0 overflow-hidden">
      <!-- {{userName}} Workspace -->
      {{user?.first_name}}
    </h4>
    <a href="javascript:void(0)" class="float-right p-0 closebtn" (click)="openSideMenu('user')">
      <icon cuClass="sidebar-icon" name="close" size="20px" type="material"></icon>
    </a>
  </div>
  <ul class="submenu mt-24 mr-8 mb-0 ml-8 p-0">
    <!-- <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="build_circle" size="20px" type="material">
        </icon>
        Account Settings
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="add_alert" size="20px" type="material">
        </icon>
        Notifications Settings
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="security" size="20px" type="material">
        </icon>
        Change Password
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="book" size="20px" type="material">
        </icon>
        Recent Activities
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="phonelink_setup" size="20px"
          type="material">
        </icon>
        Mobile App Settings
      </a>
    </li> -->
    <li>
      <a href="javacript:void(0)" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative"
        (click)="logout()">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="login" size="20px" type="material">
        </icon>
        Logout
      </a>
    </li>
    <li class="seperationlabel mt-24 ml-16">Theme</li>
    <app-theme-switcher class="theme-switcher"></app-theme-switcher>
    <li class="seperationlabel mt-24 ml-16">Support</li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="contact_support" size="20px"
          type="material">
        </icon>
        Help
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="keyboard" size="20px" type="material">
        </icon>
        Hot Keys
      </a>
    </li>
    <li>
      <a href="#" class="overflow-hidden d-block pt-0 pb-0 pl-16 pr-16 position-relative">
        <icon class="float-left mr-16" cuClass="sidebar-icon-submenu" name="play_circle_filled" size="20px"
          type="material"></icon>
        Video Tutorials
      </a>
    </li>
  </ul>
</div>
