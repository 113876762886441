<form [formGroup]="clientCreate">
  <div class="Upload_box_wrapper ">
    <svms-upload-avatar [image]="clientCreate?.controls?.logo?.value" [ngClass]="{'disable':isViewClicked}" (hideError)="getCropImage($event)"></svms-upload-avatar>
    <div class="upload-panel-body">
      <h5 *ngIf="!logoComponent?.logoUrl || !isImagePresent">Client Logo</h5>
      <div *ngIf="(!logoComponent?.logoUrl || !isImagePresent) && !isViewClicked" class="error-text">Only .PNG, .JPG formats with maximum file size of 3 MB</div>
    </div>

    <div class="toggle-panel" [ngClass]="{'disable':isViewClicked}">
      <div class="toggle-panel-inner" [ngClass]="toggle?.value ? 'if-active' : 'if-inactive'">
        <span class="status-text">{{toggle?.title}}</span>
        <span class="toggle-switch" [ngClass]="toggle?.value ? 'toggle-on' : 'toggle-off'"
          (click)="onClickToggle()"></span>
      </div>
    </div>
  </div>

  <div class="form-group" *ngIf="!toggle?.value">
    <label>Inactive Reason <span class="require">*</span></label>
    <ng-select bindLabel="name" [clearable]="false" placeholder="Choose Reason" ngDefaultControl
      formControlName="client_inactivereason" [readonly]="isViewClicked">
      <ng-option [value]="'Administrative'">Administrative</ng-option>
      <ng-option [value]="'Client Cancelled'">Client Cancelled</ng-option>

    </ng-select>
    <p class="validation-msg"
      *ngIf="clientCreate.controls.client_inactivereason.touched && clientCreate.controls['client_inactivereason'].errors">
      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Inactive Reason is
      Required
    </p>
  </div>

  <div class="form-group">
    <label>Client Name<span class="require">*</span></label>
    <input type="text" class="form-control" placeholder="Client organization name" formControlName="org_name" required [readonly]="isViewClicked"
      [ngClass]="{'error':
    clientCreate.controls['org_name'].invalid && (clientCreate.controls['org_name'].dirty || clientCreate.controls['org_name'].touched)
      }" minlength="2">
    <p class="validation-msg"
      *ngIf="clientCreate.controls.org_name.touched && clientCreate.controls['org_name'].invalid && clientCreate.controls['org_name'].errors?.required">
      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Client Name is
      Required
    </p>
    <p class="validation-msg" *ngIf="clientCreate.controls.org_name.errors?.minlength">
      Name must be at least 2 characters long.
    </p>
    <p class="validation-msg"  *ngIf="!isNameValid">{{duplicateOrg}}</p>
  </div>

  <div class="form-group">
    <label>Organization Type <span class="require">*</span></label>
    <input type="text" class="form-control" placeholder="Client" formControlName="org_type" readonly>
  </div>

  <div class="form-group">
    <label>Industry <span class="require">*</span></label>
    <ng-select bindLabel="name" [clearable]="false" [multiple]=" true" placeholder="Industry" ngDefaultControl
      formControlName="industry" [readonly]="isViewClicked">
      <ng-option [value]="data.id" *ngFor="let data of industries">{{data.name}}</ng-option>
    </ng-select>
    <p class="validation-msg"
      *ngIf="clientCreate.controls.industry.touched && clientCreate.controls['industry'].errors">
      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Industry is
      Required
    </p>
  </div>
</form>
