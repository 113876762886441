import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, forwardRef, ViewEncapsulation } from '@angular/core';
import { google } from "google-maps"; //Don't remove import
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'search-address',
  template: `
      <input 
        type="text"
        [value]="value" [id]="id"
        #addresstext class="form-control" placeholder="Enter Address" [readonly]="readonlyMode">
    `,
  styleUrls: ['./search-address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchAddressComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SearchAddressComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() adressType: string;
  @Input() country: string;
  @Input() readonlyMode: false;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  public autocompleteInput: string;
  public queryWait: boolean;
  @Input() set id(value: string) {
    this._ID = value;
  }
  get id() {
    return this._ID;
  }
  private _ID = '';
  @Input('value') _value = '';
  onChange: any = () => { };
  onTouched: any = () => {
  };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value,hardreset=false) {
    if (value || hardreset == true) {
      this.value = value;
    }
  }
  
  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

   getPlaceAutocomplete(countryName?) {
     if (!countryName && !this.country) {
       countryName = 'CA';
     }
     if (!countryName && this.country) {
       countryName = this.country;
     }
     const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
       {
         componentRestrictions: { country: countryName },
         types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
       });
     google.maps.event.addListener(autocomplete, 'place_changed', () => {
       const place = autocomplete.getPlace();
       this.writeValue(place.formatted_address);
       this.invokeEvent(place);
     });
   }

  invokeEvent(place: Object) {

    this.setAddress.emit(place);
  }


}
