import {
  Component,
  LOCALE_ID,
  Inject,
  OnInit,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  EventStreamService,
  Events,
} from '../core/services/event-stream.service';
import { AlertService } from '../shared/components/alert/alert.service';

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss']
})
export class CandidateComponent implements OnInit {

  title = 'Simplify VMS 2.0';
  showBackdrop = false;

  valueEmittedFromChildComponent = '';

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
  }

  valueEmittedFromHeaderComponent: '';

  notificationEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromHeaderComponent = valueEmitted;
  }


  logged_In = false;
  constructor(
    private changeDetection: ChangeDetectorRef,
    public translate: TranslateService,
    private eventStream: EventStreamService,
    private _alerts: AlertService,
    private _render: Renderer2,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    if (localeId) {
      translate.setDefaultLang(
        localeId.split('-')[0]
          ? localeId.split('-')[0]
          : 'English (United States)'
      );
    }
  }

  ngOnInit() {
    this.eventStream.on(Events.LoggedIn, (data) => {
      this.logged_In = data;
    });
    this.eventStream.on(Events.DO_SHOW_CLIENT_FORM, (data) => {
      this.showBackdrop = data;
      if (data) {
        this._render.addClass(document.body, 'overflow-hidden');
      } else {
        this._render.removeClass(document.body, 'overflow-hidden');
      }
      this.changeDetection.detectChanges();
    });
    this.eventStream.on(Events.DO_SHOW_USER_FORM, (data) => {
      this.showBackdrop = data;
      if (data) {
        this._render.addClass(document.body, 'overflow-hidden');
      } else {
        this._render.removeClass(document.body, 'overflow-hidden');
      }
      this.changeDetection.detectChanges();
    });
    this.eventStream.on(Events.DO_SHOW_USER_FORM, (data) => {
      this.showBackdrop = data;
      if (data) {
        this._render.addClass(document.body, 'overflow-hidden');
      } else {
        this._render.removeClass(document.body, 'overflow-hidden');
      }
      this.changeDetection.detectChanges();
    });
  }

}
