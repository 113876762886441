import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';


@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {
  @Input() moduleData: any[] = [];
  @Input() readOnly = false;
  @Output() moduleDataChange = new EventEmitter<any[]>();

  constructor() { }

  ngOnInit(): void {

  }

  onClickToggle(index) {
    if (this.readOnly) {
      return;
    }
    this.moduleData[index].is_enabled = !this.moduleData[index].is_enabled;
    this.moduleData = [... this.moduleData];
    this.moduleDataChange.emit(this.moduleData);
  }
}

export class ToggleModule {
  id: number;
  //icon: string;
  title: string;
  name: string;
  value: boolean;
}
