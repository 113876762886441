import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { AlertComponent } from '../shared/components/alert/alert.component';
import { NgxStickySidebarModule } from '@smip/ngx-sticky-sidebar';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { LoaderInterceptor } from '../shared/components/loader/loader.interceptor';
// import { DataTransferService } from './services/data-transfer.service';
// import { ClonerService } from './services/cloner.service';
// import { EventStreamService } from './services/event-stream.service';
// import { HttpService } from './services/http.service';


@NgModule({
  imports: [HttpClientModule, SharedModule, CommonModule, NgxStickySidebarModule],
  declarations: [AlertComponent,  LoaderComponent],
  providers: [ /* DataService, ClonerService, EventBusService, HttpClientRxJSService, */
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true
    // }
  ],
  exports: [ AlertComponent, LoaderComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'Core is already loaded. Import it in the AppModule only');
    }
  }

}
