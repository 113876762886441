import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardHeaderComponent } from './Component/dashboard-header/dashboard-header.component';
import { DashboardConfigComponent } from './Component/dashboard-config/dashboard-config.component';
import { QuickLinksComponent } from './Component/dashboard-config/quick-links/quick-links.component';
import { ListWidgetComponent } from './Component/dashboard-config/list-widget/list-widget.component';
import { ChartsComponent } from './Component/dashboard-config/charts/charts.component';
import { CalendarsComponent } from './Component/dashboard-config/calendars/calendars.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { VmsTableModule } from '../library/smartTable/vms-table.module';
import { DashboardCounterComponent } from './Component/dashboard-counter/dashboard-counter.component';
import { DashboardActionsComponent } from './Component/dashboard-actions/dashboard-actions.component';
import { DashboardService } from './dashboard.service';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { SvmsDoughnutChartModule } from 'src/app/library/svms-doughnut-chart/svms-doughnut-chart.module'
import { ContactSupportComponent } from './Component/contact-support/contact-support.component';
import { QuillModule } from 'ngx-quill';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


@NgModule({
  declarations: [
    DashboardComponent,
    DashboardHeaderComponent,
    DashboardConfigComponent,
    QuickLinksComponent,
    ListWidgetComponent,
    ChartsComponent,
    CalendarsComponent,
    DashboardCounterComponent,
    DashboardActionsComponent,
    ContactSupportComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    NgSelectModule,
    VmsTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFullCalendarModule,
    SvmsDoughnutChartModule,
    QuillModule.forRoot(),
    NgxSkeletonLoaderModule
  ],
  providers: [DashboardService]
})
export class DashboardModule { }
