import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvmsDoughnutChartComponent } from './svms-doughnut-chart.component';

@NgModule({
  declarations: [SvmsDoughnutChartComponent],
  imports: [
    CommonModule
  ],
  exports: [SvmsDoughnutChartComponent]
})
export class SvmsDoughnutChartModule { }
