<form [formGroup]="createJobForm">
  <div class="create-wrapper p-24">
    <div class="create-form pt-32">
      <h3 class="font-weight-medium">Create Job</h3>
      <div class="card bg-light mt-24">
        <div class="breadcrumb d-flex">
          <div
            (click)="goToBasic()"
            class="item pl-24 pr-24"
            [ngClass]="{
              active: basicInfo,
              finished: financialDetail || jobDetails
            }"
          >
            <span class="mr-8">1</span>
            <icon class="mr-8" size="20px" name="done_outline"></icon> Basic
            Info
          </div>
          <div
            (click)="goToFinancialDetail()"
            class="item pl-24 pr-24"
            [ngClass]="{ active: financialDetail, finished: jobDetails }"
          >
            <span class="mr-8">2</span>
            <icon class="mr-8" size="20px" name="done_outline"></icon> Financial
            Details
          </div>
          <div
            (click)="goToJobDetails()"
            class="item pl-24 pr-24"
            [ngClass]="{ active: jobDetails }"
          >
            <span class="mr-4">3</span>
            <icon class="mr-8" size="20px" name="done_outline"></icon> Job
            Details
          </div>
        </div>
        <div class="p-32" *ngIf="basicInfo">
          <div class="p-8">
            <div class="form-group">
              <label> Job Template <span class="require">*</span> </label>
              <div class="with-icon position-relative">
                <icon class="icon-on-inout" size="24" name="search"></icon>
                <!-- templet serach input -->
                <input
                  type="text"
                  class="form-control s_height"
                  autofocus
                  placeholder="Search template by name or job title"
                  [(ngModel)]="selectedTemplate.template_name"
                  (focus)="suggestionPaneVisible = true"
                  (keyup)="usersearchTemplate.next($event)"
                  [ngModelOptions]="{ standalone: true }"
                />
                <div class="loader search-loader" *ngIf="dataLoading"></div>
                <div
                  class="dropdown-user position-absolute py-8 rounded-sm d-flex flex-column px-16"
                  *ngIf="
                    suggestionPaneVisible &&
                    templateData &&
                    templateData?.length > 0
                  "
                >
                  <div
                    class="jobs py-12 cursor-pointer"
                    *ngFor="let jobTemplate of templateData"
                  >
                    <div
                      class="w-100"
                      (click)="selectedJobtemplate(jobTemplate)"
                    >
                      <p class="job-title">{{ jobTemplate?.template_name }}</p>
                      <div
                        class="d-flex align-items-center mt-8 position-relative"
                      >
                        <span class="d-flex align-items-center mr-32">
                          <icon
                            size="14px"
                            name="next_week"
                            type="material"
                            class="d-flex"
                            cuClass="d-flex"
                          >
                          </icon>
                          <p class="ml-8 small">
                            {{ jobTemplate?.title?.title }}
                          </p>
                        </span>
                        <span class="d-flex align-items-center">
                          <icon
                            size="14px"
                            name="category"
                            type="material"
                            class="d-flex"
                            cuClass="d-flex"
                          >
                          </icon>
                          <p class="ml-8 small">
                            {{ jobTemplate?.category?.category_name }}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div class="" (click)="selectedJobtemplate(jobTemplate)">
                      <div class="badge badge-blue">
                        {{ jobTemplate?.id }}
                      </div>
                    </div>
                  </div>
                </div>
                <icon
                  *ngIf="selectedTemplate?.id"
                  size="20px"
                  name="cancel"
                  class="icon-on-inout clear-search"
                  (click)="clearTemplate()"
                ></icon>
              </div>
            </div>
            <div *ngIf="!selectedTemplate || !selectedTemplate?.id">
              <p class="title-small small mb-8 font-weight-medium">
                Recent Jobs
              </p>
              <p
                class="text-center"
                *ngIf="
                  tempRecentArray &&
                  tempRecentArray?.length === 0 &&
                  dataLoading
                "
              >
                No data found
              </p>

              <ul class="template-list" *ngIf="skeletonLoading">
                <li
                  class="skeleton"
                  *ngFor="let sk of [1, 2, 3, 4, 5, 6]; let i = index"
                ></li>
              </ul>
              <ul class="template-list">
                <li *ngFor="let recentJob of tempRecentArray; let i = index">
                  <a
                    href="javascript:void(0)"
                    (click)="selectedJobtemplate(recentJob)"
                    >{{ recentJob?.template_name }}</a
                  >
                </li>
              </ul>
            </div>

            <div *ngIf="!selectedTemplate || !selectedTemplate?.id">
              <p class="title-small small mb-8 font-weight-medium">
                Popular Jobs
              </p>
              <p
                class="text-center"
                *ngIf="
                  tempPopularArray &&
                  tempPopularArray?.length === 0 &&
                  dataLoading
                "
              >
                No data found
              </p>
              <ul class="template-list" *ngIf="skeletonLoading">
                <li
                  class="skeleton"
                  *ngFor="let sk of [1, 2, 3, 4, 5, 6]; let i = index"
                ></li>
              </ul>
              <ul class="template-list">
                <li *ngFor="let recentJob of tempPopularArray; let i = index">
                  <a
                    href="javascript:void(0)"
                    (click)="selectedJobtemplate(recentJob)"
                    >{{
                      recentJob?.template_name || recentJob?.title?.title
                    }}</a
                  >
                </li>
              </ul>
            </div>

            <div
              class="form-group-row"
              *ngIf="selectedTemplate && selectedTemplate?.id"
            >
              <!-- <div class="form-group">
                <label>
                  Hierarchy <span class="require">*</span>
                </label>
                <ng-select placeholder="Choose Hierarchy" formControlName="hierarchy"
                  (change)="getQualificationTypes()" (open) ="sidebarOpen()">
                  <ng-option [value]=h.id *ngFor="let h of createJobObject?.hierarchyData">{{h?.name}}</ng-option>
                </ng-select>
              </div> -->
              <div class="form-group position-relative">
                <label> Hierarchy <span class="require">*</span> </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Choose Hierarchy"
                  formControlName="hierarchy"
                  (click)="sidebarOpen()"
                  readonly
                />
                <span class="cursor-pointer hierarchy" (click)="sidebarOpen()">
                  <icon
                    size="19px"
                    name="account_tree"
                    type="material"
                    class="d-flex"
                    cuClass="d-flex"
                  ></icon>
                </span>
              </div>

              <div class="form-group">
                <label> Work Location <span class="require">*</span> </label>
                <ng-select
                  placeholder="Select Work Location"
                  formControlName="locations"
                  (change)="changeLocation($event)"
                >
                  <ng-option
                    [value]="location?.id"
                    *ngFor="let location of createJobObject?.locations"
                  >
                    <span class="text-capitalize">{{
                      location?.name
                    }}</span></ng-option
                  >
                </ng-select>
              </div>
              <div class="form-group managers">
                <label> Job Manager <span class="require">*</span> </label>
                <ng-select
                  bindLabel="name"
                  placeholder="Select Manager"
                  formControlName="job_manager"
                >
                  <ng-option
                    *ngFor="let option of managerList"
                    [value]="option"
                  >
                    <!-- <img class="mt-4" src="assets/images/5.jpg" width="20px"> -->
                    <div class="user_logo">
                      {{ option?.first_name | slice: 0:1 }}
                    </div>
                    {{ option?.first_name }} {{ option?.last_name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div
              class="form-group"
              *ngIf="selectedTemplate && selectedTemplate?.id"
              [ngClass]="{
                disableDiv: !selectedTemplate?.allow_user_description
              }"
            >
              <label> Job Description </label>
              <quill-editor
                [modules]="modules"
                formControlName="description"
                [styles]="{ height: '200px' }"
              >
              </quill-editor>
            </div>
            <div
              class="mt-24"
              *ngIf="
                createJobForm.get('qualifications')?.controls &&
                selectedTemplate &&
                selectedTemplate?.id
              "
            >
              <h4 I18n class="float-left mr-16 title-height font-weight-medium">
                Qualifications
              </h4>
              <div class="add-opton float-left">
                <button
                  (click)="openDropdown()"
                  (clickOutside)="onClickedOutside()"
                  class="mr-24 d-flex align-items-center justify-content-center"
                >
                  <icon name="add" size="20px" cuClass="d-flex"></icon> Add
                  Qualifications
                  <icon
                    [name]="showQualification ? 'expand_less' : 'expand_more'"
                    cuClass="d-flex"
                    size="20px"
                  ></icon>
                </button>
                <ul
                  class="qualification-dropdown"
                  [ngClass]="{ active: showQualification }"
                >
                  <li
                    *ngFor="
                      let qualification of qualification_types;
                      let j = index
                    "
                  >
                    <a
                      href="javascript:void(0)"
                      [class.active]="qualification.selected"
                      (click)="closeDropdown(qualification, j)"
                      >{{ qualification.name }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="clearfix"></div>
              <div class="py-16">
                <div class="row">
                  <div
                    class="col-12"
                    [formGroup]="item"
                    *ngFor="
                      let item of createJobForm.get('qualifications')?.controls;
                      let i = index
                    "
                  >
                    <div class="form-group-row">
                      <div class="form-group">
                        <label
                          >{{ item.get("qualification_type")?.value }}
                        </label>
                        <div class="position-relative" *ngIf="credentialsData">
                          <ng-select
                            bindLabel="name"
                            [clearable]="false"
                            formControlName="values"
                            [multiple]="true"
                            [items]="item.get('data')?.value"
                            (search)="searchQualifications(item, $event)"
                            placeholder="Search {{
                              item.get('qualification_type')?.value
                            }} "
                            [searchable]="true"
                          >
                          </ng-select>
                        </div>
                      </div>
                      <div
                        class="delete-icon d-flex align-items-center cursor-pointer"
                        (click)="removeQualification(i)"
                      >
                        <icon
                          name="delete_forever"
                          color="#B25858"
                          size="20px"
                        ></icon>
                      </div>
                    </div>
                    <div class="tabs d-flex flex-wrap">
                      <div
                        class="tabs-panel p-8 mr-8 mb-16"
                        *ngFor="
                          let c of item.get('values')?.value;
                          let i = index
                        "
                      >
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <p class="small font-weight-medium">{{ c?.name }}</p>
                          <span class="cursor-pointer">
                            <icon
                              size="13px"
                              name="close"
                              type="material"
                              class="d-flex"
                              cuClass="d-flex"
                              (click)="removeQualificationValues(item, i)"
                            >
                            </icon>
                          </span>
                        </div>
                        <!-- <div class="small font-weight-medium">{{c?.code}}</div> -->
                        <div
                          class="d-flex justify-content-between align-items-center my-8 mb-8"
                        >
                          <span class="toggle-with-icon cursor-pointer">
                            <div class="toggle-panel-inner px-0 remove_style">
                              <span
                                class="toggle-switch"
                                [ngClass]="
                                  c?.is_active ? 'toggle-on' : 'toggle-off'
                                "
                                (click)="c.is_active = !c.is_active"
                              ></span>
                            </div>
                          </span>
                          <span class="small pl-8 left-option">
                            <span *ngIf="!c?.is_active">Optional</span>
                            <span *ngIf="c?.is_active">Required</span>
                          </span>
                        </div>
                        <div
                          class="strength w-100"
                          *ngIf="
                            item.get('qualification_type')?.value === 'Skill' ||
                            item.get('qualification_type')?.value ===
                              'Speciality'
                          "
                        >
                          <div class="pt-4 strength-bar">
                            <ul>
                              <li
                                tooltip="{{ tooltipTitle }}"
                                [visibility]="tooltipvisible"
                                placement="bottom"
                                delay="500"
                                *ngFor="let r of [1, 2, 3]; let index = index"
                                (click)="clickOnRatings(c, r)"
                                [ngClass]="{
                                  active: c.level >= r || index == 0
                                }"
                                (mouseover)="showTooltip(index)"
                              ></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-32" *ngIf="financialDetail">
          <div class="p-8">
            <div class="form-group-row">
              <div class="form-group date-picker position-relative">
                <label> Start Date <span class="require">*</span> </label>
                <svms-datepicker
                  [inputdateFormat]="'yy-mm-dd'"
                  [options]="options"
                  id="date1"
                  I18n
                  formControlName="start_date"
                >
                </svms-datepicker>
              </div>
              <div class="form-group date-picker position-relative">
                <label> End Date <span class="require">*</span> </label>
                <svms-datepicker
                  [inputdateFormat]="'yy-mm-dd'"
                  id="date1"
                  I18n
                  formControlName="end_date"
                  [options]="options2"
                >
                </svms-datepicker>
              </div>
              <div class="form-group">
                <label>
                  Number of Positions <span class="require">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Number of positions"
                  formControlName="num_resources"
                  (Change)="getWorkingHoursEstimate()"
                  onkeydown="return ( event.ctrlKey || event.altKey 
                  || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                  || (95<event.keyCode && event.keyCode<106)
                  || (event.keyCode==8) || (event.keyCode==9) 
                  || (event.keyCode>34 && event.keyCode<40) 
                  || (event.keyCode==46) )"
                />
                <p
                  class="validation-msg"
                  *ngIf="createJobForm?.get('num_resources').errors?.pattern"
                >
                  Positions must be greater than zero.
                </p>
              </div>
              <div class="form-group">
                <label> Expense Allowed </label>
                <div class="radio-group">
                  <div *ngFor="let radioitem of items" class="radio-container">
                    <input
                      type="radio"
                      name="allow_expense"
                      value="{{ radioitem?.value }}"
                      formControlName="allow_expense"
                    />
                    <span class="checkmark">{{ radioitem.value }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group-row">
              <div class="form-group">
                <label> Currency <span class="require">*</span> </label>
                <ng-select
                  placeholder="Select Currency"
                  formControlName="currency"
                  (change)="getRateCard()"
                >
                  <ng-option
                    [value]="c?.name"
                    *ngFor="let c of createJobObject?.currencies"
                  >
                    <span width="20px" class="mr-4" *ngIf="c?.symbol">{{
                      c?.symbol
                    }}</span>
                    {{ c?.name }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="form-group">
                <label> Unit of Measure <span class="require">*</span> </label>
                <ng-select
                  placeholder="Select Unit"
                  [searchable]="false"
                  formControlName="unit_of_measure"
                >
                  <!-- <ng-option [value]="'Hourly'">
                    Hourly
                  </ng-option> -->
                  <ng-option [value]="'Monthly'"> Monthly </ng-option>
                </ng-select>
              </div>
              <div class="form-group">
                <label> Minimum Rate <span class="require">*</span> </label>
                <div class="input-with-fixed-value position-relative">
                  <span>{{ createJobObject?.currencySymbol }}</span>
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Enter Minimum bill rate"
                    formControlName="min_bill_rate"
                    (blur)="ToFloat()"
                    onkeydown="return ( event.ctrlKey || event.altKey 
                  || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                  || (95<event.keyCode && event.keyCode<106)
                  || (event.keyCode==8) || (event.keyCode==9) 
                  || (event.keyCode>34 && event.keyCode<40) 
                  || (event.keyCode==46) )"
                  />
                </div>
              </div>
              <div class="form-group">
                <label> Maximum Rate <span class="require">*</span> </label>
                <div class="input-with-fixed-value position-relative">
                  <span>{{ createJobObject?.currencySymbol }}</span>
                  <!-- [(ngModel)]="maxRate" -->
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Enter Maximum bill rate"
                    (keyup)="showSummary()"
                    formControlName="max_bill_rate"
                    (blur)="checkMaxMin()"
                    onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
                  />
                </div>
              </div>
            </div>
            <!-- [ngIf]="createJobForm.get('formatted_working_days').value && createJobForm.get('single_net_budget').value" -->
            <!--  -->
            <div
              *ngIf="
                createJobForm.get('formatted_working_days').value &&
                createJobForm.get('single_net_budget').value
              "
            >
              <h4 class="pt-12 pb-12 font-weight-medium">Budget Summary</h4>
              <div class="budget-section d-flex">
                <div class="budget-item mr-16 p-12">
                  <icon
                    name="hourglass_empty"
                    size="20"
                    class="green mr-12"
                  ></icon>
                  <p>
                    <span class="font-weight-bold-500">{{
                      createJobForm.get("formatted_working_days").value
                    }}</span>
                    <span>Duration</span>
                  </p>
                </div>
                <div class="budget-item mr-16 p-12">
                  <icon
                    name="person_outline"
                    size="20"
                    class="blue mr-12"
                  ></icon>
                  <p>
                    <span class="font-weight-bold-500"
                      >{{
                        createJobForm.get("single_net_budget").value
                          | currency: "USD":"symbol":"4.2-2"
                      }}
                    </span>
                    <span>Budget /Resource</span>
                  </p>
                </div>
                <div class="budget-item mr-16 p-12">
                  <icon name="payments" size="20" class="yellow mr-12"></icon>
                  <p>
                    <span class="font-weight-bold-500">{{
                      createJobForm.get("net_budget")?.value
                        | currency: "USD":"symbol":"4.2-2"
                    }}</span
                    ><span>Total Est. Budget</span>
                  </p>
                </div>
                <!-- *ngIf="createJobForm.get('max_bill_rate').value" -->
                <div class="detail-button" (click)="openCreateEstimate()">
                  <button>View Detailed Estimate</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-32" *ngIf="jobDetails">
          <div class="p-8">
            <div class="toggle-panel toggle-with-icon w-100">
              <div
                class="toggle-panel-inner p-16"
                [ngClass]="preicdntified ? 'if-active' : 'if-inactive'"
              >
                <!-- <icon name="how_to_reg" size="20px" class="mr-24 d-flex align-items-center justify-content-center"></icon> -->
                <icon
                  size="20px"
                  name="how_to_reg"
                  type="material"
                  class="mr-24 d-flex align-items-center justify-content-center"
                  cuClass="d-flex"
                >
                </icon>
                <p>Do you have pre-Identified Candidate(s)?</p>
                <span
                  class="toggle-switch"
                  [ngClass]="preicdntified ? 'toggle-on' : 'toggle-off'"
                  (click)="onClickToggle()"
                ></span>
              </div>
              <div *ngIf="addCandidate" class="toggled-list p-16 pb-8">
                <ul class="add-wrap">
                  <li
                    class="p-16 bg-light rounded-sm item"
                    *ngFor="let c of candidates; let i = index"
                    (mouseleave)="hideDropdown(i)"
                  >
                    <div class="dropdown">
                      <button
                        class="btn btn-light small pl-4 pr-4"
                        (click)="displayCandidateOption(i)"
                      >
                        <icon name="more_horiz" size="24px"></icon>
                      </button>
                      <!-- class="dropdown-items" -->
                      <ul
                        class="dropdown-items"
                        [ngClass]="{ active: c?.showOption }"
                      >
                        <li>
                          <a (click)="editCandidate(i)">Edit</a>
                        </li>
                        <li>
                          <a (click)="removeCandidate(i)">Remove</a>
                        </li>
                      </ul>
                    </div>

                    <div class="h-150">
                      <div class="overflow-hidden">
                        <div class="Quali-logo float-left mr-8">
                          {{ c?.first_name | slice: 0:1 }}
                        </div>
                        <div class="float-left candidate-select">
                          <p class="candidate-name mb-0 font-weight-medium">
                            {{ c?.first_name }} {{ c?.last_name }}
                          </p>
                          <p>
                            <a href="mailto: {{ c?.email }} ">
                              {{ c?.email }}</a
                            >
                          </p>
                        </div>
                      </div>
                      <div
                        class="contacts d-flex align-items-center mt-8"
                        *ngIf="c?.phone_number"
                      >
                        <icon
                          size="15px"
                          name="local_phone"
                          type="material"
                          class="d-flex"
                          cuClass="d-flex"
                        >
                        </icon>
                        <span class="ml-12"> {{ c?.phone_number }} </span>
                      </div>
                      <div class="contacts d-flex align-items-center mt-8">
                        <icon
                          size="15px"
                          name="corporate_fare"
                          type="material"
                          class="d-flex"
                          cuClass="d-flex"
                        >
                        </icon>
                        <span class="ml-12"> {{ c?.vendorName?.name }} </span>
                      </div>
                    </div>
                  </li>
                  <li
                    (click)="openCreateCandidate()"
                    class="add-cadidate cursor-pointer bg-light border rounded-sm p-16 h-150"
                  >
                    + Add Candidates
                  </li>
                </ul>
                <div class="clearfix"></div>
              </div>
            </div>
            <h4 class="mt-32 pb-12 font-weight-medium">Foundational Data</h4>
            <div class="row">
              <div
                class="col-4"
                *ngFor="
                  let item of createJobForm.get('foundational_data')?.controls
                "
              >
                <div class="form-group-row" [formGroup]="item">
                  <div class="form-group">
                    <label I18n
                      >{{ item?.get("foundational_data_name")?.value }}
                      <span
                        class="require"
                        *ngIf="item?.get('is_requiredis_required')?.value"
                        >*</span
                      ></label
                    >
                    <ng-select
                      [multiple]="false"
                      [formControl]="item?.get('values')"
                      bindLabel="name"
                      placeholder="Select"
                    >
                      <ng-option
                        *ngFor="let option of item?.get('options')?.value"
                        [value]="option"
                        >{{ option.name }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <ng-template [ngIf]="approvalList?.length > 0">
              <h4 class="pt-12 pb-12 font-weight-medium">Approval Work Flow</h4>
              <p>Approvers</p>
              <div
                class="approvers d-flex mb-4 p-12"
                *ngFor="let approver of approvalList; let i = index"
              >
                <div
                  class="initials align-self-center initials-profile initials__green d-flex mr-8"
                  *ngIf="approver && approver?.first_name"
                >
                  {{ approver?.first_name | shortName: "first" }}
                </div>
                <div class="d-flex flex-column">
                  <span class="subtitle-2 font-weight-medium">{{
                    approver?.first_name
                  }}</span>
                  <span class="x-small">{{ approver?.role }}</span>
                </div>
              </div>
              <div class="form-group pt-16 pb-0 mb-0">
                <label> Note for Approver(s) </label>
                <textarea
                  rows="3"
                  placeholder="Add Note"
                  formControlName="note_for_approver"
                  class="form-control"
                ></textarea>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="form-footer d-flex p-24">
          <div class="footer-left" *ngIf="showSummaries">
            <h4 class="mb-0 font-weight-medium">Market Rate</h4>
            <p>What salaries are candidates seeing for positions like this?</p>
          </div>
          <button
            *ngIf="!jobDetails"
            type="submit"
            class="btn btn-primary"
            (click)="clickToContinue()"
            [disabled]="isDisabled"
          >
            {{ btnText }}
            <icon
              *ngIf="!jobDetails"
              size="20px"
              name="chevron_right"
              type="material"
              class="icon--on-right"
              cuClass="icon-cu"
            ></icon>
          </button>
          <button
            *ngIf="jobDetails"
            type="submit"
            class="btn btn-primary"
            (click)="clickToContinue()"
            [disabled]="isDisabled || checkCandidate()"
          >
            {{ btnText }}
            <icon
              *ngIf="!jobDetails"
              size="20px"
              name="chevron_right"
              type="material"
              class="icon--on-right"
              cuClass="icon-cu"
            ></icon>
          </button>
        </div>
      </div>
      <div class="card summary bg-light mt-24 p-32" *ngIf="showSummaries">
        <div class="d-flex">
          <div class="left-panel">
            <div class="child-card pt-8 mt-8">
              <h4 class="pl-16 pr-16 mb-4">
                {{ selectedTemplate?.title?.title }} (Level
                {{ selectedTemplate?.level }})
              </h4>
              <p class="pl-16 pr-16 pb-8">
                <icon name="corporate_fare" size="24px" class="mr-8"></icon>
                United States of America
              </p>
              <div class="card-bottom p-16 d-flex">
                <p class="small">Your Rate</p>
                <p class="large">
                  ${{
                    createJobObject?.rateCartDetails?.salary / (2000 * 40) ||
                      135
                  }}
                </p>
              </div>
            </div>
            <ul class="other-info mt-16">
              <li>
                {{ createJobObject?.rateCartDetails?.supply || "10,045" }}
                <span>Supply</span>
              </li>
              <li>
                {{ createJobObject?.rateCartDetails?.demand || 1519 }}
                <span>Demand</span>
              </li>
              <li>
                {{ createJobObject?.rateCartDetails?.posting_duration || 68 }}
                <span class="color-gray">Posting Duration</span>
              </li>
              <li>
                ${{
                  createJobObject?.rateCartDetails?.salary / (2000 * 40) ||
                    66.66
                }}
                <span class="color-gray">Market Hourly Billing ..</span>
              </li>
            </ul>
          </div>
          <div class="right-panel pt-16">
            <!-- <app-create-job-chart></app-create-job-chart> -->
            <!--Chart Code-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="343"
              height="236"
              viewBox="0 0 343 236"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_3215"
                    data-name="Rectangle 3215"
                    width="89"
                    height="236"
                    transform="translate(0 0)"
                    fill="#776de0"
                    stroke="#707070"
                    stroke-width="1"
                  />
                </clipPath>
              </defs>
              <g
                id="Group_3633"
                data-name="Group 3633"
                transform="translate(0 0)"
              >
                <path
                  id="Union_11"
                  data-name="Union 11"
                  d="M322.565,164.338h.277a.568.568,0,0,1-.119.009A1.561,1.561,0,0,1,322.565,164.338ZM0,164.338H.275a1.527,1.527,0,0,1-.157.009A.562.562,0,0,1,0,164.338Zm161.376,0H.275c2.273-.232,19.876-5.948,99.266-113.83-.106-.472,32.782-47.871,61.833-50.5a.03.03,0,0,1,0-.009l.045,0,.046,0a.021.021,0,0,1,0,.009c29.051,2.628,61.94,50.028,61.831,50.5,79.392,107.883,96.993,113.6,99.265,113.83Z"
                  transform="translate(9.357 33.803)"
                  fill="#f6fafd"
                  stroke="rgba(119,109,224,0.56)"
                  stroke-width="2"
                  stroke-dasharray="10 6"
                />
                <g
                  id="Mask_Group_7"
                  data-name="Mask Group 7"
                  transform="translate(127)"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Union_12"
                    data-name="Union 12"
                    d="M322.565,164.338h.277a.568.568,0,0,1-.119.009A1.561,1.561,0,0,1,322.565,164.338ZM0,164.338H.275a1.527,1.527,0,0,1-.157.009A.562.562,0,0,1,0,164.338Zm161.376,0H.275c2.273-.232,19.876-5.948,99.266-113.83-.106-.472,32.782-47.871,61.833-50.5a.03.03,0,0,1,0-.009l.045,0,.046,0a.021.021,0,0,1,0,.009c29.051,2.628,61.94,50.028,61.831,50.5,79.392,107.883,96.993,113.6,99.265,113.83Z"
                    transform="translate(-117.645 33.107)"
                    fill="#776de0"
                  />
                </g>
                <line
                  id="Line_228"
                  data-name="Line 228"
                  y2="164.09"
                  transform="translate(170.616 33.054)"
                  fill="none"
                  stroke="#fff"
                  stroke-width="3"
                  stroke-dasharray="10 12"
                />
                <line
                  id="Line_229"
                  data-name="Line 229"
                  y1="164.09"
                  transform="translate(140.023 33.054)"
                  fill="none"
                  stroke="#ffc409"
                  stroke-width="4"
                />
                <path
                  id="Polygon_16"
                  data-name="Polygon 16"
                  d="M7.129,1.508a1,1,0,0,1,1.734,0l6.267,10.9a1,1,0,0,1-.867,1.5H1.729a1,1,0,0,1-.867-1.5Z"
                  transform="translate(144.132 17.96) rotate(60)"
                  fill="#ffc409"
                />
                <text
                  id="Min:22.10"
                  transform="translate(10 219)"
                  fill="#002626"
                  font-size="12"
                  font-family="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    Min: $
                    {{ createJobObject?.rateCartDetails?.range_min || 22.1 }}
                  </tspan>
                </text>
                <text
                  id="Max:65.65"
                  transform="translate(268 218)"
                  fill="#002626"
                  font-size="11"
                  font-family="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    Max:$
                    {{ createJobObject?.rateCartDetails?.range_max || 65.65 }}
                  </tspan>
                </text>
                <text
                  id="Mean_:_43.55"
                  data-name="Mean : $43.55"
                  transform="translate(130 218)"
                  fill="#002626"
                  font-size="11"
                  font-family="SegoeUI, Segoe UI"
                >
                  <tspan x="0" y="0">
                    Mean : ${{
                      createJobObject?.rateCartDetails?.range_min || 43.55
                    }}
                  </tspan>
                </text>
                <rect
                  id="Rectangle_3216"
                  data-name="Rectangle 3216"
                  width="343"
                  height="7"
                  transform="translate(0 195)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<app-create-candidate
  [isCreateCandidate]="isCreateCandidate"
  [isEdit]="createJobObject?.editedCandidate"
  (createdCandidate)="candidate($event)"
  (onCreateClose)="onCreateClose()"
></app-create-candidate>
<app-job-estimate
  [isCreateEstimate]="isCreateEstimate"
  [estimatorFields]="createJobForm?.value"
  (onCreateClose)="closePopup($event)"
></app-job-estimate>

<svms-sidebar
  [title]="'Select Hierarchy'"
  size="small"
  [dataVisibility]="seletHierarchy"
  (onClose)="sidebarClose()"
>
  <svms-sidebar-body>
    <label i18n class="pl-24 py-16"> Select Hierarchy </label>
    <svms-hierarchy
      [hierarchyData]="hierarchyData"
      (selectHierarchyLevel)="selectHierarchy($event)"
      [selectedHierarchy]="defaultHierarchy"
      [isUserOnly]="true"
      [userAssociatedHierarchyID]="userAssociateHierarchy"
    >
    </svms-hierarchy>
  </svms-sidebar-body>

  <svms-sidebar-footer>
    <div class="d-flex align-items-center justify-content-end">
      <button
        type="button"
        class="btn btn-primary"
        (click)="changeDefaultHierarchy()"
      >
        Continue
        <icon
          size="20px"
          name="chevron_right"
          type="material"
          class="icon--on-right"
          cuClass="icon-cu"
        >
        </icon>
      </button>
    </div>
  </svms-sidebar-footer>
</svms-sidebar>
<section
  class="success-modal py-64"
  [ngClass]="{ active: jobDetails?.id }"
  *ngIf="false"
>
  <icon
    size="70px"
    name="check_circle"
    type="material"
    class="success-icon pb-8"
    cuClass="icon-cu"
  ></icon>
  <h3 class="mt-16">Your Job has been successfully created</h3>
  <p class="mb-16">Job ID : {{ jobDetails?.id }}</p>
  <button class="btn btn-secondary mb-16" (click)="viewJob()">View Job</button>
  <button class="btn btn-outline" (click)="createJob()">Create new Job</button>
</section>
<div class="modal-overlay" [ngClass]="{ active: jobDetails?.id }"></div>
