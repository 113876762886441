<div class=" login-wrapper position-relative">

  <!--language switcher-->
  <div class="lang-switcher-panel position-absolute">
    <app-lang-switcher></app-lang-switcher>
  </div>
  <!--language switcher end-->

  <!--Left panel-->
  <div class="left-panel overflow-hidden">
    <div class="left-panel-content position-absolute text-center">
      <figure>
        <img src="assets/images/logo.svg" alt="" width="221" height="51" />
      </figure>
      <ul>
        <li i18n>Next Generation Vendor Management</li>
        <li i18n>Direct Sourcing &nbsp;</li>
        <li i18n>Statement of Work</li>
        <li i18n>Gig Economy Task Boards</li>
        <li i18n>Talent Indexes and Third-Party Platforms</li>
      </ul>
    </div>
  </div>

  <!--Left panel end-->

  <!--right panel start-->
  <div class="right-panel-wrapper position-relative">
    <div class="right-panel position-absolute" [ngSwitch]="condCheckerObj['formtype']">

      <!--default login page-->
      <div *ngSwitchCase="'defaultpage'">
        <figure class="mobile-logo">
          <img src="../../assets/images/logo-mobile.svg" alt="" width="221" height="51" />
        </figure>
        <h2 i18n class="font-weight-bold">Welcome to SimplifyVMS,</h2>
        <p class="mb-32 font-weight-normal" i18n>Login to your Simplify Account to Continue </p>

        <form [formGroup]='loginForm' (ngSubmit)="login(loginForm.value)">
          <div class="validation-alert  alert-error overflow-hidden mb-8"
            *ngIf="condCheckerObj['invalidUserCredentials'] == '401' || condCheckerObj['invalidUserCredentials'] == '400'">
            <icon name="error_outline" size="24px" type="material" class="float-left mr-16 pt-8"></icon>
            <p class="small">
              <span i18n>Invalid Username or password. You have {{wrongCredentials}} attempts remaining. To
                reset the password
                use </span>
              <a i18n class="cursor-pointer" (click)="condCheckerObj['formtype'] = 'forgetPassword'"> Forgot Password
                link. </a>
            </p>
          </div>


          <div class="validation-alert p-8 alert-error overflow-hidden mb-8"
            *ngIf="condCheckerObj['invalidResponse'] && condCheckerObj['invalidUserCredentials'] === '500'">
            <icon name="error_outline" size="24px" type="material" class="pt-0 float-left mr-16"></icon>
            <p i18n class="small"> Sorry, Internal Server issue, please try after some time. </p>
          </div>
          <div class="input-group position-relative mt-16 mb-16 login-email">
            <span class="input-group-text" id="email">
              <icon name="email" cuClass="login-form-icon" size="19px" type="material"></icon>
            </span>
            <input type="email" autocomplete="new-password" class="form-control medium" name="email" i18n-placeholder
              placeholder="Enter your Email Address Or Username" aria-label="Email" aria-describedby="email"
              formControlName='email' required autofocus required [ngClass]="{'error':
              loginForm.controls['email'].invalid}" />

            <!-- Success icon goes here -->
            <div class="validation-status position-absolute success"
              *ngIf="loginForm.get('email').errors == null && loginForm.get('email').value?.length > 1 && (condCheckerObj['invalidUserCredentials'] !== '401')">
              <icon name="check_circle" cuClass="valid-field-icon-green" size="19px" type="material"></icon>
            </div>
            <!-- Gray icon goes here -->
            <div class="validation-status position-absolute gray"
              *ngIf="loginForm.get('email').errors !== null || loginForm.get('email').value?.length == 1">
              <icon name="check_circle" cuClass="valid-field-icon-gray" size="19px" type="material"></icon>
            </div>
            <!-- Invalid icon goes here -->
            <div class="validation-status position-absolute invalid" *ngIf="
              loginForm.get('email').touched &&
                (loginForm.get('email').errors?.pattern || loginForm.get('email').errors?.required || loginForm.get('email').invalid) || (condCheckerObj['invalidUserCredentials'] == '401' || condCheckerObj['invalidUserCredentials'] == '403')
              ">
              <icon name="info" cuClass="invalid-field-icon" size="19px" type="material"></icon>
            </div>

          </div>
          <div class="validation-status invalid msg-input-bottom" *ngIf="
              loginForm.get('email').touched &&
                (loginForm.get('email').errors?.pattern || loginForm.get('email').errors?.required || loginForm.get('email').invalid)
              ">
            <p class="validation-msg">
              <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Please enter a valid
              email address Or Username.
            </p>
          </div>
          <div class="input-group position-relative mb-16">
            <span class="input-group-text" id="password">
              <icon name="vpn_key" cuClass="login-form-icon" size="19px" type="material"></icon>
            </span>
            <input autocomplete="new-password" type="password" class="form-control medium password-field" name="password" i18n-placeholder
              placeholder="Enter your Password" aria-label="Password" aria-describedby="password"
              formControlName="password" required appPasswordToggle />
          </div>

          <div class="mb-32 overflow-hidden d-flex justify-content-between">
            <!-- <a i18n (click)="condCheckerObj['formtype'] = 'forgetPassword'" class="forgot-links cursor-pointer">Forgot
              your password ?</a> -->
            <span>Forgot your <a i18n (click)="condCheckerObj['formtype'] = 'forgotUsername';forgetemail = ''"
                class="forgot-links cursor-pointer"> Username</a> Or <a i18n
                (click)="condCheckerObj['formtype'] = 'forgetPassword'" class="forgot-links cursor-pointer">
                Password</a> </span>
            <button type="submit" i18n class="btn login-btn btn-primary font-weight-normal position-relative"
              [disabled]="loginForm.status == 'INVALID' || wrongCredentials == 0"> Sign In</button>
          </div>
          <div class="other-login-option position-relative mb-32">
            <span class="position-absolute">Or</span>
          </div>
          <a type="button" i18n class="btn sso-login font-weight-normal btn-secondary large" href="{{ssoUrl}}">Sign In
            with Single Sign On (SSO)</a>
        </form>
      </div>
      <!--default login page end-->

      <!-- this part for forget Username -->
      <div *ngSwitchCase="'getUserName'">

        <div class="d-block ml--7">
          <span class="initials initials__green mb-16">{{ condCheckerObj["userid"] |shortName: 'last'}}</span>
        </div>
        <div class="clearfix"></div>

        <h2>
          <span i18n> Welcome Back, </span> <span aria-disabled="true">{{ condCheckerObj["userid"].slice(0, 8)}}</span>
        </h2>
        <div class="validation-alert p-8 alert-error overflow-hidden mb-8"
          *ngIf="condCheckerObj['invalidUserCredentials'] == '401'">
          <icon name="error_outline" size="19px" type="material" class="pt-0 float-left mr-16"></icon>
          <p class="text12">Invalid Email address and/or password. You have {{wrongCredentials}} attempts remaining. To
            reset the password </p>
        </div>
        <div class="validation-alert p-8 alert-error overflow-hidden mb-8"
          *ngIf="condCheckerObj['invalidResponse'] && condCheckerObj['invalidUserCredentials'] != '401'">
          <icon name="error_outline" size="19px" type="material" class="pt-0 float-left mr-16"></icon>
          <p i18n class="small">
            Sorry, Something went Wrong please try after some time.
          </p>
        </div>
        <p i18n class="mb-32">Enter your password to continue</p>
        <form>
          <div class="input-group position-relative mb-16">
            <span class="input-group-text" id="password">
              <icon name="vpn_key" cuClass="login-form-icon" size="19px" type="material"></icon>
            </span>
            <input type="password" appPasswordToggle class="form-control medium password-field" name="password"
              i18n-placeholder placeholder="Enter your password" aria-label="Password" aria-describedby="password"
              [(ngModel)]="reEnterPassword" [disabled]="wrongCredentials == 0" autofocus />
          </div>
          <div class="d-flex align-items-center justify-content-between mb-16">
            <p class="mb-0 font-weight-normal">
              <span i18n>Forgot your password ? </span> <a i18n (click)="condCheckerObj['formtype'] = 'forgetPassword'"
                class="links cursor-pointer font-weight-semibold">Click here</a>
            </p>
            <button type="submit" i18n class="btn loginbtn userbtn btn-primary large position-relative"
              (click)="reLogin()" [disabled]="!reEnterPassword || wrongCredentials == 0">
              Sign In <icon name="keyboard_arrow_right" cuClass="signin-icon" size="19px" type="material"
                class="position-absolute"></icon>
            </button>
          </div>
        </form>
        <div>
        </div>
        <div class="line-shadow mt-32 mb-32"></div>
        <p class="text-center">
          <a i18n (click)="getBack()" class="link2 cursor-pointer font-weight-medium" i18n> Sign in with a different
            account ?</a>
        </p>
      </div>
      <!--  forget Username end here  -->

      <!-- this part for forget password -->
      <div *ngSwitchCase="'forgetPassword'">
        <figure class="ml--7 pb-16">
          <icon size='45px' color='black' name='lock_open'></icon>
        </figure>
        <div>
          <h2 i18n>Forgot your Password ?</h2>
          <p class="mb-32 font-weight-medium" i18n> Enter your registered email address to send the security code. </p>
          <div class="validation-alert  alert-error overflow-hidden mb-8" *ngIf="userNotFound">
            <p class="small">
              <span i18n>{{userNotFound}} </span>
            </p>
          </div>
        </div>
        <form #f="ngForm">
          <div class="input-group position-relative  mb-16 login-email">
            <span class="input-group-text" id="email">
              <icon name="email" cuClass="login-form-icon" size="18px" type="material"></icon>
            </span>
            <input type="email" class="form-control medium" name="email" i18n-placeholder
              placeholder="Enter your Email Address" aria-label="Email" aria-describedby="email"
              [(ngModel)]="forgetemail" #test="ngModel" (keyup)="validateEmail($event)" required
              [pattern]="emailvalidation" [ngClass]="{'error':
              test.invalid && (test.dirty || test.touched)
                }">
            <div class="validation-status position-absolute success" *ngIf="test.dirty && test.errors == null">
              <icon name="check_circle" cuClass="valid-field-icon" size="19px" type="material"></icon>
            </div>
            <!-- Invalid icon goes here -->
            <div class="validation-status position-absolute invalid" *ngIf="
                (test.dirty && test.errors?.pattern )
              ">
              <icon name="info" cuClass="invalid-field-icon" size="19px" type="material"></icon>
            </div>
          </div>
          <div class="validation-status invalid msg-input-bottom" *ngIf="test.invalid && (test.dirty || test.touched)">
            <p class="validation-msg">
              <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Please enter a valid
              email address.
            </p>
          </div>

          <div class="d-flex overflow-hidden mb-32">
            <button i18n type="submit" class="btn loginbtn btn-primary large cursor-pointer full-width"
              (click)="sendResetPasswordMail()" [disabled]="!f.valid || userNotFound || isSaveLoader">
              Request Security Code <span *ngIf="isSaveLoader" class="loader ml-12"> </span>
            </button>
          </div>
          <div>
            <p class="text-left font-weight-normal">
              <span i18n> Did you remember your password? </span> <a i18n (click)="getBack()"
                class="links cursor-pointer font-weight-semibold"> Try logging in</a>
            </p>
          </div>
        </form>
      </div>

      <!-- forget password end here -->

      <!-- reset password screen with password strength -->
      <div *ngIf="condCheckerObj['formtype'] == 'resetPasswordComp'">
        <figure class="ml--7 pb-16">
          <icon size='45px' name='fingerprint'></icon>
        </figure>
        <h2 class="mb-16" i18n>Reset your Password ?</h2>
        <div class="validation-alert p-8 alert-error overflow-hidden mb-16" *ngIf="invalidEmail">
          <p class="small" i18n>{{invalidEmail}} </p>
        </div>
        <form [formGroup]="resetPasswordForm">
          <div class="input-group position-relative mb-0">
            <span class="input-group-text" id="password">
              <icon name="vpn_key" cuClass="login-form-icon" size="19px" type="material"></icon>
            </span>
            <input type="password" class="form-control password-field no-radius" name="password" i18n-placeholder
              placeholder="Enter your password" aria-label="Password" aria-describedby="password"
              formControlName="password" required appPasswordToggle (keyup)="resetMessage()" />
          </div>
        </form>

        <!--password strength component -->
        <form [formGroup]="resetPasswordForm">
          <vms-password-strength [passwordToCheck]="password" (passwordStrengthChanged)="getPasswordStrength($event)">
          </vms-password-strength>

          <!--password strength component -->

          <div class="input-group position-relative mb-16">
            <span class="input-group-text" id="password">
              <icon name="vpn_key" cuClass="login-form-icon" size="19px" type="material"></icon>
            </span>
            <input type="password" class="form-control password-field" name="password" i18n-placeholder
              placeholder="Confirm your password" aria-label="Password" aria-describedby="password"
              formControlName='confirmpassword' required appPasswordToggle (keyup)="resetMessage()" />
          </div>
          <div class="validation-alert p-8 alert-error overflow-hidden mb-16"
            *ngIf="resetPasswordForm.get('confirmpassword').dirty && resetPasswordForm.get('password').value != resetPasswordForm.get('confirmpassword').value">
            <p class="small" i18n>Password not matched </p>
          </div>
          <div class="validation-alert p-8 alert-error overflow-hidden mb-16" *ngIf="errorMessage">
            <p class="small" i18n>{{errorMessage}} </p>
          </div>
          <div class="mb-32 d-flex justify-content-end">
            <button type="submit" i18n class="btn  loginbtn btn-primary large cursor-pointer" (click)="resetPassword()">
              Change Your Password
            </button>
          </div>
        </form>
      </div>
      <!-- reset password screen with password strength end here-->


      <!-- forgot password step-2-->
      <!--*ngSwitchCase="'defaultsecurityCode'"-->
      <div class="text-center p-32" *ngSwitchCase="'defaultsecurityCode'">
        <img src="./assets/images/handsome.svg" alt="handsome" width="150">
        <p class="pt-16 font-weight-normal"><span i18n>We have sent an Security code to your registered </span>
          <span> Email address <a class="links"> {{forgetemail}} </a></span>
        </p>
        <p i18n>Please check your inbox to continue</p>
        <div class="mb-32 d-flex overflow-hidden">
          <button type="submit" i18n class="btn loginbtn btn-primary large full-width cursor-pointer"
            (click)="securityCode()">
            Click here to enter Security Code
          </button>
        </div>
        <p class="font-weight-normal text-center pb-24 "><span i18n> Didn’t receive the email? </span> <a i18n
            (click)="sendResetPasswordMail()" class="links cursor-pointer font-weight-semibold"> Resend </a></p>
        <div class="d-flex justify-content-center"><button type="button" i18n class="btn btn-light"
            (click)="condCheckerObj['formtype'] = 'defaultpage'"> Back to Login </button></div>
      </div>
      <!-- forgot password step-2-->

      <!-- security Code Verification-->
      <div *ngSwitchCase="'securityCodeVerification'">
        <div class="text-center p-8">
          <h2 class="mb-16" i18n > Security Code Verification </h2>
          <p *ngIf="!forMfa || (forMfa && invalidOTP>=0 && invalidOTP < 3 )"> <span i18n>Enter the Security code sent to</span> <a class="links cursor-pointer" i18n>
              {{forgetemail}}.</a> </p>
          <p *ngIf="!forMfa || (forMfa && invalidOTP>=0 && invalidOTP < 3 )" class="text-primary small" i18n> Security code is valid for 15 minutes </p>
          <p *ngIf="!forMfa && passwordstrengthForm !='';else showMFAMessage"class="text-primary small"> {{passwordstrengthForm}} <span
              *ngIf="(invalidOTP>=1 && expireOTP=='')"> You have {{invalidOtp_cnt}} attempts remaining. Please click <a
                i18n (click)="sendResetPasswordMail()" class="links cursor-pointer font-weight-semibold"> Resend </a> to
              get a new security code to your registered email address</span><span *ngIf="expireOTP=='406'">Please click
              <a i18n (click)="sendResetPasswordMail()" class="links cursor-pointer font-weight-semibold"> Resend </a>
              to get a new security code to your registered email address</span></p>
              <ng-template #showMFAMessage>
                <span *ngIf="(invalidOTP>=1 && invalidOTP < 3 && expireOTP=='')"> You have {{invalidOtp_cnt}} attempts remaining.</span>
                <div class="validation-alert alert-error msg-input-bottom text-left" *ngIf="(invalidOTP == 3 && expireOTP=='')">
                  <icon name="error_outline" class="float-left mr-16 pt-8" size="24px" type="material" cuClass="validation-icon"></icon>
                  <p class="small">
                     You have exceeded the maximum allowed attempts to enter valid security code.
                  </p>
                </div>
              </ng-template>
          <div class="mt-8">
            <form [formGroup]="OTPForm">
              <div class="form-group-row">

                <div *ngFor="let input of formInput;  index as i" class="form-group">
                  <input type="text" #formRow formControlName="{{input}}" class="form-control" maxlength="1" max="1"
                    (keyup)="keyUpEvent($event, i,input)"
                    class="form-control rounded-sm border-left align-justify-center text-center p-4">
                </div>

              </div>
            </form>
          </div>
          <div class="mb-32 mt-8 d-flex justify-content-center">
            <button i18n type="submit" class="btn loginbtn btn-primary large cursor-pointer" (click)="validateOTP();"
              [disabled]="invalidOTP == 3">
              Verify & Proceed
            </button>
          </div>
          <p *ngIf="!forMfa;else resendSection" class="text-center font-weight-normal"> <span i18n> Didn’t receive the email? </span>
            <a i18n 
            (click)="sendResetPasswordMail()" class="links cursor-pointer font-weight-semibold"> Resend </a></p>
          <ng-template #resendSection>
            <p class="text-center font-weight-normal"> <span i18n> Didn’t receive the email? </span> 
              <span i18n *ngIf="!showMFAResendButton && counter"> Resend email in (<span class="error-text"> {{counter}} </span>)</span> 
              
              <a i18n
              (click)="sendMFAResetPasswordEmail()" class="links cursor-pointer font-weight-semibold" *ngIf="showMFAResendButton"> Resend </a></p>
          
          </ng-template>
        </div>
        <!-- security CodeVerification-->
      </div>

      <!-- this part for forget Username -->
      <div *ngSwitchCase="'forgotUsername'">
        <figure class="ml--7 pb-16">
          <icon size='45px' color='black' name='perm_identity'></icon>
        </figure>
        <div>
          <h2 i18n> Forgot your Username ? </h2>
          <p class="mb-32 font-weight-medium" i18n> Enter your registered email address to receive your username. If you
            don't have an email address in the system, contact a system administrator for assistance </p>
          <div class="validation-alert  alert-error overflow-hidden mb-8" *ngIf="userNotFound">
            <p class="small d-flex align-items-center mb-0">
              <icon name="error_outline" size="24px" type="material" class="pt-0 d-flex mr-8"></icon> <span
                i18n>{{userNotFound}} </span>
            </p>
          </div>
        </div>
        <form #f="ngForm">
          <div class="input-group position-relative  mb-16 login-email">
            <span class="input-group-text" id="email">
              <icon name="email" cuClass="login-form-icon" size="18px" type="material"></icon>
            </span>
            <input type="email" class="form-control medium" name="email" i18n-placeholder
              placeholder="Enter your Email Address" aria-label="Email" aria-describedby="email"
              [(ngModel)]="forgetemail" #test="ngModel" (keyup)="resetMessage()" required [pattern]="emailvalidation"
              [ngClass]="{'error':
              test.invalid && (test.dirty || test.touched)
                }">
            <div class="validation-status position-absolute success" *ngIf="test.dirty && test.errors == null">
              <icon name="check_circle" cuClass="valid-field-icon-green" size="19px" type="material"></icon>
            </div>
            <!-- Invalid icon goes here -->
            <div class="validation-status position-absolute invalid" *ngIf="
                (test.dirty && test.errors?.pattern )
              ">
              <icon name="info" cuClass="invalid-field-icon" size="19px" type="material"></icon>
            </div>
          </div>
          <div class="validation-status invalid msg-input-bottom" *ngIf="test.invalid && (test.dirty || test.touched)">
            <p class="validation-msg">
              <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Please enter a valid
              email address.
            </p>
          </div>

          <div class="d-flex overflow-hidden mb-32">
            <button i18n type="submit" class="btn loginbtn btn-primary large cursor-pointer full-width"
              (click)="sendForgetUsernameMail()" [disabled]="!f.valid">
              Submit
            </button>
          </div>
          <div>
            <p class="text-left font-weight-normal">
              <span i18n> Did you remember your password? </span> <a i18n (click)="getBack()"
                class="links cursor-pointer font-weight-semibold"> Try logging in</a>
            </p>
          </div>
        </form>
      </div>

      <!-- forget username end here -->

      <div class="text-center p-32" *ngSwitchCase="'EmailVerification'">
        <icon name="mark_email_read" cuClass="login-form-icon" size="46px" type="material" class="blue-text"></icon>
        <p class="pt-16 font-weight-normal"><span i18n> We send username to your Registered Email</span>
          <a i18n class="links"> {{forgetemail}}</a>
        </p>
        <p i18n>Please check your inbox to continue</p>
        <p class="font-weight-normal text-center pb-24 "><span i18n> Didn’t receive the email? </span> <a i18n
            (click)="sendForgetUsernameMail()" class="links cursor-pointer font-weight-semibold"> Resend </a></p>
        <div class="d-flex justify-content-center"><button type="button" i18n class="btn btn-light"
            (click)="condCheckerObj['formtype'] = 'defaultpage'"> Back to Login </button></div>
      </div>

    </div>
    <!-- right panel end here -->
  </div>
  <!--main wrapper end here  -->
</div>