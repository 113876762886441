import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';

@Component({
  selector: 'app-job-detail-static',
  templateUrl: './job-detail-static.component.html',
  styleUrls: ['./job-detail-static.component.scss']
})
export class JobDetailStaticComponent implements OnInit {
  toggleAction = false;
  shortDescription = true;
  btnText = "Read more";
  jobDetals = true;
  availableCandidate = false;
  SubmittedCandidate = false;
  interviews = false;

  @ViewChild('actionButton', { read: ElementRef, static: false }) actionButton: ElementRef;
  @ViewChild('actionDropdown', { read: ElementRef, static: false }) actionDropdown: ElementRef;
  constructor(private render: Renderer2) {
    this.render.listen('window', 'click', (e: Event) => {
      if ((this.actionButton && this.actionButton.nativeElement.contains(e.target) && !this.toggleAction) ||
        (this.actionDropdown && this.actionDropdown.nativeElement.contains(e.target))) {
        this.toggleAction = true;
      } else {
        this.toggleAction = false;
      }
    });
  }

  ngOnInit(): void {
  }

  clicktoExpand() {
    this.shortDescription = !this.shortDescription;
    if(this.btnText == "Read more") {
      this.btnText = "view less"
    }
    else {
      this.btnText = "Read more"
    }
  }

  openJobDetail() {
    this.jobDetals = true;
    this.availableCandidate = false;
    this.interviews = false;
    this.SubmittedCandidate = false;
  }

  openAvailableCandidate() {
    this.availableCandidate = true;
    this.jobDetals = false;
    this.interviews = false;
    this.SubmittedCandidate = false;
  }

  openSubmittedCandidate() {
    this.SubmittedCandidate = true;
    this.jobDetals = false;
    this.availableCandidate = false;
    this.interviews = false;
  }

  openInterviews() {
    this.interviews = true;
    this.jobDetals = false;
    this.availableCandidate = false;
    this.SubmittedCandidate = false;
  }
}
 