<div class="form-group">
  <ng-select (change)="selectedPickListName($event)" bindLabel="name" [clearable]="false" [searchable]="true"
    [multiple]="false" bindLabel="name" autofocus placeholder="Select role" ngDefaultControl>
    <ng-option *ngFor="let picklist of pickListData" [value]="picklist">
      {{picklist?.name}}
    </ng-option>
  </ng-select>
  <div class="svms-custom-data mt-12">
    <ul>
      <li *ngFor="let item of pickListItemsData | slice:0:3;let i = index">
        {{item.label}}
      </li>
      <span *ngIf="isShowAllItems">
        <li *ngFor="let item of pickListItemsData | slice:3;let i = index">
          {{item.label}}
        </li>
      </span>
    </ul>
    <div class="svms-custom-btn" *ngIf="pickListItemsData.length > 0">
      <a *ngIf="!isShowAllItems" href="javascript:void()" class="text-green">+{{pickListItemsData.length - 3}} more Values</a>
      <a *ngIf="!isShowAllItems" href="javascript:void()" class="text-blue text-underline ml-auto" (click)="showAllItems()">View All</a>
      <a *ngIf="isShowAllItems" href="javascript:void()" class="text-blue text-underline ml-auto" (click)="hideAllItems()">Hide All</a>
    </div>
  </div>
  <div *ngIf="pickListItemsData.length > 0" class="form-group mt-12">
    <label>
      Select Default Value
    </label>
    <ng-select   bindLabel="name" [clearable]="false" [searchable]="true"
    [multiple]="false" bindLabel="name" autofocus placeholder="Select role" ngDefaultControl>
    <ng-option *ngFor="let picklist of pickListItemsData" [value]="picklist">
      {{picklist?.label}}
    </ng-option>
  </ng-select>
  </div>
</div>