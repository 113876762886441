import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  AfterViewInit,
  Input,
} from '@angular/core';
import StickySidebar from 'sticky-sidebar';
import { ThemeService } from '../../../core/services/theme.service';
import { SidebarModule, SubItemModule } from './sidebar.module';
import { SidebarService } from './sidebar.service';
import { StorageService } from '../../../core/services/storage.service';
import { UserDataObj } from 'src/app/shared/enums';
import { Router } from '@angular/router';
import { EventStreamService, Events } from '../../../core/services/event-stream.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @Output()
  buttonClicked: EventEmitter<string> = new EventEmitter<string>();
  sidebarToggle = '';
  @Input()
  addZindexClass;
  tooltipvisible = 'hidden';
  currentUrl;
  account: any;
  activeSideBar: string = '';
  activeSideBarSub: string = '';
  isToggled: string = '';
  sideBarData: SidebarModule[];
  sideBar: SidebarModule;
  public userAvatar: string;
  public userName: string;
  public userData = UserDataObj;
  public user: any;
  public userType: any;
  currentProgram: any;
  constructor(
    public themeService: ThemeService,
    private sidebarService: SidebarService,
    private router: Router,
    private _storageService: StorageService,
    public eventStrems: EventStreamService
  ) { }

  ngOnInit(): void {
    this.user = this._storageService.get('user');
    this.userType = this._storageService.get('user_type');
    this.account = this._storageService.get('account');
    let tooltip = this._storageService.get(this.userData[6]);
    if (tooltip && tooltip == "visible") {
      this.tooltipvisible = "visible";
    }

    let sidebar = this._storageService.get(this.userData[5]);

    if (sidebar) {
      document.body.classList.add("collapse");
    }
    else {
      document.body.classList.remove("collapse");
    }
    setTimeout(() => {
      if (sidebar) {
        this.buttonClicked.emit((this.sidebarToggle = sidebar));
        if (sidebar == "mini-sidebar") {
          this.tooltipvisible = "visible";
        }
      }
      else {
        this.buttonClicked.emit((this.sidebarToggle = 'mini-sidebar'));
        this.tooltipvisible = "visible";
      }
    }, 0);
    let userObj = this._storageService.get(this.userData[1]);
    if (userObj && userObj.email) {
      let userid = userObj.email.split('@');
      this.userAvatar = userid[0];
      this.userName = userid[0];
    }
    this.sideBarData = this.sidebarService.getSideMenu();
    const autoSelectSideBarData = this.getNamePerentAndSubByUrl(this.router.url, this.sideBarData)
    this.activeSideBar = autoSelectSideBarData.side
    this.activeSideBarSub = autoSelectSideBarData.sub

    this.eventStrems.on(Events.PROGRAM_SIDEBAR, (data) => {
      this.currentProgram = this._storageService.get('CurrentProgram');
    });

    this.currentProgram = this._storageService.get('CurrentProgram');
  }

  ngAfterViewInit() {
    this.currentUrl = this.router.url
  }


  toggleSidebar() {
    if (this.sidebarToggle == '') {
      this.buttonClicked.emit((this.sidebarToggle = 'mini-sidebar'));
      this._storageService.set(this.userData[5], 'mini-sidebar', true);
      this._storageService.set(this.userData[6], "visible", true);
      this.tooltipvisible = "visible";
      document.body.classList.add("collapse");
    } else {
      this.buttonClicked.emit((this.sidebarToggle = ''));
      this._storageService.set(this.userData[5], '', true);
      this._storageService.set(this.userData[6], "", true);
      this.tooltipvisible = "hidden";
      document.body.classList.remove("collapse");
    }
  }

  openSideMenu(name: string, open = false) {
    if (open) {
      if (this.isToggled === name) {
        this.isToggled = '';
        this.sideBar = undefined;
        return;
      }
      this.sideBar = this.sideBarData.find((s) => s.name === name);
      this.isToggled = name;
    } else {
      this.isToggled = '';
      this.sideBar = undefined;
    }
  }

  toActiveSideBar(submenu, name: string, is_sub = false) {
    // console.log(name)
    if (is_sub) {
      this.activeSideBarSub = name
    } else {
      this.activeSideBar = name
    }
    if (!submenu?.isSideMenu) {
      let url = submenu.path;
      if (submenu.params) {
        for (let param of submenu?.params) {
          const localData = this._storageService.get(param) || '';
          url = url.replace(`{${param}}`, localData)
        }
      }
      this.router.navigateByUrl(url)
    }
  }

  // redirectByUrl(url) {
  //   this.isToggled = '';
  //   this.sideBar = undefined;
  //   this.router.navigateByUrl(url);
  // }

  logout() {
    this._storageService.clear();
    this.router.navigate(['/auth/login']);
  }

  closeSidebar() {
    this.isToggled = '';
    this.sideBar = undefined;
  }

  getNamePerentAndSubByUrl(url: string, data) {
    let mainMenuName = ''
    let submenuName = ''
    for (let d of data) {
      if (d?.isSideMenu) {
        for (let c of d?.sideBarSubMenu) {
          for (let cl of c?.subMenuItem) {
            if (cl?.path === url) {
              mainMenuName = d?.name
              submenuName = cl?.title
              break;
            }
          }
        }
      } else {
        if (d?.path && d?.path === url) {
          mainMenuName = d?.name
          break;
        }
      }
    }
    return { side: mainMenuName, sub: submenuName }
  }


  createFullName(users) {
    const user = this._storageService.get('user');
    if (user?.full_name) {
      return user?.full_name;
    } else {
      if (user?.middle_name) {
        return user?.first_name + ' ' + user?.middle_name + ' ' + user?.last_name
      } else {
        return user?.first_name + ' ' + user?.last_name
      }
    }
  }
}
