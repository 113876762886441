<div class="create-wrapper p-24">
  <div class="create-form pt-32">
    <h3 class="font-weight-medium">Create Job</h3>
    <div class="card bg-light mt-24">
      <div class="breadcrumb d-flex">
        <div (click)="goToBasic()" class="item pl-24 pr-24" [ngClass]="{active : basicInfo, finished : financialDetail || jobDetails}"><span
            class="mr-8">1</span>
          <icon class="mr-8" size="20px" name="done_outline"></icon> Basic Info
        </div>
        <div (click)="goToFinancialDetail()" class="item pl-24 pr-24" [ngClass]="{active : financialDetail, finished : jobDetails}"><span
            class="mr-8">2</span>
          <icon class="mr-8" size="20px" name="done_outline"></icon> Financial Details
        </div>
        <div (click)="goToJobDetails()" class="item pl-24 pr-24" [ngClass]="{active : jobDetails}"><span class="mr-4">3</span>
          <icon class="mr-8" size="20px" name="done_outline"></icon> Job Details
        </div>
      </div>
      <div class="p-32" *ngIf="basicInfo">
        <div class="p-8">
          <div class="form-group">
            <label>
              Job Template <span class="require">*</span>
            </label>
            <div class="with-icon position-relative">
              <icon size="24" name="search"></icon>
              <input type="text" class="form-control" placeholder="Search template by name or job title">
            </div>
          </div>
          <div class="form-group-row">
            <div class="form-group">
              <label>
                Hierarchy <span class="require">*</span>
              </label>
              <ng-select placeholder="Choose Hierarchy">
                <ng-option [value]="'USTech Solutions'">USTech Solutions</ng-option>
                <ng-option [value]="'Simplify VMS'">Simplify VMS</ng-option>
              </ng-select>
            </div>
            <div class="form-group">
              <label>
                Job Location <span class="require">*</span>
              </label>
              <ng-select placeholder="Select Job Location">
                <ng-option [value]="'USA'">USA</ng-option>
                <ng-option [value]="'Canada'">Canada</ng-option>
              </ng-select>
            </div>
            <div class="form-group managers">
              <label>
                Job Manager <span class="require">*</span>
              </label>
              <ng-select placeholder="Select Job Manager">
                <ng-option [value]="'Suresh Gupta'">
                  <figure class="mr-8">
                    <img src="../../../assets/images/kris.png" alt="">
                  </figure> Suresh Gupta
                </ng-option>
                <ng-option [value]="'Shanu'">
                  <figure class="mr-8">
                    <img src="../../../assets/images/leota.png" alt="">
                  </figure>
                  Shanu
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <label>
              Job Description
            </label>
            <quill-editor [modules]="modules" [styles]="{height: '200px'}"></quill-editor>
          </div>
          <div class="form-group">
            <label>
              Skills
            </label>
            <ng-select placeholder="Select Skills" [(ngModel)]="selectedSkills" [multiple]="true" [clearable]="false">
              <ng-option *ngFor="let skill of skills" value="{{skill.value}}">{{skill.value}}</ng-option>
            </ng-select>
          </div>
          <div class="skills-output" *ngIf="selectedSkills">
            <ul>
              <li *ngFor="let appliedskills of selectedSkills; let i = index" class="mr-8 mb-8">
                <a href="javascript:void(0)" class="deleteSkill" (click)="deleteSkill(i)">
                  <icon name="close" size="14px"></icon>
                </a>
                <P class="mb-8">{{appliedskills}}</P>
                <ul class="level expert">
                  <li class="active"></li>
                  <li class="active"></li>
                  <li class="active"></li>
                  <li class="active"></li>
                  <li></li>
                </ul>
                <span class="level-title">Expert</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="p-32" *ngIf="financialDetail">
        <div class="p-8">
          <div class="form-group-row">
            <div class="form-group">
              <label>
                Currency <span class="require">*</span>
              </label>
              <ng-select placeholder="Select Currency">
                <ng-option [value]="'USD($)'">
                  <img src="../../../assets/images/flag/us.png" width="20px" class="mr-4" />
                  USD($)
                </ng-option>
                <ng-option [value]="'CAD($)'">
                  <img src="../../../assets/images/flag/English (Canada).png" width="20px" class="mr-4" />
                  CAD($)
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group">
              <label>
                Unit of Measure <span class="require">*</span>
              </label>
              <ng-select placeholder="Select Unit">
                <ng-option [value]="'Hourly'">
                  Hourly
                </ng-option>
                <ng-option [value]="'Monthly'">
                  Monthly
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group">
              <label>
                Minimum Rate <span class="require">*</span>
              </label>
              <div class="input-with-fixed-value position-relative">
                <span>$</span>
                <input class="form-control" type="text" placeholder="0.00">
              </div>
            </div>
            <div class="form-group">
              <label>
                maximum Rate <span class="require">*</span>
              </label>
              <div class="input-with-fixed-value position-relative">
                <span>$</span>
                <input class="form-control" type="text" [(ngModel)]="maxRate" placeholder="0.00"
                  (keyup)="showSummary()">
              </div>
            </div>
          </div>
          <div class="form-group-row">
            <div class="form-group date-picker position-relative">
              <label>
                Start Date <span class="require">*</span>
              </label>
              <svms-datepicker [inputdateFormat]="" id="date1" I18n>
              </svms-datepicker>
            </div>
            <div class="form-group date-picker position-relative">
              <label>
                End Date <span class="require">*</span>
              </label>
              <svms-datepicker [inputdateFormat]="" id="date1" I18n>
              </svms-datepicker>
            </div>
            <div class="form-group">
              <label>
                Number of Positions <span class="require">*</span>
              </label>
              <input type="text" class="form-control" placeholder="Number of positions">
            </div>
            <div class="form-group">
              <label>
                Expense Allowed
              </label>
              <div class="radio-group">
                <div *ngFor="let radioitem of items" class="radio-container">
                  <input type="radio" value="{{radioitem.value}}" name="expense-allowed" [(ngModel)]="expense">
                  <span class="checkmark">{{radioitem.value}}</span>
                </div>
              </div>
            </div>
          </div>
          <h4 class="pt-12 pb-12 font-weight-medium">Budget Summary</h4>
          <div class="budget-section d-flex">
            <div class="budget-item mr-16 p-12">
              <icon name="hourglass_empty" size="20" class="green mr-12"></icon>
              <p>1 Month 2 Weeks <span>Duration</span></p>
            </div>
            <div class="budget-item mr-16 p-12">
              <icon name="person_outline" size="20" class="blue mr-12"></icon>
              <p>$ 1,000 <span>Budget /Resource</span></p>
            </div>
            <div class="budget-item mr-16 p-12">
              <icon name="payments" size="20" class="yellow mr-12"></icon>
              <p>$ 12,000 <span>Total Est. Budget</span></p>
            </div>
            <div class="detail-button">
              <button>View Detailed Estimate</button>
            </div>
          </div>
        </div>
      </div>
      <div class="p-32" *ngIf="jobDetails">
        <div class="p-8">
          <div class="toggle-panel toggle-with-icon w-100">
            <div class="toggle-panel-inner p-16" [ngClass]="preicdntified ? 'if-active' : 'if-inactive'">
              <icon name="how_to_reg" size="20px" class="mr-24"></icon>
              <p>Do you have pre-Identified Candidate(s)? </p>
              <span class="toggle-switch" [ngClass]="preicdntified ? 'toggle-on' : 'toggle-off'"
                (click)="onClickToggle()"></span>
            </div>
          </div>
          <h4 class="mt-32 pb-12 font-weight-medium">Foundational Data</h4>
          <div class="form-group-row">
            <div class="form-group">
              <label>
                GL Code <span class="require">*</span>
              </label>
              <ng-select placeholder="Select">
                <ng-option [value]="'Code 1'">Code 1</ng-option>
                <ng-option [value]="'Code 2'">Code 2</ng-option>
              </ng-select>
            </div>
            <div class="form-group">
              <label>
                Cost Center <span class="require">*</span>
              </label>
              <ng-select placeholder="Select">
                <ng-option [value]="'Code 1'">Code 1</ng-option>
                <ng-option [value]="'Code 2'">Code 2</ng-option>
              </ng-select>
            </div>
            <div class="form-group">
              <label>
                Company Code <span class="require">*</span>
              </label>
              <ng-select placeholder="Select">
                <ng-option [value]="'Code 1'">Code 1</ng-option>
                <ng-option [value]="'Code 2'">Code 2</ng-option>
              </ng-select>
            </div>
          </div>
          <h4 class="pt-12 pb-12 font-weight-medium">Custom Fields</h4>
          <div class="form-group-row">
            <div class="form-group">
              <label>
                Custom Field 1
              </label>
              <ng-select placeholder="Select">
                <ng-option [value]="'Code 1'">Code 1</ng-option>
                <ng-option [value]="'Code 2'">Code 2</ng-option>
              </ng-select>
            </div>
            <div class="form-group">
              <label>
                Custom Field 2
              </label>
              <ng-select placeholder="Select">
                <ng-option [value]="'Code 1'">Code 1</ng-option>
                <ng-option [value]="'Code 2'">Code 2</ng-option>
              </ng-select>
            </div>
            <div class="form-group">
              <label>
                Custom Field 3
              </label>
              <ng-select placeholder="Select">
                <ng-option [value]="'Code 1'">Code 1</ng-option>
                <ng-option [value]="'Code 2'">Code 2</ng-option>
              </ng-select>
            </div>
          </div>
          <h4 class="pt-12 pb-12 font-weight-medium">Approval Work Flow</h4>
          <p>Approvers</p>
          <div class="approvers d-flex mb-4 p-12">
            <figure class="mt-4 mr-8">S</figure>
            <p>Suresh Gupta <span>Hiring Manager</span></p>
          </div>
          <div class="approvers d-flex mb-4 p-12">
            <figure class="mt-4 mr-8">J</figure>
            <p>Jason Main <span>Reporting Manager</span></p>
          </div>
          <div class="form-group pt-16 pb-0 mb-0">
            <label>
              Note for Approver(s)
            </label>
            <textarea rows="3" placeholder="Add Note" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="form-footer d-flex p-24">
        <div class="footer-left" *ngIf="showSummaries">
          <h4 class="mb-0 font-weight-medium">
            Market Rate
          </h4>
          <p>What salaries are candidates seeing for positions like this?</p>
        </div>
        <button type="submit" class="btn btn-primary" (click)="clickToContinue()">
          {{btnText}}
          <icon *ngIf="!jobDetails" size="20px" name="chevron_right" type="material" class="icon--on-right" cuClass="icon-cu"></icon>
        </button>
      </div>
    </div>
    <div class="card summary bg-light mt-24 p-32" *ngIf="showSummaries">
      <div class="d-flex">
        <div class="left-panel">
          <div class="child-card pt-8 mt-8">
            <h4 class="pl-16 pr-16 mb-4">Senior Java Developer (Level 5)</h4>
            <p class="pl-16 pr-16 pb-8">
              <icon name="corporate_fare" size="24px" class="mr-8"></icon> United States of America
            </p>
            <div class="card-bottom p-16 d-flex">
              <p class="small">Your Rate</p>
              <p class="large">$135.00</p>
            </div>
          </div>
          <ul class="other-info mt-16">
            <li>10,045 <span>Supply</span></li>
            <li>1,519 <span>Demand</span></li>
            <li>68 <span class="color-gray">Posting Duration</span></li>
            <li>$66.66 <span class="color-gray">Market Hourly Billing ..</span></li>
          </ul>
        </div>
        <div class="right-panel pt-16">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="343" height="236"
            viewBox="0 0 343 236">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_3215" data-name="Rectangle 3215" width="89" height="236" transform="translate(0 0)"
                  fill="#776de0" stroke="#707070" stroke-width="1" />
              </clipPath>
            </defs>
            <g id="Group_3633" data-name="Group 3633" transform="translate(0 0)">
              <path id="Union_11" data-name="Union 11"
                d="M322.565,164.338h.277a.568.568,0,0,1-.119.009A1.561,1.561,0,0,1,322.565,164.338ZM0,164.338H.275a1.527,1.527,0,0,1-.157.009A.562.562,0,0,1,0,164.338Zm161.376,0H.275c2.273-.232,19.876-5.948,99.266-113.83-.106-.472,32.782-47.871,61.833-50.5a.03.03,0,0,1,0-.009l.045,0,.046,0a.021.021,0,0,1,0,.009c29.051,2.628,61.94,50.028,61.831,50.5,79.392,107.883,96.993,113.6,99.265,113.83Z"
                transform="translate(9.357 33.803)" fill="#f6fafd" stroke="rgba(119,109,224,0.56)" stroke-width="2"
                stroke-dasharray="10 6" />
              <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(127)" clip-path="url(#clip-path)">
                <path id="Union_12" data-name="Union 12"
                  d="M322.565,164.338h.277a.568.568,0,0,1-.119.009A1.561,1.561,0,0,1,322.565,164.338ZM0,164.338H.275a1.527,1.527,0,0,1-.157.009A.562.562,0,0,1,0,164.338Zm161.376,0H.275c2.273-.232,19.876-5.948,99.266-113.83-.106-.472,32.782-47.871,61.833-50.5a.03.03,0,0,1,0-.009l.045,0,.046,0a.021.021,0,0,1,0,.009c29.051,2.628,61.94,50.028,61.831,50.5,79.392,107.883,96.993,113.6,99.265,113.83Z"
                  transform="translate(-117.645 33.107)" fill="#776de0" />
              </g>
              <line id="Line_228" data-name="Line 228" y2="164.09" transform="translate(170.616 33.054)" fill="none"
                stroke="#fff" stroke-width="3" stroke-dasharray="10 12" />
              <line id="Line_229" data-name="Line 229" y1="164.09" transform="translate(140.023 33.054)" fill="none"
                stroke="#ffc409" stroke-width="4" />
              <path id="Polygon_16" data-name="Polygon 16"
                d="M7.129,1.508a1,1,0,0,1,1.734,0l6.267,10.9a1,1,0,0,1-.867,1.5H1.729a1,1,0,0,1-.867-1.5Z"
                transform="translate(144.132 17.96) rotate(60)" fill="#ffc409" />
              <text id="Min:22.10" transform="translate(10 219)" fill="#002626" font-size="12"
                font-family="SegoeUI, Segoe UI">
                <tspan x="0" y="0">Min:22.10</tspan>
              </text>
              <text id="Max:65.65" transform="translate(268 218)" fill="#002626" font-size="11"
                font-family="SegoeUI, Segoe UI">
                <tspan x="0" y="0">Max:65.65</tspan>
              </text>
              <text id="Mean_:_43.55" data-name="Mean : $43.55" transform="translate(130 218)" fill="#002626"
                font-size="11" font-family="SegoeUI, Segoe UI">
                <tspan x="0" y="0">Mean : $43.55</tspan>
              </text>
              <rect id="Rectangle_3216" data-name="Rectangle 3216" width="343" height="7" transform="translate(0 195)"
                fill="#fff" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
