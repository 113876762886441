<div>
  <svms-sidebar-body>
    <div class="p-24">
      <h4 class="mb-16">Available fields</h4>

      <div class="d-flex flex-column ">
        <div class="border rounded-sm p-12 filter-panel mb-12 ifvisible" *ngFor="let f of report_Filter_Data">
          <div class="d-flex align-items-center justify-content-between">
            <span class="font-weight-medium">
              {{f?.filter_title}}
              <!-- Job ID -->
            </span>
            <div class="d-flex align-items-center visible cursor-pointer">
              <div class="d-none align-items-center mr-16">
                <span class="mr-4">
                  <icon size="12px" name="remove_red_eye" type="material" cuClass="d-flex" class="class"></icon>
                </span>
                <p class="small">Visible</p>
              </div>
              <div class="d-flex align-items-center add-filter cursor-pointer" (click)="getFilterData(f)">
                <span class="mr-4">
                  <icon *ngIf="f.isFilter" size="12px" name="remove" type="material" cuClass="d-flex" class="class"></icon>
                  <icon *ngIf="!f.isFilter" size="12px" name="add" type="material" cuClass="d-flex" class="class"></icon>
                </span>
                <p class="small">Add Filter</p>
              </div>

              <div class="ml-12 align-items-center active-filter d-none cursor-pointer">
                <span class="mr-4 filter-dot">
                  <icon size="18px" name="filter_alt" type="material" cuClass="d-flex" class="class"></icon>
                </span>
                <p class="small">6 Active Filters</p>
              </div>
            </div>
          </div>
          <!-- show hide data -->
          <div class="input-panel" *ngIf="f?.isFilter">
            <div class="py-12">
              <ng-select [multiple]=" true" [clearable]="false" placeholder="Select {{f?.filter_title}}" [ngModelOptions]="{standalone:true}"
                (change)="selectedFilter($event,f?.filter_id)" [(ngModel)]="report_filters[f.filter_id]" [items]="f.filter_data" bindLabel="text">
              </ng-select>
            </div>
            <!-- selected values -->
            <div class="d-flex flex-wrap flex-row">
              <div *ngFor="let d of report_filters[f?.filter_id];let i = index">
                <div class="badge badge-light mb-12 mr-12 rounded-sm">
                  <span>{{d}}</span>
                  <!-- <span class="ml-8">
                    <icon size="12px" name="close" type="material" cuClass="d-flex" class="class" (click)="removeFilter(f?.filter_id,i)"></icon>
                  </span> -->
                </div>
            </div>
            </div>
            
          </div>

        </div>
      </div>
    </div>
  </svms-sidebar-body>
  <svms-sidebar-footer>
    <div class="d-flex align-items-center justify-content-between">
      <button type="button" class="btn btn-outline-primary" (click)="hideSidebar()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="applyFilter()">Apply</button>
    </div>
  </svms-sidebar-footer>
</div>