<div class="tab-menu">
  <ul class="d-flex ">
    <li *ngFor="let tab of tabs" (click)="selectTab(tab, true)">

      <a href="javascript:void(0)" [class.active]="tab.active" class="d-flex align-items-center">
        <icon *ngIf="tab?.iconLeft" size="20px" [theme]="theme" [name]="tab?.iconLeft" type="material"
          class="icon--on-left d-flex mr-12" cuClass="cu-icon d-flex">
        </icon>
        {{tab.label}}
        <icon *ngIf="tab?.iconRight" size="20px" [name]="tab?.iconRight" type="material"
          class="icon--on-right d-flex ml-12" cuClass="cu-icon d-flex">
        </icon>
      </a>

    </li>
  </ul>
</div>
<ng-content></ng-content>
