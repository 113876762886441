<div class="create-candidate position-relative">
  <div class="create-wrapper p-24">
    <div class="create-form pt-32">
      <h3 class="font-weight-medium">{{formHeading}}</h3>
      <div class="card bg-light mt-24">
        <div class="breadcrumb d-flex">
          <div (click)="back('goToBasic')" class="item pl-24 pr-24" [ngClass]="{active : basicInfo, finished : submitCandidate || qualification}">
            <span class="mr-8">1</span>
            <icon class="mr-8" size="20px" name="done_outline"></icon> Basic Info
          </div>
          <div *ngIf="!isSubmitCandidateRoute" class="item pl-24 pr-24"
            [ngClass]="{active : qualification, finished : submitCandidate}" (click)="continue('goToQualification')"><span class="mr-8">2</span>
            <icon class="mr-8" size="20px" name="done_outline"></icon> Qualification
          </div>
          <div (click)="continue('goToSubmitCandidate')" *ngIf="isSubmitCandidateRoute" class="item pl-24 pr-24" [ngClass]="{'active' : submitCandidate}"><span
              class="mr-8">2</span>
            <icon class="mr-8" size="20px" name="done_outline"></icon> Submission Details
          </div>
        </div>
        <div>
          <section class="basic-info" *ngIf="basicInfo">
            <!-- Resume -->
            <form>
              <div class="mb-24">
                <div class="resume-wrapper p-32">
                  <h4 class="d-flex mb-8 pb-8">
                    <icon class="d-flex mr-8" size="24px" name="insert_drive_file" type="material" cuClass="d-flex">
                    </icon>
                    Resume
                  </h4>
                  <div *ngIf="progressBar"></div>
                  <div class="drop-zone mb-16" *ngIf="!resumeFile.name && !viewMode">
                    <icon size="24px" name="publish" type="material" cuClass="d-flex"></icon>
                  </div>
                  <h4 class="pt-4 mb-4" *ngIf="!resumeFile.name && !viewMode">Drag &amp; Drop file or <a
                      (click)="fileInput.click()">Browse</a></h4>
                  <input hidden type="file" accept=".pdf, .doc, .docx" #fileInput
                    (change)="uploadResume($event,'parsing')">
                  <p class="file-format mb-32" *ngIf="!resumeFile.name && !viewMode">Only .pdf, .doc files with maximum
                    size
                    of 10 MB</p>
                  <div class="autofill pt-32 pb-24"
                    *ngIf="!fileNotSupportAlert && isValidFileSize && !viewMode && resumeFile.name">
                    <div class="toggle-panel">
                      <div class="toggle-panel-inner"
                        [ngClass]="toggleResumeAutoFill?.value ? 'if-active' : 'if-inactive'">
                        <span class="status-text mr-12">Auto Fill the Profile Details from Resume?</span>
                        <span class="toggle-switch" [ngClass]="toggleResumeAutoFill?.value ? 'toggle-on' : 'toggle-off'"
                          (click)="onClickToggleAutofill()"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <ul class="uploaded-list" [ngClass]="{'error': fileNotSupportAlert || !isValidFileSize}">
                  <li *ngIf="resumeFile.name">
                    <figure *ngIf="resumeFile.ext == 'pdf'">
                      <img src="/assets/images/pdf.svg" alt="">
                    </figure>
                    <figure *ngIf="resumeFile.ext == 'docx' || resumeFile.ext == 'doc' ">
                      <img src="/assets/images/word.svg" alt="">
                    </figure>
                    <div class="item-description">
                      {{resumeFile.name}}
                      <icon class="d-inline-block ml-8" size="18px" name="check_circle" type="material" cuClass="d-flex"
                        *ngIf="!fileNotSupportAlert && isValidFileSize"></icon>
                      <p class="upload-size" *ngIf="!fileNotSupportAlert && isValidFileSize">
                        {{resumeFileSize}} {{mbKbFileSize}}<span>|</span>{{resumeFile.time | date:'d MMM'}}
                      </p>
                      <icon class="d-inline-block ml-8" size="18px" name="error" type="material" cuClass="d-flex"
                        *ngIf="fileNotSupportAlert || !isValidFileSize"></icon>
                      <p class="upload-size" *ngIf="!isValidFileSize">
                        Error : File size must be less than 10MB
                      </p>
                      <p class="upload-size" *ngIf="fileNotSupportAlert">
                        Error : File type is not supported. Please upload the resume either in .PDF / .DOC / .DOCX
                        format
                      </p>
                    </div>
                    <a (click)="deleteResume()" class="delete-resume" *ngIf="resumeFile.name && !viewMode">
                      <icon class="d-flex" size="20px" name="delete_forever" type="material" cuClass="d-flex">
                      </icon>
                    </a>
                  </li>
                  <!-- <li *ngIf="!resumeFile.name">
                    <p class="upload-size">
                      Resume not available!
                    </p>
                  </li> -->
                </ul>
              </div>
            </form>
            <!--PERSONAL INFORMATION FORM-->
            <form [formGroup]="personalInfoForm">
              <div class="mb-24 p-32">
                <h4 class="d-flex mb-32 pb-8">
                  <icon class="d-flex mr-8" size="24px" name="person" type="material" cuClass="d-flex"></icon>
                  Personal
                  Information
                </h4>
                <div class="form-group-row">
                  <div class="form-group">
                    <label>
                      First Name <span class="require">*</span>
                    </label>
                    <input type="text" placeholder="Enter First Name" class="form-control" [readOnly]="viewMode"
                      [pattern]="nameValidation" formControlName="firstName">
                    <p class="validation-msg"
                      *ngIf="(personalInfoForm.controls.firstName.touched && personalInfoForm.controls['firstName'].errors)">
                      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> First Name
                      is
                      Required
                    </p>
                  </div>
                  <div class="form-group">
                    <label>
                      Middle Name
                    </label>
                    <input type="text" placeholder="Enter Middle Name" class="form-control" [pattern]="nameValidation"
                      [readOnly]="viewMode" formControlName="middleName">
                  </div>
                  <div class="form-group">
                    <label>
                      Last Name <span class="require">*</span>
                    </label>
                    <input type="text" placeholder="Enter Last Name" class="form-control" [pattern]="nameValidation"
                      [readOnly]="viewMode" formControlName="lastName">
                    <p class="validation-msg"
                      *ngIf="(personalInfoForm.controls.lastName.touched && personalInfoForm.controls['lastName'].errors)">
                      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Last Name
                      is
                      Required
                    </p>
                  </div>
                  <div class="form-group small-input">
                    <label>
                      Prefix
                    </label>
                    <ng-select [clearable]="false" placeholder="Choose" [readonly]="viewMode" [searchable]="false"
                      formControlName="prefix">
                      <ng-option [value]="'mr'">Mr</ng-option>
                      <ng-option [value]="'mrs'">Mrs</ng-option>
                      <ng-option [value]="'miss'">Miss</ng-option>
                      <ng-option [value]="'dr'">Dr</ng-option>
                      <ng-option [value]="'prof'">Prof</ng-option>
                      <ng-option [value]="'mx'">MX.</ng-option>
                      <ng-option [value]="'ind'">IND.</ng-option>
                      <ng-option [value]="'misc'">Misc</ng-option>
                    </ng-select>
                  </div>
                  <div class="form-group small-input">
                    <label>
                      Suffix
                    </label>
                    <ng-select [clearable]="false" placeholder="Choose" [searchable]="false" [readonly]="viewMode"
                      formControlName="suffix">
                      <ng-option [value]="'jr'">Jr</ng-option>
                      <ng-option [value]="'sr'">Sr</ng-option>
                      <ng-option [value]="'III'">III</ng-option>
                      <ng-option [value]="'IV'">IV</ng-option>
                      <ng-option [value]="'V'">V</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label>
                    Title
                    <!-- <span class="require">*</span> -->
                  </label>
                  <input type="text" placeholder="Eg: Senior UX Designer, Senior Java Developer etc.."
                    [readOnly]="viewMode" class="form-control" formControlName="title" [readOnly]="viewMode">
                  <!-- ### Ucomment in case of needed validatino ####  
                    <p class="validation-msg"
                    *ngIf="(personalInfoForm.controls.title.touched && personalInfoForm.controls['title'].errors)">
                    <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Title is
                    Required
                  </p> -->
                </div>
              </div>
            </form>
            <!-- personal info form ends here -->
            <!--CONTACT INFORMATION FORM-->
            <form [formGroup]="contactInfoForm">
              <div class="mb-24 p-32">
                <h4 class="d-flex mb-32 pb-8">
                  <icon class="d-flex mr-8" size="24px" name="add_ic_call" type="material" cuClass="d-flex"></icon>
                  Contact
                  Information
                </h4>
                <div class="form-group">
                  <label>
                    Email Address <span class="require">*</span>
                  </label>
                  <input type="email" placeholder="Enter Email Address" class="form-control" [readOnly]="viewMode"
                    [pattern]="emailvalidation" formControlName="email">
                  <p class="validation-msg"
                    *ngIf="(contactInfoForm.controls.email.touched && contactInfoForm.controls['email'].errors)">
                    <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Email Address
                    is not valid.
                  </p>
                </div>
                <div class="form-group-row">
                  <div class="form-group">
                    <label>
                      Country
                    </label>
                    <ng-select bindLabel="name" [clearable]="false" placeholder="Select Country" [readonly]="viewMode"
                      ngDefaultControl formControlName="country" (change)="setCountry()">
                      <ng-option [value]="'Canada'">Canada</ng-option>
                      <ng-option [value]="'USA'">USA</ng-option>
                    </ng-select>
                  </div>
                  <div class="form-group">
                    <label>
                      Address
                    </label>
                    <search-address [adressType]="'geocode'" (setAddress)="getAddress($event)"
                      [country]="countryShortName" id="addressField" formControlName="address" #candidateAddress>
                    </search-address>
                    <p class="validation-msg"
                      *ngIf="(contactInfoForm.controls.address.touched && contactInfoForm.controls['address'].errors)">
                      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Address is
                      not valid
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label>
                    Phone
                  </label>
                  <div class="d-flex align-items-center country-searchable">
                    <ng-select [searchable]="false" [clearable]="false" ngDefaultControl [readonly]="viewMode"
                      formControlName="phoneFormat">
                      <ng-option [value]="'+12'">
                        <img src="../../../assets/images/flag/English (Canada).png" width="20px" class="mr-4" />
                        <span>+1</span>
                      </ng-option>
                      <ng-option [value]="'+1'">
                        <img src="../../../assets/images/flag/us.png" width="20px" class="mr-4" />
                        <span>+1</span>
                      </ng-option>
                    </ng-select>
                    <input type="number" class="form-control" placeholder="Enter Phone Number" formControlName="phone"
                      [readOnly]="viewMode" [pattern]="mobNumberPattern"
                      [ngClass]="{'error':contactInfoForm.controls['phone'].invalid && (contactInfoForm.controls['phone'].dirty || contactInfoForm.controls['phone'].touched)}">
                  </div>
                  <p *ngIf="
                  contactInfoForm.get('phone').touched &&
                          (contactInfoForm.get('phone').errors?.pattern || contactInfoForm.get('phone').errors?.required || contactInfoForm.get('phone').invalid)
                        " class="validation-msg">
                    <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
                    Phone number not valid.
                  </p>
                </div>
              </div>
            </form>
            <!--contact info form ends here-->
            <!-- visa start here -->
            <form [formGroup]="visaInfoForm" hidden>
              <div class="mb-24 p-32">
                <div class="toggle-disable position-relative">
                  <div class="toggle-panel">
                    <div class="toggle-panel-inner">
                      <span class="toggle-switch" [ngClass]="toggleDisable?.value ? 'toggle-on' : 'toggle-off'"
                        (click)="onClickToggleDisable()"></span>
                    </div>
                  </div>
                </div>
                <h4 class="d-flex mb-32 pb-8">
                  <icon class="d-flex mr-8" size="24px" name="language" type="material" cuClass="d-flex"></icon> Visa
                  Details
                </h4>
                <div class="form-group-row" *ngIf="toggleDisable.value">
                  <div class="form-group">
                    <label>Visa Type <span class="require">*</span></label>
                    <input type="text" placeholder="Enter Visa Type" [readOnly]="viewMode" formControlName="type"
                      class="form-control">
                  </div>
                  <div class="form-group visa-expiration position-relative">
                    <label>Visa Expiration Date</label>
                    <svms-datepicker [inputdateFormat]="" [placeholder]="'Select Date'" formControlName="date"
                      ngDefaultControl id="date1" I18n #dateFiled>
                    </svms-datepicker>
                  </div>
                </div>
              </div>
            </form>
            <!-- visa end here -->
            <div class="mb-24 p-32" *ngIf="isSubmitCandidateRoute">
              <div class="add-new position-relative" *ngIf="!viewMode">
                <button type="submit" class="btn small btn-outine" (click)="addNewSkill($event)">
                  <icon size="20px" name="add" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
                  Add
                </button>
              </div>
              <h4 class="d-flex mb-16 pb-4">
                <icon class="d-flex mr-8" size="24px" name="category" type="material" cuClass="d-flex"></icon>
                Skills
              </h4>
              <ul class="item-list" *ngFor="let skill of skillsArr, let i = index">
                <li class="clearfix position-relative  mb-12">
                  <a (click)="editPanel('SKILL',skill,i)" class="edit-item" *ngIf="!viewMode">
                    <icon class="d-flex mr-8" size="24px" name="edit" type="material" cuClass="d-flex"></icon>
                  </a>
                  <figure class="bullet float-left text-center d-flex mt-12">
                    <img src="/assets/images/medicine.svg" alt="">
                  </figure>
                  <div class="item-description float-right pt-12 full-width">
                    <h4>{{skill.skill_name}}</h4>
                    <p>Experience : {{skill.experience_level}}</p>
                    <p class="mt-8">{{skill.description}}
                      <!-- <a class="read-more text-underline"
                        href="#">More</a> -->
                    </p>
                  </div>
                </li>
              </ul>
              <div class="no-document text-center mb-32" *ngIf="!skillsArr.length">
                <img src="/assets/images/specialities.svg" alt="">
                <p class="font-weight-medium">No Skills Added !</p>
                <p class="small mb-8">Here you can add the Skills here</p>
              </div>
            </div>
            <!-- social media start here -->
            <form *ngIf="!isSubmitCandidateRoute">
              <div class="mb-24 p-32">
                <div class="add-new position-relative" *ngIf="!viewMode">
                  <button type="submit" class="btn small btn-outine" (click)="addSocialLink()">
                    <icon size="20px" name="add" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
                    Add New
                  </button>
                </div>
                <h4 class="d-flex mb-32 pb-8">
                  <icon class="d-flex mr-8" size="24px" name="connect_without_contact" type="material" cuClass="d-flex">
                  </icon>
                  Social Media Profile(s)
                </h4>
                <div class="form-group-row" *ngFor="let social of socialProfileArr, let i = index">
                  <div class="form-group mr-0 social-application">
                    <label>Application</label>
                    <ng-select [searchable]="false" [clearable]="false" [readonly]="viewMode"
                      placeholder="Choose Application" ngDefaultControl class="no-right-border"
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="social.type"
                      (change)="selectSocialApp(social)">
                      <ng-option [value]="'linkedin'">
                        <img src="/assets/images/linkedin.svg" class="mr-12">
                        <span>Linked In</span>
                      </ng-option>
                      <ng-option [value]="'facebook'">
                        <img src="/assets/images/facebook.svg" class="mr-12 pr-4">
                        <span>Facebook</span>
                      </ng-option>
                      <ng-option [value]="'git'">
                        <img src="/assets/images/github.svg" class="mr-12">
                        <span>GitHub</span>
                      </ng-option>
                      <ng-option [value]="'skype'">
                        <img src="/assets/images/skype.svg" class="mr-12">
                        <span>Skype</span>
                      </ng-option>
                      <ng-option [value]="'instagram'">
                        <img src="/assets/images/instagram.svg" class="mr-12">
                        <span>Instagram</span>
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="form-group">
                    <label>Enter Profile Link</label>
                    <input type="text" class="form-control no-left-border" [readOnly]="viewMode"
                      [ngModelOptions]="{standalone: true}" placeholder="Enter Your profile url"
                      [(ngModel)]="social.link" (keyup)="checkSocialApp(social)">
                  </div>
                  <div class="form-group add-remove" *ngIf="!viewMode">
                    <label>&nbsp;</label>
                    <a (click)="deleteSocialLink(i)" class="add-link">
                      <icon class="remove-icon" size="24px" name="remove_circle" type="material" cuClass="d-flex">
                      </icon>
                    </a>
                  </div>
                </div>
              </div>
            </form>
            <!-- social media end here -->

            <!-- Preferences start here -->
            <form [formGroup]="candidatePreference" *ngIf="!isSubmitCandidateRoute" (ngSubmit)="onPrefereSubmit()">
              <div class=" mb-24 p-32">
                <div class="toggle-disable position-relative">
                  <div class="toggle-panel" [ngClass]="{'disable':viewMode}">
                    <div class="toggle-panel-inner">
                      <span class="toggle-switch" [ngClass]="togglePreference?.value ? 'toggle-on' : 'toggle-off'"
                        (click)="onClickTogglePreference()"></span>
                    </div>
                  </div>
                </div>
                <h4 class="d-flex mb-32 pb-8">
                  <icon class="d-flex mr-8" size="24px" name="psychology" type="material" cuClass="d-flex"></icon>
                  Preferences
                </h4>
                <div *ngIf="togglePreference.value">
                  <div class="form-group-row">
                    <div class="form-group">
                      <label>
                        Preferred Rate
                      </label>
                      <div class="d-flex align-items-center">
                        <ng-select [clearable]="false" [searchable]="false" formControlName="currency"
                          [readonly]="viewMode">
                          <ng-option [value]="curr.name" *ngFor="let curr of currencies">
                            {{curr.label == 'USD' ? '$' : curr.label}}</ng-option>
                        </ng-select>
                        <!-- <input type="number" class="form-control" [readOnly]="viewMode" placeholder="0.00" formControlName="rate"> -->
                        <input type="number" class="form-control" [readOnly]="viewMode" placeholder="0.00"
                          [pattern]="preferredRatePattern" formControlName="rate" [ngClass]="{'error':
                        candidatePreference.controls['rate'].invalid && !viewMode}">
                      </div>
                    </div>
                    <div class="form-group">
                      <label>
                        Select Period
                      </label>
                      <ng-select [clearable]="false" placeholder="Select Period" [searchable]="false"
                        [readonly]="viewMode" formControlName="period">
                        <ng-option [value]="'hour'">Hour</ng-option>
                        <ng-option [value]="'day'">Day</ng-option>
                        <ng-option [value]="'week'">Week</ng-option>
                        <ng-option [value]="'month'">Month</ng-option>
                      </ng-select>
                    </div>
                  </div>
                  <div class="location-box position-relative">
                    <ng-select bindLabel="locations" [clearable]="false" placeholder="Enter preferred locations"
                      [multiple]="true" [readonly]="viewMode" ngDefaultControl formControlName="locations">
                      <ng-option [value]="'US'">USA</ng-option>
                      <ng-option [value]="'Canada'">Canada</ng-option>
                    </ng-select>
                  </div>
                  <div class="form-group-row" hidden>
                    <div class="form-group custom-width-big">
                      <label>Preferred Availability</label>
                    </div>
                    <div class="form-group custom-width-small">
                      <label>From</label>
                    </div>
                    <div class="form-group custom-width-small">
                      <label>To</label>
                    </div>
                    <div class="form-group add-remove"></div>
                  </div>
                  <div [formGroup]="preferredAvailability" hidden>
                    <div class="item-row clearfix" formArrayName="sun">
                      <div class="left-col float-left">
                        <div class="toggle-panel">
                          <div class="toggle-panel-inner" [ngClass]="toggle?.sun?.value ? 'if-active' : 'if-inactive'">
                            <span class="status-text">Sunday</span>
                            <span class="toggle-switch" [ngClass]="toggle?.sun?.value ? 'toggle-on' : 'toggle-off'"
                              (click)="onClickToggle('sun')"></span>
                          </div>
                        </div>
                      </div>
                      <div class="right-col float-right" *ngFor="let item of sunTime, let i = index"
                        [formGroupName]="i">
                        <div class="select-box-wrap float-left">
                          <div class="form-group-row" [formGroupName]="'time'">
                            <div class="form-group">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="from">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                            <div class="form-group">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="to">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="add-remove-box float-right">
                          <div class="add-remove pt-4" *ngIf="i===0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onAddTime('sun', false, !toggle?.sun?.value)">
                              <icon size="24px" name="add" type="material" cuClass="d-flex"></icon>
                            </a>
                          </div>
                          <div class="add-remove pt-4" *ngIf="i>0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onRemoveTime('sun', i, !toggle?.sun?.value)">
                              <icon class="remove-icon" size="24px" name="remove_circle" type="material"
                                cuClass="d-flex">
                              </icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-row clearfix" formArrayName="mon">
                      <div class="left-col float-left">
                        <div class="toggle-panel">
                          <div class="toggle-panel-inner" [ngClass]="toggle?.mon?.value ? 'if-active' : 'if-inactive'">
                            <span class="status-text">Monday</span>
                            <span class="toggle-switch" [ngClass]="toggle?.mon?.value ? 'toggle-on' : 'toggle-off'"
                              (click)="onClickToggle('mon')"></span>
                          </div>
                        </div>
                      </div>
                      <div class="right-col float-right" *ngFor="let item of monTime, let i = index"
                        [formGroupName]="i">
                        <div class="select-box-wrap float-left">
                          <div class="form-group-row" [formGroupName]="'time'">
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="from">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="to">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="add-remove-box float-right">
                          <div class="form-group add-remove pt-4" *ngIf="i===0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onAddTime('mon', false, !toggle?.mon?.value)">
                              <icon size="24px" name="add" type="material" cuClass="d-flex"></icon>
                            </a>
                          </div>
                          <div class="form-group add-remove pt-4" *ngIf="i>0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onRemoveTime('mon', i, !toggle?.mon?.value)">
                              <icon class="remove-icon" size="24px" name="remove_circle" type="material"
                                cuClass="d-flex">
                              </icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-row clearfix" formArrayName="tue">
                      <div class="left-col float-left">
                        <div class="toggle-panel">
                          <div class="toggle-panel-inner" [ngClass]="toggle?.tue?.value ? 'if-active' : 'if-inactive'">
                            <span class="status-text">Tuesday</span>
                            <span class="toggle-switch" [ngClass]="toggle?.tue?.value ? 'toggle-on' : 'toggle-off'"
                              (click)="onClickToggle('tue')"></span>
                          </div>
                        </div>
                      </div>
                      <div class="right-col float-right" *ngFor="let item of tueTime, let i = index"
                        [formGroupName]="i">
                        <div class="select-box-wrap float-left">
                          <div class="form-group-row" [formGroupName]="'time'">
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="from">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="to">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="add-remove-box float-right">
                          <div class="form-group add-remove pt-4" *ngIf="i===0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onAddTime('tue', false, !toggle?.tue?.value)">
                              <icon size="24px" name="add" type="material" cuClass="d-flex"></icon>
                            </a>
                          </div>
                          <div class="form-group add-remove pt-4" *ngIf="i>0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onRemoveTime('tue', i, !toggle?.tue?.value)">
                              <icon class="remove-icon" size="24px" name="remove_circle" type="material"
                                cuClass="d-flex">
                              </icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-row clearfix" formArrayName="wed">
                      <div class="left-col float-left">
                        <div class="toggle-panel">
                          <div class="toggle-panel-inner" [ngClass]="toggle?.wed?.value ? 'if-active' : 'if-inactive'">
                            <span class="status-text">Wednesday</span>
                            <span class="toggle-switch" [ngClass]="toggle?.wed?.value ? 'toggle-on' : 'toggle-off'"
                              (click)="onClickToggle('wed')"></span>
                          </div>
                        </div>
                      </div>
                      <div class="right-col float-right" *ngFor="let item of wedTime, let i = index"
                        [formGroupName]="i">
                        <div class="select-box-wrap float-left">
                          <div class="form-group-row" [formGroupName]="'time'">
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="from">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="to">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="add-remove-box float-right">
                          <div class="form-group add-remove pt-4" *ngIf="i===0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onAddTime('wed', false, !toggle?.wed?.value)">
                              <icon size="24px" name="add" type="material" cuClass="d-flex"></icon>
                            </a>
                          </div>
                          <div class="form-group add-remove pt-4" *ngIf="i>0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onRemoveTime('wed', i, !toggle?.wed?.value)">
                              <icon class="remove-icon" size="24px" name="remove_circle" type="material"
                                cuClass="d-flex">
                              </icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-row clearfix" formArrayName="thu">
                      <div class="left-col float-left">
                        <div class="toggle-panel">
                          <div class="toggle-panel-inner" [ngClass]="toggle?.thu?.value ? 'if-active' : 'if-inactive'">
                            <span class="status-text">Thursday</span>
                            <span class="toggle-switch" [ngClass]="toggle?.thu?.value ? 'toggle-on' : 'toggle-off'"
                              (click)="onClickToggle('thu')"></span>
                          </div>
                        </div>
                      </div>
                      <div class="right-col float-right" *ngFor="let item of thuTime, let i = index"
                        [formGroupName]="i">
                        <div class="select-box-wrap float-left">
                          <div class="form-group-row" [formGroupName]="'time'">
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="from">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="to">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="add-remove-box float-right">
                          <div class="form-group add-remove pt-4" *ngIf="i===0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onAddTime('thu', false, !toggle?.thu?.value)">
                              <icon size="24px" name="add" type="material" cuClass="d-flex"></icon>
                            </a>
                          </div>
                          <div class="form-group add-remove pt-4" *ngIf="i>0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onRemoveTime('thu', i, !toggle?.thu?.value)">
                              <icon class="remove-icon" size="24px" name="remove_circle" type="material"
                                cuClass="d-flex">
                              </icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-row clearfix" formArrayName="fri">
                      <div class="left-col float-left">
                        <div class="toggle-panel">
                          <div class="toggle-panel-inner" [ngClass]="toggle?.fri?.value ? 'if-active' : 'if-inactive'">
                            <span class="status-text">Friday</span>
                            <span class="toggle-switch" [ngClass]="toggle?.fri?.value ? 'toggle-on' : 'toggle-off'"
                              (click)="onClickToggle('fri')"></span>
                          </div>
                        </div>
                      </div>
                      <div class="right-col float-right" *ngFor="let item of friTime, let i = index"
                        [formGroupName]="i">
                        <div class="select-box-wrap float-left">
                          <div class="form-group-row" [formGroupName]="'time'">
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="from">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="to">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="add-remove-box float-right">
                          <div class="form-group add-remove pt-4" *ngIf="i===0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onAddTime('fri', false, !toggle?.fri?.value)">
                              <icon size="24px" name="add" type="material" cuClass="d-flex"></icon>
                            </a>
                          </div>
                          <div class="form-group add-remove pt-4" *ngIf="i>0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onRemoveTime('fri', i, !toggle?.fri?.value)">
                              <icon class="remove-icon" size="24px" name="remove_circle" type="material"
                                cuClass="d-flex">
                              </icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-row clearfix" formArrayName="sat">
                      <div class="left-col float-left">
                        <div class="toggle-panel">
                          <div class="toggle-panel-inner" [ngClass]="toggle?.sat?.value ? 'if-active' : 'if-inactive'">
                            <span class="status-text">Saturday</span>
                            <span class="toggle-switch" [ngClass]="toggle?.sat?.value ? 'toggle-on' : 'toggle-off'"
                              (click)="onClickToggle('sat')"></span>
                          </div>
                        </div>
                      </div>
                      <div class="right-col float-right" *ngFor="let item of satTime, let i = index"
                        [formGroupName]="i">
                        <div class="select-box-wrap float-left">
                          <div class="form-group-row" [formGroupName]="'time'">
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="from">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                            <div class="form-group custom-width-small">
                              <ng-select [clearable]="false" placeholder="Select" [searchable]="false"
                                formControlName="to">
                                <ng-option [value]="'9 am'">9 am</ng-option>
                                <ng-option [value]="'10 am'">10 am</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="add-remove-box float-right">
                          <div class="form-group add-remove pt-4" *ngIf="i===0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onAddTime('sat', false, !toggle?.sat?.value)">
                              <icon size="24px" name="add" type="material" cuClass="d-flex"></icon>
                            </a>
                          </div>
                          <div class="form-group add-remove pt-4" *ngIf="i>0">
                            <a href="javascript:void(0)" class="add-link"
                              (click)="onRemoveTime('sat', i, !toggle?.sat?.value)">
                              <icon class="remove-icon" size="24px" name="remove_circle" type="material"
                                cuClass="d-flex">
                              </icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <!-- Preferences end here -->
          </section>
          <section class="qualification" *ngIf="qualification">
            <!-- Certifications start here -->
            <div class="mb-24 p-32">
              <div class="add-new position-relative" *ngIf="!viewMode">
                <button type="submit" class="btn small btn-outine" (click)="addNewCertificate($event)">
                  <icon size="20px" name="add" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
                  Add
                </button>
              </div>
              <h4 class="d-flex mb-16 pb-4">
                <icon class="d-flex mr-8" size="24px" name="verified" type="material" cuClass="d-flex"></icon>
                Certifications
              </h4>
              <ul class="item-list" *ngFor="let certi of certificationsArr, let i = index">
                <li class="clearfix position-relative  mb-12">
                  <a class="edit-item" (click)="editPanel('CERTIFICATION',certi, i)" *ngIf="!viewMode">
                    <icon class="d-flex mr-8" size="24px" name="edit" type="material" cuClass="d-flex"></icon>
                  </a>
                  <figure class="bullet float-left text-center d-flex mt-12">
                    <img src="/assets/images/badge.svg" alt="">
                  </figure>
                  <div class="item-description float-right pt-12">
                    <h4>{{certi.certification_name}}</h4>
                    <p>{{certi.authority}}</p>
                    <p>Issued {{certi.initial_date | date:'dd MMM y'}} .
                      {{certi.expiration_date ? 'Expired on ' + (certi.expiration_date | date:'dd MMM y') : 'No Expiration Date'}}
                    </p>
                    <p class="mt-8">Credential ID <a href="javascript:void()">{{certi.certification_id}}</a></p>
                    <div *ngFor="let file of certi.files, let i = index">
                      <figure class="item-img mt-16 float-left mr-16">
                        <img src="/assets/images/certificate.svg" alt="">
                        <figcaption>{{file.name}}</figcaption>
                      </figure>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="no-document text-center mb-32" *ngIf="!certificationsArr.length">
                <img src="/assets/images/certifications.svg" alt="">
                <p class="font-weight-medium">No Certifications Added !</p>
                <p class="small mb-8">Here you can add the candidate certifications</p>
              </div>
            </div>
            <!-- Certifications end here -->

            <!-- Credentials start here -->
            <div class="mb-24 p-32">
              <div class="add-new position-relative" *ngIf="!viewMode">
                <button type="submit" class="btn small btn-outine" (click)="addNewCredential($event)">
                  <icon size="20px" name="add" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
                  Add
                </button>
              </div>
              <h4 class="d-flex mb-16 pb-4">
                <icon class="d-flex mr-8" size="24px" name="admin_panel_settings" type="material" cuClass="d-flex">
                </icon>
                Credentials
              </h4>
              <ul class="item-list" *ngFor="let cred of credentialsArr, let i = index">
                <li class="clearfix position-relative  mb-12">
                  <a (click)="editPanel('CREDENTIAL', cred,i)" class="edit-item" *ngIf="!viewMode">
                    <icon class="d-flex mr-8" size="24px" name="edit" type="material" cuClass="d-flex"></icon>
                  </a>
                  <figure class="bullet float-left text-center d-flex mt-12">
                    <img src="/assets/images/verified.svg" alt="">
                  </figure>
                  <div class="item-description float-right pt-12">
                    <h4>{{cred.credential_name}}</h4>
                    <p>{{cred.issuing_authority}} Issued {{cred.initial_issue_date | date:'dd MMM y'}} . Expired on
                      {{cred.expiration_date | date:'dd MMM y'}}</p>
                    <p class="mt-8">Credential ID <a class="text-underline"
                        href="javascript:void()">{{cred.credential_id}}</a></p>
                    <div *ngFor="let file of cred.files, let i = index">
                      <figure class="item-img mt-16 float-left mr-16">
                        <img src="/assets/images/certificate.svg" alt="">
                        <figcaption>{{file.name}}</figcaption>
                      </figure>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="no-document text-center mb-32" *ngIf="!credentialsArr.length">
                <img src="/assets/images/credentials.svg" alt="">
                <p class="font-weight-medium">No Credentials Added !</p>
                <p class="small mb-8">Here you can add the candidate credentials</p>
              </div>
            </div>
            <!-- Credentials end here -->

            <!-- Specialities start here -->
            <div class="mb-24 p-32">
              <div class="add-new position-relative" *ngIf="!viewMode">
                <button type="submit" class="btn small btn-outine" (click)="addSpecialities($event)">
                  <icon size="20px" name="add" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
                  Add
                </button>
              </div>
              <h4 class="d-flex mb-16 pb-4">
                <icon class="d-flex mr-8" size="24px" name="local_library" type="material" cuClass="d-flex"></icon>
                Specialities
              </h4>
              <ul class="item-list" *ngFor="let speciality of specialityArr, let i = index">
                <li class="clearfix position-relative  mb-12">
                  <a (click)="editPanel('SPECIALITY', speciality,i)" class="edit-item" *ngIf="!viewMode">
                    <icon class="d-flex mr-8" size="24px" name="edit" type="material" cuClass="d-flex"></icon>
                  </a>
                  <figure class="bullet float-left text-center d-flex mt-12">
                    <img src="/assets/images/emergency.svg" alt="">
                  </figure>
                  <div class="item-description float-right pt-12 full-width">
                    <h4>{{speciality.speciality_name}}</h4>
                    <p>{{speciality.experience_level}}</p>
                    <p class="mt-8">{{speciality.description}}
                      <!-- <a class="read-more text-underline"
                        href="#">More</a> -->
                    </p>
                  </div>
                </li>
              </ul>
              <div class="no-document text-center mb-32" *ngIf="!specialityArr.length">
                <img src="/assets/images/specialities.svg" alt="">
                <p class="font-weight-medium">No Speciality Added !</p>
                <p class="small mb-8">Here you can add the Speciality here</p>
              </div>
            </div>
            <!-- Specialities end here -->

            <!-- Vaccinations start here -->
            <div class="mb-24 p-32">
              <div class="add-new position-relative" *ngIf="!viewMode">
                <button type="submit" class="btn small btn-outine" (click)="addNewVaccinations($event)">
                  <icon size="20px" name="add" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
                  Add
                </button>
              </div>
              <h4 class="d-flex mb-16 pb-4">
                <icon class="d-flex mr-8" size="24px" name="local_hospital" type="material" cuClass="d-flex"></icon>
                Vaccinations
              </h4>
              <ul class="item-list" *ngFor="let vaccine of vaccinationsArr, let i = index">
                <li class="clearfix position-relative  mb-12">
                  <a (click)="editPanel('VACCINATION', vaccine,i)" class="edit-item" *ngIf="!viewMode">
                    <icon class="d-flex mr-8" size="24px" name="edit" type="material" cuClass="d-flex"></icon>
                  </a>
                  <figure class="bullet float-left text-center d-flex mt-12">
                    <img src="/assets/images/vaccination.svg" alt="">
                  </figure>
                  <div class="item-description float-right pt-12 full-width">
                    <h4>{{vaccine.vaccine_name}}</h4>
                    <p>{{vaccine.provider}}</p>
                    <p>Issued {{vaccine.vaccination_date | date:'dd MMM y'}} . Expired on
                      {{vaccine.expiration_date | date:'dd MMM y'}}</p>
                  </div>
                </li>
              </ul>
              <div class="no-document text-center mb-32" *ngIf="!vaccinationsArr.length">
                <img src="/assets/images/credentials.svg" alt="">
                <p class="font-weight-medium">No Vaccination Added !</p>
                <p class="small mb-8">Here you can add the vaccination details here</p>
              </div>
            </div>
            <!-- Vaccinations end here -->

            <!-- Skills start here -->
            <div class="mb-24 p-32">
              <div class="add-new position-relative" *ngIf="!viewMode">
                <button type="submit" class="btn small btn-outine" (click)="addNewSkill($event)">
                  <icon size="20px" name="add" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
                  Add
                </button>
              </div>
              <h4 class="d-flex mb-16 pb-4">
                <icon class="d-flex mr-8" size="24px" name="category" type="material" cuClass="d-flex"></icon>
                Skills
              </h4>
              <ul class="item-list" *ngFor="let skill of skillsArr, let i = index">
                <li class="clearfix position-relative  mb-12">
                  <a (click)="editPanel('SKILL',skill,i)" class="edit-item" *ngIf="!viewMode">
                    <icon class="d-flex mr-8" size="24px" name="edit" type="material" cuClass="d-flex"></icon>
                  </a>
                  <figure class="bullet float-left text-center d-flex mt-12">
                    <img src="/assets/images/medicine.svg" alt="">
                  </figure>
                  <div class="item-description float-right pt-12 full-width">
                    <h4>{{skill.skill_name}}</h4>
                    <p>Experience : {{skill.experience_level}}</p>
                    <p class="mt-8">{{skill.description}}
                      <!-- <a class="read-more text-underline"
                        href="#">More</a> -->
                    </p>
                  </div>
                </li>
              </ul>
              <div class="no-document text-center mb-32" *ngIf="!skillsArr.length">
                <img src="/assets/images/specialities.svg" alt="">
                <p class="font-weight-medium">No Skills Added !</p>
                <p class="small mb-8">Here you can add the Skills here</p>
              </div>
            </div>
            <!-- Skills end here -->

            <!-- Education start here -->
            <div class="mb-24 p-32">
              <div class="add-new position-relative" *ngIf="!viewMode">
                <button type="submit" class="btn small btn-outine" (click)="addNewEducations($event)">
                  <icon size="20px" name="add" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
                  Add
                </button>
              </div>
              <h4 class="d-flex mb-16 pb-4">
                <icon class="d-flex mr-8" size="24px" name="school" type="material" cuClass="d-flex"></icon>
                Education
              </h4>
              <ul class="item-list" *ngFor="let educ of educationArr, let i = index">
                <li class="clearfix position-relative  mb-12">
                  <a (click)="editPanel('EDUCATION', educ, i)" class="edit-item" *ngIf="!viewMode">
                    <icon class="d-flex mr-8" size="24px" name="edit" type="material" cuClass="d-flex"></icon>
                  </a>
                  <figure class="bullet float-left text-center d-flex mt-12">
                    <img src="/assets/images/education.svg" alt="">
                  </figure>
                  <div class="item-description float-right pt-12">
                    <h4>{{educ.institution}}</h4>
                    <p>{{educ.degree}}</p>
                    <p class="mt-8">{{educ.start_date | date:'dd MMM y'}} - {{educ.end_date | date:'dd MMM y'}}</p>
                    <div *ngFor="let file of educ.files, let i = index">
                      <figure class="item-img mt-16 float-left mr-16">
                        <img src="/assets/images/certificate.svg" alt="">
                        <figcaption>{{file.name}}</figcaption>
                      </figure>
                      <!-- <figure class="item-img mt-16 float-left mr-16">
                        <img src="/assets/images/pdf.svg" alt="">
                        <figcaption>Sample PDF Up..</figcaption>
                      </figure> -->
                    </div>
                  </div>
                </li>
              </ul>
              <div class="no-document text-center mb-32" *ngIf="!educationArr.length">
                <img src="/assets/images/credentials.svg" alt="">
                <p class="font-weight-medium">No Education Added !</p>
                <p class="small mb-8">Here you can add the Speciality here</p>
              </div>
            </div>
            <!-- Education end here -->
          </section>
          <section class="submitCandidate" *ngIf="submitCandidate">
            <!-- <form>
              <div class="panel panel-light mb-24 p-24 candidate-status">
                <h4 class="d-flex mb-12 pb-0">Senior Java Developer <span
                    class="tag position-relative rounded-sm ml-16 overflow-hidden">Sourcing</span></h4>
                <p class="float-left mr-32 ids">
                  <icon class="d-inline-bock mr-8" size="15px" name="label" type="material"></icon> 345
                </p>
                <p class="float-left updated">
                  <icon class="d-inline-bock mr-8" size="15px" name="update" type="material"></icon> Last Updated On : March
                  20, 2020
                </p>
                <div class="clearfix"></div>
              </div>
            </form> -->
            <form [formGroup]="submissionForm">
              <div class="mb-24 p-32">
                <h4 class="d-flex mb-32 pb-8">
                  <icon class="d-flex mr-8" size="24px" name="person" type="material" cuClass="d-flex"></icon>
                  Submission Details
                </h4>
                <div class="form-group-row">
                  <div class="form-group position-relative date-field">
                    <label>Available Date <span class="require">*</span></label>
                    <svms-datepicker [inputdateFormat]="'yy-mm-dd'" [placeholder]="'Choose Date'" ngDefaultControl
                      formControlName="availableDate" I18n #availableDate>
                    </svms-datepicker>
                    <p class="validation-msg"
                      *ngIf="(submissionForm.controls.availableDate.touched && submissionForm.controls['availableDate'].errors)">
                      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon> Available Date
                      is Required
                    </p>
                  </div>
                  <!-- <div class="form-group position-relative date-field">
                    <label>Date of Birth</label>
                    <svms-datepicker [inputdateFormat]="'yy-mm-dd'" [placeholder]="'Choose Date'" ngDefaultControl
                      formControlName="dob" I18n #dob>
                    </svms-datepicker>
                  </div> -->
                </div>
                <!-- <div class="form-group-row">
                  <div class="form-group">
                    <label>
                      State ID - Last 3 Digits
                    </label>
                    <input type="text" placeholder="Enter State ID" class="form-control" [readOnly]="viewMode"
                      formControlName="stateId">
                  </div>
                  <div class="form-group">
                    <label>
                      Candidate Sourcing Type
                    </label>
                    <ng-select [clearable]="false" placeholder="Select" [readonly]="viewMode" [searchable]="false"
                      formControlName="sourcingType">
                      <ng-option [value]="'sourcing'">Sourcing</ng-option>
                    </ng-select>
                  </div>
                </div> -->
                <!-- <div class="form-group-row">
                  <div class="form-group">
                    <label>
                      Candidate Type
                    </label>
                    <ng-select [clearable]="false" placeholder="Select" [readonly]="viewMode" [searchable]="false"
                      formControlName="type">
                      <ng-option [value]="'permanent'">Permanent</ng-option>
                    </ng-select>
                  </div>
                  <div class="form-group">
                    <label>
                      Virtual/Remote/Contractor?
                    </label>
                    <ng-select [clearable]="false" placeholder="Select" [readonly]="viewMode" [searchable]="false"
                      formControlName="virtual">
                      <ng-option [value]="'virtual'">Virtual</ng-option>
                      <ng-option [value]="'remote'">Remote</ng-option>
                      <ng-option [value]="'contract'">Contract</ng-option>
                    </ng-select>
                  </div>
                </div> -->
                <!-- <div class="form-group">
                  <div class="toggle-panel fullwidth">
                    <div class="toggle-panel-inner toggle-background">
                      <span class="status-text">Candidate eligible to work in the US without employer sponsorship?</span>
                      <span class="toggle-switch" [ngClass]="toggleEligible?.value ? 'toggle-on' : 'toggle-off'"
                        (click)="onClickEligible()"></span>
                    </div>
                  </div>
                </div> -->
                <div class="form-group">
                  <label>
                    Comments
                  </label>
                  <textarea class="form-control" rows="4" placeholder="Enter Comments" maxlength="1000"
                    formControlName="comments" [readOnly]="viewMode"></textarea>
                  <!-- <input type="text" placeholder="Enter Comments" maxlength="1000" class="form-control" [readOnly]="viewMode"
                    formControlName="comments"> -->
                </div>
              </div>
              <div class="mb-24 p-32">
                <h4 class="d-flex mb-32 pb-8">
                  <icon class="d-flex mr-8" size="24px" name="person" type="material" cuClass="d-flex"></icon>
                  Rate & Other Information
                </h4>
                <div class="form-group-row">
                  <div class="form-group">
                    <label>
                      Rate Type
                    </label>
                    <ng-select [clearable]="false" placeholder="Select" [searchable]="false" [readonly]="viewMode"
                      formControlName="rateType">
                      <ng-option [value]="'hour'">Per Hour</ng-option>
                      <ng-option [value]="'day'">Per Day</ng-option>
                      <ng-option [value]="'week'">Per Week</ng-option>
                      <ng-option [value]="'month'">Per Month</ng-option>
                    </ng-select>
                  </div>
                  <div class="form-group">
                    <label>
                      Not to Exceed Bill Rate
                    </label>
                    <div class="d-flex align-items-center">
                      <input type="number" class="form-control" [readOnly]="viewMode || !jobData?.vendor_rate_exceed"
                        placeholder="$ 0.00" formControlName="noExceedBillRate">
                    </div>
                  </div>
                </div>
                <div class="form-group-row">
                  <div class="form-group">
                    <label>
                      Bill Rate <span class="require">*</span>
                    </label>
                    <div class="d-flex align-items-center bill-rate position-relative">
                      <input type="number" class="form-control" formControlName="billRate"
                        [readOnly]="viewMode || (jobData?.rate_model != 'bill_rate')" placeholder="$ 0.00">
                      <icon tooltip="Bill Rate Goes here" placement="right" delay="500" class="position-absolute"
                        size="14px" name="help_outline"></icon>
                    </div>
                    <p class="validation-msg" *ngIf="showBillRateValidation">
                      <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
                      Bill Rate cannot be more than Max Bill Rate
                    </p>
                  </div>
                  <div class="form-group">
                    <label>
                      Candidate Pay Rate <span class="require">*</span>
                    </label>
                    <div class="d-flex align-items-center">
                      <input type="number" class="form-control"
                        [readOnly]="viewMode || jobData?.rate_model == 'bill_rate'" placeholder="$ 0.00"
                        formControlName="payRate">
                    </div>
                  </div>
                </div>
                <div class="form-group-row">
                  <div class="form-group">
                    <label>
                      Candidate Over Time Pay Rate
                    </label>
                    <div class="d-flex align-items-center">
                      <input type="number" class="form-control" [readOnly]="true" placeholder="0.00"
                        formControlName="candidateOverTimeRate">
                    </div>
                  </div>
                  <div class="form-group">
                    <label>
                      Candidate Double Time Pay Rate
                    </label>
                    <div class="d-flex align-items-center">
                      <input type="number" class="form-control" [readOnly]="true" placeholder="0.00"
                        formControlName="clientOverTimeRate">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-24 p-32 position-relative" *ngIf="skillsArr.length">
                <h4 class="d-flex position-absolute">
                  <icon class="d-flex mr-8" size="24px" name="account_balance" type="material" cuClass="d-flex"></icon>
                  Skills Rating
                </h4>
                <div class="form-group-row mt-4" *ngFor="let skill of skillsArr, let i = index">
                  <div class="form-group">
                    <label class="mt-32 pt-8">{{skill.skill_name}}</label>
                  </div>
                  <div class="form-group">
                    <label class="mb-16">
                      Years of Experience
                    </label>
                    <ng-select [clearable]="false" placeholder="Choose Experience" [searchable]="false"
                      formControlName="experience">
                      <ng-option [value]="'3-5 Years'">3-5 Years</ng-option>
                      <ng-option [value]="'3-5 Years'">6-8 Years</ng-option>
                    </ng-select>
                  </div>
                  <div class="form-group">
                    <label class="mb-16">
                      Rating
                    </label>
                    <ng-select [clearable]="false" placeholder="Choose Rating" [searchable]="false"
                      formControlName="rating">
                      <ng-option [value]="'Intermediate'">Intermediate</ng-option>
                      <ng-option [value]="'Expert'">Expert</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="mb-24 p-32 upload-section">
                <h4 class="d-flex mb-32 pb-8">
                  <icon class="d-flex mr-8" size="24px" name="insert_drive_file" type="material" cuClass="d-flex">
                  </icon>
                  Other Documents
                </h4>
                <div class="form-group">
                  <label>Cover letter <span class="require">*</span></label>
                  <div class="file-field position-relative">
                    <input type="file" (change)="uploadDocs($event,'cover')" formControlName="cover">
                    <span class="browse">Browse</span>
                  </div>
                </div>
                <div class="form-group mb-0 pb-0">
                  <label>Sample Portfolio (or any other custom files) <span class="require">*</span></label>
                  <div class="file-field position-relative">
                    <input type="file" (change)="uploadDocs($event,'portfolio')" formControlName="portfolio">
                    <span class="browse">Browse</span>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <div class="form-footer p-24">
            <button class="btn large btn-outine float-left" (click)="back('goToBasic')" *ngIf="!basicInfo">
              <icon size="20px" name="keyboard_backspace" type="material" cuClass="icon-cu"></icon>
            </button>
            <!-- <button class="btn large btn-outine float-left mb-32" (click)="back('goToBasic')"
    *ngIf="qualification && isSubmitCandidateRoute">
    <icon size="20px" name="keyboard_backspace" type="material" cuClass="icon-cu"></icon>
    </button> -->
            <!-- <button class="btn large btn-outine float-left mb-32" (click)="back('goToQualification')"
      *ngIf="!qualification && submitCandidate">
      <icon size="20px" name="keyboard_backspace" type="material" cuClass="icon-cu"></icon>
    </button> -->
            <div *ngIf="isSubmitCandidateRoute">
              <button class="btn large btn-primary float-right" (click)="continue('goToSubmitCandidate')"
                *ngIf="basicInfo">
                Continue
                <icon size="20px" name="chevron_right" type="material" class="icon--on-right" cuClass="icon-cu"></icon>
              </button>
              <!-- <button class="btn large btn-primary float-right mb-32" (click)="continue('goToSubmitCandidate')"
        *ngIf="qualification && isSubmitCandidateRoute &&!viewMode">
        Continue
        <icon size="20px" name="chevron_right" type="material" class="icon--on-right" cuClass="icon-cu"></icon>
      </button> -->
            </div>
            <div *ngIf="!isSubmitCandidateRoute">
              <button class="btn large btn-primary float-right" (click)="continue('goToQualification')"
                *ngIf="basicInfo">
                Continue
                <icon size="20px" name="chevron_right" type="material" class="icon--on-right" cuClass="icon-cu"></icon>
              </button>
            </div>
            <button class="btn large btn-primary float-right"
              *ngIf="(qualification && !isSubmitCandidateRoute && !viewMode)" (click)="saveCandidate('save')">
              <icon size="20px" name="check_circle" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
              Save
            </button>
            <button class="btn large btn-primary float-right"
              *ngIf="(!qualification && submitCandidate && !viewMode && isSubmitCandidateRoute && !directSubmitCandidate) "
              (click)="saveCandidate('submit')">
              <icon size="20px" name="check_circle" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
              Save & Submit
            </button>
            <button class="btn large btn-primary float-right"
              *ngIf="(!qualification && submitCandidate && !viewMode && isSubmitCandidateRoute && directSubmitCandidate) "
              (click)="saveSubmitCandidate()">
              <icon size="20px" name="check_circle" type="material" class="icon--on-left" cuClass="icon-cu"></icon>
              Submit
            </button>
            <div class="clearfix"></div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<app-add-certificate (certificateData)="getCertificateData($event)" *ngIf="certificationTypeId"
  [certificationTypeId]="certificationTypeId"></app-add-certificate>
<app-add-skill (skillData)="getSkillData($event)" *ngIf="skillTypeId" [skillTypeId]="skillTypeId"></app-add-skill>
<app-add-vaccinations (vaccinationData)="getVaccineData($event)" *ngIf="vaccineTypeId" [vaccineTypeId]="vaccineTypeId">
</app-add-vaccinations>
<app-add-education (educationData)="getEducationData($event)" *ngIf="educationTypeId"
  [educationTypeId]="educationTypeId"></app-add-education>
<app-add-specialities (specialityData)="getSpecialityData($event)" *ngIf="specialityTypeId"
  [specialityTypeId]="specialityTypeId"></app-add-specialities>
<app-add-credential (credentialData)="getCredentialData($event)" *ngIf="credentialTypeId"
  [credentialTypeId]="credentialTypeId"></app-add-credential>
<section class="success-modal" [ngClass]="{'active' : saveCandidateModal}">
  <icon size="70px" name="check_circle" type="material" class="success-icon pb-8" cuClass="icon-cu"></icon>
  <h3 class="mt-16 mb-32">Candidate {{afterSaveText}} Successfully</h3>
  <button class="btn btn-secondary mb-16" (click)="viewCandidate($event)">View Candidate</button>
  <button class="btn btn-outline" (click)="createNewCandidate($event)" *ngIf="!isSubmitCandidateRoute">Create new
    Candidate</button>
  <button class="btn btn-outline" (click)="goToSubmittedCandidate($event)" *ngIf="isSubmitCandidateRoute">View Submitted
    Candidate</button>
</section>
<div class="modal-overlay" [ngClass]="{'active' : saveCandidateModal}"></div>

<section class="success-modal autofill-modal" [ngClass]="{'active' : modalStatus?.value}">
  <div class="autofill-wrap">
    <div class="svg-wrap" [ngClass]="{'animation': loader}">
      <svg id="Handsome" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="108.067"
        height="108.067" viewBox="0 0 108.067 108.067">
        <defs>
          <linearGradient id="linear-gradient" x1="0.547" y1="1" x2="0.547" y2="-0.069"
            gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#c74d75" stop-opacity="0" />
            <stop offset="1" stop-color="#af3b6e" stop-opacity="0.2" />
          </linearGradient>
          <linearGradient id="linear-gradient-2" x1="0.535" y1="0" x2="0.5" y2="1" xlink:href="#linear-gradient" />
        </defs>
        <g id="Primary">
          <g id="Scan">
            <g id="description-24px" transform="translate(3.621 3.62)">
              <path id="Path_16853" data-name="Path 16853" d="M0,0H100.826V100.826H0Z" fill="none" />
              <path id="Path_16854" data-name="Path 16854"
                d="M46.011,2H12.4a8.391,8.391,0,0,0-8.36,8.4L4,77.62a8.391,8.391,0,0,0,8.36,8.4H62.815a8.427,8.427,0,0,0,8.4-8.4V27.207Zm8.4,67.217H20.8v-8.4H54.413Zm0-16.8H20.8v-8.4H54.413Z"
                transform="translate(12.804 6.402)" fill="#776de0" />
              <rect id="Rectangle_1341" data-name="Rectangle 1341" width="66.6" height="78" rx="8"
                transform="translate(17.413 8.414)" fill="#776de0" />
            </g>
            <path id="Detail"
              d="M79.984,4.266a2.133,2.133,0,0,1,0-4.266h21.685a6.4,6.4,0,0,1,6.4,6.4V28.083a2.133,2.133,0,1,1-4.266,0V6.4a2.133,2.133,0,0,0-2.133-2.133ZM103.8,79.984a2.133,2.133,0,0,1,4.266,0v21.685a6.4,6.4,0,0,1-6.4,6.4H79.984a2.133,2.133,0,0,1,0-4.266h21.685a2.133,2.133,0,0,0,2.133-2.133ZM28.083,103.8a2.133,2.133,0,1,1,0,4.266H6.4a6.4,6.4,0,0,1-6.4-6.4V79.984a2.133,2.133,0,0,1,4.266,0v21.685A2.133,2.133,0,0,0,6.4,103.8ZM4.266,28.083a2.133,2.133,0,1,1-4.266,0V6.4A6.4,6.4,0,0,1,6.4,0H28.083a2.133,2.133,0,1,1,0,4.266H6.4A2.133,2.133,0,0,0,4.266,6.4Z"
              fill="#393e41" />
            <rect id="Bg" width="108" height="55" transform="translate(0.034 50.034)" fill="url(#linear-gradient)" />
            <g id="Group_3480" data-name="Group 3480" transform="translate(-663.966 -235.967)" opacity="0.626">
              <line id="Line_227" data-name="Line 227" x2="47" transform="translate(694.5 293.5)" fill="none"
                stroke="#fff" stroke-width="2" />
              <line id="Line_230" data-name="Line 230" x2="47" transform="translate(694.5 311.131)" fill="none"
                stroke="#fff" stroke-width="2" />
              <line id="Line_228" data-name="Line 228" x2="47" transform="translate(694.5 287.5)" fill="none"
                stroke="#fff" stroke-width="1" />
              <line id="Line_229" data-name="Line 229" x2="47" transform="translate(694.5 302.315)" fill="none"
                stroke="#fff" stroke-width="2" />
              <line id="Line_231" data-name="Line 231" x2="47" transform="translate(694.5 319.946)" fill="none"
                stroke="#fff" stroke-width="2" />
            </g>
            <g id="Detail-2" data-name="Detail" transform="translate(0 45.218)">
              <path id="Path_16849" data-name="Path 16849"
                d="M98.528,324.968H9.539a4.977,4.977,0,1,1,0-3.981H98.528a4.977,4.977,0,1,1,0,3.981Z"
                transform="translate(0 -318)" fill="#ff6663" />
              <path id="Path_16850" data-name="Path 16850"
                d="M98.528,324.968H9.539a4.977,4.977,0,1,1,0-3.981H98.528a4.977,4.977,0,1,1,0,3.981Z"
                transform="translate(0 -318)" fill="url(#linear-gradient-2)" />
            </g>
          </g>
        </g>
        <g id="account_circle-24px" transform="translate(42.034 21.033)">
          <path id="Path_16855" data-name="Path 16855" d="M0,0H24V24H0Z" fill="none" />
          <path id="Path_16856" data-name="Path 16856"
            d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,3A3,3,0,1,1,9,8,3,3,0,0,1,12,5Zm0,14.2a7.2,7.2,0,0,1-6-3.22c.03-1.99,4-3.08,6-3.08s5.97,1.09,6,3.08A7.2,7.2,0,0,1,12,19.2Z"
            fill="#fff" />
        </g>
      </svg>
    </div>
    <h4 class="mt-16 mb-32 font-weight-normal">Do you want to <span
        class="font-weight-medium text-underline">Auto-Fill</span> the Candidate Details from the Resume?</h4>
    <button class="btn btn-outline d-inline-block mr-12" (click)="disableAutoFill()">No</button>
    <button class="btn btn-secondary d-inline-block" (click)="enabledAutoFill()">Yes</button>
    <div *ngIf="loader" class="resume-loader-wrap position-relative">
      <div class="resume-loader"></div>
      <p class="resume-loader-text">Parsing, please wait ...</p>
    </div>
  </div>
  <footer class="p-16 text-left">
    <icon size="20px" name="warning" type="material" class="mr-16 d-inline-block" cuClass="icon-cu"></icon> Clicking yes
    will override the profiled details
  </footer>
</section>
<div class="modal-overlay" [ngClass]="{'active' : modalStatus?.value}"></div>
