import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-list-static-v2',
  templateUrl: './job-list-static-v2.component.html',
  styleUrls: ['./job-list-static-v2.component.scss']
})
export class JobListStaticV2Component implements OnInit {
  vmsData;
  hoverState;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.tableData();
    console.log(this.vmsData)
  }

  tableData() {
    this.vmsData = {
      "candidate": [
        {
          "job_title": "Java Developer",
          "status": "Approved",
          "id": "12",
          "hierarchy_manager": {
            "avatar": "james",
            "name": "James Butt"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "New Orleans",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Content Marketing Manager",
          "status": "Approved",
          "id": "34",
          "hierarchy_manager": {
            "avatar": "joseph",
            "name": "Josephine Darakjy"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Brighton",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Copywriter",
          "status": "Approved",
          "id": "45",
          "hierarchy_manager": {
            "avatar": "art",
            "name": "Art Venere"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Bridgeport",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Digital Marketing Manager",
          "status": "Approved",
          "id": "64",
          "hierarchy_manager": {
            "avatar": "lenna",
            "name": "Lenna Paprocki"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Anchorage",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Brand Strategist",
          "status": "Approved",
          "id": "67",
          "hierarchy_manager": {
            "avatar": "donette",
            "name": "Donette Foller"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Hamilton",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Vice President of Marketing",
          "status": "Approved",
          "id": "445",
          "hierarchy_manager": {
            "avatar": "simona",
            "name": "Simona Morasca"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Ashland",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Media Relations Coordinator",
          "status": "Approved",
          "id": "43",
          "hierarchy_manager": {
            "avatar": "mitsue",
            "name": "Mitsue Tollner"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Chicago",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Marketing Communications",
          "status": "Approved",
          "id": "56",
          "hierarchy_manager": {
            "avatar": "leota",
            "name": "Leota Dilliard"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "San Jose",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "SEO Manager",
          "status": "Approved",
          "id": "34",
          "hierarchy_manager": {
            "avatar": "sage",
            "name": "Sage Wieser"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Sioux Falls",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Administrative Assistant",
          "status": "Approved",
          "id": "635",
          "hierarchy_manager": {
            "avatar": "kris",
            "name": "Kris Marrier"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Baltimore",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Python Developer",
          "status": "Approved",
          "id": "65",
          "hierarchy_manager": {
            "avatar": "james",
            "name": "John Deo"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Baltimore",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Angular Developer",
          "status": "Approved",
          "id": "95",
          "hierarchy_manager": {
            "avatar": "leota",
            "name": "Leota Dilliard"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Sioux Falls",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Media Relations Coordinator",
          "status": "Approved",
          "id": "43",
          "hierarchy_manager": {
            "avatar": "mitsue",
            "name": "Mitsue Tollner"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Chicago",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Brand Strategist",
          "status": "Approved",
          "id": "67",
          "hierarchy_manager": {
            "avatar": "donette",
            "name": "Donette Foller"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Hamilton",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Java Developer",
          "status": "Approved",
          "id": "12",
          "hierarchy_manager": {
            "avatar": "james",
            "name": "James Butt"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "New Orleans",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "SEO Manager",
          "status": "Approved",
          "id": "34",
          "hierarchy_manager": {
            "avatar": "sage",
            "name": "Sage Wieser"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Sioux Falls",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Media Relations Manager",
          "status": "Approved",
          "id": "63",
          "hierarchy_manager": {
            "avatar": "mitsue",
            "name": "Mitsue Tollner"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Chicago",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Vice President of Marketing",
          "status": "Approved",
          "id": "445",
          "hierarchy_manager": {
            "avatar": "simona",
            "name": "Simona Morasca"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Ashland",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Brand Strategist",
          "status": "Approved",
          "id": "67",
          "hierarchy_manager": {
            "avatar": "donette",
            "name": "Donette Foller"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Hamilton",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        },
        {
          "job_title": "Vice President of Marketing",
          "status": "Approved",
          "id": "445",
          "hierarchy_manager": {
            "avatar": "simona",
            "name": "Simona Morasca"
          },
          "duration": "11/22/2020 - 08/31/2021",
          "hierarchy": "Populas-DLL",
          "location": "Ashland",
          "cost_center": "30 - ALBUQUERQUE",
          "department": "2 - Zone 2",
          "position": "1"
        }
      ]
    }
  }
  hideOptionDropdown() {
    this.hoverState = null;
  }

  hoverClass(i) {
    this.hoverState = i;
  }

  onClickCreate() {
    this.router.navigateByUrl('/jobs/create-job');
  }

  viewDetailPage() {
    this.router.navigateByUrl('/jobs/job-details');
  }
}
