import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HierarchyChildRendererComponent } from './hierarchy-child-renderer/hierarchy-child-renderer.component';
import { HierarchyComponent } from './hierarchy.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [HierarchyComponent, HierarchyChildRendererComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
  exports: [HierarchyComponent]
})
export class SvmsHierarchyModule { }
