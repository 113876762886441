<div class="account-form-wrapper secret-question">
  <div class="top-left-corner"></div>
  <div class="top-right-corner"></div>
  <div class="bottom-left-corner"></div>
  <div class="bottom-right-corner"></div>
  <div class="form-wrapper">
    <div class="logo text-center">@</div>
    <h2 class="text-center">Pick Your Secret Questions</h2>
    <p class="text-center mx-auto custom-width">Give yourself another way to recover your account Securely in case
      your information becomes outdated</p>
    <div class="progress-bar">
      <ul>
        <li class="done"></li>
        <li class="done"></li>
        <li class="active"></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <form [formGroup]="questionform" (ngSubmit)="onSubmitForm(questionform.value)" autocomplete="off">
    <div class="form-group">
      <label>Question 1</label>
      <ng-select [items]="security_questions" placeholder="Choose Security Question" [searchable]="false" [clearable]="false" bindLabel="title"  bindValue="id" (change)="removeSecurityQues($event,'zero')" ngDefaultControl  formControlName="question1">
      </ng-select>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" name="question1Value" formControlName="awnser1"  placeholder="Your Answer">
    </div>
    <div class="form-group">
      <label>Question 2</label>
      <ng-select [items]="security_questions1" placeholder="Choose Security Question" [searchable]="false" [clearable]="false" (change)="removeSecurityQues($event,'one')" bindLabel="title"  bindValue="id" ngDefaultControl formControlName="question2">
      </ng-select>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" name="question2Value" formControlName="awnser2" placeholder="Your Answer">
    </div>
    <div class="form-group">
      <label>Question 3</label>
      <ng-select [items]="security_questions2" placeholder="Choose Security Question" [searchable]="false" [clearable]="false" (change)="removeSecurityQues($event,'two')" bindLabel="title"  bindValue="id" ngDefaultControl formControlName="question3">
      </ng-select>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" name="question3Value" formControlName="awnser3" placeholder="Your Answer">
    </div>
    <div class="row">
      <div class="col-md-3">
        <button class="account-btn btn btn-outline-secondary icon skip float-left" (click)="onBackClick()">
          <icon cuClass="intro-btn" name="keyboard_backspace" size="24px" type="material"></icon>
        </button>
      </div>
      <div class="col-md-9">
        <button class="account-btn btn btn-secondary float-right" type="submit" [disabled]="!questionform.valid ">Save &amp;
          Continue
          <icon cuClass="intro-btn icon--on-right" name="keyboard_backspace" size="24px" type="material"></icon>
        </button>
        <button class="account-btn btn btn-outline-secondary float-right skip-btn mr-16 pr-32 pl-32"
          (click)="onSkipClick()" >Skip
        </button>
      </div>
    </div>
  </form>
  </div>
</div>
