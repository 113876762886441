import { Component, OnInit } from '@angular/core';
import { VMSConfig } from 'src/app/library/table/table/table.model';
import { CandidateService } from 'src/app/candidate/service/candidate.service';
import { errorHandler } from 'src/app/shared/util/error-handler';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { Router } from '@angular/router';
import { EmitEvent, Events, EventStreamService } from 'src/app/core/services/event-stream.service';

@Component({
  selector: 'app-available-candidates',
  templateUrl: './available-candidates.component.html',
  styleUrls: ['./available-candidates.component.scss']
})
export class AvailableCandidatesComponent implements OnInit {
  public vmsData: any;
  public tableConfig: VMSConfig;
  public isExpand = false;
  public totalPages = 0;
  public totalRecords = 0;
  tableLoaded = false;
  dataLoading = true;
  searchTerm: any;
  itemsPerPage: any;
  constructor(
    private candidateService:CandidateService,
    private _alert: AlertService,
    private _loader: LoaderService,
    private router:Router,
    private eventStream: EventStreamService
  ) { }

  ngOnInit(): void {
    this.getAvailableCandidateList();

    this.tableConfig = {
      title: 'Available Candidates',
      columnList: [
        { name: 'candidate_name', title: 'Candidate', width: 25, isIcon: false, isImage: true, isContact: false, isNumberBadge: false },
        { name: 'availability', title: 'Availability', width: 15, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
        { name: 'prefRate', title: 'Pref. Pay Rate', width: 16, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
        { name: 'est_hours', title: 'EST.Hours', width: 14, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
        { name: 'est_pay', title: 'EST.Pay', width: 15, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
        { name: 'match', title: 'Match', width: 10, isIcon: false, isImage: false, isContact: false , isVieworEdit: true, isViewProfile:true, isDisableorDelete: false, isDelete: false, isNumberBadge: false, isProfileMatch:true},
      ],
      isExpand: false,
      isFilter: true,
      isSearch: true,
      isSetting: false,
      isTopPagination: false,
      hideResultCount: true,
      isCreate: false,
      density: 'COMFORTABLE',
      isTheme: true
    };
  }

  getAvailableCandidateList(pageNo = 1) {
    let search = this.searchTerm ? ('&k=' + this.searchTerm) : ''
    this.candidateService.fetchCandidates(10, pageNo, search).subscribe(data => {
      const noOfPages = Math.ceil(data.total_records / data.items_per_page);
      var cand_arr = [];
      data?.candidates.forEach(element => {
        let candidate_name1 = (element.middle_name) ? `${element.first_name} ${element.middle_name} ${element.last_name}` : `${element.first_name} ${element.last_name}`;
        let candidate_list_data = {
          "candidate_name": candidate_name1,
          "prefRate":'$' + element?.preferences.rate.amount,
          "id": element.id
        }
       cand_arr.push(candidate_list_data);
      });
      this.vmsData = {'candidate':cand_arr};
      this.dataLoading = false;
      this.totalRecords = data?.total_records;
      this.itemsPerPage = data?.items_per_page;
      this.tableLoaded = true;
      this._loader.hide();
    }, err => {
      this._alert.error(errorHandler(err));
    });
  }
  //submit candidate btn
    onClickView(e) {
      this.router.navigate(['/candidates/submit'],{ queryParams: { candidateSubmission: true, jobId: 1, type:'submit', candidateId:e.id} });
    }
    onEditClick(data) {
      this.router.navigate(['/candidates/submit'],{ queryParams: { candidateSubmission: true, jobId: 1, type:'updateThensubmit', candidateId:data.id} });
    }
    cloneClicked(e) {

    }
    onExpandClick(e) { }

    onPaginationClick(e) {

    }

    onDeleteClick(role) {

    }

    disableClicked(role) {
    }

    onCreateClick(e) {

    }

    columnClicked(columnData) {

    }

    viewProfileClicked(event) {
      if (event) {
        this.eventStream.emit(new EmitEvent(Events.VIEW_CANDIDATE_PROFILE, { value: true }));
      }
    }
  }
