<form [formGroup]="addressForm">
  <div class="float-left subtitle-2 mb-16 font-weight-bold">Primary Address</div>
  <div class="btn small btn-light float-right" (click)="showPrimarySecondaryAddress()"
    [ngClass]="{'disable':isViewClicked}"> {{secondaryAddress}}</div>
  <div class="clearfix"></div>
  <div class="primary-address">
    <div class="form-group">
      <label> Country </label>
      <ng-select bindLabel="name" [clearable]="false" [class.hide-arrow]="isViewClicked" placeholder="Select Country" [readonly]="isViewClicked"
        ngDefaultControl formControlName="country" (change)="setCountry('primary');setaddres('primary')">
        <ng-option [value]="'Canada'">Canada</ng-option>
        <ng-option [value]="'USA'">USA</ng-option>
        <ng-option [value]="'India'">India</ng-option>
      </ng-select>
    </div>
    <div class="form-group" *ngIf="addressForm.get('country').value" [ngClass]="{'disable':isViewClicked}">
      <label>Address</label>
      <search-address (setAddress)="getAddress($event)" [adressType]="'geocode'" [country]="countryShortName" id="date"
        formControlName="address" [readonlyMode]="isViewClicked" #address1></search-address>
    </div>
  </div>
  <div class="Secondary-address" [hidden]="!secondaryaddressOpen">
    <div class="float-left subtitle-2 mb-16 font-weight-bold">Secondary Address </div>
    <div class="clearfix"></div>
    <div class="form-group">
      <label> Country </label>
      <ng-select bindLabel="name" [class.hide-arrow]="isViewClicked" [clearable]="false" placeholder="Select Country" ngDefaultControl
        formControlName="secondarycountry" (change)="setCountry('secondary');setaddres('secondary')"
        [readonly]="isViewClicked">
        <ng-option [value]="'Canada'">Canada</ng-option>
        <ng-option [value]="'USA'">USA</ng-option>
        <ng-option [value]="'India'">India</ng-option>
      </ng-select>
    </div>
    <div class="form-group" *ngIf="addressForm.get('secondarycountry').value" [ngClass]="{'disable':isViewClicked}">
      <label>Address</label>
      <search-address (setAddress)="getSecondaryAddress($event)" [adressType]="'geocode'"
        [country]="secondaryCountryShortName" id="date" formControlName="secondary_address"
        [readonlyMode]="isViewClicked" #address2></search-address>
    </div>
  </div>
  <div class="float-left subtitle-2 mb-16 font-weight-bold">{{subtitle}}</div>
  <div class="btn small btn-light float-right" (click)="showPrimarySecondaryContact()"
    [ngClass]="{'disable':isViewClicked}">{{secondaryContact}}</div>
  <div class="clearfix"></div>
  <div class="primary-contact">
    <div class="form-group-row">
      <div class="form-group">
        <label>First Name<span class="require">*</span></label>
        <input type="text" class="form-control" placeholder="First Name" formControlName="first_name"
          [readonly]="isViewClicked">
      </div>

      <div class="form-group">
        <label>Last Name<span class="require">*</span></label>
        <input type="text" class="form-control" placeholder="Last Name" formControlName="last_name"
          [readonly]="isViewClicked">
      </div>
    </div>
    <div class="form-group">
      <label>Designation</label>
      <input type="text" class="form-control" placeholder="Enter Designation" formControlName="designation"
        [readonly]="isViewClicked">
    </div>
    <div class="form-group-row">
      <div class="form-group">
        <label>Phone</label>
        <div class="d-flex align-items-center country-searchable">
          <ng-select [searchable]="false" [class.hide-arrow]="isViewClicked" [clearable]="false" ngDefaultControl formControlName="phoneFomat"
            [readonly]="isViewClicked">
            <ng-option>
              <img src="../../../assets/images/flag/English (Canada).png" width="20px" class="mr-4" />
              <span>+1</span>
            </ng-option>
            <ng-option>
              <img src="../../../assets/images/flag/us.png" width="20px" class="mr-4" />
              <span>+1</span>
            </ng-option>
            <ng-option [value]="'+91'">
              <img src="../../../assets/images/flag/हिन्दी.png" width="20px" class="mr-4" />
              <span>+91</span>
            </ng-option>
          </ng-select>

          <input type="number" class="form-control" placeholder="Contact Person Phone Number" formControlName="phone"
            [readonly]="isViewClicked" [pattern]="mobNumberPattern" [ngClass]="{'error':
        addressForm.controls['phone'].invalid && (addressForm.controls['phone'].dirty || addressForm.controls['phone'].touched)
          }">
        </div>
        <p *ngIf="
        addressForm.get('phone').touched &&
                (addressForm.get('phone').errors?.pattern || addressForm.get('phone').errors?.required || addressForm.get('phone').invalid)
              " class="validation-msg">
          <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
          Phone number is not valid.
        </p>
      </div>
      <div class="form-group">
        <label>Email<span class="require">*</span></label>
        <input type="email" class="form-control" placeholder="Contact Person Email Address" formControlName="email"
          [readonly]="isViewClicked" pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$" [ngClass]="{'error':
      addressForm.controls['email'].invalid && (addressForm.controls['email'].dirty || addressForm.controls['email'].touched)
        }">

        <p *ngIf="
        addressForm.get('email').touched &&
                (addressForm.get('email').errors?.pattern || addressForm.get('email').errors?.required || addressForm.get('email').invalid)
              " class="validation-msg">
          <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
          Email is not valid.
        </p>
      </div>
    </div>
  </div>
  <div class="secondary-contact" [hidden]="!secondaryContactOpen">
    <div class="float-left subtitle-2 mb-16 font-weight-bold">Secondary Contact </div>
    <div class="clearfix"></div>
    <div class="form-group-row">
      <div class="form-group">
        <label>First Name <span class="require">*</span></label>
        <input type="text" class="form-control" placeholder="First Name"
          formControlName="secondary_first_name" [readonly]="isViewClicked">
      </div>
      <div class="form-group">
        <label>Last Name <span class="require">*</span></label>
        <input type="text" class="form-control" placeholder="Last Name"
          formControlName="secondary_last_name" [readonly]="isViewClicked">
      </div>
      
    </div>
    <div class="form-group">
      <label>Designation</label>
      <input type="text" class="form-control" placeholder="Enter Designation" formControlName="secondary_designation"
        [readonly]="isViewClicked">
    </div>
    <div class="form-group-row">
      <div class="form-group">
        <label>Phone</label>
        <div class="d-flex align-items-center country-searchable">
          <ng-select [searchable]="false" [class.hide-arrow]="isViewClicked" [clearable]="false" ngDefaultControl formControlName="secondary_phoneFomat"
            [readonly]="isViewClicked">
            <ng-option>
              <img src="../../../assets/images/flag/English (Canada).png" width="20px" class="mr-4" />
              <span>+1</span>
            </ng-option>
            <ng-option>
              <img src="../../../assets/images/flag/us.png" width="20px" class="mr-4" />
              <span>+1</span>
            </ng-option>
            <ng-option [value]="'+91'">
              <img src="../../../assets/images/flag/हिन्दी.png" width="20px" class="mr-4" />
              <span>+91</span>
            </ng-option>
          </ng-select>

          <input type="number" class="form-control" placeholder="Contact Person Phone Number"
            formControlName="secondary_phone" [pattern]="mobNumberPattern" [ngClass]="{'error':
        addressForm.controls['secondary_phone'].invalid && (addressForm.controls['secondary_phone'].dirty || addressForm.controls['secondary_phone'].touched)
          }" [readonly]="isViewClicked">
        </div>
        <p *ngIf="
        addressForm.get('phone').touched &&
                (addressForm.get('secondary_phone').errors?.pattern || addressForm.get('secondary_phone').errors?.required || addressForm.get('secondary_phone').invalid)
              " class="validation-msg">
          <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
          Phone number is not valid.
        </p>
      </div>
      <div class="form-group">
        <label>Email<span class="require">*</span></label>
        <input type="email" class="form-control" placeholder="Contact Person Email Address"
          formControlName="secondary_email" pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$"
          [ngClass]="{'error':
      addressForm.controls['secondary_email'].invalid && (addressForm.controls['secondary_email'].dirty || addressForm.controls['secondary_email'].touched)}" [readonly]="isViewClicked">

        <p *ngIf="addressForm.get('secondary_email').touched && (addressForm.get('secondary_email').errors?.pattern || addressForm.get('secondary_email').errors?.required || addressForm.get('secondary_email').invalid)"
          class="validation-msg">
          <icon name="error" size="14px" type="material" cuClass="validation-icon"></icon>
          Email is not valid.
        </p>
      </div>
    </div>
  </div>
</form>