<app-job-details>
  <div class="description-card bg-light p-32">
    <section class="content-section mt-32">
      <h4 class="title position-relative  mb-24">
        <icon class="icon-bg color-yellow mr-12 rounded-sm" theme="outlined" size="16px" name="info" type="material"
          cuClass="icon-cu"></icon>
        Candidate Information
        <a href="javascript:void(0)" class="btn-edit-save d-inline-block position-absolute pl-24 pr-24"
          (click)="editInformation()">{{candidateInfoEdit.title}}</a>
        <span class="toggle position-absolute rounded-sm" [ngClass]="{'disabled' : candidateInfoEdit.visibility}"
          (click)="toggleCandidateInfo()">
          <icon size="16px" [name]="candidateInfo ? 'expand_more' : 'expand_less'" type="material" cuClass="icon-cu">
          </icon>
        </span>
      </h4>
      <div class="section-info overflow-hidden" [ngClass]="candidateInfo ? 'hidden' : 'active'">
        <div *ngIf="!candidateInfoEdit.visibility">
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Name</span>
              <p>John Doe</p>
            </div>
            <div class="info-box pr-12">
              <span>Current Location</span>
              <p>Contract</p>
            </div>
            <div class="info-box">
              <span>Vendor</span>
              <P>Suresh Gupta</P>
            </div>
          </div>
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Custom Field Questions</span>
              <p>Recruited</p>
            </div>
            <div class="info-box pr-12">
              <span>Foundational Data</span>
              <p>Intermediate</p>
            </div>
            <div class="info-box">
              <span>Start Date</span>
              <p>
                <icon size="16px" class="v-align mr-4" name="calendar_today" type="material" cuClass="icon-cu">
                </icon> {{availableStartDate ? availableStartDate : '09/12/2020'}}
              </p>
            </div>
          </div>
        </div>
        <div class="edit-mode" *ngIf="candidateInfoEdit.visibility">
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Name</span>
              <p>John Doe</p>
            </div>
            <div class="info-box pr-12">
              <span>Current Location</span>
              <p>Contract</p>
            </div>
            <div class="info-box">
              <span>Vendor</span>
              <P>Suresh Gupta</P>
            </div>
          </div>
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Custom Field Questions</span>
              <p>Recruited</p>
            </div>
            <div class="info-box pr-12">
              <span>Foundational Data</span>
              <p>Intermediate</p>
            </div>
            <div class="info-box">
              <span>Start Date</span>
              <div class="mt-8 mb-24">
                <svms-datepicker I18n [(ngModel)]="availableStartDate"></svms-datepicker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="content-section mt-32 with-border pt-24">
      <h4 class="title position-relative  mb-24">
        <icon class="icon-bg color-light-red mr-12 rounded-sm" theme="outlined" size="16px" name="addchart"
          type="material" cuClass="icon-cu"></icon>
        Job Information
        <a href="javascript:void(0)" class="btn-edit-save d-inline-block position-absolute pl-24 pr-24"
          (click)="jobInformation()">{{jobInfoEdit.title}}</a>
        <span class="toggle position-absolute rounded-sm" [ngClass]="{'disabled' : jobInfoEdit.visibility}"
          (click)='toggleJobInfo()'>
          <icon size="16px" [name]="jobInfo ? 'expand_more' : 'expand_less'" type="material" cuClass="icon-cu">
          </icon>
        </span>
      </h4>
      <div class="section-info overflow-hidden" [ngClass]="jobInfo ? 'hidden' : 'active'">
        <div *ngIf="!jobInfoEdit.visibility">
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Start Date</span>
              <p>
                <icon size="16px" class="v-align mr-4" name="calendar_today" type="material" cuClass="icon-cu">
                </icon> 07/12/2020
              </p>
            </div>
            <div class="info-box pr-12">
              <span>End Date</span>
              <p>
                <icon size="16px" class="v-align mr-4" name="calendar_today" type="material" cuClass="icon-cu">
                </icon> 05/12/2021
              </p>
            </div>
            <div class="info-box">
              <span>Organisation Name</span>
              <P>Organisation ABC</P>
            </div>
          </div>
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Hierarchy Level</span>
              <p>Hospital A _ ICU</p>
            </div>
            <div class="info-box pr-12">
              <span>Address</span>
              <p>123 Main Street, 2nd Floor, Anytown TX129011</p>
            </div>
            <div class="info-box">
              &nbsp;
            </div>
          </div>
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Job Manager</span>
              <p>{{jobManager ? jobManager : 'Tiara Smith'}}</p>
            </div>
            <div class="info-box pr-12">
              <span>Timesheet Manager</span>
              <p>{{timesheetManager ? timesheetManager : 'William Smith'}}</p>

            </div>
            <div class="info-box">
              &nbsp;
            </div>
          </div>
        </div>
        <div class="edit-mode" *ngIf="jobInfoEdit.visibility">
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Start Date</span>
              <p>
                <icon size="16px" class="v-align mr-4" name="calendar_today" type="material" cuClass="icon-cu">
                </icon> 04/12/2020
              </p>
            </div>
            <div class="info-box pr-12">
              <span>End Date</span>
              <p>
                <icon size="16px" class="v-align mr-4" name="calendar_today" type="material" cuClass="icon-cu">
                </icon> 05/12/2021
              </p>
            </div>
            <div class="info-box">
              <span>Organisation Name</span>
              <P>Organisation ABC</P>
            </div>
          </div>
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Hierarchy Level</span>
              <p>Hospital A _ ICU</p>
            </div>
            <div class="info-box pr-12">
              <span>Address</span>
              <p>123 Main Street, 2nd Floor, Anytown TX129011</p>
            </div>
            <div class="info-box">
              &nbsp;
            </div>
          </div>
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Job Manager</span>
              <div class="mt-8 mb-24">
                <input class="form-control" value="Tiara Smith" [(ngModel)]="jobManager">
              </div>

            </div>
            <div class="info-box pr-12">
              <span>Timesheet Manager</span>
              <div class="mt-8 mb-24">
                <ng-select [clearable]="false" placeholder="Select" appendTo="body" [(ngModel)]="timesheetManager">
                  <ng-option [value]="'William Smith'">William Smith</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="info-box">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="content-section mt-32 with-border pt-24">
      <h4 class="title position-relative  mb-24">
        <icon class="icon-bg color-gray mr-12 rounded-sm" theme="outlined" size="16px" name="trending_up"
          type="material" cuClass="icon-cu"></icon>
        Rate Information
        <a href="javascript:void(0)" class="btn-edit-save d-inline-block position-absolute pl-24 pr-24"
          (click)="rateInformation()">{{rateInfoEdit.title}}</a>
        <span class="toggle position-absolute rounded-sm" [ngClass]="{'disabled' : rateInfoEdit.visibility}"
          (click)='toggleRateInfo()'>
          <icon size="16px" [name]="rateInfo ? 'expand_more' : 'expand_less'" type="material" cuClass="icon-cu">
          </icon>
        </span>
      </h4>
      <div class="section-info overflow-hidden" [ngClass]="rateInfo ? 'hidden' : 'active'">
        <div *ngIf="!rateInfoEdit.visibility">
          <div class="d-flex">
            <div class="info-box pr-12">
              <span class="color-red">Pay Rate</span>
              <p class="color-red strike">{{offerPayRate ? offerPayRate : '$560'}}</p>
            </div>
            <div class="info-box pr-12">
              <span class="color-green">Pay Rate</span>
              <p class="color-green">$ 560</p>
            </div>
            <div class="info-box">
              &nbsp;
            </div>
          </div>
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Bill Rate</span>
              <p>$ 200</p>
            </div>
            <div class="info-box pr-12">
              <span>Overtime Pay Rate</span>
              <p>$ 120</p>
            </div>
            <div class="info-box">
              <span>Double Time Pay Rate</span>
              <p>$ 140</p>
            </div>
          </div>
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Over Time Bill Rate</span>
              <p>$ 100</p>
            </div>
            <div class="info-box pr-12">
              <span>Double Time Pay Rate</span>
              <p>$ 150</p>
            </div>
            <div class="info-box">
              &nbsp;
            </div>
          </div>
        </div>
        <div class="edit-mode" *ngIf="rateInfoEdit.visibility">
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Pay Rate</span>
              <div class="mt-8 mb-24">
                <input class="form-control" [(ngModel)]="offerPayRate" value="$ 560">
              </div>
            </div>
            <div class="info-box pr-12">
              &nbsp;
            </div>
            <div class="info-box">
              &nbsp;
            </div>
          </div>
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Bill Rate</span>
              <p>$ 200</p>
            </div>
            <div class="info-box pr-12">
              <span>Overtime Pay Rate</span>
              <p>$ 120</p>
            </div>
            <div class="info-box">
              <span>Double Time Pay Rate</span>
              <p>$ 140</p>
            </div>
          </div>
          <div class="d-flex">
            <div class="info-box pr-12">
              <span>Over Time Bill Rate</span>
              <p>$ 100</p>
            </div>
            <div class="info-box pr-12">
              <span>Double Time Pay Rate</span>
              <p>$ 150</p>
            </div>
            <div class="info-box">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="content-section mt-32 with-border pt-24">
      <h4 class="title position-relative  mb-24">
        <icon class="icon-bg color-gray mr-12 rounded-sm" theme="outlined" size="16px" name="description"
          type="material" cuClass="icon-cu"></icon>
        Notes
        <span class="toggle position-absolute rounded-sm" (click)='toggleNotes()'>
          <icon size="16px" [name]="noteInfo ? 'expand_more' : 'expand_less'" type="material" cuClass="icon-cu">
          </icon>
        </span>
      </h4>
      <div class="section-info overflow-hidden" [ngClass]="noteInfo ? 'hidden' : 'active'">
        <div class="d-flex">
          <div class="info-box pr-12">
            <textarea class="form-control" rows="7" [(ngModel)]="notes"></textarea>
          </div>
        </div>
      </div>
      <div class="notes-output mt-24">
        <ul>
          <li>
            <div class="avatar pr-24">
              <figure>
                <img src="../../../../../assets/images/5.jpg" alt="">
              </figure>
            </div>
            <div class="comment-info">
              <h4 class="mb-0">Art Venere <span class="ml-12">1 Day Ago</span></h4>
              <p>We had discussed about upping the rate to $ 82.00. That was on 19-02-20, and I forwarded it to you....
                <a href="jaascript:void(0)">more</a></p>
              <a href="javascript:void(0)" class="reply">
                <icon class="mr-8" size="16px" name="reply" type="material" cuClass="icon-cu"></icon> Reply
              </a>
            </div>
            <ul>
              <li>
                <div class="avatar pr-24">
                  <figure>
                    <img src="../../../../../assets/images/5.jpg" alt="">
                  </figure>
                </div>
                <div class="comment-info">
                  <h4 class="mb-0">Art Venere <span class="ml-12">1 Day Ago</span></h4>
                  <p>We had discussed about upping the rate to $ 82.00. That was on 19-02-20, and I forwarded it to
                    you..<a href="jaascript:void(0)">more</a></p>
                  <a href="javascript:void(0)" class="reply">
                    <icon class="mr-8" size="16px" name="reply" type="material" cuClass="icon-cu"></icon> Reply
                  </a>
                </div>
              </li>
            </ul>
          </li>
          <li>
            <div class="avatar pr-24">
              <figure>
                <img src="../../../../../assets/images/5.jpg" alt="">
              </figure>
            </div>
            <div class="comment-info">
              <h4 class="mb-0">Art Venere <span class="ml-12">1 Day Ago</span></h4>
              <p>We had discussed about upping the rate to $ 82.00. That was on 19-02-20, and I forwarded it to you.</p>
              <a href="javascript:void(0)">
                <icon class="mr-8" size="16px" name="reply" type="material" cuClass="icon-cu"></icon> Reply
              </a>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <div class="mt-64 pt-24 pb-64">
      <button class="float-left large btn btn-outined">Back To Candidate List</button>
      <button class="float-right large btn btn-primary" (click)="releaseOffer()">Release Offer</button>
    </div>
  </div>
</app-job-details>
