<div class="pagination font-weight-normal mx-24" [attr.actpage]="currentPage">
  <a (click)="onClick($event, '-1')">
    <table-icon name="keyboard_arrow_left" size="14px" type="material" class="d-flex" cuClass="d-flex"></table-icon>
  </a>
  <a *ngFor="let page of pager" [ngClass]="{'active': currentPage === page}" [hidden]="paginationTabShowHide(page)"
    (click)="onClick($event, page.toString())">
    {{page}}
  </a>
  <a (click)="onClick($event, '+1')">
    <table-icon name="keyboard_arrow_right" size="14px" type="material" class="d-flex" cuClass="d-flex"></table-icon>
  </a>
</div>
