import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef, ChangeDetectorRef, ViewChildren, OnChanges } from '@angular/core';
import { EmitEvent, Events, EventStreamService } from 'src/app/core/services/event-stream.service';
import { VMSConfig } from '../table/table.model';

@Component({
  selector: 'vms-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class VMSHeaderComponent implements OnInit {

  @Input() mainmenuName = 'Clients';
  @Input() submenuName: string;
  @Input() createBtnName = 'Create New';
  @Input() isCreateButtonName: string;
  @Input() tableConfig: VMSConfig;
  @Input() initialPage = 1;
  @Input() currentPage = 1;
  @Input() maxPages = 1;
  @Input() count: any;
  @Input() currentTab: string= undefined;
  @Input() isSelectSubmitButtonName: string;
  @Input() routeLinkOption: string;
  @Input() routeLinkText: string;
  @Output() changePages = new EventEmitter<number>(true);
  @Output() onCreate = new EventEmitter<boolean>();
  @Output() onFilter = new EventEmitter<any>();
  @Output() onSearch = new EventEmitter<any>();
  @Output() onMonthChangeEvent = new EventEmitter<any>();
  @Output() onYearChangeEvent = new EventEmitter<any>();
  @Output() onSetting = new EventEmitter<any>();
  @Output() onListFilter = new EventEmitter();
  @Output() onTabClick = new EventEmitter();
  @Output() onSortFilter = new EventEmitter();
  @Output() onHeaderButtonClick = new EventEmitter();
  @Output() onDownloadButtonClick = new EventEmitter();
  isSearchOpen = false;
  searchValue = '';
  density = 'COMFORTABLE';
  selectedTab: string= '';
  showSettings = false;
  showFilter = false;
  totalFilterApplied = 0;
  isFilterCleard = false;
  tooltipName: any;
  openDrop = false;
  monthList = [ {name : 'January', value: 'jan'},
                {name : 'February', value: 'feb'},
                {name : 'March', value: 'mar'},
                {name : 'April', value: 'apr'},
                {name : 'May', value: 'may'},
                {name : 'June', value: 'jun'},
                {name : 'July', value: 'jul'},
                {name : 'August', value: 'aug'},
                {name : 'September', value: 'sep'},
                {name : 'October', value: 'oct'},
                {name : 'November', value: 'nov'},
                {name : 'December', value: 'dec'} ];
  yearList = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  selectedMonth = this.monthList[(new Date).getMonth()].value;               
  selectedYear = (new Date()).getFullYear();
  defaultSortOptions = [{ title: 'Date Created', key: 'created' }, { title: 'Date Updated', key: 'updated' }];
  @ViewChild('setting', { read: ElementRef, static: false }) setting: ElementRef;
  @ViewChild('settingMenu', { read: ElementRef, static: false }) settingMenu: ElementRef;
  @ViewChild('search', { read: ElementRef, static: false }) search: ElementRef;
  @ViewChild('searchInput', { read: ElementRef, static: false }) searchInput: ElementRef;
  @ViewChild('filter', { read: ElementRef, static: false }) filter: ElementRef;
  @ViewChild('filterMenu', { read: ElementRef, static: false }) filterMenu: ElementRef;
  @ViewChild('filterCount', { read: ElementRef, static: false }) filterCount: ElementRef;

  classList = ['ng-option-label', 'ng-option-marked', 'ng-option', 'ng-value-icon', 'datepicker--cell'];
  constructor(private render: Renderer2, private changeDetectorRef: ChangeDetectorRef,
      private eventStream : EventStreamService
    ) {
    this.render.listen('window', 'click', (e: Event) => {
      if ((this.setting && this.setting.nativeElement.contains(e.target) && !this.showSettings) ||
        (this.settingMenu && this.settingMenu.nativeElement.contains(e.target))) {
        this.showSettings = true;
      } else {
        this.showSettings = false;
      }
      if ((this.search && this.search.nativeElement.contains(e.target)) ||
        (this.searchInput && this.searchInput.nativeElement.contains(e.target))) {
        this.isSearchOpen = true;
      } else {

        if (this.searchValue.length > 0) {
          this.isSearchOpen = true;
        } else {
          this.isSearchOpen = false;
        }
      }
      if ((this.filter && this.filter.nativeElement.contains(e.target)) ||
        (this.filterMenu && this.filterMenu.nativeElement.contains(e.target)) ||
        (this.filterCount && this.filterCount.nativeElement.contains(e.target)) ||
        (this.classList.some(className => e.target['classList'].contains(className)))) {
        if (!this.classList.some(className => e.target['classList'].contains(className))) {
          this.showFilter = true;
        }
      } else {
        this.showFilter = false;
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  filterList = {};
  columnCust = [];

  ngOnInit(): void {
    this.tooltipName =  this.mainmenuName?.replace(/ List/ig, ' ');  
    if (this.tableConfig && this.tableConfig.density) {
      this.density = this.tableConfig.density;
    }
    if (this.tableConfig && this.tableConfig.columnList) {

      this.tableConfig.columnList.forEach(col => {
        this.filterList[col.name] = true;
        this.columnCust.push({ name: col.name, value: true });
      });
    }
    this.onSetting.emit(this.columnCust);
    if(this.tableConfig?.tabsList  && this.tableConfig?.tabsList.length > 0){ 
    const defaultSelected= this.currentTab || this.tableConfig?.tabsList[0];
    this.tabClick(defaultSelected);
    }

  }

  get sortOptions() {
    return (this.tableConfig.sortOptions && Array.isArray(this.tableConfig.sortOptions)) ? this.tableConfig.sortOptions : this.defaultSortOptions;
  }

  getActiveTab(tab){
    return this?.selectedTab?.toLowerCase() == tab?.toLowerCase();
  }
  onCreateClick() {
    this.onCreate.emit(true);
  }

  onFilterDone(event) {
    this.isFilterCleard = false
    if (event) {
      this.searchValue = '';
      this.isSearchOpen = false;
      this.totalFilterApplied = event.noOfFilter
      this.onListFilter.emit(event.filterData);
    } else {
      this.totalFilterApplied = 0
      this.onListFilter.emit();
    }
    this.changeDetectorRef.detectChanges()

    this.showFilter = false
  }

  onClick(event, page: string) {
    if (page === '-1') {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
      } else {
        event.preventDefault();
        return;
      }
    } else if (page === '+1') {
      if (this.currentPage < this.maxPages) {
        this.currentPage = this.currentPage + 1;
      } else {
        event.preventDefault();
        return;
      }
    }
    this.changePages.emit(this.currentPage);
    event.preventDefault();
  }

  tabClick(tab) {
    this.selectedTab = tab;
    this.onTabClick.emit(this.selectedTab);
  }

  onSearchClick(event = null) {
    if (event === null) {
      this.onSearch.emit(this.searchValue);
    } else if (event.keyCode === 13) {
      this.onSearch.emit(this.searchValue);
    } else if (this.searchValue.length === 0) {
      this.onSearch.emit(this.searchValue);
    }  
  }

  onMonthChange(event){
    if(event){
      this.selectedMonth = event;
      this.onMonthChangeEvent.emit(this.selectedMonth);
    }
  }

  onYearChange(event){
    if(event){
      this.selectedYear = event;
      this.onYearChangeEvent.emit(this.selectedYear);
    }
  }

  onClickColumn(name) {
    if (this.columnCust.indexOf(this.columnCust.find(n => n.name === name)) === 0) {
      this.columnCust.find(n => n.name === name).value = true;
    } else {
      this.columnCust.find(n => n.name === name).value = !this.columnCust.find(n => n.name === name).value;
    }
    this.filterList[name] = this.columnCust.find(n => n.name === name).value;
    this.onSetting.emit(this.columnCust);
  }

  onDensityClick(id) {
    this.density = id;
    this.eventStream.emit(new EmitEvent(Events.VIEW_TYPE, id));
  }

  onColumnCustomClick(name) {
    this.filterList[name] = !this.filterList[name];
  }

  onDragEnd(item: any, list: any[]) {
    list.splice(list.indexOf(item), 1);
  }

  onAdFilterClear() {
    this.isFilterCleard = true;
    this.totalFilterApplied = 0
    this.onListFilter.emit();
  }

  showGridViewLayout(event){
      console.log("event", event);
      this.eventStream.emit(new EmitEvent(Events.SHOW_GRID_LAYOUT, event));
  }

  onClickSort(eve){
    this.onSortFilter.emit(eve);
  }

  onSelectButtonClick(eve){
    this.onHeaderButtonClick.emit(eve);
  }
  onDownloadClick(eve){
    this.onDownloadButtonClick.emit(eve);
  }
  openDropdown() {
    this.openDrop = !this.openDrop;
  }
  onClickedOutside(e) {
    this.openDrop = false;
  }

}
