<app-job-details>
 
  <div class="description-card p-24 mb-24">
    <div class="dotloader" *ngIf="!jobDetails?.id"></div>
    <section class="content-section" *ngIf="jobDetails?.id">
      <aside class="detail-sidebar">
        <div class="bg-gray  mb-24 p-16" *ngIf="jobDetails?.id">
          <h4 class="section-title position-relative  mb-24">
            Basic Info
          </h4>
          <div class="d-flex mb-24">
            <p class="info-item">Category</p>
            <p class="info-item item-value" *ngIf="jobDetails?.category">{{jobDetails.category.category_name ? jobDetails.category.category_name : '--' }}</p>
          </div>
          <div class="d-flex mb-24">
            <p class="info-item">Job Manager</p>
            <p class="info-item  item-value">
              <figure class="d-inline-block mr-8"><img src="../../../assets/images/5.jpg" alt=""></figure> 
                  <span *ngIf="jobDetails?.job_manager">{{jobDetails.job_manager.first_name ? jobDetails.job_manager.first_name : '--'}} {{jobDetails.job_manager.last_name}}</span>
            </p>
          </div>
          <div class="d-flex mb-24">
            <p class="info-item">Start Date</p>
            <p class="info-item  item-value">{{getFormattedDate(jobDetails.start_date) }}</p>
          </div>
          <div class="d-flex mb-24">
            <p class="info-item">Openings</p>
            <p class="info-item  item-value">{{jobDetails.no_of_openings ? jobDetails.no_of_openings : '0'}}</p>
          </div>
          <div class="d-flex mb-24">
            <p class="info-item">Job level</p>
            <p class="info-item  item-value">{{jobDetails.level ? jobDetails.level : '--'}}</p>
          </div>
          <div class="d-flex mb-24">
            <p class="info-item">Assignment Length</p>
            <p class="info-item  item-value">{{jobDetails.assignment_length ? jobDetails.assignment_length : '0'}}</p>
          </div>
          <div class="d-flex mb-24">
            <p class="info-item">End Date</p>
            <p class="info-item  item-value">{{getFormattedDate(jobDetails.end_date)}}</p>
          </div>
          <div class="d-flex mb-24">
            <p class="info-item">Job Type</p>
            <p class="info-item  item-value">{{jobDetails.type ? jobDetails.type : '--'}}</p>
          </div>
        </div>
        <div class="bg-gray p-16" *ngIf="jobDetails?.id">
          <h4 class="section-title position-relative  mb-24">
              Financial Info
          </h4>
          <div class="d-flex mb-24">
            <p class="info-item">Min Bill Rate</p>
            <p class="info-item  item-value">{{jobDetails.min_bill_rate ? (jobDetails.min_bill_rate | currency:jobDetails?.currency) : (0.00 | currency:jobDetails?.currency)}}</p>
          </div>
          <div class="d-flex mb-24">
            <p class="info-item">Max Bill Rate</p>
            <p class="info-item  item-value">{{jobDetails.max_bill_rate ?  (jobDetails.max_bill_rate | currency:jobDetails?.currency ) : (0.00 | currency:jobDetails?.currency)}}</p>
          </div>
          <div class="d-flex mb-24">
            <p class="info-item">Estimate Budget</p>
            <p class="info-item  item-value">{{jobDetails.budget_estimate ? (jobDetails.budget_estimate | currency:jobDetails?.currency ) :  (0.00 | currency:jobDetails?.currency)}}</p>
          </div>
          <!-- <div class="d-flex mb-24">
            <p class="info-item">Maximum Budget</p>
            <p class="info-item  item-value">{{jobDetails.max_budget ? (jobDetails.max_budget  | currency:jobDetails?.currency ) :  (0.00 | currency:jobDetails?.currency)}}</p>
          </div> -->
        </div>
      </aside>
      <div class="content-part">
        <div *ngIf="isShowStatus" class="notifications pb-32 mb-12">
          <ul>
            <li class="float-left mr-16 rounded-sm p-12">
              <icon class="rounded-circle d-inline-block" size="14px" name="mark_email_unread" type="material"
              cuClass="icon-cu"></icon> <h4 class="d-inline-block ml-12 mr-16 mb-0">Pending Approval Request</h4> 
              <button class="d-inline-block btn small btn-secondary" (click)="openApprovalModal()">View</button>
            </li>
            <!-- <li class="float-left mr-16 rounded-sm p-12">
              <icon class="rounded-circle d-inline-block" size="14px" name="mark_email_unread" type="material"
              cuClass="icon-cu"></icon> <h4 class="d-inline-block ml-12 mr-16 mb-0">Pending Approval Request</h4> <button class="d-inline-block btn small btn-secondary">View</button>
            </li> -->
          </ul>
        </div>
        <h5 class="section-title position-relative mb-24">
          <icon class="mr-8" theme="outlined" size="20px" name="description" type="material"
            cuClass="icon-cu"></icon>
          Job Description
        </h5>
        <div class="description mb-24" [ngClass]="{'short-view' : !toggleDescription && (jobDetails?.description?.length > maxContentLength)}"  innerHTML=" {{toggleDescription ? jobDetails?.description : jobDetails?.description?.substr(0, maxContentLength)}}">
        </div>
        <button class="more-link" (click)="contentViewToggle()" *ngIf="jobDetails?.description?.length > maxContentLength">{{ toggleDescription ? 'View Less' : 'Read more'}}</button>
        <div *ngIf="jobDetails?.id" class="mt-32">
          <h5 class="section-title position-relative mb-24">
            <icon class="mr-8" theme="outlined" size="20px" name="offline_bolt" type="material"
              cuClass="icon-cu"></icon>
              Foundational Data
          </h5>
          <div class="info-horizontal d-flex">
            <div class="mb-24" *ngFor="let found of jobDetails?.foundational_data">
              <!-- <div *ngFor="let value of found?.values"> -->
                <p class="small label">{{found?.values?.name}}</p>
                <p>{{found?.values?.code}}</p>
              <!-- </div> -->
            
            </div>
            <div *ngIf="!jobDetails?.foundational_data?.length">
              No data available
            </div>
          </div>
        </div>
        <div *ngIf="jobDetails?.id" class="mt-32">
          <h5 class="section-title position-relative mb-24">
            <icon class="mr-8" theme="outlined" size="20px" name="school" type="material"
              cuClass="icon-cu"></icon>
              Qualifications
          </h5>
          <div class="d-flex mb-16" *ngFor="let qualification of jobDetails?.qualifications ;let i = index ">
            <div class="q-label">
              <p>{{qualification?.qualification_type}}</p>
            </div>
            <div class="q-value" *ngIf="qualification?.values?.length">
              <ul *ngFor="let value of qualification?.values">
                <li >{{value?.name}}</li>
              </ul>
            </div>
          
          </div>
          <div class="q-value" *ngIf="!jobDetails?.qualifications?.length || jobDetails?.qualifications?.length ==0 ">
            No data available
          </div>
          <!-- <div class="d-flex">
            <div class="q-label">
              <p>Skills</p>
            </div>
            <div class="q-value" *ngIf="jobDetails?.qualifications?.length">
              <ul *ngFor="let skill of jobDetails.qualifications">
                <li *ngIf="skill?.qualification_type == 'Skill'">{{skill?.values[0]?.name}}</li>
              </ul>
            </div>
            <div class="q-value" *ngIf="!jobDetails?.qualifications?.length">
              No data available
            </div>
          </div> -->
        </div>
      </div>
      <div class="clearfix"></div>
    </section>
  </div>
</app-job-details>
<app-pending-approval-request (reloadPage)="reloadDetailsPage($event)"></app-pending-approval-request>