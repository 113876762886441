import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthguardService } from '../core/services/auth_guard.service';
import { AccountSetupComponent } from './account-setup/account-setup.component';
import { InviteUserComponent } from '../auth/invite-user/invite-user.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',

    children: [
      { path: 'invite', component: AccountSetupComponent },
      { path: 'setup-password', component: AccountSetupComponent },
      { path: 'basic-details', component: AccountSetupComponent },
      { path: 'security-questions', component: AccountSetupComponent },
      { path: 'avatar', component: AccountSetupComponent },
      { path: 'theme-color', component: AccountSetupComponent },
      { path: 'accept', component: InviteUserComponent },
      { path: '', component: LoginComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  // providers:[AuthguardService]
})
export class AuthRoutingModule { }
