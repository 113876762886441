import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    if (environment.demoMode) this.baseUrl = environment.MOCK_ENDPOINT;
    else this.baseUrl = environment.API_ENDPOINT;
  }

  get(endpoint, baseurl?) {
    if (endpoint) {
      if (baseurl) {
        return this.http.get(baseurl + endpoint);
      }
      else {
        return this.http.get(this.baseUrl + endpoint);
      }
    } else {
      return of(null);
    }
  }

  downloadBlob(endpoint, baseurl?) {
    if (endpoint) {
      if (baseurl) {
        return this.http.get(baseurl + endpoint, { responseType: 'blob', observe: 'response' });
      }
      else {
        return this.http.get(this.baseUrl + endpoint, { responseType: 'blob', observe: 'response' });
      }
    } else {
      return of(null);
    }
  }

  downloadPostBlob(endpoint, data, baseurl?) {
    if (endpoint) {
      if (baseurl) {
        return this.http.post(baseurl + endpoint, data, { responseType: 'blob' });
      } else {
        return this.http.post(this.baseUrl + endpoint, data, { responseType: 'blob' });
      }
    } else {
      return of(null);
    }
  }

  uploadPostBlob(endpoint, data) {
    if (endpoint) {
      return this.http.post(endpoint, data, {
        reportProgress: true,
        observe: 'events'
      });
    } else {
      return of(null);
    }
  }

  getMock(url) {
    return this.http.get(url);
  }

  post(endpoint, data, baseurl?) {
    if (endpoint) {
      if (baseurl) {
        return this.http.post(baseurl + endpoint, data);
      }
      else {
        return this.http.post(this.baseUrl + endpoint, data);
      }
    } else {
      return of(null);
    }
  }

  patch(endpoint, data, baseurl?) {
    if (endpoint) {
      if (baseurl) {
        return this.http.patch(baseurl + endpoint, data);
      }
      else {
        return this.http.patch(this.baseUrl + endpoint, data);
      }
    } else {
      return of(null);
    }
  }

  put(endpoint, data?, baseurl?) {
    if (endpoint) {
      if (baseurl) {
        return this.http.put(baseurl + endpoint, data);
      }
      else {
        return this.http.put(this.baseUrl + endpoint, data);
      }
    } else {
      return of(null);
    }
  }

  delete(endpoint, data, baseurl?) {
    if (endpoint) {
      if (baseurl) {
        return this.http.delete(baseurl + endpoint);
      }
      else {
        return this.http.delete(this.baseUrl + endpoint);
      }
    } else {
      return of(null);
    }
  }

  getLocal(endpoint) {
    if (endpoint) {
      return this.http.get(endpoint);
    } else {
      return of(null);
    }
  }

}
