<div class="account-form-wrapper color-theme">
  <div class="top-left-corner"></div>
  <div class="top-right-corner"></div>
  <div class="bottom-left-corner"></div>
  <div class="bottom-right-corner"></div>
  <div class="form-wrapper">
    <div class="logo text-center pt-8">@</div>
    <h2 class="text-center">Choose Your Favourite Color Theme</h2>
    <p class="text-center mx-auto custom-width">You can set your favourite color theme for the application</p>
    <div class="progress-bar">
      <ul class="pb-12">
        <li class="done"></li>
        <li class="done"></li>
        <li class="done"></li>
        <li class="done"></li>
        <li class="active"></li>
      </ul>
    </div>
    <div class="color-theme-option">
      <ul>
        <li *ngFor="let colors of colorList">
          <label class="radio-container" [ngClass]="{'active': defaultColor === colors.name}"
            [style.background-color]="colors.navbg">
            <input type="radio" [(ngModel)]="defaultColor" name="my-theme" [value]="colors.name"
              (click)="setColor($event, colors)">
            <span class="checkmark">
              <span class="secondary-color" [style.background-color]="colors.color"></span>
            </span>
          </label>
        </li>
      </ul>
    </div>
    <div class="theme-preview">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="476" height="335"
        viewBox="22 0 476 335">
        <defs>
          <filter id="Rectangle_933" x="0" y="0" width="476" height="335" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="8" result="blur" />
            <feFlood flood-opacity="0.043" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clip-path">
            <rect width="66" height="210" fill="none" />
          </clipPath>
          <clipPath id="clip-path-2">
            <rect width="308" height="191" fill="none" />
          </clipPath>
        </defs>
        <g id="Group_738" data-name="Group 738" transform="translate(-479 -297)">
          <g transform="matrix(1, 0, 0, 1, 479, 297)" filter="url(#Rectangle_933)">
            <g id="Rectangle_933-2" data-name="Rectangle 933" transform="translate(24 21)" fill="#fff" stroke="#dbdbdb"
              stroke-width="1">
              <rect width="428" height="287" rx="6" stroke="none" />
              <rect x="0.5" y="0.5" width="427" height="286" rx="5.5" fill="none" />
            </g>
          </g>
          <path id="Rectangle_934" data-name="Rectangle 934"
            d="M6,0H94a0,0,0,0,1,0,0V287a0,0,0,0,1,0,0H6a6,6,0,0,1-6-6V6A6,6,0,0,1,6,0Z" transform="translate(503 318)"
            [attr.fill]="defaultNavBg" />
          <circle id="Ellipse_81" data-name="Ellipse 81" cx="9.5" cy="9.5" r="9.5" transform="translate(515 331)"
            fill="#fff" />
          <g id="Repeat_Grid_8" data-name="Repeat Grid 8" transform="translate(518 367)" clip-path="url(#clip-path)">
            <g id="Group_736" data-name="Group 736" transform="translate(-518 -367)">
              <circle id="Ellipse_82" data-name="Ellipse 82" cx="5.5" cy="5.5" r="5.5" transform="translate(518 368)"
                fill="#e1ddff" />
              <rect id="Rectangle_936" data-name="Rectangle 936" width="40" height="6" rx="3"
                transform="translate(539 370)" fill="#e1ddff" />
            </g>
            <g id="Group_736-2" data-name="Group 736" transform="translate(-518 -339)">
              <circle id="Ellipse_82-2" data-name="Ellipse 82" cx="5.5" cy="5.5" r="5.5" transform="translate(518 368)"
                fill="#e1ddff" />
              <rect id="Rectangle_936-2" data-name="Rectangle 936" width="40" height="6" rx="3"
                transform="translate(539 370)" fill="#e1ddff" />
            </g>
            <g id="Group_736-3" data-name="Group 736" transform="translate(-518 -311)">
              <circle id="Ellipse_82-3" data-name="Ellipse 82" cx="5.5" cy="5.5" r="5.5" transform="translate(518 368)"
                fill="#e1ddff" />
              <rect id="Rectangle_936-3" data-name="Rectangle 936" width="40" height="6" rx="3"
                transform="translate(539 370)" fill="#e1ddff" />
            </g>
            <g id="Group_736-4" data-name="Group 736" transform="translate(-518 -283)">
              <circle id="Ellipse_82-4" data-name="Ellipse 82" cx="5.5" cy="5.5" r="5.5" transform="translate(518 368)"
                fill="#e1ddff" />
              <rect id="Rectangle_936-4" data-name="Rectangle 936" width="40" height="6" rx="3"
                transform="translate(539 370)" fill="#e1ddff" />
            </g>
            <g id="Group_736-5" data-name="Group 736" transform="translate(-518 -255)">
              <circle id="Ellipse_82-5" data-name="Ellipse 82" cx="5.5" cy="5.5" r="5.5" transform="translate(518 368)"
                fill="#e1ddff" />
              <rect id="Rectangle_936-5" data-name="Rectangle 936" width="40" height="6" rx="3"
                transform="translate(539 370)" fill="#e1ddff" />
            </g>
            <g id="Group_736-6" data-name="Group 736" transform="translate(-518 -227)">
              <circle id="Ellipse_82-6" data-name="Ellipse 82" cx="5.5" cy="5.5" r="5.5" transform="translate(518 368)"
                fill="#e1ddff" />
              <rect id="Rectangle_936-6" data-name="Rectangle 936" width="40" height="6" rx="3"
                transform="translate(539 370)" fill="#e1ddff" />
            </g>
            <g id="Group_736-7" data-name="Group 736" transform="translate(-518 -199)">
              <circle id="Ellipse_82-7" data-name="Ellipse 82" cx="5.5" cy="5.5" r="5.5" transform="translate(518 368)"
                fill="#e1ddff" />
              <rect id="Rectangle_936-7" data-name="Rectangle 936" width="40" height="6" rx="3"
                transform="translate(539 370)" fill="#e1ddff" />
            </g>
            <g id="Group_736-8" data-name="Group 736" transform="translate(-518 -171)">
              <circle id="Ellipse_82-8" data-name="Ellipse 82" cx="5.5" cy="5.5" r="5.5" transform="translate(518 368)"
                fill="#e1ddff" />
              <rect id="Rectangle_936-8" data-name="Rectangle 936" width="40" height="6" rx="3"
                transform="translate(539 370)" fill="#e1ddff" />
            </g>
          </g>
          <g id="Rectangle_937" data-name="Rectangle 937" transform="translate(597 318)" fill="#fff" stroke="#ededed"
            stroke-width="1">
            <path d="M0,0H328a6,6,0,0,1,6,6V31a1,1,0,0,1-1,1H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" stroke="none" />
            <path d="M1,.5H328A5.5,5.5,0,0,1,333.5,6V31a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,31V1A.5.5,0,0,1,1,.5Z"
              fill="none" />
          </g>
          <rect id="Rectangle_938" data-name="Rectangle 938" width="62" height="15" rx="5"
            transform="translate(611 365)" fill="#ede8e8" />
          <rect id="Rectangle_939" data-name="Rectangle 939" width="38" height="15" rx="5"
            transform="translate(679 365)" [attr.fill]="defaultThemeColor" />
          <circle id="Ellipse_83" data-name="Ellipse 83" cx="6" cy="6" r="6" transform="translate(871 367)"
            fill="#ede8e8" />
          <circle id="Ellipse_84" data-name="Ellipse 84" cx="6" cy="6" r="6" transform="translate(889 367)"
            fill="#ede8e8" />
          <circle id="Ellipse_85" data-name="Ellipse 85" cx="6" cy="6" r="6" transform="translate(907 367)"
            fill="#ede8e8" />
          <g id="Repeat_Grid_9" data-name="Repeat Grid 9" transform="translate(611 399)" clip-path="url(#clip-path-2)">
            <g transform="translate(-611 -399)">
              <g id="Rectangle_940" data-name="Rectangle 940" transform="translate(608 399)" fill="#fff" stroke="#fff"
                stroke-width="1">
                <rect width="317" height="23" rx="2" stroke="none" />
                <rect x="0.5" y="0.5" width="316" height="22" rx="1.5" fill="none" />
              </g>
              <circle id="Ellipse_86" data-name="Ellipse 86" cx="4.5" cy="4.5" r="4.5" transform="translate(612 405)"
                fill="#6f5cec" opacity="0.256" />
              <rect id="Rectangle_941" data-name="Rectangle 941" width="62" height="4" rx="2"
                transform="translate(633 408)" fill="#ede8e8" />
              <rect id="Rectangle_942" data-name="Rectangle 942" width="34" height="4" rx="2"
                transform="translate(714 408)" fill="#ede8e8" />
              <rect id="Rectangle_944" data-name="Rectangle 944" width="34" height="4" rx="2"
                transform="translate(827 408)" fill="#ede8e8" />
              <rect id="Rectangle_945" data-name="Rectangle 945" width="51" height="4" rx="2"
                transform="translate(869 408)" fill="#ede8e8" />
              <rect id="Rectangle_943" data-name="Rectangle 943" width="59" height="4" rx="2"
                transform="translate(758 408)" fill="#ede8e8" />
            </g>
            <g transform="translate(-611 -378)">
              <g id="Rectangle_940-2" data-name="Rectangle 940" transform="translate(608 399)" fill="#fff" stroke="#fff"
                stroke-width="1">
                <rect width="317" height="23" rx="2" stroke="none" />
                <rect x="0.5" y="0.5" width="316" height="22" rx="1.5" fill="none" />
              </g>
              <circle id="Ellipse_86-2" data-name="Ellipse 86" cx="4.5" cy="4.5" r="4.5" transform="translate(612 405)"
                fill="#6f5cec" opacity="0.256" />
              <rect id="Rectangle_941-2" data-name="Rectangle 941" width="62" height="4" rx="2"
                transform="translate(633 408)" fill="#ede8e8" />
              <rect id="Rectangle_942-2" data-name="Rectangle 942" width="34" height="4" rx="2"
                transform="translate(714 408)" fill="#ede8e8" />
              <rect id="Rectangle_944-2" data-name="Rectangle 944" width="34" height="4" rx="2"
                transform="translate(827 408)" fill="#ede8e8" />
              <rect id="Rectangle_945-2" data-name="Rectangle 945" width="51" height="4" rx="2"
                transform="translate(869 408)" fill="#ede8e8" />
              <rect id="Rectangle_943-2" data-name="Rectangle 943" width="59" height="4" rx="2"
                transform="translate(758 408)" fill="#ede8e8" />
            </g>
            <g transform="translate(-611 -357)">
              <g id="Rectangle_940-3" data-name="Rectangle 940" transform="translate(608 399)" fill="#fff" stroke="#fff"
                stroke-width="1">
                <rect width="317" height="23" rx="2" stroke="none" />
                <rect x="0.5" y="0.5" width="316" height="22" rx="1.5" fill="none" />
              </g>
              <circle id="Ellipse_86-3" data-name="Ellipse 86" cx="4.5" cy="4.5" r="4.5" transform="translate(612 405)"
                fill="#6f5cec" opacity="0.256" />
              <rect id="Rectangle_941-3" data-name="Rectangle 941" width="62" height="4" rx="2"
                transform="translate(633 408)" fill="#ede8e8" />
              <rect id="Rectangle_942-3" data-name="Rectangle 942" width="34" height="4" rx="2"
                transform="translate(714 408)" fill="#ede8e8" />
              <rect id="Rectangle_944-3" data-name="Rectangle 944" width="34" height="4" rx="2"
                transform="translate(827 408)" fill="#ede8e8" />
              <rect id="Rectangle_945-3" data-name="Rectangle 945" width="51" height="4" rx="2"
                transform="translate(869 408)" fill="#ede8e8" />
              <rect id="Rectangle_943-3" data-name="Rectangle 943" width="59" height="4" rx="2"
                transform="translate(758 408)" fill="#ede8e8" />
            </g>
            <g transform="translate(-611 -336)">
              <g id="Rectangle_940-4" data-name="Rectangle 940" transform="translate(608 399)" fill="#fff" stroke="#fff"
                stroke-width="1">
                <rect width="317" height="23" rx="2" stroke="none" />
                <rect x="0.5" y="0.5" width="316" height="22" rx="1.5" fill="none" />
              </g>
              <circle id="Ellipse_86-4" data-name="Ellipse 86" cx="4.5" cy="4.5" r="4.5" transform="translate(612 405)"
                fill="#6f5cec" opacity="0.256" />
              <rect id="Rectangle_941-4" data-name="Rectangle 941" width="62" height="4" rx="2"
                transform="translate(633 408)" fill="#ede8e8" />
              <rect id="Rectangle_942-4" data-name="Rectangle 942" width="34" height="4" rx="2"
                transform="translate(714 408)" fill="#ede8e8" />
              <rect id="Rectangle_944-4" data-name="Rectangle 944" width="34" height="4" rx="2"
                transform="translate(827 408)" fill="#ede8e8" />
              <rect id="Rectangle_945-4" data-name="Rectangle 945" width="51" height="4" rx="2"
                transform="translate(869 408)" fill="#ede8e8" />
              <rect id="Rectangle_943-4" data-name="Rectangle 943" width="59" height="4" rx="2"
                transform="translate(758 408)" fill="#ede8e8" />
            </g>
            <g transform="translate(-611 -315)">
              <g id="Rectangle_940-5" data-name="Rectangle 940" transform="translate(608 399)" fill="#fff" stroke="#fff"
                stroke-width="1">
                <rect width="317" height="23" rx="2" stroke="none" />
                <rect x="0.5" y="0.5" width="316" height="22" rx="1.5" fill="none" />
              </g>
              <circle id="Ellipse_86-5" data-name="Ellipse 86" cx="4.5" cy="4.5" r="4.5" transform="translate(612 405)"
                fill="#6f5cec" opacity="0.256" />
              <rect id="Rectangle_941-5" data-name="Rectangle 941" width="62" height="4" rx="2"
                transform="translate(633 408)" fill="#ede8e8" />
              <rect id="Rectangle_942-5" data-name="Rectangle 942" width="34" height="4" rx="2"
                transform="translate(714 408)" fill="#ede8e8" />
              <rect id="Rectangle_944-5" data-name="Rectangle 944" width="34" height="4" rx="2"
                transform="translate(827 408)" fill="#ede8e8" />
              <rect id="Rectangle_945-5" data-name="Rectangle 945" width="51" height="4" rx="2"
                transform="translate(869 408)" fill="#ede8e8" />
              <rect id="Rectangle_943-5" data-name="Rectangle 943" width="59" height="4" rx="2"
                transform="translate(758 408)" fill="#ede8e8" />
            </g>
            <g transform="translate(-611 -294)">
              <g id="Rectangle_940-6" data-name="Rectangle 940" transform="translate(608 399)" fill="#fff" stroke="#fff"
                stroke-width="1">
                <rect width="317" height="23" rx="2" stroke="none" />
                <rect x="0.5" y="0.5" width="316" height="22" rx="1.5" fill="none" />
              </g>
              <circle id="Ellipse_86-6" data-name="Ellipse 86" cx="4.5" cy="4.5" r="4.5" transform="translate(612 405)"
                fill="#6f5cec" opacity="0.256" />
              <rect id="Rectangle_941-6" data-name="Rectangle 941" width="62" height="4" rx="2"
                transform="translate(633 408)" fill="#ede8e8" />
              <rect id="Rectangle_942-6" data-name="Rectangle 942" width="34" height="4" rx="2"
                transform="translate(714 408)" fill="#ede8e8" />
              <rect id="Rectangle_944-6" data-name="Rectangle 944" width="34" height="4" rx="2"
                transform="translate(827 408)" fill="#ede8e8" />
              <rect id="Rectangle_945-6" data-name="Rectangle 945" width="51" height="4" rx="2"
                transform="translate(869 408)" fill="#ede8e8" />
              <rect id="Rectangle_943-6" data-name="Rectangle 943" width="59" height="4" rx="2"
                transform="translate(758 408)" fill="#ede8e8" />
            </g>
            <g transform="translate(-611 -273)">
              <g id="Rectangle_940-7" data-name="Rectangle 940" transform="translate(608 399)" fill="#fff" stroke="#fff"
                stroke-width="1">
                <rect width="317" height="23" rx="2" stroke="none" />
                <rect x="0.5" y="0.5" width="316" height="22" rx="1.5" fill="none" />
              </g>
              <circle id="Ellipse_86-7" data-name="Ellipse 86" cx="4.5" cy="4.5" r="4.5" transform="translate(612 405)"
                fill="#6f5cec" opacity="0.256" />
              <rect id="Rectangle_941-7" data-name="Rectangle 941" width="62" height="4" rx="2"
                transform="translate(633 408)" fill="#ede8e8" />
              <rect id="Rectangle_942-7" data-name="Rectangle 942" width="34" height="4" rx="2"
                transform="translate(714 408)" fill="#ede8e8" />
              <rect id="Rectangle_944-7" data-name="Rectangle 944" width="34" height="4" rx="2"
                transform="translate(827 408)" fill="#ede8e8" />
              <rect id="Rectangle_945-7" data-name="Rectangle 945" width="51" height="4" rx="2"
                transform="translate(869 408)" fill="#ede8e8" />
              <rect id="Rectangle_943-7" data-name="Rectangle 943" width="59" height="4" rx="2"
                transform="translate(758 408)" fill="#ede8e8" />
            </g>
            <g transform="translate(-611 -252)">
              <g id="Rectangle_940-8" data-name="Rectangle 940" transform="translate(608 399)" fill="#fff" stroke="#fff"
                stroke-width="1">
                <rect width="317" height="23" rx="2" stroke="none" />
                <rect x="0.5" y="0.5" width="316" height="22" rx="1.5" fill="none" />
              </g>
              <circle id="Ellipse_86-8" data-name="Ellipse 86" cx="4.5" cy="4.5" r="4.5" transform="translate(612 405)"
                fill="#6f5cec" opacity="0.256" />
              <rect id="Rectangle_941-8" data-name="Rectangle 941" width="62" height="4" rx="2"
                transform="translate(633 408)" fill="#ede8e8" />
              <rect id="Rectangle_942-8" data-name="Rectangle 942" width="34" height="4" rx="2"
                transform="translate(714 408)" fill="#ede8e8" />
              <rect id="Rectangle_944-8" data-name="Rectangle 944" width="34" height="4" rx="2"
                transform="translate(827 408)" fill="#ede8e8" />
              <rect id="Rectangle_945-8" data-name="Rectangle 945" width="51" height="4" rx="2"
                transform="translate(869 408)" fill="#ede8e8" />
              <rect id="Rectangle_943-8" data-name="Rectangle 943" width="59" height="4" rx="2"
                transform="translate(758 408)" fill="#ede8e8" />
            </g>
            <g transform="translate(-611 -231)">
              <g id="Rectangle_940-9" data-name="Rectangle 940" transform="translate(608 399)" fill="#fff" stroke="#fff"
                stroke-width="1">
                <rect width="317" height="23" rx="2" stroke="none" />
                <rect x="0.5" y="0.5" width="316" height="22" rx="1.5" fill="none" />
              </g>
              <circle id="Ellipse_86-9" data-name="Ellipse 86" cx="4.5" cy="4.5" r="4.5" transform="translate(612 405)"
                fill="#6f5cec" opacity="0.256" />
              <rect id="Rectangle_941-9" data-name="Rectangle 941" width="62" height="4" rx="2"
                transform="translate(633 408)" fill="#ede8e8" />
              <rect id="Rectangle_942-9" data-name="Rectangle 942" width="34" height="4" rx="2"
                transform="translate(714 408)" fill="#ede8e8" />
              <rect id="Rectangle_944-9" data-name="Rectangle 944" width="34" height="4" rx="2"
                transform="translate(827 408)" fill="#ede8e8" />
              <rect id="Rectangle_945-9" data-name="Rectangle 945" width="51" height="4" rx="2"
                transform="translate(869 408)" fill="#ede8e8" />
              <rect id="Rectangle_943-9" data-name="Rectangle 943" width="59" height="4" rx="2"
                transform="translate(758 408)" fill="#ede8e8" />
            </g>
            <g transform="translate(-611 -210)">
              <g id="Rectangle_940-10" data-name="Rectangle 940" transform="translate(608 399)" fill="#fff"
                stroke="#fff" stroke-width="1">
                <rect width="317" height="23" rx="2" stroke="none" />
                <rect x="0.5" y="0.5" width="316" height="22" rx="1.5" fill="none" />
              </g>
              <circle id="Ellipse_86-10" data-name="Ellipse 86" cx="4.5" cy="4.5" r="4.5" transform="translate(612 405)"
                fill="#6f5cec" opacity="0.256" />
              <rect id="Rectangle_941-10" data-name="Rectangle 941" width="62" height="4" rx="2"
                transform="translate(633 408)" fill="#ede8e8" />
              <rect id="Rectangle_942-10" data-name="Rectangle 942" width="34" height="4" rx="2"
                transform="translate(714 408)" fill="#ede8e8" />
              <rect id="Rectangle_944-10" data-name="Rectangle 944" width="34" height="4" rx="2"
                transform="translate(827 408)" fill="#ede8e8" />
              <rect id="Rectangle_945-10" data-name="Rectangle 945" width="51" height="4" rx="2"
                transform="translate(869 408)" fill="#ede8e8" />
              <rect id="Rectangle_943-10" data-name="Rectangle 943" width="59" height="4" rx="2"
                transform="translate(758 408)" fill="#ede8e8" />
            </g>
          </g>
          <g id="Rectangle_946" data-name="Rectangle 946" transform="translate(609 325)" fill="#fff" stroke="#ebebeb"
            stroke-width="1">
            <rect width="108" height="16" rx="8" stroke="none" />
            <rect x="0.5" y="0.5" width="107" height="15" rx="7.5" fill="none" />
          </g>
          <g id="Rectangle_947" data-name="Rectangle 947" transform="translate(890 328)" fill="#fff" stroke="#ebebeb"
            stroke-width="1">
            <rect width="12" height="12" rx="6" stroke="none" />
            <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" fill="none" />
          </g>
          <g id="Rectangle_948" data-name="Rectangle 948" transform="translate(907 328)" fill="#fff" stroke="#ebebeb"
            stroke-width="1">
            <rect width="12" height="12" rx="6" stroke="none" />
            <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" fill="none" />
          </g>
          <g id="Rectangle_949" data-name="Rectangle 949" transform="translate(873 328)" fill="#fff" stroke="#ebebeb"
            stroke-width="1">
            <rect width="12" height="12" rx="6" stroke="none" />
            <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" fill="none" />
          </g>
        </g>
      </svg>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button [disabled]="disableSubmit" class="account-btn btn btn-secondary float-right" (click)="onNextClick()">Save &amp; Continue
          <icon class="icon--on-right d-flex" cuClass="intro-btn" name="keyboard_backspace" size="24px" type="material"></icon>
        </button>
        <!-- <button class="account-btn btn btn-outine skip-btn float-right mr-16 pr-32 pl-32" (click)="onSkip()">Skip</button> -->
        <button class="account-btn btn btn-outine icon skip float-left" (click)="onBackClick()">
          <icon cuClass="intro-btn" name="keyboard_backspace" size="24px" type="material"></icon>
        </button>
      </div>
    </div>
  </div>
</div>