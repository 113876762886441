import { Injectable } from '@angular/core';
import { HttpService } from '../core/services/http.service';
import { map } from 'rxjs/operators';
import { StorageService } from '../core/services/storage.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private currentProgram;
  private baseUrl;
  private reportUrl;
  constructor(private http: HttpService,private storageService: StorageService) {
    this.currentProgram = this.storageService.get('CurrentProgram');
    this.baseUrl = environment.API_ENDPOINT;
    this.reportUrl = environment.REPORT_ENDPOINT;
  }

  get(url) {
    return this.http.get(url);
  }

  getreport(url) {
    return this.http.get(url, this.reportUrl);
  }

  post(url, payload?) {
    return this.http.post(url, payload, this.baseUrl);
  }
  getConfigJson(programId, userId, roleId) {
    return this.http.post(`/report/programs/${programId}/get-widgets`, {
      "user_id": userId,
      "role_id": roleId
    },this.reportUrl);

  }

  getmasterConfigJson(programId) {
    return this.http.get(`/configurator/programs/${programId}/config?entity_code=dashboard_master_data`)
  }

 getWorkersList(){
    const _url = `/assignment/programs/${this.currentProgram?.id}/worker`;
    return this.get(_url).pipe(map(
      data => {
        return data;
      }
    ));
  }
}
