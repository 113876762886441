<div class="px-24 reports-bg">
    <div class="header py-24 d-flex aign-items-center justify-content-between">
        <a routerLink="/reports/dashboard" class="d-flex align-items-center text-primary">
            <icon size="24px" name="keyboard_arrow_left" type="material" cuClass="d-flex mr-3"></icon>
            <span>Back to Reports</span>
        </a>
        <div class="d-flex align-items-center">
            <!-- <ng-select class="m-width" [clearable]="false" placeholder="Select Type" (change)="downloadReport($event)">
                <ng-option [value]="'excel'">Excel</ng-option>
                <ng-option [value]="'csv'">CSV</ng-option>
                <ng-option [value]="'pdf'">PDF</ng-option>
            </ng-select> -->
            <div class="btn-group position-relative" (click)="openDropdown()" (clickOutside)="onClickedOutside($event)">
                <button type="button" class="btn btn-primary d-flex align-items-center small">
                   Download
                    <icon class="d-flex cursor-pointer icon--on-right" size="20px" name="arrow_drop_down"
                        type="material" cuClass="d-flex">
                    </icon>
                </button>
                <ul class="dropdown-menu" *ngIf="openDrop">
                    <li class="dropdown-item cursor-pointer" (click)="downloadReport('excel')">Excel
                    </li>
                    <li class="dropdown-item cursor-pointer" (click)="downloadReport('csv')">CSV
                    </li>
                    <li class="dropdown-item cursor-pointer" (click)="downloadReport('pdf')">PDF
                    </li>
                </ul>
            </div>

            <button type="button" class="btn btn-outline-primary small ml-12" (click)="dataVisibility = 'visible'">
                <icon size="18px" name="filter_alt" type="material" cuClass="d-flex mr-8"></icon>

                <span>Filter & Fields</span>
            </button>

        </div>
    </div>

    <!-- DO NOT REMOVE IT. NOT IN SCOPE FOR WIRPO -->
    <!--     
    <div class="panel panel-light mb-24 p-24">
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <h4 class="font-weight-medium mr-16 mb-0">
                    {{ this.tableConfig.title }}
                </h4>
                <div class="position-relative">
                   
                    <div class="">
                        <ng-select [clearable]="true" placeholder="Status" (change)="getDimentionId($event)"
                            ([ngModel])="(dimention.Id)">
                            <ng-option *ngFor="let dimention of diamentionData" value="{{ dimention?.dimension_id }}">{{
                                dimention?.label }}</ng-option>
                        </ng-select>
                    </div>
                  
                </div>
            </div>

            <div class="d-flex aign-items-center chart-filter cursor-pointer">
                <a class="active" (click)="activeChart('barChart')">
                    <icon size="16px" name="bar_chart" type="material" cuClass="d-flex"></icon>
                </a>
                <a class="flip cursor-pointer" (click)="activeChart('colChart')">
                    <icon size="16px" name="bar_chart" type="material" cuClass="d-flex"></icon>
                </a>
                <a class="cursor-pointer" (click)="activeChart('pieChart')">
                    <icon size="16px" name="pie_chart" type="material" cuClass="d-flex"></icon>
                </a>

                <a class="cursor-pointer" (click)="activeChart('donutChart')">
                    <icon size="16px" name="donut_large" type="material" cuClass="d-flex"></icon>
                </a>
                <a class="cursor-pointer" (click)="activeChart('lineChart')">
                    <icon size="16px" name="show_chart" type="material" cuClass="d-flex"></icon>
                </a>
             
            </div>
        </div>

        
        <div class="d-flex aign-items-center chartContainer">
           
            <svms-column-chart class="chart_center" [chart]="chartData" *ngIf="activeChartValue==='colChart'">
            </svms-column-chart>
          
            <svms-bar-chart class="chart_center" [chart]="chartData" *ngIf="activeChartValue==='barChart'">
            </svms-bar-chart>
            
            <svms-line-chart class="chart_center" [chart]="chartData" *ngIf="activeChartValue==='lineChart'">
            </svms-line-chart>
            
            <svms-pie-chart class="chart_center" [chart]="chartData" *ngIf="activeChartValue==='pieChart'">
            </svms-pie-chart>
           
            <app-donut-chart class="chart_center" [chart]="chartData" *ngIf="activeChartValue==='donutChart'">
            </app-donut-chart>
        </div>
    </div> -->

    <vms-table *ngIf="tableLoaded" [loading]="dataLoading" [vmsTableConfig]="tableConfig" [vmsDataSource]="reportData?.table_data" [totalItem]="totalRecords" (onExpandClick)="onExpandClick()" [itemsPerPage]="itemPerPage" (changePage)="onPaginationClick($event)"
        (onViewClick)="onClickView()" (onCreate)="onCreateClick()" (onSort)="onSortClick()" (search)="onSearch($event)">
    </vms-table>

    <svms-sidebar [title]="'Filter & Fields'" [dataVisibility]="dataVisibility" (onClose)="closehistoryView()">
        <app-filter-fields-sidepanel [reportKey]="reportKey" [reportFilterData]="report_filters" (onSubmit)="applyReportFilter($event)" (onClose)="closehistoryView()">
        </app-filter-fields-sidepanel>
    </svms-sidebar>
</div>