import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName, FormArray } from '@angular/forms';
import {
  EventStreamService,
  Events,
  EmitEvent,
} from 'src/app/core/services/event-stream.service';
import { CandidateService } from '../../service/candidate.service';
import { errorHandler } from '../../../shared/util/error-handler';
import { AlertService } from 'src/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-add-specialities',
  templateUrl: './add-specialities.component.html',
  styleUrls: ['./add-specialities.component.scss']
})
export class AddSpecialitiesComponent implements OnInit {
  @Output() specialityData: EventEmitter<any> = new EventEmitter<any>();
  closePanel: EventEmitter<boolean> = new EventEmitter();
  @Input() specialityTypeId;
  specialityList = [];
  addSpecialities = "hidden";
  panelHeading = 'Add New Speciality';
  public addSpecialitiesForm: FormGroup;
  editPanel = false;
  clickedIndex ;
  constructor(
    private fb: FormBuilder,
    private eventStream: EventStreamService,
    private alertService: AlertService,
    private candidateService : CandidateService
  ) {

  }

  ngOnInit(): void {
    this.eventStream.on(Events.CREATE_CANDIDATE_SPECIALITY, (data) => {
      if (data.value) {
        this.addSpecialities = 'visible';
        if(data.obj != null){
          this.updatePanel(data.obj);
          this.panelHeading = 'Edit Speciality';
          this.editPanel = true;
          this.clickedIndex = data.obj.clickedIndex;
        }
      } else {
        this.addSpecialities = 'hidden';
      }
    });
    this.getSpecialities();
    this.addSpecialitiesForm = this.fb.group({
      specialityType: [null, Validators.required],
      experienceLevel: [null, Validators.required],
      description: [null, ''],
      notes: [null, ''],
      files:[null,'']
    });
  }
  uploadFiles(event){
    this.addSpecialitiesForm.patchValue({
      files:event
    })
  }
  sidebarClose() {
    this.addSpecialities = 'hidden';
    this.addSpecialitiesForm.reset();
    this.panelHeading = 'Add New Speciality';
    this.editPanel = false;
    this.closePanel.emit(true);
  }
  getSpecialities(){
    const specialityId = this.specialityTypeId;
    this.candidateService._getQualiFicationType(specialityId) .subscribe((res) => {
      this.specialityList = res.qualifications;
    }, (err) => {
      this.alertService.error(errorHandler(err));
    })
  }
  updatePanel(data){
    this.addSpecialitiesForm.patchValue({
      specialityType: data.qualification_id,
      experienceLevel: data.experience_level,
      description: data.description,
      notes: data.notes,
      files: data.files
    })
  }
  saveSkill(action){
    if(this.addSpecialitiesForm.valid){
      let data = this.addSpecialitiesForm.value;
      const specType = this.specialityList.filter((item)=>{
        if(item.id == data.specialityType){
          return item.name;
        }
      }).map((obj)=>{
          return obj.name;
      })
      const payload={
        qualification_id: data.specialityType,
        experience_level: data.experienceLevel,
        description: data.description,
        notes:data.notes,
        files:data.files,
        speciality_name: specType
      }
      this.specialityData.emit({payload: payload, isEdit:this.editPanel, clickedIndex: this.clickedIndex});
      this.addSpecialitiesForm.reset();
      if(action == 'save'){
        this.sidebarClose();
      }else{
        this.editPanel = false;
        this.panelHeading = 'Add New Speciality';
        this.closePanel.emit(true);
      }
    }else{
      this.alertService.error('Please fill the required fields properly.');
    }
  }
}
