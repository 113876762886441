<div class="account-form-wrapper customize-avatar">
  <div class="top-left-corner"></div>
  <div class="top-right-corner"></div>
  <div class="bottom-left-corner"></div>
  <div class="bottom-right-corner"></div>
  <div class="form-wrapper">
    <div class="logo text-center pt-8">@</div>
    <h2 class="text-center">Customise Your Avatar</h2>
    <p class="text-center mx-auto pl-24 pr-24">Upload an Image of yourself so others can get to know you. Or choose
      your letter icon with your favourite color.</p>
    <div class="progress-bar">
      <ul class="pb-12">
        <li class="done"></li>
        <li class="done"></li>
        <li class="done"></li>
        <li class="active"></li>
        <li></li>
      </ul>
    </div>
  
    <div class="position-relative">
      <div class="upload-box" (click)="fileInput.click()" [ngClass]="{'after-image-upload': showCropper}">
        <div class="before-img-upload">
          <icon cuClass="upload-icon" name="cloud_upload" size="30px" type="material"></icon>
          <p *ngIf="!croppedImage">upload an image or
            <span>browse</span>
          </p>
        </div>
        <input hidden type="file" #fileInput (change)="fileChangeEvent($event)">
        <img *ngIf="croppedImage" class="img-after-upload" [src]="croppedImage"
          [style.z-index]="croppedImage ? '1' : '-1'" />
        <div class="upload-image-edit">
          <div class="edit-icon" (click)="fileInput.click()">
            <icon cuClass="d-flex" name="create" size="30px" type="material"></icon>
          </div>
 
        </div>
      </div>
      <div *ngIf="croppedImage" class="edit-icon deleteicon" (click)="cancelUpload()">
        <icon cuClass="d-flex" name="delete" size="30px" type="material"></icon>
      </div>
    </div>
    <div class="other-option-title">
      <span>Or choose from below</span>
    </div>
    <div class="other-avatar-option">
      <ul>
        <li *ngFor="let avatars of avatarList">
          <label class="radio-container" [style.background-color]="avatars.color">
            <input type="radio" [(ngModel)]="defaultColor" name="my-avatar" [value]="avatars.color"
              (click)="setAvatar($event, avatars)">
            <span class="checkmark"></span>
            <span class="text">{{name | shortName: 'last'}}</span>
          </label>
        </li>
      </ul>
      <span class="active-avatar" [style.background-color]="defaultColor">{{name | shortName: 'last'}}</span>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-center">
        <button class="account-btn btn btn-outine icon skip mr-16" (click)="onBackClick()">
          <icon cuClass="intro-btn" name="keyboard_backspace" size="24px" type="material"></icon>
        </button>
        <button class="account-btn btn btn-outine skip-btn mr-16 pr-32 pl-32" (click)="onSkipClick()">Skip</button>
        <button class="account-btn btn btn-secondary" (click)="chooseColorTheme()">Save &amp; Continue
          <icon class="icon--on-right d-flex" cuClass="intro-btn" name="keyboard_backspace" size="24px" type="material">
          </icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="cropping-avtar align-items-center justify-content-center d-none" *ngIf="imageLoad">
  <div class="cropping-avtar-image rounded-sm position-relative">
    <div class="close_img_croper" (click)="cancelUpload()"> <icon cuClass="d-flex justify-content-end" name="close" size="30px" type="material"></icon></div>
    <div class="image">
      <!-- <img src="../../../../assets/images/crop-images.png" class="img-fluid" /> -->
      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
        [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="1/ 1" [onlyScaleDown]="true"
        [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'center'"
        [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()">
      </image-cropper>
    </div>
    <div class="mt-16 d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-light" (click)="zoomIn()">
          <icon cuClass="d-flex" name="zoom_in" size="24px" type="material"></icon>
        </button>
        <button type="button" class="btn btn-light" (click)="zoomOut()">
          <icon cuClass="d-flex" theme="outlined" name="zoom_out" size="24px" type="material"></icon>
        </button>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <button type="reset"  class="btn btn-outline-danger mr-16" (click)="cancelUpload()">
          Cancel
        </button>
        <button type="button" class="btn btn-secondary" (click)="crop()">
          Crop & Save
        </button>
      </div>
    </div>
  </div>
</div>
