import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuillModule } from 'ngx-quill';
import { SvmsHierarchyModule } from 'src/app/library/hierarchy/hierarchy.module';
import { VmsTableModule } from '../library/smartTable/vms-table.module';
import { SharedModule } from '../shared/shared.module';
import { BasicJobInfoComponent } from './component/basic-job-info/basic-job-info.component';
import { JobDetailsComponent } from './component/job-details/job-details.component';
import { JobQualificationsComponent } from './component/job-qualifications/job-qualifications.component';
import { JobsSummaryComponent } from './component/jobs-summary/jobs-summary.component';
import { MarketRateComponent } from './component/market-rate/market-rate.component';
import { SimplifyAssistComponent } from './component/simplify-assist/simplify-assist.component';
import { CreateCandidateComponent } from './create-candidate/create-candidate.component';
import { CreateJobComponent } from './create-job/create-job.component';
import { JobDetailsModule } from './job-details/job-details.module'
import { JobDetailsService } from './job-details/job-details.service';
import { JobsService } from './jobs.service';
import { JobListComponent } from './job-list/job-list.component';
import { JobsRoutingModule } from './jobs-routing.module';
import { JobsComponent } from './jobs.component';
import { JobDistributionComponent } from './component/job-distribution/job-distribution.component';
import { JobEstimateComponent } from './component/job-estimate/job-estimate.component';
import { JobListStaticComponent } from './job-list-static/job-list-static.component';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CreateJobStaticComponent } from './create-job-static/create-job-static.component';
import { JobDetailStaticComponent } from './job-detail-static/job-detail-static.component';
import { JobListStaticV2Component } from './job-list-static-v2/job-list-static-v2.component';
import { CreateJobChartModule } from 'src/app/jobs/create-job-chart/create-job-chart.module';

// @NgModule({
//   declarations: [JobsComponent, CreateJobComponent, SimplifyAssistComponent, MarketRateComponent, BasicJobInfoComponent, JobQualificationsComponent, JobDetailsComponent, JobDistributionComponent, JobsSummaryComponent, CreateCandidateComponent, JobListComponent, JobEstimateComponent],

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  declarations: [JobsComponent, CreateJobComponent, SimplifyAssistComponent, MarketRateComponent, BasicJobInfoComponent, JobQualificationsComponent, JobDetailsComponent, JobDistributionComponent, JobsSummaryComponent, CreateCandidateComponent, JobListComponent, JobListStaticComponent, JobEstimateComponent, CreateJobStaticComponent, JobDetailStaticComponent, JobListStaticV2Component],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    JobsRoutingModule,
    JobDetailsModule,
    SharedModule,
    NgSelectModule,
    VmsTableModule,
    FormsModule,
    ReactiveFormsModule,
    SvmsHierarchyModule,
    QuillModule.forRoot(),
    SvmsHierarchyModule,
    PerfectScrollbarModule,
    CreateJobChartModule
  ],
  providers: [
    JobsService,
    JobDetailsService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]

})
export class JobsModule { }
