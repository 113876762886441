import {
  Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, HostListener, forwardRef, SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { dateRangeToTimeStampConverter } from '../../../shared/util/date.util';

@Component({
  selector: 'svms-datepicker',
  templateUrl: './svms-datepicker.component.html',
  styleUrls: ['./svms-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SvmsDatepickerComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SvmsDatepickerComponent implements OnInit, ControlValueAccessor {

  @Output() onDateChange = new EventEmitter<any>();
  @Output() onendDateChange = new EventEmitter<Date>();
  @Input() options: svmsOptions;
  startDate: Date;
  endDate: Date;
  placeholdervalue;
  @Input() dateRangePicker;
  @Input() inputdateFormat;
  @Input() placeholder;
  @Input('value') _value = '';
  @Input() set id(value: string) {
    this._ID = value;
  }
  get id() {
    return this._ID;
  }
  @Input() alwaysVisible: boolean;
  @Input() hideDisplayValue: boolean = false;
  @Input() disabled = false;
  @Input() clearInputDate = false;
  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.options = new svmsOptions(changes.options.currentValue || {});
      if (this.pickerCalendar) {
        this.pickerCalendar.options = new svmsOptions(changes.options.currentValue || {});
        this.pickerCalendar.updateCalendar();
      }
      if (this.pickerCalendarTwo) {
        this.pickerCalendarTwo.options = new svmsOptions(changes.options.currentValue || {});
        this.pickerCalendarTwo.updateCalendar();
      }
    }
  }

  pickCalyears: number[] = [];
  pickerLanguage: svmsLanguage;
  pickerCalendar: svmsCalender;
  pickerCalendarTwo: svmsCalender;
  mode = 'datepicker';
  showPicker = false;
  dateData;
  airDateSim: Date;
  airEndDateSim: Date;
  private _ID = '';
  dateRangeOptions: DateRangeCode[] = [
    DateRangeCode.TODAY,
    DateRangeCode.YESTERDAY,
    DateRangeCode.WEEK,
    DateRangeCode.LAST_30_DAYS,
    DateRangeCode.CURRENT_MONTH,
    DateRangeCode.LAST_MONTH,
    DateRangeCode.CUSTOM_RANGE
  ];

  @HostListener('click', ['$event'])
  datePopup(event: any) {
    if (event.target.id === 'datepicker' || event.target.className === 'datepicker-- form-control pl-32 text-truncate') {
      if (this.disabled) {
        return;
      }
      if (!this.options.alwaysVisible) {
        this.showPicker = !this.showPicker;
      } else {
        this.showPicker = true;
      }

    }
  }

  @HostListener('document:click', ['$event'])
  datePopup1(event: any) {
    const tclassName = event.target.className;
    if (event.target.tagName.toLowerCase() !== 'svg' && event.target.tagName.toLowerCase() !== 'path' && tclassName && tclassName.search('datepicker--') < 0) {
      if (!this.options.alwaysVisible) {
        this.showPicker = false;
      }
    }
  }

  constructor() { }

  ngOnInit() {
    this.options = new svmsOptions(this.options || ({} as svmsOptions));
    this.showPicker = this.options.alwaysVisible || false;
    this.pickerLanguage = SVMS_LANGUAGES.get(this.options.language);
    this.pickerCalendar = new svmsCalender(this.startDate, this.options);
    this.pickerCalendarTwo = new svmsCalender(new Date(this.pickerCalendar.year, this.pickerCalendar.month + 1, this.pickerCalendar.date),
      this.options);
    const firstYear = this.pickerCalendar.year - 6;
    this.pickCalyears = Array.from({ length: 12 }, (v, k) => firstYear + k);
  }

  onChange: any = () => {
    if (this.value) {
      this.pickerCalendar = new svmsCalender(new Date(this.value), this.options);
      this.startDate = new Date(this.value);
      this.pickerCalendar.updateCalendar();
    }

  };
  onTouched: any = () => { };

  closeDatePicker() {
    if (!this.options.alwaysVisible) {
      this.showPicker = false;
    }
  }

  get value() {
    return this._value;
  }

  set value(val) {
    if (this.options.range && val) {
      val = this.initDateRange(val);
    }
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  initDateRange(val) {
    const rawDate = dateRangeToTimeStampConverter(val);
    let startDate = rawDate[0];
    let endDate = rawDate[1];
    if (startDate && endDate) {
      this.startDate = new Date(startDate);
      this.endDate = new Date(endDate);
    }
    return `${this.dateFormat(this.startDate)}-${this.dateFormat(this.endDate)}`
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

  nextMonth() {
    console.log(this.pickerCalendar.month + 1);
    this.pickerCalendar.setMonth(this.pickerCalendar.month + 1);
    this.pickerCalendarTwo.setMonth(this.pickerCalendarTwo.month + 1);
  }

  previousMonth() {
    this.pickerCalendar.setMonth(this.pickerCalendar.month - 1);
    this.pickerCalendarTwo.setMonth(this.pickerCalendarTwo.month - 1);
  }

  clearInput() {
    this.writeValue('');
    this.value = null;
  }

  next() {
    for (let i = 0; i < this.pickCalyears.length; i++) {
      this.pickCalyears[i] += 10;
    }
  }

  previous() {
    for (let i = 0; i < this.pickCalyears.length; i++) {
      this.pickCalyears[i] -= 10;
    }
  }

  resetDate() {
    this.writeValue('');
  }

  setDate(index: number, calender: svmsCalender) {
    
    if (calender.airDays[index]) {
      if (calender.airDays[index].disabled) {
        return;
      }
      calender.selectDate(index);
    }
    const date = new Date(
      calender.year,
      calender.month,
      calender.date,
      0,
      0
    );

    if (svmsOptions.sameDate(date, this.startDate)) {
      if (this.options.range && !this.endDate) {
        this.endDate = date;
      } else if (this.options.range && this.endDate) {
        this.startDate = date;
        this.endDate = null;
      } else {
        this.startDate = this.options.range && this.endDate ? this.endDate : null;
        this.endDate = null;
      }
    } else if (svmsOptions.sameDate(date, this.endDate)) {
      this.endDate = null;
    } else if (!this.options.isDisabled(date)) {
      if (!this.options.range || (!this.startDate && !this.endDate)) {
        this.startDate = date;
      } else {
        if (this.startDate) {
          if (date < this.startDate) {
            this.endDate = this.startDate;
            this.startDate = date;
          } else {
            if (this.endDate) {
              this.endDate = null;
              this.startDate = date;
            } else {
              this.endDate = date;
            }
          }
        } /* endDate is truthy */ else {
          if (this.endDate < date) {
            this.startDate = this.endDate;
            this.endDate = date;
          } else {
            this.startDate = date;
            this.endDate = null;
          }
        }
      }
      if (this.options.range) {
        if (this.startDate && this.endDate) {
          // this.showPicker = false;
          this.writeValue(this.dateFormat(this.startDate) + '-' + this.dateFormat(this.endDate));
        }
      } else {
        this.hidePicker();
        this.writeValue(this.dateFormat(this.startDate));
      }
    }

    if (this.options.range) {
      this.onendDateChange.emit(this.endDate);
    }
  }

  hidePicker() {
    // if(this.options.alwaysVisible) {

    // }
    this.showPicker = this.options.alwaysVisible ? true : false;
  }

  dateFormat(date, range?) {
    if (!date) { return; }
    const shortMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (month < 10) { month = '0' + month; }
    if (day < 10) { day = '0' + day; }
    if (this.inputdateFormat === '' || this.inputdateFormat === undefined) {
      return shortMonth[date.getMonth()] + ' ' + day + ', ' + year;
    }
    else if (this.inputdateFormat === 'dd-mm-yy') { return day + '-' + month + '-' + year; }
    else if (this.inputdateFormat === 'yy-mm-dd') { return year + '-' + month + '-' + day; }
    else if (this.inputdateFormat === 'mm-dd-yy') { return month + '-' + day + '-' + year; }
    else if (this.inputdateFormat === 'mm/dd/yy') { return month + '/' + day + '/' + year; }

    else { return date; }
  }
  isInRange(day: AirDay) {
    if (this.startDate && this.endDate) {
      return (
        this.startDate < new Date(day.year, day.month, day.date) &&
        new Date(day.year, day.month, day.date, 23, 59, 59) <
        this.endDate
      );
    }

    if (this.airDateSim && this.airEndDateSim) {
      return (
        this.airDateSim < new Date(day.year, day.month, day.date) &&
        new Date(day.year, day.month, day.date, 23, 59, 59) <
        this.airEndDateSim
      );
    }

    return false;
  }

  isCalendarDate(date: Date, day: AirDay) {
    return date
      ? date.getFullYear() === day.year &&
      date.getMonth() === day.month &&
      date.getDate() === day.date
      : false;
  }

  simulate(day: AirDay) {
    const date = new Date(Date.UTC(day.year, day.month, day.date, 0, 0));
    this.airDateSim = this.startDate;
    this.airEndDateSim = this.endDate;

    if (
      !this.options.isDisabled(date) &&
      ((this.startDate && !this.endDate) || (this.endDate && !this.startDate))
    ) {
      if (this.startDate) {
        if (date < this.startDate) {
          this.airEndDateSim = this.startDate;
          this.airDateSim = date;
        } else {
          if (this.endDate) {
            this.airEndDateSim = null;
            this.airDateSim = date;
          } else {
            this.airEndDateSim = date;
          }
        }
      } /* endDate is truthy */ else {
        if (this.endDate < date) {
          this.airDateSim = this.endDate;
          this.airEndDateSim = date;
        } else {
          this.airDateSim = date;
          this.airEndDateSim = null;
        }
      }
    }
  }

  resetSim() {
    this.airDateSim = null;
    this.airEndDateSim = null;
  }

  changeDateRange(dateRangeOption: DateRangeCode) {
    let startDate = new Date();
    let endDate = new Date();
    switch (dateRangeOption) {
      case DateRangeCode.TODAY:
        break;
      case DateRangeCode.YESTERDAY:
        startDate.setDate(startDate.getDate() - 1);
        endDate.setDate(endDate.getDate() - 1);
        break;
      case DateRangeCode.WEEK:
        startDate.setDate(startDate.getDate() - 7);
        break;
      case DateRangeCode.LAST_30_DAYS:
        startDate.setDate(startDate.getDate() - 30);
        break;
      case DateRangeCode.CURRENT_MONTH:
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
        break;
      case DateRangeCode.LAST_MONTH:
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        startDate = new Date(y, m - 1, 1);
        endDate = new Date(y, m, 0);
        break;
      default:
        break;
    }

    this.setStartMonth(startDate);
    this.startDate = startDate;
    this.endDate = endDate;
    this.writeValue(this.dateFormat(this.startDate) + '-' + this.dateFormat(this.endDate));
  }

  setStartMonth(date: Date = new Date()) {
    this.pickerCalendar = new svmsCalender(date, this.options);
    const date2 = new Date(this.pickerCalendar.year, this.pickerCalendar.month + 1, this.pickerCalendar.date);
    this.pickerCalendarTwo = new svmsCalender(date2, this.options);
  }

}

export enum DateRangeCode {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  WEEK = 'Last 7 Days',
  LAST_30_DAYS = 'Last 30 Days',
  CURRENT_MONTH = 'This Month',
  LAST_MONTH = 'Last Month',
  CUSTOM_RANGE = 'Custom Range'
}

export class svmsCalender {
  daysInMonth: Array<number> = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  options: svmsOptions;
  airDays: Array<AirDay>;
  currentMonth: number;
  currentYear: number;
  year: number;
  month: number;
  date: number;
  hour: number;
  minute: number;

  constructor(
    date: Date = new Date(),
    options: svmsOptions = new svmsOptions()
  ) {
    const currentDate = date;
    this.currentMonth = currentDate.getMonth();
    this.currentYear = currentDate.getFullYear();
    this.options = options;
    this.year = date.getFullYear();
    this.month = date.getMonth();
    this.date = date.getDate();
    this.hour = date.getHours();
    this.minute = date.getMinutes();
    this.updateCalendar();
  }

  updateCalendar() {
    this.airDays = [];
    const daysInMonth = this.getDaysInMonth(this.month);

    const date = new Date();
    const firstDayOfMonth =
      (new Date(this.year, this.month, 1).getDay() || 7) - 1; // making 0 == monday
    const weekend = new AirWeekend();

    if (firstDayOfMonth >= 0 /* is not monday (0) */) {
      const daysInLastMonth = this.getDaysInMonth(this.month - 1);

      const prevAirMonth = new AirMonth(this.month - 1, this.year);
      // UI changes
      // add starting date's form previous month to start from SU
      for (
        let dateNo = daysInLastMonth - firstDayOfMonth;
        dateNo <= daysInLastMonth;
        dateNo++
      ) {

        this.airDays.push(
          new AirDay(
            dateNo,
            prevAirMonth.month,
            prevAirMonth.year,
            weekend.progress(),
            this.options.isDisabled(
              new Date(prevAirMonth.year, prevAirMonth.month, dateNo)
            ),
            true
          )
        );
      }
    }

    for (let dateNo = 1; dateNo <= daysInMonth; dateNo++) {

      this.airDays.push(
        new AirDay(
          dateNo,
          this.month,
          this.year,
          weekend.progress(),
          this.options.isDisabled(new Date(this.year, this.month, dateNo))
        )
      );
    }

    if (this.date > daysInMonth) {
      this.date = daysInMonth; // select the maximum available this month instead
    }

    // set the current date if it's the current month & year
    if (date.getMonth() === this.month && date.getFullYear() === this.year) {
      this.airDays[firstDayOfMonth + date.getDate()].current = true;
    }

    const daysSoFar = firstDayOfMonth + daysInMonth - 1;
    const nextAirMonth = new AirMonth(this.month + 1, this.year);
    // adding date's from next month
    for (
      let dateNo = 1;
      dateNo <= (daysSoFar > 35 ? 42 : 35) - daysSoFar;
      dateNo++
    ) {
      this.airDays.push(
        new AirDay(
          dateNo,
          nextAirMonth.month,
          nextAirMonth.year,
          weekend.progress(),
          this.options.isDisabled(
            new Date(nextAirMonth.year, nextAirMonth.month, dateNo)
          ),
          true
        )
      );
    }
  }

  selectDate(index: number) {
    this.date = this.airDays[index].date;

    // might be a day from the previous/next month
    if (index < 7 && this.date > 20) {
      this.setMonth(this.month - 1);
    } else if (index > 20 && this.date < 8) {
      this.setMonth(this.month + 1);
    }
  }

  setMonth(month: number) {
    console.log(month);
    const airMonth: AirMonth = new AirMonth(month, this.year);
    this.month = airMonth.month;
    this.year = airMonth.year;
    console.log(airMonth);

    this.updateCalendar();
  }

  setYear(year: number) {
    this.year = year;
  }

  getDaysInMonth(month: number) {
    const airMonth: AirMonth = new AirMonth(month, this.year);
    if (
      (airMonth.month === 1 &&
        airMonth.year % 4 === 0 &&
        airMonth.year % 100 !== 0) ||
      airMonth.year % 400 === 0
    ) {
      return 29;
    }

    return this.daysInMonth[airMonth.month];
  }
}

// normalizes month/year
export class AirMonth {
  month: number;
  year: number;

  constructor(month, year) {
    if (month > 11) {
      year++;
      month = 0;
    } else if (month < 0) {
      year--;
      month = 11;
    }

    this.month = month;
    this.year = year;
  }
}

export class AirDay {
  date: number;
  month: number;
  year: number;
  weekend: boolean;
  other: boolean;
  current: boolean;
  disabled: boolean;

  constructor(
    date: number,
    month: number,
    year: number,
    weekend = false,
    disabled = false,
    other = false,
    current = false
  ) {
    this.date = date;
    this.month = month;
    this.year = year;
    this.weekend = weekend;
    this.disabled = disabled;
    this.other = other;
    this.current = current;
  }
}

export class AirWeekend {
  day: number;

  constructor(day: number = 0) {
    this.day = day;
  }

  progress(): boolean {
    let weekend = false;

    if (this.day === 5 /* Saturday */) {
      weekend = true;
      ++this.day;
    } else if (this.day === 6 /* Sunday */) {
      weekend = true;
      this.day = 0; // it's a new week!
    } else {
      ++this.day;
    }

    return weekend;
  }
}

export class svmsOptions {
  timepicker?: boolean;
  format12h?: boolean;
  fullDays?: boolean;
  language?: string;
  hourStep?: number;
  minuteStep?: number;
  range?: boolean;
  enabledDateRanges?: DateRange[];
  alwaysVisible?: boolean;

  constructor(_options: svmsOptions = {} as svmsOptions) {
    this.timepicker = !!_options.timepicker;
    this.format12h = !!_options.format12h;
    this.fullDays = !!_options.fullDays;
    this.language = _options.language || 'English';
    this.hourStep = _options.hourStep || 1;
    this.minuteStep = _options.minuteStep || 1;
    this.range = !!_options.range;
    this.enabledDateRanges = _options.enabledDateRanges || [];
    this.alwaysVisible = _options.alwaysVisible || false;
  }

  static sameDate(date1: Date, date2: Date) {
    return (
      date1 &&
      date2 &&
      date1.getUTCFullYear() === date2.getUTCFullYear() &&
      date1.getUTCMonth() === date2.getUTCMonth() &&
      date1.getUTCDate() === date2.getUTCDate()
    );
  }

  isDisabled(date: Date) {
    for (const dateRange of this.enabledDateRanges) {
      if (dateRange.start && dateRange.end) {
        if (date >= dateRange.start && date <= dateRange.end) {
          return false;
        }
      } else if (dateRange.start && !dateRange.end) {
        if (date >= dateRange.start) {
          return false;
        }
      } else if (!dateRange.start && dateRange.end) {
        if (date <= dateRange.end) {
          return false;
        }
      }

    }

    return !!this.enabledDateRanges.length;
  }
}

export interface DateRange {
  start: Date;
  end: Date;
}

export class svmsLanguage {
  days: Array<string>;
  daysMin: Array<string>;
  months: Array<string>;

  constructor(
    days: Array<string>,
    daysMin: Array<string>,
    months: Array<string>
  ) {
    this.days = days;
    this.daysMin = daysMin;
    this.months = months;
  }
}

export const SVMS_LANGUAGES: Map<string, svmsLanguage> = new Map([
  [
    'English',
    new svmsLanguage(
      ['Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        ,
      ],
      ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
    ),
  ] as [string, svmsLanguage],

  [
    'Español - ES - Traducción',
    new svmsLanguage(
      [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',

      ],
      ['Lu', 'Dp', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Augosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ]
    ),
  ] as [string, svmsLanguage],

  [
    'fi',
    new svmsLanguage(
      [
        'Sunnuntai',
        'Maanantai',
        'Tiistai',
        'Keskiviikko',
        'Torstai',
        'Perjantai',
        'Lauantai',

      ],
      ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
      [
        'Tammikuu',
        'Helmikuu',
        'Maaliskuu',
        'Huhtikuu',
        'Toukokuu',
        'Kesäkuu',
        'Heinäkuu',
        'Elokuu',
        'Syyskuu',
        'Lokakuu',
        'Marraskuu',
        'Joulukuu',
      ]
    ),
  ] as [string, svmsLanguage],

  [
    'fr',
    new svmsLanguage(
      ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
      ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
      [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Decembre',
      ]
    ),
  ] as [string, svmsLanguage],

  [
    'hu',
    new svmsLanguage(
      ['Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat', 'Vasárnap'],
      ['H', 'K', 'Sz', 'Cs', 'P', 'Sz', 'V'],
      [
        'Január',
        'Február',
        'Március',
        'Április',
        'Május',
        'Június',
        'Július',
        'Augusztus',
        'Szeptember',
        'Október',
        'November',
        'December',
      ]
    ),
  ] as [string, svmsLanguage],
]);
