<div class="filter-panel position-fixed bg-light">
  <h4 class="pt-16 pl-24 pr-24 font-weight-normal">
    <icon size="19px" name="filter_alt" type="material" cuClass="d-flex" class="float-left mr-4"></icon> Filters
  </h4>
  <div class="filter-content p-24">
    <div class="form-group position-relative">
      <label>Available Date</label>
      <svms-datepicker [inputdateFormat]="" [placeholder]="'choose Date'" id="date1" I18n #refdate1></svms-datepicker>
    </div>
    <div class="form-group-row">
      <div class="form-group position-relative mr-0">
        <label>Estimated Pay Rate</label>
        <input type="number" placeholder="Min" class="form-control">
      </div>
      <div class="field-between">
        to
      </div>
      <div class="form-group position-relative">
        <label>&nbsp;</label>
        <input type="number" placeholder="Max" class="form-control">
      </div>
    </div>
    <div class="form-group-row">
      <div class="form-group position-relative mr-0">
        <label>Estimated Hours</label>
        <input type="number" placeholder="Min" class="form-control">
      </div>
      <div class="field-between">
        to
      </div>
      <div class="form-group position-relative">
        <label>&nbsp;</label>
        <input type="number" placeholder="Max" class="form-control">
      </div>
    </div>
    <div class="form-group filter-slider">
        <label>Score : <span class="range-value ml-8">{{value}} - {{highValue}}%</span></label>
        <ng5-slider [(value)]="value" [(highValue)]="highValue" [options]="options"></ng5-slider>
    </div>
    <div class="filter-footer pt-16 pb-16 pl-24 pr-24">
      <button class="btn btn-outine float-left" (click)="clearFilter()">
        <icon size="20px" name="highlight_off" type="material" cuClass="d-flex" class="icon--on-left d-flex"></icon>
        Clear
        all Filter
      </button>
      <button class="btn btn-primary float-right" (click)="submitFilter()">
        <icon size="20px" name="check_circle" type="material" cuClass="d-flex" class="icon--on-left d-flex"></icon>
        Apply
      </button>
    </div>
  </div>
