<div class="content-area ui-content-wrapper no-content bg-light">
  <div class="page-title px-24 pt-24">Programs</div>
  <div class="first-program">
    <img src="./assets/images/no-programs.png" alt="organization-board" width="250" class="img-fluid">
    <h2>You don’t have any Programs yet.</h2>
    <h6 class="py-4">Your programs will show up here Create a program to get started.</h6>
    <a class="btn btn-secondary mt-4" href="/programs/create">
      <icon size="20px" name="add" type="material" class="icon--on-left" cuClass="cu-icon"></icon> Create Your First
      Program
    </a>
  </div>
</div>
