import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VmsTabComponent } from './vms-tab/vms-tab.component';
import { VmsTabGroupComponent } from './vms-tab-group/vms-tab-group.component';
import { IconComponent } from './icon/icon.component';

@NgModule({
  declarations: [
    VmsTabComponent,
    VmsTabGroupComponent,
IconComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
   VmsTabComponent,
    VmsTabGroupComponent
  ],
})
export class TabsModule { }
