<svms-sidebar size="large" [title]="sidebarTitle" [dataVisibility]="currentAssignments" (onClose)="sidebarClose()">
    <svms-sidebar-body>
        <div class="p-24 pt-32">
            <figure class="avatar float-left rounded-circle overflow-hidden mr-12">
                <img src="/assets/images/5.jpg" alt="">
            </figure>
            <div class="user-description float-left">
                <h4 class="mb-0">John Smith(#345)</h4>
                <p>United States of America</p>
            </div>
            <ul class="tab-list float-right">
                <li class="d-inline-block mr-16">
                    <a class="d-inline-block" [ngClass]="{'active': submittedJob}" href="javascript:void(0)" (click)="submittedJobsList()">
                        Submitted Job <span>{{totalRecords}}</span>
                    </a>
                </li>
                <li class="d-inline-block">
                    <a class="d-inline-block" [ngClass]="{'active': currentAssignmet}" href="javascript:void(0)" (click)="currentAssignmentList()">
                        Current Assignment <span>{{totalRecords}}</span>
                    </a>
                </li>
            </ul>
            <div class="clearfix"></div>
        </div>
        <div class="tabular-list">
            <vms-table *ngIf="tableLoaded" [vmsTableConfig]="tableConfig" [vmsDataSource]="vmsData?.candidate"  [loading]="dataLoading" [totalItem]="totalRecords"
            [itemsPerPage]="itemsPerPage">
          </vms-table>
        </div>
    </svms-sidebar-body>
</svms-sidebar>
  