import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName, FormArray } from '@angular/forms';
import { EventStreamService, Events, EmitEvent } from 'src/app/core/services/event-stream.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { CandidateService } from '../../service/candidate.service';
import { errorHandler } from '../../../shared/util/error-handler';
import { SvmsDatepickerComponent } from 'src/app/shared/components/svms-datepicker/svms-datepicker.component';

@Component({
  selector: 'app-add-education',
  templateUrl: './add-education.component.html',
  styleUrls: ['./add-education.component.scss']
})
export class AddEducationComponent implements OnInit {
  @Output() educationData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('startDate') startDate: SvmsDatepickerComponent;
  @ViewChild('endDate') endDate: SvmsDatepickerComponent;
  @Input() educationTypeId;
  closePanel: EventEmitter<boolean> = new EventEmitter();
  addEducation = "hidden";
  educationList =[];
  public addEducationForm: FormGroup;
  panelHeading = 'Add New Education';
  editPanel = false;
  clickedIndex ;
  constructor(
    private fb: FormBuilder,
    private eventStream: EventStreamService,
    private alertService : AlertService,
    private candidateService : CandidateService
  ) {

  }

  ngOnInit(): void {
    this.eventStream.on(Events.CREATE_CANDIDATE_EDUCATION, (data) => {
      if (data.value) {
        this.addEducation = 'visible';
        if(data.obj != null){
          this.updatePanel(data.obj);
          this.panelHeading = 'Edit Education';
          this.editPanel = true;
          this.clickedIndex = data.obj.clickedIndex;
        }
      } else {
        this.addEducation = 'hidden';
      }
    });
    this.getEducationList();
    this.addEducationForm = this.fb.group({
      educationType: [null, Validators.required],
      institution: [null, Validators.required],
      degree: [null, Validators.required],
      major: [null, Validators.required],
      minor: [null, ''],
      from: [null, Validators.required],
      to: [null, Validators.required],
      gpa: [null, Validators.required],
      notes: [null, ''],
      files:[null, '']
    });
  }
  updatePanel(data){
    this.addEducationForm.patchValue({
      educationType: data.qualification_id,
      institution: data.institution,
      degree: data.degree,
      major: data.major,
      minor: data.minor,
      from: data.start_date,
      to: data.end_date,
      gpa: data.score,
      notes: data.notes,
      files:data.files
    })
  }
  uploadFiles(event){
    this.addEducationForm.patchValue({
      files:event
    })
  }
  sidebarClose() {
    this.addEducationForm.reset();
    this.addEducation = 'hidden';
    this.panelHeading = 'Add New Education';
    this.editPanel = false;
    this.closePanel.emit(true);
  }
  getEducationList(){
    const educationId = this.educationTypeId;
    this.candidateService._getQualiFicationType(educationId) .subscribe((res) => {
      this.educationList = res.qualifications;
    }, (err) => {
      this.alertService.error(errorHandler(err));
    })
  }
  saveEducation(action){
    let data = this.addEducationForm.value;
    const educationType = this.educationList.filter((item)=>{
      if(item.id == data.educationType){
        return item.name;
      }
    }).map((obj)=>{
        return obj.name;
    })
    if( this.addEducationForm.valid){
      const payload = {
        qualification_id: data.educationType,
        institution: data.institution,
        degree: data.degree ,
        major: data.major,
        minor: data.minor,
        start_date: data.from,
        end_date: data.to,
        score: data.gpa,
        notes: data.notes,
        files: data.files,
        education_name:educationType
      }
      this.educationData.emit({payload: payload, isEdit:this.editPanel, clickedIndex: this.clickedIndex});
      this.addEducationForm.reset();
      this.startDate.value = null;
      this.endDate.value = null;
      if(action == 'save'){
        this.sidebarClose();
      }else{
        this.editPanel = false;
        this.panelHeading = 'Add New Education';
        this.closePanel.emit(true);
      }
    }else{
      this.alertService.error('Please fill the required fields properly.');
    }
    
  }
}
