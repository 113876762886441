import { Component, OnInit, ViewChildren } from '@angular/core';
import {
  FormControl, FormGroup,

  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EmitEvent,
  Events, EventStreamService
} from 'src/app/core/services/event-stream.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../../app/core/services/storage.service';
import { AlertService } from '../../shared/components/alert/alert.service';
import { UserDataObj } from '../../shared/enums';
import { AccountService } from '../account-setup/account.service';
import { AuthLoginService } from '../auth-login.service';
import { UserService } from './../../core/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  ssoUrl = environment.SSO_URL;
  isLoggingViaSSO = false;
  loginForm: FormGroup;
  resetPasswordForm: FormGroup;
  emailvalidation = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  condCheckerObj = {
    username: '',
    userid: '',
    resetsuccesslink: '',
    invalidUserCredentials: '',
    invalidResponse: '',
    user: '',
    formtype: 'defaultpage',
  };
  password = '';
  userData;
  forgetemail = '';
  name: string;
  public userNotFound: any;
  public wrongCredentials: number;
  public reEnterPassword: string;
  public OTPForm: any;
  public passwordstrengthForm: any;
  public invalidEmail: any;
  public expireOTP: any;
  public invalidOTP = 0;
  public invalidOtp_cnt = 3;
  private user: any;
  public isStrong: any ;
  public errorMessage= ''
  private currentProgram: any;
  org_id: any;
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  navigateTo: any;
  isSaveLoader: boolean = false;
  @ViewChildren('formRow') rows: any;
  forMfa: boolean = false;
  userId: any;
  showMFAResendButton:boolean= true;
  counter:string=undefined;
  constructor(
    public eventStream: EventStreamService,
    public router: Router,
    public storageservice: StorageService,
    private _http: AuthLoginService,
    public accountService: AccountService,
    private _alert: AlertService,
    public userService: UserService,
    public route: ActivatedRoute,
    public loader: LoaderService,
    private themeService: ThemeService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl(' '),
      password: new FormControl(''),
    });
    this.resetPasswordForm = new FormGroup({
      password: new FormControl(''),
      confirmpassword: new FormControl(''),
    });
    this.userData = UserDataObj;
  }

  ngOnInit(): void {
    this.invalidEmail = ''
    this.route.queryParamMap.subscribe(query => {
      let ssoCode = query.get('code');
      this.loginViaSSO(ssoCode);

      this.navigateTo = query.get('navigate');
    })
    this.wrongCredentials = this.storageservice.getWithExpiry(this.userData[4]);
    if (this.wrongCredentials == 0) {
      this.loginForm.controls['email'].disable();
      this.loginForm.controls['password'].disable();
    }
    this.OTPForm = this.otpFormGroup(this.formInput);
    this.getUserName();
    //checking the user login before or not
    setTimeout(() => {
      this.eventStream.emit(new EmitEvent(Events.LoggedIn, false));
      this.themeService.changeTheme(undefined);
    }, 300);
    this.resetPasswordForm.get('password').valueChanges.subscribe((data) => {
      this.password = data;
    });

  }

  startTimer(duration:number) {
    let timer:any= duration; 
    let minutes;
    let seconds;
    const interval= setInterval(() =>{
        minutes = parseInt(""+(timer / 60), 10);
        seconds = parseInt(""+(timer % 60), 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.counter= minutes + ":" + seconds;

        if (--timer < 0) {
          clearInterval(interval);
          this.counter= undefined;
          this.showMFAResendButton= true;
          return;
            // timer = duration;
        }
    }, 1000);
  }

  sendMFAResetPasswordEmail(){
    this.counter= undefined;
    this.isSaveLoader = true;
    this.showMFAResendButton= false;
    this.OTPForm?.enable();
    this.invalidOTP = 0;
    this.invalidOtp_cnt = 3;
    this.passwordstrengthForm = "";
    this.expireOTP = "";
    let email = { email: this.forgetemail };
    this._http
      .post('/profile-manager/authentication/token-resend', email)
      .subscribe(
        (data) => {
          this._alert.success("We have sent an Security code to your registered email address.!");
          this.isSaveLoader = false;
          this.startTimer(90);
        },
        (err) => {
          this.showMFAResendButton= true;
          this.isSaveLoader = false;
          this._alert.error(err?.error?.error?.message);
        }
      );
  }
  
  loginViaSSO(code) {
    if (code) {
      this.isLoggingViaSSO = true;
      this.loader.show();
      let req = {
        "sso_token": code,
        "redirect_uri": environment.SSO_REDIRECT_URL
      }
      this.accountService.loginViaSSO(req).subscribe(data => {
        this.postLogin(data);
      }, err => {
        this._alert.error('Could not login via SSO');
        console.log(err);
        this.loader.hide();
      });
    } else {
      this.isLoggingViaSSO = false;
    }
  }

  getMyPrograms() {
    this.userService.getAllPrograms().subscribe(data => {
      this.loader.hide();
      this.storageservice.set('CurrentProgram', data?.programs[0], true);
      this.currentProgram = data?.programs[0];
      this.getMembershipDetailsAndRole();

    }, err => {
      this.loader.hide();
      console.log(err);
    })
  }

  getMembershipDetailsAndRole() {
    this.userService.getMembershipDetails(this.currentProgram?.id, this.user.id).subscribe(res => {
      this.storageservice.set('account', res?.member, true);
      let temptheme = this.storageservice.get('Usertheme');
      this.themeService.changeTheme(temptheme || this.user?.theme_code);
      let permissionList = res?.member?.role?.permissions;
      let permissions = [];
      permissionList?.forEach(element => {
        permissions.push(element?.slug);
      });
      this.storageservice.set('user_permission', permissions, true);
      if (this.navigateTo) {
        this.router.navigateByUrl(decodeURIComponent(this.navigateTo));
      } else {
        this.router.navigateByUrl('/dashboard');
      }
    }, err => {
      console.log('Not found the membership details, restricted access');
      console.log(err);
      if (this.navigateTo) {
        this.router.navigateByUrl(decodeURIComponent(this.navigateTo));
      } else {
        this.router.navigateByUrl('/dashboard');
      }
    });
  }

  keyUpEvent(event, index, controlname) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index;
    } else {
      pos = index + 1;
    }
    let charcnt = this.OTPForm.get(controlname).value;
    if (pos > -1 && pos < this.formInput.length) {
      let currentpos = pos;
      if (parseInt(charcnt) >= 0 && parseInt(charcnt) <= 9) {
        pos = currentpos;
      }
      else {
        this.OTPForm.get(controlname).setValue('');
        pos = currentpos - 1;
      }
      this.rows._results[pos]?.nativeElement.focus();
    }
    else {
      if (parseInt(charcnt) >= 0 && parseInt(charcnt) <= 9) {
      }
      else {
        this.OTPForm.get(controlname).setValue('');
      }
    }
  }

  securityCode() {
    this.OTPForm?.reset();
    this.condCheckerObj['formtype'] = 'securityCodeVerification'
  }

  validateOTP() {
    let otp =
      this.OTPForm.value.input1.toString() +
      this.OTPForm.value.input2.toString() +
      this.OTPForm.value.input3.toString() +
      this.OTPForm.value.input4.toString() +
      this.OTPForm.value.input5.toString() +
      this.OTPForm.value.input6.toString();
    
      if(this.forMfa) {
          this.validateMFA(otp);
      } else {
          this._http
          .get(`/public/users/password-request/${otp}`)
          .subscribe(
            (data) => {
              this.passwordstrengthForm = '';
              this.condCheckerObj['formtype'] = 'resetPasswordComp';
            },
            (err) => {
              this.passwordstrengthForm = err?.error?.error?.message;
              this.invalidOTP += 1;
              this.invalidOtp_cnt -= 1;
              if (this.invalidOTP == 3) {
                this.OTPForm.disable()
              }
              else if (err?.status == 406) {
                this.expireOTP = err?.status;
              }
              else if (err?.status == 404) {
                this.expireOTP = "";
              }
              else {
                this.expireOTP = "";
              }
            }
          );
      }
      
    
      
  }

  reLogin() {
    let email = this.storageservice.get(this.userData[1]);
    email = email.email;
    if (email.email != '' && this.reEnterPassword != '') {
      let payLoad = { username: email, password: this.reEnterPassword };
      this.login(payLoad, true);
    }
  }

  getUserName() {
    let userObj = this.storageservice.get(this.userData[1]);
    if (userObj && userObj.email) {
      this.condCheckerObj['formtype'] = 'getUserName';
      this.condCheckerObj['username'] = userObj.email;
      let userid = userObj.email.split('@');
      this.condCheckerObj['userid'] = userid[0];
    }
    if (userObj && this.loginForm.value.email == userObj.email) {
      this.condCheckerObj['formtype'] = 'getUserName';
      let userid = userObj.email.split('@');
      this.condCheckerObj['userid'] = userid[0];
      this.condCheckerObj['invalidUserCredentials'] = '';
      this.condCheckerObj['invalidResponse'] = '';
    }
  }
  getBack() {
    this.condCheckerObj['formtype'] = 'defaultpage';
    this.condCheckerObj['resetPassword'] = '';
    this.condCheckerObj['invalidUserCredentials'] = '';
    this.condCheckerObj['invalidResponse'] = '';
    this.userNotFound = '';
  }
  sendResetPasswordMail() {
    this.isSaveLoader = true;
    this.OTPForm?.enable();
    this.invalidOTP = 0;
    this.invalidOtp_cnt = 3;
    this.passwordstrengthForm = "";
    this.expireOTP = "";
    let email = { email: this.forgetemail };
    this._http
      .post('/public/users/password-reset', email)
      .subscribe(
        (data) => {
          this.condCheckerObj['formtype'] = 'defaultsecurityCode';
          this.condCheckerObj['resetsuccesslink'] = 'Yes';
          this.condCheckerObj['formtype'] = 'defaultsecurityCode';
          this.isSaveLoader = false;
        },
        (err) => {
          this.isSaveLoader = false;
          this.userNotFound = err.error.error.message;
        }
      );
  }
  sendForgetUsernameMail() {
    this.OTPForm?.enable();
    this.invalidOTP = 0;
    this.invalidOtp_cnt = 3;
    this.passwordstrengthForm = "";
    this.expireOTP = "";
    let email = { email: this.forgetemail };
    this._http
      .post('/public/users/username', email)
      .subscribe(
        (data) => {
          this.condCheckerObj['formtype'] = 'EmailVerification';
          this.condCheckerObj['resetsuccesslink'] = 'Yes';
        },
        (err) => {
          if(err?.status === 404){
            this.userNotFound = 'Sorry, we couldn’t find an account with that email address.';
          } else {
          this.userNotFound = err?.error?.error?.message;
          }
        }
      );
  }
  validateEmail(e) {
    this.userNotFound = '';
  }

  otpFormGroup(elements) {
    const group: any = {};
    elements.forEach((key) => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  changePassword() {
    this.condCheckerObj['resetPassword'] == 'yes';
  }
  getPasswordStrength(event) {
    if(event) {
       this.isStrong = event;
       this.errorMessage= ''
    }
   }

  resetPassword() {
    this.invalidEmail = '';
    let password = this.resetPasswordForm.get('password').value;
    let confirmpwd = this.resetPasswordForm.get('confirmpassword').value;
    const validpassword = this.validatePassword();
    if(!validpassword) {
      return ;
    }
    let otp =
      this.OTPForm.value.input1.toString() +
      this.OTPForm.value.input2.toString() +
      this.OTPForm.value.input3.toString() +
      this.OTPForm.value.input4.toString() +
      this.OTPForm.value.input5.toString() +
      this.OTPForm.value.input6.toString();
    let payLaod = {
      otp: otp,
      password: password,
    };
    this._http
      .put('/public/users/password-reset', payLaod)
      .subscribe(
        (data) => {
          this.invalidEmail = '';
          this._alert.success('Password Reset Succesfully..', {
            color: 'white',
            bgColor: 'lightgreen',
          });
          this.errorMessage = ''
          this.isStrong = ''
          this.OTPForm.reset();
          this.resetPasswordForm.reset();
          this.forgetemail = '';
          this.condCheckerObj['formtype'] = 'defaultpage';
        },
        (err) => {
          this.invalidEmail = err.error.error.message;
        }
      );
  }
  validatePassword() {
    this.errorMessage = '';
    let password = this.resetPasswordForm.get('password').value;
    let confirmpwd = this.resetPasswordForm.get('confirmpassword').value;

    if ((password=== undefined || password === '')) {
      this.errorMessage = 'Password field should not be blank';
      return false;
    }
      if ((password || '').match(/\s/g)) {
        this.errorMessage = 'Space in the password field is not allowed !'
        return false;
      } 
      if ((confirmpwd === undefined || confirmpwd === '')) {
        this.errorMessage = 'Confirm Password field should not be blank';
        return false;
        }
     if ((confirmpwd || '').match(/\s/g)) {
       this.errorMessage = 'Space in confirm password field not allowed';
       return false;
       } 
     if(password  !== confirmpwd) {
        return false ;
      }
      
      if(this.isStrong !=='strong') {
       this.errorMessage = 'The password must satisfy all the above conditions'
       return false;
     }
     const passwordstrength = password?.length >= 7 ? true : false;
     if(!passwordstrength) {
      this.errorMessage = 'The password must be longer than 7 characters'
      return false;
    } 
       return true;
  }
  resetMessage() {
    this.errorMessage = '';
    this.invalidEmail = '';
    this.userNotFound = false;
  }

  onSubmit(valid) { }
  getuserType(org_id) {
    if (org_id) {
      this.userService.get(`/configurator/organizations/${org_id}`).subscribe(data => {
        this.storageservice.set('user_type', data.category, true);
      }, error => {
        console.log(error);
      });
    }
  }

  postLogin(data) {
    this.eventStream.emit(new EmitEvent(Events.LoggedIn, true));
    this.storageservice.set(this.userData[0], data['token'], true);
    if (data && data['user_id']) {
      this.accountService.getUser(data['user_id']).subscribe((data) => {

        if (data && data?.user) {
          this.storageservice.set('user', data?.user, true);
          this.user = data?.user;
          this.org_id = data?.user?.organization_id;
          this.getuserType(this.org_id);
          this.getMyPrograms();
          this.storageservice.setWithExpiry(this.userData[4], 3, 50000);
        } else {
          this._alert.warn('Invalid user information');
        }
      });

    }
  }

  login(val, type?) {
    let data = {
      username: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };

    if (type) data = val;
    this._http.post('/authenticate', data)
    .subscribe(
      (res) => {
        console.log(res);
        if(res && res['is_mfa_enabled']){
          if(res['is_mfa_enabled']){
            this.securityCode();
            this.forMfa = true;
            this.userId = res['user_id'];
            this.forgetemail = data.username;
          } else {
            this.postLogin(res);
          }          
        }else{
          this.postLogin(res);
        }     
      },
      (err) => {
        let wrongcredentials = this.storageservice.getWithExpiry(
          this.userData[4]
        );
        if (!wrongcredentials && wrongcredentials != 0) wrongcredentials = 3;
        if (
          wrongcredentials != 'undefined' &&
          wrongcredentials != null &&
          wrongcredentials > 0
        ) {
          wrongcredentials -= 1;
          this.wrongCredentials = wrongcredentials;
        }
        this.storageservice.setWithExpiry(
          this.userData[4],
          wrongcredentials,
          50000
        );
        if (this.wrongCredentials == 0) {
          this.loginForm.controls['email'].disable();
          this.loginForm.controls['password'].disable();
        }
        this.condCheckerObj['invalidUserCredentials'] = err.status;
        this.condCheckerObj['invalidResponse'] = err.statusText;
      }
    );
  }
  removedPlus(email) {
    return email?.replace('+', '%2B');
  }

  validateMFA(otp: any) {
    let data = {
      user_id: this.userId,
      otp: otp
    };

    this._http
    .post(`/profile-manager/authentication/token-verification`, data)
    .subscribe(
      (res) => {
        this.passwordstrengthForm = '';
        this.postLogin(res);
      },
      (err) => {
        this.passwordstrengthForm = err?.error?.error?.message;
        this.invalidOTP += 1;
        this.invalidOtp_cnt -= 1;
        if (this.invalidOTP == 3) {
          this.OTPForm.disable()
        }
        else if (err?.status == 406) {
          this.expireOTP = err?.status;
        }
        else if (err?.status == 404) {
          this.expireOTP = "";
        }
        else {
          this.expireOTP = "";
        }
      }
    );
  }
}