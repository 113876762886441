import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTransferService } from '../../../core/services/data-transfer.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserDataObj } from '../../enums';

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss'],
})


export class LangSwitcherComponent implements OnInit {
  @Input("standalone")
  public standalone: boolean;

  @ViewChild('langTrigger', { read: ElementRef, static: false }) langTrigger: ElementRef;
  @ViewChild('langSwitcherPanel', { read: ElementRef, static: false }) langSwitcherPanel: ElementRef;

  userLanguage = UserDataObj;
  language: string = "English (United States)";
  langShowHide = false;
  langSearch = false;
  searchText: string;
  selectedLang: any = "EN (US)";

  constructor(public translate: TranslateService, private _storageService: StorageService, private render: Renderer2) {
    translate.addLangs(['English (United States)']);
    // 'English (United Kingdom)', 'English (Canada)','Spanish','French'
    let language = this._storageService.get(this.userLanguage[3]);
    if (language != null && language && language != undefined) {
      translate.setDefaultLang(language);
      this.language = language;
    }
    else translate.setDefaultLang('English (United States)');
    this.render.listen('window', 'click', (e: Event) => {
      if ((this.langTrigger && this.langTrigger.nativeElement.contains(e.target)) ||
        (this.langSwitcherPanel && this.langSwitcherPanel.nativeElement.contains(e.target)) && this.langShowHide) {
        this.langShowHide = true;
      } else {
        this.langShowHide = false;
      }
    })
  }
  ngOnInit(): void { }

  switchLang() {
    this.translate.use(this.language);
    this.translate.setDefaultLang(this.language);
  }

  changeLang(e, lang) {
    if (e.target.checked) {
      switch (lang) {
        case "English (United States)":
          this.selectedLang = "EN (US)"
          break;
        case "English (United Kingdom)":
          this.selectedLang = "EN (UK)"
          break;
        case "English (Canada)":
          this.selectedLang = "EN (CA)"
          break;
        case "Spanish":
          this.selectedLang = "ES"
          break;
        case "French":
          this.selectedLang = "FR"
          break;
      }
      this.translate.use(lang);
      this.translate.setDefaultLang(lang);
      this._storageService.set(this.userLanguage[3], lang, true)
    }
  }

  openModalLanguage() {
    this.langShowHide = true;
  }

  closeLang() {
    this.langShowHide = false;
  }
}
