import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProgramSetupComponent } from './program-setup.component';
import { ProgramSetupDetailsComponent } from './program-setup-details/program-setup-details.component';
import { ProgramSetupHomeComponent } from './program-setup-home/program-setup-home.component';



const routes: Routes = [
  {
    path: '',
    component: ProgramSetupComponent,
    children: [
      { path: '', component: ProgramSetupHomeComponent },
      { path: 'program-detail', component: ProgramSetupDetailsComponent },
      {
        path: 'users',
        loadChildren: () => import('./manage-users/manage-users.module').then(m => m.ManageUsersModule)
      },
      {
        path: 'hierarchy',
        loadChildren: './hierarchy/hierarchy.module#HierarchyModule'
        // loadChildren:() => import('./hierarchy/hierarchy.module').then(m =>m.HierarchyModule),
      },
      {
        path: 'qualifications',
        loadChildren: './qualifications/qualifications.module#QualificationsModule'
      },
      {
        path: 'custom-fields',
        loadChildren: () => import('./custom-fields/custom-fields.module').then(m => m.CustomFieldsModule)
      },
      {path: 'vendor',
        // loadChildren: './vendor/vendor.module#VendorModule'
        loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule)
      },
      {path: 'job-template',
      // loadChildren: './vendor/vendor.module#VendorModule'
      loadChildren: () => import('./job-managment/job-managment.module').then(m => m.JobManagmentModule)
    }
    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramSetupRoutingModule { }
