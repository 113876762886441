import { Component, OnInit } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { VMSConfig } from '../library/smartTable/table/table.model';
import { ReportService } from './report.service';
import { errorHandler } from '../shared/util/error-handler';
import { AlertService } from '../shared/components/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  programId: any;
  reportData: any;
  search_term: any;
  oldReportData: any;
  constructor(public reportService: ReportService,
    public _storageService: StorageService,
    public _alert: AlertService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    let programDetails = this._storageService.get('CurrentProgram');
    if (programDetails) {
      this.programId = programDetails['id'];
    }
    this.getReports();
  }

  getReports() {
    let _url = `/report/programs/${this.programId}/report-navigation`;
    this.reportService.get(_url).subscribe(
      data => {
        data?.data?.forEach(r => {
          if (r?.key === 'activity_report') {
            r.icon = 'grain'
          } if (r?.key === 'custom_report') {
            r.icon = 'extension'
          } if (r?.key === 'performance_report') {
            r.icon = 'auto_graph'
          } if (!r?.icon) {
            r.icon = 'auto_graph'
          }
        });
        this.reportData = data?.data;
        this.oldReportData = JSON.stringify(this.reportData);
      }, err => {
        this._alert.error(errorHandler(err));
      });
  }
  searchReport(value) {
    this.reportData = JSON.parse(this.oldReportData);
    value = value?.toLocaleLowerCase();
    let data = this.reportData?.filter(rs => {
      return rs?.label?.toLocaleLowerCase().includes(value) ||
        rs?.sub_items?.some(val => val?.label?.toLocaleLowerCase()?.includes(value));
    });

    data = data.map(res => {
      res.sub_items = res?.sub_items.filter(val => val?.label?.toLocaleLowerCase()?.includes(value));
      return res;
    })
    this.reportData = data;
  }
  showReport(url) {
    this.router.navigateByUrl('/reports/details/' + url)
  }

}
