import { Component, OnInit } from '@angular/core';
import { VMSConfig } from 'src/app/library/smartTable/table/table.model';
import { JobService } from '../job.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { errorHandler } from 'src/app/shared/util/error-handler';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit {
  vmsData = [];
  filter: any = {};
  public user_type: any;

  public tableConfig: VMSConfig;
  public searchTerm: any;

  public totalPages = 0;
  public totalRecords = 10;
  public itemPerPage = 20;
  public pageNo = 1;
  public currentProgram;
  selectedTab: string = undefined;
  public status: any;
  constructor(public jobService: JobService, private loaderService: LoaderService,
    private alertService: AlertService, private router: Router, private storageService: StorageService) { }

  ngOnInit(): void {
    this.user_type = this.storageService.get('user_type');
    if (this.user_type == 'PROGRAM' || this.user_type == 'SUPER_ORG') {
      this.tableConfig = {
        title: 'Jobs',
        columnList: [
          { name: 'title.title', title: 'Job Title', width: 20, isIcon: true, icon: 'expand_more', isImage: true, isContact: false, isNumberBadge: false, isOptOut: true, isSubmitCandidate: true },
          { name: 'status', title: 'Status', width: 10, isIcon: false, isImage: false, isContact: false, isNoOption: false, isNumberBadge: false },
          { name: 'id', title: 'ID', width: 5, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'location_id.city', title: 'Location', width: 8, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'start_date', title: 'Duration', width: 13, isIcon: false, isImage: false, isContact: false, isNumberBadge: false, isRange: true, rangeNameList: ['start_date', 'end_date'] },
          { name: 'hired', title: 'Hired', width: 5, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'submissions', title: 'Submissions', width: 5, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'positions', title: 'Positions', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          // { name: 'no_of_openings', title: 'No of Positions', width: 5, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'budget_estimate', title: 'Estimated Budget', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'direct_sourcing_distribution', title: 'Direct Sourcing Distribution', width: 7, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'submissions_from_direct_sourcing', title: 'Submissions from Direct Sourcing', width: 7, isIcon: false, isImage: false, isContact: false, isNumberBadge: false }
          
        ],
        isExpand: false,
        isFilter: true,
        isSearch: true,
        isSetting: true,
        isTopPagination: true,
        isCreate: true,
        density: 'COMFORTABLE',
        tableWidth: "2000px",
        advanceFilter: [
          { name: 'name', title: 'Job Title', filterType: 'TEXT' },
          {
            name: 'is_enabled', title: 'Status', filterType: 'SELECT', multiSelectData: [
              { name: 'Active', value: true },
              { name: 'Inactive', value: false }
            ]
          }
        ]
      };
    } else if (this.user_type == 'MSP') {
      this.tableConfig = {
        title: 'Jobs',
        columnList: [
          { name: 'title.title', title: 'Job Title', width: 18, isIcon: true, icon: 'expand_more', isImage: true, isContact: false, isNumberBadge: false, isOptOut: true, isSubmitCandidate: true },
          { name: 'status', title: 'Status', width: 10, isIcon: false, isImage: false, isContact: false, isNoOption: false, isNumberBadge: false },
          { name: 'id', title: 'ID', width: 4, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'location_id.city', title: 'Location', width: 7, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'start_date', title: 'Duration', width: 12, isIcon: false, isImage: false, isContact: false, isNumberBadge: false, isRange: true, rangeNameList: ['start_date', 'end_date'] },
          { name: 'hired', title: 'Hired', width: 5, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'submissions', title: 'Submissions', width: 5, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'positions', title: 'Positions', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'no_of_openings', title: 'No of Openings', width: 5, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'budget_estimate', title: 'Estimated Budget', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'direct_sourcing_distribution', title: 'Direct Sourcing Distribution', width: 7, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'submissions_from_direct_sourcing', title: 'Submissions from Direct Sourcing', width: 7, isIcon: false, isImage: false, isContact: false, isNumberBadge: false }
        ],
        tabsList: ['All Jobs', 'Open Jobs', 'Filled Jobs', 'Closed Jobs', 'Pending Approval'],
        showTabs: true,
        isExpand: false,
        isFilter: true,
        isSearch: true,
        isSetting: true,
        isTopPagination: true,
        isCreate: true,
        density: 'COMFORTABLE',
        tableWidth: "2500px",
        advanceFilter: [
          { name: 'name', title: 'Job Title', filterType: 'TEXT' },
          {
            name: 'is_enabled', title: 'Status', filterType: 'SELECT', multiSelectData: [
              { name: 'Active', value: true },
              { name: 'Inactive', value: false }
            ]
          }
        ]
      };
    } else if (this.user_type == 'VENDOR' || !this.user_type) {
      this.tableConfig = {
        title: 'Jobs',
        columnList: [
          { name: 'title.title', title: 'Job Title', width: 20, isIcon: true, icon: 'expand_more', isImage: true, isContact: false, isNumberBadge: false, isOptOut: true, isSubmitCandidate: true },
          { name: 'status', title: 'Status', width: 10, isIcon: false, isImage: false, isContact: false, isNoOption: false, isNumberBadge: false },
          { name: 'id', title: 'ID', width: 7, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'location_id.city', title: 'Location', width: 14, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'start_date', title: 'Duration', width: 15, isIcon: false, isImage: false, isContact: false, isNumberBadge: false, isRange: true, rangeNameList: ['start_date', 'end_date'] },
          { name: 'hired', title: 'Hired', width: 8, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'submissions', title: 'Submissions', width: 8, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          // { name: 'positions', title: 'Positions', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'no_of_openings', title: 'No of Openings', width: 8, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          { name: 'budget_estimate', title: 'Estimated Budget', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: true }
        ],
        tabsList: ['All Jobs', 'Open Jobs', 'Filled Jobs', 'Closed Jobs', 'Pending Approval'],
        showTabs: true,
        isExpand: false,
        isFilter: true,
        isSearch: true,
        isSetting: true,
        isTopPagination: true,
        isCreate: true,
        density: 'COMFORTABLE',
        tableWidth: "2000px",
        advanceFilter: [
          { name: 'name', title: 'Job Title', filterType: 'TEXT' },
          {
            name: 'is_enabled', title: 'Status', filterType: 'SELECT', multiSelectData: [
              { name: 'Active', value: true },
              { name: 'Inactive', value: false }
            ]
          }
        ]
      };
    } else if (this.user_type == 'CLIENT') {
      this.tableConfig = {
        title: 'Jobs',
        columnList: [
          { name: 'title.title', title: 'Job Title', width: 15, isIcon: true, icon: 'expand_more', isImage: true, isContact: false, isNumberBadge: false, isOptOut: true, isSubmitCandidate: true },
          { name: 'status', title: 'Status', width: 8, isIcon: false, isImage: false, isContact: false, isNoOption: false, isNumberBadge: false },
          { name: 'id', title: 'ID', width: 5, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'hierarchy.name', title: 'Hierarchy', width: 7, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          // { name: 'location_id.city', title: 'Location', width: 7, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'start_date', title: 'Duration', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: false, isRange: true, rangeNameList: ['start_date', 'end_date'] },
          { name: 'hired', title: 'Hired', width: 5, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          { name: 'submissions', title: 'Submissions', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          // { name: 'no_of_openings', title: 'No of Openings', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          // { name: 'budget_estimate', title: 'Estimated Budget', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          // { name: 'positions', title: 'Positions', width: 12, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
          // { name: 'direct_sourcing_distribution', title: 'Direct Sourcing Distribution', width: 7, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
          // { name: 'submissions_from_direct_sourcing', title: 'Submissions from Direct Sourcing', width: 7, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },

        ],
        tabsList: ['All Jobs', 'Open Jobs', 'Filled Jobs', 'Closed Jobs', 'Pending Approval'],
        showTabs: true,
        isExpand: false,
        isFilter: true,
        isSearch: true,
        isSetting: true,
        isTopPagination: true,
        isCreate: true,
        density: 'COMFORTABLE',
        tableWidth: "1500px",
        advanceFilter: [
          { name: 'name', title: 'Job Title', filterType: 'TEXT' },
          {
            name: 'is_enabled', title: 'Status', filterType: 'SELECT', multiSelectData: [
              { name: 'Active', value: true },
              { name: 'Inactive', value: false }
            ]
          }
        ]
      };
    }
    this.currentProgram = this.storageService.get('CurrentProgram');
    if (this.currentProgram) {
      this.getJobList();
    }
  }
  onCreateClick(e) {
    this.router.navigate(['/jobs/create']);
  }
  getJobList(pageNo = 1) {
    let _this = this;
    this.pageNo = pageNo;
    this.currentProgram = this.storageService.get('CurrentProgram');
    if (pageNo === 1 && !this.searchTerm) {
      this.loaderService.show();
    }
    let qry = '?';
    if (this.filter.searchTerm) {
      qry = qry + 'title=' + this.filter.searchTerm;
    }
    if (this.filter.hasOwnProperty('is_enabled')) {
      qry = qry + '&is_enabled=' + this.filter.is_enabled;
    }
 
    if(this.status !== 'All Jobs') {
      let jobStatus = '';
      if(this.status === 'Open Jobs') {
        jobStatus = 'Open (Approved)'
      } else if(this.status === 'Filled Jobs') {
        jobStatus = 'Filled'
      } else if(this.status === 'Closed Jobs') {
        jobStatus ='Closed'
      } else if(this.status === 'Pending Approval') {
        jobStatus = 'pending_approval'
      }
       if(this.status) {
        qry = qry + '&status=' + jobStatus;
       }

    }
    qry = qry + '&limit=' + this.itemPerPage + '&page=' + this.pageNo;
    const url = '/job-manager/' + this.currentProgram?.id + '/job' + qry;
    this.jobService.get(url).subscribe(
      data => {
        if (data) {
          data.data.map(function (job) {
            if (!job.is_delete) {
              if (job.status === 'pending_approval') {
                job.status = 'Pending approval';
              } else if (job.status === 'release_job') {
                job.status = 'Release job';
              }
              // job.is_enabled = true;
              job.hired = 0;
              job.submissions = 0;
              job.submissions_from_direct_sourcing = null;
              job.direct_sourcing_distribution = null;
              job.program_name = _this.currentProgram?.name || ''
            }
          });
          this.vmsData = data.data;
          this.totalRecords = data.total_count;
          // this.itemPerPage = data.items_per_page;
          this.loaderService.hide();
        }
      },
      (err) => {
        this.loaderService.hide();
        this.alertService.error(errorHandler(err));
        this.vmsData = new Array();
        this.totalRecords = 0;
      });
  }
  onPaginationClick(event) {
    this.getJobList(event);
  }
  onSortClick(event) {

  }

  onSearch(event) {
    this.filter.searchTerm = event;
    this.getJobList();
  }

  onListFilter(event) {
    if (event) {
      // if (event?.hasOwnProperty('is_enabled')) {
      //   if (event.is_enabled) {
      //     this.filter.is_delete = 'False';
      //   } else {
      //     this.filter.is_delete = 'True';
      //   }
      // }
      if (event.hasOwnProperty('is_enabled')) {
        if (event.is_enabled) {
          this.filter.is_enabled = 'True';
        } else {
          this.filter.is_enabled = 'False';
        }
      }
      if (event.name) {
        this.filter.searchTerm = event.name;
      }

      if (event.name && !event?.hasOwnProperty('is_enabled')) {
        this.filter.searchTerm = event.name;
      }
      this.getJobList();
    } else {
      this.filter = {};
      this.getJobList();
    }
  }


  onClickView(ev) {
    if (ev) {
      this.storageService.set('viewd_job', ev, true);
      this.router.navigate([`jobs/details/job-details/${ev.uid}`]);
    }
  }
  onTabClick(e) {
    if (typeof (e) === 'object') {
      return
    } else {
      this.status = e;
      this.getJobList();
      // this.location.replaceState("assignment/all-list");
    }
  }
  onOptOutClick(event) {
  }
  onSubmittedCandidateClick(event) {
    if (event && event?.uid) {
      this.router.navigate(['/candidates/submit'], { queryParams: { candidateSubmission: true, jobId: event.uid, type: 'addThenSubmit' } });
    }

  }

}
