import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { errorHandler } from 'src/app/shared/util/error-handler';
import { environment } from './../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CandidateService {
  mockApi: string;
  private currentProgram;
  constructor(private _http: HttpService,
    private _alert: AlertService,
    private storageService: StorageService) {
      this.currentProgram = this.storageService.get('CurrentProgram'); 
  }

  get(url) {
    return this._http.get(url);
  }

  post(url, payload) {
    return this._http.post(url, payload);
  }

  put(url, payload) {
    return this._http.put(url, payload);
  }

  getQualifications() {
    const _url = `/configurator/programs/*/qualification-types`;
    return this.get(_url).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }
  _getQualiFicationType(id) {
    const _url = `/configurator/programs/*/qualification-types/${id}/qualifications`;
    return this.get(_url).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }

  saveCandidate(payload) {
    const _url = `/configurator/candidates`;
    return this.post(_url, payload).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }

  updateCandidate(id, payload) {
    const _url = `/configurator/candidates/${id}`;
    return this.put(_url, payload).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }

  getCandidateDetail(id) {
    const _url = `/configurator/candidates/${id}`;
    return this.get(_url).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }

  fetchCandidates(limit = 10, page = 1, k = null) {
    const _url = `/configurator/candidates?limit=${limit}&page=${page}${k}`;
    return this.get(_url).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }

  /**
   * Use this method to encode base64
   * and it returns the promiss.
   * @param filedata 
   */

  encodeToBase64(filedata) {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(filedata);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  }

  getParsedResumeData(fileData) {
    const payload = {
      filename: fileData['name'],
      raw: fileData['raw_without_base64'] ? fileData['raw_without_base64'] : null
    }
    const _url = `/configurator/candidates/resumes/parse`;
    return this.post(_url, payload).pipe(map(
      data => {
        return data;
      },
      err => {
        throw new Error(err);
      }
    ));
  }
  getCurrency() {
    const _url = `/configurator/resources/currencies`;
    return this.get(_url).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      } else {
        this._alert.error(`No data found.`);
      }
    }, (err) => {
      this._alert.error(errorHandler(err));
    }));
  }


  chnageCandidateStatus(param) {

    const candidate_id = param?.candidate_real_id;
    const payload = {
      is_enabled: param.is_enabled ? false : true
    }
    const _url = `/configurator/candidates/${candidate_id}`;

    return this.put(_url, payload).pipe(map(data => {
      if (data) {
        return data;
      }
    }, (err) => {
      throw new Error(err);
      // this._alert.error(errorHandler(err));
    }));
  }


  advanceFilter(fileData, page = 1) {
    // console.log("5678865", fileData);
    const payload = {
      "filters": {
        "name": fileData?.name,
        "email": fileData?.email,
        "designation": fileData?.designation,
        "vendor": [],
        "date_range": fileData?.start_date,
        "is_enabled": true,
        "location":fileData?.location,
      },
      pagination: {
        "limit": 10,
        "page": page
      }
    }
    const _url = `/configurator/candidates/advanced-filters`;
    return this.post(_url, payload).pipe(map(
      data => {
        return data;
      },
      err => {
        throw new Error(err);
      }
    ));
  }
  getSubmittedCandidate(limit = 10, page = 1, k = null, programID, jobID) {
    programID = this.currentProgram?.id; //'04f00cc1-67ef-435b-884a-654e20587a2a'; 
    const base_url = environment.API_ENDPOINT;
    const _url = `/configurator/programs/${programID}/jobs/${jobID}/candidates?limit=${limit}&page=${page}${k}`;
    return this._http.get(_url,base_url).pipe(map(res => {
      let data = res;
      if (data) {
        return data;
      }
    }));
  }

}
