import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService: HttpService) { }

  public password: any;

  validateUserInvitationToken(orgId: string, token: string): Observable<any> {
    return this.httpService.get(`${environment.API_ENDPOINT}/public/organizations/${orgId}/members/invitation/${token}`)
  }
  setPasswordViaInvitaionToken(orgId: string, token: string, password: string): Observable<any> {
    let data = { "password": password }
    this.password = data.password;
    return this.httpService.put(`/public/organizations/${orgId}/members/invitation/${token}/password-setup/`, data)
  }
  
  getMembershipDetails(programId, memberId){
    return this.httpService.get(`/configurator/programs/${programId}/members/${memberId}`);
  }

  sendWelcomeEmail(userId){
    let data = {};
    return this.httpService.post(`/configurator/resend-invite/${userId}`,data);
  }

  sendWelcomeEmailCheck(userId){
    return this.httpService.get(`/profile-manager/users/${userId}`);
  }

  updateUserDetails(userId: string, userData): Observable<any> {
    return this.httpService.put(`/vms-profile-manager/users/${userId}/`, userData)
  }

  Questions(url): Observable<any> {
    return this.httpService.get(`/public/resources/security-questions`);
  }

  updateQuestion(orgId: string, token: string, userData): Observable<any> {
    return this.httpService.put(`/account-setup/profile-setup?orgId=${orgId}&token=${token}`, userData)
  }


  getAvatar(uuid: any): Observable<any> {
    return this.httpService.get(`/vms-profile-manager/users/avatar/${uuid}`)
  }

  updateAvatar(orgId: string, token: string, userData: any): Observable<any> {
    return this.httpService.put(`/public/organizations/${orgId}/members/invitation/${token}/avatar`, userData)
  }

  uploadMembers(orgId: string, token: string, userData: any): Observable<any> {
    return this.httpService.put(`/public/organizations/${orgId}/members/invitation/${token}/profile-setup`, userData)
  }

  getThemeColor(themeColor: any, orgId: any): Observable<any> {
    return this.httpService.get(`/account-setup/resources/themes`)
  }

  login(data: any): Observable<any> {
    return this.httpService.post(`/authenticate`, data)
  }

  getUser(email: any): Observable<any> {
    return this.httpService.get(`/profile-manager/users?email=${email}`);
  }

  getAllMembers(orgId, pageNo, limit): Observable<any> {
    const id = orgId ? orgId : environment.SIMPLIFY_ORG_ID
    return this.httpService.get(`/profile-manager/organizations/${id}/members?limit=3`);
  }

  getAllPrograms(): Observable<any> {
    return this.httpService.get(`/configurator/programs?source_user=SELF`);
  }

  get(url) {
    return this.httpService.get(url);
  }

  put(url, payload) {
    return this.httpService.put(url, payload);
  }
  post(url, data: any): Observable<any> {
    return this.httpService.post(url, data);
  }
  delete(url, payload) {
    return this.httpService.delete(url, payload);
  }

  saveUser(url, data) {
    return this.httpService.post(url, data);
  }

  updateUser(url, data) {
    return this.httpService.put(url, data)
  }

  getAllRoleList(program_req_Id, pageNo, limit, term = null): Observable<any> {
    const id = program_req_Id ? program_req_Id : environment.SIMPLIFY_ORG_ID;
    const query = `?limit=${limit}&page=${pageNo}${term ? ('&k=' + term) : ''}`;
    return this.httpService.get(`/configurator/programs/${id}/roles${query}`);
  }

  getAllCustomFieldsList(program_req_Id, pageNo, limit): Observable<any> {
    const id = program_req_Id ? program_req_Id : environment.SIMPLIFY_ORG_ID;
    const query = `?limit=${limit}&page=${pageNo}`;
    return this.httpService.get(`/configurator/programs/${id}/custom-fields${query}`);
  }

  getAllpicksList(program_req_Id, pageNo, limit): Observable<any> {
    const id = program_req_Id ? program_req_Id : environment.SIMPLIFY_ORG_ID;
    const query = `?limit=${limit}&page=${pageNo}`;
    return this.httpService.get(`/configurator/programs/${id}/picklists${query}`);
  }

  getpicksListItems(program_req_Id, picklist_Id, pageNo, limit): Observable<any> {
    const id = program_req_Id ? program_req_Id : environment.SIMPLIFY_ORG_ID;
    const query = `?limit=${limit}&page=${pageNo}`;
    return this.httpService.get(`/configurator/programs/${id}/picklists/${picklist_Id}/items${query}`);
  }

  getAllModuleList(program_req_Id): Observable<any> {
    const id = program_req_Id ? program_req_Id : environment.SIMPLIFY_ORG_ID
    return this.httpService.get(`/configurator/programs/${id}/module-groups `);
  }

  getUserByRoleId(programId, roleId, pageNo, limit): Observable<any> {
    const qry = '?limit=' + limit + '&page=' + pageNo + '&role_ids=' + roleId;
    return this.httpService.get(`/configurator/programs/${programId}/members` + qry);
  }

  cloneRole(programId, roleId) {
    return this.httpService.post(`/configurator/programs/${programId}/roles/${roleId}/clone`, {});
  }

  getAllRecentJobs(program_req_Id, limit, pageNo): Observable<any> {
    const id = program_req_Id ? program_req_Id : environment.SIMPLIFY_ORG_ID;
    const qry = '?limit=' + limit + '&page=' + pageNo;
    return this.httpService.get(`/job-manager/${id}/recent_job` + qry);
  }

  getAllPopularJobs(program_req_Id, limit, pageNo): Observable<any> {
    const id = program_req_Id ? program_req_Id : environment.SIMPLIFY_ORG_ID;
    const qry = '?limit=' + limit + '&page=' + pageNo;
    return this.httpService.get(`/job-manager/${id}/popular_job` + qry);
  }

  searchTemplate(url): Observable<any> {
    return this.httpService.get(`/job-manager/12345_program/job_template` + url);
  }
  getProgram(url): Observable<any> {
    return this.httpService.get('/configurator/programs' + url);
  }

  getFoundantionalType(program_req_Id): Observable<any> {
    const id = program_req_Id ? program_req_Id : environment.SIMPLIFY_ORG_ID;
    return this.httpService.get(`/configurator/programs/${program_req_Id}/foundational-data-types`);
  }
  
}
