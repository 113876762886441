import { Component, OnInit } from '@angular/core';
import { EventStreamService, Events, EmitEvent } from 'src/app/core/services/event-stream.service';
import { FormBuilder, FormGroup, NgForm, Validators, FormControlName, FormArray } from '@angular/forms';
import { JobDetailsService } from '../../job-details.service';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { errorHandler } from 'src/app/shared/util/error-handler';
import { Router,ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-withdraw-candidate',
  templateUrl: './withdraw-candidate.component.html',
  styleUrls: ['./withdraw-candidate.component.scss']
})
export class WithdrawCandidateComponent implements OnInit {
  withdrawSidebar = "hidden";
  candidateId:'';
  jobId:'';
  public withdrawForm: FormGroup;
  public candidateWithdrwanReason = {}
  title : string = "Withdrawal Reason";
  label : string = "Withdrawal Reason";
  btnLabel : string = "Withdraw";
  isRejectCandidate :boolean =false;
  cancelInterview: boolean = false;
  interviewID: any;
  constructor(
    private eventStream: EventStreamService,
    private fb: FormBuilder,
    private jobService : JobDetailsService,
    private alert : AlertService,
    private Router : Router,
    private ActivatedRoute:ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.withdrawForm = this.fb.group({
      reason: [null, Validators.required],
      notes: [null,''],
    });
    this.eventStream.on(Events.WITHDRAW_CANDIDATE, (data) => {
      if (data.value) {
        this.title = "Withdrawal Reason";
        this.label = "Withdrawal Reason";
        this.btnLabel = "Withdraw";
        this.withdrawSidebar = 'visible';
        this.candidateId = data.candidateId;
        this.jobId = data.jobId;
      } else {
        this.withdrawSidebar = 'hidden';
      }
    });
    this.eventStream.on(Events.REJECT_CANDIDATE,(data)=>{
      if (data.value) {
        this.title = "Rejection Reason";
        this.label = "Rejection Reason";
        this.btnLabel = "Reject";
        this.isRejectCandidate = true;
        this.withdrawSidebar = 'visible';
        this.candidateId = data.candidateId;
        this.jobId = data.jobId;
      } else {
        this.withdrawSidebar = 'hidden';
      }
    })
    this.eventStream.on(Events.CANCEL_INTERVIEW,(data)=>{
      if(data.value){
        this.title = "Cancellation Reason";
        this.label = "Cancellation Reason";
        this.btnLabel = "Cancel";
        this.cancelInterview = true;
        this.withdrawSidebar = 'visible';
        this.interviewID = data.id;
        this.jobId = this.ActivatedRoute.snapshot.params['id'];
      }
      else {
        this.withdrawSidebar = 'hidden';
      }
    })
  }
  sidebarClose() {
    this.withdrawForm.reset();
    this.isRejectCandidate = false;
    this.cancelInterview = false;
    this.withdrawSidebar = 'hidden';
  }
  withdrawCandidate(){
    if(this.isRejectCandidate){
      let rejectCandidate = this.withdrawForm.value;
      const payload = {
        candidates: [{
            candidate_id: this.candidateId,
            status_reason: rejectCandidate.reason,
            status_note: rejectCandidate.notes,
          }]
      }
      this.jobService.rejectCandidate(payload, this.jobId).subscribe(data => {
        if (data.ref) {
          this.withdrawForm.reset();
          this.sidebarClose();
          this.alert.success('Rejected successfully');
          this.eventStream.emit(new EmitEvent(Events.UPDATED_CANDIDATE_STATUS,true))
        }  
      }, (err) => {
          this.alert.error(errorHandler(err));
      });
    }
    else if(this.cancelInterview){
      // let cancelInterview = this.withdrawForm.value;
      // const payload = {
      //   interview: [{
      //       interview_id: this.interviewID,
      //       cancel_reason: cancelInterview.reason,
      //       cancel_note: cancelInterview.notes,
      //     }]
      // }
      // this.jobService.rejectCandidate(payload, this.interviewID).subscribe(data => {
      //   if (data.ref) {
      //     this.withdrawForm.reset();
           this.sidebarClose();
      //     this.alert.success('Interview Cancelled successfully');
      //     this.eventStream.emit(new EmitEvent(Events.UPDATED_CANDIDATE_STATUS,true))
      //   }  
      // }, (err) => {
      //     this.alert.error(errorHandler(err));
      // });
    }
    else{
      let withdrawData = this.withdrawForm.value;
      const payload = {
        candidates: [{
            candidate_id: this.candidateId,
            status_reason: withdrawData.reason,
            status_note: withdrawData.notes
          }]
      }
      this.jobService.withdrawCandidate(payload, this.jobId).subscribe(data => {
        if (data.ref) {
          this.withdrawForm.reset();
          this.sidebarClose();
          this.alert.success('Withdrawn successfully');
          this.eventStream.emit(new EmitEvent(Events.UPDATED_CANDIDATE_STATUS,true))
        }  
      }, (err) => {
          this.alert.error(errorHandler(err));
      });
    }
  }
}
