import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateJobStaticComponent } from './create-job-static/create-job-static.component';
import { CreateJobComponent } from './create-job/create-job.component';
import { JobDetailStaticComponent } from './job-detail-static/job-detail-static.component';
import { JobListStaticComponent } from './job-list-static/job-list-static.component';
import { JobListComponent } from './job-list/job-list.component';
import { JobsComponent } from './jobs.component';
import { JobListStaticV2Component } from './job-list-static-v2/job-list-static-v2.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '', component: JobsComponent,
    children: [
      { path: 'create', component: CreateJobComponent },
      { path: 'create-job', component: CreateJobStaticComponent },
      { path: 'job-details', component: JobDetailStaticComponent },
      {
        path: 'details',
        loadChildren: () => import('./job-details/job-details.module').then(m => m.JobDetailsModule)
      },
      { path: 'list', component: JobListComponent },
      { path: 'job-list', component: JobListStaticComponent },
      { path: 'job-list-v2', component: JobListStaticV2Component },
      {
        path: 'submission',
        loadChildren: () => import('./submitted-candidate/submitted-candidate.module').then(m => m.SubmittedCandidateModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobsRoutingModule { }
