<div id="pagecontainer" class="page-container {{valueEmittedFromChildComponent}}">
  <app-sidebar [addZindexClass]='showBackdrop' (buttonClicked)="parentEventHandlerFunction($event)">
  </app-sidebar>
  <div  class="content">
    <app-header (notificationClick)="notificationEventHandlerFunction($event)"></app-header>

    <div class="backdrop-Overlay" *ngIf="showBackdrop"></div>
   <router-outlet></router-outlet>

  </div>
  <div class="notification-panel {{valueEmittedFromHeaderComponent}}">
    <app-notification (notifyClose)="notificationEventHandlerFunction($event)"></app-notification>
  </div>

    <div class="notification-panel active" *ngIf="toggleProgramPanel">
      <app-programs [programs]="programs"></app-programs>
    </div>
  <div class="clearfix"></div>
</div>
