<div class="static-listing static-v2">
    <div>
      <div class="d-flex align-items-center pt-32 pb-32 pr-32 pl-0 justify-content-between bg-light vms-header-panel">
        <div class="d-flex mt-12">
          <ul class="breadcrumb-list">
            <li class="breadcrumb-listItem">
              <a href="javascript:void(0)" class="page-title font-weight-medium"> Jobs </a>
            </li>
            <li class="breadcrumb-listItem">
              <button type="button" class="btn small btn-primary ml-16 pl-8" (click)="onClickCreate()">
                <icon size="20px" name="add" type="material" cuClass="d-flex" class="icon--on-left d-flex">
                </icon>
                Create New
              </button>
            </li>
          </ul>
        </div>
        <div class="d-flex  mt-12">
          <ul class="listing-filter">
            <li class="position-relative">
              <a href="javascript:void(0)" class="btn small btn-outine" #search>
                <icon size="17px" name="search" type="material" cuClass="d-flex"></icon>
              </a>
            </li>
            <li class="position-relative">
              <a href="javascript:void(0)" class="btn small btn-outine">
                <icon size="17px" name="settings" type="material" cuClass="d-flex"></icon>
              </a>
            </li>
            <li class="position-relative">
              <!--ui for no active filter-->
              <a href="javascript:void(0)" class="btn small btn-outine">
                <icon size="17px" name="filter_alt" type="material" cuClass="d-flex"></icon>
              </a>
            </li>
            <li class="mr-0">
              <!-- Top Pagination -->
              <div class="btn small btn-outine pagination">
                <a href="#" class="mr-1">
                  <icon size="20px" name="keyboard_arrow_left" type="material" cuClass="d-flex"></icon>
                </a>
                <span>1</span>
                <span>&#x0002F;</span>
                <span>1</span>
                <a href="#" class="ml-1">
                  <icon size="20px" size="20px" name="keyboard_arrow_right" type="material" cuClass="d-flex">
                  </icon>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content-area ui-content-wrapper bg-light">
      <div class="content-area-inner">
        <div class="listing-wrap">
          <div class="table-outer">
            <div class="table-overflow">
              <perfect-scrollbar  [config]="{ suppressScrollX: false, suppressScrollY: false}">
                <table class="table table-listing  wide-table cloned">
                  <thead>
                    <tr>
                      <th width="20%" class="not-scrollable">
                        <span class="mr-4">Job Title</span>
                      </th>
                      <th width="8%">
                        <span class="mr-4">Status</span>
                      </th>
                      <th width="5%">
                        <span class="mr-4">ID</span>
                      </th>
                      <th width="14%">
                        <span class="mr-4">Hiring Manager</span>
                      </th>
                      <th width="12%">
                        <span class="mr-4">Duration</span>
                      </th>
                      <th width="7%">
                        <span class="mr-4">Hierarchy</span>
                      </th>
                      <th width="7%">
                        <span class="mr-4">Location</span>
                      </th>
                      <th width="10%">
                        <span class="mr-4">Cost Center</span>
                      </th>
                      <th width="8%">
                        <span class="mr-4">Department</span>
                      </th>
                      <th width="9">
                        <span class="mr-4">Position 1</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr (mouseleave)="hideOptionDropdown()">
                      <td colspan="11">
                        <table>
                          <tr *ngFor="let data of vmsData.candidate; let i = index"
                            [ngClass]="{'active' : hoverState == i}" (mouseenter)="hoverClass(i)">
                            <td width="20%" class="not-scrollable has-border">
                              <p><span
                                  class="initials initials__purple mr-8">{{data.job_title | shortName:'first'}}</span><a
                                  href="javascript:void(0)"
                                  class="font-weight-medium listing-title">{{data.job_title}}</a></p>
                              <span class="actions">
                                <button class="btn small btn-secondary" (click)="viewDetailPage()">
                                  <icon size="14px" name="visibility" type="material" class="d-flex"
                                    cuClass="icon--on-left d-flex">
                                  </icon>
                                  View
                                </button>
  
                                <button class="btn small btn-outine bg-light rounded-sm ml-4">
                                  <icon size="14px" name="more_horiz" type="material" cuClass="d-flex" class="flex">
                                  </icon>
                                </button>
                              </span>
                            </td>
                            <td width="8%">
                              <p><span class="approved">{{data.status}}</span></p>
                            </td>
                            <td width="5%">
                              <p class="text-underline">{{data.id}}</p>
                            </td>
                            <td width="14%">
                              <figure class="float-left mr-12">
                                <img src="../../../assets/images/{{data.hierarchy_manager.avatar}}.png" alt="">
                              </figure>
                              {{data.hierarchy_manager.name}}
                            </td>
                            <td width="12%">{{data.duration}}</td>
                            <td width="7%">{{data.hierarchy}}</td>
                            <td width="7%">{{data.location}}</td>
                            <td width="10%">{{data.cost_center}}</td>
                            <td width="8%">{{data.department}}</td>
                            <td width="9"><span class="position">{{data.position}}</span></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-listing wide-table">
                  <thead>
                    <tr>
                      <th width="20%">
                        <span class="mr-4">Job Title</span>
                      </th>
                      <th width="8%">
                        <span class="mr-4">Status</span>
                      </th>
                      <th width="5%">
                        <span class="mr-4">ID</span>
                      </th>
                      <th width="14%">
                        <span class="mr-4">Hiring Manager</span>
                      </th>
                      <th width="12%">
                        <span class="mr-4">Duration</span>
                      </th>
                      <th width="7%">
                        <span class="mr-4">Hierarchy</span>
                      </th>
                      <th width="7%">
                        <span class="mr-4">Location</span>
                      </th>
                      <th width="10%">
                        <span class="mr-4">Cost Center</span>
                      </th>
                      <th width="8%">
                        <span class="mr-4">Department</span>
                      </th>
                      <th width="9">
                        <span class="mr-4">Position 1</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr (mouseleave)="hideOptionDropdown()">
                      <td colspan="11">
                        <table>
                          <tr *ngFor="let data of vmsData.candidate; let i = index" [ngClass]="{'active' : hoverState == i}" (mouseenter)="hoverClass(i)">
                            <td width="20%">
                              <p><span
                                  class="initials initials__purple mr-8">{{data.job_title | shortName:'first'}}</span><a
                                  href="javascript:void(0)"
                                  class="font-weight-medium listing-title">{{data.job_title}}</a></p>
                            </td>
                            <td width="8%">
                              <p><span class="approved">{{data.status}}</span></p>
                            </td>
                            <td width="5%">
                              <p class="text-underline">{{data.id}}</p>
                            </td>
                            <td width="14%">
                              <figure class="float-left mr-12">
                                <img src="../../../assets/images/{{data.hierarchy_manager.avatar}}.png" alt="">
                              </figure>
                              {{data.hierarchy_manager.name}}
                            </td>
                            <td width="12%">{{data.duration}}</td>
                            <td width="7%">{{data.hierarchy}}</td>
                            <td width="7%">{{data.location}}</td>
                            <td width="10%">{{data.cost_center}}</td>
                            <td width="8%">{{data.department}}</td>
                            <td width="9"><span class="position">{{data.position}}</span></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </perfect-scrollbar>
            </div>
          </div>
          <div class="total-result">
            <div class="total-result-box">
              20 Results
              <div class="pagination-bottom font-weight-normal float-right">
                <a href="javscript:void(0)">
                  <icon name="keyboard_arrow_left" size="14px" type="material" class="d-flex" cuClass="d-flex">
                  </icon>
                </a>
                <a href="javscript:void(0)" class="active">
                  1
                </a>
                <a href="javscript:void(0)">
                  <icon name="keyboard_arrow_right" size="14px" type="material" class="d-flex" cuClass="d-flex">
                  </icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  