import { Component, OnInit, ViewChild } from '@angular/core';
import { EmitEvent, Events, EventStreamService } from 'src/app/core/services/event-stream.service';
import { VMSConfig } from 'src/app/library/table/table/table.model';
import { GridViewComponent } from '../grid-view/grid-view.component';
import { CandidateService } from 'src/app/candidate/service/candidate.service';
import { errorHandler } from 'src/app/shared/util/error-handler';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-submitted-candidates',
  templateUrl: './submitted-candidates.component.html',
  styleUrls: ['./submitted-candidates.component.scss']
})
export class SubmittedCandidatesComponent implements OnInit {
  public dataLoader: any;
  public vmsData: any;
  public tableConfig: VMSConfig;
  public isExpand = false;
  public totalPages = 0;
  public totalRecords = 0;
  gridContentType = 'candidate';
  tableLoaded = false;
  dataLoading = true;
  public viewGrid: boolean = true;
  public gridVewJson: any = {};
  itemsPerPage: any;
  searchTerm: any;
  jobId = '';
  filterDataPage: number;
  isAdvanceSearch = false;
  filterpayLoad: any;

  // @ViewChild(GridViewComponent) public grid: GridViewComponent;

  constructor(private _eventStrem: EventStreamService,
    private candidateService: CandidateService,
    private _alert: AlertService,
    private _loader: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.params['id'];

    this.tableConfig = {
      title: 'Submitted Candidates',
      columnList: [
        { name: 'candidate', title: 'Candidate', width: 20, isIcon: false, isImage: true, isContact: false, isNumberBadge: false },
        { name: 'availability', title: 'Availability', width: 15, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
        { name: 'pay_rate', title: 'Pref. Pay Rate', width: 15, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
        { name: 'est_hours', title: 'EST.Hours', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: false },
        { name: 'est_pay', title: 'EST.Pay', width: 8, isIcon: false, isImage: false, isContact: false, isNumberBadge: true },
        { name: 'status', title: 'Status', width: 10, isIcon: false, isImage: false, isContact: false, isNumberBadge: false, isCandidateStatus: false },
        { name: 'submitted_on', title: 'Submitted On', width: 15, isIcon: false, isImage: false, isContact: false, isNumberBadge: false, isProfileMatch: false, isCandidateStatus: false },
        { name: 'match', title: 'Match', width: 12, isIcon: false, isImage: false, isContact: false, isVieworEdit: false, isDisableorDelete: false, isDelete: false, isViewProfile: false, isWithdrawal: true, isNumberBadge: false, isProfileMatch: true, isViewEnabled: true },
      ],
      isExpand: false,
      isFilter: true,
      isSearch: true,
      isSetting: true,
      isTopPagination: false,
      hideResultCount: true,
      isCreate: false,
      density: 'COMFORTABLE',
      isTheme: true,
      // advanceFilter: [
      //   { name: 'availability', title: 'Availability', filterType: 'DATERANGE'},
      //   { name: 'est_pay', title: 'Estimated Pay Rate', filterType: 'DATERANGE'},
      //   { name: 'modified_on', title: 'Date Updated range', filterType: 'DATERANGE'},
      //  ]
    };

    this.getSubmittedCandidateList();
    this._eventStrem.on(Events.SHOW_GRID_LAYOUT, (data) => {
      // this.viewGrid = false;
      if(data){
        this.viewGrid = false;
      }else{
        this.viewGrid = true;
      }
    });

    this._eventStrem.on(Events.SEARCH_IN_SUB_CANDIDATE, (data) => {
     if(data){
        console.log(data);
        this.searchTerm = data.value;
        this.getSubmittedCandidateList();
     }
    });

  }

  getSubmittedCandidateList(pageNo = 1) {
    this.dataLoader = true;
    // this.vmsData = {
    //   "candidate": [
    //     {
    //       "candidate": "James Butt",
    //       "availability": "12/04/2020",
    //       "pay_rate": "$54.00",
    //       "est_hours": "55.0",
    //       "est_pay": "$2,970.00",
    //       "status": "Submitted",
    //       "submitted_on": "12/04/2020",
    //       "match": "60",
    //       "submittenOn":"1"
    //     },
    //     {
    //       "candidate": "Josephine Darakjy",
    //       "availability": "12/04/2020",
    //       "pay_rate": "$54.00",
    //       "est_hours": "55.0",
    //       "est_pay": "$2,970.00",
    //       "status": "Sourcing",
    //       "submitted_on": "12/04/2020",
    //       "match": "50",
    //       "submittenOn":"2"
    //     },
    //     {
    //       "candidate": "Art Venere",
    //       "availability": "12/04/2020",
    //       "pay_rate": "$54.00",
    //       "est_hours": "55.0",
    //       "est_pay": "$2,970.00",
    //       "status": "Rejeceted",
    //       "submitted_on": "12/04/2020",
    //       "match": "90",
    //       "submittenOn":"5"
    //     }
    //   ]
    // }
    // this.tableLoaded = true;
    // this.dataLoading = false;
    // this.itemsPerPage = 10;
    // this.totalRecords = 3;
    // this.gridVewJson =this.vmsData;
    //call this after api start working
    //console.log("######", this.jobId);
    let search = this.searchTerm ? ('&k=' + this.searchTerm) : ''
    var cand_arr = [];
    let programId = "04f00cc1-67ef-435b-884a-654e20587a2a";
    let JobID =this.jobId;// this.jobId;
    this.candidateService.getSubmittedCandidate(10, pageNo, search, programId, JobID).subscribe(data => {
      if (data) {
        this.dataLoader = false;
        data?.candidates.forEach(element => {
          let candidate_name1 = (element?.middle_name) ? `${element?.first_name} ${element?.middle_name} ${element?.last_name}` : `${element?.first_name} ${element?.last_name}`;
          let date1 = new Date(element.modified_on);
          let today = new Date();
          // To calculate the time difference of two dates 
          let Difference_In_Time = Math.round(today.getTime() - date1.getTime());
          // To calculate the no. of days between two dates 
          let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
         // let estPay = element.est_pay ? `$ ${element.est_pay}` :'' ;
          let estPayRate = element?.candidate_pay_rate ? `$ ${element.candidate_pay_rate}` :'' ;
          let candidate_list_data = {
            "id":element.id,
            "candidate": candidate_name1,
            "availability": element.available_date,
            "pay_rate": estPayRate,
            "est_hours": element.estimated_hours,
            "status": element.status,
            "est_pay": '$10',            
            "submitted_on": element.modified_on,
            "submittenOn": Difference_In_Days,
            "match":  {
              'matchValue': 20,
              'status': element.status
            },
          }
          cand_arr.push(candidate_list_data);
        });
        this.vmsData = { 'candidate': cand_arr };
        this.gridVewJson =this.vmsData;
        this.dataLoading = false;
        this.totalRecords = data?.total_records;
        this.itemsPerPage = data?.items_per_page;
        this.tableLoaded = true;
        this._loader.hide();
      }
      else {
        this.dataLoader = false;
        this._alert.error(`No data found.`);
      }
    }, err => {
      this.dataLoader = false;
      this._alert.error(errorHandler(err));
    });

  }
  withdrawalClicked(event) {
    if (event.status == 'SUBMITTED') {
      this._eventStrem.emit(new EmitEvent(Events.WITHDRAW_CANDIDATE, { value: true, candidateId: event.id, jobId: this.jobId }));
    }else if (event.status == 'WITHDRAWN'){
      this.router.navigate(['/candidates/submit'],{ queryParams: { candidateSubmission: true, jobId: this.jobId, type:'submit', candidateId:event.id} });
    }
  }

  onClickView(e) {
    if(e.status == 'SUBMITTED'){
      this.router.navigate([`/jobs/submission/${this.jobId}/candidate/${e.id}/offer`]);
    }else{
      this._alert.warn(`The status for this candidate is ${e.status}`)
    }
  }
  onEditClick(data) {

  }
  cloneClicked(e) {

  }
  onExpandClick(e) { }

  onPaginationClick(e) {
    this.getSubmittedCandidateList(e);
  }

  onDeleteClick(role) {

  }

  disableClicked(role) {
  }

  onCreateClick(e) {

  }

  columnClicked(columnData) {

  }
  selectAllClicked(data){
    console.log(data);
  }
  selectClicked(data){
    console.log(data);
  }
  onSearch(term) {
    this.searchTerm = term;
    this.getSubmittedCandidateList();
  }
  onListFilter(event) {
    // this._loader.show();
    this.isAdvanceSearch = true;
    this.filterpayLoad = event;
   this.submittedCandidates(event);
 }

 submittedCandidates(event){
     console.log(event);
 }
 clickViewSubmittedCandidate(event) {
   if(event) {
    this._eventStrem.emit(new EmitEvent(Events.VIEW_SUBMITTED_CANDIDATE_PROFILE, { value: event}));
   }
 }
}
