import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridStack } from "gridstack";
import "gridstack/dist/h5/gridstack-dd-native";
import "gridstack/dist/jq/gridstack-dd-jqueryui";
import { AccountService } from '../auth/account-setup/account.service';
import { EventStreamService } from '../core/services/event-stream.service';
import { StorageService } from '../core/services/storage.service';
import { ThemeService } from '../core/services/theme.service';
import { UserService } from '../core/services/user.service';
import { AlertService } from '../shared/components/alert/alert.service';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  programId: string;
  grid: GridStack;
  user: any;
  role: any;
  widgetData: any;
  workers: any;
  assignment: any;
  private currentProgram;
  assignmentId: any;
  hasNoProgram = false;
  constructor(
    private userService: UserService,
    private dashboardService: DashboardService,
    public eventStream: EventStreamService,
    public router: ActivatedRoute,
    public storageservice: StorageService,
    public accountService: AccountService,
    private _alert: AlertService,
    public themeService: ThemeService,
    private route: Router,
    private storageService: StorageService,
    private _storageService: StorageService) {
    this.currentProgram = this.storageService.get('CurrentProgram');
  }

  ngOnInit(): void {
    // this.grid = GridStack.init();
    // this.grid.disable();
    let temptheme = this.storageservice.get('Usertheme');
    this.themeService.changeTheme(temptheme || this.user?.theme_code);

    let programDetails = this._storageService.get('CurrentProgram');
    if (programDetails) {
      this.programId = programDetails['id'];
      this.user = this._storageService.get('user');
      this.userService.getMembershipDetails(this.programId, this.user?.id).subscribe(data => {
        this.role = data?.member?.role;
        this.loadConfigWidgetData();
      });
      // handle widgetload after vendor check
      const isVendor = this.storageservice.get('user_type') === 'VENDOR';
      if (isVendor) {
        this.userService.get(`/configurator/programs/${this.programId}/vendors/${this.user.organization_id}`)
          .subscribe(res => {
            const { program_vendor } = res;
            if (!program_vendor.is_onboarded) {
              this.route.navigate(['vendor-managment/setup'])
            }
          })
      }
      if (this.user?.is_candidate) {
      this.getWorkersList();
      }
    } else {
      this.hasNoProgram = true;
    }
  }

  loadConfigWidgetData() {
    this.dashboardService.getConfigJson(this.programId, this.user?.id, this.role?.id).subscribe(res => {
      this.widgetData = res?.data?.widget_data;
    }, err => {
      console.log(err);
    });
  }
  onClickSetting(event) {
    if (event) {
      this.grid.enable();
    } else {
      this.grid.disable();
    }
  }
  setAssignmentUUID(id) {
    this.assignmentId = id;
  }
  getWorkersList() {
    this.dashboardService.getWorkersList().subscribe(data => {
      if (data) {
        this.workers = data?.data;
        if (this.workers && this.workers?.length > 0) {
          this.getAssignment(this.workers[0]);
        }
      }
    }, (err) => {
      // this.alert.error(errorHandler(err));
    });
  }

  getAssignment(e) {
    let programDetails = this.storageService.get('CurrentProgram');
    let programId = programDetails['id'];
    let _url = `/assignment/programs/${programId}/worker/${e?.worker_id}/assignment`;
    this.dashboardService.get(_url).subscribe(data => {
      if (data) {
        this.assignment = data?.data;
      }
    });
  }



}
